export enum BrandingImageType {
  SPLASH_IMAGE = 'SPLASH_IMAGE',
  THUMBNAIL = 'THUMBNAIL',
  FAVICON = 'FAVICON',
}

export enum ProductDomainType {
  XHEDGE = 'XHEDGE',
  API = 'API',
  BANK_HEDGE = 'BANK_HEDGE',
  BANK_HEDGE_PARTNER = 'BANK_HEDGE_PARTNER',
}

export interface PartnerBrandingImage {
  id: string;
  name: string;
  contentType: string;
  link: string;
  type: BrandingImageType;
}

export interface PartnerProductDomain {
  product: ProductDomainType;
  domain: string;
}

export interface PartnerColorSheme {
  property: string;
  value: string;
}

export interface EditablePartnerBrandingData {
  productDomains: PartnerProductDomain[];
  colorSchemes: PartnerColorSheme[];
  email: string;
  notificationEmail: string;
  helpEmail: string;
  helpURL: string;
  tradingDeskPhoneNumber: string;
  tradingDeskEmail: string;
}

export interface PartnerBrandingData extends EditablePartnerBrandingData {
  id: string;
  name: string;
  logos: PartnerBrandingImage[];
  displayCostTransparency: boolean;
  createdAt: string;
  createdById: string;
  updatedAt?: string;
  updatedById?: string;
  deleted: boolean;
  deletedAt?: string;
  deletedById?: string;
}
