<script setup lang="ts">
import { reactive, computed, ref, watch } from 'vue';
import OptionDetailsFormGroup from './forms/OptionDetailsFormGroup.vue';
import OptionReview from './OptionReview.vue';
import OptionConfirmation from './OptionConfirmation.vue';
import { AmountType, FeatureFlag, VanillaPriceResponse } from 'ah-api-gateways/models';
import CountdownTimer from 'ah-common-lib/src/common/components/CountdownTimer.vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { cloneDeep } from 'lodash';
import { useFeatureFlagStore } from 'ah-common-stores';

const props = defineProps({
  flowTitle: {
    type: String,
    default: 'Options',
  },
});

const optionDetailsFormGroup = ref<InstanceType<typeof OptionDetailsFormGroup> | null>(null);

enum OptionsCreatorSteps {
  OPTIONS_CREATOR = 'optionCreator',
  OPTIONS_REVIEW = 'optionsReview',
  OPTIONS_BOOKED = 'optionsBooked',
}

const state = reactive<{
  selectedSolution?: VanillaPriceResponse;
  solutions: VanillaPriceResponse[];
  stage: OptionsCreatorSteps;
  createdTrade?: string;
  loading: boolean;
  pause: boolean;
  defaultDate: Date | null;
  validations: any[];
}>({
  solutions: [],
  stage: OptionsCreatorSteps.OPTIONS_CREATOR,
  loading: false,
  pause: false,
  defaultDate: null,
  validations: [],
});

const featureFlagStore = useFeatureFlagStore();

function setSolution(selectedSolution: VanillaPriceResponse) {
  state.selectedSolution = selectedSolution;
}

const title = computed(() => {
  return state.stage === OptionsCreatorSteps.OPTIONS_CREATOR ? props.flowTitle : 'Review Termsheet & Schedule';
});

const requestManager = useRequestManager();
const tradeState = useAhTradesState();
const onBehalfOfClient = useOnBehalfOf();

const clientId = computed(
  () => tradeState.store.useAuthStore().loggedInIdentity?.client?.id ?? onBehalfOfClient.value?.id
);

function isInvalidSolution(solutionId: string) {
  const index = state.validations.findIndex((validation) => validation.solutionId === solutionId);

  return index >= 0 ? state.validations[index].validation : false;
}

function refreshRates() {
  if (state.stage === OptionsCreatorSteps.OPTIONS_CREATOR) {
    state.defaultDate = new Date(new Date().setMinutes(new Date().getMinutes() + 1));
    state.solutions.forEach((solution) => {
      if (!isInvalidSolution(solution.id)) {
        optionDetailsFormGroup.value?.reloadPrices(createVanillaOption(solution), solution.id);
      }
    });
  } else if (state.stage === OptionsCreatorSteps.OPTIONS_REVIEW && state.selectedSolution) {
    requestManager.manager
      .sameOrCancelAndNew(
        'createVanillaOptions' + state.selectedSolution.id,
        tradeState.services.pricingEngine.createVanillaOptionsQuote(
          createVanillaOption(state.selectedSolution),
          onBehalfOfClient.value?.id
        )
      )
      .subscribe((price) => {
        const index = state.selectedSolution
          ? state.solutions.findIndex((sol) => sol.id === state.selectedSolution!.id)
          : -1;
        if (index >= 0) {
          const solutions = cloneDeep(state.solutions);
          let premiumCurrency;
          let premiumType;
          if (solutions[index].premiumCurrency) {
            premiumCurrency = solutions[index].premiumCurrency;
          } else {
            premiumCurrency = price.ccy1.amountType === AmountType.SELL ? price.ccy1.currency : price.ccy2.currency;
          }

          if (solutions[index].premiumType) {
            premiumType = solutions[index].premiumType;
          }
          solutions[index] = { ...price, premiumCurrency, premiumType };
          state.selectedSolution = solutions[index];
          state.solutions = solutions;
        }
      });
  }
}

function createVanillaOption(solution: VanillaPriceResponse) {
  const isPrimaryRate = solution.ccy1.isPrimaryRate === true ? solution.ccy1 : solution.ccy2;
  const isFixedSide = solution.ccy1.isFixedSide === true ? solution.ccy1 : solution.ccy2;
  const sellCurrency = solution.ccy1.amountType === AmountType.SELL ? solution.ccy1.currency : solution.ccy2.currency;
  const buyCurrency = solution.ccy1.amountType === AmountType.BUY ? solution.ccy1.currency : solution.ccy2.currency;

  return {
    sellCurrency: sellCurrency,
    buyCurrency: buyCurrency,
    amount: isFixedSide.clientAmount,
    amountType: isFixedSide.amountType,
    settlementDate: formatDate(new Date(solution.settlementDate), 'yyyy-MM-dd'),
    strikeRate: isPrimaryRate.optionsCurrencyData.selectedStrikeRate,
    clientId: clientId.value,
  };
}

const earliestPriceExpiry = computed(() => {
  if (state.solutions.length === 0) {
    return undefined;
  }

  if (state.stage === OptionsCreatorSteps.OPTIONS_REVIEW && state.selectedSolution) {
    return new Date(state.selectedSolution.priceExpirationTimestamp);
  }

  return state.defaultDate;
});

watch(
  () => state.solutions,
  (newVal, oldVal) => {
    if (oldVal.length === 0 && newVal.length === 1) {
      state.defaultDate = new Date(new Date().setMinutes(new Date().getMinutes() + 1));
    }
  }
);

function loadFeatureFlag() {
  const payload = {
    featureFlag: FeatureFlag.VANILLA_OPTIONS_HNPL_ENABLED,
    force: true,
  };

  featureFlagStore.loadFeatureFlag(payload);
}

loadFeatureFlag();
</script>

<template>
  <div class="section">
    <div class="title-bar mb-4" v-if="state.stage !== OptionsCreatorSteps.OPTIONS_BOOKED">
      <h2 class="mb-0">{{ title }}</h2>

      <div class="update-quote" v-if="earliestPriceExpiry">
        <span>Refreshing in... </span>
        <CountdownTimer :target-date="earliestPriceExpiry" @timer-end="refreshRates" :pause="state.pause" />
        <VButton class="refresh-button secondary" :loading="state.loading" @click="refreshRates">
          <RefreshIcon />
          Refresh rates
        </VButton>
      </div>
    </div>

    <OptionDetailsFormGroup
      v-if="state.stage === OptionsCreatorSteps.OPTIONS_CREATOR"
      :stage.sync="state.stage"
      :solutions.sync="state.solutions"
      :validations.sync="state.validations"
      :clientId="clientId"
      @update:selectedSolution="setSolution($event)"
      ref="optionDetailsFormGroup"
    />
    <OptionReview
      v-else-if="state.stage === OptionsCreatorSteps.OPTIONS_REVIEW && state.selectedSolution"
      :solution="state.selectedSolution"
      :stage.sync="state.stage"
      :pause.sync="state.pause"
      :createdTrade.sync="state.createdTrade"
      :loading="requestManager.manager.requestStates[`createVanillaOptions${state.selectedSolution.id}`] === 'pending'"
      :error="requestManager.manager.requestStates[`createVanillaOptions${state.selectedSolution.id}`] === 'error'"
      @update:createLoading="state.loading = $event"
      @remove-selected-solution="state.selectedSolution = undefined"
      @reload-prices="refreshRates"
    />
    <OptionConfirmation
      v-else-if="state.stage === OptionsCreatorSteps.OPTIONS_BOOKED && state.createdTrade"
      :createdTrade="state.createdTrade"
    />
  </div>
</template>

<style lang="scss" scoped>
.title {
  min-width: 50%;
  font-size: 1.6em;
  @include upToResolution($tabletResolution) {
    font-size: 18px;
    height: 2em;
    line-height: 3.2em;
    margin-bottom: 0 !important;
  }
  @include themedTextColor($color-text, $color-dark-text);
}

.title-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  @include upToResolution($tabletResolution) {
    font-size: 15px;
    padding-bottom: 0rem;
    display: block;
    flex-direction: column;
  }

  h2 {
    min-width: 50%;
    font-size: 1.6em;
    @include upToResolution($tabletResolution) {
      font-size: 18px;
      height: 2em;
      line-height: 3.2em;
      margin-bottom: 1rem !important;
    }
  }

  .update-quote {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
</style>
