<script setup lang="ts">
import { CurrencyData } from 'ah-api-gateways';
import { computed, reactive, watch, PropType } from 'vue';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { useAhTradesState } from '../..';
import TagMultiSelect from 'ah-common-lib/src/common/components/selects/TagMultiSelect.vue';

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  allowedCurrencies: {
    type: Array as PropType<string[]>,
    required: false,
  },
  maxFulltextLabels: {
    type: Number,
    required: false,
    default: 3,
  },
  placeholder: {
    type: String,
    required: false,
  },
  value: {
    type: Array,
    required: false,
    default: () => [],
  },
  clearable: {
    type: [Boolean, String],
    required: false,
    default: true,
  },
});

interface CurrencyOption extends FieldOptionObj {
  currencyLabel: string;
}

const state = reactive<{
  tradeableCurrencies: CurrencyData[];
  options: CurrencyOption[];
}>({
  tradeableCurrencies: [],
  options: [],
});

const emit = defineEmits<{
  (e: 'update:value', value: any[]): void;
}>();

const tradeState = useAhTradesState();

function loadCurrencies() {
  tradeState.store
    .useSettingsStore()
    .loadTradeableCurrencies()
    .then((currencies) => {
      state.tradeableCurrencies = currencies;
    });
}
loadCurrencies();

const showClear = computed(() => isClearable.value && props.value && props.value.length > 0);

const isClearable = computed(() => props.clearable !== false);

function clearField() {
  emit('update:value', []);
}

function checkOptionFilter(option: CurrencyOption, label: string, search: string) {
  const searchable = `${option.value} ${option.currencyLabel}`;
  return searchable.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
}

watch(
  () => [state.tradeableCurrencies, props.allowedCurrencies],
  () => {
    state.options = state.tradeableCurrencies
      .filter((c) => !props.allowedCurrencies || props.allowedCurrencies.includes(c.currency))
      .map((c) => ({ label: c.currency, value: c.currency, currencyLabel: c.name }));
  },
  { immediate: true }
);
</script>

<template>
  <div class="currency-filter-select">
    <label v-if="title && isClearable">
      {{ title }}
      <a v-if="showClear" class="field-group-clear-link" @click="clearField">clear</a>
    </label>
    <TagMultiSelect
      :value="value"
      @update:value="$emit('update:value', $event)"
      :options="state.options"
      :maxFulltextLabels="maxFulltextLabels"
      :placeholder="placeholder"
      :filterBy="checkOptionFilter"
      itemsCountLabel="currencies"
    >
      <template #optionLabel="{ option }">
        <slot name="optionLabel" v-bind="{ option }"> {{ option.value }} - {{ option.currencyLabel }} </slot>
      </template>
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </TagMultiSelect>
  </div>
</template>
