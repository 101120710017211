<script setup lang="ts">
import { computed, onBeforeMount, reactive, watch } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { setState, toDataModel, updateModel, getChildModel, resetForm } from 'ah-common-lib/src/form/helpers';
import { makeCompanyDetailsFM, makeCompanyAddressFM } from './reviewForms';
import { Client, EntityType } from 'ah-api-gateways';
import { useSettingsStore } from '@/app/store/settingsModule';
import { capitalize } from 'lodash';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { catchError, mergeMap } from 'rxjs/operators';
import { waitForCQRSEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { useToast } from 'ah-common-lib/src/toast';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';

const props = withDefaults(defineProps<{ editable?: boolean; model: Client }>(), { editable: true });

const state = reactive<{ editing: boolean }>({ editing: false });

const emit = defineEmits<{ (e: 'update:model', value: Client): void }>();

const settingsStore = useSettingsStore();

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const toast = useToast();

settingsStore.loadCountries();
settingsStore.loadEntityTypes();

function entityToHuman(entityType?: string) {
  if (!entityType) return '-';
  return (
    settingsStore.entityTypesList?.find((entity: EntityType) => entity.id === entityType)?.legalForm ||
    capitalize(entityType.toLowerCase().split('_').join(' '))
  );
}

const companyInfoBlock = computed<InformationBlock[]>(() => [
  {
    label: 'Country of Incorporation',
    key: 'countryCode',
    value: countryNameFromCC(props.model.companyDetails.address.countryCode),
    cols: 4,
  },
  { label: 'Company Type', key: 'entityType', value: entityToHuman(props.model.companyDetails.entityType), cols: 4 },
  { label: 'Registered Company Name', key: 'name', cols: 4 },
  { label: 'Company Number', key: 'registrationNumber', cols: 4 },
  { label: 'Sector', key: 'sectorDescription', cols: 4 },
  { label: 'Company Website', key: 'website', cols: 4 },
  { label: 'Company Telephone', key: 'phoneNumber', cols: 4 },
]);

const addressBlock = computed(() => [
  { label: 'Address Line 1', key: 'addressLine', cols: 4 },
  { label: 'City', key: 'city', cols: 4 },
  { label: 'State/Province/County', key: 'stateOrProvince', cols: 4 },
  { label: 'Postcode / ZIP Code', key: 'postalCode', cols: 4 },
  {
    label: 'Country',
    key: 'countryCode',
    cols: 4,
  },
]);

const companyDetailsForm = reactive<FormDefinition>({
  form: makeCompanyDetailsFM(),
  validation: null,
});

const addressForm = reactive<FormDefinition>({
  form: makeCompanyAddressFM(true),
  validation: null,
});

const tradingAddressForm = reactive<FormDefinition>({
  form: makeCompanyAddressFM(),
  validation: null,
});

const anyInvalid = computed(
  () =>
    companyDetailsForm.validation?.$invalid ||
    addressForm.validation?.$invalid ||
    tradingAddressForm.validation?.$invalid
);

const isEditable = computed(() => props.editable !== false);

const companyDetailsModel = computed(() => ({
  countryCode: props.model.companyDetails.address.countryCode,
  ...props.model.companyDetails,
  name: props.model.name,
}));

function save() {
  const clientId = authStore.loggedInIdentity!.client!.id;
  const companyDetails = { ...toDataModel(companyDetailsForm.form) };
  const address = { ...toDataModel(addressForm.form) };
  const tradingAddress = { ...toDataModel(tradingAddressForm.form) };

  Object.keys(companyDetails).forEach((k) => {
    if (companyDetails[k] === '') {
      delete companyDetails[k];
    }
  });

  const model = {
    name: companyDetails.name,
    companyDetails: {
      ...companyDetails,
      address,
      tradingAddress,
    },
  };

  requestManager
    .currentOrNew(
      'saveClient',
      services.registration
        .updateClient(clientId, model)
        .pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () => services.client.getClient(idEntity.id)).pipe(
              catchError(() => of(props.model))
            )
          )
        )
    )
    .subscribe((client) => {
      toast?.success('Company details changed successfully');
      state.editing = false;
      emit('update:model', client);
    });
}

function toggleEditing() {
  state.editing = !state.editing;
  if (!state.editing) {
    resetForms(true);
  }
}

onBeforeMount(() => {
  Promise.all([settingsStore.loadEntityTypes(true), settingsStore.loadCountries(true)]).then(
    ([entityTypes, countries]) => {
      setState(
        getChildModel(companyDetailsForm.form, 'entityType')!,
        'options',
        entityTypes.map((c) => ({ value: c.id, label: c.legalForm }))
      );

      const countryOptions = countries.map((c) => ({ value: c.cc, label: c.name }));
      const countryForms = [companyDetailsForm, addressForm, tradingAddressForm];

      countryForms.forEach((form) => {
        setState(getChildModel(form.form, 'countryCode')!, 'options', countryOptions);
      });
    }
  );
});

function resetForms(resetState = false) {
  updateModel(companyDetailsForm.form, {
    countryCode: props.model.companyDetails.address.countryCode,
    ...props.model,
    ...props.model.companyDetails,
  });
  updateModel(addressForm.form, props.model.companyDetails.address ?? {});
  updateModel(tradingAddressForm.form, props.model.companyDetails.tradingAddress ?? {});

  if (resetState) {
    companyDetailsForm.validation && resetForm(companyDetailsForm.validation);
    addressForm.validation && resetForm(addressForm.validation);
    tradingAddressForm.validation && resetForm(tradingAddressForm.validation);
  }
}

watch(
  () => props.model,
  () => resetForms(),
  { immediate: true }
);
</script>

<template>
  <div class="card-block" x-test-name="company-details-review">
    <div class="card-review-header">
      <h2>Company Details</h2>
      <div class="button-holder" v-if="isEditable">
        <VButton blurOnClick @click="toggleEditing" class="btn-stroked">
          {{ state.editing ? 'Cancel' : 'Edit' }}
        </VButton>
        <VButton
          blurOnClick
          @click="save"
          v-if="state.editing"
          :loading="requestManager.anyPending"
          :disabled="anyInvalid"
          class="ml-3"
        >
          Save
        </VButton>
      </div>
    </div>
    <template v-if="!state.editing">
      <InfoBlock emptyValue="-" :model="companyDetailsModel" :info="companyInfoBlock" />
      <h3 class="mb-3">Registered Address</h3>
      <InfoBlock emptyValue="-" :model="model.companyDetails.address" :info="addressBlock">
        <template #countryCode-value>
          {{ countryNameFromCC(model.companyDetails.address.countryCode) }}
        </template>
      </InfoBlock>
      <h3 class="mb-3">Trading Address</h3>
      <InfoBlock emptyValue="-" :model="model.companyDetails.tradingAddress" :info="addressBlock">
        <template #countryCode-value>
          {{ countryNameFromCC(model.companyDetails.tradingAddress?.countryCode) }}
        </template>
      </InfoBlock>
    </template>
    <template v-else>
      <ValidatedForm :fm="companyDetailsForm.form" :validation.sync="companyDetailsForm.validation" />
      <h3 class="my-3">Registered Address</h3>
      <ValidatedForm :fm="addressForm.form" :validation.sync="addressForm.validation" />
      <h3 class="my-3">Trading Address</h3>
      <ValidatedForm :fm="tradingAddressForm.form" :validation.sync="tradingAddressForm.validation" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }

  .button-holder {
    margin-left: auto;
    display: inline-flex;
    .btn {
      min-width: 7rem;
    }
  }
}

::v-deep {
  label {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }
}

h4 {
  font-weight: $font-weight-semibold;
}
</style>
