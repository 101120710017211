export enum VoiceTradeState {
  OPEN = 'OPEN',
  PARTIALLY_OPEN = 'PARTIALLY_OPEN',
  CANCELLED = 'CANCELLED',
  AMENDED = 'AMENDED',
  SETTLED = 'SETTLED',
}

export const openVoiceTradeStates = [VoiceTradeState.OPEN, VoiceTradeState.PARTIALLY_OPEN];

export const voiceTradeStateBadges: Record<VoiceTradeState, { label: string; class: string }> = {
  [VoiceTradeState.OPEN]: {
    label: 'Open',
    class: 'light-grey',
  },
  [VoiceTradeState.PARTIALLY_OPEN]: {
    label: 'Partially Open',
    class: 'light-grey',
  },
  [VoiceTradeState.CANCELLED]: {
    label: 'Cancelled',
    class: 'light-red',
  },
  [VoiceTradeState.AMENDED]: {
    label: 'Amended',
    class: 'light-grey',
  },
  [VoiceTradeState.SETTLED]: {
    label: 'Settled',
    class: 'green',
  },
};
