import { ApiError, PayloadErrorData } from '..';
import { toPath } from 'lodash';

/**
 * Construct a PayloadErrorData structure from an API error response
 * This process:
 * - maps the `field` value of suberrors (expected to be a path for the field in question) to a tree of errors
 * - adds occurenceId to all field levels
 *    - occurenceId helps check for error changes and recalculations
 *    - adding it at all field levels allows for using any subsection of the generated payloads
 */
export function constructPayloadErrors<M = any>(apiError: ApiError): PayloadErrorData<M> {
  const out: PayloadErrorData = {
    occurrenceId: apiError.occurrenceId,
  };

  apiError.subErrors?.forEach((fieldError) => {
    let parent = out;

    const path = fieldError.field ? toPath(fieldError.field) : [];

    for (let index = 0; index < path.length; index++) {
      const element = path[index];

      parent.fields = parent.fields || {};
      parent.fields[element] = parent.fields[element] || {
        occurrenceId: apiError.occurrenceId,
      };

      parent = parent.fields[element]!;
    }
    parent.errors = parent.errors || [];

    parent.errors.push(fieldError);
  });

  return out;
}
