<script lang="ts" setup>
import {
  BeneficiaryType,
  BeneficiaryCategory,
  Beneficiary,
  Address,
  BankAccount,
  BankingScheme,
  BeneficiaryManagerErrorCodes,
} from 'ah-api-gateways';
import { makeFormModel, submitForm, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { beneficiaryTypeForm } from './formSchemas/beneficiaryFormSchemas';
import { waitForCQRSEntityChange } from 'ah-requests';
import LocalizedAddressForm from './address/LocalizedAddressForm.vue';
import BeneficiaryDetailsForm from './BeneficiaryDetailsForm.vue';
import cloneDeep from 'lodash/cloneDeep';
import { CountryInfo, GenericErrorCodes } from 'ah-api-gateways';
import BankAccountForm from './bankAccounts/BankAccountForm.vue';
import { mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { scrollToValidation } from 'ah-common-lib/src/form/helpers';
import { FormValidation, CompoundValidation, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { computed, reactive, ref, watch } from 'vue';
import { useAhBeneficiariesState } from '..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const DEFAULT_CURRENCY = 'GBP';

const emit = defineEmits<{
  /**
   * - update:validations: emmited when `validations` changes. Can be used with .sync
   */
  (e: 'update:validations', value: FormValidation[]): void;
  /**
   * update:beneficiary: emmited when a beneficiary is saved/created. Can be used with .sync
   */
  (e: 'update:beneficiary', value: Beneficiary): void;
  (e: 'cancel'): void;
}>();

const requestManager = useRequestManager({
  exposeToParent: ['loadCountries'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadCountries') {
      updateCountriesList();
    }
  },
});

const beneficiaryDetailsForm = ref<InstanceType<typeof BeneficiaryDetailsForm> | null>(null);

const addressForm = ref<InstanceType<typeof LocalizedAddressForm> | null>(null);

const bankAccountForm = ref<InstanceType<typeof BankAccountForm> | null>(null);

const props = defineProps<{
  /**
   * Beneficiary to update, if any
   *
   * If unset, a new beneficiary will be created
   * Can be updated via .sync
   */
  beneficiary?: Beneficiary;
  /**
   * Beneficiary forced banking scheme
   *
   * if set, banking scheme will be preselected with 'forceBankingScheme'
   * and user can't change the beneficiary banking scheme
   */
  forceBankingScheme?: BankingScheme;
}>();

const state = reactive({
  beneficiaryDetailsValidation: null as FormValidation | null,
  addressFormValidation: null as FormValidation | null,
  address: {} as Partial<Address>,
  beneficiaryDetails: {} as Partial<Beneficiary>,
  bankAccountDetails: {} as Partial<BankAccount>,
  bankFormValidation: null as CompoundValidation | null,
  countriesList: [] as CountryInfo[],
});

const beneficiaryState = useAhBeneficiariesState();

const onBehalfOfClient = useOnBehalfOf();

const beneficiaryTypeDef = reactive<FormDefinition>({
  form: makeFormModel(beneficiaryTypeForm()),
  validation: null,
});

const formsInvalid = computed(() => {
  return validations.value.find((f) => !f || f.$invalid);
});

const formsLoaded = computed(() => {
  return validations.value.filter((f) => !f).length === 0;
});

const countryCode = computed(() => {
  if (props.beneficiary) {
    return props.beneficiary.address.countryCode ?? '';
  }
  return beneficiaryTypeDef.form.countryCode ?? '';
});

const currency = computed(() => {
  if (state.bankAccountDetails.currency) {
    return state.bankAccountDetails.currency;
  }

  /**
   * In order to get the correct beneficiary details (reference to PT-1958)
   * We need to check, after selecting the country, if the provided currency is allowed/tradable in our app
   * If not, we send GPB as the default currency
   */
  const countryCurrency = beneficiaryState.store.useSettingsStore().getCountryCurrency(countryCode.value);

  if (countryCurrency) {
    const isSupportedCurrency = beneficiaryState.store
      .useSettingsStore()
      .currencies.some((currencyObj) => currencyObj.currency === countryCurrency);

    return isSupportedCurrency ? countryCurrency : DEFAULT_CURRENCY;
  }

  return DEFAULT_CURRENCY;
});

const bankingScheme = computed(() => {
  return state.bankAccountDetails.bankingScheme;
});

const isSaving = computed(() => {
  return requestManager.manager.requestStates.saveBeneficiary === 'pending';
});

const isIndividualBeneficiary = computed(() => {
  if (props.beneficiary) {
    return props.beneficiary.type === BeneficiaryType.INDIVIDUAL;
  }
  return beneficiaryTypeDef.form.type === BeneficiaryType.INDIVIDUAL;
});

const beneficiaryType = computed(() => {
  return isIndividualBeneficiary.value ? BeneficiaryType.INDIVIDUAL : BeneficiaryType.COMPANY;
});

const isAgentUser = computed(() => {
  return !beneficiaryState.store.useAuthStore().isClientUser;
});

function loadCountries() {
  requestManager.manager.newPromise(
    'loadCountries',
    beneficiaryState.store
      .useSettingsStore()
      .loadCountries()
      .then((countries) => {
        state.countriesList = countries.filter((c) => c.enabled);
      })
  );
}

loadCountries();

watch(
  () => props.forceBankingScheme,
  () => {
    if (props.forceBankingScheme) {
      beneficiaryTypeDef.form.countryCode =
        onBehalfOfClient.value?.companyDetails.address.countryCode ||
        beneficiaryState.store.useAuthStore().loggedInIdentity?.address.countryCode;
    }
  },
  { immediate: true }
);

function updateCountriesList() {
  const countryCodeField = getChildModel(beneficiaryTypeDef.form, 'countryCode');
  if (countryCodeField) {
    setState(
      countryCodeField,
      'options',
      state.countriesList.map((i) => ({ label: i.name, value: i.cc }))
    );
  }
}

watch(
  () => state.countriesList,
  () => {
    updateCountriesList();
  },
  { immediate: true }
);

watch(
  () => props.beneficiary,
  () => {
    if (props.beneficiary) {
      state.beneficiaryDetails = cloneDeep(props.beneficiary);
      state.address = props.beneficiary.address;
    } else {
      state.beneficiaryDetails = {};
      state.address = {};
    }
  },
  { immediate: true }
);

watch(
  () => state.beneficiaryDetails,
  () => {
    beneficiaryDetailsForm.value?.clearErrorMessages();
  },
  { immediate: true }
);

function saveBeneficiary() {
  submitForms();

  if (formsInvalid.value) {
    scrollToValidation(validations.value.find((f) => !f || f.$invalid)!);
    return;
  }

  const model: Beneficiary = {
    ...formToBeneficiaryModel(),
    category:
      isAgentUser.value && !onBehalfOfClient.value
        ? BeneficiaryCategory.PARTNER_3RD_PARTY
        : BeneficiaryCategory.CLIENT_3RD_PARTY,
  };

  if (!model.identificationType || !model.identificationValue) {
    delete model.identificationType;
    delete model.identificationValue;
  }

  Object.keys(model).forEach((key) => {
    if ((model as any)[key] === '') {
      (model as any)[key] = null;
    }
  });

  let request;
  if (props.beneficiary?.id) {
    request = beneficiaryState.services.beneficiary.updateBeneficiary(
      props.beneficiary.id,
      model,
      onBehalfOfClient.value?.id,
      { errors: { silent: true } }
    );
  } else {
    request = beneficiaryState.services.beneficiary.createBeneficiary(model, onBehalfOfClient.value?.id, {
      errors: { silent: true },
    });
  }

  requestManager.manager
    .currentOrNew(
      'saveBeneficiary',
      request.pipe(
        mergeMap((idEntity) =>
          waitForCQRSEntityChange(
            idEntity,
            () =>
              beneficiaryState.services.beneficiary.getBeneficiary(idEntity.id, {
                errors: { silent: true },
              }),
            { ignoreErrors: (e) => e.response?.status === 404 }
          ).pipe(catchError(() => of(model)))
        )
      )
    )
    .subscribe(
      (beneficiary) => {
        emit('update:beneficiary', beneficiary);
        beneficiaryState.toast.success(`Beneficiary ${props.beneficiary?.id ? 'updated' : 'created'}`);
      },
      (e) => {
        const error = e.response?.data;
        if (error && error.code === GenericErrorCodes.VALIDATION_ERROR) {
          if (error.subErrors && error.subErrors.length) {
            for (let i = 0; i < error.subErrors.length; i++) {
              const subError = error.subErrors[i];
              if (subError.category === 'VALIDATION') {
                setErrorMessage(subError.field, subError.message);
              }
            }
          }
          beneficiaryState.toast.error('Form contains errors, please review values entered.');
          return;
        } else if (error && error.code === BeneficiaryManagerErrorCodes.BUSINESS_RULE_NOT_MET && error.message) {
          beneficiaryState.toast.error(error.message);
          return;
        }
        beneficiaryState.toast.error('An unexpected problem has occurred. Please try again later.');
      }
    );
}

function setErrorMessage(fieldName: string, message: string) {
  if (fieldName.startsWith('address.')) {
    if (addressForm.value) {
      addressForm.value!.setErrorMessage(fieldName.substr('address.'.length), message);
    }
  } else {
    if (beneficiaryDetailsForm.value!) {
      beneficiaryDetailsForm.value!.setErrorMessage(fieldName, message);
      bankAccountForm.value!.setErrorMessage(fieldName, message);
    }
  }
}

function formToBeneficiaryModel(): Beneficiary {
  const address = {
    ...state.address,
    countryCode: countryCode.value,
  };
  Object.keys(address).forEach((k) => {
    if (!(address as any)[k]) {
      delete (address as any)[k];
    }
  });

  const beneficiary: Beneficiary = {
    ...{ clientId: beneficiaryState.store.useAuthStore().loggedInIdentity?.client?.id },
    ...props.beneficiary,
    ...state.beneficiaryDetails,
    ...state.bankAccountDetails,
    address,
  } as any;

  return beneficiary;
}

const validations = computed(() => {
  const bankAccountValidators = state.bankFormValidation?.validations || [];

  return [
    ...(props.beneficiary ? [] : [beneficiaryTypeDef.validation!]),
    state.beneficiaryDetailsValidation!,
    state.addressFormValidation!,
    ...bankAccountValidators,
  ] as FormValidation[];
});

watch(
  validations,
  () => {
    emit('update:validations', validations.value);
  },
  { immediate: true }
);

// FIXME this is a hack hardcoded to prevent Chinese Individual beneficiaries.
// Once this is data retrievable from the API, we should use that instead
watch(
  countryCode,
  () => {
    if (props.beneficiary) return;

    const typeField = getChildModel(beneficiaryTypeDef.form, 'type')!;

    if (beneficiaryTypeDef.form.countryCode === 'CN') {
      beneficiaryTypeDef.form.type = BeneficiaryType.COMPANY;
      setState(typeField, 'readonly', true);
    } else {
      setState(typeField, 'readonly', false);
    }
  },
  { immediate: true }
);

function submitForms() {
  validations.value!.forEach((f) => {
    if (f) {
      submitForm(f);
    }
  });
}
</script>

<template>
  <div class="beneficiary-form">
    <div class="card-block">
      <!-- beneficiary type -->
      <ValidatedForm
        :fm="beneficiaryTypeDef.form"
        :validation.sync="beneficiaryTypeDef.validation"
        v-if="!beneficiary"
      />
      <template v-if="beneficiary || (currency && countryCode)">
        <!-- beneficiary details -->
        <BeneficiaryDetailsForm
          ref="beneficiaryDetailsForm"
          :model.sync="state.beneficiaryDetails"
          :allowNameEdit="true"
          :validation.sync="state.beneficiaryDetailsValidation"
          :isIndividual="isIndividualBeneficiary"
          :bankingScheme="bankingScheme"
          :currency="currency"
          :bankCountry="countryCode"
        />
        <LocalizedAddressForm
          ref="addressForm"
          :model.sync="state.address"
          :validation.sync="state.addressFormValidation"
          :currency="currency"
          :beneficiaryType="beneficiaryType"
          :bankingScheme="bankingScheme"
          noCountryCode
        />
        <h3 class="mt-2">Bank Account</h3>
        <BankAccountForm
          ref="bankAccountForm"
          :countryCode="beneficiaryTypeDef.form.countryCode"
          :beneficiaryType="beneficiaryTypeDef.form.type"
          :beneficiary="state.beneficiaryDetails"
          :bankAccount.sync="state.bankAccountDetails"
          :compoundValidation.sync="state.bankFormValidation"
          :forceBankingScheme="forceBankingScheme"
        />
      </template>
    </div>
    <div class="my-4 text-sm-center text-md-left">
      <!-- save and cancel -->
      <VButton label="Cancel" class="secondary mr-2" @click="emit('cancel')" />
      <VButton
        :loading="isSaving"
        :disabled="!formsLoaded || formsInvalid"
        :label="beneficiary ? 'Save' : 'Add beneficiary'"
        @click="saveBeneficiary"
      />
    </div>
  </div>
</template>
