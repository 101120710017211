import { Form, Field } from '../../interfaces';
import { textField, selectField } from '../../models';
import { formOverride } from '../../helpers';
import countries from '../../../constants/countries';
import { maxLength } from '@vuelidate/validators';
import { requiredIfStateValue } from '../../validators';

export const addressFormFields = (required = true, countryEditable = true): Field[] => [
  textField(
    'addressLine',
    'Address',
    { required, errorMessages: { maxLength: 'Address cannot have more than 250 characters.' } },
    { maxLength: maxLength(250), required: requiredIfStateValue('addressLine') }
  ),
  textField(
    'city',
    'City',
    { required, errorMessages: { maxLength: 'City cannot have more than 86 characters.' } },
    { maxLength: maxLength(86), required: requiredIfStateValue('city') }
  ),
  textField(
    'stateOrProvince',
    'State/Province',
    {
      class: 'half-width right',
      required,
      errorMessages: { maxLength: 'State/Province cannot have more than 128 characters.' },
    },
    { maxLength: maxLength(128), required: requiredIfStateValue('stateOrProvince') }
  ),
  textField(
    'postalCode',
    'Postcode',
    { class: 'half-width right', required, errorMessages: { maxLength: 'Postcode cannot have more than 10 digits.' } },
    { maxLength: maxLength(10), required: requiredIfStateValue('postalCode') }
  ),
  selectField('countryCode', 'Country', countries, {
    required: required && countryEditable,
    readonly: !countryEditable,
    titleTooltip:
      'Your country of residence is the country in which you are currently living in, at the time you submit your application for this account.',
  }),
];

export const addressForm = (
  name: string,
  title: string,
  overrides: any = {},
  required = true,
  countryEditable = true
): Form =>
  formOverride(
    {
      name,
      fieldType: 'form',
      state: {
        title,
      },
      fields: addressFormFields(required, countryEditable),
    },
    overrides
  );
