<script setup lang="ts">
import { makeFormModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { PropType, reactive, watch } from 'vue';
import { FormDefinition } from 'src/form/interfaces';

const emit = defineEmits({
  'update:selected-value': (_value: number) => true,
});

const props = defineProps({
  selectedValue: {
    type: Number,
    default: 10,
  },
  customRowsOptions: {
    type: Array as PropType<{ label: string; value: number }[]>,
    default: () => [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '50', value: 50 },
    ],
  },
});

const pageNumberSelectFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'numberOfRowsSelector',
    fieldType: 'form',
    fields: [
      selectField('numberOfRows', 'Display', [], {
        placeholder: 'Display...',
        fieldWrapperClass: 'col col-12 mb-0',
      }),
    ],
    state: {
      hideErrors: true,
    },
  }),
  validation: null,
});

function onCustomRowOptionsChange() {
  setState(getChildModel(pageNumberSelectFormDef.form, 'numberOfRows')!, 'options', props.customRowsOptions);
}

watch(() => props.customRowsOptions, onCustomRowOptionsChange, { immediate: true });

function onPropsChange() {
  pageNumberSelectFormDef.form.numberOfRows = props.selectedValue;
}

watch(() => props.selectedValue, onPropsChange, { immediate: true });

function onValueChange() {
  emit('update:selected-value', pageNumberSelectFormDef.form.numberOfRows);
}

watch(() => pageNumberSelectFormDef.form.numberOfRows, onValueChange, { immediate: true });
</script>

<template>
  <ValidatedForm :fm="pageNumberSelectFormDef.form" />
</template>

<style lang="scss" scoped></style>
