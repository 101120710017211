import { VersionedObject } from './rest';
import { TransactionDirection, WalletAccountSource, WalletType } from './wallet';

export enum AccountType {
  CASS = 'CASS',
  OPERATIONAL = 'OPERATIONAL',
  SAFEGUARDING = 'SAFEGUARDING',
}

export enum ReconciliationType {
  FUNDING = 'FUNDING',
}

export interface ReconcilableTransactionsSenderInfo {
  reconciliationDescription?: string;
  senderAccountIban: string;
  senderAddress?: string;
  senderBic?: string;
  senderCountry?: string;
  senderName: string;
  senderRoutingCode?: string;
}

export interface ReconcilableTransaction extends ReconcilableTransactionsSenderInfo, VersionedObject {
  id: string;
  transactionId: string;
  accountId: string;
  accountDescription: string;
  accountNickname: string;
  accountIdentification: string;
  accountSecondaryIdentification: string;
  accountType: AccountType;
  amount: number;
  currency: string;
  creditDebitIndicator: TransactionDirection;
  status: string;
  bookingDateTime: string;
  valueDateTime: string;
  transactionInformation: string;
  addressLine: string;
  bankTransactionCode: string;
  bankTransactionSubCode: string;
  proprietaryBankTransactionCode: string;
  proprietaryBankTransactionCodeIssuer: string;
  accountBalanceAmount: number;
  accountBalanceCurrency: string;
  accountBalanceCreditDebitIndicator: TransactionDirection;
  accountBalanceType: string;
  merchantName: string;
  merchantCategoryCode: string;
  createdAt: string;
  updatedAt: string;
  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;
  walletId: string;
  walletType: WalletType;
  externalProvider: string;
  accountSource: WalletAccountSource;
  reconciliationType: ReconciliationType;
  reconciliationRequired: true;
  isReconciled: true;
  reconciledBy: string;
  reconciledAt: string;
}

export interface ReconcilableTransactionsFilters {
  id?: string;
  query?: string;
  queryBy?: string;
  transactionId?: string;
  accountId?: string;
  accountType?: AccountType;
  currency?: string;
  creditDebitIndicator?: TransactionDirection;
  status?: string;
  bookingDateTimeFrom?: string;
  bookingDateTimeTo?: string;
  valueDateTimeFrom?: string;
  valueDateTimeTo?: string;
  proprietaryBankTransactionCode?: string;
  accountBalanceCurrency?: string;
  accountBalanceCreditDebitIndicator?: TransactionDirection;
  accountBalanceType?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  partnerId?: string;
  clientId?: string;
  walletId?: string;
  walletType?: WalletType;
  externalProvider?: string;
  accountSource?: WalletAccountSource;
  reconciliationType?: ReconciliationType;
  senderCountry?: string;
  reconciliationRequired?: boolean;
  isReconciled?: boolean;
  reconciledBy?: string;
  reconciledAtFrom?: string;
  reconciledAtTo?: string;
}

export interface ReconcilableTransactionsRequest extends ReconcilableTransactionsSenderInfo {
  reconcilableTransactionId: string;
  walletId: string;
  reconciliationType: ReconciliationType;
}
