import { render, staticRenderFns } from "./SignatoryDetailsReview.vue?vue&type=template&id=ce69e010&scoped=true"
import script from "./SignatoryDetailsReview.vue?vue&type=script&setup=true&lang=ts"
export * from "./SignatoryDetailsReview.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SignatoryDetailsReview.vue?vue&type=style&index=0&id=ce69e010&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce69e010",
  null
  
)

export default component.exports