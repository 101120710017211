<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
  },
  noHeader: {
    type: [String, Boolean],
    default: false,
  },
  noBody: {
    type: [String, Boolean],
    default: false,
  },
});

const shouldHideHeader = computed(() => props.noHeader !== false);

const shouldHideBody = computed(() => props.noBody !== false);
</script>

<template>
  <div class="v-card card">
    <div class="v-card-header" v-if="!shouldHideHeader">
      <slot name="header"
        ><h4>{{ title }}</h4></slot
      >
    </div>
    <slot />
    <div class="v-card-body" v-if="!shouldHideBody">
      <slot name="body" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  @include themedBackgroundColor($color-card-bg);
  background-clip: border-box;
  border: 1px solid;
  @include themedBorderColor($color-card-border);
  border-radius: 8px;
}

.v-card-header {
  border-radius: 7px 7px 0 0;
  @include themedBackgroundColor($color-card-header-bg);
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  @include themedBorderColor($color-card-border);
}

.v-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
</style>
