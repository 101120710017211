import { TenorLimits, tenorLimitsToMonthCount } from 'ah-api-gateways';
import { addYears, addMonths, endOfDay, format } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from 'date-fns-tz';

export function oneYearFromNow() {
  return addYears(new Date(), 1);
}

/**
 * Returns a Date object representing the start (00:00) of the current UTC date, regardless of the local timezone
 */
export function currentUTCDate() {
  return new Date(new Date().toISOString().substring(0, 10));
}

/**
 * Strips a Date of its zone offset, converting to UTC time while ignoring location
 *
 * ATTENTION: Date is assumed to be local, and no checking is possible.
 * Running this multiple times will result in "overshooting" the time zone compensation
 */
export function stripZoneOffset(date: Date) {
  return zonedTimeToUtc(date, 'UTC');
}

/**
 * Adds local offset to a Date, converting to local time while ignoring previous location
 *
 * ATTENTION: Date is assumed to be UTC, and no checking is possible.
 * Running this multiple times will result in "overshooting" the time zone compensation
 */
export function addZoneOffset(date: Date) {
  return utcToZonedTime(date, 'UTC');
}

export function tenorToDate(tenor: TenorLimits, baseDate = new Date()) {
  return endOfDay(addMonths(baseDate, tenorLimitsToMonthCount[tenor]));
}

export const FULL_DATE_AND_TIME_FORMAT = "MMM dd, yyyy, 'at' HH:mm:ss";
export const FULL_DATE_FORMAT = 'MMM dd, yyyy';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const SIMPLE_DATE_FORMAT = 'dd-MM-yyyy';
export const SIMPLE_DATE_WITH_FULL_MONTH_FORMAT = 'dd MMMM yyyy';

export function formatDate(
  value?: string | Date,
  dateFormat: string = FULL_DATE_FORMAT,
  emptyText = '-',
  invalidDateText: string = '<Invalid Date>'
) {
  if (!value) return emptyText;
  try {
    return format(new Date(value), dateFormat);
  } catch (e) {
    return invalidDateText;
  }
}

export function formatDateInTimeZone(
  timezone: string,
  value?: string | Date,
  dateFormat: string = FULL_DATE_FORMAT,
  emptyText = '-',
  invalidDateText: string = '<Invalid Date>'
) {
  if (!value) return emptyText;
  try {
    return formatInTimeZone(new Date(value), timezone, dateFormat);
  } catch (e) {
    return invalidDateText;
  }
}
