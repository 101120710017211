import { CreateWebhookRequest, PaginatedQuery, PaginatedResponse, standardiseQuerySort, Webhook } from '../models';
import { HttpBasedService, HttpOptions, HttpRequestOptions, HttpService } from 'ah-requests';
import { Observable } from 'rxjs';

export class WebhooksService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'webhooksService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public listWebhooks(
    query?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<Webhook>>>
  ): Observable<PaginatedResponse<Webhook>> {
    return this.get(`${this.baseUrl}webhooks`, {
      axiosConfig: {
        params: query && standardiseQuerySort(query),
      },
      options,
    });
  }

  public createWebhook(createRequest: CreateWebhookRequest, options?: Partial<HttpRequestOptions<Webhook>>) {
    return this.post<Webhook>(`${this.baseUrl}webhooks/`, createRequest, options);
  }

  public getWebhook(id: string, options?: Partial<HttpRequestOptions<Webhook>>) {
    return this.get<Webhook>(`${this.baseUrl}webhooks/${id}`, options);
  }

  public deleteWebhook(id: string, options?: HttpOptions<void>) {
    return this.delete<void>(`${this.baseUrl}webhooks/${id}`, { options });
  }

  public updateWebhook(id: string, updateRequest: Partial<Webhook>, options?: Partial<HttpOptions<Webhook>>) {
    return this.put<Webhook>(`${this.baseUrl}webhooks/${id}`, updateRequest, {
      options,
    });
  }
}
