<script setup lang="ts">
import { makeValidation } from '../../../form/helpers';
import { BaseFormModel, FieldModel, FormValidation } from '../../../form/interfaces';
import useVuelidate, { NestedValidations } from '@vuelidate/core';
import DynamicForm from '../DynamicForm.vue';
import { PropType, ref, computed, watch } from 'vue';

const props = defineProps({
  fm: {
    type: Object as PropType<BaseFormModel>,
    required: true,
  },
});

const emit = defineEmits({
  'update:validation': (_value: FormValidation) => true,
});

const form = ref<InstanceType<typeof DynamicForm>>();

const rules = computed(() => ({
  fm: makeValidation(props.fm),
}));

const validation = useVuelidate<{ fm: BaseFormModel }>(
  rules,
  computed(() => ({
    fm: props.fm,
  }))
);

/** * Expanded typing for the scope of the slot,
 * * assuming that field and model are always present (must be upheld by the inner components)
 * ** TODO remove this method in favor of "as Type" directly in the template, after Vue is updated to v3*/
function expandedTyping(scope: any) {
  return scope as { field: NestedValidations; model: FieldModel } & Record<string, any>;
}
watch(
  () => validation.value.fm,
  () => emit('update:validation', validation.value.fm),
  { immediate: true }
);

defineExpose({
  validate() {
    return validation.value.$validate();
  },
  form,
});
</script>

<template>
  <DynamicForm ref="form" :form="validation.fm" v-if="validation.fm" v-on="$listeners">
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="expandedTyping(scope)" />
    </template>
  </DynamicForm>
</template>
