import { getCurrentInstance } from 'vue';
import { BvModal } from 'bootstrap-vue';

export function useBvModal() {
  const instance = getCurrentInstance()?.proxy;

  if (!instance) {
    throw 'Current instance not found! Please ensure useBvModal is called on the setup function of the component';
  }
  const bvModal = instance.$bvModal as BvModal;
  if (!instance) {
    throw "BvModal not found! Please ensure BootstrapVue's Modal plugin is installed!";
  }

  return bvModal;
}
