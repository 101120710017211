<script setup lang="ts">
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { ref } from 'vue';
import { getServices } from '@/app/services';
import { useRouter } from 'vue-router/composables';

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

const requestManager = useRequestManager().manager;

const router = useRouter();

const services = getServices();

const resendCode = ref(false);

function resendVerificationCode() {
  if (resendCode.value) {
    return;
  }
  requestManager
    .currentOrNew('resendVerification', services.registration.resendRegistrationConfirmation(props.email))
    .subscribe(
      () => {
        resendCode.value = true;
      },
      () => {
        router.push('/login');
      }
    );
}
</script>

<template>
  <div>
    <h1 class="mb-5">Email not verified</h1>
    <p>Check your {{ email }} inbox to confirm your email and finish registration.</p>
    <div class="text-center mt-5">
      <VButton
        :label="resendCode ? 'Email sent' : 'Resend verification email'"
        :disabled="resendCode"
        @click="resendVerificationCode"
        class="btn-primary"
      />
    </div>
  </div>
</template>
