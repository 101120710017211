<script setup lang="ts">
import FormattedNumber from 'ah-common-lib/src/number/FormattedNumber.vue';
import SimpleDoughnutChart from 'ah-reports/src/components/SimpleDoughnutChart.vue';
import { AuthorityType, ClientRiskLimitsProfile } from 'ah-api-gateways';
import { VButton, VCol, VRow } from 'ah-common-lib/src/common/components';
import { computed, PropType } from 'vue';
import { getChartColorsForProfileUsageLevel } from '../../helpers/chartConfig';
import { useRouter } from 'vue-router/composables';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import CreateCreditFacilityRequestModal from '../modals/createCreditFacilityRequestModal/CreateCreditFacilityRequestModal.vue';

const props = defineProps({
  clientId: {
    type: String,
  },
  isActiveMarginCall: {
    type: Boolean,
    required: true,
  },
  limitsProfileData: {
    type: Object as PropType<ClientRiskLimitsProfile>,
  },
  variationMarginPercentage: {
    type: Number,
    required: true,
  },
});

const router = useRouter();

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const ownerId = computed(
  () => props.clientId ?? onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
);

const canCreateCreditFacilityRequest = computed(() =>
  tradeState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_CREDIT_FACILITY)
);

const showCreditFacilityRequestOption = computed(
  () => !props.isActiveMarginCall && !onBehalfOfClient.value?.id && canCreateCreditFacilityRequest.value
);

const isAHUser = computed(() => tradeState.store.useAuthStore().isAHUser);

const spotsInfo = computed(() =>
  props.limitsProfileData
    ? [
        { label: 'Total Limit', value: props.limitsProfileData.notionalLimitSpots },
        { label: 'Utilised Limit', value: props.limitsProfileData.notionalLimitSpotsInUse },
        { label: 'Remaining Limit', value: props.limitsProfileData.notionalLimitSpotsRemaining },
      ]
    : []
);

const forwardsAndOptionsInfo = computed(() =>
  props.limitsProfileData
    ? [
        { label: 'Total Limit', value: props.limitsProfileData.notionalLimitForwardsOptions },
        { label: 'Utilised Limit', value: props.limitsProfileData.notionalLimitForwardsOptionsInUse },
        { label: 'Remaining Limit', value: props.limitsProfileData.notionalLimitForwardsOptionsRemaining },
      ]
    : []
);

const percentageFormatter = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function goToCreditAndMTMPage() {
  if (isAHUser.value && onBehalfOfClient.value?.id) {
    router.push(`/dashboard/trader-obo/${onBehalfOfClient.value.id}/credit-mtm`);
  } else if (props.clientId || onBehalfOfClient.value?.id) {
    router.push(`/admin/activity/clients/${onBehalfOfClient.value?.id ? 'obo/' : ''}${ownerId.value}/credit-mtm`);
  } else {
    router.push('/dashboard/trades/credit-mtm');
  }
}
</script>

<template>
  <div class="trading-limits-section">
    <template v-if="limitsProfileData">
      <VRow>
        <VCol lg="6" sm="12">
          <div class="d-flex chart-section mb-5">
            <SimpleDoughnutChart
              :colors="getChartColorsForProfileUsageLevel(limitsProfileData.notionalLimitSpotsUsage)"
              :fractions="[limitsProfileData.notionalLimitSpotsInUse, limitsProfileData.notionalLimitSpotsRemaining]"
              secondaryLabel="Utilised"
            >
              <template #mainLabel>
                <div>
                  <FormattedNumber
                    :value="limitsProfileData.notionalLimitSpotsUsage * 100"
                    :formatter="percentageFormatter"
                  />
                </div>
                <div style="font-size: medium" class="mt-2">%</div>
              </template>
            </SimpleDoughnutChart>
            <div class="chart-info">
              <h1>Spots</h1>
              <p v-for="item in spotsInfo" :key="item.label">
                {{ item.label }}:
                <FormattedNumber
                  :value="item.value"
                  class="formatted-value"
                  :prefix-label="limitsProfileData.currency"
                />
              </p>
            </div>
          </div>
        </VCol>
        <VCol lg="6" sm="12">
          <div class="d-flex chart-section mb-5">
            <SimpleDoughnutChart
              :colors="getChartColorsForProfileUsageLevel(limitsProfileData.notionalLimitForwardsOptionsUsage)"
              :fractions="[
                limitsProfileData.notionalLimitForwardsOptionsInUse,
                limitsProfileData.notionalLimitForwardsOptionsRemaining,
              ]"
              secondaryLabel="Utilised"
            >
              <template #mainLabel>
                <div>
                  <FormattedNumber
                    :value="limitsProfileData.notionalLimitForwardsOptionsUsage * 100"
                    :formatter="percentageFormatter"
                  />
                </div>
                <div style="font-size: medium" class="mt-2">%</div>
              </template>
            </SimpleDoughnutChart>
            <div class="chart-info">
              <h1>Forwards + Options</h1>
              <p v-for="item in forwardsAndOptionsInfo" :key="item.label">
                {{ item.label }}:
                <FormattedNumber
                  :value="item.value"
                  class="formatted-value"
                  :prefix-label="limitsProfileData.currency"
                />
              </p>
            </div>
          </div>
        </VCol>
      </VRow>
      <VRow class="increase-trading-limit-info" :alignH="isActiveMarginCall ? 'end' : 'between'" alignV="center">
        <span v-if="showCreditFacilityRequestOption && ownerId"
          >If you would like to increase your trading limit, please
          <CreateCreditFacilityRequestModal
            v-slot="{ showCreditFacilityModal }"
            :limitsProfileData="limitsProfileData"
            :variationMarginPercentage="variationMarginPercentage"
            :clientId="ownerId"
          >
            <a class="plain-color-link" href="#" @click="showCreditFacilityModal">click here</a>
          </CreateCreditFacilityRequestModal>
          to submit a request</span
        >
        <VButton
          class="new-xhedge-styles"
          label="View Credit Limits and Mark-to-Market"
          @click="goToCreditAndMTMPage"
          roundedBorder
        />
      </VRow>
    </template>
    <template v-else>
      <p>No Limits Profile has been provided for you yet, so trading is still not possible.</p>
      <p>
        Please contact our trading desk on <strong>+44 20 3695 1338</strong> or
        <a href="mailto:tradingdesk@alt21.com">tradingdesk@alt21.com</a>
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.trading-limits-section {
  padding: 1rem;

  .chart-section {
    h1 {
      margin-bottom: 1rem;
    }
    .chart-info {
      margin-left: 2rem;
      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  .formatted-value {
    font-weight: $font-weight-bold;

    ::v-deep {
      .number-segment {
        font-size: 16px;
      }

      .number-segment-fraction,
      .number-segment-decimal {
        font-size: 14px;
      }
    }
  }

  ::v-deep .doughnut-chart {
    width: 160px;
    height: 160px;
  }

  .increase-trading-limit-info {
    margin: 0;
  }

  @include upToResolution(1464px) {
    .increase-trading-limit-info span {
      margin-bottom: 1rem;
    }
  }
}
</style>
