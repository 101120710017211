import { FeatureFlag, FeatureFlagResponse, FeatureFlagService, FeatureFlagTreatment } from 'ah-api-gateways';
import { CachedItem } from 'ah-common-lib/src/helpers';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { defineStore } from 'pinia';
import { StoreSupportData } from 'ah-common-lib/src/store';
import { FEFeatureFlags } from 'config/baseConfig';
import Vue, { computed } from 'vue';

// Importing persisted state plugin for type completion
import 'pinia-plugin-persistedstate';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { NavigationGuard, RawLocation } from 'vue-router';

export type FeatureFlagStoreSupportData = StoreSupportData<
  {},
  {
    featureFlag: FeatureFlagService;
  }
>;

export interface FeatureFlagStoreSetupOptions {
  supportData: FeatureFlagStoreSupportData;
}

export interface FeatureFlagLoadOptions {
  featureFlag: FeatureFlag;
  oboClientId?: string;
  force?: boolean;
}

export interface UseFeatureFlagOptions extends FeatureFlagLoadOptions {
  skipPreload?: boolean;
}

let sd: FeatureFlagStoreSupportData;

const getFeatureFlagKey = (featureFlag: FeatureFlag, oboClientId?: string) =>
  oboClientId ? `${featureFlag}-${oboClientId}` : featureFlag;

const useStore = defineStore('featureFlagStore', {
  persist: true,
  state: () => {
    return {
      featureFlags: {} as { [key: string]: CachedItem<FeatureFlagResponse> },
      /**
       * FE feature flags
       *
       * These are determined by application configuration (but overridable via Pinia store for testing purposes)
       */
      feFeatureFlagOverrideArr: [] as { key: keyof FEFeatureFlags; state: boolean }[],
    };
  },
  getters: {
    /**
     * Whether feature flag is loaded
     */
    isFeatureFlagLoaded() {
      return (featureFlag: FeatureFlag, oboClientId?: string) =>
        !!this.featureFlags[getFeatureFlagKey(featureFlag, oboClientId)]?.item;
    },
    /**
     * Whether feature flag is on. Defaults to false if unloaded
     */
    isFeatureFlagOn() {
      return (featureFlag: FeatureFlag, oboClientId?: string) =>
        this.featureFlags[getFeatureFlagKey(featureFlag, oboClientId)]?.item?.treatment === FeatureFlagTreatment.ON;
    },
    /**
     * Whether a FE feature flag is on. Defaults to false if unloaded
     */
    isFEFeatureFlagOn() {
      return (featureFlag: keyof FEFeatureFlags) =>
        this.feFeatureFlagOverrideArr.find((i) => i.key === featureFlag)?.state ?? sd.config[featureFlag];
    },
  },
  actions: {
    loadFeatureFlag(payload: FeatureFlagLoadOptions): Promise<FeatureFlagResponse | null> {
      const key = getFeatureFlagKey(payload.featureFlag, payload.oboClientId);

      if (!this.featureFlags[key]) {
        Vue.set(this.featureFlags, key, new CachedItem<FeatureFlag>(key, MINUTE * 15));
      }

      return CachedItem.loadCachedItem(
        this.featureFlags[key],
        sd.s.featureFlag.getfeatureFlag({ featureFlag: payload.featureFlag }, payload.oboClientId, {
          errors: { silent: true },
        }),
        payload?.force
      );
    },
    setFEFeatureFlag(payload: { key: keyof FEFeatureFlags; state: boolean }) {
      if (!sd.config.showDevTools) {
        throw 'Customizing feature flags is not allowed';
      }
      const item = this.feFeatureFlagOverrideArr.find((i) => i.key === payload.key);
      if (item) {
        item.state = payload.state;
      } else {
        this.feFeatureFlagOverrideArr.push({ ...payload });
      }
    },
    unsetFEFeatureFlag(key: keyof FEFeatureFlags) {
      if (!sd.config.showDevTools) {
        throw 'Customizing feature flags is not allowed';
      }
      this.feFeatureFlagOverrideArr = this.feFeatureFlagOverrideArr.filter((i) => i.key !== key);
    },
    clearStore() {
      this.featureFlags = {};
    },
    async [commonStoreActions.onOutMaintenance]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogout]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogoutOtherTab]() {
      this.clearStore();
    },
    async [commonStoreActions.afterSetup]() {
      if (!sd.config.showDevTools) {
        this.feFeatureFlagOverrideArr = [];
      }
    },
  },
});

export type FeatureFlagStore = ReturnType<typeof useStore>;

export function setupFeatureFlagStore(options: FeatureFlagStoreSetupOptions) {
  sd = options.supportData;
}

export function useFeatureFlagStore(): FeatureFlagStore {
  if (!sd) {
    throw 'Feature flag store not setup! Please ensure setup method is called on app boot.';
  }
  return useStore();
}

export function useFeatureFlag(options: UseFeatureFlagOptions) {
  const store = useFeatureFlagStore();
  if (!options.skipPreload) {
    store.loadFeatureFlag(options);
  }

  return {
    isActive: computed(() => store.isFeatureFlagOn(options.featureFlag, options.oboClientId)),
    isLoaded: computed(() => store.isFeatureFlagLoaded(options.featureFlag, options.oboClientId)),
    loadFlag: (force?: boolean) => store.loadFeatureFlag({ ...options, force: force || options.force }),
  };
}

export function useFEFeatureFlag(key: keyof FEFeatureFlags) {
  const store = useFeatureFlagStore();
  return computed(() => store.isFEFeatureFlagOn(key));
}

export function featureFlagRouteGuard(
  options: FeatureFlagLoadOptions & { requireOff?: boolean; fallback?: RawLocation }
): NavigationGuard {
  return (_to, _from, next) => {
    const store = useFeatureFlagStore();
    store.loadFeatureFlag(options).then((flag) => {
      const matchesRequirement = (flag?.treatment === FeatureFlagTreatment.ON) !== !!options.requireOff;
      if (matchesRequirement) {
        next();
      } else {
        next(options.fallback || false);
      }
    });
  };
}
