import { Point } from 'chart.js';
import { addDays } from 'date-fns';

/**
 * Zero-fill dates on a data set (for data presentations where required)
 * @param source Set of {x, y} filled data points
 */
export function fillDates(source: { x: Date; y: number }[], fromDate?: Date, toDate?: Date): Point[] {
  const out: Point[] = [];
  let date = fromDate ?? new Date(source[0].x as Date);
  const finalDate = toDate ?? new Date(source[source.length - 1].x as Date);

  for (let i = 0; i < source.length; i++) {
    const dataPoint = source[i];
    const dataPointDate = dataPoint.x as Date;
    while (date < dataPointDate && date <= finalDate) {
      out.push({
        x: date.toISOString() as any,
        y: 0,
      });
      date = addDays(date, 1);
    }
    if (date <= finalDate) {
      out.push({
        x: date.toISOString() as any,
        y: dataPoint.y,
      });
      date = addDays(dataPointDate, 1);
    }
  }
  while (date <= finalDate) {
    out.push({
      x: date.toISOString() as any,
      y: 0,
    });
    date = addDays(date, 1);
  }
  return out;
}
