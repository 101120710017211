import _Vue, { PluginObject } from 'vue';
import { TabSync } from './tabSync';

let tabSync: TabSync | null = null;

export default {
  install: function install(Vue: typeof _Vue) {
    tabSync = new TabSync();

    Vue.tabSync = tabSync;
    Vue.prototype.$tabSync = tabSync;
  },
} as PluginObject<void>;

export function useTabSync() {
  if (!tabSync) {
    throw 'TabSync not initialized! Plugin not installed before tabSync is called!';
  }

  return tabSync;
}
