<script setup lang="ts">
import AppLogo from '@/app/components/common/AppLogo.vue';
import ClientResponsiveNav from '@/app/components/nav/ClientResponsiveNav.vue';
import AdminResponsiveNav from '@/app/components/nav/AdminResponsiveNav.vue';
import { useAuthStore } from '@/app/store/authStore';
import { computed } from 'vue';

const authStore = useAuthStore();

const isClientUser = computed(() => authStore.isClientUser);
</script>

<template>
  <div class="sidebar-wrapper">
    <div class="sidebar">
      <div class="logo">
        <AppLogo class="logo-inner" />
      </div>
      <ClientResponsiveNav v-if="isClientUser" class="main-menu" />
      <AdminResponsiveNav v-else class="main-menu" />
    </div>
    <div class="inner-sidebar">
      <RouterView name="sidebar" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;

  .sidebar {
    height: 100%;
    width: $side-nav-width;
    position: relative;

    @include themedBackgroundColor($color-sidebar-bg);
    @include themedTextColor($color-primary, $color-dark-primary);
    @include themedPropColor('border-right', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');

    display: grid;
    grid-template-rows: max-content auto max-content;

    .logo {
      width: $side-nav-width;
      height: $side-nav-width;
      margin-bottom: 1rem;
      position: relative;

      .logo-inner {
        width: $side-nav-width - 16px;
        height: $side-nav-width - 16px;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }

    .main-menu {
      width: 100%;
      overflow: hidden;

      ::v-deep ul {
        .menu-link {
          width: 100%;
          overflow: hidden;
          text-align: center;
          padding-top: 0.5rem;
          margin-bottom: $side-nav-width * 0.2;
          cursor: pointer;
          min-height: $side-nav-width * 0.8;

          svg {
            width: 60%;
            height: $side-nav-width * 0.5;
            position: relative;
            top: -3%;
          }

          .menu-link-text {
            font-size: 0.5rem;
            line-height: 1.2em;
            font-weight: 400;
          }

          &:hover:not(.router-link-active) {
            @include themedBackgroundColor($color-highlight-grey);
            @include themedPropColor('border-right', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');
            @include themedTextColor($color-primary, $color-dark-primary);

            svg path {
              @include themedPropColor('fill', $color-primary, $color-dark-primary);
            }
          }

          &.router-link-active {
            @include themedBackgroundColor($color-primary, $color-dark-primary);
            color: white;
            font-weight: bold;

            ::v-deep svg path {
              fill: white;
            }
          }
        }
      }
    }
  }

  .inner-sidebar {
    width: $side-inner-nav-width;
    padding-top: $top-nav-height;

    @include themedPropColor('border-right', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');

    &:empty {
      display: none;
    }

    ::v-deep {
      .menu-header {
        text-transform: uppercase;
        padding: 1em;
        padding-bottom: 0.5em;
        font-weight: 800;
        opacity: 0.5;
        @include themedTextColor;
      }

      ul {
        list-style: none;
        padding: 0;

        .menu-link {
          width: 100%;
          padding: 0.3em 1em;
          margin-bottom: 0.2em;
          font-size: 1em;
          line-height: 1.5em;
          cursor: pointer;

          &:hover:not(.router-link-active) {
            @include themedBackgroundColor($color-highlight-grey);
            @include themedPropColor('border-right', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');
            @include themedTextColor($color-primary, $color-dark-primary);

            ::v-deep svg path {
              @include themedPropColor('fill', $color-primary, $color-dark-primary);
            }
          }

          &.router-link-active {
            @include themedBackgroundColor($color-primary, $color-dark-primary);
            color: white;

            ::v-deep svg path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
</style>
