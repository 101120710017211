import { UpdatableObject } from '../rest';

export enum PremiumPaymentType {
  UPFRONT = 'UPFRONT',
  MONTHLY = 'MONTHLY',
}

export function isPaidPremiumToHumanReadable(isPaid: boolean) {
  return isPaid ? 'Paid' : 'Scheduled';
}

export function isPaidPremiumToHumanReadableForClient(isPaid: boolean) {
  return isPaid ? 'Paid' : '-';
}

export interface ConfirmPremiumPaid {
  id: string;
  isPaid: boolean;
}

export interface PremiumPaymentSchedule {
  currency: string;
  amount: number;
  date: string;
}

export interface PremiumPaymentResponse extends PremiumPaymentSchedule, Partial<UpdatableObject> {
  id: string;
  tradeId: string;
  isPaid: boolean;
}

export const premiumSettlementTypeToHumanReadable: Record<PremiumPaymentType, string> = {
  [PremiumPaymentType.UPFRONT]: 'Pay Now',
  [PremiumPaymentType.MONTHLY]: 'Pay Later',
};

export function getVanillaOptionPremiumDescription(premiumPaymentType: PremiumPaymentType) {
  return `A Vanilla Option is a foreign exchange contract between two parties that grants the purchaser of the option protection, but not obligation to transact, on a Notional amount at the Strike rate on a Settlement date in exchange for ${
    premiumPaymentType === PremiumPaymentType.UPFRONT ? 'an up-front' : 'a'
  } premium.`;
}

export function getVanillaOptionPremiumAdvantages(premiumPaymentType: PremiumPaymentType) {
  if (premiumPaymentType === PremiumPaymentType.UPFRONT) {
    return [
      'Provides full protection on the notional amount against adverse movements beyond the strike rate on the settlement date',
      'Ability to benefit from favourable movements beyond the strike rate on settlement date',
      'Ability to select a desired strike rate, notional amount and settlement date to set against your commercial requirement',
      'A non-refundable premium is payable on the premium settlement date, typically two days after booking',
    ];
  }

  return [
    'Provides full protection on the notional amount against adverse movements beyond the strike rate on the settlement date',
    'Ability to benefit from favourable movements beyond the strike rate on settlement date',
    'Ability to select a desired strike rate, notional amount and settlement date to set against your commercial requirement',
  ];
}
export function getVanillaOptionPremiumDisadvantages(premiumPaymentType: PremiumPaymentType) {
  if (premiumPaymentType === PremiumPaymentType.UPFRONT) {
    return ['Premium must be paid upfront'];
  }

  return [
    'If your vanilla option is cancelled, the total remainder of your premium will be payable within 2 business days.',
  ];
}
