import _Vue, { PluginObject } from 'vue';
import TooltipComponent from './components/TooltipComponent.vue';
import { ValidationArgs } from '@vuelidate/core';

import * as Components from './components';
import { FieldOptions, FormOptions } from './interfaces';

declare module 'vue/types/options' {
  interface ComponentOptions<V extends _Vue> {
    validations?: ValidationArgs;
  }
}

export interface FormPluginOptions {
  defaultState: {
    form?: Partial<FormOptions>;
    fieldCommon?: Partial<FieldOptions>;
    fields?: Record<string, FieldOptions>;
  };
}

export let formPluginOptions: FormPluginOptions = {
  defaultState: {},
};

const pluginOptionDefaults: FormPluginOptions = {
  defaultState: {
    fieldCommon: {
      tooltipComponent: TooltipComponent,
    },
  },
};

export default {
  install: function install(Vue: typeof _Vue, options?: FormPluginOptions) {
    Object.keys(Components).forEach((key) => Vue.component(key, (Components as any)[key]));

    formPluginOptions = {
      defaultState: {
        ...pluginOptionDefaults.defaultState,
        ...options?.defaultState,
        fieldCommon: {
          ...pluginOptionDefaults.defaultState.fieldCommon,
          ...options?.defaultState.fieldCommon,
        },
      },
    };
  },
} as PluginObject<FormPluginOptions>;
