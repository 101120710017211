import _Vue, { PluginObject } from 'vue';
import { MediaQueries } from 'ah-common-lib/src/mediaQuery';
import {
  ClientService,
  TradeService,
  WalletService,
  RateService,
  CustomerReferenceService,
  PartnerAdminService,
} from 'ah-api-gateways';

declare module 'vue/types/vue' {
  interface Vue {
    $ahReportsState: AhReportsState;
  }
}

export interface AhReportsPluginOptions {
  state: AhReportsState;
}

export interface AhReportsState {
  mediaQuery: MediaQueries;
  services: {
    trade: TradeService;
    client: ClientService;
    wallet: WalletService;
    rates: RateService;
    customer: CustomerReferenceService;

    // Only avaliable in admin settings
    partner?: PartnerAdminService;
  };
}

export const AhReportsPlugin = {
  install: function install(Vue: typeof _Vue, options: AhReportsPluginOptions) {
    const culledState: AhReportsState = {
      mediaQuery: options.state.mediaQuery,
      services: options.state.services,
    };

    Vue.prototype.$ahReportsState = new _Vue({
      data: culledState,
    });
  },
} as PluginObject<AhReportsPluginOptions>;

export function useAhReportsState() {
  return _Vue.prototype.$ahReportsState as AhReportsState;
}

export * from './components';
export * from './helpers';
export * from './models';
