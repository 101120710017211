<script setup lang="ts">
import { useAuthStore } from '@/app/store/authStore';
import { useRoute, useRouter } from 'vue-router/composables';
import { AuthorityType } from 'ah-api-gateways';
import { computed, ref } from 'vue';
import DownloadPartnerCommissionsStatementModal from 'ah-trades/src/components/modals/DownloadPartnerCommissionsStatementModal.vue';
import SearchableTradeReportList from 'ah-trades/src/components/lists/SearchableTradeReportList.vue';

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const openTrade = ref<string>();

const canSeeExport = computed(() => authStore.hasAuthorities(AuthorityType.EXPORT_PARTNER_COMMISSION_REPORTS));
const isActivityReports = computed(() => route.path.startsWith('/admin/activity/reports'));

if (route.query.trade) {
  const { trade, ...query } = route.query;
  router.replace({ path: route.path, query });
  openTrade.value = trade as string;
}
</script>

<template>
  <div class="padded-space" id="activity-reports-and-statements-view">
    <div :class="{ 'centered-space': !isActivityReports }">
      <div class="wrapper">
        <div class="title"><h2>Trade and Commission Report</h2></div>
        <div v-if="canSeeExport">
          <DownloadPartnerCommissionsStatementModal v-slot="{ showModal }">
            <VButton @click="showModal" offset="1" class="mr-3">Download Statement</VButton>
          </DownloadPartnerCommissionsStatementModal>
        </div>
      </div>
      <SearchableTradeReportList :showExport="canSeeExport" :openTrade="openTrade" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  .title {
    flex: 1;
  }
}
</style>
