<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { BModal } from 'bootstrap-vue';
import OtpRequestForm from 'ah-common-lib/src/otp/OtpRequestForm.vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { emailField } from 'ah-common-lib/src/form/models';
import config from '@/config';
import { SecurityErrorCodes } from 'ah-api-gateways';
import { mergeMap } from 'rxjs/operators';
import { helpers, email } from '@vuelidate/validators';
import { requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import type { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';
import { ValidatedForm } from 'ah-common-lib/src/form/components';

/**
 * Email change component
 * This component will:
 * - Display the current email, as defined in the logged in user
 * - Show a warning if the user has changed but not validated a new email
 */

const state = ref<'prechange' | 'postchange'>('prechange');
const otp = ref('');
const otpValidation = ref<FormValidation>();
const changeModal = ref<InstanceType<typeof BModal>>();
const changeOtpRequest = ref<InstanceType<typeof OtpRequestForm>>();

const authStore = useAuthStore();
const services = getServices();
const toast = useToast();
const requestManager = useRequestManager().manager;

const changeContactsFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'changeEmailForm',
    fieldType: 'form',
    fields: [
      emailField(
        'email',
        'New email',
        {
          required: true,
          errorMessages: { differentFrom: 'Cannot be the same as the current email' },
        },
        {
          differentFrom: helpers.withParams({ type: 'differentFrom' }, (val: any) => val !== currentEmail.value),
          email,
          required: requiredIfStateValue('email'),
        }
      ),
    ],
  }),
  validation: null,
});

const anyInvalid = computed(() => {
  if (!otpValidation.value || !changeContactsFormDef.validation) {
    return true;
  }
  return otpValidation.value.$invalid || changeContactsFormDef.validation.$invalid;
});

const isNewEmailInvalid = computed(() => {
  if (!changeContactsFormDef.validation) {
    return true;
  }
  return changeContactsFormDef.validation.$invalid;
});

const currentEmail = computed(() => authStore.loggedInUser?.email ?? '');
const currentPhoneNumber = computed(() => authStore.loggedInUser?.phoneNumber ?? '');
const currentPhoneLastDigits = computed(() =>
  currentPhoneNumber.value.substring(Math.max(0, currentPhoneNumber.value.length - 3))
);
const newEmail = computed(() => authStore.loggedInUser?.secondaryEmail ?? '');
const staticOTP = computed(() => config.staticOTP);
const tfaEnabled = computed(() => !!authStore.userData?.tfaEnabled);

const changeEmail = () => {
  let request = services.account.changeContacts(
    { email: changeContactsFormDef.form.email },
    tfaEnabled.value ? otp.value : undefined,
    {
      errors: { silent: true },
    }
  );
  requestManager.sameOrCancelAndNew('changeEmail', request.pipe(mergeMap(() => services.auth.getSession()))).subscribe(
    (session) => {
      authStore.setTokenUser(session);
      if (session.secondaryEmail) {
        state.value = 'postchange';
      } else {
        if (changeContactsFormDef.form.email === session.email) {
          toast.success('Email changed successfully');
        } else {
          toast.error('Email change failed. Please try again later.');
        }
        closeChangeModal();
      }
    },
    (e) => {
      if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(e.response?.data?.code)) {
        changeOtpRequest.value?.setOtpError(
          e.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
        );
      } else if (e.response?.status === 409 || e.response?.status === 400) {
        toast.info(e.response.data.message);
      } else {
        toast.error('An unexpected problem has occurred. Please try again later.');
      }
    }
  );
};

const resendEmail = () => {
  requestManager
    .currentOrNew(
      'resendEmail',
      services.account.resendEmailForChange({
        errors: { silent: true },
      })
    )
    .subscribe(
      () => toast.success('Resent verification email'),
      () => toast.error('Verification email failed to send')
    );
};

const refreshOtpRequest = () => services.auth.refreshOtp();

const closeChangeModal = () => changeModal.value?.hide();

const onChangeModalHidden = () => {
  otpValidation.value = undefined;
};

const openChangeModal = () => {
  state.value = 'prechange';
  otp.value = '';
  changeModal.value?.show();
};

defineExpose({
  changeModal,
  changeOtpRequest,
});
</script>

<template>
  <div class="email-change">
    <div class="field-title mb-1">Email</div>
    <div class="email">{{ currentEmail }}</div>
    <div class="link">
      <a class="text-secondary change-link text-small" @click="openChangeModal">Change email</a>
    </div>
    <div class="text-small pending-email" v-if="newEmail">
      <div class="icon">
        <IconDangerSign />
      </div>
      <div class="text">You have not verified your new email address<br />{{ newEmail }}.</div>
      <div class="text-secondary">Please check your spam or junk folder</div>
      <div class="link">
        <a class="text-secondary change-link" @click="resendEmail">
          {{ requestManager.requestStates.resendEmail === 'pending' ? 'Sending...' : 'Resend verification email' }}
        </a>
      </div>
    </div>
    <BModal
      modal-class="change-modal"
      title="Change account email"
      ref="changeModal"
      hide-footer
      @hidden="onChangeModalHidden"
    >
      <div v-if="state === 'prechange'">
        <div class="mb-3">
          <div class="field-title mb-1">Email</div>
          <div class="email">{{ currentEmail }}</div>
        </div>
        <ValidatedForm
          :fm="changeContactsFormDef.form"
          :validation.sync="changeContactsFormDef.validation"
          class="mb-3 phone-form"
        />
        <OtpRequestForm
          ref="changeOtpRequest"
          class="mb-5"
          title="For security reasons, before confirming your new
        email we must verify your request."
          :otp.sync="otp"
          :staticOTP="staticOTP"
          :otpPhone="currentPhoneLastDigits"
          :refreshOtpRequest="refreshOtpRequest"
          :validation.sync="otpValidation"
          :forbidOtpRequest="isNewEmailInvalid"
          v-if="tfaEnabled"
        />
        <p class="text-center">
          <VButton @click="closeChangeModal" class="btn-stroked mr-3">Cancel</VButton>
          <VButton :loading="requestManager.anyPending" :disabled="anyInvalid" @click="changeEmail">Confirm</VButton>
        </p>
      </div>
      <div v-else class="post-change">
        <p class="text-center">
          <IconMail class="mail-icon" />
        </p>
        <h2 class="text-center">Please confirm your email address</h2>
        <p class="text-center">
          We sent a verification to<br />
          <span class="purple">
            {{ newEmail }}
          </span>
        </p>
        <p class="text-center">Please click the verification link in the email to confirm your new email address.</p>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.email {
  word-wrap: break-word;
}

.change-link {
  text-decoration: underline;
}

.pending-email {
  margin-top: 0.3em;
  position: relative;

  .text,
  .icon {
    color: getColor($color-danger);
  }

  .text,
  .text-secondary,
  .change-link {
    padding-left: 2em;
  }

  .icon {
    position: absolute;
    left: 0;
    top: -0.15em;
  }
}

.post-change {
  .mail-icon {
    font-size: 4em;
  }
}
</style>
