<script setup lang="ts">
import { radioField } from 'ah-common-lib/src/form/models';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { FormValidation, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { FeeChargeType } from 'ah-api-gateways';
import { computed, reactive } from 'vue';

/**
 * Charge type form
 *
 * Emits:
 * - update:chargeType: Emitted when `chargeType` changes, on form input. If none is selected, `null` will be emitted. Can be used with .sync (prop is ignored)
 */

/**
 * Possible set default charge type
 */
const props = withDefaults(
  defineProps<{
    chargeType: FeeChargeType | null;
  }>(),
  {
    chargeType: null,
  }
);

const emit = defineEmits<{
  (e: 'update:chargeType', value: FeeChargeType): void;
}>();

const swiftFeeFormDef = reactive({
  form: makeFormModel({
    name: 'switfFeeType',
    fieldType: 'form',
    fields: [
      radioField(
        'type',
        '',
        [
          { label: 'ACO', value: 'ACO' },
          { label: 'SHA', value: 'SHA' },
        ],
        {
          required: true,
          inline: true,
          defaultValue: props.chargeType ?? FeeChargeType.ACO,
          fieldWrapperClass: 'swift-fee-form col col-12 mb-1',
        }
      ),
    ],
  }),
  validation: null as FormValidation | null,
});

const valid = computed(() => swiftFeeFormDef.validation && !swiftFeeFormDef.validation.$invalid);

function touchForms() {
  if (swiftFeeFormDef.validation) {
    submitForm(swiftFeeFormDef.validation);
  }
}

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    emit('update:chargeType', swiftFeeFormDef.form.type);
  }
}

emit('update:chargeType', swiftFeeFormDef.form.type);

defineExpose({ valid: valid.value, touchForms });
</script>

<template>
  <ValidatedForm :fm="swiftFeeFormDef.form" :validation.sync="swiftFeeFormDef.validation" @form-event="onFormEvent">
    <template #switfFeeType.type:label> Swift Fee Type <InfoTooltip :text="$t('tooltips.swiftFeeType')" /> </template>
  </ValidatedForm>
</template>

<style lang="scss" scoped>
::v-deep .swift-fee-form {
  .custom-control {
    margin-right: 6rem;
  }
}
</style>
