<script setup lang="ts">
import { PaginatedQuery, AgentCommission } from 'ah-api-gateways';
import { agentCommissionTableFields } from './tableFields/commissionTableFields';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: agentCommissionTableFields }),
  },
  ...defineUseManagedListingProps<AgentCommission>(),
});

interface AgentCommissionEmits extends UseManagedListingEmits<AgentCommission> {}

const emit = defineEmits<AgentCommissionEmits>();

const requestManager = useRequestManager();

const services = getServices();

const managedListing = useManagedListing<AgentCommission>({
  loadDataRequest(query: PaginatedQuery) {
    return services.trade.listAgentCommissions(query);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

const currency = computed(
  () => (props.tableData?.list && props.tableData.list.length > 0 && props.tableData.list[0].currency) || 'GBP'
);
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    nowrap
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(totalPartnerProfit)="{ item }">
      {{ formatCurrencyValue(item.totalPartnerProfit) }}
    </template>
    <template #headInner(totalPartnerProfit)>
      Commission <span class="text-small">({{ currency }} Equivalent)</span>
    </template>
    <template #cell(assignee)="{ item }">
      <div v-if="item.assignee">{{ item.assignee.name }}</div>
      <div v-else>Unassigned</div>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.edit-icon-block {
  cursor: pointer;

  .edit-icon {
    @include themedTextColor($color-text-secondary);

    &:not(.disabled):hover {
      @include themedTextColor($color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
</style>
