<script setup lang="ts">
import { WizardConfig } from 'ah-common-lib/src/models/progress';

const props = defineProps<{
  config: WizardConfig;
}>();

const visibleSteps = props.config.steps.filter((step) => !step.hiddenFromNavigation);
</script>

<template>
  <VRow x-test-name="registration-progress-flow">
    <VCol class="steps-container" v-for="(step, index) in visibleSteps" :key="step.key">
      <div
        class="card-block step-wrapper"
        :class="{ active: index === config.active, valid: step.state === 'valid', invalid: step.state === 'invalid' }"
        v-if="!step.hiddenFromNavigation"
      >
        <div class="step-count">Step {{ index + 1 }} of {{ visibleSteps.length }}</div>
        <div class="step-title">{{ step.title }}</div>
      </div>
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.steps-container {
  @include upToResolution($tabletResolution) {
    font-size: $font-size-sm;
    white-space: nowrap;
  }

  .step-wrapper {
    display: grid;
    border-radius: 0.25em;
    grid-template-rows: min-content 1fr;
    height: 100%;
    text-align: center;
    padding: 0.5em 0;
    @include themedTextColor($color-primary);

    &.invalid {
      outline: 1px solid;
      @include themedPropColor('outline', $color-danger, $color-danger);
      @include themedPropColor($color-danger, $color-dark-danger);
    }

    &.active,
    &.valid {
      @include themedBackgroundColor($color-primary);
      color: white;
    }

    &.valid {
      opacity: 0.5;
    }

    &.active {
      opacity: 1;
    }

    .step-count {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
    }

    .step-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.2rem;
      font-size: $font-size-sm;
      font-weight: $font-weight-semibold;
    }
  }
}
</style>
