import { PaginatedQuery, PaginatedResponse, NotificationSetting } from '../models';
import { HttpBasedService, HttpService } from 'ah-requests';

export class NotificationSettingsService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'notificationSettingsService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public getNotificationSetting(notificationId: string, parentId?: string) {
    return this.get<NotificationSetting>(
      `${this.baseUrl}${parentId ? parentId + '/' : ''}settings/notifications/${notificationId}`
    );
  }

  public editNotificationSetting(
    notification: Partial<NotificationSetting> & Pick<NotificationSetting, 'id'>,
    parentId?: string
  ) {
    return this.put<NotificationSetting>(
      `${this.baseUrl}${parentId ? parentId + '/' : ''}settings/notifications/${notification.id}`,
      notification
    );
  }

  public createNotificationSetting(notification: Partial<Omit<NotificationSetting, 'id'>>, parentId?: string) {
    return this.post<NotificationSetting>(
      `${this.baseUrl}${parentId ? parentId + '/' : ''}settings/notifications`,
      notification
    );
  }

  public deleteNotificationSetting(notificationId: string, parentId?: string) {
    return this.delete<void>(
      `${this.baseUrl}${parentId ? parentId + '/' : ''}settings/notifications/${notificationId}`
    );
  }

  public listNotificationSettings(query: PaginatedQuery & { type?: string }, parentId?: string) {
    return this.get<PaginatedResponse<NotificationSetting>>(
      `${this.baseUrl}${parentId ? parentId + '/' : ''}settings/notifications`,
      {
        axiosConfig: {
          params: query,
        },
      }
    );
  }
}
