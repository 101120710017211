<script setup lang="ts">
import { TradeState, tradeStateBadges } from 'ah-api-gateways';
import { computed, PropType } from 'vue';

const props = defineProps({
  state: {
    type: String as PropType<TradeState>,
    default: '',
  },
});

const stateBadge = computed(() => tradeStateBadges[props.state] || {});
</script>

<template>
  <VBadge :label="stateBadge.label || state" :class="stateBadge.class" />
</template>
