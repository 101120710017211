<script setup lang="ts">
import { BaseIndividual, ClientFileCategories, UploadedFile } from 'ah-api-gateways';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { useAuthStore } from '@/app/store/authStore';
import { tap } from 'rxjs/operators';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { cloneDeep } from 'lodash';
import { computed, onBeforeMount, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

/**
 * UBO Documents editor
 * Can work in "auto-upload" mode or in select-only mode ("auto-upload" prop will be sent over to the FileUploader child components)
 *
 * Emits:
 * - update:files - .sync-able event to relay updates to the currently uploaded files.
 * - file-selected (payload: {file: File | null, category: ClientFileCategories}) - emitted when file is selected and ready to be uploaded or null if cancelled.
 */

const props = defineProps({
  ubo: { type: Object as PropType<BaseIndividual>, required: true },
  files: { type: Array as PropType<UploadedFile[]> },
});

const emit = defineEmits({
  'update:files': (_files: UploadedFile[]) => true,
  'file-selected': (_file: { file: File | null; category: ClientFileCategories }) => true,
});

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const individualSettingsStore = useIndividualSettingsStore();

const innerFiles = ref<UploadedFile[]>([]);

const clientId = computed(() => authStore.loggedInIdentity!.client!.id);

const photoId = computed(
  () => innerFiles.value.find((document) => document.category === ClientFileCategories.PHOTO_ID) || null
);

const proofOfAddress = computed(
  () => innerFiles.value.find((document) => document.category === ClientFileCategories.PROOF_OF_ADDRESS) || null
);

onBeforeMount(() => {
  if (!props.files) {
    requestManager
      .sameOrCancelAndNew(
        'getUboDocuments',
        services.compliance.getUboDocuments(clientId.value, props.ubo.id!, {
          errors: { silent: true },
        })
      )
      .subscribe((response) => {
        innerFiles.value = response;
        emit('update:files', response);
      });
  }
});

function onFilesChange() {
  if (props.files) {
    innerFiles.value = cloneDeep(props.files);
  }
}

watch(() => props.files, onFilesChange, { immediate: true });

function uploadFile(file: File, category: ClientFileCategories) {
  return services.compliance.submitUboDocument(clientId.value, props.ubo.id!, category, file).pipe(
    tap((update) => {
      if (update.finished) {
        services.compliance.getUboDocuments(clientId.value, props.ubo.id!).subscribe((docs) => {
          const document = docs.find((d) => d.id === update.file.id);

          if (document) {
            const currFileIndex = innerFiles.value.findIndex((i) => i.category === document.category);
            currFileIndex > -1 ? innerFiles.value.splice(currFileIndex, 1, document) : innerFiles.value.push(document);
          }

          // update ubo file status in store
          individualSettingsStore.loadClientUbos(true);
        });
      }
    })
  );
}

function deleteFile(file: UploadedFile) {
  return services.compliance.removeUboDocument(clientId.value, props.ubo.id!, file.id).pipe(
    tap(() => {
      const currFileIndex = innerFiles.value.findIndex((i) => i.category === file.category);
      if (currFileIndex > -1) {
        innerFiles.value.splice(currFileIndex, 1);
      }

      // update ubo file status in store
      individualSettingsStore.loadClientUbos(true);
    })
  );
}

function downloadFile(file: UploadedFile) {
  return services.compliance.downloadUboSyncDocument(clientId.value, props.ubo.id!, file);
}

function uploadID(file: File) {
  return uploadFile(file, ClientFileCategories.PHOTO_ID);
}

function uploadPOA(file: File) {
  return uploadFile(file, ClientFileCategories.PROOF_OF_ADDRESS);
}
</script>

<template>
  <VRow>
    <VCol>
      <FileUploader
        title="Proof of ID"
        :maxSize="10485760"
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        description="Clear, colour copy of driving license or passport (in date, with all information legible)"
        :uploadRequest="uploadID"
        :uploaded="photoId"
        :deleteRequest="deleteFile"
        :downloadRequest="downloadFile"
        :loading="requestManager.anyPending"
        @update:selected="$emit('file-selected', { file: $event, category: ClientFileCategories.PHOTO_ID })"
        v-bind="$attrs"
      />
    </VCol>
    <VCol>
      <FileUploader
        title="Proof of Address"
        :maxSize="10485760"
        description="Clear, colour copy of a utility bill (must be dated within the last 3 months)."
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :uploadRequest="uploadPOA"
        :uploaded="proofOfAddress"
        :deleteRequest="deleteFile"
        :downloadRequest="downloadFile"
        :loading="requestManager.anyPending"
        @update:selected="$emit('file-selected', { file: $event, category: ClientFileCategories.PROOF_OF_ADDRESS })"
        v-bind="$attrs"
      />
    </VCol>
  </VRow>
</template>
