<script setup lang="ts">
import {
  Individual,
  IndividualGroup,
  IndividualType,
  clientUserIndividualTypes,
  partnerUserIndividualTypes,
  ComplianceStatus,
} from 'ah-api-gateways';
import PermissionsEditor from '../settings/PermissionsEditor.vue';
import { mergeMap } from 'rxjs/operators';
import { waitForEntityDeletion, waitForCQRSEntityChange } from 'ah-requests';
import { useAuthStore } from '@/app/store/authStore';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useToast } from 'ah-common-lib/src/toast';

/**
 * Information and actions on an individual
 *
 * Emits:
 * - individual-deleted, with individual id
 * - individual-reinvited, with individual id
 * - individual-updated, with individual object
 */

const authStore = useAuthStore();

const services = getServices();

const toast = useToast();

const props = defineProps({
  individualId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits({
  'individual-updated': (_value: Individual) => true,
  'individual-reinvited': (_value: String) => true,
  'individual-deleted': (_value: String) => true,
});

const individual = ref<Individual>();
const individualGroups = ref<IndividualGroup[]>([]);

onBeforeMount(() => {
  authStore.loadComplianceCase();
});

const requestManager = useRequestManager({
  exposeToParent: ['loadIndividual', 'loadIndividualGroups', 'updateIndividual', 'reinviteIndividual'],
  onRetryFromParentManager(k: string) {
    if (k === 'loadIndividual') {
      onIndividualIdChange();
    } else if (k === 'loadIndividualGroups') {
      onIndividualChange();
    } else if (k === 'reinviteIndividual') {
      reinviteIndividual();
    } else if (k === `deleteIndividual-${props.individualId}`) {
      deleteIndividual();
    }
  },
}).manager;

const isApproved = computed(() => authStore.complianceStatus === ComplianceStatus.APPROVED);

const isAdmin = computed(
  () => individual.value && [IndividualType.PARTNER_ADMIN, IndividualType.CLIENT_ADMIN].includes(individual.value.type)
);

const isUser = computed(
  () =>
    individual.value?.type &&
    [...clientUserIndividualTypes, ...partnerUserIndividualTypes].includes(individual.value?.type)
);

const groupService = computed(() => {
  if (individual.value?.client) {
    return services.clientGroup;
  } else {
    return services.partnerGroup;
  }
});

const groupParentId = computed(() => {
  if (individual.value?.client) {
    return individual.value!.client!.id;
  } else {
    // Partner group endpoints do not use id in path, when called from a partner user
    return undefined;
  }
});

const isLoggedUser = computed(() => individual.value?.id === authStore.loggedInIdentity?.id);

function onIndividualIdChange() {
  requestManager
    .cancelAndNew('loadIndividual', services.individual.getIndividual(props.individualId))
    .subscribe((individualData) => {
      individual.value = individualData;
    });
}

function onIndividualChange() {
  if (individual.value) {
    requestManager
      .cancelAndNew(
        'loadIndividualGroups',
        groupService.value.listGroups({ individualId: [props.individualId], size: 200 }, groupParentId.value)
      )
      .subscribe((groups) => {
        individualGroups.value = groups.list;
      });
  }
}

function onAdminChange(value: boolean) {
  if (individual.value) {
    if (individual.value.client) {
      individual.value.type = value ? IndividualType.CLIENT_ADMIN : IndividualType.CLIENT_INDIVIDUAL;
    } else {
      individual.value.type = value ? IndividualType.PARTNER_ADMIN : IndividualType.PARTNER_AGENT;
    }
    saveIndividualType(individual.value.type);
  }
}

function saveIndividualType(type: IndividualType) {
  requestManager
    .new(
      'updateIndividualType',
      services.individual
        .updateIndividualType(props.individualId, type)
        .pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () => services.individual.getIndividual(idEntity.id))
          )
        )
    )
    .subscribe((individualData) => {
      individual.value = individualData;
      emit('individual-updated', individual.value);
    });
}

function reinviteIndividual() {
  requestManager
    .currentOrNew(`reinviteIndividual`, services.individual.reinviteIndividual(props.individualId))
    .subscribe(() => {
      emit('individual-reinvited', props.individualId);
      toast.success('User reinvited. They will receive a new email to create their account.');
    });
}

function deleteIndividual() {
  requestManager
    .currentOrNew(
      `deleteIndividual-${props.individualId}`,
      services.individual
        .deleteIndividual(props.individualId, { errors: { silent: true } })
        .pipe(
          mergeMap(() =>
            waitForEntityDeletion(() =>
              services.individual.getIndividual(props.individualId, { errors: { silent: true } })
            )
          )
        )
    )
    .subscribe(
      () => {
        emit('individual-deleted', props.individualId);
        toast.success('User deleted.');
      },
      (e) => {
        if (e.response?.status === 409 || e.response?.status === 400) {
          toast.info(e.response.data.message);
        } else {
          toast.error('An unexpected problem has occurred. Please try again later.');
        }
      }
    );
}

watch(() => props.individualId, onIndividualIdChange, { immediate: true });

watch(() => individual.value, onIndividualChange, { immediate: true });
</script>

<template>
  <BoxGridBlock :loadingOverlayProps="{ loading: requestManager.anyPending }" useLoadingOverlay overlayType="simple">
    <template v-if="individual">
      <div class="block-header text-right">
        <VButton
          blurOnClick
          v-if="!individual.user && isApproved"
          @click="reinviteIndividual"
          :loading="requestManager.requestStates.reinviteIndividual === 'pending'"
          class="btn-stroked"
        >
          Resend Invitation
        </VButton>
        <ConfirmModal modalText="Permanently delete this team member?" @ok="deleteIndividual" v-slot="{ showModal }">
          <VButton blurOnClick class="delete-button btn-danger-secondary" @click="showModal">Delete</VButton>
        </ConfirmModal>
      </div>
      <div class="d-flex justify-content-between">
        <h3>{{ individual.firstName }} {{ individual.lastName }}</h3>
      </div>
      <div class="info">
        <DataRow class="mb-3 individual-groups" label="Groups">
          <span class="text-secondary" v-if="requestManager.requestStates.loadIndividualGroups === 'pending'">
            Loading...
          </span>
          <span class="text-secondary" v-else-if="individualGroups.length === 0">Not a member of any groups</span>
          <span v-else>
            <span v-for="(group, index) in individualGroups" :key="group.id">
              {{ group.name }}<span v-if="index < individualGroups.length - 1">,</span>
            </span>
          </span>
        </DataRow>
        <DataRow class="mb-3 individual-email" label="Email">
          {{ individual.email }}
        </DataRow>
        <DataRow class="mb-3 individual-phone" label="Mobile Number" v-if="individual.phoneNumber">
          {{ individual.phoneNumber }}
        </DataRow>
      </div>
      <PermissionsEditor
        v-if="individual.user.id"
        targetType="user"
        :targetId="individual.user.id"
        :role="individual.user.role"
      >
        <div class="d-flex justify-content-between" v-if="isUser">
          <div class="permission-description">
            <h4>Admin</h4>
            <p class="text-secondary">
              Admins can add, delete and change permissions of other members and admins.<br /><span v-if="isLoggedUser">
                <b>This cannot be edited for your own Individual.</b>
              </span>
            </p>
          </div>
          <div class="permission-switch">
            <BFormCheckbox
              :checked="isAdmin"
              name="check-button"
              :disabled="isLoggedUser || !individual.user"
              switch
              @change="onAdminChange"
            />
          </div>
        </div>
      </PermissionsEditor>
    </template>
  </BoxGridBlock>
</template>

<style lang="scss" scoped>
.block-header {
  height: 4rem;
}

.delete-button {
  margin-left: 1rem;
}
</style>
