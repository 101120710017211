export enum NotificationChannelType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  IN_APP = 'IN_APP',
  DEALER_CALL = 'DEALER_CALL',
}

export interface NotificationChannel {
  type: NotificationChannelType;
  enabled: boolean;
}

export interface NotificationChannelSetting {
  channelType: NotificationChannelType;
  editable: boolean;
  configurable: boolean;
  defaultEnabledValue: boolean;
}
