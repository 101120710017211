<script setup lang="ts">
import VButton from '../VButton.vue';
import { computed, ref, useListeners } from 'vue';

defineProps({
  accept: {
    type: String,
    default: '',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: '',
  },
});

const emit = defineEmits({
  'files-selected': (_files: FileList) => true,
});

const input = ref<InstanceType<typeof HTMLInputElement>>();

const listeners = useListeners();

const buttonEvents = computed(() => {
  let clickListeners: Function[] = [];
  if (listeners.click) {
    clickListeners = Array.isArray(listeners.click) ? listeners.click : [listeners.click];
  }

  return {
    ...listeners,
    click: [showUpload, ...clickListeners],
  };
});

function onFileSelection() {
  if (input.value?.files) {
    emit('files-selected', input.value.files);
  }

  input.value!.value = '';
}

function showUpload() {
  input.value!.dispatchEvent(
    new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
  );
}
</script>

<template>
  <span class="upload-button">
    <VButton v-bind="$attrs" v-on="buttonEvents" :class="buttonClass">
      <slot />
    </VButton>
    <input
      ref="input"
      type="file"
      :accept="accept"
      :multiple="multiple"
      class="file-select-input"
      @change="onFileSelection"
    />
  </span>
</template>

<style lang="scss" scoped>
.upload-button {
  .file-select-input {
    display: none;
  }
}
</style>
