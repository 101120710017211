import { selectField, financialAmountField } from 'ah-common-lib/src/form/models/commonFields';
import { Form } from 'ah-common-lib/src/form/interfaces';
import {
  requiredIfStateValue,
  smallerThanOrEqualsParam,
  biggerThanOrEqualsParam,
  optional,
} from 'ah-common-lib/src/form/validators';

export function paymentCurrencyForm(): Form {
  return {
    name: 'paymentCurrencyForm',
    state: {
      title: 'Reason',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: false,
    },
    fieldType: 'form',
    fields: [
      selectField('currency', 'Currency', [], {
        showRequiredMarkers: true,
        multiple: false,
        showBadges: false,
        maxFulltextLabels: 0,
        fieldWrapperClass: 'col col-6',
      }),
      financialAmountField(
        'amount',
        'Amount',
        {
          inputClass: 'form-control text-right',
          fieldWrapperClass: 'col col-6 mb-0',
          availableBalance: 0,
          min: 0.01,
          max: Infinity,
          errorMessages: {
            min: 'Amount cannot be empty or zero.',
            max: 'The amount exceeds the payment limit',
            required: 'Amount is required',
          },
        },
        {
          min: optional(biggerThanOrEqualsParam('amount', 'min')),
          max: smallerThanOrEqualsParam('amount', 'max'),
          required: requiredIfStateValue('amount'),
        }
      ),
    ],
  };
}

export function paymentPurposeForm(): Form {
  return {
    name: 'paymentPurposeForm',
    state: {
      title: 'Purpose',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: true,
    },
    fieldType: 'form',
    fields: [
      selectField('paymentPurpose', '', [], {
        required: true,
      }),
    ],
  };
}
