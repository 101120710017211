<script lang="ts" setup>
import SizeSelector from 'ah-common-lib/src/common/components/SizeSelector.vue';
import { capitalize } from 'lodash';
import { OutstandingFee } from 'ah-api-gateways/models/feesAndCharges/fees';
import LoadingIcon from 'ah-common-lib/src/icons/components/LoadingIcon.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import {
  defineUseManagedListFilterProps,
  useManagedListFilter,
  UseManagedListFilterEmits,
} from 'ah-common-lib/src/listing';
import { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../..';

const numberOfRowsOptions: { label: string; value: number }[] = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: 'All', value: 100000 },
];

interface SingleOwnerOutstandingFeesHeaderEmits extends UseManagedListFilterEmits {}

const ahTradesState = useAhTradesState();

const requestManager = useRequestManager().manager;

const feesInformation = ref<OutstandingFee[]>([]);

const emit = defineEmits<SingleOwnerOutstandingFeesHeaderEmits>();

const props = defineProps({
  ...defineUseManagedListFilterProps(),
  clientOrPartnerId: {
    type: String,
    required: true,
  },
  userType: {
    type: String as PropType<'client' | 'partner'>,
    required: true,
  },
});

const { sortAndPageData } = useManagedListFilter<{}>({
  props,
  emit,
  filterKeys: [],
  sortAndPageKeys: [
    {
      key: 'pageSize',
      getter: (filters) => filters.value.pageSize || undefined,
      setter: (value, filters) => (filters.value.pageSize = value),
    },
  ],
});

const feesAccrued = computed(() => feesInformation.value.reduce((sum, fee) => sum + fee.feesAccrued, 0));

const feesPaid = computed(() => feesInformation.value.reduce((sum, fee) => sum + fee.feesPaid, 0));

const feesOutstanding = computed(() => feesInformation.value.reduce((sum, fee) => sum + fee.feesOutstanding, 0));

watch(
  () => [props.clientOrPartnerId, props.userType],
  () => {
    feesInformation.value = [];
    const params: any = { type: `${props.userType}s` };
    params[`${props.userType}Id`] = props.clientOrPartnerId;

    requestManager
      .sameOrCancelAndNew(
        `get${capitalize(props.userType)}-${props.clientOrPartnerId}`,
        ahTradesState.services.fees.listOutstandingFees(params)
      )
      .subscribe((response) => (feesInformation.value = response.list));
  },
  { immediate: true }
);
</script>

<template>
  <VRow class="mb-n3" align-h="between" align-v="end">
    <VCol cols="5">
      <h3 class="mb-2">Fees & Charges</h3>
      <VRow class="fee-information mb-3" v-if="feesInformation">
        <VCol>
          <label>Fees Accrued</label>
          <div v-if="requestManager.anyPending"><LoadingIcon /></div>
          <LoadingIcon v-if="requestManager.anyPending" />
          <template v-else>
            <div>{{ formatCurrencyValue(feesAccrued) }}</div>
          </template>
        </VCol>
        <VCol>
          <label>Fees Paid</label>
          <div v-if="requestManager.anyPending"><LoadingIcon /></div>
          <template v-else>
            <div>{{ formatCurrencyValue(feesPaid) }}</div>
          </template>
        </VCol>
        <VCol>
          <label>Fees Outstanding</label>
          <div v-if="requestManager.anyPending"><LoadingIcon /></div>
          <template v-else>
            <div>{{ formatCurrencyValue(feesOutstanding) }}</div>
          </template>
        </VCol>
      </VRow>
    </VCol>
    <VCol cols="2">
      <SizeSelector :customRowsOptions="numberOfRowsOptions" :selected-value.sync="sortAndPageData.pageSize" />
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.fee-information {
  font-size: $font-size-sm;
  white-space: nowrap;
  label {
    font-weight: $font-weight-regular;
  }
  div {
    font-weight: $font-weight-bold;
  }
}
</style>
