import { ClientType, ComplianceStatus, Individual, FileCategory, CompanyDetails, Address } from '../';

export interface ClientActivityReport {
  total: number;
  active: number;
  inactive: number;
}

export interface ClientActivityReportRequest {
  partnerId?: string[];
  from?: Date;
  to?: Date;
}

export interface ClientOnboardingReportFilters {
  query?: string;
  complianceStatus?: ComplianceStatus;
  createdAtFrom?: string;
  createdAtTo?: string;
  termsAndConditionsDateFrom?: string;
  termsAndConditionsDateTo?: string;
  caseClosedAtFrom?: string;
  caseClosedAtTo?: string;
}

export interface OnboardingStatusLabel {
  status: ComplianceStatus;
  statusLabel: string;
  statusGroupedLabel: string;
}

export interface OnboardingStatusReport extends OnboardingStatusLabel {
  total: number;
}

export interface ClientOnboardingStatusReport {
  summary: OnboardingStatusReport[];
}

export interface ClientOnboardingReport extends OnboardingStatusLabel {
  id: string;
  referenceNumber: string;
  composedReferenceNumber: string;
  name: string;

  type: ClientType;
  applicant: Individual;
  owner: Individual;
  missingDocuments?: FileCategory[];
  uboMissingDocuments?: FileCategory[];

  reportingCurrency: string;
  address: Address;
  companyDetails: CompanyDetails;
  clientReferral: string;
  permissions: string[];

  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  termsAndConditionsDate: string;
  caseClosedAt: string;

  deleted: boolean;
}

export const complianceStatusNote: Record<ComplianceStatus, string> = {
  [ComplianceStatus.UNKNOWN]: 'Unknown',
  [ComplianceStatus.PENDING]: 'User Details Registered',
  [ComplianceStatus.TERMS_AND_CONDITIONS]: 'Pending T&Cs Acceptance',
  [ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS]: 'Updated T&Cs Outstanding',
  [ComplianceStatus.DOCUMENTS_REQUIRED]: 'Documents Required',
  [ComplianceStatus.SUBMITTED]: 'Application in Compliance Review',
  [ComplianceStatus.APPROVED]: 'Application Approved',
  [ComplianceStatus.REJECTED]: 'Application Rejected',
  [ComplianceStatus.DEACTIVATED]: 'Application Deactivated',
  [ComplianceStatus.SUSPENDED]: 'Application Suspended',
  [ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED]: `Suspended for Updated T&C's outstanding`,
};
