import defaultsDeep from 'lodash/defaultsDeep';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpBasedService, HttpService, HttpOptions, SocketService, RSocketTransport } from 'ah-requests';
import {
  EmailOptions,
  Notification,
  NotificationType,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  NotificationEvent,
  NotificationRequestType,
} from '../models';

export class NotificationService extends HttpBasedService {
  private socket!: RSocketTransport;

  constructor(http: HttpService, socketService: SocketService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'notificationService' } },
      },
    });
    this.socket = socketService.makeRSocketTransport();
  }

  /**
   * Watch for notifications
   *
   * Will connect automatically to the socket, if needed
   */
  public watchNotifications(forceReconnection?: boolean) {
    return from(this.socket.connect(`${this.baseUrl.replace('https://', 'wss://')}ws`, forceReconnection)).pipe(
      mergeMap(() => this.socket.watchChannel<NotificationEvent>())
    );
  }

  /**
   * Disconnect from the notifications websocket
   */
  public disconnectFromNotifications() {
    this.socket.disconnect();
  }

  public listNotifications(
    query: PaginatedQuery,
    options: HttpOptions<PaginatedResponse<Notification>> = {}
  ): Observable<PaginatedResponse<Notification>> {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<Notification>>(`${this.baseUrl}notifications`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public markAsRead(id: string, options: HttpOptions<void> = {}) {
    options = defaultsDeep(options, {
      errors: {
        silent: true,
      },
    });

    return this.put<void>(`${this.baseUrl}notifications/${id}/read`, undefined, { options });
  }

  public markAllAsRead(types?: NotificationType[], options: HttpOptions<void> = {}) {
    options = defaultsDeep(options, {
      errors: {
        silent: true,
      },
    });

    return this.put<void>(`${this.baseUrl}notifications/read`, undefined, {
      options,
      axiosConfig: {
        params: {
          type: types,
        },
      },
    });
  }

  public resendTradeConfirmation(
    tradeId: string,
    emailOptions: Partial<EmailOptions>,
    type = NotificationType.POSITION_STATUS_OPEN,
    options: HttpOptions<void> = {}
  ) {
    const payload = {
      id: tradeId,
      type,
      ...emailOptions,
    };
    return this.put<void>(`${this.baseUrl}notifications/resend`, payload, { options });
  }

  public sentOutstandingFeeReminder(
    clientId: string,
    emailOptions?: Partial<EmailOptions>,
    options: HttpOptions<void> = {}
  ) {
    const payload = {
      clientId: clientId,
      type: NotificationRequestType.FEE_DUE_OVERDUE,
      ...emailOptions,
    };
    return this.post<void>(`${this.baseUrl}notifications`, payload, { options });
  }
}
