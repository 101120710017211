<script lang="ts" setup>
import { BankAccount, Beneficiary, BeneficiaryType } from 'ah-api-gateways';
import BankAccountForm from './BankAccountForm.vue';
import { from, of } from 'rxjs';
import { prefilledBankAccount } from '../../helpers/bankAccount';
import { submitForm } from 'ah-common-lib/src/form/helpers';
import { GenericErrorCodes } from 'ah-api-gateways';
import { CompoundValidation } from 'ah-common-lib/src/form/interfaces';
import { flatMap, map, mergeMap, catchError } from 'rxjs/operators';
import { waitForCQRSEntityChange } from 'ah-requests';
import { computed, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhBeneficiariesState } from '../..';

const emit = defineEmits<{
  /**
   * - update:bankAccount : payload with bankAccount
   */
  (e: 'update:bankAccount', value: Beneficiary): void;
  (e: 'cancel'): void;
}>();

const requestManager = useRequestManager({
  exposeToParent: false,
});

const bankAccountForm = ref<InstanceType<typeof BankAccountForm> | null>(null);

const beneficiarieState = useAhBeneficiariesState();

const props = defineProps<{
  beneficiary?: Beneficiary;
  /*
   * Currency of the currently adding bank account
   * if set, no other currencies are allowed
   */
  currency?: string;
}>();

const state = reactive({
  editedBankAccount: {} as Partial<BankAccount>,
  formLoading: false as boolean,
  bankAccountValidation: null as CompoundValidation | null,
});

watch(
  () => [props.beneficiary],
  () => {
    state.editedBankAccount = {
      ...props.beneficiary,
    };
  },
  { immediate: true }
);

function resetForm() {
  state.editedBankAccount = {
    ...props.beneficiary,
  };
}

const isFormFullyVisible = computed(() => {
  return !state.bankAccountValidation?.$invalid;
});

function cleanupBankAccount() {
  const bankAccount = prefilledBankAccount(state.editedBankAccount);

  return from(
    Promise.all([
      beneficiarieState.store.useSettingsStore().loadCurrencyAddressFields({
        params: {
          currency: bankAccount.currency!,
          beneficiaryType: props.beneficiary?.type || BeneficiaryType.INDIVIDUAL,
          bankingScheme: bankAccount.bankingScheme!,
        },
        force: true,
      }),
      beneficiarieState.store.useSettingsStore().loadCurrencyBankAccountFields({
        params: {
          currency: bankAccount.currency!,
          beneficiaryType: props.beneficiary?.type || BeneficiaryType.INDIVIDUAL,
          bankingScheme: bankAccount.bankingScheme!,
          bankCountry: bankAccount.bankCountry,
        },
        force: true,
      }),
    ])
  ).pipe(
    map((fields) => {
      (Object.keys(bankAccount) as (keyof BankAccount)[]).forEach((k) => {
        if (!(props.beneficiary || {})[k] && !bankAccount[k]) {
          delete bankAccount[k];
        }
      });

      fields[0].forEach((definition) => {
        if (!definition.visible || !(bankAccount.address as any)[definition.fieldName]) {
          delete (bankAccount.address as any)[definition.fieldName];
        }
      });

      fields[1].forEach((definition) => {
        if (!definition.visible || !(bankAccount as any)[definition.fieldName]) {
          delete (bankAccount as any)[definition.fieldName];
        }
      });

      return bankAccount;
    })
  );
}

function saveBankAccount() {
  if (!state.bankAccountValidation) {
    return;
  }
  bankAccountForm.value!.clearErrorMessages();
  state.bankAccountValidation.validations.forEach((v) => {
    submitForm(v);
  });
  if (state.bankAccountValidation.$invalid) {
    return;
  }

  requestManager.manager
    .currentOrNew(
      'saveBankAccount',
      cleanupBankAccount().pipe(
        flatMap((account) => {
          if (props.beneficiary) {
            return beneficiarieState.services.beneficiary.updateBeneficiary(props.beneficiary.id, account);
          }
          return beneficiarieState.services.beneficiary.createPersonalBeneficiary(account);
        }),
        mergeMap((idEntity) =>
          waitForCQRSEntityChange(
            idEntity,
            () =>
              beneficiarieState.services.beneficiary.getBeneficiary(idEntity.id, {
                errors: { silent: true },
              }),
            { ignoreErrors: (e) => e.response?.status === 404 }
          ).pipe(catchError(() => of(idEntity)))
        )
      )
    )
    .subscribe(
      (bankAccount) => {
        beneficiarieState.toast.success(props.beneficiary ? 'Bank account saved' : 'Bank account created');
        emit('update:bankAccount', bankAccount as Beneficiary);
      },
      (e) => {
        const error = e.response?.data;
        if (error && error.code === GenericErrorCodes.VALIDATION_ERROR && error.subErrors.length) {
          for (let i = 0; i < error.subErrors.length; i++) {
            const subError = error.subErrors[i];
            if (subError.category === 'VALIDATION') {
              bankAccountForm.value!.setErrorMessage(subError.field, subError.message);
            }
          }
        }
      }
    );
}

defineExpose({
  resetForm,
});
</script>

<template>
  <div class="card-block">
    <BankAccountForm
      ref="bankAccountForm"
      :beneficiary="beneficiary"
      :currency="currency"
      :bankAccount.sync="state.editedBankAccount"
      :compoundValidation.sync="state.bankAccountValidation"
      :loading.sync="state.formLoading"
      v-bind="$attrs"
    />
    <div class="my-4 text-sm-center text-md-left">
      <!-- save and cancel -->
      <VButton label="Cancel" class="secondary mr-2" @click="emit('cancel')" />
      <VButton
        :disabled="state.formLoading || !isFormFullyVisible"
        class="btn-success"
        :loading="requestManager.manager.requestStates.saveBankAccount === 'pending'"
        :label="beneficiary ? 'Save' : 'Add'"
        @click="saveBankAccount"
      />
    </div>
  </div>
</template>
