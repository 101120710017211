<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';
import FormFlowWrapper from '../common/form/FormFlowWrapper.vue';
import { useTheme } from 'ah-theme';
import { BrandingImageType } from 'ah-api-gateways';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { emailField } from 'ah-common-lib/src/form/models';
import { getServices } from '@/app/services';

const props = defineProps({
  source: {
    type: String,
  },
  referral: {
    type: String,
  },
  clientId: {
    type: String,
  },
  individualId: {
    type: String,
  },
});

const emit = defineEmits({
  'email-submitted': (_email: string, _referralId: string) => true,
});

const mediaQuery = useMediaQuery();

const theme = useTheme();

const services = getServices();

const emailFormDef = ref<FormDefinition>({
  form: makeFormModel({
    name: 'landingEmail',
    fieldType: 'form',
    fields: [
      emailField('email', 'Email Address', {
        showRequiredMarkers: true,
      }),
    ],
  }),
  validation: null,
});

const isSmallScreen = computed(() => mediaQuery.is('mdDown'));

const splashLogoLink = computed(() => theme.val?.logos.find((l) => l.type === BrandingImageType.SPLASH_IMAGE)?.link);

function submitEmail() {
  emailFormDef.value.validation && submitForm(emailFormDef.value.validation);
  if (emailFormDef.value.validation?.$invalid === false) {
    const payload = {
      email: emailFormDef.value.form.email,
      source: props.source,
      referral: props.referral,
      clientId: props.clientId,
      individualId: props.individualId,
    };

    services.registration
      .registrationLanding(payload, {
        options: {
          errors: {
            silent: true,
          },
        },
      })
      .subscribe({
        next: (response) => {
          const referralId = response.id;
          emit('email-submitted', emailFormDef.value.form.email, referralId);
        },
      });
  }
}
</script>

<template>
  <FormFlowWrapper>
    <SimpleCenteredPage :narrow="isSmallScreen" fullHeight padded>
      <VRow class="landing-wrapper">
        <VCol xl="6" lg="6" sm="12" class="text-center">
          <img class="registration-partner-logo" :src="splashLogoLink" alt="" v-if="splashLogoLink" />
        </VCol>
        <VCol xl="4" lg="4" sm="12">
          <div class="landing-email-form mt-5">
            <ValidatedForm @submit="submitEmail" :fm="emailFormDef.form" :validation.sync="emailFormDef.validation" />
          </div>
          <div class="landing-footer">
            <div class="ml-3">
              Already have an account?
              <RouterLink to="/"> Login </RouterLink>
            </div>
            <VButton class="ml-auto" @click="submitEmail" :disabled="emailFormDef.validation?.$invalid">
              Proceed
            </VButton>
          </div>
        </VCol>
      </VRow>
    </SimpleCenteredPage>
  </FormFlowWrapper>
</template>

<style lang="scss" scoped>
.landing-wrapper {
  @include upToResolution($desktopResolution) {
    * {
      font-size: $font-size-sm;
    }
  }

  .registration-partner-logo {
    max-height: 10rem;
    width: 60%;
    align-items: center;
    object-fit: contain;

    @include phablet {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
    @include tablet {
      margin-top: 4rem;
      margin-bottom: 5rem;
    }
  }

  .landing-footer {
    width: 100%;
    margin-top: 1em;
    display: inline-flex;
    align-items: center;
  }
}
</style>
