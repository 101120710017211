<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
});

const visible = ref(false);

watch(
  () => props.src,
  () => {
    visible.value = false;
  },
  { immediate: true }
);

function onImageLoad() {
  setTimeout(() => {
    visible.value = true;
  });
}
</script>

<template>
  <img v-bind="$attrs" :src="src" v-if="src" @load="onImageLoad" :class="['image', { visible }]" />
</template>

<style lang="scss" scoped>
.image {
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
</style>
