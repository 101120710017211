<script lang="ts" setup>
import {
  Beneficiary,
  beneficiaryName,
  AuthorityType,
  BeneficiaryStatus,
  ListSortParams,
  BeneficiaryManagerErrorCodes,
} from 'ah-api-gateways';
import BeneficiaryEditModal from './BeneficiaryEditModal.vue';
import BeneficiaryDetails from './BeneficiaryDetails.vue';
import { waitForEntityDeletion } from 'ah-requests';
import { BModal } from 'bootstrap-vue';
import DownloadStatementModal from 'ah-wallets/src/components/transactions/DownloadStatementModal.vue';
import SendMoneyToBeneficiaryModal from './SendMoneyToBeneficiaryModal.vue';
import TransactionsListing from 'ah-wallets/src/components/transactions/TransactionsListing.vue';
import { beneficiaryTransactionsLongTableFields } from 'ah-trades/src/models/transactionsTableFields';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';
import BeneficiaryBankAccountDetails from './BeneficiaryBankAccountDetails.vue';
import { mergeMap } from 'rxjs/operators';
import { computed, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhBeneficiariesState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const beneficiaryTransactions = ref<InstanceType<typeof TransactionsListing> | null>(null);

const deleteModal = ref<InstanceType<typeof BModal> | null>(null);

const emit = defineEmits<{
  (e: 'back-to-list'): void;
  (e: 'update:beneficiary', value: Beneficiary): void;
  (e: 'beneficiary-deleted'): void;
}>();

const props = withDefaults(
  defineProps<{
    beneficiaryId: string;
    readonly?: boolean | string;
  }>(),
  {
    readonly: false,
  }
);

const state = reactive({
  beneficiary: null as Beneficiary | null,
  sortAndPageParams: {
    sort: beneficiaryTransactionsLongTableFields[0].key,
    sortDirection: 'DESC',
  } as Partial<ListSortParams>,
});

const requestManager = useRequestManager({
  clearOnDestroy: true,
  exposeToParent: ['getBeneficiary'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'getBeneficiary') {
      loadBeneficiary();
    }
  },
});

const onBehalfOfClient = useOnBehalfOf();

const beneficiarieState = useAhBeneficiariesState();

const canManageBeneficiaries = computed(() => {
  return (
    onBehalfOfClient.value || beneficiarieState.store.useAuthStore().hasAuthorities(AuthorityType.MANAGE_BENEFICIARIES)
  );
});

const canSeeTransactions = computed(() => {
  return beneficiarieState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_TRANSACTIONS);
});

const fields = computed(() => {
  return beneficiaryTransactionsLongTableFields;
});

const isReadonly = computed(() => {
  return props.readonly !== false;
});

const config = computed(() => {
  return { tableFields: beneficiaryTransactionsLongTableFields };
});

function loadBeneficiary() {
  requestManager.manager
    .sameOrCancelAndNew(
      'getBeneficiary',
      beneficiarieState.services.beneficiary.getBeneficiary(props.beneficiaryId),
      props.beneficiaryId
    )
    .subscribe((response) => {
      state.beneficiary = response;
    });
}

watch(
  () => props.beneficiaryId,
  () => {
    loadBeneficiary();
  },
  { immediate: true }
);

function onFundsSent() {
  if (beneficiaryTransactions.value) {
    beneficiaryTransactions.value?.loadData();
  }
}

function goBack() {
  emit('back-to-list');
}

const findBeneficiaryName = computed(() => {
  return (state.beneficiary && beneficiaryName(state.beneficiary)) || '';
});

const canSendOwnMoney = computed(() => {
  return !onBehalfOfClient.value && beneficiarieState.store.useAuthStore().hasAuthorities(AuthorityType.MAKE_PAYMENTS);
});

const canSendOBOMoney = computed(() => {
  return onBehalfOfClient.value?.permissions?.includes(AuthorityType.PAY_ON_BEHALF_OF);
});

const shouldDisplaySendMoney = computed(() => {
  return (
    (canSendOwnMoney.value || canSendOBOMoney.value) &&
    !isReadonly.value &&
    state.beneficiary?.status === BeneficiaryStatus.APPROVED
  );
});

function onBeneficiaryUpdate(beneficiary: Beneficiary) {
  state.beneficiary = beneficiary;
  emit('update:beneficiary', beneficiary);
}

function confirmDeleteBeneficiary() {
  requestManager.manager
    .currentOrNew(
      `deleteBeneficiary-${props.beneficiaryId}`,
      beneficiarieState.services.beneficiary
        .deleteBeneficiary(props.beneficiaryId, {
          options: {
            errors: { silent: true },
          },
        })
        .pipe(
          mergeMap(() =>
            waitForEntityDeletion(
              () =>
                beneficiarieState.services.beneficiary.getBeneficiary(props.beneficiaryId, {
                  errors: { silent: true },
                }),
              { retries: 30 }
            )
          )
        )
    )
    .subscribe(
      () => {
        beneficiarieState.toast.success('Beneficiary successfully deleted');
        emit('beneficiary-deleted');
      },
      (e) => {
        const error = e.response?.data;
        if (error && error.code === BeneficiaryManagerErrorCodes.BUSINESS_RULE_NOT_MET && error.message) {
          beneficiarieState.toast.error(error.message);
        } else {
          beneficiarieState.toast.error('An unexpected problem has occurred. Please try again later.');
        }
      }
    );
}

function deleteBeneficiary() {
  deleteModal.value!.show();
}

const currency = computed(() => {
  return state.beneficiary?.currency;
});

const filter = computed(() => {
  return { otherBeneficiaryId: state.beneficiary!.id };
});
</script>

<template>
  <BoxGrid>
    <BoxGridBlock cols="12" md="12" :loading="requestManager.manager.requestStates.getBeneficiary === 'pending'">
      <BackButton label="back to list" class="back-button d-md-block d-lg-none px-2 py-1" @click="goBack()" />
      <div class="mt-lg-0 mt-sm-4">
        <!-- BENEFICIARY DETAILS -->
        <div class="d-flex justify-content-between pr-2">
          <div>
            <h3>
              {{ findBeneficiaryName }}
              <span v-if="currency">{{ `&#9679; ${currency}` }}</span>
            </h3>
          </div>
          <div v-if="!onBehalfOfClient && canManageBeneficiaries" class="d-sm-none d-md-flex">
            <BeneficiaryEditModal
              v-slot="{ showModal }"
              :beneficiary="state.beneficiary || undefined"
              @update:beneficiary="onBeneficiaryUpdate"
              v-bind="$attrs"
            >
              <VButton
                @click="showModal"
                class="mr-2"
                :disabled="requestManager.manager.requestStates[`deleteBeneficiary-${beneficiaryId}`] === 'pending'"
                v-bind="$attrs"
                >Edit</VButton
              >
            </BeneficiaryEditModal>
            <span>
              <VButton
                blurOnClick
                @click="deleteBeneficiary"
                :loading="requestManager.manager.requestStates[`deleteBeneficiary-${beneficiaryId}`] === 'pending'"
                class="danger-secondary"
                >Delete</VButton
              >
            </span>
          </div>
        </div>
        <BeneficiaryDetails v-if="state.beneficiary" :beneficiary="state.beneficiary" v-bind="$attrs">
          <div
            v-if="!onBehalfOfClient"
            class="d-sm-flex d-md-none justify-content-center mt-3"
            slot="beneficiary-footer"
          >
            <BeneficiaryEditModal
              v-slot="{ showModal }"
              :beneficiary="state.beneficiary"
              @update:beneficiary="onBeneficiaryUpdate"
              v-bind="$attrs"
            >
              <VButton
                @click="showModal"
                class="mr-2 px-5"
                :disabled="requestManager.manager.requestStates[`deleteBeneficiary-${beneficiaryId}`] === 'pending'"
                >Edit</VButton
              >
            </BeneficiaryEditModal>
            <VButton
              blurOnClick
              @click="deleteBeneficiary"
              :loading="requestManager.manager.requestStates[`deleteBeneficiary-${beneficiaryId}`] === 'pending'"
              class="danger-secondary px-4"
              >Delete</VButton
            >
          </div>
        </BeneficiaryDetails>
      </div>

      <div class="mt-4" v-if="state.beneficiary">
        <!-- BANK ACCOUNT DETAILS -->
        <BeneficiaryBankAccountDetails :beneficiary="state.beneficiary" />
      </div>
    </BoxGridBlock>

    <!-- TRANSACTIONS DETAILS -->
    <BoxGridBlock
      cols="12"
      md="12"
      :state="requestManager.manager.requestStates.getBeneficiary"
      v-if="canSeeTransactions"
    >
      <template v-if="state.beneficiary">
        <div class="d-flex justify-content-between">
          <div>
            <h3 class="mt-0 mb-4 pb-2">Transactions</h3>
          </div>
          <div v-if="!$ahBeneficiariesState.mediaQuery.is('smDown')">
            <DownloadStatementModal :beneficiary="state.beneficiary" v-slot="{ show }">
              <VButton @click="show" class="mr-2">Statements</VButton>
            </DownloadStatementModal>
            <SendMoneyToBeneficiaryModal
              v-if="shouldDisplaySendMoney"
              :beneficiary.sync="state.beneficiary"
              @update:trade="onFundsSent"
              @flow-completed="onFundsSent"
              v-bind="$attrs"
            />
          </div>
        </div>
        <SortSelector
          :fields="fields"
          class="w-100"
          v-if="$ahBeneficiariesState.mediaQuery.is('smDown')"
          :sortAndPageParams.sync="state.sortAndPageParams"
        />
        <TransactionsListing
          ref="beneficiaryTransactions"
          v-if="state.beneficiary"
          :sortAndPageParams.sync="state.sortAndPageParams"
          :config="config"
          :filter="filter"
          v-bind="$attrs"
        />

        <div class="mt-4 text-center" v-if="$ahBeneficiariesState.mediaQuery.is('smDown')">
          <DownloadStatementModal :beneficiary="state.beneficiary" v-slot="{ show }">
            <VButton @click="show" class="mr-2">Statements</VButton>
          </DownloadStatementModal>
          <SendMoneyToBeneficiaryModal
            v-if="shouldDisplaySendMoney"
            :beneficiary.sync="state.beneficiary"
            @update:trade="onFundsSent"
            @flow-completed="onFundsSent"
            v-bind="$attrs"
          />
        </div>
        <!-- MODALS -->
        <BModal
          :centered="true"
          ok-title="Yes, delete"
          ok-variant="danger"
          cancel-variant="secondary"
          body-class="py-5 px-5"
          footer-class="justify-content-start pl-5 pb-5"
          ref="deleteModal"
          @ok="confirmDeleteBeneficiary"
          >Do you want to delete beneficiary <strong> {{ findBeneficiaryName }} </strong>?
        </BModal>
      </template>
    </BoxGridBlock>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.back-button {
  @include themedTextColor($color-dark-primary, $color-primary);
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 10px;
}
</style>
