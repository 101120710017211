import { BaseNotificationSetting } from './notificationSetting';

export enum RateAlertInequalityType {
  HIGHER_THAN = 'HIGHER_THAN',
  LOWER_THAN = 'LOWER_THAN',
}

export const rateAlertInequalityTypeStrings: {
  [key in RateAlertInequalityType]: string;
} = {
  HIGHER_THAN: 'higher than',
  LOWER_THAN: 'lower than',
};

export interface RateAlertSetting extends BaseNotificationSetting {
  inequality: RateAlertInequalityType;
  currencyPair: string;
  rate: number;
}
