import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';
import { PaginatedQuery, PaginatedResponse, standardiseQuerySort, TfaTypes, User, VersionedObject } from '../models';

export type ImpersonateToken = {
  token: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
};

export class UserService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'userService' } },
      },
    });
  }

  public impersonateUser(userId: string, options?: Partial<HttpOptions<ImpersonateToken>>) {
    return this.put<ImpersonateToken>(`${this.baseUrl}admin/switchuser/${userId}`, undefined, { options });
  }

  public getUser(userId: string, options?: Partial<HttpOptions<User>>) {
    return this.get<User>(`${this.baseUrl}users/${userId}`, { options });
  }

  public updateUser(userId: string, update: Partial<User>, options?: HttpOptions<VersionedObject>) {
    return this.put<VersionedObject>(`${this.baseUrl}users/${userId}`, update, { options });
  }

  public listUsers(query: PaginatedQuery, options?: Partial<HttpOptions<PaginatedResponse<User>>>) {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<User>>(`${this.baseUrl}users`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public listUsersTfas(query: PaginatedQuery, options?: Partial<HttpOptions<PaginatedResponse<TfaTypes>>>) {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<TfaTypes>>(`${this.baseUrl}users/tfa-types`, {
      options,
      axiosConfig: {
        params: query,
      },
    });
  }
}
