import type { PropType, PropOptions } from 'vue';
type ClassLike = string | { [key: string]: boolean };

type ClassLikeArray = (ClassLike | ClassLikeArray)[];

export const BoxGridItemProps = {
  animateTransitions: {
    type: [Boolean, String],
    default: false,
  },
  itemClass: {
    type: [Object, Array, String] as PropType<ClassLike | ClassLikeArray>,
    required: false,
  },
  loadingOverlayProps: {
    type: Object,
    required: false,
  },
  overlayType: {
    type: String as PropType<'managed' | 'simple'>,
    default: 'managed',
  },
  useLoadingOverlay: {
    type: [Boolean, String],
    default: false,
  },
} satisfies Record<string, PropOptions>;
