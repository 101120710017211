<script setup lang="ts">
import ActivitySidebarView from '@/app/views/admin/activity/ActivitySidebarView.vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

const activityOpen = ref(false);

const route = useRoute();

const inActivity = computed(() => route.fullPath.includes('admin/activity'));

watch(
  () => route.fullPath,
  () => (activityOpen.value = inActivity.value),
  { immediate: true }
);

function toggleActivity() {
  activityOpen.value = !activityOpen.value;
}
</script>

<template>
  <span>
    <div tag="li" class="menu-link" :class="{ 'router-link-active': inActivity }" v-if="$mediaQuery.is('smOnly')">
      <!-- Visible only on phablets displays -->
      <IconReport /> <span class="menu-link-text"> Activity </span>
      <span>
        <span @click="toggleActivity()"><IconChevronDown class="toggle-icon" :class="{ open: activityOpen }" /></span>
        <ExpandTransition appear>
          <ActivitySidebarView v-if="activityOpen" path="/admin/activity" class="router-view" name="sidebar" />
        </ExpandTransition>
      </span>
    </div>

    <RouterLink tag="li" class="menu-link" to="/admin/activity" v-else>
      <!-- Visible in tablet or higher resolutions -->
      <IconReport /> <span class="menu-link-text"> Activity </span>
    </RouterLink>
  </span>
</template>

<style lang="scss" scoped>
.toggle-icon {
  float: right;
  transition: transform 0.5s;
  font-size: 1.5em;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
