import { email, helpers, required } from '@vuelidate/validators';
import { emirClassificationOptionsFullLabel, EmirClassification } from 'ah-api-gateways';
import { personTitleField } from 'ah-common-lib/src/form/formModels';
import { makeFormModel, toDataModel } from 'ah-common-lib/src/form/helpers';
import { FieldOptions } from 'ah-common-lib/src/form/interfaces';
import { ValidationRuleSet } from 'ah-common-lib/src/form/interfaces';
import { Field, FormModel } from 'ah-common-lib/src/form/interfaces/form';
import {
  checkboxField,
  dateField,
  emailField,
  formTitle,
  passwordField,
  phoneField,
  radioField,
  selectField,
  textField,
} from 'ah-common-lib/src/form/models';
import {
  checkParam,
  date,
  ifTest,
  maxDate,
  optional,
  requiredIfStateValue,
  stateValueGetter,
  url,
  validName,
} from 'ah-common-lib/src/form/validators';
import {
  tradeFrequencyOptions,
  occupationOptions,
  sourceOfFundOptions,
  expectedVolumeOptions,
  getMaxExperience,
  getMinExpectedVolume,
  sourceOfFundsLabels,
  occupationLabels,
  reasonForAccountUsageOptions,
} from 'ah-common-lib/src/helpers/questionnaire';
import { subYears } from 'date-fns';

export function registrationDateField(state?: FieldOptions) {
  return dateField(
    'birthDate',
    'Date of Birth',
    {
      useDateTime: false,
      required: true,
      fieldWrapperClass: 'col col-6',
      errorMessages: {
        maxDate: 'Must be 18 years or older.',
      },
      ...state,
    },
    {
      date: ifTest(date, (val) => val instanceof Date),
      required: ifTest(requiredIfStateValue('birthDate'), (a) => !(a instanceof Date)),
      maxDate: maxDate(subYears(new Date(), 18)),
    }
  );
}

/**
 *
 * Personal Details Forms
 *
 */
export const residenceFM = () =>
  makeFormModel({
    name: 'residence',
    fieldType: 'form',
    fields: [
      /**
       * Warning!
       * we are looking at this field in `./flow.ts` to make sure the current form step is completed
       * changing the requirement of this field will break our checks, therefore the checks made need to be
       * re-evaluated
       */
      selectField('countryCode', '', [], {
        required: true,
        placeholder: 'Select One',
      }),
      checkboxField(
        'reverseSolicitation',
        'I confirm that this is a reverse solicitation request for ALT 21 and I have otherwise not been contacted by ALT 21 or any of its partners.',
        false,
        {
          required: true,
          fieldWrapperClass: 'text-small text-muted pl-2',
          errorMessages: {
            mustAccept: 'You must confirm this in order to proceed.',
          },
          showRequiredMarkers: true,
        },
        {
          mustAccept: helpers.withParams({ type: 'mustAccept' }, (val: any, vm: any) => {
            const stateValue = stateValueGetter('reverseSolicitation', 'required');
            if (stateValue(vm)) {
              return !!val;
            }
            return true;
          }),
        }
      ),
    ],
  });

export const accountAccessFM = (config: { isCompanyApplicant: boolean; useDOB?: boolean }) =>
  makeFormModel({
    name: 'accountAccess',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2', showRequiredMarkers: true }),
      textField(
        'firstName',
        'First Name',
        {
          fieldWrapperClass: 'col col-5',
          showRequiredMarkers: true,
        },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last Name',
        {
          fieldWrapperClass: 'col col-5',
          showRequiredMarkers: true,
        },
        { required: requiredIfStateValue('lastName'), validName }
      ),
      ...(config.isCompanyApplicant
        ? []
        : [
            selectField(
              'nationality',
              'Nationality',
              [],
              {
                fieldWrapperClass: 'col col-6',
                required: true,
                showRequiredMarkers: true,
              },
              { required: requiredIfStateValue('nationality') }
            ),
          ]),
      ...(config.isCompanyApplicant
        ? [textField('jobTitle', 'Job Title', { required: true, showRequiredMarkers: true })]
        : config.useDOB
        ? [registrationDateField()]
        : []),
      phoneField('phoneNumber', 'Mobile Number', { showRequiredMarkers: true }),
      emailField('email', `${config.isCompanyApplicant ? 'Business' : 'Private'} Email Address`, {
        showRequiredMarkers: true,
        maxLength: 100,
      }),
      textField('username', 'Username', {
        required: true,
        showRequiredMarkers: true,
        maxLength: 30,
        minLength: 5,
        errorMessages: {
          minLength: 'This username is too short. The minimum allowed is 5 characters.',
        },
        value: '',
      }),
      passwordField('password', 'Create Password', true, {
        errorMessages: {
          passwordLength: '',
          passwordNumber: '',
          passwordSpecialChar: '',
          passwordUpper: '',
          passwordLower: '',
        },
        allowShowPassword: true,
        showRequiredMarkers: true,
      }),
      textField('referenceDescription', '', {
        required: false,
        hidden: true,
        fieldWrapperClass: 'col col-12 mt-n3',
        placeholder: 'Please specify...',
        maxLength: 100,
      }),
    ],
  });

/**
 *
 * Trading Profile Forms
 *
 */
const sourceOfFoundsFields: Field[] = [
  selectField('sourceOfFundsSelection', 'Source of Funds', sourceOfFundOptions, {
    placeholder: 'Select One',
    showRequiredMarkers: true,
    fieldWrapperClass: 'col col-6',
  }),
  textField('sourceOfFunds', '', {
    required: true,
    hidden: true,
    placeholder: 'Please specify...',
    maxLength: 100,
    fieldWrapperClass: 'col col-6 mt-n2 mr-1',
  }),
];

const occupationFields: Field[] = [
  selectField('occupationSelection', 'Occupation', occupationOptions, {
    placeholder: 'Select One',
    showRequiredMarkers: true,
    fieldWrapperClass: 'col col-6',
  }),
  textField('occupation', '', {
    required: true,
    hidden: true,
    placeholder: 'Please specify...',
    maxLength: 100,
    fieldWrapperClass: 'col col-6 mt-n2 mr-1',
  }),
];

export const tradingDetailsFM = (isCompany = false) =>
  makeFormModel({
    name: 'tradingFM',
    fieldType: 'form',
    fields: [
      /**
       * Warning!
       * we are looking at the following fields (`fxSpotInterest` and `fxForwardInterest`) in `./flow.ts` to make sure the current form step is completed
       * changing the requirement of this fields will break our checks, therefore the checks made need to be re-evaluated
       */
      checkboxField(
        'fxSpotInterest',
        'FX Spot',
        false,
        {
          fieldWrapperClass: 'col col-6',
          errorMessages: { required: 'At least one product interest is required.' },
        },
        {
          required: helpers.withParams({ type: 'anyFxSet' }, (val: any, ctx: any) => {
            return !!val || !!ctx.fxForwardInterest;
          }),
        }
      ),
      checkboxField(
        'fxForwardInterest',
        'FX Forwards',
        false,
        { fieldWrapperClass: 'col col-6', errorMessages: { required: 'At least one product interest is required.' } },
        {
          required: helpers.withParams({ type: 'anyFxSet' }, (val: any, ctx: any) => {
            return !!val || !!ctx.fxSpotInterest;
          }),
        }
      ),
      ...(isCompany ? sourceOfFoundsFields : occupationFields),
      selectField('reason', 'Reason for use of account', reasonForAccountUsageOptions, {
        placeholder: 'Select One',
        showRequiredMarkers: true,
        fieldWrapperClass: 'col col-6',
      }),
      selectField('buyCurrencies', 'Which currencies will you be buying?', [], {
        placeholder: 'Select all that apply',
        showRequiredMarkers: true,
        multiple: true,
        showBadges: true,
        maxFulltextLabels: 0,
        itemsCountLabel: 'currencies',
        fieldWrapperClass: 'col col-6',
      }),
      selectField('sellCurrencies', 'Which currencies will you be selling?', [], {
        placeholder: 'Select all that apply',
        showRequiredMarkers: true,
        multiple: true,
        showBadges: true,
        maxFulltextLabels: 0,
        itemsCountLabel: 'currencies',
        fieldWrapperClass: 'col col-6',
      }),
      selectField('tradeFrequency', 'Frequency of Activity', tradeFrequencyOptions, {
        placeholder: 'Select One',
        showRequiredMarkers: true,
        fieldWrapperClass: 'col col-6',
      }),
      selectField('maxExpectedVolume', 'Volumes', expectedVolumeOptions, {
        placeholder: 'Select One',
        showRequiredMarkers: true,
        fieldWrapperClass: 'col col-6',
      }),
      selectField('beneficiaryCountries', 'Destination of Payments', [], {
        placeholder: 'Select all that apply',
        showRequiredMarkers: true,
        multiple: true,
        showBadges: true,
        maxFulltextLabels: 0,
        itemsCountLabel: 'countries',
        appendToBody: true,
        fieldWrapperClass: 'col col-6',
        maxItems: 5,
      }),
      ...(isCompany
        ? [
            selectField('reportingCurrency', 'What is your tax reporting currency', [], {
              placeholder: 'Select One',
              showRequiredMarkers: true,
              fieldWrapperClass: 'col col-6 mt-4',
            }),
          ]
        : []),
      radioField('emirClassification', 'EMIR Classification', emirClassificationOptionsFullLabel, {
        required: true,
        defaultValue: EmirClassification.NFC,
        inline: true,
        fieldWrapperClass: 'col col-12',
      }),
    ],
  });

export function tradingDetailsFormToModel(formModel: FormModel, isCompany: boolean) {
  const { sourceOfFundsSelection, occupationSelection, ...tradingDetails } = toDataModel(formModel);

  if (isCompany && formModel.sourceOfFundsSelection && formModel.sourceOfFundsSelection !== sourceOfFundsLabels.OTHER) {
    tradingDetails.sourceOfFunds = formModel.sourceOfFundsSelection;
  }

  if (!isCompany && formModel.occupationSelection && formModel.occupationSelection !== occupationLabels.OTHER) {
    tradingDetails.occupation = formModel.occupationSelection;
  }

  if (tradingDetails.minExperienceYears !== undefined) {
    tradingDetails.maxExperienceYears = getMaxExperience(tradingDetails.minExperienceYears);
    if (tradingDetails.maxExperienceYears === Infinity) {
      tradingDetails.maxExperienceYears = undefined;
    }
  }

  if (tradingDetails.maxExpectedVolume !== undefined) {
    tradingDetails.minExpectedVolume = getMinExpectedVolume(tradingDetails.maxExpectedVolume);
  }

  return tradingDetails;
}

/**
 *
 * Company Forms
 *
 */
export const makeCompanyDetailsFM = () =>
  makeFormModel({
    name: 'companyDetailsForm',
    fieldType: 'form',
    fields: [
      selectField('entityType', 'Company Type', [], {
        required: true,
        placeholder: 'Select One',
        showRequiredMarkers: true,
      }),
      textField('name', 'Registered Company Name', { showRequiredMarkers: true }),
      textField('registrationNumber', 'Company number', { showRequiredMarkers: true }),
      textField('sectorDescription', 'Sector', { required: true, showRequiredMarkers: true }),
      textField(
        'website',
        'Company website',
        {
          fieldWrapperClass: 'col col-12 mb-2',
          showRequiredMarkers: true,
          maxLength: 100,
          errorMessages: { url: 'Incorrect URL ' },
        },
        { required: requiredIfStateValue('website'), url }
      ),
      checkboxField(
        'noWebsite',
        'No website',
        false,
        { fieldWrapperClass: 'col col-12', showRequiredMarkers: true },
        {}
      ),
      phoneField('phoneNumber', 'Company Telephone', {
        showRequiredMarkers: true,
        fieldWrapperClass: 'col col-6',
      }),
    ],
  });

export const makeDuplicateAddressFM = () =>
  makeFormModel({
    name: 'duplicateAddressFM',
    fieldType: 'form',
    fields: [
      formTitle('Is your trading address and registered address the same?'),
      radioField(
        'duplicatedAddress',
        '',
        [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        { defaultValue: true, inline: true, fieldWrapperClass: 'col col-12' }
      ),
    ],
  });

export const makeUserTypeFM = () =>
  makeFormModel({
    name: 'signatoryForm',
    fieldType: 'form',
    fields: [
      radioField(
        'userTypes',
        'I am a...',
        [
          { label: 'Primary Authorised Signatory', value: 'owner' },
          { label: 'Employee', value: 'user' },
        ],
        {
          defaultValue: 'owner',
          inline: true,
          fieldWrapperClass: 'col col-12 mb-0',
        }
      ),
    ],
  });

export const makeApplicantExtraInfoForm = () =>
  makeFormModel({
    name: 'applicantExtraInfoForm',
    fieldType: 'form',
    fields: [registrationDateField()],
  });

export const makeAdditionalUserFM = (config: {
  emailValidations?: Partial<ValidationRuleSet>;
  isSignatory?: boolean;
  useDOB?: boolean;
}) =>
  makeFormModel({
    name: 'additionalUserForm',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2', showRequiredMarkers: true }),
      textField(
        'firstName',
        'First Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last Name',
        {
          fieldWrapperClass: 'col col-5',
          showRequiredMarkers: true,
        },
        {
          required: requiredIfStateValue('lastName'),
          validName,
        }
      ),
      emailField(
        'email',
        'Email Address',
        {
          showRequiredMarkers: true,
          errorMessages: {
            differentFromIndividuals: 'Email can not be the same as another proposed additional user',
            differentFromApplicant: 'Email can not be the same as the applicant',
            differentFromOwner: 'Email can not be the same as the signatory',
            differentFromSecondaryOwner: 'Email can not be the same as the secondary signatory',
          },
        },
        {
          required,
          email,
          ...config.emailValidations,
        }
      ),
      phoneField(
        'phoneNumber',
        'Mobile Number',
        { fieldWrapperClass: `col ${config.useDOB ? 'col-6' : 'col-12'}` },
        {
          phone: optional(checkParam('phoneNumber', 'valid')),
        }
      ),
      ...(config.useDOB ? [registrationDateField({ fieldWrapperClass: 'col col-6' })] : []),
    ],
  });
