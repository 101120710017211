export const enum commonStoreActions {
  /**
   * Store action running on session start
   *
   * Runs before onLogin
   * Runs on any valid session start (registration session included)
   */
  onSessionStart = 'STORE_ON_SESSION_START',
  onSessionStartOtherTab = 'STORE_ON_SESSION_START_OTHER_TAB',
  /**
   * Store action running on log in
   *
   * Does not run on a registration session
   */
  onLogin = 'STORE_ON_LOGIN',
  onLoginOtherTab = 'STORE_ON_LOGIN_OTHER_TAB',
  onLogout = 'STORE_ON_LOGOUT',
  onLogoutOtherTab = 'STORE_ON_LOGOUT_OTHER_TAB',
  onInMaintenance = 'STORE_ON_IN_MAINTENANCE',
  onOutMaintenance = 'STORE_ON_OUT_MAINTENANCE',
  /**
   * Store action running on store setup
   *
   * This runs BEFORE any persistence synchronization
   *
   * FIXME - deprecated, in Pinia can be replaced by running on created hook?
   */
  onSetup = 'STORE_ON_SETUP',
  /**
   * Store action running AFTER store setup
   *
   * This runs BEFORE any persistence synchronization
   *
   * FIXME - deprecated, in Pinia can be replaced by running on created hook?
   */
  afterSetup = 'STORE_AFTER_SETUP',
  /**
   * Store action running ON (just prior) store persistence sync
   *
   * FIXME - deprecated, in Pinia can be replaced by running on beforeRestore hook
   */
  afterRestore = 'STORE_AFTER_RESTORE',
  /**
   * Store action running AFTER store persistence sync (duplicate of afterSync?)
   *
   * FIXME - deprecated, in Pinia can be replaced by running on afterRestore hook
   */
  onSync = 'STORE_ON_SYNC',
  /**
   * Store action running AFTER store persistence sync
   *
   * FIXME - deprecated, in Pinia can be replaced by running on afterRestore hook
   */
  afterSync = 'STORE_AFTER_SYNC'
}

export const allCommonStoreActions = [
  commonStoreActions.onSessionStart,
  commonStoreActions.onSessionStartOtherTab,
  commonStoreActions.onLogin,
  commonStoreActions.onLoginOtherTab,
  commonStoreActions.onLogout,
  commonStoreActions.onLogoutOtherTab,
  commonStoreActions.onSetup,
  commonStoreActions.afterSetup,
  commonStoreActions.afterRestore,
  commonStoreActions.afterSync,
  commonStoreActions.onSync,
  commonStoreActions.onInMaintenance,
  commonStoreActions.onOutMaintenance
];
