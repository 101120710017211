import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const upcomingPaymentsTableFields: TableFieldDefinition[] = [
  {
    header: 'Currency',
    key: 'currency',
    sortable: false,
  },
  {
    header: 'Amount',
    key: 'amount',
    sortable: false,
    tdClass: 'text-left',
  },
  {
    header: 'Payment Date',
    key: 'executionDate',
    sortable: false,
  },
];
