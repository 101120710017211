import _Vue, { PluginObject } from 'vue';

export default {
  install: function install(Vue: typeof _Vue) {
    const svgContext = require.context('./svgs', true, /Icon.vue$/);
    svgContext.keys().forEach(function (key) {
      Vue.component(`Icon${key.substring(2, key.length - 8)}`, svgContext(key).default);
    });
  },
} as PluginObject<void>;
