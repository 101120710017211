<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  maxLength: {
    type: Number,
  },
  length: {
    type: Number,
    required: true,
  },
});

const counter = computed(() => {
  let out = (props.length || 0).toString();
  if (typeof props.maxLength === 'number' && props.maxLength > -1) {
    out += ' / ' + props.maxLength;
  }
  return out;
});
</script>

<template>
  <BInputGroupText class="input-group char-counter">
    <small>{{ counter }}</small>
  </BInputGroupText>
</template>

<style lang="scss">
// Unscoped style to allow for overriding
.input-group.char-counter {
  position: relative;

  small {
    position: absolute;
    margin: 1em 0 0 -3em;
    font-size: 65%;
    z-index: 3;
  }
}
</style>
