<script setup lang="ts">
import { RequestState } from 'ah-requests';
import { computed, PropType, watch } from 'vue';

/**
 * Loading Overlay component
 *
 * Emits:
 * update:isError (payload: boolean) - sync-able read-only prop displaying whether or not state is currently in error
 * update:isLoading (payload: boolean) - sync-able read-only prop displaying whether or not state is currently loading
 */

const props = defineProps({
  /**
   * Text to show while loading
   */
  loadingText: {
    type: String,
    default: '',
  },
  /**
   * Whether the loader should not wrap the child content
   */
  noWrap: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to show the loader in a loading state:
   * - If true, `state` is ignored
   * - If false (but not undefined) `state` is ignored, meaning no loading state is shown
   */
  loading: {
    type: [Boolean, String],
  },
  /**
   * Whether to show the loader in an error state:
   * - If true, `loading` and `state` are ignored
   * - If false (but not undefined) `state` is ignored, meaning no error state is shown
   */
  error: {
    type: [Boolean, String],
  },
  /**
   * Request state (or states) to track. `loading` and `error` will override the states provided.
   * Otherwise, loader is considered:
   * - In error if any of the requests are in error state
   * - Loading if any of the requests are in a loading state
   * - Idle/loaded otherwise
   */
  state: {
    type: [String, Array] as PropType<RequestState | RequestState[]>,
  },
  /**
   * Title for error state
   */
  errorTitle: {
    type: String,
    default: 'An error has occured',
  },
  /**
   * Descriptive text for error state
   */
  errorText: {
    type: String,
    default: 'Content failed to load due to a technical error.',
  },
  /**
   * Whether to show the retry button when in an error state
   */
  showRetry: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Label for the retry button. Only valid if `showRetry` is truthy, and no loading slot is provided.
   */
  retryLabel: {
    type: String,
    default: 'Retry',
  },
  /**
   * Layout overlay variant
   */
  variant: {
    type: String,
    default: 'main',
  },
  /**
   * Layout overlay opacity value
   */
  opacity: {
    type: [String, Number],
    default: '1',
  },
  /**
   * Layout overlay opacity value
   */
  blur: {
    type: String,
    default: '',
  },

  /**
   * Whether to hide content on loading
   */
  hideOnLoading: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to remove content on loading
   */
  removeOnLoading: {
    type: [Boolean, String],
    default: false,
  },
});

const emit = defineEmits({
  'update:isError': (_isError: boolean) => true,
  'update:isLoading': (_isLoading: boolean) => true,
  retry: () => true,
});

const stateArray = computed(() => {
  if (props.state) {
    return Array.isArray(props.state) ? props.state : [props.state];
  }
  return [];
});

const isLoading = computed(() => {
  if (props.loading !== undefined) {
    return props.loading !== false;
  }
  return !!stateArray.value.find((i) => i === 'pending');
});

const isError = computed(() => {
  if (props.error !== undefined) {
    return props.error !== false;
  }
  return !!stateArray.value.find((i) => i === 'error');
});

function onIsErrorChange() {
  emit('update:isError', isError.value);
}

watch(isError, onIsErrorChange, { immediate: true });

function onIsLoadingChange() {
  emit('update:isLoading', isLoading.value);
}

watch(isLoading, onIsLoadingChange, { immediate: true });

const isRetryShown = computed(() => props.showRetry !== false);

const isHiddenOnLoading = computed(() => props.hideOnLoading !== false);

const isRemovedOnLoading = computed(() => props.removeOnLoading !== false);

const isNoWrap = computed(() => props.noWrap !== false);

function retry() {
  emit('retry');
}
</script>

<template>
  <BOverlay
    class="loading-overlay"
    :no-wrap="isNoWrap"
    :show="isLoading"
    :variant="variant"
    :opacity="opacity"
    :blur="blur"
    v-if="!isNoWrap || isLoading || isError"
    v-bind="$attrs"
  >
    <div class="error-wrapper" v-show="isError">
      <div class="error-icon-wrapper">
        <ErrorIcon class="error-icon icon" />
      </div>
      <VRow class="error-container" align-v="center">
        <div>
          <h2 class="mb-1">{{ errorTitle }}</h2>
          <p class="text-muted text-small mb-5">{{ errorText }}</p>
          <VButton v-if="isRetryShown" @click="retry">{{ retryLabel }}</VButton>
        </div>
      </VRow>
    </div>
    <div
      class="h-100 w-100"
      v-if="!isRemovedOnLoading || (isRemovedOnLoading && !isLoading)"
      v-show="!isError && (!isHiddenOnLoading || !isLoading)"
    >
      <slot />
    </div>
    <template #overlay v-if="!isError">
      <slot name="loading-overlay">
        <div class="loading-card card">
          <LoadingIcon class="loading-icon" />
          <div class="loading-text" v-if="loadingText">
            <p class="f-semibold mb-xs">{{ loadingText }}</p>
          </div>
        </div>
      </slot>
    </template>
  </BOverlay>
</template>

<style lang="scss" scoped>
.loading-overlay {
  height: 100%;
  position: unset !important;

  ::v-deep .loading-card {
    min-height: 3rem;
  }

  &.page-overlay {
    .error-wrapper {
      min-height: 30vh;
      padding-left: 2vw;
      min-height: 30vh;
    }
  }

  ::v-deep .error-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .error-container {
      padding: 0 2rem;
    }

    .error-icon-wrapper {
      padding-left: 2rem;
      margin-bottom: 1rem;
      .error-icon {
        font-size: 3rem;
        width: 3rem;
      }
    }
  }
}
</style>
