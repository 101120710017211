export enum PaymentState {
  REGISTERED = 'REGISTERED',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  IN_PROCESS = 'IN_PROCESS',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  SCHEDULED = 'SCHEDULED',
}

export const paymentStateLabels: Record<PaymentState, string> = {
  [PaymentState.REGISTERED]: 'Registered',
  [PaymentState.IN_REVIEW]: 'In Review',
  [PaymentState.REJECTED]: 'Rejected',
  [PaymentState.APPROVED]: 'Approved',
  [PaymentState.IN_PROCESS]: 'In Process',
  [PaymentState.CONFIRMED]: 'Confirmed',
  [PaymentState.FAILED]: 'Failed',
  [PaymentState.PENDING]: 'Pending',
  [PaymentState.QUEUED]: 'Queued',
  [PaymentState.SCHEDULED]: 'Scheduled',
};
