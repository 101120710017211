import { CurrencyData } from 'ah-api-gateways';
import Vue, { watch, Ref, reactive, computed } from 'vue';

export function useCurrencyFormatter(options: { currencyDataReference: Ref<CurrencyData[]>; currency: Ref<string> }) {
  const formatters = reactive<Record<string, Intl.NumberFormat>>({});

  watch(options.currencyDataReference, () => {
    Object.keys(formatters).forEach((key) => Vue.delete(formatters, key));
  });

  return computed(() => {
    if (!formatters[options.currency.value]) {
      const currencyData = options.currencyDataReference.value.find((data) => data.currency === options.currency.value);
      const digits = currencyData ? currencyData.amountDecimalPlaces : 2;

      Vue.set(
        formatters,
        options.currency.value,
        new Intl.NumberFormat('en-GB', {
          maximumFractionDigits: digits,
          minimumFractionDigits: digits,
        })
      );
    }

    return formatters[options.currency.value];
  });
}
