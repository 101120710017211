<script lang="ts" setup>
import TradeReportListing from './TradeReportListing.vue';
import { tradeAndComissionReportTableFields } from '../../models/tradeTableFields';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import TradeReportListFilters from './TradeReportListFilters.vue';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { ExportListType, PaginatedParams, PartnerReport } from 'ah-api-gateways';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';
import { CommissionDisplayType, PaginatedResponse } from '../../../../ah-api-gateways/models';
import { cloneDeep } from 'lodash';
import { computed, ref, reactive, watch } from 'vue';
import { useAhTradesState } from '../..';

const tradeReportListing = ref<InstanceType<typeof TradeReportListing> | null>(null);

const props = withDefaults(
  defineProps<{
    showSearch?: boolean | string;
    showDates?: boolean | string;
    withRowDetails?: boolean | string;
    commonFilters?: any;
    openTrade?: string;
    showExport?: boolean | string;
  }>(),
  {
    showSearch: true,
    showDates: false,
    withRowDetails: false,
    commonFilters: () => ({}),

    showExport: false,
  }
);

const state = reactive({
  // Automatically syncronised via .sync
  dataDownloadState: 'idle',
  sortAndPageParams: {
    sort: 'createdAt',
    sortDirection: 'DESC',
    pageSize: 10,
  } as Partial<PaginatedParams>,
  allFilters: {} as any,
  filter: { pageSize: 10 } as any,
  commissionDisplayType: CommissionDisplayType.ORIGINAL as CommissionDisplayType,
  homeCurrency: null as string | null,
});

const tradeState = useAhTradesState();

const isStackedView = computed(() => {
  return tradeState.mediaQuery.is('smDown');
});

const isApportioned = computed(() => {
  return state.commissionDisplayType === CommissionDisplayType.APPORTIONED;
});

function onFilterUpdate(filter: any) {
  state.filter = { ...filter, pageSize: state.sortAndPageParams?.pageSize };
}

const tableFields = computed(() => {
  const out = cloneDeep(tradeAndComissionReportTableFields(state.homeCurrency ?? undefined));
  const keys = ['gbpPartnerCommissions', 'partnerInitialMarginCreditChargeAmount', 'gbpPartnerNetCommission'];
  out.forEach((field: any) => {
    if (keys.includes(field.key)) {
      field.sortKey = isApportioned.value
        ? 'remaining' + field.key[0].toUpperCase() + field.key.substring(1)
        : field.key;
    }
  });
  return out;
});

function onDataUpdate(data: Partial<PaginatedResponse<PartnerReport>>) {
  state.homeCurrency = data?.list?.find((i) => i.homeCurrency)?.homeCurrency ?? null;
}

watch(
  () => props.openTrade,
  () => {
    if (props.openTrade) {
      state.filter.referenceNumber = props.openTrade;
    }
  },
  { immediate: true }
);

function downloadList(format: ExportListType) {
  tradeReportListing.value!.downloadData(format);
}

state.filter = { ...props.commonFilters, ...state.allFilters };

const debouncedFilterChange = debounce(() => {
  const filter = { ...props.commonFilters, ...state.allFilters };
  if (!isEqual(filter, state.filter)) {
    state.filter = filter;
  }
}, 1500);

function setFilters(filtersAndDisplay: any) {
  const { commissionDisplayType, ...filters } = filtersAndDisplay;
  state.allFilters = filters;
  state.commissionDisplayType = commissionDisplayType;
}

function onFiltersChange() {
  debouncedFilterChange();
}

watch(
  () => state.allFilters,
  () => {
    onFiltersChange();
  }
);
</script>

<template>
  <BoxGrid alignH="start" alignV="stretch">
    <BoxGridBlock>
      <TradeReportListFilters
        showDates
        allowClientNameSearch
        showSearch
        showStates
        :filter="state.filter"
        @update:filter="setFilters"
        :sort-and-page-params.sync="state.sortAndPageParams"
        :openTrade="openTrade"
      />
    </BoxGridBlock>
    <SortSelector
      :fields="tableFields"
      v-if="isStackedView"
      :sortAndPageParams.sync="state.sortAndPageParams"
      class="w-100 ml-1"
    />
    <BoxGridBlock cols="12">
      <TradeReportListing
        ref="tradeReportListing"
        paginationQueryParam="tradeCommissionSort"
        filterQueryParam="tradeCommissionFilter"
        :config="{ tableFields }"
        :filter="state.filter"
        @update:filter="onFilterUpdate"
        @update:tableData="onDataUpdate"
        :displayType="state.commissionDisplayType"
        :sortAndPageParams.sync="state.sortAndPageParams"
        :withRowDetails="withRowDetails"
      />
    </BoxGridBlock>
    <BoxGridItem cols="12">
      <ListDownloadButton
        v-if="showExport !== false"
        :loading="state.dataDownloadState === 'pending'"
        class="download-button"
        @download="downloadList"
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.trade-details {
  padding: 0 math.div($padded-space, 2);
}

.dates-filter-holder {
  flex-basis: 26em;
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep {
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }

    .field-group-field-input.month,
    .field-group-field-input.day {
      width: 3rem !important;
    }

    .field-group-field-input.year {
      width: 4rem !important;
    }
  }
}
</style>
