<script setup lang="ts">
import { AnimatedListingWithSubViewState } from '@/app/components/common/animation/AnimatedListingWithSubView.vue';
import { agentTableFieldsFull, agentTableFieldsMinimal } from '@/app/components/agent/tableFields/agentTableFields';
import AddIndividualModal from '@/app/components/individual/AddIndividualModal.vue';
import { Individual, IndividualType } from 'ah-api-gateways';
import IndividualInfo from '@/app/components/individual/IndividualInfo.vue';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import IndividualListing from '@/app/components/individual/IndividualListing.vue';
import {
  companyMemberTableFieldsFull,
  companyMemberTableFieldsMinimal,
} from '@/app/components/individual/tableFields/companyMemberTableFields';
import { useAuthStore } from '@/app/store/authStore';
import { computed, ref } from 'vue';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';
import { useRouter } from 'vue-router/composables';

const props = defineProps({
  memberId: {
    type: String,
    required: false,
  },
});

const router = useRouter();

const mediaQuery = useMediaQuery();

const individualListing = ref<IndividualListing>();

// Auto-set by AnimatedListingWithSubView[animatedState.sync] in template
const animatedState = ref<AnimatedListingWithSubViewState>();

const authStore = useAuthStore();

const individualsFilter = computed(() => {
  if (authStore.isAgent) {
    return {
      type: [IndividualType.PARTNER_ADMIN, IndividualType.PARTNER_AGENT],
    };
  } else {
    return {
      type: [IndividualType.CLIENT_ADMIN, IndividualType.CLIENT_INDIVIDUAL],
    };
  }
});

const memberTableConfig = computed(() => {
  if (authStore.isAgent) {
    return mediaQuery.is('mdDown') && !animatedState.value?.wideList
      ? { tableFields: agentTableFieldsMinimal }
      : { tableFields: agentTableFieldsFull };
  } else {
    return mediaQuery.is('mdDown') && !animatedState.value?.wideList
      ? { tableFields: companyMemberTableFieldsMinimal }
      : { tableFields: companyMemberTableFieldsFull };
  }
});

function onIndividualUpdated() {
  individualListing.value?.loadData();
}

function onIndividualDeleted() {
  gotoMember();
  if (individualListing.value) {
    individualListing.value.loadData();
  }
}

function gotoMember(agent?: Individual) {
  if (!agent || props.memberId === agent.id) {
    router.push({ path: '..', append: true });
  } else {
    if (props.memberId) {
      router.push({ path: `../${agent.id}`, append: true });
    } else {
      router.push({ path: agent.id, append: true });
    }
  }
}
</script>

<template>
  <div class="padded-space" id="members-settings-view">
    <div class="centered-space">
      <h2>Members <InfoTooltip :text="$t('tooltips.users')" /></h2>
      <AddIndividualModal v-slot="{ show }" @individual-invited="onIndividualUpdated">
        <VButton class="invite-user-button" blurOnClick @click="show">Invite User</VButton>
      </AddIndividualModal>
      <BackButton
        class="d-block mb-0 back-button-mobile"
        @click="gotoMember()"
        v-if="memberId && $mediaQuery.is('mdDown')"
        small
      />
      <AnimatedListingWithSubView
        :wideListBlockProps="{ cols: 8, lg: 8, sm: 12 }"
        :narrowListBlockProps="{ cols: 4, lg: 6, sm: 6 }"
        :infoBlockProps="{ cols: 8, lg: 6, sm: 12 }"
        :showInfo="!!memberId"
        :animatedState.sync="animatedState"
        :listingBlockClass="memberId ? 'd-sm-none d-lg-block' : 'd-block'"
      >
        <template #listing>
          <BackButton
            class="back-to-list"
            @click="gotoMember()"
            v-if="animatedState && !animatedState.wideList && $mediaQuery.is('lgUp')"
            small
          />

          <IndividualListing
            ref="individualListing"
            :filter="individualsFilter"
            :config="memberTableConfig"
            showEmptyHeaders
            withRowSubView
            @row-clicked="gotoMember"
            :selectedItems="[memberId]"
          />
        </template>
        <template #sub-view>
          <IndividualInfo
            :individualId="memberId"
            @individual-deleted="onIndividualDeleted"
            @individual-updated="onIndividualUpdated"
          />
        </template>
      </AnimatedListingWithSubView>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.centered-space {
  position: relative;
}

.invite-user-button {
  position: absolute;
  top: 0em;
  right: 0;
}

.back-to-list {
  position: absolute;
  top: 16px;
  right: 10px;
  z-index: 1;
}

.back-button-mobile {
  margin-top: -1rem;
  ::v-deep * {
    font-size: $font-size-xs;
  }
}
</style>
