<script setup lang="ts">
import { RegistrationStepKeys } from '@/app/helpers/registration/flow';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import {
  BaseIndividual,
  Client,
  OnboardingIndividualInfo,
  PaginatedResponse,
  PublicComplianceCase,
  UnsubmittedUbo,
  UploadedFile,
} from 'ah-api-gateways';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { isArray } from 'lodash';
import { forkJoin, from, Observable, of } from 'rxjs';
import { computed, onBeforeMount, ref } from 'vue';
import CompanyDetailsReview from './forms/CompanyDetailsReview.vue';
import ContactDetailsReview from './forms/ContactDetailsReview.vue';
import DocumentsReview from './forms/DocumentsReview.vue';
import TradingDetailsReview from './forms/TradingDetailsReview.vue';
import ClientMembersReview from './forms/ClientMembersReview.vue';
import SignatoryDetailsReview from './forms/SignatoryDetailsReview.vue';
import ClientRejectionModal from './modals/ClientRejectionModal.vue';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { catchError, defaultIfEmpty, mergeMap, map } from 'rxjs/operators';
import { waitForEntityChange } from 'ah-requests';

const props = withDefaults(
  defineProps<{
    client: Client;
    allowEdit?: RegistrationStepKeys[] | boolean;
    successBtnLabel?: string;
  }>(),
  { allowEdit: () => [], successBtnLabel: 'Approve' }
);

const emit = defineEmits({
  proceed: () => true,
  'update:client': (_client: Client) => true,
});

function checkEditAllowance(step: RegistrationStepKeys) {
  return props.allowEdit === true || (isArray(props.allowEdit) && props.allowEdit.includes(step));
}

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const individualStore = useIndividualSettingsStore();

const clientId = computed(() => authStore.loggedInIdentity?.client?.id);

const isOwner = computed(() => authStore.loggedInIdentity?.owner);

const complianceCase = ref<PublicComplianceCase>();

const individuals = ref<OnboardingIndividualInfo[]>([]);

const ubos = ref<UnsubmittedUbo[]>([]);

const applicant = ref<OnboardingIndividualInfo>();

const documents = ref<UploadedFile[]>([]);

const owner = computed({
  get: () => individuals.value?.find((i) => i.owner !== false)!,
  set: (value: OnboardingIndividualInfo) => {
    if (individuals.value && value) {
      const index = individuals.value.findIndex((i) => i.owner !== false);
      if (index > -1) {
        individuals.value.splice(index, 1, value);
      } else {
        individuals.value.push(value);
      }
    }
  },
});

function approve() {
  emit('proceed');
}

function reject() {
  requestManager
    .currentOrNew('rejectClient', services.client.rejectTermsAndConditions(clientId.value!))
    .subscribe(() => authStore.logout());
}

function loadUbosRequest(clientId: string): Observable<UnsubmittedUbo[]> {
  return services.compliance.listUbos(clientId).pipe(
    mergeMap((ubos: PaginatedResponse<BaseIndividual>) =>
      forkJoin(
        ubos.list.map((ubo) =>
          forkJoin([
            services.compliance.getUboDocuments(clientId, ubo.id!),
            services.compliance.listAddressHistoryItems({
              uboId: ubo.id!,
              pageSize: 30,
            }),
          ]).pipe(
            catchError(() => of([])),
            map(
              ([
                documents,
                {
                  list: [currentAddress, ...previousAddresses],
                },
              ]) => ({ ...ubo, documents, currentAddress, previousAddresses } as UnsubmittedUbo)
            )
          )
        )
      ).pipe(defaultIfEmpty([] as UnsubmittedUbo[]))
    )
  );
}

function loadIndividualsRequest(clientId: string): Observable<OnboardingIndividualInfo[]> {
  return waitForEntityChange(
    () => services.individual.listIndividuals({ clientId: clientId }, { errors: { silent: true } }),
    (list) => list.total > 0,
    {
      ignoreErrors: () => true,
    }
  ).pipe(
    mergeMap((response) =>
      forkJoin(
        response.list.map((individual) =>
          services.compliance
            .listAddressHistoryItems({
              individualId: individual.id!,
              pageSize: 30,
            })
            .pipe(
              catchError(() => of({ list: [] })),
              map(({ list: [currentAddress, ...previousAddresses] }) => ({
                ...individual,
                currentAddress,
                previousAddresses,
              }))
            )
        )
      )
    )
  );
}

function loadData() {
  if (clientId.value) {
    requestManager
      .sameOrCancelAndNew(
        'loadData',
        forkJoin([
          from(individualStore.loadClientDocuments(true)),
          services.compliance.getClientComplianceCase(clientId.value),
          loadUbosRequest(clientId.value),
          loadIndividualsRequest(clientId.value),
        ])
      )
      .subscribe(([loadedDocuments, caseObj, uboList, individualsList]) => {
        documents.value = loadedDocuments;
        complianceCase.value = caseObj;
        ubos.value = uboList;
        individuals.value = individualsList;
        applicant.value = individualsList.find((ind) => ind.applicant);
      });
  }
}

onBeforeMount(loadData);
</script>

<template>
  <div x-test-name="onboarding-review">
    <div class="text-secondary">
      <p v-if="applicant">
        {{ `${applicant.firstName} ${applicant.lastName}` }} has completed an application on behalf of
        {{ client.name }}.  Please check the details of the application, read the T&C's and sign the form to submit the
        application.
      </p>
    </div>
    <LoadingOverlay :state="requestManager.requestStates.loadData" show-retry @retry="loadData">
      <div v-if="client && complianceCase">
        <SignatoryDetailsReview
          :model.sync="owner"
          :editable="checkEditAllowance(RegistrationStepKeys.SIGNATORY)"
          v-if="!isOwner"
        />
        <ContactDetailsReview
          :applicant="applicant"
          :client="client"
          :editable="checkEditAllowance(RegistrationStepKeys.PERSONAL_DETAILS)"
          v-if="applicant"
        />
        <CompanyDetailsReview
          :model="client"
          :editable="checkEditAllowance(RegistrationStepKeys.COMPANY_DETAILS)"
          @update:model="emit('update:client', $event)"
        />
        <ClientMembersReview
          :model.sync="individuals"
          :editable="checkEditAllowance(RegistrationStepKeys.ADDITIONAL_USERS)"
        />
        <TradingDetailsReview
          :model.sync="complianceCase"
          :permissions="client.permissions"
          :editable="checkEditAllowance(RegistrationStepKeys.TRADING_DETAILS)"
        />
        <DocumentsReview
          :documents.sync="documents"
          :ubos.sync="ubos"
          :client="client"
          :editable="checkEditAllowance(RegistrationStepKeys.DOCUMENTATION)"
        />

        <div class="row justify-content-center px-4 mb-4" v-if="isOwner">
          <ClientRejectionModal
            class="col-5 mx-2"
            :client="client"
            v-if="applicant"
            :applicant="applicant"
            @reject-application="reject"
          />
          <VButton class="col-5 mx-2" blurOnClick @click="approve"> {{ successBtnLabel }} </VButton>
        </div>
      </div>
    </LoadingOverlay>
  </div>
</template>

<style lang="scss" scoped>
::v-deep.card-block {
  margin-bottom: 2rem;
}

::v-deep {
  .btn-danger {
    background-color: transparent;
    border: 1px solid getColor($color-danger);
    color: getColor($color-danger);

    &:hover,
    &:focus {
      color: white;
    }
  }
}
</style>
