export enum PaymentRail {
  SWIFT = 'SWIFT',
  SEPA = 'SEPA',
  FASTER = 'FASTER',
  FED_ACH = 'FED_ACH',
  AUS_PAY_NET = 'AUS_PAY_NET',
  EFT = 'EFT',
  CERTIS = 'CERTIS',
  STRAKSCLEARING_INSTANT_INTRADAGCLEARING = 'STRAKSCLEARING_INSTANT_INTRADAGCLEARING',
  CHATS = 'CHATS',
  FINA = 'FINA',
  GIRO_ZRT = 'GIRO_ZRT',
  TRANSFOND_SENT_ACH = 'TRANSFOND_SENT_ACH',
  SKN = 'SKN',
  IMPS_NEFT = 'IMPS_NEFT',
  SPEI = 'SPEI',
  IBG = 'IBG',
  NORWEGIAN_INTERBANK_CLEARING_SYSTEM = 'NORWEGIAN_INTERBANK_CLEARING_SYSTEM',
  PESONET = 'PESONET',
  ELIXIR = 'ELIXIR',
  RIX = 'RIX',
  FAST = 'FAST',
  ACH = 'ACH',
  FEDWIRE = 'FEDWIRE',
}

export const paymentRailLabels: Record<PaymentRail, string> = {
  [PaymentRail.SWIFT]: 'SWIFT',
  [PaymentRail.SEPA]: 'SEPA',
  [PaymentRail.FASTER]: 'FASTER',
  [PaymentRail.FED_ACH]: 'FedACH',
  [PaymentRail.AUS_PAY_NET]: 'Australian PAY NET',
  [PaymentRail.EFT]: 'EFT',
  [PaymentRail.CERTIS]: 'Certis',
  [PaymentRail.STRAKSCLEARING_INSTANT_INTRADAGCLEARING]: 'Straksclearingen',
  [PaymentRail.CHATS]: 'CHATS',
  [PaymentRail.FINA]: 'FINA',
  [PaymentRail.GIRO_ZRT]: 'GIRO Zrt',
  [PaymentRail.TRANSFOND_SENT_ACH]: 'TransFonD SENT ACH',
  [PaymentRail.SKN]: 'SKN',
  [PaymentRail.IMPS_NEFT]: 'IMPS/NEFT',
  [PaymentRail.SPEI]: 'SPEI',
  [PaymentRail.IBG]: 'Interbank GIRO',
  [PaymentRail.NORWEGIAN_INTERBANK_CLEARING_SYSTEM]: 'NORWEGIAN_INTERBANK_CLEARING_SYSTEM',
  [PaymentRail.PESONET]: 'PESONET',
  [PaymentRail.ELIXIR]: 'ELIXIR',
  [PaymentRail.RIX]: 'RIX',
  [PaymentRail.FAST]: 'FAST',
  [PaymentRail.ACH]: 'ACH',
  [PaymentRail.FEDWIRE]: 'Fed Wire',
};

export const paymentRailDescriptions: Record<PaymentRail, string> = {
  [PaymentRail.SWIFT]: 'Society for Worldwide Interbank Financial Telecommunication',
  [PaymentRail.SEPA]: 'Single Euro Payments Area',
  [PaymentRail.FASTER]: 'Faster Payment System',
  [PaymentRail.FED_ACH]: 'Automated Clearing House',
  [PaymentRail.AUS_PAY_NET]: 'Australian Payments Network',
  [PaymentRail.EFT]: 'Electronic Funds Transfer',
  [PaymentRail.CERTIS]: 'Czech Express Real Time Interbank Gross Settlement system',
  [PaymentRail.STRAKSCLEARING_INSTANT_INTRADAGCLEARING]: 'Straksclearingen',
  [PaymentRail.CHATS]: 'Clearing House Automated Payment System',
  [PaymentRail.FINA]: 'FINA',
  [PaymentRail.GIRO_ZRT]: 'GIRO Zrt',
  [PaymentRail.TRANSFOND_SENT_ACH]: 'Transfond SENT Automated Clearing House',
  [PaymentRail.SKN]: 'Bank Indonesia National Clearing System',
  [PaymentRail.IMPS_NEFT]: 'Immediate Payments Service / National Electronic Fund Transfer',
  [PaymentRail.SPEI]: "Mexico's real time gross settlements payments system",
  [PaymentRail.IBG]: 'Interbank GIRO',
  [PaymentRail.NORWEGIAN_INTERBANK_CLEARING_SYSTEM]: 'Interbank clearing system for the Norwegian Krone',
  [PaymentRail.PESONET]:
    'Interbank funds transfer service under the National Retail Payment System of the Bangko Sentral ng Pilipinas',
  [PaymentRail.ELIXIR]: 'Elixir',
  [PaymentRail.RIX]: 'Swedish payment system',
  [PaymentRail.FAST]: 'Faster Payment System',
  [PaymentRail.ACH]: 'Automated Clearing House',
  [PaymentRail.FEDWIRE]: 'Fed Wire',
};
