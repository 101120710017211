<script setup lang="ts">
import { Observable } from 'rxjs';
import { makeFormModel } from '../form/helpers/formMaker';
import { otpField } from '../form/models/commonFields';
import { FormDefinition, FormEvent } from '../form/interfaces/form';
import LoadingIconVue from '../icons/components/LoadingIcon.vue';
import { VButton } from '../common/components';
import { otpMessage } from 'ah-common-lib/src/helpers/otp';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';
import { onBeforeMount, PropType, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { FormValidation } from 'src/form/interfaces';

const props = defineProps({
  title: {
    type: String,
    default: 'The security of your account is important to us.',
  },
  staticOTP: {
    type: Boolean,
    default: false,
  },
  forbidOtpRequest: {
    type: Boolean,
    default: false,
  },
  otpPhone: {
    type: String,
    default: '',
  },
  autoRequest: {
    type: [Boolean, String],
    default: false,
  },
  preSent: {
    type: [Boolean, String],
    default: false,
  },
  refreshOtpRequest: {
    type: Function as PropType<() => Observable<any>>,
    required: true,
  },
  expiry: {
    type: Object as PropType<ExpiryTime>,
  },
});

const emit = defineEmits({
  'success-requested': () => true,
  'update:validation': (_validation: FormValidation) => true,
  'update:otp': (_otp: Array<any>) => true,
});

const state = ref<'unsent' | 'autosending' | 'sent' | 'invalid' | 'expired'>('unsent');

const otpFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'otpForm',
    title: 'OTP',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  }),
  validation: null,
});

const minutesToExpire = ref<number | null>(null);

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'refreshOtp') {
      refreshOtp();
    }
  },
}).manager;

onBeforeMount(() => {
  if (props.preSent !== false) {
    state.value = 'sent';
    otpFormDef.form.otp = [];
  } else if (props.autoRequest !== false) {
    state.value = 'autosending';
    refreshOtp();
  }
});

function setOtpError(error: 'invalid' | 'expired') {
  state.value = error;
}

function refreshOtp() {
  requestManager.currentOrNew('refreshOtp', props.refreshOtpRequest()).subscribe((res) => {
    state.value = 'sent';
    otpFormDef.form.otp = [];
    emit('success-requested');
    if (res?.expiresIn) {
      minutesToExpire.value = res.expiresIn / 60;
    }
  });
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit('update:otp', (otpFormDef.form.otp ?? []).join(''));
  }
}

function onExpiryChange() {
  if (props.expiry?.expiresIn) {
    minutesToExpire.value = props.expiry.expiresIn / 60;
  }
}

watch(() => props.expiry, onExpiryChange, { immediate: true });

defineExpose({ setOtpError: setOtpError });
</script>

<template>
  <div>
    <p class="resend-code-text text-center">{{ title }}</p>
    <div v-if="state === 'unsent'">
      <p class="text-center">
        To proceed, you must verify your phone number<span v-if="otpPhone"> ending in {{ otpPhone }}</span
        >.
      </p>
      <p class="text-center">
        <VButton
          :loading="requestManager.requestStates.refreshOtp === 'pending'"
          @click="refreshOtp"
          :disabled="forbidOtpRequest !== false"
        >
          Send SMS code
        </VButton>
      </p>
    </div>
    <div v-else-if="state === 'autosending'" class="loading-wrapper">
      <LoadingIconVue class="loading-icon" />
    </div>
    <div v-else>
      <p class="resend-code-text text-center" v-html="otpMessage(otpPhone, minutesToExpire ?? undefined)" />
      <p v-if="staticOTP" class="text-secondary text-center">For testing purposes, code is always 123456</p>
      <ValidatedForm
        ref="form"
        :fm="otpFormDef.form"
        @form-event="onFormEvent"
        @update:validation="$emit('update:validation', $event)"
      />
      <p v-if="state === 'invalid' || state === 'expired'" class="text-error text-center">
        {{ state === 'invalid' ? 'Invalid OTP code sent.' : 'OTP code expired' }}
        <a href="" @click.prevent="refreshOtp" :disabled="requestManager.requestStates.refreshOtp === 'pending'">
          {{ requestManager.requestStates.refreshOtp !== 'pending' ? 'Resend code?' : 'Resending...' }}
        </a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-wrapper {
  text-align: center;
  font-size: 3em;
}
</style>
