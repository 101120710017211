<script lang="ts" setup>
import {
  Client,
  IndividualTargetSelection,
  IndividualReference,
  partnerUserIndividualTypes,
  AssigneeReference,
  RegistrationStatus,
} from 'ah-api-gateways';
import TargetSearchSelect from '@/app/components/user/TargetSearchSelect.vue';
import InfoTooltip from '../common/InfoTooltip.vue';
import ClientAssigneeBlock from './ClientAssigneeBlock.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, PropType, ref, watch } from 'vue';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';

/**
 * Client Assignees View/Edit Modal
 * Allows user to see an editable list of a Client's Assignees
 *
 * Emits:
 * - saved-assignees - emitted when assignees are saved
 */

const props = defineProps({
  client: {
    required: true,
    type: Object as PropType<Client>,
  },
});

const emit = defineEmits({
  'saved-assignees': () => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'getAssignees') {
      loadAssignees();
    }
    if (k === 'setClientAssignees') {
      save();
    }
  },
}).manager;

const toast = useToast();

const services = getServices();

const clientAssignees = ref<AssigneeReference[]>([]);

const savedClientAssignees = ref<AssigneeReference[]>([]);

const modalShown = ref(false);

const hasAssignees = computed(() => {
  return !!clientAssignees.value.length;
});

const disallowedAgentIds = computed(() => {
  return clientAssignees.value.map((r) => r.individual.id);
});

const agentFilters = computed(() => {
  return {
    type: partnerUserIndividualTypes,
    status: [RegistrationStatus.PENDING, RegistrationStatus.APPROVED, RegistrationStatus.REJECTED],
  };
});

const primaryAssignee = computed(() => {
  return clientAssignees.value.find((c) => c.primary === true);
});

const additionalAssignees = computed(() => {
  return clientAssignees.value.filter((c) => c.primary === false);
});

const isAssigneeListValid = computed(() => {
  if (clientAssignees.value.length === 0) {
    return true;
  }
  return !!primaryAssignee.value;
});

function loadAssignees() {
  if (props.client) {
    requestManager
      .sameOrCancelAndNew('getAssignees', services.client.getClientAssignees(props.client.id), props.client.id)
      .subscribe((response) => {
        savedClientAssignees.value = [...response];
        clientAssignees.value = [...response];
      });
  }
}

function isInAssigneesList(target: IndividualReference) {
  return !!clientAssignees.value.find((i) => i.individual.id === target.id);
}

function onTargetSelected(target: IndividualTargetSelection) {
  if (!isInAssigneesList(target.individual)) {
    clientAssignees.value.push({
      individual: target.individual,
      primary: false,
    });
  }
}

function deleteTarget(target: AssigneeReference) {
  const index = clientAssignees.value.findIndex((i) => i.individual.id === target.individual.id);
  if (index > -1) {
    clientAssignees.value.splice(index, 1);
  }
}

function makePrimaryAssignee(target?: AssigneeReference) {
  const currentPrimary = clientAssignees.value.find((i) => i.primary === true);
  if (currentPrimary) {
    currentPrimary.primary = false;
  }
  if (target) {
    target.primary = true;
  }
}

function show() {
  modalShown.value = true;
}

function hide() {
  modalShown.value = false;
}

function save() {
  if (clientAssignees.value.length <= 0) {
    toast.error('At least one of the assignees must be marked as primary');
    return;
  }
  requestManager
    .new(
      'setClientAssignees',
      services.client.setClientAssignees(
        props.client.id,
        clientAssignees.value.map((assignee) => ({
          id: assignee.individual.id,
          primary: assignee.primary,
        }))
      )
    )
    .subscribe(() => {
      emit('saved-assignees');
      toast.success(`Saved assignees of the client ${props.client.name} successfully`);
      hide();
    });
}

function cancel() {
  clientAssignees.value = [...savedClientAssignees.value];
  hide();
}

watch(() => props.client.id, loadAssignees, { immediate: true });
</script>

<template>
  <span>
    <BModal title="Edit assignees" modal-class="side-modal modal-lg" hide-footer v-model="modalShown">
      <TargetSearchSelect
        class="mt-3 mb-3"
        @target-selected="onTargetSelected"
        :types="{
          individual: {
            filters: agentFilters,
            disallowed: disallowedAgentIds,
          },
        }"
      />
      <div class="card-block recipients-list mb-3">
        <h3 class="recipients-list-title">{{ client.name }} assignees</h3>
        <template v-if="hasAssignees">
          <Label class="text-secondary text-small">PRIMARY ASSIGNEE</Label>
          <BoxGrid align-content="start" align-h="start" class="group-targets-holder">
            <BoxGridItem class="group-target-item-holder" v-if="primaryAssignee">
              <ClientAssigneeBlock
                class="group-target-item"
                :assigneeRef="primaryAssignee"
                @delete="deleteTarget(primaryAssignee)"
                @remove-primary="makePrimaryAssignee()"
              />
            </BoxGridItem>
            <div v-else class="ml-1">
              <InfoTooltip class="red-info-circle" style="color: red" /> &nbsp; select a user from the list below as the
              primary assignee for this client.
            </div>
          </BoxGrid>
          <template v-if="additionalAssignees.length > 0">
            <Label class="text-secondary text-small">ADDITIONAL ASSIGNEES</Label>
            <BoxGrid align-content="start" align-h="start" class="group-targets-holder">
              <BoxGridItem class="group-target-item-holder" :key="assignee.id" v-for="assignee in additionalAssignees">
                <ClientAssigneeBlock
                  class="group-target-item"
                  :assigneeRef="assignee"
                  @delete="deleteTarget(assignee)"
                  @set-primary="makePrimaryAssignee(assignee)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
        </template>
        <p v-else class="text-secondary">No assignees added yet</p>
      </div>
      <div class="buttons text-md-left text-sm-center">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton
          class="done-btn btn-success"
          :disabled="!isAssigneeListValid"
          :loading="requestManager.anyPending"
          @click="save"
          >Save Changes</VButton
        >
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<style lang="scss">
.recipients-list-title {
  margin-bottom: math.div($padded-space, 3);
}

.group-targets-holder {
  display: flex;
  margin-bottom: math.div($padded-space, 2);

  &:last-child {
    margin-bottom: 0;
  }

  .group-target-item-holder {
    flex-basis: 33%;
    flex-grow: 0;
    min-width: 200px;

    @include upToResolution($tabletResolution) {
      min-width: 100% !important;
    }

    .group-target-item {
      width: 100%;
    }
  }
}
.red-info-circle {
  color: red;
  font-size: 1.8em;
}
</style>
