export enum TransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  WALLETS_MOVEMENT = 'WALLETS_MOVEMENT',
  POST_COLLATERAL = 'POST_COLLATERAL',
  WITHDRAW_COLLATERAL = 'WITHDRAW_COLLATERAL',
  COLLECT_COLLATERAL = 'COLLECT_COLLATERAL',
  ROLLBACK = 'ROLLBACK',
  ADJUSTMENT = 'ADJUSTMENT',
  PENDING_BALANCE = 'PENDING_BALANCE',
}

export enum ScheduleState {
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  READY = 'READY',
  SUBMITTED = 'SUBMITTED',
  EXECUTED = 'EXECUTED',
  FAILED_EXTERNALLY = 'FAILED_EXTERNALLY',
  FAILED_EXECUTION = 'FAILED_EXECUTION',
}

export enum TransactionReason {
  DEPOSIT_FROM_OWNER = 'DEPOSIT_FROM_OWNER',
  DEPOSIT_FROM_UNKNOWN = 'DEPOSIT_FROM_UNKNOWN',

  WITHDRAW_CLIENT_TO_AH_FEE = 'WITHDRAW_CLIENT_TO_AH_FEE',
  WITHDRAW_AH_TO_PARTNER_FEE = 'WITHDRAW_AH_TO_PARTNER_FEE',
  WITHDRAW_PARTNER_TO_AH_FEE = 'WITHDRAW_PARTNER_TO_AH_FEE',
  DEPOSIT_PARTNER_TO_AH_FEE = 'DEPOSIT_PARTNER_TO_AH_FEE',
  DEPOSIT_AH_TO_PARTNER_FEE = 'DEPOSIT_AH_TO_PARTNER_FEE',
  DEPOSIT_CLIENT_TO_AH_FEE = 'DEPOSIT_CLIENT_TO_AH_FEE',
  WITHDRAW_TO_OWNER_CLIENT_TO_AH_FEE = 'WITHDRAW_TO_OWNER_CLIENT_TO_AH_FEE',
  WITHDRAW_TO_BENEFICIARY_CLIENT_TO_AH_FEE = 'WITHDRAW_TO_BENEFICIARY_CLIENT_TO_AH_FEE',

  REFUND_FEE_AH_TO_CLIENT = 'REFUND_FEE_AH_TO_CLIENT',
  REFUND_FEE_AH_TO_PARTNER = 'REFUND_FEE_AH_TO_PARTNER',
  CUSTOM_FEE_CLIENT_TO_AH = 'CUSTOM_FEE_CLIENT_TO_AH',
  CUSTOM_FEE_PARTNER_TO_AH = 'CUSTOM_FEE_PARTNER_TO_AH',

  WITHDRAW_TO_OWNER = 'WITHDRAW_TO_OWNER',
  WITHDRAW_SEND_MONEY_TO_BENEFICIARY = 'WITHDRAW_SEND_MONEY_TO_BENEFICIARY',

  POST_COLLATERAL_BY_CLIENT = 'POST_COLLATERAL_BY_CLIENT',
  WITHDRAW_COLLATERAL_BY_CLIENT = 'WITHDRAW_COLLATERAL_BY_CLIENT',
  WITHDRAW_COLLATERAL_FOR_TRADE = 'WITHDRAW_COLLATERAL_FOR_TRADE',
  ROLLBACK_COLLATERAL_USED_FOR_TRADE = 'ROLLBACK_COLLATERAL_USED_FOR_TRADE',

  ADD_PENDING_DEBIT = 'ADD_PENDING_DEBIT', // Debit the wallet balance and add the amount to pendingDebit balance
  REMOVE_PENDING_DEBIT = 'REMOVE_PENDING_DEBIT', // Credit back the amount into wallet balance and subtract the pendingDebit balance

  ADD_PENDING_CREDIT = 'ADD_PENDING_CREDIT', // Credit the wallet balance and add the amount to pendingCredit balance
  REMOVE_PENDING_CREDIT = 'REMOVE_PENDING_CREDIT', // Debit the amount from wallet balance and subtract the pendingCredit balance

  COLLECT_COLLATERAL_CLIENT_TO_AH = 'COLLECT_COLLATERAL_CLIENT_TO_AH',
  PAY_DEBT_CLIENT_TO_AH = 'PAY_DEBT_CLIENT_TO_AH',
  TRADE_MARGIN_CLIENT_TO_AH = 'TRADE_MARGIN_CLIENT_TO_AH',
  TRADE_MARGIN_AH_TO_CLIENT = 'TRADE_MARGIN_AH_TO_CLIENT',
  TRADE_PARTNER_PROFIT_AH_TO_PARTNER = 'TRADE_PARTNER_PROFIT_AH_TO_PARTNER',
  TRADE_SWAP_PNL_AH_TO_PARTNER = 'TRADE_SWAP_PNL_AH_TO_PARTNER',
  TRADE_DRAWDOWN_PNL_AH_TO_PARTNER = 'TRADE_DRAWDOWN_PNL_AH_TO_PARTNER',
  TRADE_DRAWDOWN_PNL_PARTNER_TO_AH = 'TRADE_DRAWDOWN_PNL_PARTNER_TO_AH',
  TRADE_SELL_CURRENCY_CLIENT_TO_AH = 'TRADE_SELL_CURRENCY_CLIENT_TO_AH',
  TRADE_SELL_CURRENCY_CLIENT_TO_AH_PENDING_DEBIT = 'TRADE_SELL_CURRENCY_CLIENT_TO_AH_PENDING_DEBIT',
  TRADE_SELL_CURRENCY_AH_TO_CLIENT_FX = 'TRADE_SELL_CURRENCY_AH_TO_CLIENT_FX',
  TRADE_SELL_CURRENCY_AH_TO_LP = 'TRADE_SELL_CURRENCY_AH_TO_LP',
  TRADE_SELL_CURRENCY_CLIENT_FX_TO_LP = 'TRADE_SELL_CURRENCY_CLIENT_FX_TO_LP',
  TRADE_BUY_CURRENCY_LP_TO_AH = 'TRADE_BUY_CURRENCY_LP_TO_AH',
  TRADE_BUY_CURRENCY_LP_TO_CLIENT_FX = 'TRADE_BUY_CURRENCY_LP_TO_CLIENT_FX',
  TRADE_BUY_CURRENCY_AH_TO_CLIENT = 'TRADE_BUY_CURRENCY_AH_TO_CLIENT',
  TRADE_BUY_CURRENCY_AH_TO_CLIENT_PENDING_CREDIT = 'TRADE_BUY_CURRENCY_AH_TO_CLIENT_PENDING_CREDIT',
  TRADE_BUY_CURRENCY_CLIENT_FX_TO_AH = 'TRADE_BUY_CURRENCY_CLIENT_FX_TO_AH',
  TRADE_BUY_CURRENCY_CLIENT_TO_BENEFICIARY = 'TRADE_BUY_CURRENCY_CLIENT_TO_BENEFICIARY',
  TRADE_IMCC_PARTNER_TO_AH = 'TRADE_IMCC_PARTNER_TO_AH',

  ROLLBACK_EXTERNAL_ERROR = 'ROLLBACK_EXTERNAL_ERROR',
  ROLLBACK_EXTERNAL_ERROR_COLLATERAL = 'ROLLBACK_EXTERNAL_ERROR_COLLATERAL',
  ROLLBACK_EXTERNAL_ERROR_PENDING_DEBIT = 'ROLLBACK_EXTERNAL_ERROR_PENDING_DEBIT',
  ROLLBACK_EXTERNAL_ERROR_PENDING_CREDIT = 'ROLLBACK_EXTERNAL_ERROR_PENDING_CREDIT',

  ADJUSTMENT_ERROR_CORRECTION = 'ADJUSTMENT_ERROR_CORRECTION',
  COMMISSION_CONVERSION_BUY_CURRENCY_LP_TO_PARTNER = 'COMMISSION_CONVERSION_BUY_CURRENCY_LP_TO_PARTNER',
  COMMISSION_CONVERSION_SELL_CURRENCY_PARTNER_TO_LP = 'COMMISSION_CONVERSION_SELL_CURRENCY_PARTNER_TO_LP',
}

export const transactionReasonLabels: Record<TransactionReason, string> = {
  [TransactionReason.ADD_PENDING_CREDIT]: 'Reserved incoming sum',
  [TransactionReason.ADD_PENDING_DEBIT]: 'Reserved sum for pending transfer',
  [TransactionReason.ADJUSTMENT_ERROR_CORRECTION]: 'Adjustment error correction',
  [TransactionReason.WITHDRAW_COLLATERAL_FOR_TRADE]: 'Withdraw collateral to settle a trade',
  [TransactionReason.ROLLBACK_COLLATERAL_USED_FOR_TRADE]: 'Rollback withdrawn collateral used to settle a trade',
  [TransactionReason.COLLECT_COLLATERAL_CLIENT_TO_AH]: 'Collateral Collection',
  [TransactionReason.TRADE_IMCC_PARTNER_TO_AH]: 'IM Credit Charges',
  [TransactionReason.DEPOSIT_FROM_OWNER]: 'Deposit',
  [TransactionReason.DEPOSIT_FROM_UNKNOWN]: 'Deposit (Unknown)',
  [TransactionReason.PAY_DEBT_CLIENT_TO_AH]: 'Debt payment',
  [TransactionReason.POST_COLLATERAL_BY_CLIENT]: 'Posted Collateral',
  [TransactionReason.REMOVE_PENDING_CREDIT]: 'Removal of pending credit',
  [TransactionReason.REMOVE_PENDING_DEBIT]: 'Removal of pending debit',
  [TransactionReason.ROLLBACK_EXTERNAL_ERROR_COLLATERAL]: 'Rollback (error recovery)',
  [TransactionReason.ROLLBACK_EXTERNAL_ERROR_PENDING_CREDIT]: 'Rollback (error recovery)',
  [TransactionReason.ROLLBACK_EXTERNAL_ERROR_PENDING_DEBIT]: 'Rollback (error recovery)',
  [TransactionReason.ROLLBACK_EXTERNAL_ERROR]: 'Rollback (error recovery)',
  [TransactionReason.TRADE_BUY_CURRENCY_AH_TO_CLIENT_PENDING_CREDIT]: 'Buy Notional in',
  [TransactionReason.TRADE_BUY_CURRENCY_AH_TO_CLIENT]: 'Buy Notional in',
  [TransactionReason.TRADE_BUY_CURRENCY_CLIENT_FX_TO_AH]: 'LP Buy Notional transfer',
  [TransactionReason.TRADE_BUY_CURRENCY_CLIENT_TO_BENEFICIARY]: 'Send to Beneficiary',
  [TransactionReason.TRADE_BUY_CURRENCY_LP_TO_AH]: 'Deposit from LP',
  [TransactionReason.TRADE_BUY_CURRENCY_LP_TO_CLIENT_FX]: 'LP Buy Notional in',
  [TransactionReason.TRADE_DRAWDOWN_PNL_AH_TO_PARTNER]: 'Drawdown Swap PNL commission',
  [TransactionReason.TRADE_DRAWDOWN_PNL_PARTNER_TO_AH]: 'Drawdown Swap PNL commission',
  [TransactionReason.TRADE_MARGIN_AH_TO_CLIENT]: 'Initial margin',
  [TransactionReason.TRADE_MARGIN_CLIENT_TO_AH]: 'Initial margin',
  [TransactionReason.TRADE_PARTNER_PROFIT_AH_TO_PARTNER]: 'MarkUp commission',
  [TransactionReason.TRADE_SELL_CURRENCY_AH_TO_CLIENT_FX]: 'LP Sell Notional transfer',
  [TransactionReason.TRADE_SELL_CURRENCY_AH_TO_LP]: 'Withdraw to LP',
  [TransactionReason.TRADE_SELL_CURRENCY_CLIENT_FX_TO_LP]: 'LP Sell Notional out',
  [TransactionReason.TRADE_SELL_CURRENCY_CLIENT_TO_AH_PENDING_DEBIT]: 'Sell Notional out',
  [TransactionReason.TRADE_SELL_CURRENCY_CLIENT_TO_AH]: 'Sell Notional out',
  [TransactionReason.TRADE_SWAP_PNL_AH_TO_PARTNER]: 'Swap PNL commission',
  [TransactionReason.WITHDRAW_COLLATERAL_BY_CLIENT]: 'Collateral Withdrawn',
  [TransactionReason.WITHDRAW_SEND_MONEY_TO_BENEFICIARY]: 'Send to Beneficiary',
  [TransactionReason.WITHDRAW_TO_BENEFICIARY_CLIENT_TO_AH_FEE]: 'Payment fee',
  [TransactionReason.WITHDRAW_TO_OWNER_CLIENT_TO_AH_FEE]: 'Payment fee',
  [TransactionReason.WITHDRAW_CLIENT_TO_AH_FEE]: 'Apply fee from withdraw to beneficiary operation',
  [TransactionReason.WITHDRAW_AH_TO_PARTNER_FEE]: 'Apply fee from withdraw to beneficiary operation',
  [TransactionReason.WITHDRAW_PARTNER_TO_AH_FEE]: 'Apply fee from withdraw to beneficiary operation',
  [TransactionReason.DEPOSIT_PARTNER_TO_AH_FEE]: 'Payment by partner of deposit fee to in-house account',
  [TransactionReason.DEPOSIT_AH_TO_PARTNER_FEE]: 'Payment by in-house account of deposit fee to partner',
  [TransactionReason.DEPOSIT_CLIENT_TO_AH_FEE]: 'Payment by client of deposit fee to in-house account',
  [TransactionReason.REFUND_FEE_AH_TO_CLIENT]: 'Refund fee by in-house account to client',
  [TransactionReason.REFUND_FEE_AH_TO_PARTNER]: 'Refund fee by in-house account to partner',
  [TransactionReason.CUSTOM_FEE_CLIENT_TO_AH]: 'Custom fee by client to in-house account',
  [TransactionReason.CUSTOM_FEE_PARTNER_TO_AH]: 'Custom fee by partner in-house account',

  [TransactionReason.WITHDRAW_TO_OWNER]: 'Withdraw',
  [TransactionReason.COMMISSION_CONVERSION_BUY_CURRENCY_LP_TO_PARTNER]: 'Comm. conversion',
  [TransactionReason.COMMISSION_CONVERSION_SELL_CURRENCY_PARTNER_TO_LP]: 'Comm. conversion',
};

export enum TransactionState {
  PENDING = 'PENDING',
  REGISTERED = 'REGISTERED',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  IN_PROCESS = 'IN_PROCESS',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}

export const transactionStateLabels: Record<TransactionState, string> = {
  [TransactionState.PENDING]: 'Pending',
  [TransactionState.REGISTERED]: 'Registered',
  [TransactionState.IN_REVIEW]: 'In Review',
  [TransactionState.REJECTED]: 'Rejected',
  [TransactionState.APPROVED]: 'Approved',
  [TransactionState.IN_PROCESS]: 'In process',
  [TransactionState.CONFIRMED]: 'Confirmed',
  [TransactionState.FAILED]: 'Failed',
};

export interface TransactionBase {
  id: string;
  currency: string;
  description: string;
  direction: string;
  reason: TransactionReason;
  tradeId?: string;
  quoteId?: string;
  paymentId?: string;
  type: TransactionType;
  // Amount moved
  amount: number;
  // Balance immediately following the movement
  walletBalance: number;
  // Collateral balance immediately following the movement
  collateralBalance: number;
  // Credit in transit immediately following the movement
  creditInTransit: number;
  // Debit in transit immediately following the movement
  debitInTransit: number;
  // Pending Debit immediately following the movement
  pendingDebit: number;
  // Pending Credit immediately following the movement
  pendingCredit: number;
  // Transaction reference
  reference: string;
}

export interface HistoricalTransactionBase extends TransactionBase {
  id: string;
  trackingId: string;
  scheduleTxId: string;
  walletId: string;
  state: TransactionState;
  docCreatedAt: string;
  docUpdatedAt: string;
  docId: string;
  txId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ScheduledTransactionBase extends TransactionBase {
  dateCreated: string;
  dateUpdated: string;
  executionDate: string;
  scheduleState: ScheduleState;
}

export interface TransactionInternal extends TransactionBase {
  type: TransactionType.WALLETS_MOVEMENT;
  otherWalletId: string;
}

export interface TransactionExternal extends TransactionBase {
  type: Exclude<TransactionType, TransactionType.WALLETS_MOVEMENT>;
  otherBeneficiaryId?: string;
  otherName: string;
  otherSwift: string;
  otherIban: string;
  otherRoutingCode: string;
  otherRoutingNumber: string;
  scheduleType?: string;
}

export type HistoricalTransaction =
  | (TransactionInternal & HistoricalTransactionBase)
  | (TransactionExternal & HistoricalTransactionBase);

export type ScheduledTransaction =
  | (TransactionInternal & ScheduledTransactionBase)
  | (TransactionExternal & ScheduledTransactionBase);

export type Transaction = HistoricalTransaction | ScheduledTransaction;

export function isTrade(transaction: Transaction) {
  return [
    TransactionReason.TRADE_SELL_CURRENCY_CLIENT_TO_AH,
    TransactionReason.TRADE_BUY_CURRENCY_AH_TO_CLIENT,
    TransactionReason.TRADE_SELL_CURRENCY_CLIENT_TO_AH_PENDING_DEBIT,
    TransactionReason.TRADE_BUY_CURRENCY_AH_TO_CLIENT_PENDING_CREDIT,
    TransactionReason.TRADE_MARGIN_CLIENT_TO_AH,
    TransactionReason.WITHDRAW_COLLATERAL_BY_CLIENT,
    TransactionReason.POST_COLLATERAL_BY_CLIENT,
  ].includes(transaction.reason);
}

export function isDeposit(transaction: Transaction) {
  return [
    TransactionReason.DEPOSIT_FROM_OWNER,
    TransactionReason.DEPOSIT_FROM_UNKNOWN,
    TransactionReason.TRADE_BUY_CURRENCY_LP_TO_AH,
  ].includes(transaction.reason);
}

export function isWithdraw(transaction: Transaction) {
  return [
    TransactionReason.WITHDRAW_TO_OWNER,
    TransactionReason.WITHDRAW_SEND_MONEY_TO_BENEFICIARY,
    TransactionReason.TRADE_SELL_CURRENCY_AH_TO_LP,
    TransactionReason.TRADE_BUY_CURRENCY_CLIENT_TO_BENEFICIARY,
  ].includes(transaction.reason);
}

export function isPartnerPayment(transaction: Transaction) {
  return [
    TransactionReason.TRADE_PARTNER_PROFIT_AH_TO_PARTNER,
    TransactionReason.TRADE_SWAP_PNL_AH_TO_PARTNER,
    TransactionReason.TRADE_DRAWDOWN_PNL_AH_TO_PARTNER,
    TransactionReason.TRADE_DRAWDOWN_PNL_PARTNER_TO_AH,
    TransactionReason.TRADE_IMCC_PARTNER_TO_AH,
  ].includes(transaction.reason);
}

export function isSchedule(transaction: Transaction): transaction is ScheduledTransaction {
  return !!(transaction as ScheduledTransaction).executionDate;
}
