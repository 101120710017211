import { getChildModel, getState } from '../helpers';
import { FormModel } from '../interfaces';

/**
 * Getter factory function for a field's state value
 *
 * To be used with validators to create reactive validation based on VM state
 */
export function stateValueGetter(
  fieldName: string,
  stateKey: string,
  defaultValue: any = undefined,
  useHierarchy = true
) {
  return (vm: FormModel) => {
    if (!vm) {
      return false;
    }
    const model = getChildModel(vm, fieldName);
    const state = model && getState(model, stateKey, defaultValue, useHierarchy);

    if (typeof state === 'function') {
      return state(model);
    }
    return state;
  };
}
