<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { computed, PropType, ref } from 'vue';
import { useRouteProtector } from './useRouteProtector';

const props = defineProps({
  allowChange: {
    type: [Boolean, Function] as PropType<boolean | (() => boolean)>,
    default: () => () => true,
  },
  allowSubpaths: {
    type: Boolean,
    default: true,
  },
  allowQueryChange: {
    type: Boolean,
    default: true,
  },
});

const modal = ref<InstanceType<typeof BModal>>();

const routeProtector = useRouteProtector({
  allowSubpaths: computed(() => props.allowSubpaths),
  allowQueryChange: computed(() => props.allowQueryChange),
  exitAllowed: computed(() => canLeave()),
  onNavigationPrevented: (to) => {
    if (modal.value) {
      const confirm = () => {
        routeProtector.forcePush(to.fullPath);
      };

      modal.value.show();
      modal.value.$once('ok', confirm);
      modal.value.$once('hidden', () => {
        modal.value!.$off('ok', confirm);
      });
    }
  },
});

function show() {
  modal.value!.show();
}

function canLeave() {
  return typeof props.allowChange === 'function' ? props.allowChange() : props.allowChange;
}

defineExpose({ show: show });
</script>

<template>
  <BModal ref="modal" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </BModal>
</template>
