<script lang="ts" setup>
import { PaginatedQuery, formatHedgingInstrument, OpenPositionsMtmMonitor } from 'ah-api-gateways';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { PropType, computed } from 'vue';
import { openPositionsMtmTableFields } from '../../models/openPositionsMtmTableFields';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useAhTradesState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { capitalize } from 'lodash';
import { formatCurrencyValue } from '../../../../ah-common-lib/src/helpers/currency';

const props = defineProps({
  clientId: {
    type: String,
  },
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: openPositionsMtmTableFields }),
  },
  ...defineUseManagedListingProps<OpenPositionsMtmMonitor>(),
});

const tradeState = useAhTradesState();

interface OpenPositionsMtmMonitorEmits extends UseManagedListingEmits<OpenPositionsMtmMonitor> {}

const emit = defineEmits<OpenPositionsMtmMonitorEmits>();

const managedListing = useManagedListing<OpenPositionsMtmMonitor>({
  loadDataRequest(query: PaginatedQuery) {
    let params = query;
    if (!tradeState.store.useAuthStore().isClientUser) {
      params = { ...query, clientId: props.clientId };
    }
    return tradeState.services.risk.listOpenPositionsMtm(params);
  },

  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    :stacked="tradeState.mediaQuery.is('smDown')"
  >
    <template #cell(tradeDate)="{ item }">
      {{ formatDate(item.tradeDate, 'dd MMM yyyy') }}
    </template>

    <template #cell(instrument)="{ item }">
      {{ formatHedgingInstrument(item.instrument) }}
    </template>

    <template #cell(settlementDate)="{ item }">
      {{ formatDate(item.settlementDate, 'dd MMM yyyy') }}
    </template>

    <template #cell(ccyPairDirection)="{ item }">
      {{ capitalize(item.ccyPairDirection) }}
    </template>

    <template #cell(remainingGbpAmount)="{ item }">
      {{ formatCurrencyValue(item.remainingGbpAmount) }}
    </template>
    <template #cell(remainingGbpPositionMtm)="{ item }">
      {{ formatCurrencyValue(item.remainingGbpPositionMtm) }}
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep {
  .p-datatable-wrapper {
    .table-title {
      .title-inner {
        font-size: 13px;
        font-weight: 500;
      }
    }
    .p-datatable-tbody {
      tr {
        td {
          font-size: 13px;
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
