<script lang="ts" setup>
import SizeSelector from 'ah-common-lib/src/common/components/SizeSelector.vue';
import { FeeStatus } from 'ah-api-gateways/models/feesAndCharges/fees';
import {
  defineUseManagedListFilterProps,
  useManagedListFilter,
  UseManagedListFilterEmits,
} from 'ah-common-lib/src/listing';
import { computed } from 'vue';

interface OutstandingFeesFilters {
  query?: string;
  type: 'CLIENTS' | 'PARTNERS';
  feeStatus: FeeStatus[];
}

const feeStatusOptions = [
  {
    label: 'Ok',
    value: FeeStatus.OK,
  },
  {
    label: 'Overdue',
    value: FeeStatus.OVERDUE,
  },
  {
    label: 'Due',
    value: FeeStatus.DUE,
  },
];

interface OutstandingFeesFiltersEmits extends UseManagedListFilterEmits<OutstandingFeesFilters> {}

const emit = defineEmits<OutstandingFeesFiltersEmits>();

const props = defineProps({
  ...defineUseManagedListFilterProps<OutstandingFeesFilters>(),
  hideOwnerType: {
    type: [Boolean, String],
    default: false,
  },
  hideSearch: {
    type: [Boolean, String],
    default: false,
  },
});

const displayOwnerType = computed(() => props.hideOwnerType === false);

const displaySearch = computed(() => props.hideSearch === false);

const { filterData, sortAndPageData } = useManagedListFilter<OutstandingFeesFilters>({
  props,
  emit,
  filterKeys: [
    {
      key: 'query',
      getter: (filters) => (displaySearch.value && filters.value.query) || undefined,
      setter: (value: any, filters) => (filters.value.query = value),
    },
    {
      key: 'type',
      getter: (filters) => (displayOwnerType.value && filters.value.type) || undefined,
      setter: (value: any, filters) => (filters.value.type = value),
    },
    {
      key: 'feeStatus',
      getter: (filters) => filters.value.feeStatus || undefined,
      setter: (value: any, filters) => (filters.value.feeStatus = value),
    },
  ],
  sortAndPageKeys: [
    {
      key: 'pageSize',
      getter: (filters) => filters.value.pageSize || undefined,
      setter: (value, filters) => (filters.value.pageSize = value),
    },
  ],
});
</script>

<template>
  <VRow class="mx-1" align-h="between" align-v="center">
    <VCol cols="4" md="4" v-if="displayOwnerType">
      <VButton :class="{ 'btn-primary': filterData.type === 'CLIENTS' }" @click="filterData.type = 'CLIENTS'">
        Clients
      </VButton>
      <VButton :class="{ 'btn-primary': filterData.type === 'PARTNERS' }" @click="filterData.type = 'PARTNERS'">
        Partners
      </VButton>
    </VCol>
    <VCol class="mock-label-padding" cols="4" md="4" sm="12" v-if="displaySearch">
      <SearchInput :placeholder="'Search by Client'" :search.sync="filterData.query" />
    </VCol>
    <VCol cols="4" md="4" sm="12">
      <VRow>
        <VCol cols="8">
          <label>
            Fee Status
            <a
              v-if="filterData.feeStatus && filterData.feeStatus.length > 0"
              class="field-group-clear-link"
              @click="filterData.feeStatus = []"
            >
              clear
            </a>
          </label>
          <TagMultiSelect
            :maxFulltextLabels="2"
            :options="feeStatusOptions"
            :value.sync="filterData.feeStatus"
            itemsCountLabel="status"
          />
        </VCol>
        <VCol>
          <SizeSelector :selected-value.sync="sortAndPageData.pageSize" />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.btn {
  width: 8rem;
  margin-right: 1rem;
}
</style>
