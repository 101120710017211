import { VersionedObject } from '../models';
import { HttpBasedService, HttpService } from 'ah-requests';

export class RiskDecisionService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'riskDecisionService' } },
      },
    });
  }

  public triggerCreditDecisioning(clientId: string) {
    return this.post<VersionedObject>(`${this.baseUrl}risk-decision/${clientId}`);
  }
}
