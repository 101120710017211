<script setup lang="ts">
import { formatDistanceToNowStrict, formatDistanceToNow } from 'date-fns';
import { formatDate, FULL_DATE_AND_TIME_FORMAT } from '../../../helpers/time';
import { onBeforeUnmount, onMounted, PropType, ref } from 'vue';

const props = defineProps({
  date: {
    type: Date,
    required: true,
  },
  dateStyle: {
    type: String as PropType<'long' | 'strict' | 'short'>,
    default: 'long',
  },
});

const updateTimeout = ref<number | null>(null);

const timeAgo = ref('');

onMounted(() => {
  updateTimeAgoText();
});

onBeforeUnmount(() => {
  if (updateTimeout.value) {
    clearTimeout(updateTimeout.value);
  }
});

function updateTimeAgoText() {
  timeAgo.value =
    props.dateStyle === 'long'
      ? formatDistanceToNow(props.date)
      : formatDistanceToNowStrict(props.date, {
          roundingMethod: 'floor',
        });
  if (props.dateStyle === 'short') {
    timeAgo.value = timeAgo.value.replace(/ second[s]?/, 's');
    timeAgo.value = timeAgo.value.replace(/ minute[s]?/, 'm');
    timeAgo.value = timeAgo.value.replace(/ hour[s]?/, 'h');
    timeAgo.value = timeAgo.value.replace(/ day[s]?/, 'd');
  }
  updateTimeout.value = window.setTimeout(() => updateTimeAgoText(), 1000);
}
</script>

<template>
  <span v-b-tooltip :title="formatDate(date, FULL_DATE_AND_TIME_FORMAT)">{{ timeAgo }} ago</span>
</template>
