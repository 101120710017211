import { emailRegex } from '../form/validators/email';

export function isValidEmail(email: string) {
  return emailRegex.test(email);
}

export function maskedEmail(email: string, retainLength = 2) {
  const i = email.indexOf('@');
  return email.slice(0, retainLength) + 'xxxxx' + email.slice(i);
}
