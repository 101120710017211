<script setup lang="ts">
import { Client, OnboardingIndividualInfo } from 'ah-api-gateways';
import { ref } from 'vue';
import { BModal } from 'bootstrap-vue';
import { InformationIcon } from 'ah-common-lib/src/icons/components';

defineProps<{ client: Client; applicant: OnboardingIndividualInfo }>();

const modal = ref<InstanceType<typeof BModal> | null>(null);

const emit = defineEmits<{
  (e: 'reject-application'): void;
}>();

function show() {
  modal.value?.show();
}

function reject() {
  emit('reject-application');
}
</script>

<template>
  <div x-test-name="client-rejection-modal-container">
    <BModal ref="modal" centered x-test-name="client-rejection-modal">
      <template #modal-header>
        <div class="text-center w-100 mt-2">
          <h3 class="mb-0">
            <InformationIcon class="text-danger mr-2" />
            Are you sure you want to reject this?
          </h3>
        </div>
      </template>
      <p class="text-center">
        Rejecting this application made by <b>{{ `${applicant.firstName} ${applicant.lastName}` }}</b
        >, representing <b>{{ client.name }}</b> cannot be reversed. Any future application will need to be created
        again from the beginning.
      </p>
      <template #modal-footer="{ hide }">
        <div class="d-inline-flex">
          <VButton class="btn-stroked mr-3" blurOnClick @click="hide"> Cancel </VButton>
          <VButton class="btn-danger" @click="reject"> Reject </VButton>
        </div>
      </template>
    </BModal>
    <VButton class="btn-danger" blurOnClick @click="show"> Reject </VButton>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .btn-danger {
    width: 100%;
    background-color: transparent;
    border: 1px solid getColor($color-danger);
    color: getColor($color-danger);

    &:hover,
    &:focus {
      color: white;
    }
  }
}
</style>
