<script lang="ts" setup>
import { Beneficiary, beneficiaryName } from 'ah-api-gateways';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import BeneficiaryEditor from './BeneficiaryEditor.vue';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { computed, reactive, ref } from 'vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const beneficiaryFormModal = ref<InstanceType<typeof BModal> | null>(null);

const oboModal = ref<InstanceType<typeof BModal> | null>(null);

const protectorModal = ref<InstanceType<typeof RouteProtectorModal> | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  (e: 'update:beneficiary', value: Beneficiary): void;
}>();

const props = defineProps<{
  beneficiary?: Beneficiary;
}>();

const state = reactive({
  validations: [] as FormValidation[],
  beneficiaryName: '' as string,
});

const formTitle = computed(() => {
  return props.beneficiary ? 'Edit Beneficiary' : 'Add Beneficiary';
});

const clientName = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.name;
  }
  return '';
});

function onHide(event: BvModalEvent) {
  if (event.trigger && anyDirty.value) {
    event.preventDefault();
    protectorModal.value!.$refs.modal.show();
  }
}

const anyDirty = computed(() => {
  return !!state.validations.find((f) => !f || f.$anyDirty);
});

function showModal() {
  beneficiaryFormModal.value!.show();
}

function showOBOModal() {
  oboModal.value!.show();
}

function hideModal() {
  state.validations = [];
  beneficiaryFormModal.value!.hide();
}

function hide() {
  beneficiaryFormModal.value!.hide();
}

function onBeneficiarySaved(beneficiary: Beneficiary) {
  hideModal();

  state.beneficiaryName = beneficiaryName(beneficiary) || '';

  if (onBehalfOfClient.value) {
    showOBOModal();
  }

  // setTimeout is used to ensure reactions happens after the validations have been cleared and route protector is off
  setTimeout(() => {
    emit('update:beneficiary', beneficiary);
  });
}
</script>

<template>
  <span>
    <BModal
      modal-class="side-modal beneficiary-modal"
      :title="formTitle"
      ref="beneficiaryFormModal"
      hide-footer
      @hide="onHide"
    >
      <BeneficiaryEditor
        :beneficiary="beneficiary"
        :validations.sync="state.validations"
        @update:beneficiary="onBeneficiarySaved"
        @cancel="hide()"
        v-bind="$attrs"
      />
      <RouteProtectorModal
        ref="protectorModal"
        :allowChange="!anyDirty"
        :allowSubpaths="false"
        :allowQueryChange="false"
        class="exit-protected-route"
        @ok="hide()"
        centered
        title="Changes not saved"
      >
        <p>There are unsaved changes. Are you sure you want to continue?</p>
      </RouteProtectorModal>
    </BModal>
    <BModal modal-class="obo-approval-modal" ref="oboModal" centered hide-header @hide="onHide" v-if="onBehalfOfClient">
      <div class="text-center">
        <IconAlertCircle class="status-icon-pending" />
        The new beneficiary, {{ state.beneficiaryName }} requires approval by {{ clientName }}.
      </div>
    </BModal>
    <slot v-bind="{ showModal }">
      <VButton @click="showModal()">{{ formTitle }}</VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .beneficiary-modal .modal-dialog {
  min-width: 40em;
}

::v-deep .obo-approval-modal {
  .modal-content {
    padding: 2em 1em 1em 1em;
    .status-icon-pending {
      width: 25px;
      height: 25px;
      @include themedTextColor($color-yellow-highlight);
    }
    .btn {
      width: 104px;
      &.btn-primary {
        @include themedBackgroundColor($color-widgets-green, $color-widgets-green, '', ' !important');
      }
    }
  }
  .modal-dialog {
    min-width: 35em;
  }
}
</style>
