<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import { tradeOptionsReportTableFields } from 'ah-trades/src/models/tradeTableFields';
import TradeListing from 'ah-trades/src/components/lists/TradeListing.vue';
import TradeOptionsRowDetails from 'ah-trades/src/components/lists/TradeOptionsRowDetails.vue';
import { ExportListType, PaginatedParams } from 'ah-api-gateways';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';

const tradeListing = ref<InstanceType<typeof TradeListing> | null>(null);

const config = computed(() => {
  return {
    tableFields: tradeOptionsReportTableFields,
  };
});

const state = reactive<{
  sortAndPageParams: Partial<PaginatedParams>;
  dataDownloadState: string;
}>({
  sortAndPageParams: { sort: 'createdAt', sortDirection: 'DESC' },
  dataDownloadState: 'idle',
});

function downloadList(format: ExportListType) {
  tradeListing.value!.downloadData(format);
}
</script>

<template>
  <div class="padded-space" id="all-options-view">
    <div class="inner">
      <h2>All Options</h2>
      <BoxGrid alignH="start" alignV="stretch">
        <BoxGridBlock cols="12">
          <TradeListing
            fixed
            withRowDetails
            ref="tradeListing"
            :config="config"
            :filter="{ hedgingProduct: 'VANILLA_OPTIONS' }"
            :sortAndPageParams.sync="state.sortAndPageParams"
            :dataDownloadState.sync="state.dataDownloadState"
            :showExport="false"
            paginationQueryParam="allOptionsSort"
            with-simple-scroll
            nowrap
          >
            <template #row-details="slotScope">
              <slot name="trade-details" v-bind="slotScope">
                <div class="trade-details">
                  <TradeOptionsRowDetails :trade="slotScope.item" />
                </div>
              </slot>
            </template>
          </TradeListing>
        </BoxGridBlock>
        <BoxGridItem class="bg-transparent" cols="12">
          <ListDownloadButton
            :loading="state.dataDownloadState === 'pending'"
            class="download-button"
            @download="downloadList"
          />
        </BoxGridItem>
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inner {
  position: relative;
}
</style>
