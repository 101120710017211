import _Vue, { PluginObject } from 'vue';
import { Observable } from 'rxjs';
import defaults from 'lodash/defaults';
import { catchError, map, tap } from 'rxjs/operators';
import { PartnerBrandingData } from 'ah-api-gateways';
import tinycolor from 'tinycolor2';

export interface ThemePluginOptions {
  loader: () => Observable<PartnerBrandingData>;
  onLoad: (value: PartnerBrandingData) => void;
  prefix: string;
  storageKey: string | false;
}

const defaultThemePluginOptions: Pick<ThemePluginOptions, 'storageKey' | 'onLoad' | 'prefix'> = {
  storageKey: 'themeVal',
  onLoad: () => {},
  prefix: '--ah-color-',
};

export interface Theme {
  loaded: boolean;
  error: boolean;
  load: () => Observable<void>;
  setVal: (data: PartnerBrandingData) => void;
  val: PartnerBrandingData;
}

let theme: Theme | undefined = undefined;

export function useTheme(): Theme {
  if (!theme) {
    throw 'Theme plugin not instantiated!';
  }
  return theme;
}

export default {
  install: function install(
    Vue: typeof _Vue,
    options: Partial<ThemePluginOptions> & Pick<ThemePluginOptions, 'loader'>
  ) {
    const pluginOptions: ThemePluginOptions = defaults(options || {}, defaultThemePluginOptions) as ThemePluginOptions;

    let stylesheet: HTMLStyleElement | null = null;

    // Instantiating theme as Vue to ensure reactivity
    const _theme: Theme = new Vue({
      data: {
        load() {
          return pluginOptions.loader().pipe(
            tap(onThemeLoad),
            map(() => undefined),
            catchError((e) => {
              if (!_theme.loaded) {
                _theme.error = true;
              }
              throw e;
            })
          );
        },
        val: {},
        loaded: false,
        error: false,
        setVal(val: PartnerBrandingData) {
          onThemeLoad(val);
        },
      },
    }) as Theme;

    const boot = () => {
      const loadedValue = loadStoredValue();

      if (loadedValue && loadedValue.colorSchemes) {
        onThemeLoad(loadedValue);
      }
      _theme.load().subscribe();
    };

    const setColors = () => {
      if (!stylesheet) {
        stylesheet = document.createElement('style');
        document.head.appendChild(stylesheet);
      }

      if (_theme.val?.colorSchemes) {
        _theme.val.colorSchemes.forEach((c) => {
          const propertyName = pluginOptions.prefix + c.property.toLowerCase().split('_').join('-');
          const color = tinycolor(c.value);

          document.documentElement.style.setProperty(propertyName, color.toHexString());
          document.documentElement.style.setProperty(
            propertyName + '-S',
            `${Math.round(color.toHsl().s * 100) as any}%`
          );
          document.documentElement.style.setProperty(propertyName + '-H', Math.round(color.toHsl().h) as any);
        });
      }
    };

    const onThemeLoad = (value: PartnerBrandingData) => {
      const val: any = { ...value, name: value.name || '<Unnamed>' };
      Object.keys(val).forEach((k) => {
        Vue.set(_theme.val, k, val[k]);
      });
      _theme.error = false;
      _theme.loaded = true;
      saveStoredValue();
      setColors();
      pluginOptions.onLoad(_theme.val);
    };

    const loadStoredValue = () => {
      if (!pluginOptions.storageKey) {
        return null;
      }

      const val = localStorage.getItem(pluginOptions.storageKey);
      if (val) {
        try {
          const theme = JSON.parse(val);
          return theme as PartnerBrandingData;
        } catch (e) {}
      }
      return null;
    };

    const saveStoredValue = () => {
      if (!pluginOptions.storageKey) {
        return;
      }
      localStorage.setItem(pluginOptions.storageKey, JSON.stringify(_theme.val));
    };

    Vue.theme = _theme;
    Vue.prototype.$theme = _theme;
    theme = _theme;

    boot();
  },
} as PluginObject<Partial<ThemePluginOptions> & Pick<ThemePluginOptions, 'loader'>>;
