<script setup lang="ts">
import { PaginatedQuery, ProductCommission, formatHedgingInstrument } from 'ah-api-gateways';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { getServices } from '@/app/services';
import { productCommissionTableFields } from './tableFields/commissionTableFields';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: productCommissionTableFields }),
  },
  ...defineUseManagedListingProps<ProductCommission>(),
});

interface ProductCommissionEmits extends UseManagedListingEmits<ProductCommission> {}

const emit = defineEmits<ProductCommissionEmits>();

const requestManager = useRequestManager();

const services = getServices();

/**
 * service call to get, the list of product commissions, passing whole query:PaginatedQuery as argument
 */
const managedListing = useManagedListing<ProductCommission>({
  loadDataRequest(query: PaginatedQuery) {
    return services.trade.listProductCommissions(query);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

const currency = computed(
  () => (props.tableData?.list && props.tableData?.list.length > 0 && props.tableData.list[0].currency) || 'GBP'
);
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    nowrap
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(hedgingProduct)="{ item }">
      {{ formatHedgingInstrument(item.hedgingProduct) }}
    </template>
    <template #cell(totalPartnerProfit)="{ item }">
      {{ formatCurrencyValue(item.totalPartnerProfit) }}
    </template>
    <template #headInner(totalPartnerProfit)>
      Commission <span class="text-small">({{ currency }} Equivalent)</span>
    </template>
  </PaginatedTable>
</template>
