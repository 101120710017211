<script lang="ts" setup>
import {
  PaginatedQuery,
  PartnerReport,
  purposeTypesToHuman,
  Trade,
  ExportListType,
  PartnerTrade,
  TradeStatus,
} from 'ah-api-gateways';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { tradeAndComissionReportTableFields } from '../../models/tradeTableFields';
import TradeStatusCell from '../tableCells/TradeStatusCell.vue';
import TradeAndCommissionDetails from '../info/TradeAndCommissionDetails.vue';
import { capitalize } from 'lodash';
import {
  CommissionDisplayType,
  HedgingInstruments,
  PurposeTypes,
  hedgingInstrumentToHuman,
} from 'ah-api-gateways/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { computed, PropType, ref } from 'vue';
import { useAhTradesState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: tradeAndComissionReportTableFields }),
  },
  hidePagination: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  displayType: {
    type: String as PropType<CommissionDisplayType>,
    required: false,
    default: CommissionDisplayType.ORIGINAL,
  },
  ...defineUseManagedListingProps<PartnerReport>(),
});

const tradeState = useAhTradesState();

interface PartnerReportEmits extends UseManagedListingEmits<PartnerReport> {}

const emit = defineEmits<PartnerReportEmits>();

const managedListing = useManagedListing<PartnerReport>({
  loadDataRequest(query: PaginatedQuery) {
    return tradeState.services.financial.getPartnerCommissionReportList(query);
  },
  downloadDataRequest(type: string, query: { [key: string]: any }) {
    return tradeState.services.financial.downloadPartnerCommissionReportList(query, type as ExportListType);
  },
  emit,
  props,
  refs: {
    hideDownloadButton: ref(true),
  },
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});

const isApportioned = computed(() => {
  return props.displayType === CommissionDisplayType.APPORTIONED;
});

const homeCurrency = computed(() => {
  return managedListing.bindings.data?.list?.find((i) => i.homeCurrency)?.homeCurrency;
});

function getMarkupValue(totalMarkupBasisPoints: number) {
  const out = Math.round(totalMarkupBasisPoints);
  if (!isNaN(out)) {
    return out + 'bp';
  } else {
    return '-';
  }
}

function capitalizeValue(value: string) {
  return capitalize(value);
}

function isTradeRejectedOrCanceled(trade: Trade) {
  return [TradeStatus.REJECTED, TradeStatus.CANCELED].includes(trade.status);
}

// Using a similar approach to the "Row Selected" state to show a left aligned red bar on canceled/rejected trades
// Must use a td as the relative parent for this, and not the tr, due to a bug in Safari CSS
function bodyTrClass(trade: PartnerTrade) {
  if (trade && isTradeRejectedOrCanceled(trade)) {
    return 'rejected-row';
  }

  return '';
}

function purposeType(item: PartnerReport) {
  return purposeTypesToHuman[item.purposeType as PurposeTypes];
}

function hedgingProduct(item: PartnerReport) {
  return hedgingInstrumentToHuman[item.hedgingProduct as HedgingInstruments];
}

defineExpose({ loadData: managedListing.loadData, downloadData: managedListing.listeners.download });
</script>

<template>
  <PaginatedTable
    hover
    animateCols
    withRowDetails
    with-simple-scroll
    :class="['trades-table', { 'hide-pagination': props.hidePagination !== false }]"
    items-label="trades"
    :rowClass="bodyTrClass"
    primaryKey="composedReferenceNumber"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #headInner(gbpPartnerCommissions)>
      <span class="title-inner-custom"> Commissions {{ homeCurrency }} Equiv. </span>
    </template>
    <template #headInner(partnerInitialMarginCreditChargeAmount)>
      <span class="title-inner-custom"> IMCC {{ homeCurrency }} Equiv. </span>
    </template>

    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template #cell(settlementDate)="{ item }">
      {{ formatDate(item.settlementDate) }}
    </template>
    <template #cell(purposeType)="{ item }"> {{ purposeType(item) }} </template>
    <template #cell(hedgingProduct)="{ item }"> {{ hedgingProduct(item) }} </template>
    <template #cell(status)="{ item }">
      <TradeStatusCell class="trade-state" :item="item" :showIcon="false" />
    </template>
    <template #cell(clientRate)="{ item }">
      {{ $ahTradesState.store.useSettingsStore().displayRate(item.clientRate) }}
    </template>
    <template #cell(currencyPairDirection)="{ item }">
      {{ capitalizeValue(item.currencyPairDirection) }}
    </template>
    <template #cell(totalMarkupBasisPoints)="{ item }">
      {{ getMarkupValue(item.totalMarkupBasisPoints) }}
    </template>
    <template #cell(commissionsDate)="{ item }">
      {{ formatDate(item.commissionsDate) }}
    </template>

    <!-- We should display different value depending on the Commission Display Type selected -->
    <template #cell(gbpPartnerCommissions)="{ item }">
      <span v-if="isApportioned"> {{ formatCurrencyValue(item.remainingGbpPartnerCommissions) }} </span>
      <span v-else> {{ formatCurrencyValue(item.gbpPartnerCommissions) }} </span>
    </template>
    <template #cell(partnerInitialMarginCreditChargeAmount)="{ item }">
      <span v-if="isApportioned">
        {{ formatCurrencyValue(item.remainingPartnerInitialMarginCreditChargeAmount) }}
      </span>
      <span v-else> {{ formatCurrencyValue(item.partnerInitialMarginCreditChargeAmount) }} </span>
    </template>
    <template #cell(gbpPartnerNetCommission)="{ item }">
      <span v-if="isApportioned"> {{ formatCurrencyValue(item.remainingGbpPartnerNetCommission) }} </span>
      <span v-else> {{ formatCurrencyValue(item.gbpPartnerNetCommission) }} </span>
    </template>

    <!-- row details -->
    <template #row-details="slotScope">
      <slot name="trade-details" v-bind="slotScope">
        <TradeAndCommissionDetails class="trade-details" :displayType="displayType" :commission="slotScope.item" />
      </slot>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep tr {
  &.rejected-row td:not(.spacer-cell):first-child:before {
    content: ' ';
    @include tablet {
      border-right-width: 5px;
      border-right-style: solid;
      @include themedPropColor('border-right-color', $color-danger);
    }
    @include upToResolution($tabletResolution) {
      border-left-width: 5px;
      border-left-style: solid;
      @include themedPropColor('border-left-color', $color-danger);
    }
    z-index: 11;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &.rejected-row td:not(.spacer-cell):last-child:after {
    content: ' ';
    @include tablet {
      border-right-width: 5px;
      border-right-style: solid;
      @include themedPropColor('border-right-color', $color-danger);
    }
    @include upToResolution($tabletResolution) {
      border-left-width: 5px;
      border-left-style: solid;
      @include themedPropColor('border-left-color', $color-danger);
    }
    z-index: 11;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

::v-deep .title-inner-custom {
  font-weight: $font-weight-semibold;
}

.trades-table.hide-pagination ::v-deep {
  .table-actions {
    display: none;
  }
}

.trade-details {
  @include upToResolution($desktopResolution) {
    max-width: 60vw;
  }
  @include upToResolution($phabletResolution) {
    max-width: 100%;
  }
}
</style>
