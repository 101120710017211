import { PaginatedQuery, PaginatedResponse, IndividualGroup, IndividualGroupRequest } from '../models';
import { HttpBasedService, HttpService } from 'ah-requests';

/**
 * Base Group service
 * Extended by ClientGroupService/PartnerGroupService
 *
 * All endpoints accept an optional entityId, which, on Partner groups:
 * - MUST be provided when used in an admin settin
 * - MUST NOT be provided when used in a Client/Partner user setting
 */
export class GroupService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public getGroup(groupId: string, entityId?: string) {
    return this.get<IndividualGroup>(`${this.baseUrl}${entityId ? entityId + '/' : ''}groups/${groupId}`);
  }

  public editGroup(group: IndividualGroupRequest, entityId?: string) {
    return this.put<IndividualGroup>(`${this.baseUrl}${entityId ? entityId + '/' : ''}groups/${group.id}`, group);
  }

  public createGroup(group: Partial<Omit<IndividualGroupRequest, 'id'>>, entityId?: string) {
    return this.post<IndividualGroup>(`${this.baseUrl}${entityId ? entityId + '/' : ''}groups`, group);
  }

  public deleteGroup(groupId: string, entityId?: string) {
    return this.delete<void>(`${this.baseUrl}${entityId ? entityId + '/' : ''}groups/${groupId}`);
  }

  public listGroups(query: PaginatedQuery<{ individualId: string[] }>, entityId?: string) {
    return this.get<PaginatedResponse<IndividualGroup>>(`${this.baseUrl}${entityId ? entityId + '/' : ''}groups`, {
      axiosConfig: {
        params: query,
      },
    });
  }
}
