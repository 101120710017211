<script lang="ts" setup>
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { PaginatedQuery, PaginatedResponse, Trade } from 'ah-api-gateways';
import { debounce, DebouncedFunc, uniqBy } from 'lodash';
import { computed, reactive, onBeforeMount } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import VueSelect from 'vue-select';
import { useAhPaymentState } from '..';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import ArrowRightIconVue from 'ah-common-lib/src/icons/components/ArrowRightIcon.vue';

const paymentState = useAhPaymentState();

const emit = defineEmits<{
  /**
   * - update:validations: emmited when `validations` changes. Can be used with .sync
   */
  (e: 'update:selectedOption', value: any): void;
}>();

const props = withDefaults(
  defineProps<{
    value: Trade | null;
    isReadonly: boolean;
  }>(),
  {
    value: null,
    isReadonly: false,
  }
);

const state = reactive<{
  options: FieldOptionObj[];
  searchTerm: string;
  lastResult: PaginatedResponse<any> | null;
  debouncedSearch: DebouncedFunc<() => void> | null;
  active: boolean;
}>({
  options: [],
  searchTerm: '',
  lastResult: null,
  debouncedSearch: null,
  active: false,
});

onBeforeMount(() => {
  state.debouncedSearch = debounce(() => fetchDataRequest(), 600);
});

const requestManager = useRequestManager({
  exposeToParent: true,
}).manager;

const hasMoreItems = computed(() => {
  if (
    typeof state.lastResult?.pageNumber !== 'number' ||
    typeof state.lastResult?.pageSize !== 'number' ||
    typeof state.lastResult?.total !== 'number'
  ) {
    return false;
  }
  return (state.lastResult.pageNumber + 1) * state.lastResult.pageSize < state.lastResult.total;
});

const loading = computed(() => {
  return requestManager.requestStates['fetchData'] === 'pending';
});

const searchText = computed(() => {
  return `Search for Trades...`;
});

const itemsText = computed(() => {
  if (state.active && !props.value?.referenceNumber) {
    return searchText.value;
  }
  if (props.value?.referenceNumber?.length && props.value?.referenceNumber?.length > 0) {
    return '';
  }
  return 'All';
});

const placeholderClass = computed(() => {
  return ['All', searchText].includes(itemsText.value ?? '') ? '' : 'opacity-full';
});

const options = computed(() => {
  let options = [...state.options];
  if (props.value && props.value?.id && !options.find((option) => option.value === props.value?.id)) {
    options = [props.value, ...options];
  }
  return options;
});

function onTagSearch(search: string) {
  state.searchTerm = search;
  if (state.debouncedSearch) state.debouncedSearch();
}

function onInfiniteScroll(intersection: IntersectionObserverEntry) {
  if (intersection.isIntersecting) {
    const offsetParent = (intersection.target as HTMLElement).offsetParent;
    const scrollTop = offsetParent?.scrollTop;
    if (!requestManager.anyPending) {
      fetchDataRequest(true);
      if (offsetParent && scrollTop) {
        offsetParent.scrollTop = scrollTop;
      }
    }
  }
}

/**
 *
 * Fetch new data and missing data
 *
 */
function fetchDataRequest(infinite = false) {
  const query: PaginatedQuery<any> = {
    pageSize: 20,
    queryBy: 'referenceNumber',
    query: state.searchTerm,
    status: 'OPEN',
    sort: 'settlementDate',
    sortDirection: 'DESC',
    settlementDateFrom: new Date(),
    hedgingProduct: ['FX_SPOT', 'FX_FORWARD', 'FLEXIBLE_FORWARD', 'WINDOW_FORWARD'],
  };

  const infiniteLoad = infinite && typeof state.lastResult?.pageNumber === 'number';

  if (infiniteLoad) {
    query.pageNumber = state.lastResult?.pageNumber! + 1;
  } else {
    query.pageNumber = 0;
    state.lastResult = null;
  }
  let request = paymentState.services.trade.listTrades(query);

  requestManager.currentOrNew('fetchData', request).subscribe((res) => {
    let list: FieldOptionObj<any>[] = infiniteLoad ? [...state.options] : [];
    state.lastResult = res;

    res.list.forEach((r) => {
      list.push({ ...r, label: r.referenceNumber, value: r.id });
    });

    state.options = uniqBy(list, 'value');
  });
}

function setOption(option: any) {
  emit('update:selectedOption', option);
}
</script>

<template>
  <div>
    <label> Open Trade </label>
    <VueSelect
      class="field-group-field-input"
      :value="value"
      :loading="loading"
      :closeOnSelect="true"
      :disabled="isReadonly"
      :options="options"
      :filterable="false"
      @search="onTagSearch"
      v-bind="$attrs"
      v-on="$listeners"
      @search:focus="state.active = true"
      @search:blur="state.active = false"
      required
      :clearable="false"
    >
      <template #option="option">
        <div class="custom-control" @click="setOption(option)">
          <label :for="`tags-filter-select-${option?.label}`">
            <span class="d-block"
              ><b>
                {{ option?.referenceNumber }}
              </b>
            </span>
            <span class="d-block">
              {{ option?.ccy1?.currency }} {{ formatCurrencyValue(option?.ccy1?.clientAmount) }}
              <ArrowRightIconVue class="day-change-arrow mx-2" />
              {{ option?.ccy2?.currency }}
              {{ formatCurrencyValue(option?.ccy2?.clientAmount) }}
            </span>
            <span class="d-block"> Settlement date: {{ formatDate(option.settlementDate, 'dd-MM-yyyy') }} </span>
          </label>
        </div>
      </template>
      <template #search="{ attributes, events }">
        <input :class="`vs__search ${placeholderClass}`" v-bind="attributes" v-on="events" :placeholder="itemsText" />
      </template>
      <template #list-footer>
        <div v-on-intersect="onInfiniteScroll" v-if="hasMoreItems">
          <slot name="infinite-scroll">
            <li class="vs__dropdown-option loader text-center">
              <LoadingIcon class="loading-icon" />
            </li>
          </slot>
        </div>
      </template>
    </VueSelect>
  </div>
</template>
