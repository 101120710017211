<script setup lang="ts">
import TradeCreator from 'ah-trades/src/components/TradeCreator.vue';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { useRouter } from 'vue-router/composables';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed } from 'vue';

const router = useRouter();

const props = defineProps({ clientId: { type: String, required: false } });
function onTradeCreated() {
  if (props.clientId) {
    router.replace('.');
  }
}

const clientBankingProvider = computed(() => useIndividualSettingsStore().client?.preferredBankingProvider);
</script>

<template>
  <div class="padded-space" id="trade-view">
    <OnBehalfOf :clientId="clientId">
      <TradeCreator
        @trade-created="onTradeCreated"
        title="New FX Trade"
        verifyLimits
        costTransparency
        :bankType="clientBankingProvider"
      />
    </OnBehalfOf>
  </div>
</template>
