import { helpers } from '@vuelidate/validators';
import { isWeekend, isValid, format, isAfter, isBefore, isEqual } from 'date-fns';
import { getChildModel, getState } from '../../form/helpers';
import { FormModel } from '../../form/interfaces';
import { addZoneOffset } from '../../helpers/time';

export const minDate = (
  limit: Date | ((vm: FormModel) => Date | undefined) = new Date(),
  dateFormat = 'yyyy-MM-dd',
  params: any = {}
) =>
  helpers.withParams(
    {
      minDate: typeof limit === 'function' ? 'custom' : format(limit, dateFormat),
      type: 'minDate',
      dateFormat,
      ...params,
    },
    (value: string, vm: any) => {
      // consider null value as valid because if it is not required and there is no value this would break validation
      if (!value) {
        return true;
      }
      const date = new Date(value);
      if (isNaN(date.valueOf())) {
        return true;
      }

      const limitDate = typeof limit === 'function' ? limit(vm) : limit;

      if (limitDate instanceof Date && !isNaN(limitDate.valueOf())) {
        return isEqual(date, limitDate) || isAfter(date, limitDate);
      }
      return true;
    }
  );

export const afterDateField = (fieldName: string, dateFormat = 'dd-MM-yyyy') =>
  minDate((vm) => (vm[fieldName] ? new Date(vm[fieldName]) : undefined), dateFormat);

export const maxDate = (limit: Date | ((vm: any) => Date | undefined) = new Date(), dateFormat = 'yyyy-MM-dd') =>
  helpers.withParams(
    { maxDate: typeof limit === 'function' ? 'custom' : format(limit, dateFormat), dateFormat },
    (value: string, vm: any) => {
      // consider null value as valid because if it is not required and there is no value this would break validation
      if (!value) {
        return true;
      }
      const date = new Date(value);
      if (isNaN(date.valueOf())) {
        return true;
      }

      const limitDate = typeof limit === 'function' ? limit(vm) : limit;

      if (limitDate instanceof Date && !isNaN(limitDate.valueOf())) {
        const out = isBefore(date, limitDate);
        return out;
      }
      return true;
    }
  );

export const beforeDateField = (fieldName: string, dateFormat = 'dd-MM-yyyy') =>
  maxDate((vm) => (vm[fieldName] ? new Date(vm[fieldName]) : undefined), dateFormat);

export const weekend = (allow = false, useLocal = false) =>
  helpers.withMessage(`Date ${allow ? 'must be' : 'cannot be'} on a weekend`, (value: string) => {
    if (!value) {
      return true;
    }
    try {
      const date = useLocal ? new Date(value) : addZoneOffset(new Date(value));

      return allow ? isWeekend(date) : !isWeekend(date);
    } catch (e) {
      return false;
    }
  });

export const disallowDates = (dates: string[] | ((vm: any) => string[]), dateFormat = 'dd-MM-yyyy', useLocal = false) =>
  helpers.withMessage('Date not allowed', (value: string, vm: any) => {
    if (!value) {
      return true;
    }
    try {
      const date = format(useLocal ? new Date(value) : addZoneOffset(new Date(value)), dateFormat);
      const disallowed = typeof dates === 'function' ? dates(vm) : dates;

      return disallowed.indexOf(date) === -1;
    } catch (e) {
      return true;
    }
  });

export const disallowStateDates = (
  fieldName: string,
  stateValue = 'disallowedDates',
  dateFormat = 'dd-MM-yyyy',
  useLocal = false
) =>
  disallowDates(
    (vm) => {
      const model = getChildModel(vm, fieldName);
      return model && getState(model, stateValue, []);
    },
    dateFormat,
    useLocal
  );

export const date = helpers.withParams({ type: 'date' }, (value: string) => {
  return isValid(new Date(value));
});

export const disallowWeekends = helpers.withParams({ type: 'date' }, (value: string) => {
  return !isWeekend(new Date(value));
});
