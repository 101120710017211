import { AuthorityType } from './user';

export enum AuthorityEntityType {
  USER = 'USER',
  GROUP = 'GROUP',
  CLIENT = 'CLIENT',
}

export interface RoleData {
  name: string;
  authorities: Authority[];
}

export interface Authority {
  id: AuthorityType;
  entityType: AuthorityEntityType;
  editable: boolean;
  expirable: boolean;
  index: number;
  name: string;
  description: string;
  sectionIndex: number;
  sectionName: string;
  defaultValue?: boolean;
}

export interface Permission {
  authority: AuthorityType;
  allow: boolean;
  expires?: string;
  updatedAt?: string;
  updatedBy?: string;
}
