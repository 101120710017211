<script setup lang="ts">
import TradesSidebarView from '@/app/views/dashboard/trades/TradesSidebarView.vue';
import { useRoute } from 'vue-router/composables';
import { computed, ref, watch } from 'vue';

const route = useRoute();

const dashboardOpen = ref<boolean>(false);

const inDashboard = computed(() => route.fullPath.includes('/dashboard/trades'));

watch(
  () => route.fullPath,
  () => (dashboardOpen.value = inDashboard.value),
  { immediate: true }
);

function toggleDashboard() {
  dashboardOpen.value = !dashboardOpen.value;
}
</script>

<template>
  <span>
    <div tag="li" class="menu-link" :class="{ 'router-link-active': inDashboard }" v-if="$mediaQuery.is('smOnly')">
      <!-- Visible only on phablets displays -->
      <IconTrade /> <span class="menu-link-text"> Hedging </span>
      <span>
        <span @click="toggleDashboard()"><IconChevronDown class="toggle-icon" :class="{ open: dashboardOpen }" /></span>
        <ExpandTransition appear>
          <TradesSidebarView v-if="dashboardOpen" path="/dashboard/trades" class="router-view" name="sidebar" />
        </ExpandTransition>
      </span>
    </div>

    <RouterLink tag="li" class="menu-link" to="/dashboard/trades" v-else>
      <!-- Visible in tablet or higher resolutions -->
      <IconTrade /> <span class="menu-link-text"> Hedging </span>
    </RouterLink>
  </span>
</template>

<style lang="scss" scoped>
.toggle-icon {
  float: right;
  transition: transform 0.5s;
  font-size: 1.5em;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
