import { render, staticRenderFns } from "./FieldWalletInfoOrErrors.vue?vue&type=template&id=4df4df78&scoped=true"
import script from "./FieldWalletInfoOrErrors.vue?vue&type=script&setup=true&lang=ts"
export * from "./FieldWalletInfoOrErrors.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FieldWalletInfoOrErrors.vue?vue&type=style&index=0&id=4df4df78&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df4df78",
  null
  
)

export default component.exports