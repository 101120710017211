import Vue, { getCurrentInstance } from 'vue';
import { Route, Location } from 'vue-router';
import { compile } from 'path-to-regexp';

// Simplified form of the path matching used by vue-router
export function fillParams(path: string, params: { [key: string]: string }): string {
  const filler = compile(path);
  return filler(params);
}

/**
 * Helper function to allow editing a vue-router route (used normally with Vue.$route)
 * @param route Route to edit
 * @param edits Edits to the route (query or param)
 */
export function editRoute(
  route: Route,
  edits: {
    query?: { add?: { [key: string]: any }; remove?: string[] };
    params?: { [key: string]: string };
  }
) {
  const query = {
    ...route.query,
  };

  const out: Location = {
    path: route.path,
    query,
  };

  if (edits.query?.add) {
    const { add } = edits.query;
    Object.keys(add).forEach((k) => {
      query[k] = add[k];
    });
  }

  if (edits.query?.remove) {
    const { remove } = edits.query;
    remove.forEach((k) => {
      delete query[k];
    });
  }

  if (edits.params) {
    const matched = route.matched[route.matched.length - 1].path;
    const params = { ...route.params, ...edits.params };
    Object.keys(params).forEach((k) => {
      if (params[k] === '') {
        delete params[k];
      }
    });
    out.path = fillParams(matched, params);
  }

  return out;
}

export function trimPathTrailingSlash(path: string) {
  if (path.endsWith('/')) {
    return path.substring(0, path.length - 1);
  }
  return path;
}

export function findComponentRoute(component: Vue) {
  return [...component.$route.matched].reverse().find((r) =>
    Object.keys(r.instances).find((k) => {
      let currComponent = component;
      while (currComponent) {
        if (r.instances[k] === currComponent) {
          return true;
        }
        currComponent = currComponent.$parent!;
      }
      return false;
    })
  );
}

export function findComponentBasePath(component: Vue) {
  const route = findComponentRoute(component);

  if (route) {
    return trimPathTrailingSlash(route.path);
  }

  return '';
}

export function useComponentRouteMatch() {
  const component = getCurrentInstance()?.proxy;

  return findComponentRoute(component as any)!;
}
