import { render, staticRenderFns } from "./ClientAssigneeBlock.vue?vue&type=template&id=798a496a&scoped=true"
import script from "./ClientAssigneeBlock.vue?vue&type=script&lang=ts&setup=true"
export * from "./ClientAssigneeBlock.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ClientAssigneeBlock.vue?vue&type=style&index=0&id=798a496a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "798a496a",
  null
  
)

export default component.exports