import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const openPositionsMtmTableFields: TableFieldDefinition[] = [
  {
    header: 'Trade ID',
    key: 'composedReferenceNumber',
  },
  {
    header: 'Trade Date',
    key: 'tradeDate',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'instrument',
  },
  {
    header: 'CCY Pair',
    key: 'ccyPair',
  },
  {
    header: 'Direction',
    key: 'ccyPairDirection',
  },
  {
    header: 'Notional Amount (GBP)',
    key: 'remainingGbpAmount',
    sortable: true,
  },
  {
    header: 'Client Rate',
    key: 'clientRate',
  },
  {
    header: 'Valuation Rate',
    key: 'valuationRate',
  },
  {
    header: 'Settlement Date',
    key: 'settlementDate',
    sortable: true,
  },
  {
    header: 'MTM (GBP)',
    key: 'remainingGbpPositionMtm',
    sortable: true,
  },
];
