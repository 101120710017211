<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.9444 12.5C23.9444 19.1381 18.6857 24.5 12.2222 24.5C5.75875 24.5 0.5 19.1381 0.5 12.5C0.5 5.86193 5.75875 0.5 12.2222 0.5C18.6857 0.5 23.9444 5.86193 23.9444 12.5Z"
      stroke="#01B68C"
    />
    <path
      d="M16.6554 9.31544C17.1149 9.736 17.1149 10.4179 16.6554 10.8384L11.3613 15.6846C10.9019 16.1051 10.157 16.1051 9.69753 15.6846L7.34459 13.5307C6.88514 13.1102 6.88514 12.4283 7.34459 12.0077C7.80403 11.5872 8.54892 11.5872 9.00836 12.0077L10.5294 13.4001L14.9916 9.31544C15.4511 8.89485 16.196 8.89485 16.6554 9.31544Z"
      fill="#01B68C"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
