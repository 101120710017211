<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import InfoBlock from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { IndividualType, OnboardingIndividualInfo } from 'ah-api-gateways';
import { makeMemberForm } from './reviewForms';
import { setState, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { isEmpty } from 'lodash';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { catchError, mergeMap } from 'rxjs/operators';
import { waitForCQRSEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { useToast } from 'ah-common-lib/src/toast';

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const toast = useToast();

const props = withDefaults(defineProps<{ model: OnboardingIndividualInfo; editable?: boolean }>(), { editable: false });

const emit = defineEmits<{ (e: 'update:model', value: OnboardingIndividualInfo): void }>();

const state = reactive<{ editing: boolean }>({ editing: false });

const signatoryForm = reactive<FormDefinition>({
  form: makeMemberForm(),
  validation: null,
});

const isEditable = computed(() => props.editable !== false);

const isReadonly = computed(() => !isEmpty(props.model.user));

const isRetrievedInviteDetails = computed(() => props.model.retrievedInviteDetails);

const permissionLabel = computed(() => {
  if (props.model.type === IndividualType.CLIENT_ADMIN) {
    return 'Superuser';
  } else if (props.model.proposedPermissions && props.model.proposedPermissions.length > 0) {
    return 'Custom permissions';
  } else {
    return 'None';
  }
});

const infoBlock = computed(() => [
  { label: 'Title', key: 'title', cols: 4 },
  { label: 'First Name', key: 'firstName', cols: 4 },
  { label: 'Last Name', key: 'lastName', cols: 4 },
  { label: 'Email Address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 4 },
  { label: 'Requested Permissions', key: 'permissions', value: permissionLabel.value, cols: 4 },
]);

function save() {
  const clientId = authStore.loggedInIdentity!.client!.id;

  requestManager
    .currentOrNew(
      'saveSignatory',
      services.registration
        .editProposedUser(props.model.id!, clientId, { ...props.model, ...toDataModel(signatoryForm.form) })
        .pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () => services.individual.getIndividual(idEntity.id)).pipe(
              catchError(() => of(props.model))
            )
          )
        )
    )
    .subscribe((individual) => {
      toast?.success('Authorised signatory details changed successfully');
      state.editing = false;
      emit('update:model', individual);
    });
}

watch(
  () => props.model,
  () => {
    updateModel(signatoryForm.form, { ...props.model, permissions: permissionLabel.value });
  },
  { immediate: true }
);

watch(
  isReadonly,
  () => {
    setState(signatoryForm.form, 'readonly', isReadonly.value);
  },
  { immediate: true }
);
</script>

<template>
  <div class="card-block" x-test-name="signatory-details-review">
    <div class="card-review-header">
      <h2>Authorised Signatory Details</h2>
      <div class="button-holder" v-if="!isRetrievedInviteDetails">
        <div class="button-holder" v-if="isEditable">
          <VButton blurOnClick @click="state.editing = !state.editing" class="btn-stroked">
            {{ state.editing ? 'Cancel' : 'Edit' }}
          </VButton>
          <VButton
            blurOnClick
            @click="save"
            v-if="state.editing"
            :loading="requestManager.anyPending"
            :disabled="signatoryForm.validation && signatoryForm.validation.$invalid"
            class="ml-3"
          >
            Save
          </VButton>
        </div>
      </div>
    </div>
    <p class="text-secondary">
      If you spot an error in the details submitted for the Authorised Signatory, please update them here.
    </p>
    <template v-if="!state.editing">
      <InfoBlock emptyValue="-" :model="model" :info="infoBlock" />
    </template>
    <template v-else>
      <ValidatedForm :fm="signatoryForm.form" :validation.sync="signatoryForm.validation" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }
  .button-holder {
    margin-left: auto;
    display: inline-flex;
    .btn {
      min-width: 7rem;
    }
  }
}

::v-deep {
  label {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }
}
</style>
