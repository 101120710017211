<script lang="ts" setup>
import { Beneficiary } from 'ah-api-gateways';
import AddressInfo from '../address/AddressInfo.vue';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { lastIbanDigits, maskedIban } from 'ah-common-lib/src/helpers/iban';
import { useAhBeneficiariesState } from '../..';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    hideHeader?: boolean | string;
    beneficiary: Beneficiary;
    simple?: boolean | string;
  }>(),
  {
    hideHeader: true,
    simple: false,
  }
);

const beneficiarieState = useAhBeneficiariesState();

loadRoutingCodeTypes();

function loadRoutingCodeTypes() {
  beneficiarieState.store.useSettingsStore().loadRoutingCodeTypes();
}

const accountRoutingCode = computed(() => {
  const routingData = beneficiarieState.store
    .useSettingsStore()
    .routingCodes.find((r) => r.currency === props.beneficiary.currency);
  if (routingData) {
    return routingData.title;
  }
  return 'Routing Code';
});

const withHeader = computed(() => {
  return props.hideHeader !== true;
});
</script>

<style lang="scss" scoped>
.bank-account-info {
  max-width: 75%;
  @include upToResolution($desktopResolution) {
    max-width: 100%;
  }
  @include upToResolution($tabletResolution) {
    font-size: 13px;
  }
  .table-header {
    .header-row {
      border-bottom: 1px solid;
      @include themedBorderColor($color-primary, $color-dark-primary);
    }
    ::v-deep .label-col {
      padding-left: 0 !important;
    }
  }
}
</style>

<template>
  <div class="bank-account-info">
    <div class="table-header mb-2" v-if="withHeader">
      <DataRow
        class="header-row pb-2 mb-2 currency-and-name-headers"
        v-if="beneficiary.accountHolderName"
        label="Currency"
      >
        Bank name
      </DataRow>
      <DataRow v-if="beneficiary.accountHolderName" class="currency-and-name-values">
        <template #label>
          {{ beneficiary.currency }}
        </template>
        <span v-if="beneficiary.bankName">
          {{ beneficiary.bankName }}
        </span>
        <span v-else-if="beneficiary.iban"> IBAN: {{ lastIbanDigits(beneficiary.iban) }} </span>
        <span v-else-if="beneficiary.accountNumber"> Account Number: {{ beneficiary.accountNumber }} </span>
        <span class="text-secondary" v-else> N/A </span>
      </DataRow>
    </div>
    <div :class="{ 'ml-3': withHeader }">
      <DataRow v-if="beneficiary.accountHolderName" label="Account Holder" class="account-holder">
        {{ beneficiary.accountHolderName }}
      </DataRow>
      <DataRow v-if="beneficiary.iban" label="IBAN" class="account-iban">
        {{ maskedIban(beneficiary.iban) }}
      </DataRow>
      <DataRow v-if="beneficiary.bicSwift" label="BIC Swift" class="account-swift">
        {{ beneficiary.bicSwift }}
      </DataRow>
      <DataRow v-if="beneficiary.accountNumber" label="Account number" class="account-number">
        {{ beneficiary.accountNumber }}
      </DataRow>
      <DataRow :label="accountRoutingCode" v-if="beneficiary.routingNumber1" class="account-routing-number">
        {{ beneficiary.routingNumber1 }}
      </DataRow>
      <DataRow v-if="beneficiary.bankCountry" label="Bank country" class="account-bank-country">
        {{ countryNameFromCC(beneficiary.bankCountry) }}
      </DataRow>
      <DataRow v-if="beneficiary.bankAddress" label="Bank address" class="account-bank-address">
        {{ beneficiary.bankAddress }}
      </DataRow>
      <DataRow v-if="beneficiary.countryCode" label="Country" class="account-country">
        {{ countryNameFromCC(beneficiary.countryCode) }}
      </DataRow>
      <DataRow v-if="beneficiary.currency" label="Currency" class="account-currency">
        {{ beneficiary.currency }}
      </DataRow>
      <DataRow v-if="beneficiary.reference" label="Reference" class="account-reference">
        {{ beneficiary.reference }}
      </DataRow>
      <div v-if="beneficiary.address">
        <h3 class="mt-3 mb-1 beneficiary-address-title">Account Holder address</h3>
        <AddressInfo :address="beneficiary.address" class="beneficiary-address" />
      </div>
    </div>
  </div>
</template>
