<script setup lang="ts">
import { ExportListType } from 'ah-api-gateways/models/rest/download';
import { BModal } from 'bootstrap-vue';
import { BFormSelect } from 'bootstrap-vue';
import { reactive, ref } from 'vue';

const listDownloadButtonWithDetailsModal = ref<InstanceType<typeof BModal> | null>(null);

const emit = defineEmits<{
  (e: 'download', exportType: ExportListType, options: { withDetails: boolean }): void;
}>();

const props = defineProps({
  downloadOptions: {
    type: Array<{ value: string; text: string }>,
    required: false,
    default: () => [
      { value: 'PDF', text: 'PDF' },
      { value: 'XLSX', text: 'Excel' },
      { value: 'CSV', text: 'CSV' },
    ],
  },
  loading: {
    type: [Boolean, String],
    default: false,
  },
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  allowExportWithDetails: {
    type: [Boolean, String],
    default: false,
  },
  buttonClass: {
    type: String,
    default: 'btn-primary short',
  },
});

const state = reactive<{
  selectedFormat: ExportListType;
}>({
  selectedFormat: ExportListType.XLSX,
});

function showModal() {
  listDownloadButtonWithDetailsModal.value?.show();
}

function hideModal() {
  listDownloadButtonWithDetailsModal.value?.hide();
}

function triggerExport(format: ExportListType, withDetails: boolean) {
  emit('download', format, { withDetails });
  hideModal();
}

function exportData() {
  if (props.allowExportWithDetails) {
    showModal();
    return;
  }
  triggerExport(state.selectedFormat, false);
}
</script>

<template>
  <div class="list-download-button">
    <div class="select-box">
      <BFormSelect v-model="state.selectedFormat" :options="downloadOptions" class="select-input" />
      <ArrowTopIcon class="dropdown-icon" />
    </div>
    <VButton :class="[buttonClass, 'btn-export']" :loading="loading" :disabled="props.disabled" @click="exportData()">
      <slot name="download-button-content"> <DownloadIcon class="icon" />Export </slot>
    </VButton>

    <!-- Modal for exporting with details, slots need to be utilized for the modal to work -->
    <span>
      <BModal
        centered
        modal-class="payment-modal"
        ref="listDownloadButtonWithDetailsModal"
        hide-footer
        hide-header-close
      >
        <slot name="list-download-modal-title"> </slot>
        <slot name="list-download-modal-description"> </slot>
        <div class="d-flex align-items-center justify-content-center">
          <VButton class="btn btn-secondary mr-2" @click="triggerExport(state.selectedFormat, false), hideModal()"
            >No, don't include</VButton
          >
          <VButton class="ml-1 btn btn-primary ml-2" @click="triggerExport(state.selectedFormat, true)">
            Yes, Include
          </VButton>
        </div>
      </BModal>
    </span>
  </div>
</template>

<style lang="scss" scoped>
// Styled in general styles to allow easier overriding
.select-box {
  position: relative;
  display: inline-block;
  margin-right: 0.5em;

  .select-input {
    @include themedBackgroundColor($color-main-bg, $color-dark-main-bg);
    @include themedBorderColor($color-primary);
    @include themedTextColor($color-text);
  }

  .dropdown-icon {
    position: absolute;
    z-index: 1;
    right: 0.2em;
    bottom: 0.7em;
    pointer-events: none;
  }
  .select-input {
    background: none;
  }
}

.btn-export {
  &:not(.loading) {
    padding-left: 2.5em;
  }
}
.btn {
  min-width: 0;
  position: relative;

  .icon {
    max-width: 100%;
    max-height: 100%;

    &.icon {
      position: absolute;
      top: 50%;
      left: math.div($padded-space, 4);
      transform: translate(0, -50%);
    }

    ::v-deep path {
      fill: white;
    }
  }
}
</style>
