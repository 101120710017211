import { ErrorMessage } from '..';

export const defaultServerErrorMessages: Readonly<ErrorMessage[]> = [
  {
    // An empty message results in a hidden toast for 401s (as they are ususally handled by auth module)
    message: '',
    code: 401,
  },
  {
    message: 'There was a problem with the values provided. Please review your inputs for errors.',
    code: 400,
    name: '400-generic-error',
    toastType: 'info',
  },
  {
    message: 'Sorry we were unable to find what you were looking for.',
    code: 404,
    name: '404-generic-error',
    toastType: 'info',
  },
  {
    message: `It seems that you don't have permissions to access this resource.`,
    code: 403,
    name: '403-generic-error',
    toastType: 'danger',
  },
  {
    message: `The provided input already exists on our system.`,
    code: 409,
    name: '409-generic-error',
    toastType: 'info',
  },
  {
    message: `There was a problem with the values provided. Please review your inputs for errors.`,
    code: 422,
    name: '422-generic-error',
    toastType: 'info',
  },
  {
    message: 'An unexpected problem has occurred. Please try again later.',
    code: 500,
    name: '500-generic-error',
    toastType: 'danger',
  },
  {
    message: 'The service you are trying to access is not available at the moment.',
    code: 502,
    name: '502-generic-error',
    toastType: 'danger',
  },
];

export const genericServerErrorMessage: Readonly<ErrorMessage> = {
  message: 'An unexpected problem has occurred. Please try again later.',
  name: 'catchAll-generic-error',
  toastType: 'danger',
};
