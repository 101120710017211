import { ChartData, Point, ChartOptions, ScriptableContext } from 'chart.js';
import { getHSLA } from 'ah-theme';
import { format } from 'date-fns';
import defaultsDeep from 'lodash/defaultsDeep';
import numeral from 'numeral';
import { FilledChartColorOptions } from '../models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

export function makeThemedColorOptions(
  colorName: string,
  options: { alpha: number; hoverAlpha?: number } = { alpha: 1 }
): FilledChartColorOptions {
  return {
    backgroundColor: getHSLA(colorName, { alpha: options.alpha }),
    borderColor: getHSLA(colorName, { alpha: options.alpha }),
    hoverBackgroundColor: getHSLA(colorName, { alpha: options.hoverAlpha ?? options.alpha }),
    hoverBorderColor: getHSLA(colorName, { alpha: options.hoverAlpha ?? options.alpha }),
  };
}

export function makeTimeLineChartDataset(options: {
  label: string;
  discreet?: boolean;
  lineOnly?: boolean;
  color: string;
}): ChartData {
  return {
    datasets: [
      {
        label: options.label,
        data: [],
        stepped: options.discreet ? 'middle' : false,
        borderColor: options.color,
        borderWidth: options.lineOnly ? 3 : 1,
        borderCapStyle: 'round',
        cubicInterpolationMode: 'monotone',
        backgroundColor: options.color,
        pointBorderWidth: 1,
        pointBorderColor: options.color,
        pointRadius: 0,
        fill: options.lineOnly ? '' : 'origin',
        pointHoverBackgroundColor: options.color,
        pointHoverBorderColor: options.color,
        pointHitRadius: 10,
        pointHoverRadius(ctx: ScriptableContext<'line'>) {
          const value: Point | null = ctx.dataset.data[ctx.dataIndex] as Point;
          return value?.y && value.y! > 0 ? 5 : 0;
        },
      },
    ],
  };
}

export function makeTimeLineChartOptions(
  options: {
    discreet?: boolean;
    color: string;
  },
  overrides: Partial<ChartOptions> = {}
): ChartOptions {
  return defaultsDeep(overrides, <ChartOptions>{
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        displayColors: false,
        mode: 'nearest',
        axis: 'x',
        intersect: false,
        callbacks: {
          title(items) {
            if (items.length > 0 && (items[0].raw as Point).x) {
              return format(new Date((items[0].raw as Point).x), 'dd-MM-yyyy');
            }
            return '';
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time',
        grid: {
          color: options.color,
        },
        time: {
          unit: 'month',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
          precision: options.discreet ? 0 : 2,
          callback(value: number) {
            return numeral(value).format('0.[00] a');
          },
        } as any,
        grid: {
          display: false,
        },
      },
    },
  });
}

export function makeDoughnutChartOptions(overrides: Partial<ChartOptions<'doughnut'>> = {}): ChartOptions<'doughnut'> {
  return defaultsDeep(overrides, <ChartOptions>{
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
    },
  });
}

export function makeColoredChartDataset(options: {
  labels: string[];
  colors: FilledChartColorOptions[];
  data?: number[];
}): ChartData {
  return {
    datasets: [
      {
        backgroundColor: options.colors.map((c) => c.backgroundColor),
        hoverBackgroundColor: options.colors.map((c) => c.hoverBackgroundColor),
        borderColor: options.colors.map((c) => c.borderColor),
        hoverBorderColor: options.colors.map((c) => c.hoverBorderColor),
        data: options.data ?? [],
      },
    ],
    labels: options.labels,
  };
}

export function makeBarChartOptions(
  options: {
    horizontal?: boolean;
    gridColor: string;
  },
  overrides: Partial<ChartOptions> = {}
): ChartOptions {
  return defaultsDeep(overrides, <ChartOptions>{
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        mode: 'nearest',
        axis: options.horizontal ? 'y' : 'x',
        intersect: false,
        callbacks: {
          label(item) {
            return `GBP ${formatCurrencyValue(options.horizontal ? item.parsed.x : item.parsed.y)}`;
          },
        },
      },
    },
    scales: {
      [options.horizontal ? 'y' : 'x']: {
        grid: {
          display: false,
        },
      },
      [options.horizontal ? 'x' : 'y']: {
        beginAtZero: true,
        grid: {
          color: options.gridColor,
        },
      },
    },
    indexAxis: options.horizontal ? 'y' : 'x',
  });
}

export function makeThemedChartDataset(options: {
  labels: string[];
  colorNames: string[];
  data?: number[];
}): ChartData {
  return makeColoredChartDataset({
    labels: options.labels,
    colors: options.colorNames.map((color) => ({
      ...makeThemedColorOptions(color, { alpha: 0.5, hoverAlpha: 0.9 }),
      data: [],
    })),
    data: options.data,
  });
}

export function makeThemedDoughnutChartDataset(options: {
  labels: string[];
  colorNames: string[];
  data?: number[];
}): ChartData {
  return makeColoredChartDataset({
    labels: options.labels,
    colors: options.colorNames.map((color) => ({
      ...makeThemedColorOptions(color),
      data: [],
    })),
    data: options.data,
  });
}

export function makeBarChartDataset(color: 'widgets-green' | 'primary' = 'widgets-green'): ChartData {
  return {
    datasets: [
      {
        ...makeThemedColorOptions(color, { alpha: 0.5, hoverAlpha: 0.9 }),
        data: [],
      },
    ],
    labels: [],
  };
}
