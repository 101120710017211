export enum ProductStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum Products {
  SPOT = 'SPOT',
  FORWARD = 'FORWARD',
}

export interface Product {
  id: string;
  partnerId: string;
  sellCurrency: string;
  buyCurrency: string;
  currencyPairEnabled: boolean;
  fxSpotEnabled: boolean;
  fxForwardEnabled: boolean;
  notionalCurrency: string;
  notionalAmount: number;
  updatedBy?: string;
  updatedAt?: string;
  previousStatus?: ProductStatus;
  previousProducts?: Products[];
  products?: Products[];
}

export interface ProductEditModel {
  id: string;
  currencyPairEnabled: boolean;
  fxSpotEnabled: boolean;
  fxForwardEnabled: boolean;
}

export interface ProductBulkRequest {
  products: ProductEditModel[];
}

export interface ProductBulkResponse {
  products: Product[];
}

export const productsToHuman: Record<Products, string> = {
  [Products.SPOT]: 'Spot',
  [Products.FORWARD]: 'Forward',
};

export function formatProducts(product?: Products, defaultStr = '') {
  if (!product) {
    return defaultStr;
  }
  return productsToHuman[product as Products] || defaultStr;
}

export function showProducts(products: Products[]) {
  const productsAux = products.map((product) => {
    return formatProducts(product);
  });
  return productsAux.length > 1 ? productsAux.join(', ') : productsAux.length > 0 ? productsAux[0] : '';
}

export const statusToHuman: Record<ProductStatus, string> = {
  [ProductStatus.ENABLED]: 'Enabled',
  [ProductStatus.DISABLED]: 'Disabled',
};

export function formatStatus(status?: ProductStatus, defaultStr = '') {
  if (!status) {
    return defaultStr;
  }
  return statusToHuman[status as ProductStatus] || defaultStr;
}
