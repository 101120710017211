export enum InstrumentType {
  SPOT = 'SPOT',
  FIXED_FORWARD = 'FIXED_FORWARD',
  WINDOW_FORWARD = 'WINDOW_FORWARD',
  FLEXIBLE_FORWARD = 'FLEXIBLE_FORWARD',
  SWAP = 'SWAP',
  DKI = 'DKI',
  DKO = 'DKO',
  EKI = 'EKI',
  EKO = 'EKO',
  KO = 'KO',
  RKI = 'RKI',
  RKO = 'RKO',
  VANILLA = 'VANILLA',
  WIN_DKO = 'WIN_DKO',
  WIN_KIKO = 'WIN_KIKO',
  WIN_RKI = 'WIN_RKI',
  WIN_RKO = 'WIN_RKO',
  WIN_KI = 'WIN_KI',
  WIN_KO = 'WIN_KO',
}

export const instrumentTypeToHuman: Record<InstrumentType, string> = {
  [InstrumentType.SPOT]: 'FX Spot',
  [InstrumentType.FIXED_FORWARD]: 'Fixed Forward',
  [InstrumentType.WINDOW_FORWARD]: 'Window Forward',
  [InstrumentType.FLEXIBLE_FORWARD]: 'Flexible Forward',
  [InstrumentType.SWAP]: 'SWAPS',
  [InstrumentType.DKI]: 'DKI',
  [InstrumentType.DKO]: 'DKO',
  [InstrumentType.EKI]: 'EKI',
  [InstrumentType.EKO]: 'EKO',
  [InstrumentType.KO]: 'KO',
  [InstrumentType.RKI]: 'RKI',
  [InstrumentType.RKO]: 'RKO',
  [InstrumentType.VANILLA]: 'Vanilla Options',
  [InstrumentType.WIN_DKO]: 'WIN_DKO',
  [InstrumentType.WIN_KIKO]: 'WIN_KIKO',
  [InstrumentType.WIN_RKI]: 'WIN_RKI',
  [InstrumentType.WIN_RKO]: 'WIN_RKO',
  [InstrumentType.WIN_KI]: 'WIN_KI',
  [InstrumentType.WIN_KO]: 'WIN_KO',
};

export function formatInstrumentType(instrument?: string, defaultStr = '') {
  if (!instrument) {
    return defaultStr;
  }
  return instrumentTypeToHuman[instrument as InstrumentType] || defaultStr;
}
