import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import { UpdateEntry, PaginatedResponse, PaginatedQuery, standardiseQuerySort } from '../models';

/**
 * Software Update Entries service
 *
 * Allows retrieval and editing of software updates.
 * GET endpoints are available in public API, all other endpoints are only available in the admin API
 */
export class UpdatesService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'updatesService' } },
      },
    });
  }

  public listUpdateEntries(params: PaginatedQuery, options?: Partial<HttpOptions<PaginatedResponse<UpdateEntry>>>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<UpdateEntry>>(`${this.baseUrl}software-updates`, {
      axiosConfig: { params },
      options: { ...options },
    });
  }

  public getUpdateEntry(updateId: string, options?: Partial<HttpOptions<UpdateEntry>>) {
    return this.get<UpdateEntry>(`${this.baseUrl}software-updates/${updateId}`, {
      options: { ...options },
    });
  }

  public createUpdateEntry(update: UpdateEntry, options?: Partial<HttpOptions<UpdateEntry>>) {
    return this.post<UpdateEntry>(`${this.baseUrl}software-updates`, update, {
      options: { ...options },
    });
  }

  public updateUpdateEntry(updateId: string, update: UpdateEntry, options?: Partial<HttpOptions<UpdateEntry>>) {
    return this.put<UpdateEntry>(`${this.baseUrl}software-updates/${updateId}`, update, {
      options: { ...options },
    });
  }

  public deleteUpdateEntry(updateId: string, options?: Partial<HttpOptions<void>>) {
    return this.delete(`${this.baseUrl}software-updates/${updateId}`, {
      options: { ...options },
    });
  }
}
