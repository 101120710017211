export function getThemeCSSColor(colorName: string, prefix: string = '--ah-color-') {
  return getComputedStyle(document.documentElement).getPropertyValue(prefix + colorName);
}

export interface GetHSLAOverrides {
  alpha?: number;
  prefix?: string;
  hue?: string;
  saturation?: string;
  luminance?: string;
}

export function getHSLA(color?: string, options?: GetHSLAOverrides) {
  const opts = {
    alpha: 1,
    prefix: '--ah-color-',
    ...options,
  };

  const hue = opts.hue ?? getThemeCSSColor(`${color}-H`, opts.prefix);
  const saturation = opts.saturation ?? getThemeCSSColor(`${color}-S`, opts.prefix);
  const luminance = opts.luminance ?? getThemeCSSColor(`${color}-L`, opts.prefix);
  return `hsla(${hue}, ${saturation}, ${luminance}, ${opts.alpha})`;
}
