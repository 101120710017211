import { CurrencyAmount, HedgingInstruments, PurposeTypes, TradeState } from '..';

export enum CashflowType {
  INVOICED = 'INVOICED',
  NET_SETTLEMENT = 'NET_SETTLEMENT',
  SCHEDULED = 'SCHEDULED',
}

export enum CashflowStatus {
  REALISED = 'REALISED',
  UNREALISED = 'UNREALISED',
}

export enum PnLType {
  CLIENT_MU = 'CLIENT_MU',
  PARTNER_COMMISSION = 'PARTNER_COMMISSION',
  SWAP_PNL = 'SWAP_PNL',
  SWAP_COMMISSION = 'SWAP_COMMISSION',
  SLIPPAGE = 'SLIPPAGE',
  INTERNAL_MU = 'INTERNAL_MU',
  CC_INTERNAL_MU_INVOICE = 'CC_INTERNAL_MU_INVOICE',
  CC_DRAWDOWN_PNL = 'CC_DRAWDOWN_PNL',
  DRAWDOWN_COMMISSION = 'DRAWDOWN_COMMISSION',
  DRAWDOWN_SLIPPAGE = 'DRAWDOWN_SLIPPAGE',
  IMCC = 'IMCC',
}

export interface PnLCurrency extends CurrencyAmount {
  gbpFxTranslation: number;
}

export interface PnLTotal {
  totalGbpAmount: number;
  totalGbpFxTranslation: number;
  pnlCurrencies: PnLCurrency[];
}

export interface PnLPartnerReport extends PnLTotal {
  partnerId: string;
  partnerName: string;
  pnlCurrencies: PnLCurrency[];
}

export interface PnLClientReport extends PnLPartnerReport {
  clientId: string;
  clientName: string;
  pnlCurrencies: PnLCurrency[];
}

export interface PnLProductReport extends PnLClientReport {
  hedgingProduct: HedgingInstruments;
}

export interface PnLTradeReport extends PnLProductReport {
  tradeId: string;
  referenceNumber: string;
  tradeDate: string;
  settlementDate: string;
  currencyPairDirection: string;
  currencyPair: string;
  bookedRate: number;
  purposeType: PurposeTypes;
  status: TradeState;
  markupBasisPoints: number;
  commissionRatePercentage: number;
  sellCurrency: string;
  sellAmount: number;
  buyCurrency: string;
  buyAmount: number;
}
