<script setup lang="ts">
import { computed } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import config from '@/config';
import { DotIcon } from 'ah-common-lib/src/icons/components';
import UserIcon from 'ah-common-lib/src/icons/components/UserIcon.vue';

const authStore = useAuthStore();

const showDevTools = computed(() => config.showDevTools);

const username = computed(() => authStore.loggedInUser?.usernamePresentation);
const isUsernameDefault = computed(() => authStore.loggedInUser?.usernameIsDefault);

const settingsBase = computed(() => (authStore.isAgent ? '/admin/settings' : '/settings'));

const emit = defineEmits({
  logout: () => true,
});
</script>

<template>
  <div class="popover-container dropdown-menu-block">
    <div class="popover-section">
      <ul>
        <div v-if="!isUsernameDefault" class="username-container">
          <div class="submenu-user"><UserIcon class="ml-2 mr-1" /> {{ username }}</div>
        </div>
        <RouterLink tag="li" :to="`${settingsBase}/account`">
          <div>Account Settings</div>
          <div v-if="isUsernameDefault" class="mt-1 submenu"><DotIcon class="mr-2" />Set up your username</div>
        </RouterLink>
        <RouterLink tag="li" :to="`${settingsBase}/personal-settings`">Personal Settings</RouterLink>
        <RouterLink tag="li" :to="`${settingsBase}/dev-settings`" v-if="showDevTools">[Dev Settings]</RouterLink>
        <li class="popover-section clickable" @click="emit('logout')">Log out</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popover-container {
  position: absolute;
  right: -6.25rem;
  top: 5px;
  border-radius: $user-menu-radius;
  padding: 1px;
  width: fit-content;
  min-width: 200px;
  max-width: min(400px, 90vw);

  .popover-section {
    .username-container {
      padding: 5px;
      border-radius: 1px;
      background-color: getColor($color-main-bg);
      cursor: default;
      width: 100%;
    }
    .submenu-base {
      font-size: $font-size-sm;
      margin-top: 2px;
      font-weight: $font-weight-semibold;
      padding: 10px;
      white-space: nowrap;
    }

    .submenu {
      @extend .submenu-base;
      border-radius: 3px;
      @include themedTextColor($x-ui-light-tag-orange-text, $x-ui-dark-tag-orange-text);
      @include themedBackgroundColor($x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
      svg {
        width: 4px;
        height: 4px;
      }
    }

    .submenu-user {
      @extend .submenu-base;
      border-radius: 1px;
      @include themedTextColor($x-ui-light-fg-interactive, $x-ui-dark-fg-interactive);
      @include themedBackgroundColor($x-ui-light-tag-green-bg, $x-ui-dark-tag-green-bg);

      svg {
        width: 18px;
        height: 18px;
        border-radius: 40px;
        @include themedBackgroundColor($x-ui-light-bg-field, $x-ui-dark-bg-field);
        padding: 2px;
      }
    }
  }
}
</style>
