<script lang="ts">
import { computed, PropOptions } from 'vue';

export const updatedDateProps = {
  date: {
    type: [String, Date],
    default: '',
  },
  loading: {
    type: [String, Boolean],
    default: false,
  },
  offsetMillis: {
    type: Number,
    default: 0,
  },
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
const props = defineProps(updatedDateProps);

const emit = defineEmits({
  refresh: () => true,
});

const dateObject = computed(() => {
  if (props.date instanceof Date) {
    return props.date;
  } else {
    return new Date(props.date);
  }
});

const processedDate = computed(() => new Date(dateObject.value.getTime() + props.offsetMillis));

function refresh() {
  emit('refresh');
}
</script>

<template>
  <span class="updated-date">
    <template v-if="loading === false">
      Last Updated <TimeAgo v-if="date" class="ml-1" dateStyle="short" :date="processedDate" />
    </template>
    <template v-else> Updating... </template>
    <span @click="refresh"> <IconRefresh :class="['refresh-icon', { loading }]" /> </span>
  </span>
</template>

<style lang="scss" scoped>
.refresh-icon {
  margin-left: 0.5em;
  cursor: pointer;

  &.loading {
    animation: spin 4s linear infinite;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
