import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

type CacheStore<T> = {
  expiryTime: number;
  maxItems: number;
  items: {
    key: string;
    item: T;
    date: Date;
  }[];
};

export class HttpCacheService {
  protected requestCache: { [key: string]: CacheStore<any> } = {};

  /**
   * clear HTTP request cache for provided key. If no cache key is provided the whole cache is cleared
   *
   * @param cacheKey the key for the cached response
   */
  public clearCache(cacheKey?: string, itemKey: string | undefined = undefined) {
    Object.keys(this.requestCache).forEach((k) => {
      if (!cacheKey || cacheKey === k) {
        if (itemKey) {
          const currentItemIndex = this.requestCache[k].items.findIndex((i) => i.key === itemKey);
          if (currentItemIndex > -1) {
            this.requestCache[k].items.splice(currentItemIndex, 1);
          }
        } else {
          this.requestCache[k].items = [];
        }
      }
    });
  }

  /**
   * creates a new cache store with the specified key and configurations. if a cache with the same key already exists nothing is done
   *
   * @param cacheKey the unique key that the cache can be accessed later
   * @param expiryTime the time in miliseconds the cache will be valid, default 15000
   * @param maxItems the maximum items the cache will store, default 50
   */
  protected setupCache<T>(cacheKey: string, expiryTime = 15000, maxItems = 50) {
    if (!this.requestCache[cacheKey]) {
      this.requestCache[cacheKey] = {
        expiryTime,
        maxItems,
        items: [],
      } as CacheStore<T>;
    }
  }

  /**
   * add item to cache with specified keys, to identify cache and item added
   * - if an item with the same key already exists it will be replaced by the new one
   * - if the cache reaches max items defined on configuration, the oldest item will be removed
   *
   * @param cacheKey the unique key for the cache where the item will be stored
   * @param itemKey the unique key to identify the added item in the cache
   * @param item the item to be stored in the cache
   */
  protected setCache<T>(cacheKey: string, itemKey: string | ((item: T) => string), item: T) {
    const key = typeof itemKey === 'string' ? itemKey : itemKey(item);

    // create cache if it doesn't exist
    this.setupCache<T>(cacheKey, 15000);

    const currentItemIndex = this.requestCache[cacheKey].items.findIndex((i) => i.key === key);
    const newItem = { key, item, date: new Date() };

    if (currentItemIndex > -1) {
      this.requestCache[cacheKey].items.splice(currentItemIndex, 1);
    }

    if (this.requestCache[cacheKey].items.length >= this.requestCache[cacheKey].maxItems) {
      this.requestCache[cacheKey].items.splice(0, 1);
    }

    this.requestCache[cacheKey].items.push(newItem);

    return null;
  }

  /**
   * retrieves item from the cache, for a unique provided cache key and item key
   * - if cache with provided key does not exist, a new cache will be created
   * - if item is found but is is older that the configured expiry time, no item will be returned
   *
   * @param cacheKey the unique key for the cache where the item should be
   * @param itemKey the unique key for item to retrieve from the cache
   */
  protected getCache<T>(cacheKey: string, itemKey: string) {
    // create cache if it doesn't exist
    this.setupCache<T>(cacheKey, 15000);

    const cacheItem = this.requestCache[cacheKey].items.find((i) => i.key === itemKey);
    if (cacheItem && differenceInMilliseconds(new Date(), cacheItem.date) < this.requestCache[cacheKey].expiryTime) {
      return cacheItem.item;
    }

    return null;
  }
}
