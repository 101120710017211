import { HttpBasedService, HttpService } from 'ah-requests';
import { PartnerCommissionSettings } from '../models/commission/partnerCommissionSettings';

export class PartnerCommissionSettingsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'commissionsService' } },
      },
    });
  }

  public getPartnerCommissions(partnerId: string) {
    return this.get<PartnerCommissionSettings>(`${this.baseUrl}partners/${partnerId}/commissions`);
  }

  public updatePartnerCommissions(partnerId: string, commissions: PartnerCommissionSettings) {
    return this.put<PartnerCommissionSettings>(`${this.baseUrl}partners/${partnerId}/commissions`, commissions);
  }
}
