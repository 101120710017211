export interface PaymentFormDetails {
  strikeRate: number;
  buyAmount: number;
  buyCurrency: string;
  sellCurrency: string;
  sellAmount: number;
  mvpCustomerEmail: string;
  mvpInvoiceNumber: string;
  mvpDescription?: string;
  mvpFrequency: PaymentScheduleFrequency;
  mvpNumPayments: number;
  mvpFirstPaymentDate: string;
}

export enum PaymentScheduleFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  BIANNUAL = 'BIANNUAL',
  ANNUAL = 'ANNUAL',
}
