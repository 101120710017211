<script setup lang="ts">
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { textField } from 'ah-common-lib/src/form/models';
import { Beneficiary, PurposeCodeResponse, beneficiaryName } from 'ah-api-gateways';
import { computed, reactive, watch } from 'vue';
/**
 * Payment reason form for payment processing
 */

const props = withDefaults(
  defineProps<{
    /**
     * Sync-able value for reason
     */
    reason?: string | null;
    /**
     * Whether to display this component in a narrow style
     */
    narrow?: string | boolean;
    /**
     * Beneficiary target of the payment.
     *
     * If set, form will display and emit values according to the following logic:
     * - If beneficiary has a purpose code, value emmitted is null, form is not editable, and purpose description is shown
     * - Otherwise, description is editable, and values are emmitted
     */
    beneficiary?: Beneficiary;
  }>(),
  {
    reason: '',
    narrow: false,
  }
);

const emit = defineEmits<{
  /**
   * Emitted when `reason` changes. Value emitted also depends on `beneficiary` value. Can be used with v-model
   */
  (e: 'update:reason', value: string | null): void;
}>();

const txReasonFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'txReason',
    fieldType: 'form',
    fields: [textField('reason', '', { required: false, maxLength: 50, showLengthCounter: true })],
  }),
  validation: null,
});

const state = reactive<{
  purposeCodes: PurposeCodeResponse | null;
  reasonValue: string | null;
}>({
  purposeCodes: null,
  reasonValue: '',
});

const isNarrow = computed(() => props.narrow !== false);

const valid = computed(() => {
  if (props.beneficiary?.purposeCode) {
    return true;
  }
  if (txReasonFormDef.validation) {
    return !txReasonFormDef.validation.$invalid;
  }
  return true;
});

watch(
  () => state.reasonValue,
  () => {
    txReasonFormDef.form.reason = state.reasonValue;
    emitValue();
  },
  { immediate: true }
);

watch(
  () => props.reason,
  () => {
    state.reasonValue = props.reason;
  },
  { immediate: true }
);

watch(
  () => props.beneficiary,
  () => {
    if (props.beneficiary) {
      if (!txReasonFormDef.validation?.reason?.$dirty) {
        state.reasonValue = `Payment sent to ${beneficiaryName(props.beneficiary)}`;
      }

      // Value needs to be emmitted in the next JS frame, to allow all watchers to run beforehand
      setTimeout(() => emitValue());
    }
  },
  { immediate: true }
);

function touchForms() {
  if (txReasonFormDef.validation) {
    submitForm(txReasonFormDef.validation);
  }
}

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    state.reasonValue = txReasonFormDef.form.reason;
    emitValue();
  }
}

function emitValue() {
  emit('update:reason', txReasonFormDef.form.reason);
}

defineExpose({ valid: valid.value, touchForms });
</script>

<template>
  <ValidatedForm
    :fm="txReasonFormDef.form"
    :validation.sync="txReasonFormDef.validation"
    @form-event="onFormEvent"
    :class="{ 'narrow-style': isNarrow }"
  >
    <template #txReason.reason:before>
      <span class="label-before"> Payment reason </span>
    </template>
  </ValidatedForm>
</template>

<style lang="scss" scoped>
::v-deep {
  .field-group-wrapper {
    display: flex;
    margin-bottom: 0;

    .label-before {
      white-space: nowrap;
      margin-top: 0.4em;
      margin-right: 1em;
    }

    .field-group.reason {
      width: 100%;
    }
  }
}

.narrow-style ::v-deep .field-group-wrapper {
  flex-wrap: nowrap;
}
</style>
