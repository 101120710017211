<script setup lang="ts">
import SingleOwnerOutstandingFeesListing from './SingleOwnerOutstandingFeesListing.vue';
import SingleOwnerOutstandingFeesHeader from './SingleOwnerOutstandingFeesHeader.vue';
import ListBlock from 'ah-common-lib/src/common/components/listing/ListBlock.vue';
import { computed, ref } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { PaginatedParams } from 'ah-api-gateways';

const props = withDefaults(
  defineProps<{
    userType?: 'client' | 'partner';
    clientOrPartnerId?: string;
  }>(),
  {
    userType: 'client',
  }
);

const feeList = ref<InstanceType<typeof SingleOwnerOutstandingFeesListing> | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const defaultSortAndPageParams: Partial<PaginatedParams> = { sort: 'createdAt', sortDirection: 'DESC' };

const ownerId = computed(() => props.clientOrPartnerId || onBehalfOfClient.value?.id);

function loadData() {
  feeList.value!.loadData();
}

defineExpose({ loadData });
</script>

<template>
  <ListBlock v-bind="$attrs" :defaultSortAndPageParams="defaultSortAndPageParams" v-if="ownerId">
    <template #filters="{ onSortAndPageParamsChange, sortAndPageParams }">
      <SingleOwnerOutstandingFeesHeader
        @update:sortAndPageParams="onSortAndPageParamsChange"
        :sortAndPageParams="sortAndPageParams"
        :clientOrPartnerId="ownerId"
        :userType="userType"
      />
    </template>
    <template #list="{ setSortAndPageParams, sortAndPageParams }">
      <SingleOwnerOutstandingFeesListing
        ref="feeList"
        v-if="ownerId"
        :clientOrPartnerId="ownerId"
        :userType="userType"
        :sortAndPageParams="sortAndPageParams"
        @update:sortAndPageParams="setSortAndPageParams"
        paginationQueryParam="feesSort"
        filterQueryParam="feesFilter"
        v-on="$listeners"
        v-bind="$attrs"
      />
    </template>
  </ListBlock>
</template>
