<script lang="ts" setup>
import { selectField } from 'ah-common-lib/src/form/models';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { DateRange } from 'ah-common-lib/src/common/components/DateSelector.vue';
import {
  purposeTypesToHuman,
  HedgingInstruments,
  TradeState,
  PurposeTypes,
  CommissionDisplayType,
  TradeStatus,
  formatHedgingInstrument,
} from 'ah-api-gateways';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
  subWeeks,
  subMonths,
  subYears,
  startOfToday,
  endOfToday,
} from 'date-fns';
import DateSelector from 'ah-common-lib/src/common/components/DateSelector.vue';
import { tradeStatusBadges } from '../../../../ah-api-gateways/models/trade/tradeStatus';
import { computed, PropType, ref, watch } from 'vue';
import {
  defineUseManagedListFilterProps,
  useManagedListFilter,
  UseManagedListFilterEmits,
} from 'ah-common-lib/src/listing';
import { useAhTradesState } from '../..';
import { noop } from 'lodash';

const tradeStatusOptions = (Object.keys(tradeStatusBadges) as TradeStatus[]).map((k) => {
  return {
    value: k,
    label: tradeStatusBadges[k].label,
  };
});

interface TradeReportListFilters {
  instrument?: HedgingInstruments[];
  status?: TradeStatus[];
  currencyPair?: string[];
  purposeType?: string[];
  tradeDateFrom?: string;
  tradeDateTo?: string;
  settlementDateFrom?: string;
  settlementDateTo?: string;
  clientId?: string[];
  query?: string;
  queryBy?: string;

  commissionDisplayType: CommissionDisplayType;
}

const ahTradesState = useAhTradesState();

const displayOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
];

const datePickerChoices = [
  { label: 'Today', start: startOfToday(), end: endOfToday() },
  { label: 'This week', start: startOfWeek(startOfToday()), end: endOfWeek(endOfToday()) },
  { label: 'Past 7 days', start: subWeeks(startOfToday(), 1), end: endOfToday() },
  { label: 'This month', start: startOfMonth(startOfToday()), end: endOfMonth(endOfToday()) },
  {
    label: 'Last month',
    start: startOfMonth(subMonths(startOfToday(), 1)),
    end: endOfMonth(subMonths(endOfToday(), 1)),
  },
  {
    label: 'Last 3 month',
    start: startOfMonth(subMonths(startOfToday(), 3)),
    end: endOfMonth(subMonths(endOfToday(), 1)),
  },
  {
    label: 'Last 6 month',
    start: startOfMonth(subMonths(startOfToday(), 6)),
    end: endOfMonth(subMonths(endOfToday(), 1)),
  },
  { label: 'Last 1 year', start: startOfYear(subYears(startOfToday(), 1)), end: endOfYear(subYears(endOfToday(), 1)) },
  { label: 'All', start: startOfYear(subYears(startOfToday(), 5)), end: endOfYear(endOfToday()) },
];

const tradeStatusForm = ref(
  makeFormModel({
    name: 'tradeFm',
    fieldType: 'form',
    fields: [
      selectField('tdStatus', 'Trade Status', tradeStatusOptions, {
        required: false,
        clearable: true,
        clearValue: '',
        placeholder: 'All',
      }),
    ],
  })
);

const currencyPairForm = ref(
  makeFormModel({
    name: 'currencyPairFm',
    fieldType: 'form',
    fields: [
      selectField('currencyPair', 'Currency Pair', () => currencyPairs.value, {
        required: false,
        clearable: true,
        clearValue: '',
        placeholder: 'All',
      }),
    ],
  })
);

const tradeDate = ref<DateRange>();

const settlementDate = ref<DateRange>();

interface TradeReportListFiltersEmits extends UseManagedListFilterEmits<TradeReportListFilters> {}

const emit = defineEmits<TradeReportListFiltersEmits>();

const props = defineProps({
  ...defineUseManagedListFilterProps<TradeReportListFilters>(),
  /**
   * Whether to show state filters
   *
   * Any value other than false is considered truthy
   */
  showStates: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Which states to allow
   *
   * Only applicable if showStates is true
   */
  possibleStates: {
    type: Array as PropType<TradeState[]>,
  },
  /**
   * Which hedging instruments to allow
   */
  possibleInstruments: {
    type: Array as PropType<HedgingInstruments[]>,
  },
  /**
   * Which hedging instruments to allow
   */
  possiblePurposes: {
    type: Array as PropType<PurposeTypes[]>,
  },
  /**
   * Whether to show date filters
   *
   * Any value other than false is considered truthy
   */
  showDates: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to show the search box for searching by trade id
   * (or client name, if allowClientNameSearch is truthy)
   *
   * Any value other than false is considered truthy
   */
  showSearch: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to allow searching by client name
   * Has no effect if showSearch is false
   *
   * Any value other than false is considered truthy
   */
  allowClientNameSearch: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether a trade is pre searched
   *
   * This value should be the same as the trade reference number
   */
  openTrade: {
    type: String,
  },
  /**
   * Whether to show date filters
   *
   * Any value other than false is considered truthy
   */
  hidePageSize: {
    type: [Boolean, String],
    default: false,
  },
});

const instrumentOptions = computed(() => {
  const instruments: HedgingInstruments[] = [
    HedgingInstruments.FX_SPOT,
    HedgingInstruments.FX_FORWARD,
    HedgingInstruments.WINDOW_FORWARD,
    HedgingInstruments.FLEXIBLE_FORWARD,
    HedgingInstruments.VANILLA_OPTIONS,
  ];

  return instruments
    .filter((k) => !props.possibleInstruments?.length || props.possibleInstruments.includes(k))
    .map((k) => {
      return {
        value: k,
        label: formatHedgingInstrument(k),
      };
    });
});

const purposeTypeOptions = computed(() => {
  const purpose: PurposeTypes[] = [PurposeTypes.OPENING_TRADE, PurposeTypes.DRAWDOWN, PurposeTypes.ROLL_FORWARD];

  return purpose
    .filter((k) => !props.possiblePurposes?.length || props.possiblePurposes.includes(k))
    .map((k) => {
      return {
        value: k,
        label: purposeTypesToHuman[k],
      };
    });
});

const instrumentForm = ref(
  makeFormModel({
    name: 'instrumentFm',
    fieldType: 'form',
    fields: [
      selectField('instrumentFm', 'Instrument', () => instrumentOptions.value, {
        required: false,
        clearable: true,
        clearValue: '',
        placeholder: 'All',
      }),
    ],
  })
);

const purposeTypeForm = ref(
  makeFormModel({
    name: 'purposeTypeFm',
    fieldType: 'form',
    fields: [
      selectField('purposeType', 'Purpose Type', () => purposeTypeOptions.value, {
        required: false,
        clearable: true,
        clearValue: '',
        placeholder: 'All',
      }),
    ],
  })
);

const displayForm = ref(
  makeFormModel({
    name: 'displayFm',
    fieldType: 'form',
    fields: [
      selectField('pageSize', 'Display', displayOptions, {
        required: false,
        defaultValue: 10,
      }),
    ],
  })
);

const currencyPairs = computed(() => {
  return ahTradesState.store.useSettingsStore().currencyPairs.map((i) => ({ label: i, value: i }));
});

const showPageSize = computed(() => {
  return props.hidePageSize === false;
});

const isApportioned = computed(() => {
  return filterData.value.commissionDisplayType === CommissionDisplayType.APPORTIONED;
});

const { filterData } = useManagedListFilter<TradeReportListFilters>({
  props,
  emit,
  runSettersOnUndefined: true,
  filterKeys: [
    {
      key: 'instrument',
      getter: () => instrumentForm.value.instrumentFm || undefined,
      setter: (value: any) => (instrumentForm.value.instrumentFm = value),
    },
    {
      key: 'status',
      getter: () => tradeStatusForm.value.tdStatus || undefined,
      setter: (value: any) => (tradeStatusForm.value.tdStatus = value),
    },
    {
      key: 'currencyPair',
      getter: () => currencyPairForm.value.currencyPair || undefined,
      setter: (value: any) => (currencyPairForm.value.currencyPair = value),
    },
    {
      key: 'purposeType',
      getter: () => purposeTypeForm.value.purposeType || undefined,
      setter: (value: any) => (purposeTypeForm.value.purposeType = value),
    },
    {
      key: 'tradeDateFrom',
      getter: () => {
        if (tradeDate.value?.start && !isNaN(new Date(tradeDate.value.start).valueOf())) {
          return startOfDay(tradeDate.value.start);
        }
      },
      setter: (value: any) => {
        if (tradeDate.value) {
          tradeDate.value.start = startOfDay(new Date(value));
        }
      },
    },
    {
      key: 'tradeDateTo',
      getter: () => {
        if (tradeDate.value?.end && !isNaN(new Date(tradeDate.value.end).valueOf())) {
          return endOfDay(tradeDate.value.end);
        }
      },
      setter: (value: any) => {
        if (tradeDate.value) {
          tradeDate.value.end = endOfDay(new Date(value));
        }
      },
    },
    {
      key: 'settlementDateFrom',
      getter: () => {
        if (settlementDate.value?.start && !isNaN(new Date(settlementDate.value.start).valueOf())) {
          return startOfDay(settlementDate.value.start);
        }
      },
      setter: (value: any) => {
        if (settlementDate.value) {
          settlementDate.value.start = startOfDay(new Date(value));
        }
      },
    },
    {
      key: 'settlementDateTo',
      getter: () => {
        if (settlementDate.value?.start && !isNaN(new Date(settlementDate.value.start).valueOf())) {
          return endOfDay(settlementDate.value.end);
        }
      },
      setter: (value: any) => {
        if (settlementDate.value) {
          settlementDate.value.end = endOfDay(new Date(value));
        }
      },
    },
    {
      key: 'query',
      getter: (filters) => filters.value.query || undefined,
      setter: (value: any, filters) => (filters.value.query = value),
    },
    {
      key: 'queryBy',
      getter: (filters) => {
        if (!filters.value.query) {
          return undefined;
        }
        return props.allowClientNameSearch === false
          ? ['composedReferenceNumber']
          : ['clientName', 'composedReferenceNumber'];
      },
      setter: noop,
    },
    {
      key: 'commissionDisplayType',
      getter: (filters) => filters.value.commissionDisplayType || CommissionDisplayType.ORIGINAL,
      setter: (value: any, filters) => (filters.value.commissionDisplayType = value),
    },
  ],
  sortAndPageKeys: [
    {
      key: 'pageSize',
      getter: () => displayForm.value.pageSize || undefined,
      setter: (value: any) => (displayForm.value.pageSize = value),
    },
  ],
});

watch(
  () => props.openTrade,
  () => {
    if (props.openTrade) {
      filterData.value.query = props.openTrade;
    }
  },
  { immediate: true }
);

ahTradesState.store.useSettingsStore().loadTradeableCurrencies();
</script>

<template>
  <div>
    <VRow alignH="between">
      <VCol cols="4" lg="4" md="5" sm="12" v-if="showSearch !== false">
        <div class="d-inline-flex col cols-12">
          <SearchInput
            class="mock-label-padding d-inline"
            :placeholder="`Search for ${allowClientNameSearch === false ? '' : 'client name or'} trade ID...`"
            :search.sync="filterData.query"
          />
          <InfoTooltip class="mock-label-padding pt-2" :text="ahTradesState.i18n.t('tooltips.tradeID')" />
        </div>
      </VCol>
      <VCol cols="4" lg="8" md="6" sm="12" class="text-sm-center text-md-right">
        <div class="mr-4">
          <label class="mr-1">Commission Display Type</label>
          <InfoTooltip class="comission-icon" :text="ahTradesState.i18n.t('tooltips.commissionDisplayType')" />
        </div>
        <div>
          <VButton
            :class="[{ 'btn-primary': !isApportioned }, { 'btn-stroked': isApportioned }]"
            @click="filterData.commissionDisplayType = CommissionDisplayType.ORIGINAL"
            class="mb-4"
          >
            Original
          </VButton>
          <VButton
            class="ml-3"
            :class="[{ 'btn-primary': isApportioned }, { 'btn-stroked': !isApportioned }]"
            @click="filterData.commissionDisplayType = CommissionDisplayType.APPORTIONED"
          >
            Apportioned
          </VButton>
        </div>
      </VCol>
    </VRow>

    <VRow alignV="end">
      <VCol sm="12" md="6" xl="2" v-if="showStates !== false">
        <ValidatedForm :fm="tradeStatusForm" />
      </VCol>
      <VCol sm="12" md="6" xl="2">
        <ValidatedForm :fm="currencyPairForm" />
      </VCol>
      <VCol sm="12" md="6" xl="1">
        <ValidatedForm :fm="instrumentForm" />
      </VCol>
      <VCol sm="12" md="6" xl="2">
        <ValidatedForm :fm="purposeTypeForm" />
      </VCol>

      <VCol sm="12" md="6" xl="2" v-if="showDates !== false">
        <DateSelector
          title="Trade date"
          confirmButtonLabel="Done"
          class="custom-date-selector"
          :date.sync="tradeDate"
          :commonChoices="datePickerChoices"
          clearable
          isRanged
        >
          <template v-slot:icon>
            <IconCalendar class="btn-date-picker-icon" />
          </template>
        </DateSelector>
      </VCol>
      <VCol sm="12" md="6" xl="2" v-if="showDates !== false">
        <DateSelector
          title="Settlement date"
          confirmButtonLabel="Done"
          class="custom-date-selector"
          :date.sync="settlementDate"
          :commonChoices="datePickerChoices"
          clearable
          isRanged
        >
          <template v-slot:icon>
            <IconCalendar class="btn-date-picker-icon" />
          </template>
        </DateSelector>
      </VCol>

      <VCol sm="12" md="6" xl="1" v-if="showPageSize !== false">
        <ValidatedForm :fm="displayForm" />
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.vc-container {
  border: none;
  @include themedTextColor($color-text, $color-primary);
  @include themedBackgroundColor($color-white-datepicker-bg, $color-dark-input-bg);
}

.btn {
  width: 122px;
  .btn.btn-primary {
    background-color: none !important;
  }
}

.custom-date-selector {
  margin-bottom: 1em !important;
}
</style>
