import { FieldOptions } from '../interfaces';

export const defaultOptions: FieldOptions = {
  required: true,
  showRequiredMarkers: true,
};

export const defaultRateFieldOptions: FieldOptions = {
  ...defaultOptions,
  min: 0,
  defaultValue: null,
  fieldWrapperClass: 'col col-3',
};

export const defaultSelectFieldOptions: FieldOptions = {
  ...defaultOptions,
  placeholder: 'Select One',
  fieldWrapperClass: 'col col-3',
};
