<script setup lang="ts">
import { BHTradeStatus } from 'ah-api-gateways';
import StatusPill from 'ah-common-lib/src/common/components/StatusPill.vue';
import { computed } from 'vue';

defineProps<{
  status: BHTradeStatus;
}>();

const statusColors = computed<Record<BHTradeStatus, string>>(() => {
  return {
    [BHTradeStatus.OPEN]: 'success',
    [BHTradeStatus.CLOSED]: 'warning',
    [BHTradeStatus.AMENDED]: 'primary',
    [BHTradeStatus.CANCELED]: 'danger',
    [BHTradeStatus.REMAINING_CANCELING]: 'danger',
    [BHTradeStatus.REMAINING_CANCELED]: 'danger',
    [BHTradeStatus.REJECTED]: 'danger',
    [BHTradeStatus.DATE_ROLLED]: 'success',
    [BHTradeStatus.REMAINING_DATE_ROLLED]: 'success',
    [BHTradeStatus.FULLY_SETTLED]: 'primary',
    [BHTradeStatus.REMAINING_SETTLED]: 'primary',
    [BHTradeStatus.OVERDUE]: 'danger',
    [BHTradeStatus.SETTLES_TODAY]: 'primary',
    [BHTradeStatus.SUBMITTED]: 'success',
    [BHTradeStatus.PARTIALLY_OPEN]: 'success',
    [BHTradeStatus.CANCELING]: 'danger',
    [BHTradeStatus.REMAINING_CLOSED]: 'warning',
    [BHTradeStatus.VERIFIED]: 'success',
  };
});

const defaultStatusLabels = computed<Record<BHTradeStatus, string>>(() => {
  return {
    [BHTradeStatus.OPEN]: 'Open',
    [BHTradeStatus.CLOSED]: 'Closed',
    [BHTradeStatus.AMENDED]: 'Amended',
    [BHTradeStatus.REJECTED]: 'Rejected',
    [BHTradeStatus.CANCELED]: 'Cancelled',
    [BHTradeStatus.REMAINING_CANCELING]: 'Cancelled',
    [BHTradeStatus.REMAINING_CANCELED]: 'Cancelled',
    [BHTradeStatus.DATE_ROLLED]: 'Rolled',
    [BHTradeStatus.REMAINING_DATE_ROLLED]: 'Rolled',
    [BHTradeStatus.FULLY_SETTLED]: 'Settled',
    [BHTradeStatus.REMAINING_SETTLED]: 'Partially Settled',
    [BHTradeStatus.OVERDUE]: 'Overdue',
    [BHTradeStatus.SETTLES_TODAY]: 'Settles Today',
    [BHTradeStatus.SUBMITTED]: 'Submitted',
    [BHTradeStatus.PARTIALLY_OPEN]: 'Partially Open',
    [BHTradeStatus.CANCELING]: 'Cancelling',
    [BHTradeStatus.REMAINING_CLOSED]: 'Partially Closed',
    [BHTradeStatus.VERIFIED]: 'Verified',
  };
});
</script>

<template>
  <StatusPill class="status-pill" :color="statusColors[status]" :label="defaultStatusLabels[status]" />
</template>

<style lang="scss" scoped>
.status-pill {
  white-space: nowrap;
  font-size: $font-size-sm;
  border-radius: 2.5em;
  border: 1px solid;
  color: getColor($color-secondary) !important;
  padding: 0.3em 0.9em;
  border-color: transparent;

  &.success {
    border-color: getColor($color-success);
  }

  &.warning {
    border-color: getColor($color-yellow-highlight);
  }

  &.primary {
    border-color: getColor($color-primary);
  }

  &.danger {
    border-color: getColor($color-danger);
  }
}
</style>
