<script setup lang="ts">
import ChartLegend from './ChartLegend.vue';
import SliderInput from 'ah-common-lib/src/common/components/SliderInput.vue';
import ValidatedForm from 'ah-common-lib/src/form/components/formComponents/ValidatedForm.vue';
import colors from 'ah-theme/src/styles/color-export.module.scss';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { VCol } from 'ah-common-lib/src/common/components';
import { VRow } from 'ah-common-lib/src/common/components';
import { computed, ref, watch } from 'vue';
import { financialAmountField } from 'ah-common-lib/src/form/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { required } from '@vuelidate/validators';

const DEFAULT_ALLOWED_LIMIT_VALUES = {
  min: 0,
  max: 2500000,
};

const CHART_LEGEND_DATA = [
  { color: colors.xUiLightFgSubtle, label: 'Utilised Limit' },
  { color: colors.xUiDarkTagNeutralText, label: 'Current Limit' },
  { color: colors.xUiDarkTagOrangeText, label: 'Requested Limit' },
];

const props = defineProps({
  currency: {
    type: String,
    default: 'GBP',
  },
  currentLimit: {
    type: Number,
    required: true,
  },
  requestedForwardAndOptionLimitsAmount: {
    type: Number,
    required: true,
  },
  utilisedLimit: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits({
  'update:requestedForwardAndOptionLimitsAmount': (_payload: number) => true,
  'update:validation': (_payload: boolean) => true,
});

const selectedAmount = ref<number>(props.requestedForwardAndOptionLimitsAmount);

const dynamicMaxLimit = computed(() => {
  return Math.max(props.utilisedLimit, props.currentLimit, selectedAmount.value, DEFAULT_ALLOWED_LIMIT_VALUES.max);
});

const utilizedLimitSliderPercentage = computed(() => (props.utilisedLimit * 100) / dynamicMaxLimit.value);

const currentLimitSliderPercentage = computed(() => {
  if (props.utilisedLimit > props.currentLimit) {
    return (props.currentLimit * 100) / dynamicMaxLimit.value;
  }
  return ((props.currentLimit - props.utilisedLimit) * 100) / dynamicMaxLimit.value;
});

const forwardAndOptionLimitsFormDef = ref<FormDefinition>({
  form: makeFormModel({
    name: 'forwardAndOptionLimits',
    fieldType: 'form',
    fields: [
      financialAmountField(
        'amount',
        '',
        {
          fieldWrapperClass: 'col-4',
          defaultValue: props.requestedForwardAndOptionLimitsAmount,
          errorMessages: {
            required: 'Value must be equal to or greater than 0',
          },
        },
        {
          required,
        }
      ),
    ],
  }),
  validation: null,
});

watch(
  () => forwardAndOptionLimitsFormDef.value.form.amount,
  (newAmount) => {
    if (forwardAndOptionLimitsFormDef.value.validation?.$invalid === false) {
      selectedAmount.value = newAmount;
      emit('update:requestedForwardAndOptionLimitsAmount', newAmount);
    }
  }
);

watch(
  () => forwardAndOptionLimitsFormDef.value.validation?.$invalid,
  (invalid) => emit('update:validation', !!invalid),
  { immediate: true }
);
</script>

<template>
  <VRow class="forward-option-limits">
    <VCol cols="12" class="mb-2">
      <h4>Forward and Option Limits</h4>
    </VCol>
    <VCol cols="12" class="mb-2">
      <ValidatedForm
        :fm="forwardAndOptionLimitsFormDef.form"
        :validation.sync="forwardAndOptionLimitsFormDef.validation"
      >
        <template #forwardAndOptionLimits.amount:prepend>
          <BInputGroupText> {{ currency }} </BInputGroupText>
        </template>
      </ValidatedForm>
    </VCol>
    <VCol cols="12" class="mb-2">
      <SliderInput
        :value.sync="selectedAmount"
        :min="{
          value: DEFAULT_ALLOWED_LIMIT_VALUES.min,
          label: `min ${currency} ${formatCurrencyValue(DEFAULT_ALLOWED_LIMIT_VALUES.min)}`,
        }"
        :max="{
          value: dynamicMaxLimit,
          label: `${currency} ${formatCurrencyValue(dynamicMaxLimit)}`,
        }"
        :showThumb="false"
        showMarkers
        showColorRanges
        :markers="10"
      >
        <template #colorRanges>
          <div
            :class="[
              'slider-utilised-limit',
              {
                'end-border-radius': utilizedLimitSliderPercentage === 100,
                'show-on-top': currentLimit > utilisedLimit && selectedAmount >= utilisedLimit,
              },
            ]"
            :style="{ width: `${utilizedLimitSliderPercentage}%` }"
          ></div>
          <div
            :class="[
              'slider-current-limit',
              {
                'start-border-radius': utilizedLimitSliderPercentage === 0 || utilisedLimit > currentLimit,
                'end-border-radius': utilizedLimitSliderPercentage + currentLimitSliderPercentage === 100,
                'show-on-top': utilisedLimit > currentLimit && selectedAmount >= currentLimit,
              },
            ]"
            :style="{
              width: `${currentLimitSliderPercentage}%`,
              left: `${utilisedLimit > currentLimit ? 0 : utilizedLimitSliderPercentage}%`,
            }"
          ></div>
        </template>
        <template #currentValueColorRange="{ currentValuePercentage }">
          <div
            :class="[
              'slider-current-value',
              {
                'show-on-top':
                  currentValuePercentage <= utilizedLimitSliderPercentage ||
                  currentValuePercentage <
                    (utilisedLimit > currentLimit
                      ? utilizedLimitSliderPercentage
                      : parseFloat((currentLimitSliderPercentage + utilizedLimitSliderPercentage).toFixed(2))),
              },
            ]"
            :style="{ width: `${currentValuePercentage}%` }"
          ></div>
        </template>
      </SliderInput>
    </VCol>
    <VCol cols="12" class="chart-legend">
      <ChartLegend :chartLegendData="CHART_LEGEND_DATA" />
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.forward-option-limits {
  margin-bottom: 4rem;

  .chart-legend {
    margin-top: 1rem;
  }

  .slider-utilised-limit {
    position: absolute;
    height: 100%;
    background-color: $x-ui-light-fg-subtle;
    border-radius: 10px 0 0 10px;
    z-index: 2;

    &.end-border-radius {
      border-radius: 10px;
    }

    &.show-on-top {
      z-index: 5;
    }
  }

  .slider-current-limit {
    position: absolute;
    height: 100%;
    background-color: $x-ui-dark-tag-neutral-text;
    z-index: 3;

    &.end-border-radius {
      border-radius: 0 10px 10px 0;
    }

    &.start-border-radius {
      border-radius: 10px 0 0 10px;
    }

    &.show-on-top {
      z-index: 5;
    }
  }

  .slider-current-value {
    position: absolute;
    height: 100%;
    background-color: $x-ui-dark-tag-orange-text;
    border-radius: 10px;
    overflow: hidden;

    &.show-on-top {
      z-index: 4;
    }
  }
}
</style>
