<script setup lang="ts">
import { computed } from 'vue';
import { generateUID } from '../../../helpers/uuid';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { DatedValue } from 'ah-api-gateways';

const props = defineProps(makeUseFormElementProps<FieldModel, boolean | DatedValue<boolean>>());

const emit = defineEmits(makeUseFormElementEmits<boolean | DatedValue<boolean>>());

const uid = generateUID(6);

const formElement = useFormElement({
  emit,
  props,
});

const { fieldErrorsShown, readonly, inputErrorClass } = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getSlotName, getModelState, setValue } = formElement;

const isSwitch = computed(() => getModelState('checkBoxStyle', 'checkbox') === 'switch');

const valuePropName = modelStateRef('valuePropName', 'value');

const dated = modelStateRef('dated');

const label = modelStateRef('label');

const value = computed(() => {
  if (field.value.$model && {}.hasOwnProperty.call(field.value.$model, valuePropName.value)) {
    return (field.value.$model as any)[valuePropName.value];
  }
  return field.value.$model;
});

function onChange($event: Event) {
  let value: any = !!($event.target as any).checked;
  if (dated.value) {
    value = {
      ...(typeof field.value.$model === 'object' ? field.value.$model : {}),
      [valuePropName.value]: value,
      date: new Date().toISOString(),
    };
  }
  setValue(value);
}
</script>

<template>
  <div
    :class="{ 'custom-checkbox': !isSwitch, 'custom-switch': isSwitch, [inputErrorClass]: fieldErrorsShown }"
    class="checkbox-holder custom-control custom-checkbox"
  >
    <input
      :id="`${model.$path}-${uid}`"
      :checked="value"
      :disabled="readonly"
      type="checkbox"
      class="custom-control-input"
      @change="onChange"
    />
    <label :for="`${model.$path}-${uid}`" class="custom-control-label">
      <slot :name="getSlotName('checkbox-label')" v-bind="{ field, model, label }">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.custom-switch {
  .custom-control-label {
    font-weight: 500;

    &::before {
      background-color: $common-color-lightGreyShadow;
      border: 0;
      height: 1.3rem;
    }

    &::after {
      background-color: $common-color-white;
      box-shadow: 0px 1px 3px rgba(107, 124, 148, 0.25);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $common-color-purple;
    }
  }
}
</style>
