import { FieldOption } from '../form/interfaces';

export enum TimeFrames {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  THREE_WEEKS = 'THREE_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
  OTHER = 'OTHER',
}

export const timeFramesTranslations: FieldOption[] = [
  {
    value: TimeFrames.ONE_WEEK,
    label: '1 week',
  },
  {
    value: TimeFrames.TWO_WEEKS,
    label: '2 weeks',
  },
  {
    value: TimeFrames.THREE_WEEKS,
    label: '3 weeks',
  },
  {
    value: TimeFrames.ONE_MONTH,
    label: '1 month',
  },
  {
    value: TimeFrames.TWO_MONTHS,
    label: '2 months',
  },
  {
    value: TimeFrames.THREE_MONTHS,
    label: '3 months',
  },
  {
    value: TimeFrames.SIX_MONTHS,
    label: '6 months',
  },
  {
    value: TimeFrames.NINE_MONTHS,
    label: '9 months',
  },
  {
    value: TimeFrames.TWELVE_MONTHS,
    label: '12 months',
  },
  {
    value: TimeFrames.OTHER,
    label: 'Custom date',
  },
];
