export enum PurposeTypes {
  OPENING_TRADE = 'OPENING_TRADE',
  DRAWDOWN = 'DRAWDOWN',
  ROLL_FORWARD = 'ROLL_FORWARD',
  AMEND_TRADE = 'AMEND_TRADE',
}

export const purposeTypesToHuman: Record<PurposeTypes, string> = {
  OPENING_TRADE: 'Opening Trade',
  DRAWDOWN: 'Drawdown',
  ROLL_FORWARD: 'Roll Forward',
  AMEND_TRADE: 'Amend Trade',
};
