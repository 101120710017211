import Vue from 'vue';
import { getRouter } from '../router/router';
import { makeStoreSupportData } from './supportData';
import { runActions } from './runActions';
import { CustomRoute } from '../router/routeModels';
import { checkAuth } from '../router/guards';
import { authStoreFactory } from 'ah-common-stores';

export const useAuthStore = authStoreFactory({
  checkRouteAuth() {
    const router = getRouter();

    // Recheck user's permission to be in the current route
    checkAuth(router.currentRoute as CustomRoute, router.currentRoute as CustomRoute, (location) => {
      if (location && typeof location !== 'function') {
        router.replace(location).catch(() => {
          // ignore route errors in this change
        });
      }
    });
  },
  admin: false,
  getAppUpdater: () => Vue.ahAppUpdaterState,
  getOtp: () => Vue.otp,
  getRouter,
  getToast: () => Vue.toast,
  makeStoreSupportData,
  runActions: (action) => runActions(action),
});
