import type { ComplianceFilesStatus, ComplianceStatus } from '../compliance/compliance';
import type { Address } from '../common/address';
import type { ClientReference, ClientType } from './clientReference';
import type {
  IndividualEditModel,
  OnboardingIndividualInfo,
  IndividualReference,
  UserReference,
  AuthorityType,
  UploadedFile,
  Permission,
  BankType,
} from '..';
import type { VersionedObject } from '../rest';
import type { Questionnaire } from '../compliance/questionnaire';

export enum ClientOnboardingStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum ClientGeneralStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum MifidCategorization {
  PROFESSIONAL = 'PROFESSIONAL',
  RETAIL = 'RETAIL',
}

export interface CompanyBasicInfo {
  sectorCategory?: string;
  sicCode: number;
  sectorDescription: string;
}

export interface CompanyDetails extends CompanyBasicInfo {
  name: string;
  address: Address;
  tradingAddress?: Address;
  entityType: string;
  registrationNumber: string;
  companyType?: string;
  reportingCurrency?: string;
  permissions?: AuthorityType[];
  phoneNumber?: string;
  website?: string;
}

interface BaseClient extends ClientReference {
  complianceStatus: ComplianceStatus;
  status: ClientOnboardingStatus;
  companyDetails: CompanyDetails;
  questionnaireAnswer: string;
  individualDetails?: IndividualEditModel;
  reportingCurrency?: string;
  permissions?: AuthorityType[];
  updatedAt?: Date;
  updatedBy?: UserReference;
  address: Address;
  termsAndConditionsDate?: string;
  termsAndConditionsSentBy?: string;
  termsAndConditionsSentByName?: string;
  termsAndConditionsSentByEmail?: string;
  termsAndConditionsSentAt?: string;
  mifidCategorization?: MifidCategorization;
  enableXHedgePro: boolean;
  createdBy?: UserReference;
  createdAt?: string;
  preferredBankingProvider?: BankType;
  /**
   * Partner reference (only exists if using admin endpoints)
   */
  partner?: {
    id: string;
  };
}

export interface Client extends BaseClient, VersionedObject {
  /**
   * Partner reference (only exists if using admin endpoints)
   */
  partner?: {
    name: string;
    id: string;
  };
}

export interface ClientUpdateRequest {
  name?: string;
  companyDetails: Partial<CompanyDetails>;
}

export interface ClientListResponse {
  id: string;
  /**
   * Partner reference (only exists if using admin endpoints)
   */
  partner?: {
    name: string;
    id: string;
  };
  permissions: AuthorityType[];
  name: string;
  companyDetails: CompanyDetails;
  individualDetails: IndividualEditModel;
  type: ClientType;
  complianceStatus: ComplianceStatus;
  status: ClientOnboardingStatus;
  limitsStatus: ClientGeneralStatus;
  mcfStatus: ClientGeneralStatus;
  tradingEnabled: ClientGeneralStatus;
  createdAt: string;
  documentUploadStatus?: ComplianceFilesStatus;

  updatedBy?: UserReference;
  createdBy?: UserReference;
}

export interface ClientApprovalData {
  client: Client;
  permissions: Permission[];
  questionnaire: Questionnaire;
  individuals: OnboardingIndividualInfo[];
  documents: UploadedFile[];
  ubos: IndividualReference[];
}

export interface OwnerUpdateRequest {
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthDate: string;
  jobTitle: string;
  workPhoneNumber: string;
  email: string;
}
