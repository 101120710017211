<script lang="ts">
import { computed } from 'vue';
import FormattedNumber, { formattedNumberProps } from 'ah-common-lib/src/number/FormattedNumber.vue';
import { useWalletsCurrencyFormatter } from './useWalletsCurrencyFormatter';

/**
 * FormattedCurrencyValue component for use within ah-trades
 *
 * FIXME after ah-wallets/ah-trades/ah-beneficiaries/ah-reports merge,
 * this should be replaced with a component common to all 4 subpackages
 */

const { formatter, prefixLabel, ...reducedProps } = formattedNumberProps;
</script>

<script lang="ts" setup>
const props = defineProps({
  ...reducedProps,
  currency: {
    type: String,
    required: true,
  },
  withPrefix: {
    type: [String, Boolean],
    default: true,
  },
});

const formatter = useWalletsCurrencyFormatter({
  currency: computed(() => props.currency),
});
</script>

<template>
  <FormattedNumber v-bind="$props" :prefix-label="withPrefix !== false ? currency : ''" :formatter="formatter" />
</template>
