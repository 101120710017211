import isEqual from 'lodash/isEqual';
import { Observable, Subject, Subscription } from 'rxjs';
import { multicast, refCount } from 'rxjs/operators';
import { set } from 'vue';

import { manageRxState, managePromiseState, RequestState } from './requestState';

export class RequestManager {
  public readonly requestStates: { [key: string]: RequestState } = {};

  private items: {
    [key: string]: {
      request: Subscription;
      multicasted: Observable<any>;
      payload: any;
    };
  } = {};

  private promises: {
    [key: string]: {
      promise: Promise<any>;
      payload: any;
    };
  } = {};

  /**
   * Observable management
   */

  public new<T>(key: string, request: Observable<T>) {
    return this.makeRequestObs<T>(key, request);
  }

  public currentOrNew<T>(key: string, request: Observable<T>) {
    if (this.items[key]) {
      return this.items[key].multicasted as Observable<T>;
    }

    return this.new(key, request);
  }

  public sameOrNew<T>(key: string, request: Observable<T>, payload?: any) {
    if (this.items[key] && this.isSamePayload(this.items, key, payload)) {
      return this.items[key].multicasted as Observable<T>;
    }

    return this.new(key, request);
  }

  public cancelAndNew<T>(key: string, request: Observable<T>) {
    this.cancel(key);

    return this.new(key, request);
  }

  public sameOrCancelAndNew<T>(key: string, request: Observable<T>, payload?: any) {
    if (this.items[key] && this.isSamePayload(this.items, key, payload)) {
      return this.items[key].multicasted as Observable<T>;
    }
    this.cancel(key);

    return this.makeRequestObs<T>(key, request, payload);
  }

  private makeRequestObs<T>(key: string, req: Observable<T>, payload?: any): Observable<T> {
    this.cleanup(key);
    const multicasted = new Observable((observer) => {
      const managed = manageRxState((val) => {
        if (this.requestStates[key] !== 'error' || val !== 'idle') {
          set(this.requestStates, key, val);
        }
      }, req);
      const request = managed.subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });

      this.items[key] = {
        request,
        multicasted,
        payload,
      };

      return () => {
        this.items[key]?.request.unsubscribe();
        this.cleanup(key);
      };
    }).pipe(multicast(new Subject()), refCount());

    return multicasted;
  }

  /**
   * Promise management
   */

  private makePromise<T>(key: string, promise: Promise<T>, payload?: any) {
    this.promises[key] = { promise, payload };

    managePromiseState((val) => {
      if (this.requestStates[key] !== 'error' || val !== 'idle') {
        set(this.requestStates, key, val);
      }
    }, promise);

    promise.finally(() => {
      delete this.promises[key];
    });

    return promise;
  }

  public newPromise<T>(key: string, promise: Promise<T>) {
    return this.makePromise(key, promise);
  }

  public currentOrNewPromise<T>(key: string, promiseFactory: () => Promise<T>) {
    if (this.promises[key]) {
      return this.promises[key].promise as Promise<T>;
    }

    return this.makePromise(key, promiseFactory());
  }

  public sameOrNewPromise<T>(key: string, promiseFactory: () => Promise<T>, payload: any) {
    if (this.promises[key] && this.isSamePayload(this.promises, key, payload)) {
      return this.promises[key].promise as Promise<T>;
    }

    return this.newPromise(key, promiseFactory());
  }

  /**
   * General state
   */

  public waitForRequests(keys?: string[], useRecheck?: boolean): Promise<void> {
    if (!this.isAnyInState('pending')) {
      return Promise.resolve();
    }

    const allPromises = Object.keys(this.promises)
      .filter((k) => !keys || keys.includes(k))
      .map((k) => this.promises[k].promise.catch());

    const allObs = Object.keys(this.items)
      .filter((k) => !keys || keys.includes(k))
      .map((k) => this.items[k].multicasted.toPromise().catch());

    return Promise.all([...allPromises, ...allObs]).then(() => {
      if (useRecheck) {
        return this.waitForRequests(keys, useRecheck);
      }
    });
  }

  public isAnyInState(state: RequestState, keys?: string[]) {
    const reqKeys = keys ?? Object.keys(this.requestStates);
    for (let i = 0; i < reqKeys.length; i++) {
      if (this.requestStates[reqKeys[i]] === state) {
        return true;
      }
    }
    return false;
  }

  public get anyPending() {
    return this.isAnyInState('pending');
  }

  public get currentStates() {
    return Object.values(this.requestStates);
  }

  /**
   * Utils
   */

  private isSamePayload(collection: { [key: string]: { payload: any } }, key: string, payload?: any) {
    if (collection[key]) {
      return isEqual(payload, collection[key].payload);
    }
    return true;
  }

  /**
   * Cleanup
   */

  public cancel(key: string) {
    if (this.items[key]?.request && !this.items[key].request.closed) {
      this.items[key].request.unsubscribe();
      this.cleanup(key);
    }
  }

  /**
   * Clear all current requests, potentially excluding a whitelist
   * @param whitelist List of keys to exclude or tester function for exclusion. Excluded keys will not be cleared.
   */
  public clear(whitelist?: ((key: string) => boolean) | string[]) {
    const testWhitelist =
      typeof whitelist === 'function' ? whitelist : (key: string) => (whitelist ?? []).includes(key);
    Object.keys(this.items).forEach((k) => {
      if (!testWhitelist(k)) {
        this.cancel(k);
      }
    });
    Object.keys(this.promises).forEach((k) => {
      if (!testWhitelist(k)) {
        delete this.promises[k];
      }
    });
  }

  private cleanup(key: string) {
    delete this.items[key];
  }
}
