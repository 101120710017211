<template>
  <PrivateTopNav v-if="authStore.isLoggedIn" class="top-nav" />
  <PublicTopNav v-else class="top-nav" />
</template>

<script setup lang="ts">
import PublicTopNav from '@/app/components/nav/PublicTopNav.vue';
import PrivateTopNav from '@/app/components/nav/PrivateTopNav.vue';
import { useAuthStore } from '@/app/store/authStore';

const authStore = useAuthStore();
</script>
