<script setup lang="ts">
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, useAttrs } from 'vue';

const props = defineProps({
  exposeToParent: {
    type: [Boolean, String],
    default: true,
  },
});

const emit = defineEmits({
  retry: () => true,
});

const attrs = useAttrs();
const exposedToParent = computed(() => props.exposeToParent !== false);

const requestManager = useRequestManager({
  exposeToParent: exposedToParent.value,
});

const anyLoading = computed(() => {
  if (attrs.loading !== undefined) {
    return attrs.loading;
  }
  return requestManager.anyRequestInState('pending', true);
});

const anyFailed = computed(() => {
  if (attrs.error !== undefined) {
    return attrs.error;
  }
  return requestManager.anyRequestInState('error', true);
});

const allFailedRequests = computed(() => requestManager.getAllRequestsInState('error', true));

function onRetry() {
  allFailedRequests.value.forEach((r) => r.managerData.retryRequest(r.request));
  emit('retry');
}
</script>

<template>
  <LoadingOverlay :error="anyFailed" :loading="anyLoading" @retry="onRetry" v-bind="$attrs" v-on="$listeners">
    <slot />
  </LoadingOverlay>
</template>
