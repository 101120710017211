<script setup lang="ts">
import { computed } from 'vue';
import { Notification, NotificationTranslations, updateNotifications } from 'ah-api-gateways';

/**
 * Notification component
 *
 * TODO: currently `view-resource` event is only triggered for custom-created software update notification, to allow opening modal
 */

const props = defineProps<{ notification: Notification }>();

const emit = defineEmits<{
  (e: 'mark-as-read', notification: Notification): void;
  (e: 'view-resource', notification: Notification): void;
}>();

const notificationTitle = computed(
  () => props.notification.title || NotificationTranslations[props.notification.type] || 'N/A'
);

const isSoftwareUpdate = computed(() => updateNotifications.includes(props.notification.type));

function markAsRead() {
  emit('mark-as-read', props.notification);
}

function viewResource() {
  emit('view-resource', props.notification);
}
</script>

<template>
  <div
    class="notification-card card-block my-2"
    :class="{ 'no-bg': notification.read || $ahNotificationsState.mediaQuery.is('smDown') }"
  >
    <div class="d-flex justify-content-between mb-1">
      <span class="d-flex">
        <h4>{{ notificationTitle }}</h4>
      </span>
      <span v-if="!notification.read" class="notification-dot" @click="markAsRead()">&#9679;</span>
    </div>
    <span>{{ notification.message }}</span>
    <span v-if="isSoftwareUpdate">&nbsp;View the updates <a class="link" @click="viewResource">here</a>.</span>
    <slot name="actions" />
  </div>
</template>

<style lang="scss" scoped>
.notification-card {
  padding: 0.8rem;

  .notification-dot {
    @include themedTextColor($color-primary-light);
    font-size: 1.6em;
    line-height: 0.8em;
    cursor: pointer;
  }
  .icon {
    width: 1em;
    align-self: center;
  }
}
</style>
