<script setup lang="ts">
import ClientListing from '@/app/components/clients/ClientListing.vue';
import debounce from 'lodash/debounce';
import { clientTableFields } from './tableFields/clientTableFields';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { ExportListType, ListSortParams } from 'ah-api-gateways';
import { computed, PropType, ref, watch } from 'vue';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import BoxGrid from '../common/boxGrid/BoxGrid.vue';
import BoxGridBlock from '../common/boxGrid/BoxGridBlock.vue';
import { VCol, VRow } from 'ah-common-lib/src/common/components';
import BoxGridItem from '../common/boxGrid/BoxGridItem.vue';

const clientListing = ref<InstanceType<typeof ClientListing> | null>(null);

const props = defineProps({
  commonFilters: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  showExport: {
    type: [Boolean, String],
    default: false,
  },
  tableFields: {
    type: Array as PropType<TableFieldDefinition[]>,
    default: () => clientTableFields,
  },
});

const dataDownloadState = ref('idle');

const clientsConfig = computed(() => ({
  tableFields: props.tableFields,
}));

const userFilters = ref({});

const sortAndPageParams = ref<Partial<ListSortParams>>({});

const filter = ref({ ...props.commonFilters, ...userFilters.value });

const debouncedFilterChange = debounce(() => {
  filter.value = { ...props.commonFilters, ...userFilters.value };
}, 1500);

function downloadList(format: ExportListType) {
  clientListing.value!.downloadData(format);
}

function setFilters(filters: any) {
  userFilters.value = filters;
}

function setSortAndPageParams(params: Partial<ListSortParams>) {
  sortAndPageParams.value = params;
}

watch(userFilters, debouncedFilterChange);
</script>

<template>
  <BoxGrid alignH="stretch" alignV="start">
    <BoxGridBlock cols="12">
      <slot name="filters" v-bind="{ setFilters }" />
    </BoxGridBlock>
    <VRow cols="12" class="mx-0 w-100">
      <VCol>
        <slot name="sorter" v-bind="{ sortAndPageParams, setSortAndPageParams }" />
      </VCol>
    </VRow>
    <BoxGridBlock cols="12">
      <ClientListing
        fixed
        :config="clientsConfig"
        :dataDownloadState.sync="dataDownloadState"
        ref="clientListing"
        :filter="filter"
        :sortAndPageParams.sync="sortAndPageParams"
        v-on="$listeners"
        v-bind="$attrs"
      >
        <template #row-details="row">
          <slot name="row-details" v-bind="row" />
        </template>
      </ClientListing>
    </BoxGridBlock>

    <BoxGridItem cols="12">
      <ListDownloadButton
        v-if="showExport !== false"
        :loading="dataDownloadState === 'pending'"
        class="download-button"
        @download="downloadList"
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.trade-details {
  padding: 0 math.div($padded-space, 2);
}

.dates-filter-holder {
  flex-basis: 26em;
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep {
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }

    .field-group-field-input.month,
    .field-group-field-input.day {
      width: 3rem !important;
    }

    .field-group-field-input.year {
      width: 4rem !important;
    }
  }
}
</style>
