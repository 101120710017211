<script setup lang="ts">
import { BTabs, BTab } from 'bootstrap-vue';
import { editRoute } from 'ah-common-lib/src/helpers/route';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { noop } from 'lodash';

/**
 * Tabs component syncronised with URL query string
 * Replaces Bootstrap-vue's BTabs component: https://bootstrap-vue.org/docs/components/tabs#tabs
 *
 * Exposes all props meant for BTabs (via v-bind="$attrs")
 * Exposes all events meant for BTabs (via v-on="$listeners")
 */

const props = defineProps({
  /**
   * Query string key to set/listen to in URL. Defaults to 'tab'
   */
  queryStringProp: {
    type: String,
    default: 'tab',
  },
  /**
   * Whether to replace, rather than push to, the browser history on tab change
   */
  useReplace: {
    type: Boolean,
    default: false,
  },
  /**
   * Syncable tab to be active
   */
  tab: {
    type: String,
  },
});

const emit = defineEmits({
  'update:tab': (_tabName: String) => true,
});

const route = useRoute();

const router = useRouter();

const tabs = ref<InstanceType<typeof BTabs>>();

/**
 * contains all the names of tab's which are walked through, it also contain current tab, as the last element
 */
const tabNames = ref<string[]>([]);

/**
 * current tab; which is on display on screen
 */
const currentTab = ref<string>('');

onMounted(() => {
  // set timeout to make sure correct tab is set after "active" sets current active tab
  setTimeout(() => onTabPropChange());
});

function onTabsChange(tabs: BTab[]) {
  if (tabs) {
    tabNames.value = tabs.map((tab, index) => tab.$attrs.name || tab.$props.title || index.toString());

    if (!route.query[props.queryStringProp]) {
      const activeIndex = tabs.findIndex((t) => (t as any).active);
      tabIndex.value = Math.max(activeIndex, 0);
    }
  } else {
    tabNames.value = [];
  }
}

const tabIndex = computed({
  /**
   *  return the index of the last tab, so that it can be opened on the screen
   */
  get() {
    return Math.max(tabNames.value.indexOf(currentTab.value), 0);
  },
  /**
   * sets the current tab from the tabNames with the index number
   */
  set(value: number) {
    currentTab.value = tabNames.value[value];
  },
});

/**
 *  On tab activation, update URL (if from event)
 *
 *  Tabs are not activated directly, so as to allow disabling redirection via route guards
 */
function onActivateTab(newTabIndex: number, prevTabIndex?: number, bvEvt?: any) {
  if (currentTab.value !== tabNames.value[newTabIndex]) {
    if (bvEvt) {
      setTabRoute(tabNames.value[newTabIndex]);
      bvEvt.preventDefault();
    } else {
      tabIndex.value = newTabIndex;
    }
  }
}

/**
 * Watches for the tabs property change and as it is changed,
 * if it is the string change it stores in the current tabs query
 */

function onTabPropChange() {
  if (typeof route.query[props.queryStringProp] === 'string') {
    currentTab.value = route.query[props.queryStringProp] as string;
  }
}

watch(() => route, onTabPropChange, { deep: true });

function setTabRoute(tabName: string = currentTab.value) {
  if (tabName && tabName !== route.query[props.queryStringProp]) {
    const routeAux = editRoute(route, { query: { add: { [props.queryStringProp]: tabName } } });
    if (props.useReplace || !route.query[props.queryStringProp]) {
      router.replace(routeAux).catch(noop);
    } else {
      router.push(routeAux).catch(noop);
    }
  }
  if (tabName && tabName !== props.tab) {
    emit('update:tab', tabName);
  }
}

/**
 * route is updated, as the tab changes, it pushes it into the router for the history.
 */
function updateRoute() {
  setTimeout(() => setTabRoute());
}

watch(currentTab, updateRoute, { immediate: true });

function onTabChange() {
  if (props.tab) {
    currentTab.value = props.tab;
  }
}

watch(() => props.tab, onTabChange, { immediate: true });
</script>

<template>
  <BTabs
    v-model="tabIndex"
    @activate-tab="onActivateTab"
    ref="tabs"
    v-bind="$attrs"
    v-on="$listeners"
    @changed="onTabsChange"
  >
    <slot />
  </BTabs>
</template>
