<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { BModal } from 'bootstrap-vue';
import OtpRequestForm from 'ah-common-lib/src/otp/OtpRequestForm.vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { phoneField } from 'ah-common-lib/src/form/models';
import { helpers } from '@vuelidate/validators';
import config from '@/config';
import { SecurityErrorCodes } from 'ah-api-gateways';
import { mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { optional, checkParam, requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';
import ValidatedForm from 'ah-common-lib/src/form/components/formComponents/ValidatedForm.vue';

const changeModal = ref<InstanceType<typeof BModal>>();
const verificationModal = ref<InstanceType<typeof BModal>>();
const changeOtpRequest = ref<InstanceType<typeof OtpRequestForm>>();
const validateOtpRequest = ref<InstanceType<typeof OtpRequestForm>>();

const otp = ref('');
const pendingVerification = ref(false);
const otpValidation = ref<FormValidation>();

const authStore = useAuthStore();
const services = getServices();
const toast = useToast();

const requestManager = useRequestManager().manager;

const changeContactsFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'changePhoneForm',
    fieldType: 'form',
    fields: [
      phoneField(
        'phoneNumber',
        'New telephone number',
        {
          errorMessages: { differentFrom: 'Cannot be the same as the current phone number' },
          required: true,
        },
        {
          differentFrom: helpers.withParams({ type: 'differentFrom' }, (val: any) => val !== currentPhoneNumber.value),
          phone: optional(checkParam('phoneNumber', 'valid')),
          required: requiredIfStateValue('phoneNumber'),
        }
      ),
    ],
  }),
  validation: null,
});

const anyInvalid = computed(() => {
  if (!otpValidation.value || !changeContactsFormDef.validation) {
    return true;
  }
  return otpValidation.value.$invalid || changeContactsFormDef.validation.$invalid;
});

const isNewPhoneInvalid = computed(() => {
  if (!changeContactsFormDef.validation) {
    return true;
  }
  return changeContactsFormDef.validation.$invalid;
});

const currentPhoneNumber = computed(() => authStore.loggedInUser?.individual?.phoneNumber ?? '');
const currentPhoneLastDigits = computed(() =>
  currentPhoneNumber.value.substring(Math.max(0, currentPhoneNumber.value.length - 3))
);

const newPhoneNumber = computed(() => authStore.loggedInUser?.secondaryPhoneNumber ?? '');
const newPhoneLastDigits = computed(() => newPhoneNumber.value.substring(Math.max(0, newPhoneNumber.value.length - 3)));

const staticOTP = computed(() => config.staticOTP);
const tfaEnabled = computed(() => !!authStore.userData?.tfaEnabled);

function changePhone() {
  let request = services.account.changeContacts(
    { phoneNumber: changeContactsFormDef.form.phoneNumber },
    tfaEnabled.value ? otp.value : undefined,
    {
      errors: { silent: true },
    }
  );

  requestManager.sameOrCancelAndNew('changePhone', request.pipe(mergeMap(() => services.auth.getSession()))).subscribe(
    (session) => {
      authStore.setTokenUser(session);
      closeChangeModal();
      if (session.secondaryPhoneNumber) {
        pendingVerification.value = true;
        openVerificationModal();
      } else {
        toast.success('New phone number verified');
      }
    },
    (e) => {
      if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(e.response?.data?.code)) {
        changeOtpRequest.value?.setOtpError(
          e.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
        );
      }
    }
  );
}

function confirmPhone() {
  requestManager
    .sameOrCancelAndNew(
      'changePhone',
      services.account
        .verifyOtpForNumberChange(otp.value, {
          errors: {
            silent: true,
          },
        })
        .pipe(mergeMap(() => from(authStore.refreshSession())))
    )
    .subscribe(
      () => {
        closeVerificationModal();
        toast.success('New phone number verified');
      },
      (e) => {
        if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(e.response?.data?.code)) {
          validateOtpRequest.value?.setOtpError(
            e.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
          );
        }
      }
    );
}

function refreshOtpRequest() {
  return services.auth.refreshOtp();
}

function refreshNewPhoneOtpRequest() {
  return services.account.refreshOtpForNumberChange();
}

function closeChangeModal() {
  changeModal.value?.hide();
  otpValidation.value = undefined;
  otp.value = '';
}

function onChangeModalHidden() {
  otpValidation.value = undefined;
}

function onVerificationModalHidden() {
  pendingVerification.value = false;
  otpValidation.value = undefined;
}

function openChangeModal() {
  otp.value = '';
  changeModal.value?.show();
}

function closeVerificationModal() {
  verificationModal.value?.hide();
  otpValidation.value = undefined;
  otp.value = '';
}

function openVerificationModal() {
  otp.value = '';
  verificationModal.value?.show();
}
</script>

<template>
  <div class="phone-number-change">
    <div class="field-title mb-1">Telephone number</div>
    <div class="phone">{{ getPhoneNumber(currentPhoneNumber) }}</div>
    <div class="link">
      <a class="text-secondary change-link text-small" @click="openChangeModal">Change phone number</a>
    </div>
    <div class="text-small pending-number" v-if="newPhoneNumber && !pendingVerification">
      <div class="icon">
        <IconDangerSign />
      </div>
      <div class="text">You have not verified your new telephone number<br />{{ getPhoneNumber(newPhoneNumber) }}.</div>
      <div class="link">
        <a class="text-secondary change-link" @click="openVerificationModal">Verify phone number</a>
      </div>
    </div>
    <BModal
      modal-class="change-modal"
      title="Change telephone number"
      ref="changeModal"
      hide-footer
      @hidden="onChangeModalHidden"
    >
      <div class="mb-3">
        <div class="field-title mb-1">Telephone number</div>
        <div class="phone">{{ getPhoneNumber(currentPhoneNumber) }}</div>
      </div>
      <ValidatedForm
        :fm="changeContactsFormDef.form"
        :validation.sync="changeContactsFormDef.validation"
        class="mb-3 phone-form"
      />
      <OtpRequestForm
        ref="changeOtpRequest"
        title="For security reasons, before confirming your new phone number we must verify your request."
        class="mb-5"
        :otp.sync="otp"
        :staticOTP="staticOTP"
        :otpPhone="currentPhoneLastDigits"
        :refreshOtpRequest="refreshOtpRequest"
        :forbidOtpRequest="isNewPhoneInvalid"
        :validation.sync="otpValidation"
        v-if="tfaEnabled"
      />
      <p class="text-center">
        <VButton @click="closeChangeModal" class="btn-stroked mr-3">Cancel</VButton>
        <VButton :loading="requestManager.anyPending" :disabled="anyInvalid" @click="changePhone">Confirm</VButton>
      </p>
    </BModal>
    <BModal
      modal-class="verification-modal"
      title=""
      ref="verificationModal"
      hide-footer
      @hidden="onVerificationModalHidden"
    >
      <div class="mb-3">
        <OtpRequestForm
          ref="validateOtpRequest"
          title="Please confirm your new phone number"
          :otp.sync="otp"
          :staticOTP="staticOTP"
          autoRequest
          :otpPhone="newPhoneLastDigits"
          :refreshOtpRequest="refreshNewPhoneOtpRequest"
          :validation.sync="otpValidation"
        />
      </div>
      <p class="text-center">
        <VButton @click="closeVerificationModal" class="btn-stroked mr-3">Cancel</VButton>
        <VButton :loading="requestManager.anyPending" :disabled="!otp" @click="confirmPhone">Confirm</VButton>
      </p>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.change-link {
  text-decoration: underline;
}

.pending-number {
  margin-top: 0.3em;
  position: relative;

  .text,
  .icon {
    color: getColor($color-danger);
  }

  .text,
  .change-link {
    padding-left: 2em;
  }

  .icon {
    position: absolute;
    left: 0;
    top: -0.15em;
  }
}
</style>
