export type ListSortParams = {
  sortDirection?: 'ASC' | 'DESC';
  sort?: string | string[];
};

export type ListPageParams = {
  pageNumber?: number;
  pageSize?: number;
};

export type PaginatedParams = ListSortParams & ListPageParams;

export type ListQuery<T = { [key: string]: any }> = {
  [key: string]: any;
} & ListSortParams &
  Partial<T>;

export type PaginatedQuery<T = { [key: string]: any }> = ListPageParams & ListQuery<T>;

export interface ListResponse<T> {
  list: T[];
}

export interface PaginatedResponse<T> extends ListResponse<T> {
  sortDirection?: 'ASC' | 'DESC';
  sort?: string | string[];
  pageNumber?: number;
  pageSize?: number;
  total: number;
}

export function standardiseSorting(sortString: string, sortDirection: string = 'desc'): string {
  return sortString + ',' + sortDirection.toLowerCase();
}

// FIXME this should be the standard on every request, so this helper function can be removed
export function standardiseQuerySort(query: ListQuery): ListQuery {
  const out = { ...query };
  if (out.sort && !Array.isArray(out.sort) && out.sort.indexOf(',') === -1) {
    out.sort = standardiseSorting(out.sort, out.sortDirection);
  } else if (out.sort && !Array.isArray(out.sort) && out.sort.indexOf(',') !== -1) {
    // if sort contains a coma we assume we want to sort by multiple distinct keys
    // Note: all sortBy will have the same sortDirection
    const sortArray = out.sort.split(',');
    sortArray.forEach((sortBy, index) => {
      sortArray[index] = standardiseSorting(sortBy, out.sortDirection);
    });
    out.sort = sortArray;
  }
  delete out.sortDirection;

  return out;
}
