<script lang="ts" setup>
import { EmailOptions, Trade } from 'ah-api-gateways';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { emailField } from 'ah-common-lib/src/form/models';
import { BModal } from 'bootstrap-vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { ref, reactive } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../..';

const modal = ref<BModal | null>(null);
const tradeState = useAhTradesState();

const props = defineProps<{
  trade: Trade;
}>();

const requestManager = useRequestManager({
  exposeToParent: ['sendTradeEmailConfirmation'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'sendTradeEmailConfirmation') {
      send();
    }
  },
});

const emailForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'emailForm',
    fieldType: 'form',
    fields: [
      emailField('recipient', 'Recipient', { required: true }),
      emailField('cc', 'cc', { required: false }),
      emailField('bcc', 'bcc', { required: false }),
    ],
  }),
  validation: null,
});

function show() {
  modal.value?.show();
}

function hide() {
  modal.value?.hide();
}

function send() {
  /**
   * Send email after all validations have been checked
   */
  if (emailForm.validation) {
    submitForm(emailForm.validation);
    if (emailForm.validation.$invalid) {
      return;
    }
    let emailOptions = {
      cc: [emailForm.form.cc],
      bcc: [emailForm.form.bcc],
      to: [emailForm.form.recipient],
    } as Partial<EmailOptions>;

    requestManager.manager
      .cancelAndNew(
        'sendTradeEmailConfirmation',
        tradeState.services.notification.resendTradeConfirmation(props.trade.id, emailOptions)
      )
      .subscribe(() => {
        tradeState.toast.success('Trade confirmation resend successfully');
        hide();
      });
  }
}
</script>

<template>
  <span>
    <BModal title="Resend trade confirmation" modal-class="side-modal" ref="modal" hide-footer>
      <BoxGrid class="info-block" alignH="center" alignV="start">
        <BoxGridBlock>
          <ValidatedForm :fm="emailForm.form" :validation.sync="emailForm.validation" />
        </BoxGridBlock>
      </BoxGrid>
      <div class="buttons text-sm-center text-md-left">
        <VButton class="btn-secondary mr-2" :disabled="requestManager.manager.anyPending" @click="hide">Cancel</VButton>
        <VButton class="btn-success" :loading="requestManager.manager.anyPending" @click="send">Send</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <VButton blurOnClick @click="show" class="button-success"> Resend trade confirmation </VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
.buttons {
  .btn {
    @include desktop {
      min-width: 104px;
    }
  }
}
.info-block {
  max-width: 26.5rem;
}
</style>
