<script lang="ts" setup>
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { usePlaidLink, preloadPlaid } from 'ah-common-lib/src/plaid';
import IntegrationsListing from '../../integrations/IntegrationsListing.vue';
import { VButton } from 'ah-common-lib/src/common/components';
import { useToast } from 'ah-common-lib/src/toast';
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import {
  ClientIntegration,
  IntegrationState,
  IntegrationType,
  PaginatedParams,
  PaginatedResponse,
} from 'ah-api-gateways';
import { useAhTradesState } from '../../..';
import StatusPill from 'ah-common-lib/src/common/components/StatusPill.vue';
import ArrowRightIcon from 'ah-common-lib/src/icons/components/ArrowRightIcon.vue';
import ExclamationCircleIcon from 'ah-common-lib/src/icons/components/ExclamationCircleIcon.vue';

const STATUS_COLORS: Record<IntegrationState, string> = {
  [IntegrationState.ACTIVE]: 'success',
  [IntegrationState.REVOKED]: 'danger',
};

const STATUS_LABELS: Record<IntegrationState, string> = {
  [IntegrationState.ACTIVE]: 'Active',
  [IntegrationState.REVOKED]: 'Revoked',
};

const props = defineProps({
  includeRevoked: {
    type: [Boolean, String],
    default: false,
  },

  striped: {
    type: [Boolean, String],
    default: false,
  },
});

const reqManager = useRequestManager().manager;
const tradeState = useAhTradesState();
const toast = useToast();

const integrationsListing = ref<InstanceType<typeof IntegrationsListing>>();
const plaidIntegrationData = ref<Partial<PaginatedResponse<ClientIntegration>>>();

const listConfig = {
  tableFields: [
    {
      header: 'Institution Name',
      key: 'presentationName',
      sortable: true,
    },

    {
      header: 'Validation Date',
      key: 'createdAt',
      sortable: true,
    },
    {
      header: 'Status',
      key: 'state',
    },
  ],
};

const defaultSortAndPageParams = {
  sort: 'createdAt',
  sortDirection: 'DESC',
} as Partial<PaginatedParams>;

const listFilter = computed(() => {
  const out: Record<string, any> = {
    integration: IntegrationType.PLAID,
  };

  if (props.includeRevoked === false) {
    out.state = IntegrationState.ACTIVE;
  }

  return out;
});

const hasPlaidIntegrations = computed(() => {
  return !!plaidIntegrationData.value?.total;
});

preloadPlaid();

function addPlaidIntegration() {
  reqManager
    .cancelAndNew('getPlaidToken', tradeState.services.integrations.requestPlaidLinkToken())
    .subscribe((tokenData) => {
      const plaidLink = usePlaidLink({
        token: tokenData.linkToken,
        onExit: () => {
          plaidLink.destroy();
        },
        onSuccess: (publicToken) => {
          reqManager
            .new('exchangePlaidToken', tradeState.services.integrations.exchangePlaidPublicToken(publicToken))
            .subscribe(() => {
              toast.success('Plaid integration successfully added');
              integrationsListing.value?.loadData();
              plaidLink.destroy();
            });
        },
      });

      plaidLink.open();
    });
}
</script>

<template>
  <div class="plaid-integration-container">
    <h4>Connect Your Bank Account to Plaid</h4>
    <p class="mb-3">
      Connecting to Plaid will provide ALT21 with additional information about your financial profile, which helps us to
      tailor our services to your specific needs and preferences.
    </p>
    <div class="plaid-integration-listing-container">
      <div class="info-container">
        <ExclamationCircleIcon class="info-icon" />
        <div>
          <div v-if="hasPlaidIntegrations">
            <p class="mb-1">
              You are currently connected to the following institution(s). You can connect more of your bank accounts by
              clicking the button "Connect" below.
            </p>
            <p class="mb-1">Processing of your credit request will take more time if no bank account is connected.</p>
            <p>Connecting multiple institutions will help provide a more complete financial profile.</p>
          </div>
          <p class="mb-3" v-else>
            Processing of your credit request will take more time if no bank account is connected. Connecting multiple
            institutions will help provide a more complete financial profile.
          </p>
        </div>
      </div>
      <div class="plaid-integration-listing" v-show="hasPlaidIntegrations">
        <IntegrationsListing
          ref="integrationsListing"
          :config="listConfig"
          :filter="listFilter"
          :sortAndPageParams="defaultSortAndPageParams"
          :striped="striped"
          @update:tableData="plaidIntegrationData = $event"
        >
          <template #cell(presentationName)="{ item }">
            {{ item.presentationName || 'Bank account' }}
          </template>
          <template #cell(createdAt)="{ item }">
            {{ format(new Date(item.createdAt), 'dd MMM yyyy') }}
          </template>
          <template #cell(state)="{ item }">
            <StatusPill class="status-pill" :color="STATUS_COLORS[item.state]" :label="STATUS_LABELS[item.state]" />
          </template>
        </IntegrationsListing>
      </div>
    </div>
    <div class="text-right">
      <VButton class="connect-btn btn-stroked" @click="addPlaidIntegration" :loading="reqManager.anyPending">
        Connect <ArrowRightIcon class="ml-2" />
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.plaid-integration-container {
  font-family: $font-family-inter;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;

  h4 {
    font-size: $base-font-size;
    font-family: $font-family-inter;
    @include themedTextColor($color-text-black, $color-dark-text);
    margin-bottom: 1em;
  }

  p {
    font-size: $base-font-size;
    font-weight: $font-weight-regular;
    @include themedTextColor($color-text-black, $color-dark-text);
  }

  .plaid-integration-listing-container {
    .info-container {
      display: flex;
      align-items: flex-start;
      padding-left: 5px;
    }

    .info-icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-right: 10px;
      color: #ff9900;
    }

    p {
      font-size: 0.875rem;
      font-weight: $font-weight-regular;
      @include themedTextColor($color-dark-input-border, $color-dark-text);
    }
  }

  .plaid-integration-listing ::v-deep {
    border: 1px solid #e5e7eb;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 1.3em;
    .p-datatable {
      margin-bottom: 0;
      .p-datatable-wrapper {
        .p-datatable-table {
          .title-inner {
            font-size: 0.813rem;
            font-weight: $font-weight-medium;
            display: inline;
            @include themedTextColor($color-dark-input-border, $color-dark-text);
          }

          .sort-icon {
            margin-top: -9px;
          }

          .p-datatable-tbody {
            tr {
              border-top: 1px solid #e5e7eb;

              &:last-child {
                border-bottom: 1px solid #e5e7eb;
              }

              td {
                font-size: 0.813rem;
                font-weight: $font-weight-regular;
                @include themedTextColor($color-text-black, $color-dark-text);
              }
            }
          }
        }
      }
    }

    .table-actions {
      .pagination-wrapper {
        padding-top: 15px;
        &,
        span {
          font-size: 0.813rem;
          font-weight: $font-weight-medium;
        }
      }
    }

    .status-pill {
      font-size: 0.75em;
      border-radius: 6px;
      border: 1px solid;
      padding: 2px 4px;
      font-weight: $font-weight-medium;
      border-color: transparent;

      &.success {
        border-color: #a7f3d0;
      }

      &.danger {
        border-color: #fecdd3;
      }
    }
  }

  .connect-btn {
    font-size: 0.813rem;
    font-weight: $font-weight-medium;
  }
}
</style>
