import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const feeEntryTableFields: TableFieldDefinition[] = [
  { header: 'Date', key: 'createdAt', sortable: true },
  { header: 'Fee Amount', key: 'amount' },
  { header: 'Status', key: 'state' },
];

export const feeAdjustmentTableFields: TableFieldDefinition[] = [
  { header: 'Fee event date', key: 'createdAt', thClass: 'text-left', tdClass: 'text-left', sortable: true },
  { header: 'Fee direction', key: 'feeDirection', sortable: true },
  { header: 'Fee type', key: 'feeType', sortable: true },
  { header: 'Method', key: 'bankingScheme', sortable: true },
  { header: 'Swift fee type', key: 'chargeType', sortable: true },
  { header: 'Amount', key: 'amount', sortable: true },
  { header: 'Status', key: 'state', sortable: true },
];
