<script setup lang="ts">
import { computed, PropType } from 'vue';
import { PaginatedResponse } from 'ah-api-gateways';
import { formatDate, SIMPLE_DATE_WITH_FULL_MONTH_FORMAT } from 'ah-common-lib/src/helpers/time';
import { useManagedListing, defineUseManagedListingProps, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { of } from 'rxjs';

const formatter = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

export interface OptionPaymentSchedule {
  date: string;
  sellAmount: number;
  buyAmount: number;
  sellCurrency: string;
  buyCurrency: string;
}

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: [] }),
  },
  data: {
    type: Object as PropType<PaginatedResponse<OptionPaymentSchedule>>,
    required: true,
  },
  ...defineUseManagedListingProps<OptionPaymentSchedule>(),
});

interface OptionPaymentScheduleEmit extends UseManagedListingEmits<OptionPaymentSchedule> {}

const emit = defineEmits<OptionPaymentScheduleEmit>();

const managedListing = useManagedListing<OptionPaymentSchedule>({
  loadDataRequest() {
    return of(props.data);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});
</script>

<template>
  <DynamicTable
    hover
    animateCols
    emptyCellValue="-"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(date)="{ item }">
      {{ formatDate(item.date, SIMPLE_DATE_WITH_FULL_MONTH_FORMAT) }}
    </template>
    <template #cell(sellAmount)="{ item }">
      {{ `${item.sellCurrency} ${formatCurrencyValue(item.sellAmount, '-', formatter)}` }}
    </template>
    <template #cell(buyAmount)="{ item }">
      {{ `${item.buyCurrency} ${formatCurrencyValue(item.buyAmount, '-', formatter)}` }}
    </template>
  </DynamicTable>
</template>

<style lang="scss" scoped>
.details-cell {
  width: 8rem;
  .arrow-icon {
    margin-left: 0.5rem;
    ::v-deep path {
      fill: $common-color-darkGrey;
    }
  }
}
</style>
