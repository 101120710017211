export enum DocumentExportType {
  // Analytics
  AML_MONITORING = 'AML_MONITORING',
  CAPITAL_COST = 'CAPITAL_COST',
  COLLATERALS = 'COLLATERALS',
  MARGIN_CREDIT_USAGE = 'MARGIN_CREDIT_USAGE',
  POSITION_LIMITS = 'POSITION_LIMITS',
  USAGE = 'USAGE',
  EOD_SPOT_RATES = 'EOD_SPOT_RATES',
  DELTA_RISK = 'DELTA_RISK',
  HOUSE_MONEY_BALANCE = 'HOUSE_MONEY_BALANCE',
  HOUSE_COLLATERAL_BALANCE = 'HOUSE_COLLATERAL_BALANCE',
  HOUSE_COB_BALANCE = 'HOUSE_COB_BALANCE',
  CLIENT_MONEY_BALANCE = 'CLIENT_MONEY_BALANCE',
  CLIENT_COLLATERAL_BALANCE = 'CLIENT_COLLATERAL_BALANCE',
  CLIENT_COB_BALANCE = 'CLIENT_COB_BALANCE',
  PARTNER_MONEY_BALANCE = 'PARTNER_MONEY_BALANCE',
  PARTNER_COLLATERAL_BALANCE = 'PARTNER_COLLATERAL_BALANCE',
  PARTNER_COB_BALANCE = 'PARTNER_COB_BALANCE',
  SETTLED_COMMISSIONS = 'SETTLED_COMMISSIONS',
  PARTNER_COMMISSIONS = 'PARTNER_COMMISSIONS',
  UPCOMING_SETTLEMENTS = 'UPCOMING_SETTLEMENTS',
  PNL_TYPE = 'PNL_TYPE',
  PLN_TRADE = 'PLN_TRADE',
  TRADING = 'TRADING',
  EOM_STATEMENT = 'EOM_STATEMENT',
  CLIENT_OUTSTANDING_FEES = 'CLIENT_OUTSTANDING_FEES',
  PARTNER_OUTSTANDING_FEES = 'PARTNER_OUTSTANDING_FEES',
  PARTNER_COMMISSION_REPORTS = 'PARTNER_COMMISSION_REPORTS',
  VOICE_TRADE_MARGIN_UTILITY_REPORTS = 'VOICE_TRADE_MARGIN_UTILITY_REPORTS',
  VOICE_TRADE_INITIAL_MARGIN_REQUIREMENT_REPORTS = 'VOICE_TRADE_INITIAL_MARGIN_REQUIREMENT_REPORTS',

  HOUSE_WALLET_MONEY_BALANCE = 'HOUSE_WALLET_MONEY_BALANCE',
  HOUSE_WALLET_COLLATERAL_BALANCE = 'HOUSE_WALLET_COLLATERAL_BALANCE',
  HOUSE_WALLET_TOTAL_BALANCE = 'HOUSE_WALLET_TOTAL_BALANCE',
  WALLET_TRANSACTIONS = 'WALLET_TRANSACTIONS',
  MT_103_TRANSACTION = 'MT_103_TRANSACTION',

  CLIENT_WALLET_MONEY_BALANCE = 'CLIENT_WALLET_MONEY_BALANCE',
  CLIENT_WALLET_COLLATERAL_BALANCE = 'CLIENT_WALLET_COLLATERAL_BALANCE',
  CLIENT_WALLET_TOTAL_BALANCE = 'CLIENT_WALLET_TOTAL_BALANCE',

  PARTNER_WALLET_MONEY_BALANCE = 'PARTNER_WALLET_MONEY_BALANCE',
  PARTNER_WALLET_COLLATERAL_BALANCE = 'PARTNER_WALLET_COLLATERAL_BALANCE',
  PARTNER_WALLET_TOTAL_BALANCE = 'PARTNER_WALLET_TOTAL_BALANCE',

  VOICE_TRADE_CLIENT_EOM_REPORTS = 'VOICE_TRADE_CLIENT_EOM_REPORTS',
  WRAPPED_VOICE_TRADE_CLIENT_EOM_REPORTS = 'WRAPPED_VOICE_TRADE_CLIENT_EOM_REPORTS',

  VOICE_TRADE_CLIENT_MARGIN_COLLATERAL_REPORTS = 'VOICE_TRADE_CLIENT_MARGIN_COLLATERAL_REPORTS',
  VOICE_TRADE_CLIENT_WALLET_REPORTS = 'VOICE_TRADE_CLIENT_WALLET_REPORTS',

  VOICE_TRADE_OPEN_POSITIONS_PARTNERS_REPORTS = 'VOICE_TRADE_OPEN_POSITIONS_PARTNERS_REPORTS',
  VOICE_TRADE_OPEN_POSITIONS_CLIENTS_REPORTS = 'VOICE_TRADE_OPEN_POSITIONS_CLIENTS_REPORTS',
  VOICE_TRADE_OPEN_POSITIONS_REPORTS = 'VOICE_TRADE_OPEN_POSITIONS_REPORTS',

  VOICE_TRADE_AUDITS = 'VOICE_TRADE_AUDITS',
  CASH_TRANSACTION_AUDITS = 'CASH_TRANSACTION_AUDITS',
  BANK_ACCOUNTS_AUDITS = 'BANK_ACCOUNTS_AUDITS',
  LIQUIDITY_PROVIDERS_AUDITS = 'LIQUIDITY_PROVIDERS_AUDITS',
  VOICE_TRADE_GENERAL_DAILY_STATEMENT_REPORTS = 'VOICE_TRADE_GENERAL_DAILY_STATEMENT_REPORTS',
  VOICE_TRADE_CLIENT_DAILY_STATEMENT_REPORTS = 'VOICE_TRADE_CLIENT_DAILY_STATEMENT_REPORTS',
  VOICE_TRADE_SETTLEMENT_WATCH_REPORTS = 'VOICE_TRADE_SETTLEMENT_WATCH_REPORTS',
  VOICE_TRADE_CLIENT_LEDGER_BY_CLIENT_REPORTS = 'VOICE_TRADE_CLIENT_LEDGER_BY_CLIENT_REPORTS',
  VOICE_TRADE_CLIENT_LEDGER_BY_TRANSACTION_REPORTS = 'VOICE_TRADE_CLIENT_LEDGER_BY_TRANSACTION_REPORTS',

  VOICE_TRADE_CLIENT_MONEY_BY_BANK_ACCOUNT_REPORT = 'VOICE_TRADE_CLIENT_MONEY_BY_BANK_ACCOUNT_REPORT',
  VOICE_TRADE_CLIENT_MONEY_REPORT = 'VOICE_TRADE_CLIENT_MONEY_REPORT',

  // Banking
  BENEFICIARIES = 'BENEFICIARIES',
  TRANSACTIONS = 'TRANSACTIONS',
  WALLETS = 'WALLETS',
  BALANCES_CERTIFICATE = 'BALANCES_CERTIFICATE',

  // Customer
  CLIENTS = 'CLIENTS',
  CLIENT_INVITES = 'CLIENT_INVITES',
  PARTNERS = 'PARTNERS',
  PARTNER_WITH_COMMISSIONS = 'PARTNER_WITH_COMMISSIONS',
  INDIVIDUALS = 'INDIVIDUALS',
  COMPLIANCE_CASES = 'COMPLIANCE_CASES',
  CLIENTS_ONBOARDING = 'CLIENTS_ONBOARDING',

  // FX
  TRADES = 'TRADES',
  TRADES_WITH_COMMISSIONS = 'TRADES_WITH_COMMISSIONS',
  // Deprecated export type after quote functionality removal
  QUOTES = 'QUOTES',
  FUTURE_COMMISSIONS = 'FUTURE_COMMISSIONS',
  VOICE_TRADES = 'VOICE_TRADES',
  LIQUIDITY_PROVIDERS = 'LIQUIDITY_PROVIDERS',
  CASH_TRANSACTIONS = 'CASH_TRANSACTIONS',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  FX_VANILLA_OPTION_TERMSHEET = 'FX_VANILLA_OPTION_TERMSHEET',
  DELTA_TRADES = 'DELTA_TRADES',
}

export enum DocumentExportStatus {
  EXPORTED = 'EXPORTED',
  EXPORTING = 'EXPORTING',
  FAILED = 'FAILED',
}

export enum DocumentExportFormat {
  XLSX = 'XLSX',
  CSV = 'CSV',
  PDF = 'PDF',
}

export interface DocumentExport {
  id: string;
  userId: string;
  partnerId: string;
  clientId: string;
  individualId: string;
  status: DocumentExportStatus;
  fileFormat: DocumentExportFormat;
  documentTitle?: string;
  type: DocumentExportType;
  title: string;
  createdAt: string;
  updatedAt: string;
}

export interface DocumentExportOptions {
  documentTitle?: string;
  fileFormat: DocumentExportFormat;
  backgroundExport?: boolean;
}
