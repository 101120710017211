<script lang="ts" setup>
import { NotificationSetting, RateAlertSetting, NotificationSettingConfig } from 'ah-api-gateways';
import cloneDeep from 'lodash/cloneDeep';
import SingleNotificationChannelEditForm from '@/app/components/settings/notifications/SingleNotificationChannelEditForm.vue';
import SingleNotificationTargetEditForm from '@/app/components/settings/notifications/SingleNotificationTargetEditForm.vue';
import SingleNotificationTimeEditForm from '@/app/components/settings/notifications/SingleNotificationTimeEditForm.vue';
import SingleNotificationRateAlertSettingEditForm from '@/app/components/settings/notifications/SingleNotificationRateAlertSettingEditForm.vue';
import { PropType, ref, watch } from 'vue';

const props = defineProps({
  config: {
    type: Object as PropType<NotificationSettingConfig>,
    required: true,
  },
  notification: {
    type: Object as PropType<NotificationSetting | RateAlertSetting>,
    required: true,
  },
  showTitles: {
    type: [Boolean, String],
    default: false,
  },
  deletable: {
    type: [Boolean, String],
    default: false,
  },
  hideTargets: {
    type: [String, Boolean],
    default: false,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
});

const emit = defineEmits({
  'update:notification': (_notification: NotificationSetting | RateAlertSetting) => true,
  'delete-notification': (_notification: NotificationSetting | RateAlertSetting) => true,
});

const editedNotification = ref<NotificationSetting | RateAlertSetting>(cloneDeep(props.notification));

function onNotificationUpdate(notification: NotificationSetting) {
  editedNotification.value = notification;
  emit('update:notification', editedNotification.value);
}

function deleteNotification() {
  emit('delete-notification', props.notification);
}

watch(
  () => props.notification,
  () => {
    editedNotification.value = cloneDeep(props.notification);
  },
  { immediate: true }
);
</script>

<template>
  <VRow class="align-items-center single-rate-alert-settings">
    <VCol class="description-col">
      <template v-if="config.type !== 'RATE_ALERT'">
        <span v-if="deletable !== false" @click="deleteNotification"><IconTrash class="remove-icon" /></span>
        <template v-if="!config.timed">
          <h3 class="title" v-if="config.name">{{ config.name }}</h3>
          <p class="sub-title text-secondary" v-if="config.description">{{ config.description }}</p>
        </template>
        <SingleNotificationTimeEditForm
          v-else
          :readonly="readonly"
          :config="config"
          :notification="editedNotification"
          @update:notification="onNotificationUpdate"
        />
      </template>
      <SingleNotificationRateAlertSettingEditForm
        :rateAlert="editedNotification"
        :readonly="readonly"
        @update:rateAlert="onNotificationUpdate"
        @delete-rate-alert="deleteNotification"
        v-else
      />
    </VCol>
    <VCol class="notify-col text-md-left text-sm-center" v-if="hideTargets === false">
      <SingleNotificationTargetEditForm
        :readonly="readonly"
        :showTitles="showTitles"
        :config="config"
        :notification="editedNotification"
        @update:notification="onNotificationUpdate"
      />
    </VCol>
    <VCol class="switches-col">
      <SingleNotificationChannelEditForm
        :channels="config.channels"
        :showTitles="showTitles"
        :notification="editedNotification"
        @update:notification="onNotificationUpdate"
      />
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.remove-icon {
  cursor: pointer;
  @include themedTextColor($color-text-secondary);

  &:hover {
    @include themedTextColor($color-danger);
  }
}
</style>
