import { AmountType, DeletableObject, VersionedObject, HedgingInstruments } from '..';

export enum HouseCashStatus {
  CLOSED = 'CLOSED',
  EXECUTED = 'EXECUTED',
}

export const houseCashStatusToHuman: Record<HouseCashStatus, string> = {
  CLOSED: 'Closed',
  EXECUTED: 'Executed',
};

export interface HouseCash extends Partial<DeletableObject>, VersionedObject {
  id: string;
  referenceNumber: string;
  amountType: AmountType;
  currencyPair: string;
  ccy1: string;
  ccy2: string;
  notionalCurrency: string;
  notionalAmount: number;
  settlementDate: string;
  rate: number;
  state: HouseCashStatus;
  fromCurrency: string;
  fromAmount: number;
  toCurrency: string;
  toAmount: number;
  hedgingProduct: HedgingInstruments;
  pnlCurrency: string;
  pnlAmount: number;
  closedAt: string | null;
}
