<script setup lang="ts">
import {
  AmountType,
  CreateTradeRequest,
  getVanillaOptionPremiumAdvantages,
  getVanillaOptionPremiumDescription,
  getVanillaOptionPremiumDisadvantages,
  PremiumPaymentType,
  VanillaPriceResponse,
} from 'ah-api-gateways';
import { defaultRateFormatter } from 'ah-common-lib/src/helpers/currency';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import OptionTermsheetModal from './modals/OptionTermsheetModal.vue';
import OptionPremiumSchedulePaymentsListing from './lists/OptionPremiumSchedulePaymentsListing.vue';
import { useAhTradesState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { waitForEntityCreation } from 'ah-requests';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { useToast } from 'ah-common-lib/src/toast/Toast';
import { optionPremiumPaymentSettlementShortTableFields } from 'ah-trades/src/models/optionPremiumPaymentSettlementTableFields';
import FormattedNumber from 'ah-common-lib/src/number/FormattedNumber.vue';
import TradesFormattedCurrency from '../currency/TradesFormattedCurrency.vue';

enum OptionsCreatorSteps {
  OPTIONS_CREATOR = 'optionCreator',
  OPTIONS_REVIEW = 'optionsReview',
  OPTIONS_BOOKED = 'optionsBooked',
}

const optionTermsheetModal = ref<InstanceType<typeof OptionTermsheetModal> | null>(null);

const requestManager = useRequestManager();

const toast = useToast();

const props = defineProps<{ solution: VanillaPriceResponse; loading: boolean; error: boolean; pause: boolean }>();

const tradeState = useAhTradesState();

const emit = defineEmits<{
  (e: 'update:stage', value: OptionsCreatorSteps): void;
  (e: 'remove-selected-solution'): void;
  (e: 'update:createdTrade', value: string): void;
  (e: 'reload-prices'): void;
  (e: 'update:createLoading', value: boolean): void;
  (e: 'update:pause', value: boolean): void;
}>();

const config = { tableFields: optionPremiumPaymentSettlementShortTableFields };

const agentLoading = computed(() => requestManager.manager.requestStates.createTrade === 'pending');

watch(agentLoading, () => emit('update:createLoading', agentLoading.value), { immediate: true });

function goBack() {
  emit('update:stage', OptionsCreatorSteps.OPTIONS_CREATOR);
  emit('remove-selected-solution');
}

const isPremiumCcy = computed(() => {
  if (props.solution.ccy1.currency === props.solution.premiumCurrency) {
    return props.solution.ccy1;
  }
  return props.solution.ccy2;
});

const isPrimaryRate = computed(() => {
  if (props.solution.ccy1.isPrimaryRate === true) {
    return props.solution.ccy1;
  }
  return props.solution.ccy2;
});

const premiumSelected = computed(() =>
  isPremiumCcy.value.optionsCurrencyData?.paymentOptions?.find(
    (paymentOption) => paymentOption.premiumPaymentType === props.solution.premiumType
  )
);

const alt21Costs = computed(() => isPremiumCcy.value.optionsCurrencyData.lpPremium ?? '-');

const alt21Markup = computed(() => premiumSelected.value?.markupPremium ?? '-');

const alt21Credit = computed(() => isPremiumCcy.value.optionsCurrencyData.creditPremium ?? '-');

const totalCosts = computed(() => premiumSelected.value?.totalPremium ?? '-');

const premiumDate = computed(() => premiumSelected.value?.premiumPaymentSchedule[0].date);

const sellAmount = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.SELL) {
    return props.solution.ccy1.clientAmount;
  }

  return props.solution.ccy2.clientAmount;
});

const onBehalfOfClient = useOnBehalfOf();

const sellCurrency = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.SELL) {
    return props.solution.ccy1.currency;
  }

  return props.solution.ccy2.currency;
});

const buyAmount = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.BUY) {
    return props.solution.ccy1.clientAmount;
  }

  return props.solution.ccy2.clientAmount;
});

const buyCurrency = computed(() => {
  if (props.solution.ccy1.amountType === AmountType.BUY) {
    return props.solution.ccy1.currency;
  }

  return props.solution.ccy2.currency;
});

function viewTermsheet() {
  optionTermsheetModal.value!.show();
}

function createTrade() {
  let createTradeRequest: CreateTradeRequest = {
    priceId: props.solution.id,
    hedgingProduct: props.solution.hedgingProduct,
    syncPendingFunds: false,
    premiumCurrency: props.solution.premiumCurrency,
    premiumPaymentType: props.solution.premiumType,
  };

  requestManager.manager
    .sameOrCancelAndNew(
      'createTrade',
      tradeState.services.trade.createTrade(createTradeRequest, onBehalfOfClient.value?.id).pipe(
        mergeMap((idEntity) =>
          waitForEntityCreation(() =>
            tradeState.services.trade.getTrade(idEntity.id, { errors: { silent: true } })
          ).pipe(
            mergeMap((response) => of(response.referenceNumber)),
            catchError(() => of(null))
          )
        )
      ),
      createTradeRequest
    )
    .subscribe((response) => {
      if (response !== null) {
        emit('update:createdTrade', response);
        toast.success('Trade created successfully');
        emit('update:stage', OptionsCreatorSteps.OPTIONS_BOOKED);
      }
    });
}
</script>

<template>
  <div class="section">
    <!-- main blocks -->
    <VRow>
      <VCol cols="12" lg="6">
        <TileCard tileTitle="Termsheet">
          <BoxGrid
            ><BoxGridItem
              cols="12"
              :loadingOverlayProps="{
                loading: loading,
                error: error,
                loadingText: 'Refreshing',
                class: 'loading-solution',
                hideOnLoading: true,
                showRetry: true,
              }"
              useLoadingOverlay
              overlayType="simple"
              @retry="emit('reload-prices')"
            >
              <VRow class="mb-3">
                <VCol>
                  <VButton
                    class="refresh-button secondary"
                    :loading="requestManager.manager.anyPending"
                    @click="viewTermsheet"
                  >
                    Preview termsheet
                    <FileIcon class="ml-1" />
                  </VButton>
                </VCol>
              </VRow>
              <VRow class="my-1">
                <VCol cols="6" alignSelf="end">
                  <span class="text-small">Strike Rate</span>
                  <VRow alignV="end">
                    <VCol>
                      <FormattedNumber
                        class="rate-value"
                        :value="isPrimaryRate.optionsCurrencyData.selectedStrikeRate"
                        :formatter="defaultRateFormatter"
                      />
                    </VCol>
                  </VRow>
                </VCol>

                <VCol cols="6" alignSelf="end">
                  <span class="text-small">Total Cost to You</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency
                        class="currency-value"
                        :value="totalCosts"
                        :currency="isPremiumCcy.currency"
                      />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
              <VRow class="my-1">
                <VCol cols="6" alignSelf="end">
                  <span class="text-small">Converting from</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency class="currency-value" :value="sellAmount" :currency="sellCurrency" />
                    </VCol>
                  </VRow>
                </VCol>

                <VCol cols="6" alignSelf="end">
                  <span class="text-small">Converting to</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency class="currency-value" :value="buyAmount" :currency="buyCurrency" />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>

              <VRow>
                <VCol cols="12" class="mb-2 mt-2">
                  <h3 class="title">Product definition</h3>
                </VCol>
                <VCol cols="12" v-if="props.solution.premiumType">
                  <span class="text-small">
                    {{ getVanillaOptionPremiumDescription(props.solution.premiumType) }}
                  </span>
                </VCol>
                <VCol cols="12" class="pt-4" v-if="props.solution.premiumType">
                  <span class="text-small"> Advantages </span>
                  <VRow class="text-small">
                    <ul>
                      <li
                        v-for="(advantage, index) in getVanillaOptionPremiumAdvantages(props.solution.premiumType)"
                        :key="'advantage' + index"
                      >
                        {{ advantage }}
                      </li>
                    </ul>
                  </VRow>
                </VCol>
                <VCol cols="12" class="pt-4" v-if="props.solution.premiumType">
                  <span class="text-small"> Disadvantages </span>
                  <VRow class="text-small">
                    <ul>
                      <li
                        v-for="(disadvantage, index) in getVanillaOptionPremiumDisadvantages(
                          props.solution.premiumType
                        )"
                        :key="'disadvantage' + index"
                      >
                        {{ disadvantage }}
                      </li>
                    </ul>
                  </VRow>
                </VCol>
                <VCol cols="12" class="pt-4">
                  <VRow class="p-0">
                    <VCol align-self="end" class="p-0">
                      <div>
                        <VCol cols="12"><span class="text-small text-secondary">Expiry date</span></VCol>
                        <VCol>
                          <span
                            ><strong>{{ formatDate(solution.expiryDate, 'dd MMM yyyy') }} </strong></span
                          ></VCol
                        >
                      </div>
                    </VCol>
                    <VCol align-self="end" class="p-0">
                      <div>
                        <VCol cols="12"><span class="text-small text-secondary">Settlement date</span></VCol>
                        <VCol>
                          <span
                            ><strong>{{ formatDate(solution.settlementDate, 'dd MMM yyyy') }} </strong></span
                          >
                        </VCol>
                      </div>
                    </VCol>
                    <VCol align-self="end" class="p-0" v-if="props.solution.premiumType === PremiumPaymentType.UPFRONT">
                      <div>
                        <VCol cols="12"><span class="text-small text-secondary">Premium Settlement date</span></VCol>
                        <VCol>
                          <span
                            ><strong>{{ formatDate(premiumDate, 'dd MMM yyyy') }} </strong></span
                          ></VCol
                        >
                      </div>
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
            </BoxGridItem></BoxGrid
          >
        </TileCard>
      </VCol>
      <VCol cols="12" lg="6">
        <TileCard tileTitle="Cost Transparency">
          <BoxGrid
            ><BoxGridItem
              cols="12"
              :loadingOverlayProps="{
                loading: loading,
                error: error,
                loadingText: 'Refreshing',
                class: 'loading-costs',
                hideOnLoading: true,
                showRetry: true,
              }"
              useLoadingOverlay
              overlayType="simple"
              @retry="emit('reload-prices')"
            >
              <VRow class="my-1">
                <VCol cols="6" alignSelf="end">
                  <span class="text-small">+ ALT21 Costs</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency
                        class="currency-value"
                        :value="alt21Costs"
                        :currency="isPremiumCcy.currency"
                      />
                    </VCol>
                  </VRow>
                </VCol>

                <VCol cols="6" alignSelf="end">
                  <span class="text-small">+ ALT21 Credit Charge</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency
                        class="currency-value"
                        :value="alt21Credit"
                        :currency="isPremiumCcy.currency"
                      />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>

              <VRow class="my-1">
                <VCol cols="6" alignSelf="end">
                  <span class="text-small">+ ALT21 Mark-up</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency
                        class="currency-value"
                        :value="alt21Markup"
                        :currency="isPremiumCcy.currency"
                      />
                    </VCol>
                  </VRow>
                </VCol>

                <VCol cols="6" alignSelf="end">
                  <span class="text-small">Total Cost to You</span>
                  <VRow alignV="end">
                    <VCol>
                      <TradesFormattedCurrency
                        class="currency-value"
                        :value="totalCosts"
                        :currency="isPremiumCcy.currency"
                      />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow> </BoxGridItem></BoxGrid
        ></TileCard>
        <TileCard
          tileTitle="Premium Settlement Schedule"
          v-if="props.solution.premiumType === PremiumPaymentType.MONTHLY"
        >
          <BoxGrid>
            <BoxGridItem
              cols="12"
              :loadingOverlayProps="{
                loading: loading,
                error: error,
                loadingText: 'Refreshing',
                class: 'loading-costs',
                hideOnLoading: true,
                showRetry: true,
              }"
              useLoadingOverlay
              overlayType="simple"
              @retry="emit('reload-prices')"
            >
              <OptionPremiumSchedulePaymentsListing :config="config" :data="premiumSelected?.premiumPaymentSchedule" />
            </BoxGridItem>
          </BoxGrid>
        </TileCard>
      </VCol>
    </VRow>

    <BoxGrid class="mt-3">
      <BoxGridItem lg="9" md="12" sm="12">
        <VCol cols="12" class="mb-2">
          <h4>Disclaimer</h4>
        </VCol>
        <VCol cols="12">
          <p class="text-small">
            Please read the term sheet carefully and ensure that all the information pertaining to your option is
            accurate. Options carry a high level of risk and may not be suitable for you. You should understand how this
            instrument works and whether it aligns with your risk tolerance.
          </p>
          <p class="text-small">
            By reviewing this information, you acknowledge the risks involved, including the potential for losses as
            well as gains and you accept full responsibility for the results of the trade.
          </p>
          <p class="text-small">
            If necessary, seek guidance from a qualified financial professional, as the content provided here should not
            be regarded as financial advice or a recommendation to trade.
          </p>
        </VCol>
      </BoxGridItem>
      <BoxGridItem lg="3" md="12" sm="12">
        <div class="d-flex justify-content-end">
          <VButton class="btn btn-secondary mr-3" :loading="requestManager.manager.anyPending" @click="goBack()">
            <IconArrowRight class="mr-3 back-arrow" /> Back
          </VButton>

          <VButton
            class="btn btn-primary"
            :loading="loading || requestManager.manager.anyPending"
            :disabled="error"
            @click="createTrade()"
          >
            Book Trade <IconArrowRight class="ml-3arrow-icon"
          /></VButton>
        </div>
      </BoxGridItem>
    </BoxGrid>
    <OptionTermsheetModal
      ref="optionTermsheetModal"
      @update:pause="emit('update:pause', $event)"
      :solution="solution"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .box-grid-item {
  padding: 0 0.5rem;
}

.h3-font {
  font-size: $h3-font-size;
}
.box {
  &.border-left {
    @include themedPropColor('border-left', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');
  }

  &.border-top {
    @include themedPropColor('border-top', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');
  }
}

.back-arrow {
  transform: rotate(180deg);
}

.rate-value {
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
}

.currency-value {
  font-size: $h2-font-size;

  ::v-deep {
    .number-segment {
      font-weight: $font-weight-bold;
    }

    .number-segment-fraction,
    .prefix {
      font-size: $h4-font-size;
    }
  }
}

::v-deep {
  .loading-solution {
    min-height: 40rem;
  }

  .loading-costs {
    min-height: 10rem;
  }

  .bg-box {
    @include themedBackgroundColor('none', 'none');
  }

  .bg-main {
    @include themedBackgroundColor('none', 'none');
  }

  .title {
    @include themedTextColor($color-primary, $color-primary);
  }

  .table-wrapper table.b-table thead tr > th {
    @include themedTextColor($color-primary, $color-primary);
  }
}
</style>
