import { ExportListType, PaginatedResponse, PaginatedQuery, VersionedObject, DocumentExport } from '../models';
import { Funding, FundingCharge } from '../models/feesAndCharges/fundingCharge';
import { HttpBasedService, HttpOptions, HttpService, RequestManager } from 'ah-requests';
import { standardiseQuerySort } from '../models/rest';
import {
  ListOwnerOutstandingFeesQueryParams,
  CustomFeeReason,
  FeesFilters,
  OutstandingFee,
  SingleClientOutstandingFee,
  FeeType,
} from '../models/feesAndCharges/fees';
import { cloneDeep } from 'lodash';

export class FeesAndChargesService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private reportsUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'feesAndChargesService' } },
      },
    });
  }

  private requestManager = new RequestManager();

  public get requestStates() {
    return this.requestManager.requestStates;
  }

  public listClientFees(
    query: PaginatedQuery<FeesFilters>,
    oboClientId?: string,
    options?: Partial<HttpOptions<void>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<FundingCharge[]>(`${this.baseUrl}client-fees`, {
      axiosConfig: {
        params: query,
        headers,
      },
      ...options,
    });
  }

  public listPartnerFees(
    query: PaginatedQuery<FeesFilters>,
    oboClientId?: string,
    options?: Partial<HttpOptions<void>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<FundingCharge[]>(`${this.baseUrl}partner-fees`, {
      axiosConfig: {
        params: query,
        headers,
      },
      ...options,
    });
  }

  public listAHFees(query: PaginatedQuery<FeesFilters>, oboClientId?: string, options?: Partial<HttpOptions<void>>) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<FundingCharge[]>(`${this.baseUrl}ah-fees`, {
      axiosConfig: {
        params: query,
        headers,
      },
      ...options,
    });
  }

  public listProviderFees(
    query: PaginatedQuery<FeesFilters>,
    oboClientId?: string,
    options?: Partial<HttpOptions<void>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<FundingCharge[]>(`${this.baseUrl}provider-fees`, {
      axiosConfig: {
        params: query,
        headers,
      },
      ...options,
    });
  }

  public updateFees(feeId: string, fee: number) {
    const feeBody = {
      fee: fee,
    };
    return this.put<FundingCharge[]>(`${this.baseUrl}fees/${feeId}`, feeBody);
  }

  public addFunding(funding: Funding, options?: Partial<HttpOptions<void>>) {
    const params = funding;

    return this.post<VersionedObject>(`${this.baseUrl}funding-details/create`, undefined, {
      axiosConfig: { params },
      ...options,
    });
  }

  public createFee(
    payload: {
      fromWalletId: string;
      toWalletId: string;
      amount: number;
      customFeeReason: string;
      settleToWalletIdFees: boolean;
      description: string;
    },
    options?: HttpOptions<VersionedObject>
  ) {
    return this.post<VersionedObject>(`${this.baseUrl}wallets/fees`, payload, {
      options,
    });
  }

  public getCustomFeeReasons() {
    return this.get<CustomFeeReason[]>(`${this.baseUrl}fees/custom-fees/reasons`);
  }

  public getFeeTypes() {
    return this.get<FeeType[]>(`${this.baseUrl}fees/types`);
  }

  public listOutstandingFees(query: PaginatedQuery, options?: Partial<HttpOptions<void>>) {
    const params = cloneDeep(standardiseQuerySort(query));
    const userType = (params.type ?? 'CLIENTS').toLowerCase();
    delete params.type;

    return this.get<PaginatedResponse<OutstandingFee>>(`${this.reportsUrl}reports/fees/outstanding-fees/${userType}`, {
      axiosConfig: {
        params,
      },
      ...options,
    });
  }

  public listOwnerOutstandingFees(query: ListOwnerOutstandingFeesQueryParams, options?: Partial<HttpOptions<void>>) {
    return this.get<PaginatedResponse<SingleClientOutstandingFee>>(`${this.baseUrl}fees/transactions`, {
      axiosConfig: {
        params: standardiseQuerySort(query),
      },
      ...options,
    });
  }

  public downloadOutsandingFeesList(
    query: PaginatedQuery,
    fileFormat: ExportListType,
    documentTitle = 'Client Outstanding Fees'
  ) {
    const params = cloneDeep(standardiseQuerySort(query));
    const userType = (params.type ?? 'CLIENTS').toLowerCase();
    delete params.type;

    return this.get<DocumentExport>(`${this.reportsUrl}reports/fees/outstanding-fees/${userType}/export`, {
      axiosConfig: {
        params: {
          ...params,
          fileFormat,
          documentTitle,
        },
      },
    });
  }
}
