import { render, staticRenderFns } from "./SubscriptionsSidebarView.vue?vue&type=template&id=6b1986c5&scoped=true"
import script from "./SubscriptionsSidebarView.vue?vue&type=script&setup=true&lang=ts"
export * from "./SubscriptionsSidebarView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SubscriptionsSidebarView.vue?vue&type=style&index=0&id=6b1986c5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1986c5",
  null
  
)

export default component.exports