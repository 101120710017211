export enum UserTwoFactorAuthentication {
  SMS_CHANNEL1 = 'SMS_CHANNEL1',
  SMS_CHANNEL2 = 'SMS_CHANNEL2',
  EMAIL = 'EMAIL',
  AUTHENTICATOR = 'AUTHENTICATOR',
}

export const userTwoFactorAuthentication: Record<UserTwoFactorAuthentication, string> = {
  [UserTwoFactorAuthentication.SMS_CHANNEL1]: 'SMS Channel 1',
  [UserTwoFactorAuthentication.SMS_CHANNEL2]: 'SMS Channel 2',
  [UserTwoFactorAuthentication.EMAIL]: 'Email',
  [UserTwoFactorAuthentication.AUTHENTICATOR]: 'Authenticator',
};

export const twoFactorAuthentication = [
  UserTwoFactorAuthentication.SMS_CHANNEL1,
  UserTwoFactorAuthentication.SMS_CHANNEL2,
  UserTwoFactorAuthentication.EMAIL,
  UserTwoFactorAuthentication.AUTHENTICATOR,
];
