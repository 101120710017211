<script setup lang="ts">
import {
  TransactionReason as TransactionReasonEnum,
  TransactionExternal,
  transactionReasonLabels,
  HistoricalTransaction,
} from 'ah-api-gateways';
import { computed } from 'vue';

const props = defineProps<{
  transaction: HistoricalTransaction;
}>();

const transactionReasonText = computed(() => {
  if (props.transaction.reason === TransactionReasonEnum.DEPOSIT_FROM_UNKNOWN) {
    let out = 'Deposit';
    const extTransaction = props.transaction as TransactionExternal;
    if (extTransaction.otherName) {
      out += ` from ${extTransaction.otherName}`;
    }
    return out;
  }

  return transactionReasonLabels[props.transaction.reason];
});
</script>

<template>
  <div v-b-tooltip="transactionReasonText">{{ transactionReasonText }}</div>
</template>
