import { BankAccount } from './bankAccount';
import type { UpdatableObject, VersionedObject, Address } from '../../models';
import { BeneficiaryType, BeneficiaryCategory, BeneficiaryStatus } from './beneficiaryEnums';

export interface Beneficiary extends BankAccount, UpdatableObject, VersionedObject {
  id: string;
  type: BeneficiaryType;
  category: BeneficiaryCategory;
  email: string;
  entityType: string;
  status: BeneficiaryStatus;
  partnerId: string;
  clientId: string;
  reference: string;
  address: Address;
}

export function beneficiaryName(beneficiary: Beneficiary) {
  return beneficiary.type === BeneficiaryType.COMPANY
    ? beneficiary.name
    : `${beneficiary.firstName} ${beneficiary.lastName}`;
}
