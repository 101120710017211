import { TimeFrames } from '../../models/common/timeframes';
import { AmountType } from '../../models/amountType';
import { HedgingInstruments } from './hedgingInstrument';
import { TimeFrameDate } from 'ah-trades/src/models/date';
import { OptionType } from '../currency';
import { PremiumPaymentSchedule, PremiumPaymentType } from '../trade/optionPremium';

export enum FxOperation {
  FX_SPOT = 'FX_SPOT',
  FX_FORWARD = 'FX_FORWARD',
  VANILLA_OPTIONS = 'VANILLA_OPTIONS',
  DRAWDOWN = 'DRAWDOWN',
}
export interface AmendQuotePriceRequest {
  hedgingProduct: string;
  originalPriceId: string;
  clientRate: number;
  clientRateMarkup?: number;
}

export interface CreateVanillaOptionsPriceRequest {
  clientId?: string;
  sellCurrency: string;
  buyCurrency: string;
  amount: number;
  amountType: AmountType;
  settlementDate?: string;
  strikeRate?: number;
}

export interface SpotQuotePriceRequest {
  clientId: string;
  sellCurrency: string;
  buyCurrency: string;
  amount: number;
  amountType: AmountType;
  clientRate?: number;
  clientRateMarkup?: number;
  targetTimeFrame: TimeFrames;
  targetDate?: string;
}

export interface ForwardQuotePriceRequest extends SpotQuotePriceRequest {
  targetDate: string;
  targetTimeFrame: TimeFrames;
}

export interface WindowForwardQuotePriceRequest extends ForwardQuotePriceRequest {
  windowStartDate: string;
}

export interface PaymentOptionsData {
  premiumPaymentType: PremiumPaymentType;
  markupPremium: number;
  totalPremium: number;
  totalGbpPremium: number;
  premiumPercentage: number;
  premiumPaymentSchedule: PremiumPaymentSchedule[];
}

export interface OptionsCurrencyData {
  creditPremium: number;
  defaultStrikeRates: number[];
  forwardRate: number;
  lpPremium: number;
  minStrikeInStrikeRange: number;
  maxStrikeInStrikeRange: number;
  optionType: OptionType;
  paymentOptions: PaymentOptionsData[];
  selectedStrikeRate: number;
  spotRate: number;
  startStrike: number;
}

export interface PricingCurrency {
  currency: string;
  currencyDecimalPlaces: number;
  amountType: AmountType;
  clientAmount: number;
  clientRate: number;
  productLpRate?: number;
  isFixedSide: boolean;
  isPrimaryRate: boolean;
  lpClearingFxRate: number;
  // partner only info
  profitData?: PartnerProfitPriceData;
  rate?: number;
  clientCost: number;
  clientCostBasisPoints: number;
}
export interface VanillaOptionsPricingCurrency extends PricingCurrency {
  optionsCurrencyData: OptionsCurrencyData;
}

export interface PartnerProfitPriceData {
  partnerProfit: PartnerProfitValues;
  ahProfit?: PartnerProfitValues;
  totalProfit?: number;
  gbpPartnerProfit: PartnerProfitValues;
  internalMarkupAmount?: number;
  lpInternalMarkupInvoiceAmount?: number;
  partnerInitialMarginCreditChargeAmount?: number;
  ahInitialMarginCreditChargeAmount?: number;
  totalInitialMarginCreditChargeAmount?: number;
  totalSwapPnl?: number;
  ahSwapPnl?: PartnerProfitValues;
  partnerSwapPnl: PartnerProfitValues;
}

export interface PartnerProfitValues {
  noInitialMarginValue: number;
  collateralValue: number;
  creditValue: number;
}

export interface VanillaPriceResponse {
  id: string;
  clientId: string;
  currencyPair: string;
  ccy1: VanillaOptionsPricingCurrency;
  ccy2: VanillaOptionsPricingCurrency;
  hedgingProduct: HedgingInstruments;
  totalMarkupBasisPoints?: number;
  partnerMarkupBasisPoints?: PartnerProfitValues;
  homeCurrency: string;
  homeCurrencyInitialMarginAmount: number;
  gbpInitialMarginAmount: number;
  initialMarginPercentage: number;
  settlementStartDate: string;
  settlementDate: string;
  priceRequestedTimestamp: string;
  priceExpirationTimestamp: string;
  partnerCommissionPercentage: PartnerProfitValues;
  expiryDate: string;
  expiryTime: string;
  premiumDate: string;

  // FE use only
  premiumCurrency: string;
  premiumType?: PremiumPaymentType;
}

export interface QuotePriceResponse {
  id: string;
  clientId: string;
  ccy1: PricingCurrency;
  ccy2: PricingCurrency;
  hedgingProduct: HedgingInstruments;
  totalMarkupBasisPoints?: number;
  partnerMarkupBasisPoints?: PartnerProfitValues;
  homeCurrency: string;
  homeCurrencyInitialMarginAmount: number;
  gbpInitialMarginAmount: number;
  initialMarginPercentage: number;
  settlementDate: string;
  settlementStartDate: string;
  priceRequestedTimestamp: string;
  priceExpirationTimestamp: string;
  partnerCommissionPercentage: PartnerProfitValues;
}

export interface DrawdownQuotePriceResponse extends QuotePriceResponse {
  parentTradeID?: string;
  drawdownPnlCurrency?: string;
  clientDrawdownPnl?: number;
  partnerDrawdownPnl?: PartnerProfitValues;
  drawdownRatio?: number;
}

export interface DrawdownDetails {
  amountType: AmountType;
  amount: number;
  buyCurrency: string;
  sellCurrency: string;
  timeFrame?: TimeFrameDate;
}

export function isPriceExpired(price: QuotePriceResponse, offsetMillis = 0) {
  return (
    !price.priceExpirationTimestamp ||
    new Date(price.priceExpirationTimestamp).valueOf() + offsetMillis < new Date().valueOf()
  );
}
