<script setup lang="ts">
import { findComponentBasePath } from 'ah-common-lib/src/helpers/route';
import config from '@/config';
import { useAuthStore } from '@/app/store/authStore';
import { computed, getCurrentInstance } from 'vue';

const authStore = useAuthStore();
const isAdmin = computed(() => authStore.isAdminUser);
const showDevTools = computed(() => config.showDevTools);

const props = defineProps({ path: { type: String, required: false, default: '' } });

// basePath is set on component setup and not expected to react to prop changes
// eslint-disable-next-line vue/no-setup-props-destructure
let basePath = props.path;

const instance = getCurrentInstance();
if (basePath === '' && instance != null) {
  basePath = findComponentBasePath(instance.proxy);
}
</script>

<template>
  <div class="main" id="partner-settings-sidebar-view">
    <h1 class="px-4">Settings</h1>
    <template v-if="isAdmin">
      <h5 class="menu-header">Info</h5>
      <ul>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/partner-information`"> Information </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/bank-accounts`"> Bank Accounts </RouterLink>
      </ul>
      <h5 class="menu-header">Manage</h5>
      <ul>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/members`"> Members </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/groups`"> Groups </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/notifications`"> Notifications </RouterLink>
      </ul>
    </template>
    <h5 class="menu-header">General</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/personal-settings`"> Personal Info </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/account`"> Account Settings </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/dev-settings`" v-if="showDevTools">
        [Dev Settings]
      </RouterLink>
    </ul>
    <h5 class="menu-header">Fee Management</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/payment-fees`"> Payment Fees </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/funding-fees`"> Funding Fees </RouterLink>
    </ul>
  </div>
</template>
