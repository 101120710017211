<script setup lang="ts">
import { ref, computed, watch, PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import OtpForm from './OtpForm.vue';
import EmailVerificationWarning from './EmailVerificationWarning.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useToast } from 'ah-common-lib/src/toast';
import { makeFormModel, resetForm, submitForm, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { passwordField, textField } from 'ah-common-lib/src/form/models';
import { FormValidation, FieldModel } from 'ah-common-lib/src/form/interfaces';
import { AuthenticationServiceErrorCodes, UserRole, UserStatus } from 'ah-api-gateways';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';

export type LoginStage = 'login' | 'otp' | 'autologin' | 'verifyEmail' | 'success';

const MAX_REGISTRATION_RETRIES = 5;

function makeLoginFM() {
  return makeFormModel({
    name: 'loginForm',
    title: 'Login',
    fieldType: 'form',
    fields: [
      textField('username', 'Username', { placeholder: 'Username' }),
      passwordField('password', 'Password', false, { placeholder: 'Password', allowShowPassword: true }),
    ],
    state: {
      showErrorsBeforeSubmit: false,
    },
  });
}

const props = defineProps({
  autoLogin: {
    type: Object as PropType<{ username: string; password: string } | null>,
    default: null,
  },
});

const emit = defineEmits({
  'stage-change': (_stage: LoginStage) => true,
  'login-success': () => true,
  'login-failure': (_error: any) => true,
});

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const services = getServices();
const toast = useToast();
const requestManager = useRequestManager().manager;
const loginFV = ref<FormValidation | null>(null);
const otpPhone = ref('');
const otpExpire = ref<ExpiryTime>();
const stage = ref<LoginStage>('login');
const otpForm = ref<InstanceType<typeof OtpForm>>();
const loginForm = ref(makeLoginFM());

const verifiedUsername = computed(() => route.query.verified);

const passwordFieldModel = computed(() => getChildModel(loginForm.value, 'password') as FieldModel | undefined);

function setStage(newStage: LoginStage) {
  stage.value = newStage;
  emit('stage-change', stage.value);
}

function refreshOtpCode() {
  if (stage.value === 'otp' && otpForm.value) {
    otpForm.value.refreshOtp();
  }
}

function goToLogin() {
  setStage('login');
  otpExpire.value = undefined;
}

function backToLogin() {
  goToLogin();
  loginForm.value = makeLoginFM();
  if (loginFV.value) {
    resetForm(loginFV.value);
  }
}

function onFormEvent() {
  passwordFieldModel.value && setState(passwordFieldModel.value, 'errors', []);
}

function onLoginSuccess() {
  setStage('success');
  emit('login-success');
}

function onOtpError(error: any) {
  setTimeout(function () {
    if (error === 'userNotRegistered') {
      router.push('/register');
    } else if (error === 'userWithoutIndividual') {
      toast.error('Something unexpected happened while trying to login');
      goToLogin();
    }
    if (error === 'sessionNotFound') {
      toast.error('It seems like we are still creating your user. Try again in a couple of minutes.');
      goToLogin();
    }
  });
}

async function login(username: string, password: string, retries = 0) {
  requestManager.newPromise(
    'login',
    authStore
      .login({
        username: username,
        password: password,
        silenceErrors: true,
      })
      .then((data) => {
        if (data?.role === UserRole.CLIENT_REGISTRATION && data?.status === UserStatus.TEMPORARY) {
          onLoginSuccess();
        } else if ((data?.role === UserRole.CLIENT_REGISTRATION && !data.individual) || data?.isOtpRequired) {
          return services.auth
            .refreshOtp()
            .toPromise()
            .then((res) => {
              otpPhone.value = data.phoneNumber.substring(data.phoneNumber.length - 4);
              setStage('otp');
              if (res?.expiresIn) {
                otpExpire.value = res;
              }
            });
        } else {
          onLoginSuccess();
        }
      })
      .catch((error) => {
        if (error === 'ahAdminNotAllowed') {
          toast.error(
            'Admin users cannot login to the platform directly. Please impersonate a user of this platform through the admin panel.'
          );
        } else if (error === 'userNotRegistered') {
          router.push('/register');
        } else if (error.response?.data?.code === AuthenticationServiceErrorCodes.BAD_CREDENTIALS) {
          passwordFieldModel.value &&
            setState(passwordFieldModel.value, 'errors', [
              {
                name: 'wrongLogin',
                error: 'Wrong username or password',
              },
            ]);
        } else if (error.response?.data?.code === AuthenticationServiceErrorCodes.UNVERIFIED_USER) {
          if (verifiedUsername.value && retries < MAX_REGISTRATION_RETRIES) {
            login(username, password, retries + 1);
            return;
          }
          setStage('verifyEmail');
        } else {
          toast.error('Something unexpected happened while trying to login');
          goToLogin();
        }
        emit('login-failure', error);
      })
  );
}

async function submitLoginForm() {
  if (loginFV.value) {
    submitForm(loginFV.value);
    if (loginFV.value.$invalid) {
      return;
    }
  } else {
    return;
  }

  passwordFieldModel.value && setState(passwordFieldModel.value, 'errors', []);

  return login(loginForm.value.username, loginForm.value.password);
}

watch(
  verifiedUsername,
  () => {
    if (verifiedUsername.value && !props.autoLogin) {
      loginForm.value.username = verifiedUsername.value;
    }
  },
  { immediate: true }
);

if (props.autoLogin) {
  setStage('autologin');
  login(props.autoLogin.username, props.autoLogin.password);
} else {
  goToLogin();
}

defineExpose({
  refreshOtpCode,
});
</script>

<template>
  <div class="login">
    <div v-if="stage === 'login'">
      <p class="username-warning">If you have not set up a username, by default it will be your email address.</p>
      <ValidatedForm :fm="loginForm" :validation.sync="loginFV" @submit="submitLoginForm()" @form-event="onFormEvent" />
      <div class="form-actions text-center">
        <VButton :loading="requestManager.anyPending" label="Login" @click="submitLoginForm()" class="btn-primary" />
      </div>
    </div>
    <div v-else-if="stage === 'otp'">
      <OtpForm
        ref="otpForm"
        :otp-phone="otpPhone"
        :expiry="otpExpire"
        @cancel="backToLogin"
        @error="onOtpError"
        @success="onLoginSuccess"
      />
    </div>
    <div v-else-if="stage === 'verifyEmail'" class="email-verification">
      <EmailVerificationWarning :email="loginForm.username" />
      <div class="text-center mt-3">
        <VButton label="Back to login" @click="setStage('login')" class="btn-stroked mx-3" />
      </div>
    </div>
    <div v-else-if="stage === 'autologin' || stage === 'success'">
      <LoadingOverlay :loading="requestManager.anyPending" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.username-warning {
  @include themedTextColor($x-ui-light-tag-orange-text, $x-ui-dark-tag-orange-text);
  @include themedBackgroundColor($x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
  font-size: 0.625em;
  padding: 4px 8px;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: $font-weight-regular;
  font-family: $font-family-inter;
}
</style>
