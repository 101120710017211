import { HttpBasedService, HttpOptions, HttpRequestOptions, HttpService } from 'ah-requests';
import { Observable } from 'rxjs';

import {
  BankAccount,
  Beneficiary,
  ExportListType,
  ListQuery,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  BeneficiaryStatus,
  VersionedObject,
  DocumentExport,
} from '../models';

export class BeneficiaryService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'beneficiaryService' } },
      },
    });
  }

  public getBeneficiary(id: string, options?: Partial<HttpOptions<Beneficiary>>) {
    return this.get<Beneficiary>(`${this.baseUrl}beneficiaries/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public createBeneficiary(
    data: Partial<Beneficiary>,
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
      data.clientId = oboClientId;
    }

    return this.post<VersionedObject>(`${this.baseUrl}beneficiaries`, data, {
      axiosConfig: { headers },
      options,
    });
  }

  public deleteBeneficiary(id: string, options?: Partial<HttpRequestOptions<unknown>>) {
    return this.delete(`${this.baseUrl}beneficiaries/${id}`, options);
  }

  public listBeneficiaries(query: PaginatedQuery): Observable<PaginatedResponse<Beneficiary>> {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<Beneficiary>>(`${this.baseUrl}beneficiaries`, {
      axiosConfig: {
        params: query,
      },
    });
  }

  public downloadBeneficiaryList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Beneficiaries List') {
    query = { ...query };
    if (query.sort === 'name') {
      const sortDirection = query.sortDirection ? query.sortDirection.toLowerCase() : 'desc';
      query.sort = [`firstName,${sortDirection}`, `lastName,${sortDirection}`];
    } else {
      query = standardiseQuerySort(query);
    }

    return this.get<DocumentExport>(`${this.baseUrl}beneficiaries/export`, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public updateBeneficiary(
    id: string,
    data: Partial<Beneficiary>,
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
      data.clientId = oboClientId;
    }

    return this.put<VersionedObject>(`${this.baseUrl}beneficiaries/${id}`, data, {
      axiosConfig: { headers },
      options: {
        cache: {
          type: 'delete',
          cacheKey: 'beneficiary',
          itemKey: id,
        },
        ...options,
      },
    });
  }

  public createPersonalBeneficiary(data: Partial<BankAccount>) {
    return this.post<VersionedObject>(`${this.baseUrl}beneficiaries/personal`, data);
  }

  public setBeneficiaryApproval(id: string, status: BeneficiaryStatus, oboClientId?: string) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.put<Beneficiary>(
      `${this.baseUrl}beneficiaries/${id}/approval`,
      { status: status },
      {
        axiosConfig: { headers },
        options: {
          cache: {
            type: 'override',
            cacheKey: 'beneficiary',
            itemKey: id,
          },
        },
      }
    );
  }
}
