import { EmirClassification } from '.';

export enum TradeFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  FORTNIGHTLY = 'FORTNIGHTLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
  ONE_OFF_PAYMENT = 'ONE_OFF_PAYMENT',
}

export enum Occupation {
  ACCOUNTANT = 'ACCOUNTANT',
  ANALYST = 'ANALYST',
  ARTIST = 'ARTIST',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  CONSULTANT = 'CONSULTANT',
  DOCTOR = 'DOCTOR',
  ENGINEER = 'ENGINEER',
  ENTREPRENEUR = 'ENTREPRENEUR',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  LAWYER = 'LAWYER',
  MANAGER = 'MANAGER',
  MARKETING = 'MARKETING',
  SALES = 'SALES',
  SOFTWARE_DEVELOPER = 'SOFTWARE_DEVELOPER',
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  RETIRED = 'RETIRED',
  UNEMPLOYED = 'UNEMPLOYED',
  OTHER = 'OTHER',
}

export interface BaseQuestionnaire {
  fxSpotInterest: boolean;
  fxForwardInterest: boolean;
  minExperienceYears: number;
  maxExperienceYears: number;
  reason: string;
  buyCurrencies: string[];
  sellCurrencies: string[];
  tradeFrequency: TradeFrequency;
  minExpectedVolume: number;
  maxExpectedVolume: number;
  beneficiaryCountries: string[];
  emirClassification: EmirClassification;
}

export interface IndividualQuestionnaire extends BaseQuestionnaire {
  occupation: string;
  reportingCurrency: string;
  emirClassification: EmirClassification;
}

export interface CompanyQuestionnaire extends BaseQuestionnaire {
  sourceOfFunds: string;
  emirClassification: EmirClassification;
}

export type Questionnaire = IndividualQuestionnaire | CompanyQuestionnaire;
