import {
  PositionLimitsData,
  PositionLimitsProfile,
  PositionLimitAudit,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  PriceLimitsRequest,
  PriceLimitsResponse,
  PositionLimitsRequest,
  VersionedObject,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';

export class LimitsService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'limitsService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public verifyLimits(
    clientId: string,
    priceRequest: Partial<PriceLimitsRequest>,
    options?: HttpOptions<PriceLimitsResponse>
  ) {
    return this.post<PriceLimitsResponse>(`${this.baseUrl}limits/${clientId}/verify`, priceRequest, { options });
  }

  public listLimitProfiles(query: PaginatedQuery, options?: HttpOptions<PaginatedResponse<PositionLimitsProfile>>) {
    return this.get<PaginatedResponse<PositionLimitsProfile>>(`${this.baseUrl}limits/profiles`, {
      axiosConfig: {
        params: standardiseQuerySort(query),
      },
      options,
    });
  }

  public getClientLimitProfile(clientId: string, options?: HttpOptions<PositionLimitsProfile>) {
    return this.get<PositionLimitsProfile>(`${this.baseUrl}limits/profiles/${clientId}`, {
      options,
    });
  }

  public createLimitProfile(profileData: Partial<PositionLimitsData>) {
    return this.post<VersionedObject>(`${this.baseUrl}limits/profiles`, profileData);
  }

  public updateLimitsProfile(profileData: Partial<PositionLimitsRequest>) {
    return this.put<VersionedObject>(`${this.baseUrl}limits/profiles/${profileData.clientId}`, profileData);
  }

  public notifyUsLimit(options?: HttpOptions<void>) {
    return this.post(`${this.baseUrl}limits/request`, { options });
  }

  public listPositionUsageAuditRecords(
    query: PaginatedQuery,
    options?: HttpOptions<PaginatedResponse<PositionLimitAudit>>
  ) {
    return this.get<PaginatedResponse<PositionLimitAudit>>(`${this.baseUrl}limits/audit-records`, {
      axiosConfig: {
        params: standardiseQuerySort(query),
      },
      options,
    });
  }
}
