<script lang="ts" setup>
import { Beneficiary } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { ref, PropType } from 'vue';

const paymentEditorCancelModal = ref<InstanceType<typeof BModal> | null>(null);

const emit = defineEmits<{
  (e: 'cancel-payment'): void;
}>();

const props = defineProps({
  beneficiary: {
    type: Object as PropType<Beneficiary>,
    required: true,
  },
});

function showModal() {
  paymentEditorCancelModal.value!.show();
}

function hideModal() {
  paymentEditorCancelModal.value!.hide();
}
</script>

<template>
  <span class="btn">
    <BModal centered modal-class="payment-modal" ref="paymentEditorCancelModal" hide-footer hide-header-close>
      <p class="text-center my-b">
        Are you sure you want to cancel this payment to
        {{ props.beneficiary.name ?? `${props.beneficiary.firstName} ${props.beneficiary.lastName}` }}
      </p>
      <div class="d-flex align-items-center justify-content-end">
        <VButton class="btn btn-secondary" @click="hideModal()">Cancel</VButton>
        <VButton class="ml-4 btn btn-danger" @click="emit('cancel-payment')">Confirm</VButton>
      </div>
    </BModal>

    <slot v-bind="{ showModal }">
      <VButton class="ml-4 btn btn-secondary" @click="showModal()"></VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .payment-modal .modal-dialog {
  min-width: 40em;
}

::v-deep .obo-approval-modal {
  .modal-content {
    padding: 2em 1em 1em 1em;
    .status-icon-pending {
      width: 25px;
      height: 25px;
      @include themedTextColor($color-yellow-highlight);
    }
    .btn {
      width: 104px;
    }
    .btn-outline {
      @include themedBackgroundColor($color-secondary);
      @include themedTextColor($color-secondary);
    }
  }
  .modal-dialog {
    min-width: 35em;
  }
}
</style>
