<script lang="ts">
import { previousAddressesFormsEmitsDef, previousAddressesFormsProps } from './PreviousAddressesForms.vue';

const { previousAddressesParentClass, ...props } = previousAddressesFormsProps;
</script>

<script setup lang="ts">
import PreviousAddressesForms from './PreviousAddressesForms.vue';

defineProps(props);

defineEmits(previousAddressesFormsEmitsDef);
</script>

<template>
  <PreviousAddressesForms
    class="onboarding-previous-addresses-form"
    v-bind="$props"
    previous-addresses-parent-class="card-block"
    v-on="$listeners"
  />
</template>

<style lang="scss" scoped>
.onboarding-previous-addresses-form ::v-deep {
  .previous-address {
    .previous-address-title {
      font-size: $base-font-size;
      @include themedTextColor($color-primary);
    }

    .previous-address-subtitle {
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      @include themedTextColor($color-text-black);
    }

    .info-icon {
      @include themedTextColor($color-orange-highlight);
    }
  }
}
</style>
