import { render, staticRenderFns } from "./CreateCreditFacilityRequestModal.vue?vue&type=template&id=23fc2670&scoped=true"
import script from "./CreateCreditFacilityRequestModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./CreateCreditFacilityRequestModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CreateCreditFacilityRequestModal.vue?vue&type=style&index=0&id=23fc2670&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23fc2670",
  null
  
)

export default component.exports