export enum TenorLimits {
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
}

export const tenorLimitsToMonthCount: Record<TenorLimits, number> = {
  [TenorLimits.ONE_MONTH]: 1,
  [TenorLimits.TWO_MONTHS]: 2,
  [TenorLimits.THREE_MONTHS]: 3,
  [TenorLimits.SIX_MONTHS]: 6,
  [TenorLimits.NINE_MONTHS]: 9,
  [TenorLimits.TWELVE_MONTHS]: 12,
};

export const tenorLimitsToDescription: Record<TenorLimits, string> = {
  [TenorLimits.ONE_MONTH]: 'one month',
  [TenorLimits.TWO_MONTHS]: 'two months',
  [TenorLimits.THREE_MONTHS]: 'three months',
  [TenorLimits.SIX_MONTHS]: 'six months',
  [TenorLimits.NINE_MONTHS]: 'nine months',
  [TenorLimits.TWELVE_MONTHS]: 'one year',
};

/**
 * TODO - EC: The code bellow is a temporary addition until the Sporty Team makes the US/task that will support new tenor limits
 * After that we can use the 'TenorLimits' and 'tenorLimitsToDescription' with all the supported tenor limits
 */
export enum NewTenorLimitsAddition {
  FIFTEEN_MONTHS = 'FIFTEEN_MONTHS',
  EIGHTEEN_MONTHS = 'EIGHTEEN_MONTHS',
  TWENTY_ONE_MONTHS = 'TWENTY_ONE_MONTHS',
  TWENTY_FOUR_MONTHS = 'TWENTY_FOUR_MONTHS',
}

export const newTenorLimitsAdditionToDescription: Record<NewTenorLimitsAddition, string> = {
  [NewTenorLimitsAddition.FIFTEEN_MONTHS]: 'fifteen months',
  [NewTenorLimitsAddition.EIGHTEEN_MONTHS]: 'eighteen months',
  [NewTenorLimitsAddition.TWENTY_ONE_MONTHS]: 'twenty one months',
  [NewTenorLimitsAddition.TWENTY_FOUR_MONTHS]: 'two years',
};
