<script setup lang="ts">
import ActivityPaymentsListBlock from '@/app/components/payments/ActivityPaymentsListBlock.vue';
import { useAuthStore } from '@/app/store/authStore';
import { AuthorityType } from 'ah-api-gateways';

const authStore = useAuthStore();
const canExport = authStore.hasAuthorities(AuthorityType.EXPORT_PAYMENTS);
</script>

<template>
  <div class="padded-space" id="activity-payments-view">
    <h2>Client Payments</h2>
    <ActivityPaymentsListBlock :showExport="canExport" documentsTitle="Client Payments" />
  </div>
</template>
