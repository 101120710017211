<script lang="ts" setup>
import {
  ExportListType,
  FeeChargeType,
  PaymentRail,
  PaymentReport,
  PaymentState,
  routingCodeTypeLabels,
} from 'ah-api-gateways';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { computed } from 'vue';
import PaymentsTradeDetailsModal from './modals/PaymentsTradeDetailsModal.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import PaymentsCancelModal from './modals/PaymentsCancelModal.vue';
import PaymentsCreateEditModal from './modals/PaymentsCreateEditModal.vue';
import { useAhPaymentState } from '..';

const paymentState = useAhPaymentState();

const requestManager = useRequestManager().manager;

const props = defineProps<{
  payment: PaymentReport;
}>();

const emit = defineEmits<{
  (e: 'update-payment'): void;
  (e: 'cancel-payment'): void;
}>();

const cutoffTime = computed(() => {
  if (!props.payment.cutoffTime) {
    return '-';
  }
  let date = new Date();
  let time = props.payment.cutoffTime;
  const dateTime = new Date(formatDate(date, 'yyyy-MM-dd') + ' ' + time);
  return formatDate(dateTime, 'HH:mm');
});

const paymentFee = computed(() => {
  if (props.payment.clientId === null) {
    return `${props.payment.partnerFeeCurrency}  ${formatCurrencyValue(props.payment.partnerFeeAmount)}`;
  }
  return `${props.payment.clientFeeCurrency}  ${formatCurrencyValue(props.payment.clientFeeAmount)}`;
});

const paymentCanEditOrCancel = computed(() => {
  return (
    props.payment.state === PaymentState.QUEUED ||
    props.payment.state === PaymentState.PENDING ||
    props.payment.state === PaymentState.SCHEDULED
  );
});

const showExportPaymentConfirmation = computed(
  () => props.payment.state === PaymentState.CONFIRMED && props.payment.beneficiaryPaymentRail === PaymentRail.SWIFT
);

function downloadPayment() {
  requestManager
    .cancelAndNew('downloadPayment', paymentState.services.payments.downloadPayment(props.payment.id))
    .subscribe((doc) => useNotificationsStore().triggerFileExportRequestNotification(doc));
}

function downloadPaymentMt103() {
  requestManager
    .cancelAndNew(
      'downloadPaymentMt103',
      paymentState.services.payments.downloadPaymentMt103(props.payment.id, 'PDF' as ExportListType)
    )
    .subscribe((doc) => useNotificationsStore().triggerFileExportRequestNotification(doc));
}

const feeTypeTooltip = (state: FeeChargeType) => {
  switch (state) {
    case FeeChargeType.SHA:
      return 'The intermediary bank charges are deducted from the payment amount. As a result, the beneficiary may receive less than the full payment amount.';
    case FeeChargeType.ACO:
      return 'The intermediary bank charges are covered by the payer and not deducted from the payment amount.';
    default:
      return '';
  }
};

function onPaymentSaved() {
  emit('update-payment');
}
</script>

<template>
  <div>
    <VRow class="details-row my-3 w-100" alignH="start">
      <VCol cols="5" sm="12" md="5" class="payment-details-border-right">
        <VRow>
          <VCol cols="6" offset="6">
            <h4 class="semi-bold text-left">Beneficiary Bank Details</h4>
          </VCol>
        </VRow>
        <DataRow cols="6" label="Bank name">
          {{ payment.beneficiaryBankName }}
        </DataRow>
        <DataRow cols="6" label="Bank Country">
          {{ payment.beneficiaryBankCountry }}
        </DataRow>
        <DataRow cols="6" label="Account Holder">
          {{ payment.beneficiaryName }}
        </DataRow>
        <DataRow v-if="payment.beneficiaryAccountNumber" cols="6" label="Account Number">
          {{ payment.beneficiaryAccountNumber }}
        </DataRow>
        <DataRow
          v-if="payment.beneficiaryRoutingCodeType1 && payment.beneficiaryRoutingNumber1"
          cols="6"
          :label="routingCodeTypeLabels[payment.beneficiaryRoutingCodeType1]"
        >
          {{ payment.beneficiaryRoutingNumber1 }}
        </DataRow>
        <DataRow
          v-if="payment.beneficiaryRoutingCodeType2 && payment.beneficiaryRoutingNumber2"
          cols="6"
          :label="routingCodeTypeLabels[payment.beneficiaryRoutingCodeType2]"
        >
          {{ payment.beneficiaryRoutingNumber2 }}
        </DataRow>
        <DataRow
          v-if="payment.beneficiaryBicSwift && ['SEPA', 'SWIFT'].includes(payment.beneficiaryPaymentRail)"
          cols="6"
          label="Bic/SWIFT"
        >
          {{ payment.beneficiaryBicSwift }}
        </DataRow>
        <DataRow
          v-if="payment.beneficiaryIban && ['SEPA', 'SWIFT'].includes(payment.beneficiaryPaymentRail)"
          cols="6"
          label="IBAN"
        >
          {{ payment.beneficiaryIban }}
        </DataRow>
      </VCol>
      <VCol cols="5" sm="12" md="5" class="payment-details-border-right">
        <VRow>
          <VCol cols="6" offset="6">
            <h4 class="semi-bold text-left">Payment Details</h4>
          </VCol>
        </VRow>
        <DataRow cols="6" label="Payment Reason">
          <div class="text-wrap">
            {{ payment.description }}
          </div>
        </DataRow>
        <DataRow cols="6" label="Payment Reference">
          {{ payment.reference }}
        </DataRow>
        <DataRow cols="6" label="Cut Off Time"> {{ cutoffTime }} </DataRow>
        <br />
        <DataRow cols="6" label="Fee type">
          {{ payment.chargeType ?? 'N/A' }}
          <InfoTooltip v-if="payment.chargeType" :text="feeTypeTooltip(payment.chargeType)" />
        </DataRow>
        <DataRow cols="6" label="Payment Fee">
          {{ paymentFee }}
        </DataRow>
        <br />
        <DataRow cols="6" label="Linked Trade ID" v-if="payment.tradeId">
          <PaymentsTradeDetailsModal
            :key="payment.tradeComposedReferenceNumber"
            :tradeId="payment.tradeId"
            :tradeComposedReferenceNumber="payment.tradeComposedReferenceNumber"
          />
        </DataRow>
        <br />
        <DataRow cols="6" label="Created By">
          {{ payment.createdByName ?? '-' }}
        </DataRow>
        <DataRow cols="6" label="Created On">
          {{ formatDate(payment.createdAt) }}
        </DataRow>
      </VCol>
      <VCol class="d-flex align-items flex-wrap justify-content-center" cols="2" sm="12" md="2">
        <VButton
          v-if="showExportPaymentConfirmation"
          class="btn-primary ml-auto mr-auto mt-auto d-block w-100 mw-100 text-wrap"
          @click="downloadPaymentMt103"
          :loading="requestManager.requestStates.downloadPaymentMt103 === 'pending'"
        >
          <DownloadIcon class="icon export-details-icon mr-2" />Export Payment Confirmation
        </VButton>
        <PaymentsCancelModal
          v-slot="{ showModal }"
          v-if="paymentCanEditOrCancel"
          :payment="payment"
          @cancel-payment="$emit('cancel-payment')"
        >
          <button v-if="paymentCanEditOrCancel" @click="showModal" class="btn btn-secondary d-block w-100">
            Cancel
          </button>
        </PaymentsCancelModal>

        <PaymentsCreateEditModal
          v-if="paymentCanEditOrCancel"
          :payment="payment"
          v-slot="{ showModal }"
          @update-payment="onPaymentSaved()"
        >
          <VButton v-if="paymentCanEditOrCancel" @click="showModal" class="btn-primary d-block w-100">Amend</VButton>
        </PaymentsCreateEditModal>

        <VButton
          class="btn-primary ml-auto mr-auto mt-auto d-block w-100 mw-100"
          @click="downloadPayment"
          :loading="requestManager.requestStates.downloadPayment === 'pending'"
        >
          <DownloadIcon class="icon export-details-icon mr-2" />Export Details
        </VButton>
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
  font-weight: $font-weight-bold;
}
.text-muted {
  opacity: 0.6;
}
::v-deep .semi-bold {
  text-align: right;
  font-weight: 700;
}

.payment-details-border-right {
  border-right: 1px solid;
  @include themedPropColor('border-right-color', $color-primary);
}
@media (max-width: 900px) {
  .payment-details-border-right {
    border-bottom: 1px solid;
    @include themedPropColor('border-bottom-color', $color-primary);
    border-right: none;

    margin-bottom: 10px;
  }
}

::v-deep.payment-listing-fee-type-tooltip {
  .tooltip-inner {
    max-width: 400px;
    @include themedBackgroundColor($color-primary);
  }
  .arrow::before {
    @include themedPropColor('border-top-color', $color-primary);
  }
}

::v-deep svg.icon.export-details-icon {
  @include themedPropColor('color', $color-input-bg);
}
</style>
