<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
  },
});

const fileType = computed(() => {
  if (!props.type) return '';
  if (props.type.length > 3) return props.type.slice(0, 3).toUpperCase();
  return props.type.toUpperCase();
});
</script>

<template>
  <div class="file-badge">
    <slot name="file-icon">
      <FileIcon class="file-icon" />
    </slot>
    <span class="file-type">{{ fileType }}</span>
  </div>
</template>

<style lang="scss" scoped>
.file-badge {
  position: relative;
  .file-icon {
    width: 28px;
    height: 28px;
    ::v-deep path {
      fill: $common-color-darkGrey;
    }
  }

  .file-type {
    width: 28px;
    position: absolute;
    text-align: center;
    top: 10px;
    left: 0;
    font-size: 7px;
    color: $common-color-white;
  }
}
</style>
