<script lang="ts" setup>
import VChart from './VChart.vue';
import take from 'lodash/take';
import { linesColorPrimary } from '../helpers/graphColors';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useAhReportsState } from '..';
import { makeBarChartOptions, makeThemedChartDataset } from '../helpers';

const TOP_DISPLAY_AMOUNT = 5;

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadData') {
    loadDates();
  }
}

const props = defineProps<{
  fromDate: Date;
  toDate: Date;
  /**
   * Whether to limit results to a particular Partner
   * - Value should only be used in Admin usage (non-admin usage only has access to a single Partner)
   * - Value only used if `partnerAccounts` is false
   */
  partnerId?: string;
}>();

const state = reactive({
  options: makeBarChartOptions(
    { horizontal: true, gridColor: linesColorPrimary },
    {
      scales: {
        y: {
          ticks: {
            autoSkip: false,
          },
        },
      },
    }
  ),
  data: makeThemedChartDataset({
    labels: [],
    colorNames: ['widgets-green'],
  }),
});

const dates = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
}));

const reportsState = useAhReportsState();

function loadDates() {
  requestManager.manager
    .cancelAndNew(
      'loadData',
      reportsState.services.trade.getClientTopAccountsReport(props.fromDate, props.toDate, props.partnerId)
    )
    .subscribe((response) => {
      const accounts = take(response.list, TOP_DISPLAY_AMOUNT);
      state.data.datasets![0].data! = accounts.map((i) => i.tradedAmount);
      state.data.labels = accounts.map((i) => i.clientName);

      // Force update by replacing prop reference entirely
      state.options = { ...state.options };
      state.data = { ...state.data };
    });
}

watch(dates, loadDates, { immediate: true });
</script>

<template>
  <div>
    <div class="top-accounts-container">
      <VChart class="chart" type="bar" :options="state.options" :data="state.data" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 250px;
  padding-bottom: 33%;
}
.top-accounts-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  ol {
    width: 60%;
    list-style: none;
    counter-reset: li;
    li {
      counter-increment: li;
    }
    li::before {
      @include themedTextColor($color-primary);
      content: '.' counter(li);
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl;
    }
  }
  .top-accounts-item {
    display: grid;
    grid-template-columns: 10px 50% 50px auto;
    justify-items: start;
    align-items: center;
    .currency {
      justify-self: end;
    }
  }
}

.label {
  @include phablet {
    font-size: 10px;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    margin-left: 4rem;
  }
  @include tablet {
    margin-left: -2rem;
  }
  @include desktop {
    text-align: left !important;
    margin-left: 10rem;
  }
}
</style>
