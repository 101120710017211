<script setup lang="ts">
import { ClientFileCategories, UploadedFile, Client, ClientType, Individual } from 'ah-api-gateways';
import FileReplacer from 'ah-common-lib/src/common/components/upload/FileReplacer.vue';
import { mergeMap } from 'rxjs/operators';
import {
  defaultAcceptanceFileExtension,
  defaultAcceptanceFileTypes,
  formatBytes,
} from 'ah-common-lib/src/helpers/file';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { computed, reactive, watch } from 'vue';
import { Observable, of } from 'rxjs';
import { UploadedFileUpdate, waitForEntityChange } from 'ah-requests';

const props = withDefaults(
  defineProps<{
    /**
     * Client or Individual to display documents of
     */
    entity: Client | Individual;
    /**
     * Whether to force display of individual documents
     */
    forceIndividual?: boolean | string;
    /**
     * Possibly set load list of documents request
     *
     * If not set, `services.client.getDocuments` will be used
     */
    getDocumentListRequest?: (id: string) => Observable<UploadedFile[]>;
    /**
     * Possibly set upload document request
     *
     * If not set, `services.client.uploadDocument` will be used
     */
    uploadDocumentsRequest?: (
      id: string,
      category: ClientFileCategories,
      file: File
    ) => Observable<UploadedFileUpdate<UploadedFile>>;
    /**
     * Max allowed size of documents
     *
     * Will default to 10MB (10485760)
     */
    maxSize?: number;
    /**
     * Array of file categories to be hidden, if set
     */
    hideFiles?: ClientFileCategories[];
    /**
     * Whether to hide show max size and accepted formats texts.
     *
     */
    hideFooter?: boolean | string;
  }>(),
  {
    forceIndividual: false,
    maxSize: 10485760,
  }
);

const emit = defineEmits<{
  (e: 'update:files', value: UploadedFile[]): void;
}>();

const state = reactive<{
  files: UploadedFile[];
  innerVisibleCollapse: string;
}>({
  files: [],
  innerVisibleCollapse: '',
});

const requestManager = useRequestManager();

const services = getServices();

const readableAccept = computed(() => defaultAcceptanceFileExtension.toUpperCase().split(',').join(', '));

const maxSizeFormatted = computed(() => formatBytes(props.maxSize));

const photoId = computed(() => state.files.find((d) => d.category === ClientFileCategories.PHOTO_ID));

const proofOfAddress = computed(() => state.files.find((d) => d.category === ClientFileCategories.PROOF_OF_ADDRESS));

const proofOfFunds = computed(() => state.files.find((d) => d.category === ClientFileCategories.PROOF_OF_FUNDS));

const IncCertif = computed(() => state.files.find((d) => d.category === ClientFileCategories.INCORPORATED_DOCUMENT));

const finStatement = computed(() => state.files.find((d) => d.category === ClientFileCategories.FINANCIAL_STATEMENT));

const invoiceSample = computed(() => state.files.find((d) => d.category === ClientFileCategories.SAMPLE_INVOICE));

const boardResolutionLetter = computed(() =>
  state.files.find((d) => d.category === ClientFileCategories.RESOLUTION_LETTER)
);

const isIndividual = computed(() => props.forceIndividual !== false || props.entity.type === ClientType.INDIVIDUAL);

const shouldHideFooter = computed(() => props.hideFooter !== false);

const getRequest = computed(() => {
  if (props.getDocumentListRequest) {
    return props.getDocumentListRequest(props.entity.id);
  } else {
    return services.client.getDocuments(props.entity.id, { errors: { silent: true } });
  }
});

function loadFiles() {
  requestManager.manager
    .sameOrCancelAndNew('getClientDocuments', getRequest.value)
    .subscribe((response) => (state.files = response));
}

function uploadFile(file: File, category: ClientFileCategories) {
  let request;
  if (props.uploadDocumentsRequest) {
    request = props.uploadDocumentsRequest(props.entity.id, category, file);
  } else {
    request = services.client.uploadDocument(props.entity.id, category, file);
  }

  return request.pipe(
    mergeMap((file) => {
      if (file.finished) {
        return waitForEntityChange(
          () => getRequest.value,
          (docs) => {
            return !!docs.find((d) => d.id === file.file.id);
          }
        ).pipe(
          mergeMap((docs) => {
            const document = docs.find((d) => d.id === file.file.id);
            if (document) {
              const currFileIndex = state.files.findIndex((i) => i.category === document.category);
              currFileIndex > -1 ? state.files.splice(currFileIndex, 1, document) : state.files.push(document);
            }
            return of(file);
          })
        );
      }
      return of(file);
    })
  );
}

function uploadID(file: File) {
  return uploadFile(file, ClientFileCategories.PHOTO_ID);
}

function uploadPOA(file: File) {
  return uploadFile(file, ClientFileCategories.PROOF_OF_ADDRESS);
}

function uploadPOF(file: File) {
  return uploadFile(file, ClientFileCategories.PROOF_OF_FUNDS);
}

function uploadINCC(file: File) {
  return uploadFile(file, ClientFileCategories.INCORPORATED_DOCUMENT);
}

function uploadFS(file: File) {
  return uploadFile(file, ClientFileCategories.FINANCIAL_STATEMENT);
}

function uploadSI(file: File) {
  return uploadFile(file, ClientFileCategories.SAMPLE_INVOICE);
}

function uploadBRL(file: File) {
  return uploadFile(file, ClientFileCategories.RESOLUTION_LETTER);
}

watch(
  () => state.files,
  () => {
    emit('update:files', state.files);
  }
);

watch(() => props.entity, loadFiles, { immediate: true });
</script>

<template>
  <div>
    <template v-if="!isIndividual">
      <FileReplacer
        title="Certificate of Incorporation"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadINCC"
        :uploaded="IncCertif"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.INCORPORATED_DOCUMENT)"
      />
      <FileReplacer
        title="Audited Financial Statements/Latest Accounts"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadFS"
        :uploaded="finStatement"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.FINANCIAL_STATEMENT)"
      />
      <FileReplacer
        title="Proof of Business Activity"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadSI"
        :uploaded="invoiceSample"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.SAMPLE_INVOICE)"
      />
      <FileReplacer
        title="Proof of Authorised Signatory Document"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadBRL"
        :uploaded="boardResolutionLetter"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.RESOLUTION_LETTER)"
      />
    </template>

    <template v-else>
      <FileReplacer
        title="Proof of Address"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadPOA"
        :uploaded="proofOfAddress"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.PROOF_OF_ADDRESS)"
      />
      <FileReplacer
        title="Proof of ID"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadID"
        :uploaded="photoId"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.PHOTO_ID)"
      />
      <FileReplacer
        title="Proof of Funds"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :accept="defaultAcceptanceFileTypes"
        :maxSize="10485760"
        :uploadRequest="uploadPOF"
        :uploaded="proofOfFunds"
        :loading="requestManager.manager.anyPending"
        hide-footer
        v-if="!hideFiles?.includes(ClientFileCategories.PROOF_OF_FUNDS)"
      />
    </template>
    <div class="file-replacer-descrition" v-if="!shouldHideFooter">
      <div class="my-3">
        <p>Max size: {{ maxSizeFormatted }}</p>
        <p>
          Supported formats: <span class="inline small-text">{{ readableAccept }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-replacer-descrition {
  * {
    font-size: $font-size-sm;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
  }

  p {
    margin-bottom: 0;
  }
}
</style>
