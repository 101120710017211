import { RequestManager, RequestState } from 'ah-requests';
import type { InjectKey } from 'vue/types/options';

export const REQUEST_MANAGER_REGISTER_KEY: InjectKey = Symbol();
export const REQUEST_MANAGER_DEREGISTER_KEY: InjectKey = Symbol();

export interface WithManagerData {
  manager: RequestManager;
  anyRequestInState: (state: RequestState, checkChildren: boolean) => boolean;
  getAllRequests: (filter: RequestFilter, checkChildren: boolean) => RequestData[];
  getAllRequestsInState: (state: RequestState | RequestState[], checkChildren: boolean) => RequestData[];
  retryRequest: (key: string) => void;
  children: WithManagerData[];
}

export type RequestFilter = (key: string, state: RequestState) => boolean;

export interface RequestData {
  request: string;
  state: RequestState;
  managerData: WithManagerData;
}

export interface RequestManagerConfig {
  clearOnDestroy: boolean;
  /**
   * Whether to register this manager on the manager chain, and what requests to expose in the chain (through either a whitelist or validator function)
   * Caveats:
   * - If `exposeToParent` is an array of keys or validator function, child managers will not be exposed
   * - Any manager with `exposeToParent` as false will "break" the chain,
   *   and any child components will also not be registered above it
   */
  exposeToParent: boolean | string[] | ((req: string) => boolean);
  onRetryFromParentManager: (key: string) => void;
}

export const requestManagerConfigDefaults: RequestManagerConfig = {
  clearOnDestroy: true,
  exposeToParent: false,
  onRetryFromParentManager: () => {},
};
