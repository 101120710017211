<script lang="ts">
import { makeFormModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { FormEvent, FormModel, FormSelectFieldSearchEvent } from 'ah-common-lib/src/form/interfaces';
import { catchError, flatMap, take, map } from 'rxjs/operators';
import { timer, combineLatest, Observable } from 'rxjs';
import { SECOND } from 'ah-common-lib/src/constants/time';
import { TargetSelection, GroupTargetSelection, IndividualTargetSelection, targetSelectionName } from 'ah-api-gateways';
import { cleanNumber, getPhoneNumber, isValidPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import { useAuthStore } from '@/app/store/authStore';
import { isValidEmail } from 'ah-common-lib/src/helpers/email';
import { computed, onBeforeMount, PropType, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const USER_SEARCH_DEBOUNCE_TIME = SECOND * 3;

interface RecipientOption {
  label: string;
  value: TargetSelection | false;
  header?: boolean;
}

type searchType = 'individual' | 'phone' | 'email' | 'group';

type SearchResults = Partial<{
  group: GroupTargetSelection[];
  individual: IndividualTargetSelection[];
}>;

export type searchTypeOptions = {
  [key in searchType]: {
    filters?: any;
    disallowed?: string[];
  };
};
</script>

<script setup lang="ts">
const props = defineProps({
  baseCountryCode: {
    type: String,
    default: '',
  },
  types: {
    type: Object as PropType<searchTypeOptions>,
    required: true,
  },
  filters: {
    type: Object as PropType<any>,
    default: () => {},
  },
});

const emit = defineEmits({
  'user-selected': (_user: IndividualTargetSelection) => true,
  'target-selected': (_target: IndividualTargetSelection) => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
}).manager;

const authStore = useAuthStore();

const services = getServices();

const targetSelectForm = reactive<FormModel>(
  makeFormModel({
    name: 'targetSelect',
    fieldType: 'form',
    fields: [
      selectField('target', '', [], {
        inputClass: 'search-box',
        filterable: false,
        placeholder: 'Search by name or email',
        selectable: (option: any) => {
          return !option.header;
        },
      }),
    ],
    state: {
      hideErrors: true,
    },
  })
);

const query = ref('');

const individualOptions = ref<RecipientOption[]>([]);

const groupOptions = ref<RecipientOption[]>([]);

onBeforeMount(() => {
  searchTargets('');
});

function isSearchAllowed(type: searchType) {
  return !!props.types[type];
}

function filterOptions(options: RecipientOption[], key: searchType) {
  return options.filter((option) => {
    if (props.types[key]) {
      return !(props.types[key].disallowed ?? []).includes((option.value as any)[key].id);
    }
  });
}

const filteredIndividualOptions = computed(() => filterOptions(individualOptions.value, 'individual'));

const filteredGroupOptions = computed(() => filterOptions(groupOptions.value, 'group'));

const groupService = computed(() => {
  if (authStore.isClientUser) {
    return services.clientGroup;
  } else {
    return services.partnerGroup;
  }
});

const options = computed(() => {
  const options: RecipientOption[] = [];

  if (isSearchAllowed('phone')) {
    if (isValidPhoneNumber(query.value, (props.baseCountryCode || undefined) as any)) {
      const value = cleanNumber(query.value, (props.baseCountryCode || undefined) as any);
      const disallowedPhoneNumbers = props.types.phone.disallowed ?? [];
      if (!disallowedPhoneNumbers.includes(value)) {
        options.push(
          {
            label: `Add phone number`,
            value: false,
            header: true,
          },
          {
            label: getPhoneNumber(query.value, (props.baseCountryCode || undefined) as any),
            value: {
              phoneNumber: value,
            },
          }
        );
      }
    }
  }
  if (isSearchAllowed('email')) {
    const disallowedEmails = props.types.phone.disallowed ?? [];
    if (isValidEmail(query.value) && !disallowedEmails.includes(query.value)) {
      options.push(
        {
          label: `Add email`,
          value: false,
          header: true,
        },
        {
          label: query.value,
          value: { email: query.value },
        }
      );
    }
  }

  if (isSearchAllowed('group') && filteredGroupOptions.value.length) {
    options.push(
      {
        label: `Groups`,
        value: false,
        header: true,
      },
      ...filteredGroupOptions.value
    );
  }

  if (isSearchAllowed('individual') && filteredIndividualOptions.value.length) {
    options.push(
      {
        label: `Users`,
        value: false,
        header: true,
      },
      ...filteredIndividualOptions.value
    );
  }

  return options;
});

function onOptionsChanged() {
  const formModel = getChildModel(targetSelectForm, 'target');

  formModel && setState(formModel, 'options', options.value);
}

watch(options, onOptionsChanged);

function onUserSelected() {
  if (targetSelectForm.target) {
    emit('user-selected', targetSelectForm.target);
  }
}

watch(() => targetSelectForm.target, onUserSelected);

function searchTargets(queryAux: string) {
  query.value = queryAux;
  requestManager
    .cancelAndNew(
      'searchUserTimeout',
      timer(USER_SEARCH_DEBOUNCE_TIME).pipe(
        take(1),
        flatMap(() => requestManager.cancelAndNew('searchUser', searchRequest(queryAux)))
      )
    )
    .pipe(
      catchError((e) => {
        throw e;
      })
    )
    .subscribe((response) => {
      groupOptions.value =
        response.group?.map((target) => ({
          label: targetSelectionName(target),
          value: target,
        })) ?? [];

      individualOptions.value =
        response.individual?.map((target) => ({
          label: targetSelectionName(target),
          value: target,
        })) ?? [];
    });
}

function searchRequest(query: string): Observable<SearchResults> {
  const requests: Observable<SearchResults>[] = [];

  if (isSearchAllowed('individual')) {
    requests.push(
      services.individual
        .listIndividuals({
          sort: 'name',
          query,
          ...props.types.individual?.filters,
        })
        .pipe(
          map((r) => ({
            individual: r.list.map((i) => ({ individual: i })),
          }))
        )
    );
  }
  if (isSearchAllowed('group')) {
    if (props.types.group?.filters.clientId) {
      requests.push(
        groupService.value
          .listGroups(
            {
              sort: 'name',
              query,
              ...props.types.group?.filters,
            },
            authStore.isClientUser ? props.types.group?.filters.clientId : props.types.group?.filters.partnerId
          )
          .pipe(
            map((r) => ({
              group: r.list.map((g) => ({ group: g })),
            }))
          )
      );
    }
  }

  return combineLatest(requests).pipe(
    map((r) => {
      const out = {} as SearchResults;
      r.forEach((i) => {
        Object.assign(out, i);
      });
      return out;
    })
  );
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-select-search') {
    let searchEvent = event as FormSelectFieldSearchEvent;
    searchTargets(searchEvent.search);
  } else if (event.event === 'form-field-set-value') {
    emit('target-selected', targetSelectForm.target);
    targetSelectForm.target = null;
  }
}
</script>

<template>
  <ValidatedForm :fm="targetSelectForm" @form-event="onFormEvent">
    <template #targetSelect.target:list-header>
      <li
        v-if="options.length > 0 && requestManager.requestStates.searchUserTimeout === 'pending'"
        class="text-secondary text-center loading-text"
      >
        Loading results...
      </li>
    </template>
    <template #targetSelect.target:search="{ selectScope }">
      <IconSearch class="search-icon" />
      <input class="vs__search" v-bind="selectScope.attributes" v-on="selectScope.events" />
    </template>
    <template #targetSelect.target:no-options>
      <li
        class="text-secondary text-center loading-text"
        v-if="requestManager.requestStates.searchUserTimeout !== 'pending'"
      >
        No users found<span v-if="query"> for "{{ query }}"</span>
      </li>
      <li v-else class="text-secondary text-center loading-text">Loading results...</li>
    </template>
    <template #targetSelect.target:option="{ selectScope }">
      <span :class="['option', { 'option-header': selectScope.header }]">{{ selectScope.label }}</span>
    </template>
  </ValidatedForm>
</template>

<style lang="scss" scoped>
.option {
  padding-left: 32px;

  &.option-header {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    @include themedTextColor($color-dark-text, $color-dark-text);
  }
}

::v-deep .vs__dropdown-option--disabled {
  @include themedBackgroundColor($color-primary, $color-dark-primary);
}

.search-icon {
  height: 24px;
  width: 24px;
  margin-top: 6px;
  margin-left: 6px;
}
</style>
