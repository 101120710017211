<script setup lang="ts">
import { Client, PaginatedResponse, Individual } from 'ah-api-gateways';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
  /**
   * Filter out individuals without users
   */
  hideUserlessIndividuals: {
    type: Boolean,
    default: true,
  },
  /**
   * Max number of items shown. If 0, shows only count of individuals
   */
  maxItems: {
    type: Number,
    default: 0,
  },
  /**
   * Whether to show the count of Individuals
   */
  showCount: {
    type: Boolean,
    default: true,
  },
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'getIndividuals') {
      loadClientIndividuals();
    }
  },
}).manager;

const clientIndividuals = ref<PaginatedResponse<Individual> | null>(null);

const services = getServices();

const extraIndividualCount = computed(() => {
  if (clientIndividuals.value) {
    return clientIndividuals.value.total - Math.min(props.maxItems, clientIndividuals.value.list.length);
  }

  return 0;
});

const shownIndividuals = computed(() => {
  if (clientIndividuals.value) {
    return clientIndividuals.value.list.slice(0, props.maxItems);
  }
  return [];
});

const isShowingNames = computed(() => {
  return shownIndividuals.value.length > 0;
});

function loadClientIndividuals() {
  requestManager
    .sameOrCancelAndNew(
      'getIndividuals',
      services.individual.listIndividuals({ clientId: props.client.id, pageSize: Math.max(1, props.maxItems) }),
      props.client.id
    )
    .subscribe((response) => {
      // only display individuals that have an user associated.
      if (props.hideUserlessIndividuals) response.list = response.list.filter((individual) => individual.user.id);
      clientIndividuals.value = response;
    });
}

watch(() => props.client.id, loadClientIndividuals, { immediate: true });
</script>

<template>
  <div>
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else-if="clientIndividuals">
      <template v-if="isShowingNames">
        <span class="client-name" v-for="(individual, index) in shownIndividuals" :key="individual.id">
          <slot name="individual-text" :individual="individual">
            {{ individual.firstName }} {{ individual.lastName }}
            <span v-if="index > shownIndividuals.length - 1">,</span>
          </slot>
        </span>
        <span v-if="showCount && extraIndividualCount > 0">+{{ extraIndividualCount }} others</span>
      </template>
      <span v-else-if="showCount">{{ extraIndividualCount }} Individuals</span>
    </template>
    <span v-else>N/A</span>
  </div>
</template>

<style lang="scss" scoped>
span:not(.client-name) {
  opacity: 0.5;
}
</style>
