<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { UploadedFile } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import FileUploader from './FileUploader.vue';
import InformationIcon from '../../../icons/components/InformationIcon.vue';
import LoadingOverlay from '../overlays/LoadingOverlay.vue';
import { format } from 'date-fns';

const props = withDefaults(
  defineProps<{
    title: string;
    uploaded?: UploadedFile;
    loading?: boolean | string;
    replaceButtonLabel?: string;
    uploadButtonLabel?: string;
  }>(),
  {
    loading: false,
    replaceButtonLabel: 'Replace Document',
    uploadButtonLabel: 'Upload',
  }
);

const state = reactive<{
  fileSelected: File | null;
}>({
  fileSelected: null,
});

const fileUploader = ref<InstanceType<typeof FileUploader> | null>(null);

const uploadModal = ref<InstanceType<typeof BModal> | null>(null);

const loading = computed(() => fileUploader.value?.isLoading || props.loading);

const modalTitle = computed(() => {
  if (!props.uploaded) {
    return `Upload new ${props.title}`;
  }
  return `Replace the existing ${props.title} document`;
});

const buttonLabel = computed(() => (props.uploaded ? props.replaceButtonLabel : props.uploadButtonLabel));

function onFileSelected(file: File | null) {
  state.fileSelected = file;
}

function showModal() {
  uploadModal.value?.show();
}

function hideModal() {
  uploadModal.value?.hide();
}

function onFileUploaded() {
  hideModal();
}

function uploadFile() {
  fileUploader.value?.uploadFile(0);
}
</script>

<template>
  <div class="file-replacer-wrapper">
    <LoadingOverlay :loading="loading" variant="box">
      <div class="file-replacer mt-4" :class="{ bordered: !!uploaded }">
        <div class="file-header">
          <div class="file-icon">
            <FileIcon />
          </div>
          <div class="file-title">
            <div class="font-weight-bold">{{ title }}</div>
            <div class="status" :class="{ uploaded: !!props.uploaded }">
              {{ !props.uploaded ? 'No document' : 'Document' }} uploaded
            </div>
          </div>
        </div>

        <FileUploader
          v-bind="$attrs"
          v-on="$listeners"
          class="file-uploader"
          @update:uploaded="onFileUploaded"
          hide-warning
          v-if="!uploaded"
        />

        <VRow class="file-actions" v-else>
          <VCol>
            <VButton blurOnClick class="btn-stroked" @click="showModal">
              <div class="button-label">
                {{ buttonLabel }}
                <div>
                  <slot name="upload-icon"> <UploadIcon /></slot>
                </div>
              </div>
            </VButton>
          </VCol>
          <VCol class="file-audit">
            <template v-if="props.uploaded && props.uploaded.createdByName && props.uploaded.createdAt">
              <div>Updated by {{ props.uploaded.createdByName }}</div>
              <div>{{ format(new Date(props.uploaded.createdAt), 'dd-MM-yyyy HH:mm') }}</div>
            </template>
          </VCol>
        </VRow>
      </div>
    </LoadingOverlay>

    <BModal ref="uploadModal" modal-class="file-replacer-modal" centered hide-header hide-footer>
      <h3 class="text-center"><InformationIcon class="alert-icon" /> {{ modalTitle }}</h3>
      <p class="modal-header-description text-center" v-if="props.uploaded">
        Note that replacing the document <b>{{ props.uploaded.name }}</b> will delete the existing document.
      </p>
      <FileUploader
        ref="fileUploader"
        v-bind="$attrs"
        @update:uploaded="onFileUploaded"
        @update:selected="onFileSelected"
        :autoUpload="false"
        hide-actions
        hide-warning
      />
      <div class="button-actions text-center">
        <VButton class="btn-stroked" @click="hideModal" :disabled="loading"> Cancel </VButton>
        <VButton class="btn-primary" @click="uploadFile" :loading="loading" :disabled="!state.fileSelected">
          Save
        </VButton>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.file-replacer-wrapper {
  position: relative;
  .file-replacer {
    border: 1px solid;
    @include themedBorderColor($color-primary, $color-dark-primary);

    ::v-deep .file-uploader {
      margin-top: -1rem;
      .file-input {
        border: none;
      }
    }
    .file-header {
      padding: 1em 1em 0 1em;
      display: grid;
      grid-template-columns: min-content 1fr;
      .file-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1em;
        margin-right: 0.3em;
        font-size: 1.8em;
      }
      .file-title {
        vertical-align: middle;

        .title {
          font-weight: $font-weight-semibold;
          font-size: $font-size-sm;
        }
        .status {
          @include themedTextColor($color-danger, $color-dark-danger);
          &.uploaded {
            @include themedTextColor($color-success, $color-dark-success);
          }
        }
      }
    }

    .file-actions {
      margin-top: 1em;
      padding: 0 1em 1em 1em;
      button {
        width: 100%;
        max-width: 17rem;
        ::v-deep .button-label {
          display: grid;
          grid-template-columns: 1fr min-content;
          text-align: start;
          vertical-align: middle;
        }
      }
      .file-audit {
        font-size: $font-size-sm;
        text-align: end;
      }
    }
  }
}

::v-deep {
  .file-replacer-modal {
    .modal-body {
      max-width: 35rem;
      margin: auto;
    }
    .modal-header-description {
      @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    }
    .alert-icon {
      @include themedTextColor($color-danger, $color-dark-danger);
    }

    .button-actions {
      margin-top: 1.5rem;
      button {
        min-width: 7rem;
        margin: 0 1em;
      }
    }
  }
}
</style>
