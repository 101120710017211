import {
  ConfirmPremiumPaid,
  PaginatedQuery,
  PaginatedResponse,
  PremiumPaymentResponse,
  standardiseQuerySort,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';

export class PremiumPaymentService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'premiumPaymentService' } },
      },
    });
  }

  public updateConfirmPaidPremium(
    premiumPayments: ConfirmPremiumPaid[],
    options?: Partial<HttpOptions<PremiumPaymentResponse>>
  ) {
    return this.put<PremiumPaymentResponse>(
      `${this.baseUrl}premium-payments`,
      { premiumPayments: premiumPayments },
      {
        options,
      }
    );
  }

  public listPremiumPayments(
    params: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<PremiumPaymentResponse>>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<PremiumPaymentResponse>>(`${this.baseUrl}premium-payments`, {
      ...options,
      axiosConfig: { params },
    });
  }
}
