import './styles/style.scss';

import Vue from 'vue';
import { i18nPlugin, useI18n } from 'ah-common-lib/src/i18n';
import { Toast, Icons, Common } from 'ah-common-lib';
import { ThemePlugin, ThemePluginOptions } from 'ah-theme';
import {
  VBTooltipPlugin,
  PopoverPlugin,
  ModalPlugin,
  FormCheckboxPlugin,
  InputGroupPlugin,
  LayoutPlugin,
  OverlayPlugin,
  TabsPlugin,
  DropdownPlugin,
  BadgePlugin,
  TooltipPlugin,
} from 'bootstrap-vue';

import App from './App.vue';

import InfoTooltip from './app/components/common/InfoTooltip.vue';

import { getRouter } from './app/router/router';
import { setupStore } from './app/store';
import { getServices } from './app/services';
import { injectAppGlobals } from './injectGlobals';
import xHedgeCommonPlugin from '@/app/components/common';
import mediaQuery from 'ah-common-lib/src/mediaQuery';
import { tabSyncPlugin } from 'ah-common-lib/src/tabSync';

import XHedgeIconsPlugin from '@/app/icons/iconsPlugin';

import animateClassChange from '@/app/directives/animateClassChange';
import breakpoints from '@/styles/breakpoints.module.scss';
import { EventBus } from 'ah-common-lib/src/eventBus';
import fitText from 'ah-common-lib/src/directives/fitText';
import onIntersect from 'ah-common-lib/src/directives/intersect';
import config from '@/config';
import { OtpConfig, OtpPlugin } from 'ah-common-lib/src/otp';
import { from } from 'rxjs';
import {
  BrandingImageType,
  DocumentExport,
  Notification,
  NotificationEventType,
  NotificationType,
  profileLinkNotificationTypes,
} from 'ah-api-gateways';
import { AhBeneficiariesPlugin, AhBeneficiariesPluginOptions } from 'ah-beneficiaries';
import { AhTradesPlugin, AhTradesPluginOptions } from 'ah-trades';
import { AhReportsPlugin, AhReportsPluginOptions } from 'ah-reports';
import { AhWalletsPlugin, AhWalletsPluginOptions } from 'ah-wallets';
import PrimeVue from 'primevue/config';
import { makeStoreSupportData } from './app/store/supportData';
import { XHedgeEventBusEvents } from './app/constants/events';
import { AhNotificationsPlugin, AhNotificationsPluginOptions } from 'ah-notifications';
import { setAsyncFileDownloadReaction } from 'ah-common-lib/src/listing/listingConfig';
import { AhAppUpdaterPlugin, AhAppUpdaterPluginOptions } from 'ah-app-updater';
import { useNotificationsStore, setNotificationsModuleSupportDataFactory } from 'ah-notifications/src/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useSettingsStore } from './app/store/settingsModule';
import { useAuthStore } from './app/store/authStore';
import { useWalletsStore } from './app/store/walletsModule';
import { setupFeatureFlagStore, useFeatureFlagStore, useTabSyncSafePersistence } from 'ah-common-stores';
import { setOnBehalfOfOptions } from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { AhPaymentsPlugin, AhPaymentsPluginOptions } from 'ah-payments';
import VueGtm from '@gtm-support/vue2-gtm';
import { useToast } from 'ah-common-lib/src/toast';
import FormsPlugin, { FormPluginOptions } from 'ah-common-lib/src/form/FormsPlugin';
import { setupHotjar } from './app/hotjar';

// Pinia must be created before store setup, so the current hooks are called at the right time
const pinia = createPinia();
useTabSyncSafePersistence(pinia);

Vue.use(VBTooltipPlugin);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(OverlayPlugin);
Vue.use(TabsPlugin);
Vue.use(DropdownPlugin);
Vue.use(BadgePlugin);
Vue.use(PopoverPlugin);
Vue.use(PrimeVue);
Vue.use(PiniaVuePlugin);

Vue.use(Icons.IconsPlugin);
Vue.use(XHedgeIconsPlugin);
Vue.use(EventBus);
Vue.use(Common.CommonComponentsPlugin);
Vue.use(FormsPlugin, {
  defaultState: {
    fieldCommon: {
      fieldWrapperClass: 'col col-12',
      formWrapperClass: 'col col-12',
      tooltipComponent: InfoTooltip,
    },
  },
} as FormPluginOptions);
Vue.use(mediaQuery, {
  bands: {
    xsDown: `(max-width: calc(${breakpoints.sm} - 0.02px))`,
    xsOnly: `(max-width: calc(${breakpoints.sm} - 0.02px))`,
    smUp: `(min-width: ${breakpoints.sm})`,
    smDown: `(max-width: calc(${breakpoints.md} - 0.02px))`,
    smOnly: `(min-width: ${breakpoints.sm}) and (max-width: calc(${breakpoints.md} - 0.02px))`,
    mdUp: `(min-width: ${breakpoints.md})`,
    mdDown: `(max-width: calc(${breakpoints.lg} - 0.02px))`,
    mdOnly: `(min-width: ${breakpoints.md}) and (max-width: calc(${breakpoints.lg} - 0.02px))`,
    lgUp: `(min-width: ${breakpoints.lg})`,
    lgDown: `(max-width: calc(${breakpoints.xl} -0.02px))`,
    lgOnly: `(min-width: ${breakpoints.lg}) and (max-width: calc(${breakpoints.xl} -0.02px))`,
    xlUp: `(min-width: ${breakpoints.xl})`,
    xlOnly: `(min-width: ${breakpoints.xl})`,
  },
});
Vue.use(xHedgeCommonPlugin);

Vue.use(animateClassChange);
Vue.use(fitText);
Vue.use(onIntersect);
Vue.use(tabSyncPlugin);
Vue.use(Toast.ToastPlugin, {
  toastOpts: {
    // noAutoHide: true,
    autoHideDelay: 5000,
    position: 'bottom-right',
    keepOnHover: true,
  },
});

Vue.config.errorHandler = function (err: any, vm: any, info: any) {
  if (!config.isProduction || config.showDevTools) {
    // TODO send this log to server if it is a critical error
    /* eslint-disable-next-line no-console */
    console.error('🔥 Error in Vue: \n', err, vm, info);
  }
};

injectAppGlobals();

setNotificationsModuleSupportDataFactory((data) => makeStoreSupportData({ ...data, toast: Vue.toast }));

const services = getServices(true);
const router = getRouter(true);
Vue.prototype.$services = services;

Vue.use(OtpPlugin, {
  refreshOtpRequest: () => from(services.auth.refreshOtp()),
} as OtpConfig);

Vue.use<AhReportsPluginOptions>(AhReportsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    services: {
      trade: services.trade,
      client: services.client,
      wallet: services.wallet,
      rates: services.rates,
      customer: services.customerReference,
    },
  },
});

Vue.use(ThemePlugin, {
  prefix: config.themeColorCSSPrefix,
  onLoad: (val) => {
    const icon = val.logos.find((l: { type: BrandingImageType }) => l.type === BrandingImageType.FAVICON);
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (icon && link) {
      link.href = icon.link;
      link.type = icon.contentType;
    }
  },
  loader: () => {
    const services = getServices();

    return services.branding.getPartnerBrandingData(undefined, {
      options: { errors: { silent: true } },
    });
  },
} as Partial<ThemePluginOptions>);

// i18n
Vue.use(i18nPlugin);
const i18n = useI18n();

Vue.use<AhNotificationsPluginOptions>(AhNotificationsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    toast: Vue.toast,
    store: { useAuthStore, useNotificationsStore },
    services: { documents: services.documents },
    viewNotificationResource: (item: Notification) => {
      if (item.type === NotificationType.SOFTWARE_IMPROVEMENTS) {
        Vue.eventBus.$emit(XHedgeEventBusEvents.SHOW_UPDATES);
      }
    },
  },
  directives: { onIntersect },
});

Vue.use<AhAppUpdaterPluginOptions>(AhAppUpdaterPlugin, {
  router: getRouter(),
  http: services.http,
  beforeUpdateReload() {
    return useAuthStore().waitOnRequests();
  },
  state: {
    installedVersion: window.VUE_APP_VERSION,
  },
});

Vue.use<AhBeneficiariesPluginOptions>(AhBeneficiariesPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    showDevTools: config.showDevTools,
    theme: Vue.theme.val,
    store: { useSettingsStore, useAuthStore },
    i18n,
    services,
    toast: Vue.toast,
  },
});

Vue.use<AhTradesPluginOptions>(AhTradesPlugin, {
  state: {
    theme: Vue.theme.val,
    config,
    mediaQuery: Vue.prototype.$mediaQuery,
    showDevTools: config.showDevTools,
    store: { useWalletsStore, useNotificationsStore, useSettingsStore, useAuthStore, useFeatureFlagStore },
    i18n,
    services,
    toast: Vue.toast,
  },
});

Vue.use<AhWalletsPluginOptions>(AhWalletsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    store: { useWalletsStore, useSettingsStore, useAuthStore, useNotificationsStore },
    i18n,
    services,
    toast: Vue.toast,
  },
});

Vue.use<AhPaymentsPluginOptions>(AhPaymentsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    showDevTools: config.showDevTools,
    store: { useSettingsStore, useNotificationsStore, useAuthStore },
    i18n,
    services,
    toast: Vue.toast,
  },
});

if (config.gtmKey) {
  Vue.use(VueGtm, {
    id: config.gtmKey,
    vueRouter: router,
  });
}

setOnBehalfOfOptions({
  loadClient(clientId) {
    return services.client.getClient(clientId);
  },
});

setAsyncFileDownloadReaction((document: DocumentExport) => {
  useNotificationsStore().triggerFileExportRequestNotification(document);
});

setupFeatureFlagStore({ supportData: makeStoreSupportData() });

setupHotjar();

const app = new Vue({
  i18n,
  router,
  ...services,
  pinia,
  render: (h) => h(App),
});

setupStore().then(() =>
  router.onReady(() => {
    app.$mount('#app');

    useNotificationsStore().notificationEvents.subscribe((event) => {
      if (event.eventType !== NotificationEventType.NOTIFICATION_CREATED) {
        return;
      }
      if (profileLinkNotificationTypes.includes(event.payload.type)) {
        useToast().info(
          '',
          {
            id: event.payload.id,
            title: event.payload.title,
            message: event.payload.message,
            actions: [
              {
                title: 'View new limit',
                class: 'btn-primary btn-small',
                method: () => {
                  if (profileLinkNotificationTypes.includes(event.payload.type)) {
                    router.push('/dashboard/trades/limits-collateral');
                  }

                  useToast().remove(event.payload.id);
                },
              },
            ],
          },
          { noAutoHide: true }
        );
      }
    });
  })
);
