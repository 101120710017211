<script lang="ts" setup>
import { computed, useAttrs } from 'vue';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';

const attrs = useAttrs();

withDefaults(defineProps<{ label?: string; labelClass?: string }>(), {
  label: '',
});

const labelVColAttrs = computed(() => {
  return {
    cols: 4,
    ...mapKeys(attrs, (v, k) => kebabCase(k)),
  } as any;
});

const valueVColAttrs = computed(() => {
  const attrs: any = {};
  ['cols', 'sm', 'md', 'lg', 'xl'].forEach((key) => {
    if (typeof labelVColAttrs.value[key] === 'number' || typeof labelVColAttrs.value[key] === 'string') {
      attrs[key] = 12 - Number(labelVColAttrs.value[key]);
    }
  });
  return attrs;
});
</script>

<template>
  <VRow class="data-row">
    <VCol :class="labelClass" class="label-col semi-bold" v-bind="labelVColAttrs">
      <slot name="label-icon" />
      <slot name="label">{{ label }}:</slot>
    </VCol>
    <VCol class="value-col" v-bind="valueVColAttrs">
      <slot />
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.data-row {
  word-break: break-word;
}
</style>
