export enum PaymentType {
  IMMEDIATE = 'IMMEDIATE',
  SCHEDULED = 'SCHEDULED',
  TRADE_SEND_TO_BENEFICIARY = 'TRADE_SEND_TO_BENEFICIARY',
}

export const paymentTypeLabels: Record<PaymentType, string> = {
  [PaymentType.IMMEDIATE]: 'Immediate',
  [PaymentType.SCHEDULED]: 'Scheduled',
  [PaymentType.TRADE_SEND_TO_BENEFICIARY]: 'Linked to Trade',
};
