import { DocumentExport } from 'ah-api-gateways';

/**
 * Document download reaction setter for all Listing components in an application
 *
 * NOTE: This is meant to override the issue where common-lib components do not expect any global state.
 * We may want to take another, more scalable approach, such as app-wide events, or provide/inject
 */
export function setAsyncFileDownloadReaction(reaction: (document: DocumentExport) => void) {
  asyncFileDownloadReaction = reaction;
}

let asyncFileDownloadReaction: (document: DocumentExport) => void;

export function onAsyncFileDownload(document: DocumentExport) {
  asyncFileDownloadReaction(document);
}
