<script lang="ts" setup>
import { makeFormModel, setState, getChildModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { emailField, passwordField } from 'ah-common-lib/src/form/models';
import { required } from '@vuelidate/validators';
import { tap } from 'rxjs/operators';
import { AuthenticationServiceErrorCodes, SecurityErrorCodes } from 'ah-api-gateways';
import { FieldModel, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { optional, sameAsField } from 'ah-common-lib/src/form/validators';
import { useAuthStore } from '@/app/store/authStore';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { Observable } from 'rxjs';
import { computed, reactive } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useOtp } from 'ah-common-lib/src/otp';
import { useToast } from 'ah-common-lib/src/toast';

const makeChangePasswordFM = () =>
  makeFormModel({
    name: 'changePasswordForm',
    title: 'Change Password',
    fieldType: 'form',
    fields: [
      emailField('email', 'Email', { autocomplete: 'username', hidden: true, required: false }),
      passwordField('oldPassword', 'Old password', false, {
        allowShowPassword: true,
        autocomplete: 'current-password',
      }),
      passwordField('newPassword', 'New password', true),
      passwordField(
        'newPasswordConfirm',
        'Confirm new password',
        false,
        {
          autocomplete: 'new-password',
          errorMessages: {
            required: 'Required field',
          },
        },
        {
          required,
          passwordMatch: optional(sameAsField('newPassword')),
        }
      ),
    ],
  });

const changePasswordFormDef = reactive<FormDefinition>({
  form: makeChangePasswordFM(),
  validation: null,
});

const services = getServices();

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const otp = useOtp();

const toast = useToast();

const anyDirty = computed(() => {
  return (
    changePasswordFormDef.validation?.newPassword.$anyDirty ||
    changePasswordFormDef.validation?.newPasswordConfirm.$anyDirty
  );
});

function saveModelRequest() {
  const passwordFormField = getChildModel(changePasswordFormDef.form, 'oldPassword') as FieldModel;

  changePasswordFormDef.validation?.$touch();
  if (!changePasswordFormDef.validation?.$invalid) {
    setState(passwordFormField, 'errors', []);

    let request: Observable<void> = authStore.userData?.tfaEnabled
      ? otp.requestOtp({
          request: (otpStr) =>
            services.account.changePassword(
              changePasswordFormDef.form.oldPassword,
              changePasswordFormDef.form.newPassword,
              otpStr,
              {
                errors: { silent: true },
              }
            ),
        })
      : services.account.changePassword(
          changePasswordFormDef.form.oldPassword,
          changePasswordFormDef.form.newPassword,
          undefined,
          {
            errors: { silent: true },
          }
        );

    requestManager
      .sameOrCancelAndNew(
        'saveModel',
        request.pipe(
          tap(
            () => {
              updateModel;
              changePasswordFormDef.form = makeChangePasswordFM();
              changePasswordFormDef.validation?.$reset();
              toast.success('Account password updated');
            },
            (error) => {
              if (error?.response?.data?.code === AuthenticationServiceErrorCodes.BAD_CREDENTIALS) {
                setState(passwordFormField, 'errors', [
                  {
                    name: 'wrongLogin',
                    error: 'Password inserted does not match current value',
                  },
                ]);
              } else if (error?.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
                toast.error('The access code is no longer valid. Please try again.');
              }
            }
          )
        )
      )
      .subscribe();
  }
}
</script>

<template>
  <div class="change-password-form">
    <ValidatedForm :fm="changePasswordFormDef.form" :validation.sync="changePasswordFormDef.validation" />
    <div class="form-actions text-right">
      <VButton
        :loading="requestManager.requestStates.saveModel === 'pending'"
        label="Update password"
        class="btn btn-primary"
        @click="saveModelRequest()"
      />
    </div>
    <RouteProtectorModal
      :allowChange="!anyDirty"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
  </div>
</template>
