import { BankAccount, BankAccountType, Beneficiary } from 'ah-api-gateways';

export function prefilledBankAccount(bankAccount: Partial<BankAccount>, beneficiary?: Beneficiary): BankAccount {
  return {
    ...beneficiary,
    ...bankAccount,
    address: {
      ...beneficiary?.address,
      ...bankAccount.address,
    },
    accountType: BankAccountType.SAVINGS,
    countryCode: bankAccount.address!.countryCode!,
    accountHolderName: bankAccount.accountHolderName,
  } as BankAccount;
}

export const euroCountries = [
  'FI',
  'AD',
  'AT',
  'PT',
  'BE',
  'ES',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'PT',
  'MT',
  'MC',
  'NL',
  'PT',
  'SM',
  'SK',
  'SI',
  'ES',
];

export const nonEuroSEPACountries = [
  'BG',
  'HR',
  'CZ',
  'DK',
  'GI',
  'HU',
  'IS',
  'LI',
  'NO',
  'PL',
  'RO',
  'SE',
  'CH',
  'GB',
  // French Colonies
  'GF',
  'GP',
  'MQ',
  'YT',
  'RE',
  'BL',
  'MF',
  'PM',
];

export const SEPACountries = [...euroCountries, ...nonEuroSEPACountries];
