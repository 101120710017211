export enum WebhookType {
  QUOTE_STATE_CHANGE = 'QUOTE_STATE_CHANGE',
  TRADE_STATE_CHANGE = 'TRADE_STATE_CHANGE',
}

export const webhookLabels: Record<WebhookType, string> = {
  [WebhookType.QUOTE_STATE_CHANGE]: 'Quote state change',
  [WebhookType.TRADE_STATE_CHANGE]: 'Trade state change',
};

export function webhookLabelsToHuman(webhookType: WebhookType) {
  return webhookLabels[webhookType];
}

export interface Webhook {
  id: string;
  type: string;
  uri: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CreateWebhookRequest extends Webhook {
  partnerId: string;
}
