import {
  Beneficiary,
  BeneficiaryCategory,
  Client,
  ClientFileCategories,
  FileCategory,
  IndividualFileCategories,
  OnboardingIndividualInfo,
  UploadedFile,
} from 'ah-api-gateways';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { makeStoreSupportData, StoreSupportData } from './supportData';
import { CachedItem } from 'ah-common-lib/src/helpers/cachedItem';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { useAuthStore } from './authStore';
import { defineStore } from 'pinia';

let sd!: StoreSupportData;

export const useIndividualSettingsStore = defineStore('individualSettingsModule', {
  persist: true,
  state: () => {
    if (!sd) {
      sd = makeStoreSupportData();
    }
    return {
      _clientDocuments: new CachedItem<UploadedFile[]>(),
      _individualDocuments: new CachedItem<UploadedFile[]>(),
      _selfBeneficiaries: new CachedItem<Beneficiary[]>(),
      _client: new CachedItem<Client>(),
      _ubos: new CachedItem<OnboardingIndividualInfo[]>(),
    };
  },
  getters: {
    client(state) {
      return state._client.item;
    },

    ubos(state) {
      return state._ubos.item;
    },

    selfBeneficiaries(state) {
      return state._selfBeneficiaries.item ?? [];
    },
    individualDocuments(state) {
      return state._individualDocuments.item;
    },
    clientDocuments(state) {
      return state._clientDocuments.item;
    },
    getIndividualDocument(state) {
      return (category: IndividualFileCategories) =>
        (state._individualDocuments.item ?? []).find((i) => i.category === category);
    },
    getClientDocument(state) {
      return (category: ClientFileCategories) =>
        (state._clientDocuments.item ?? []).find((i) => i.category === category);
    },
  },
  actions: {
    uploadDocument(type: 'client' | 'individual', file: File, category: FileCategory) {
      const authStore = useAuthStore();
      if (type === 'client' && !authStore.isClientUser) {
        throw new Error('only Individuals can access client documents');
      }

      const individual = authStore.loggedInIdentity!;
      const client = individual.client!;

      const request =
        type === 'client'
          ? sd.s.client.uploadDocument(client.id, category as ClientFileCategories, file)
          : sd.s.individual.uploadDocument(individual.id, category as IndividualFileCategories, file);

      return request.pipe(
        mergeMap((uploadStatus) => {
          if (uploadStatus.finished) {
            return (type === 'client' ? this.loadClientDocuments(true) : this.loadIndividualDocuments(true)).then(
              () => uploadStatus
            );
          }

          return Promise.resolve(uploadStatus);
        })
      );
    },
    /**
     * Individual Document Upload Observable
     *
     * Exposed as an observable wrapping an action so document upload updates can be streamed
     */
    uploadIndividualDocument(file: File, category: IndividualFileCategories) {
      return this.uploadDocument('individual', file, category);
    },
    /**
     * Client Document Upload Observable
     *
     * Exposed as an observable wrapping an action so document upload updates can be streamed
     */
    uploadClientDocument(file: File, category: ClientFileCategories) {
      return this.uploadDocument('client', file, category);
    },
    loadIndividualDocuments(force = false) {
      const authStore = useAuthStore();
      const individual = authStore.loggedInIdentity!;

      return CachedItem.loadCachedItem(
        this._individualDocuments,
        sd.s.individual.getDocuments(individual.id, { errors: { silent: true } }),
        force
      );
    },
    loadClientUbos(force = false) {
      const authStore = useAuthStore();
      if (!authStore.isClientUser) {
        return Promise.reject('Only Client have Ubos');
      }
      const clientId = authStore.loggedInIdentity!.client!.id;
      return CachedItem.loadCachedItem(
        this._ubos,
        sd.s.compliance.listUbos(clientId, undefined, { errors: { silent: true } }).pipe(
          mergeMap((response) => {
            if (response.list.length) {
              return of(response.list);
            }
            return of(null);
          })
        ),
        force
      );
    },
    loadClientDocuments(force = false) {
      const authStore = useAuthStore();
      if (!authStore.isClientUser) {
        return Promise.reject('Only Client Individuals can access their client documents');
      }
      const client = authStore.loggedInIdentity!.client!;

      return CachedItem.loadCachedItem(this._clientDocuments, sd.s.client.getDocuments(client.id), force);
    },
    loadSelfBeneficiaries(force = false) {
      const authStore = useAuthStore();
      const query = authStore.isClientUser
        ? {
            clientId: authStore.loggedInIdentity!.client!.id,
            category: BeneficiaryCategory.CLIENT_PERSONAL,
          }
        : {
            category: BeneficiaryCategory.PARTNER_PERSONAL,
          };

      // FIXME we should account for pagination.
      const request = sd.s.beneficiary.listBeneficiaries({ ...query, pageSize: 1000 }).pipe(
        mergeMap((beneficiaries) => {
          if (beneficiaries.list.length) {
            return of(beneficiaries.list);
          }
          return of(null);
        })
      );

      return CachedItem.loadCachedItem(this._selfBeneficiaries, request, force);
    },
    loadClient(force = false) {
      const authStore = useAuthStore();
      if (!authStore.isClientUser) {
        return Promise.reject('only Individuals can access client');
      }

      return CachedItem.loadCachedItem(
        this._client,
        sd.s.client.getClient(authStore.loggedInIdentity!.client!.id),
        force
      );
    },
    setClient(client: Client) {
      const authStore = useAuthStore();
      if (!authStore.isClientUser) {
        return Promise.reject('only Individuals can access client');
      }

      return CachedItem.setCachedItem(this._client, client);
    },
    setSelfBeneficiary(beneficiary: Beneficiary) {
      const currentBeneficiary = this.selfBeneficiaries?.find((b) => b.id === beneficiary.id);
      const beneficiaries = this.selfBeneficiaries;
      if (currentBeneficiary) {
        beneficiaries?.map((b) => (b.id === beneficiary.id ? beneficiary : b));
      } else {
        beneficiaries?.push(beneficiary);
      }
      return CachedItem.setCachedItem(this._selfBeneficiaries, beneficiaries);
    },
    deleteClientDocument(payload: { document: UploadedFile }) {
      const authStore = useAuthStore();
      const individual = authStore.loggedInIdentity!;
      const client = individual.client!;

      return sd.s.client
        .deleteDocument(client!.id, payload.document.id)
        .toPromise()
        .then(() => {
          this.unsetClientDocument({ category: payload.document.category as ClientFileCategories });
        });
    },
    deleteIndividualDocument(payload: { document: UploadedFile }) {
      const authStore = useAuthStore();
      const individual = authStore.loggedInIdentity!;

      return sd.s.individual
        .deleteDocument(individual!.id, payload.document.id)
        .toPromise()
        .then(() => {
          this.unsetIndividualDocument({ category: payload.document.category as IndividualFileCategories });
        });
    },
    setClientDocument(payload: { document: UploadedFile }) {
      CachedItem.setCachedItem(this._clientDocuments, [
        ...(this._clientDocuments.item || []).filter((d) => d.category !== payload.document.category),
        payload.document,
      ]);
    },
    setIndividualDocument(payload: { document: UploadedFile }) {
      CachedItem.setCachedItem(this._individualDocuments, [
        ...(this._individualDocuments.item || []).filter((d) => d.category !== payload.document.category),
        payload.document,
      ]);
    },
    unsetClientDocument(payload: { category: ClientFileCategories }) {
      CachedItem.setCachedItem(this._clientDocuments, [
        ...(this._clientDocuments.item || []).filter((d) => d.category !== payload.category),
      ]);
    },
    unsetIndividualDocument(payload: { category: IndividualFileCategories }) {
      CachedItem.setCachedItem(this._individualDocuments, [
        ...(this._individualDocuments.item || []).filter((d) => d.category !== payload.category),
      ]);
    },
    async [commonStoreActions.onSetup]() {
      sd = makeStoreSupportData();
      this.setupItems();
    },
    async [commonStoreActions.onLogout]() {
      this.setupItems(true);
    },
    async setupItems(clear = false) {
      this._selfBeneficiaries = (!clear && this._selfBeneficiaries) || new CachedItem<Beneficiary[]>();
      this._client = (!clear && this._client) || new CachedItem<Client>();
      this._individualDocuments = (!clear && this._individualDocuments) || new CachedItem<UploadedFile[]>();
      this._clientDocuments = (!clear && this._clientDocuments) || new CachedItem<UploadedFile[]>();
    },
    loadAllItems(force = false) {
      return Promise.all([this.loadSelfBeneficiaries(force), this.loadClient(force)]);
    },
  },
});
