<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { UploadImageIcon, UploadFileIcon, DownloadIcon, TrashcanIcon } from '../../../icons/components';
import VButton from '../../../common/components/VButton.vue';
import { FormActionEvent } from '../../interfaces/form';

const props = defineProps(makeUseFormElementProps<FieldModel, File[]>());

const emit = defineEmits(makeUseFormElementEmits<File[]>());

const input = ref<HTMLInputElement>();

const formElement = useFormElement({
  emit,
  props,
});

const { modelStateRef, emitFormEvent, field, setValue } = formElement;

const maxFilesReached = computed(() => field.value.$model.length >= maxFiles.value);

const fileIcon = modelStateRef('fileIcon');

const maxFiles = modelStateRef('maxFiles', 1);

const isDownloadFile = modelStateRef('download');

const defaultFileName = modelStateRef('defaultFileName');

const maxSize = modelStateRef('maxSize');

const maxSizeFormatted = computed(() => formatBytes(maxSize.value));

function onFileSelection(event: Event) {
  const input = event.target as HTMLInputElement;
  const files = input.files || [];

  if (!Array.isArray(field.value.$model)) {
    setValue([]);
  }

  if (!maxFilesReached.value) {
    const value = [...field.value.$model, ...files];
    setValue([...value.slice(0, 10)]);
  }

  input.value = '';
}

function removeFile(index: number) {
  setValue([...field.value.$model.slice(0, index), ...field.value.$model.slice(index + 1)]);
}

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function showUpload() {
  if (field.value.$model.length < maxFiles.value) {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    input.value?.dispatchEvent(event);
  }
}

function downloadFile() {
  emitFormEvent({
    event: 'form-action',
    action: {
      name: 'download-file',
      label: '',
    },
  } as FormActionEvent);
}
</script>

<template>
  <div class="upload-field">
    <div class="upload-field-inner">
      <div
        :class="{ uploaded: maxFilesReached, 'dash-straight': isDownloadFile }"
        class="upload-box dashed-square dash-color"
      >
        <UploadFileIcon v-if="fileIcon === 'file'" />
        <UploadImageIcon v-else />
        <input ref="input" type="file" :multiple="maxFiles > 1" class="file-select-input" @change="onFileSelection" />
      </div>
      <template v-if="isDownloadFile">
        <div class="items-holder download-file">
          <p>{{ defaultFileName || 'Download file' }}</p>
          <div class="mt-auto">
            <VButton class="btn-secondary icon-btn" @click="downloadFile()">
              <DownloadIcon />
            </VButton>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="items-holder">
          <div class="items">
            <div v-if="defaultFileName && !(field.$model && field.$model.length)" class="item">
              <p class="name default-name">
                {{ defaultFileName }}
              </p>
            </div>
            <div v-for="(file, index) in field.$model" :key="index" class="item">
              <p class="name">
                {{ file.name }}
              </p>
              <VButton class="btn-secondary icon-btn delete-item" @click="removeFile(index)">
                <TrashcanIcon />
              </VButton>
            </div>
          </div>
          <VButton
            v-if="!maxFilesReached"
            class="btn-secondary add-item"
            :disabled="maxFilesReached"
            @click="showUpload"
          >
            Upload file{{ maxFiles > 1 ? 's' : '' }}
          </VButton>
        </div>
      </template>
    </div>
    <small v-if="!isDownloadFile && maxSize" class="max-size">
      Max {{ maxSizeFormatted }} {{ maxFiles > 1 ? 'per file' : '' }}
    </small>
  </div>
</template>

<style lang="scss">
.upload-field {
  .upload-field-inner {
    display: flex;
  }

  .items,
  .item {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .items-holder {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }

  .file-select-input {
    display: none;
  }

  .default-name {
    color: $common-color-grey;
    font-style: italic;
  }

  .upload-box {
    display: flex;
    width: 6.25rem;
    height: 6.25rem;
    margin-right: math.div($padded-space, 4);

    &.dash-color {
      background: linear-gradient(to right, $common-color-grey 50%, rgba(255, 255, 255, 0) 0%),
        linear-gradient($common-color-grey 50%, rgba(255, 255, 255, 0) 0%),
        linear-gradient(to right, $common-color-grey 50%, rgba(255, 255, 255, 0) 0%),
        linear-gradient($common-color-grey 50%, rgba(255, 255, 255, 0) 0%);
      background-position: top, right, bottom, left;
      background-repeat: repeat-x, repeat-y;
      background-size: 8px 1px, 1px 8px;
      background-color: $common-color-white;
      border-radius: 4px;
    }

    &.dash-straight {
      background: linear-gradient(to right, $common-color-grey 100%, rgba(255, 255, 255, 0) 0%),
        linear-gradient($common-color-grey 100%, rgba(255, 255, 255, 0) 0%),
        linear-gradient(to right, $common-color-grey 100%, rgba(255, 255, 255, 0) 0%),
        linear-gradient($common-color-grey 100%, rgba(255, 255, 255, 0) 0%);
      background-position: top, right, bottom, left;
      background-repeat: repeat-x, repeat-y;
      background-size: 8px 1px, 1px 8px;
      background-color: $common-color-white;
      border-radius: 0px;
    }

    &.uploaded {
      background: none;
      border: 1px solid $common-color-grey;
    }

    svg {
      margin: auto;
    }
  }

  &.oversize-file {
    .dash-color {
      background: linear-gradient(to right, $common-color-red 50%, rgba(255, 255, 255, 0) 0%),
        linear-gradient($common-color-red 50%, rgba(255, 255, 255, 0) 0%),
        linear-gradient(to right, $common-color-red 50%, rgba(255, 255, 255, 0) 0%),
        linear-gradient($common-color-red 50%, rgba(255, 255, 255, 0) 0%);
      background-position: top, right, bottom, left;
      background-repeat: repeat-x, repeat-y;
      background-size: 8px 1px, 1px 8px;
      background-color: $common-color-white;
      border-radius: 4px;
    }

    .max-size {
      color: $common-color-red;
    }
  }
}
</style>
