<script setup lang="ts">
import { toDataModel, updateModel, getChildModel, setState, resetForm } from 'ah-common-lib/src/form/helpers';
import { Address, CompanyRegistrationModel } from 'ah-api-gateways';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { FormValidation, FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { cloneDeep, isEqual } from 'lodash';
import { useSettingsStore } from '@/app/store/settingsModule';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
import { makeCompanyDetailsFM, makeDuplicateAddressFM } from '@/app/helpers/registration/forms';
import { mergeMap } from 'rxjs/operators';
import { PayloadErrorData, waitForEntityChange } from 'ah-requests';
import { from } from 'rxjs';
import { handleApiRegistrationError } from '@/app/helpers/registration/requests';
import { useAuthStore } from '@/app/store/authStore';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

const services = getServices();
const requestManager = useRequestManager().manager;
const settingsStore = useSettingsStore();
const authStore = useAuthStore();

const props = defineProps<{ model: Partial<CompanyRegistrationModel> }>();

const emit = defineEmits<{
  (e: 'proceed', value: void): void;
  (e: 'update:model', value: Partial<CompanyRegistrationModel>): void;
  (e: 'update:validation', value: FormValidation<CompanyRegistrationModel>): void;
}>();

const companyRegistrationForm = ref<InstanceType<typeof RegistrationAddressForm> | null>(null);
const tradingRegistrationForm = ref<InstanceType<typeof RegistrationAddressForm> | null>(null);
const apiErrors = ref<PayloadErrorData<CompanyRegistrationModel>>();

const state = reactive<{
  companyAddress: Partial<Address>;
  tradingAddress: Partial<Address>;
  countryValidation: FormValidation | null;
  companyAddressValidation: FormValidation | null;
  tradingAddressValidation: FormValidation | null;
}>({
  companyAddress: {},
  tradingAddress: {},
  countryValidation: null,
  companyAddressValidation: null,
  tradingAddressValidation: null,
});

const companyDetailsFormDef = reactive<FormDefinition>({
  form: makeCompanyDetailsFM(),
  validation: null,
});

const duplicatedAddressFormDef = reactive<FormDefinition>({
  form: makeDuplicateAddressFM(),
  validation: null,
});

const isDuplicatedAddress = computed(() => duplicatedAddressFormDef.form.duplicatedAddress);

const calculatedModel = computed(() => {
  const address = {
    ...props.model.address,
    ...state.companyAddress,
  } as Address;

  let tradingAddress: Address;
  if (isDuplicatedAddress.value) {
    tradingAddress = cloneDeep(address);
    tradingAddress.countryCode = props.model.address?.countryCode!;
  } else {
    tradingAddress = {
      ...props.model.tradingAddress,
      ...state.tradingAddress,
    } as Address;
  }

  const companyDetails = { ...toDataModel(companyDetailsFormDef.form), tradingAddress, address };

  delete companyDetails.kycCompanyName;
  delete companyDetails.userTypes;
  delete companyDetails.noWebsite;

  Object.keys(companyDetails).forEach((k) => {
    if (companyDetails[k] === '') {
      delete companyDetails[k];
    }
  });

  return {
    ...props.model,
    address,
    companyDetails,
    duplicatedAddress: isDuplicatedAddress.value,
  } as Partial<CompanyRegistrationModel>;
});

const validation = computed(() => {
  return {
    $model: calculatedModel.value,
    $invalid: !!(
      companyDetailsFormDef.validation?.$invalid ||
      state.countryValidation?.$invalid ||
      state.companyAddressValidation?.$invalid ||
      (!isDuplicatedAddress.value && state.tradingAddressValidation?.$invalid)
    ),
    $dirty: !!(
      companyDetailsFormDef.validation?.$dirty ||
      state.countryValidation?.$dirty ||
      state.companyAddressValidation?.$anyDirty ||
      state.tradingAddressValidation?.$anyDirty
    ),
  } as FormValidation<any>;
});

const addressApiErrors = computed(() => apiErrors.value?.fields?.companyDetails?.fields?.address);

const tradingAddressApiErrors = computed(() => apiErrors.value?.fields?.companyDetails?.fields?.tradingAddress);

onBeforeMount(() => {
  // Set up entity type options
  const entityType = getChildModel(companyDetailsFormDef.form, 'entityType')!;
  setState(entityType, 'options', []);

  settingsStore.loadEntityTypes(true).then((types) => {
    const typesMapped = types.map((t) => ({ label: t.legalForm, value: t.id }));
    setState(entityType, 'options', typesMapped);
  });
});

watch(
  () => companyDetailsFormDef.form.entityType,
  () => {
    const companyName = getChildModel(companyDetailsFormDef.form, 'name')!;
    const registrationNumber = getChildModel(companyDetailsFormDef.form, 'registrationNumber')!;

    if (companyDetailsFormDef.form.entityType === 'CHARITABLE_TRUST') {
      setState(companyName, 'title', 'Charity name');
      setState(registrationNumber, 'title', 'Charity number');
      setState(registrationNumber, 'required', true);
    } else if (companyDetailsFormDef.form.entityType === 'SOLE_TRADER') {
      setState(companyName, 'title', 'Trading name');
      setState(registrationNumber, 'title', 'VAT number');
      setState(registrationNumber, 'required', false);
    } else {
      setState(companyName, 'title', 'Registered Company name');
      setState(registrationNumber, 'title', 'Company number');
      setState(registrationNumber, 'required', true);
    }
  },
  { immediate: true }
);

function checkWebsite() {
  if (companyDetailsFormDef.form.noWebsite) {
    companyDetailsFormDef.form.website = null;
    resetForm(companyDetailsFormDef.validation!);
  }
  setState(getChildModel(companyDetailsFormDef.form, 'website')!, 'required', !companyDetailsFormDef.form.noWebsite);
  setState(getChildModel(companyDetailsFormDef.form, 'website')!, 'readonly', companyDetailsFormDef.form.noWebsite);
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    checkWebsite();
    setState(event.model, 'errors', []);

    emit('update:model', calculatedModel.value);
  }
}

function submit() {
  delete calculatedModel.value.companyDetails?.address.countryCode;
  apiErrors.value = undefined;

  requestManager
    .sameOrCancelAndNew(
      'createClient',
      services.registration
        .registerClient({
          companyDetails: calculatedModel.value.companyDetails,
        })
        .pipe(
          // Waiting for session update until a client is added to the session data (due to CQRS delay)
          mergeMap(() =>
            waitForEntityChange(
              () =>
                from(
                  useAuthStore()
                    .refreshSession()
                    .then(() => authStore.userData)
                ),
              (session) => !!session?.individual?.client?.id
            )
          )
        )
    )
    .subscribe(
      () => emit('proceed'),
      (e) => {
        apiErrors.value = constructPayloadErrors<CompanyRegistrationModel>(e.response!.data);
        handleApiRegistrationError(e, companyDetailsFormDef.form, companyDetailsFormDef.validation!);
      }
    );
}

watch(
  () => duplicatedAddressFormDef.form.duplicatedAddress,
  () => emit('update:model', calculatedModel.value)
);

watch(
  validation,
  (newVal, oldVal) => {
    if (!isEqual(newVal, oldVal)) {
      emit('update:validation', validation.value);
    }
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    state.companyAddress = cloneDeep(props.model.address ?? {});
    updateModel(companyDetailsFormDef.form, props.model.companyDetails ?? {});

    if (props.model.duplicatedAddress !== undefined) {
      duplicatedAddressFormDef.form.duplicatedAddress = props.model.duplicatedAddress;
    }

    if (!isDuplicatedAddress.value) {
      state.tradingAddress = cloneDeep(props.model.companyDetails?.tradingAddress ?? {});
    }
  },
  { immediate: true }
);
</script>

<template>
  <div x-test-name="company-details-form">
    <h2>Company Details</h2>

    <div>
      <!-- Company Details Form -->
      <ValidatedForm
        class="mb-3"
        :fm="companyDetailsFormDef.form"
        :validation.sync="companyDetailsFormDef.validation"
        @form-event="onFormEvent"
      >
        <template #companyDetailsForm.phoneNumber:after>
          <span class="text-muted">
            <ul class="text-small pl-4 my-1">
              <li>Must include country code</li>
            </ul>
          </span>
        </template>
      </ValidatedForm>

      <!-- Company Address Form -->
      <h4 class="form-title mb-2">Registered Address</h4>
      <RegistrationAddressForm
        ref="companyRegistrationForm"
        :address.sync="state.companyAddress"
        :validation.sync="state.companyAddressValidation"
        :isCountryEditable="false"
        :apiErrors="addressApiErrors"
      />

      <!-- Company Trading Address Form -->
      <h4 class="form-title mb-2">Trading Address</h4>
      <ValidatedForm
        class="duplicated-address-form"
        :fm="duplicatedAddressFormDef.form"
        :validation.sync="duplicatedAddressFormDef.validation"
      />
      <ExpandTransition>
        <RegistrationAddressForm
          ref="tradingRegistrationForm"
          v-if="!isDuplicatedAddress"
          :address.sync="state.tradingAddress"
          :validation.sync="state.tradingAddressValidation"
          :isCountryEditable="!isDuplicatedAddress"
          :apiErrors="tradingAddressApiErrors"
        />
      </ExpandTransition>
    </div>

    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="validation && validation.$invalid">
        Continue
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .vs__dropdown-menu {
    max-height: 14rem;
  }

  .user-type-options {
    .custom-radio {
      width: 40%;
    }
  }

  .background-transparent {
    input {
      background: transparent !important;
      border: none;
      margin-left: -0.4rem;
      text-overflow: ellipsis;
    }
  }

  .duplicated-address-form {
    .field-group-wrapper {
      margin-bottom: 0;
    }
    .field-group-field-label {
      @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    }
    .custom-radio {
      width: 40%;
    }
  }
}

.form-title {
  font-weight: $font-weight-semibold;
}
.info-message {
  padding-top: 0.15rem;
}

.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}
</style>
