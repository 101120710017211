<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { MFAActionType, MFAState, MFAType, UserStatus } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { maskedEmail } from 'ah-common-lib/src/helpers/email';
import config from '@/config';
import { BModal } from 'bootstrap-vue';
import { useAuthStore } from '@/app/store/authStore';
import MFAForm from '@/app/components/session/MFAForm.vue';

const requestManagerParent = useRequestManager();
const requestManager = requestManagerParent.manager;

const authStore = useAuthStore();

const phoneOTP = ref<InstanceType<typeof MFAForm> | null>(null);
const emailOTP = ref<InstanceType<typeof MFAForm> | null>(null);
const expireModal = ref<InstanceType<typeof BModal> | null>(null);

const state = reactive<{
  phoneOtp: string;
  emailOtp: string;
  phoneStatus: MFAState;
  emailStatus: MFAState;
}>({
  phoneOtp: '',
  emailOtp: '',
  phoneStatus: MFAState.NOT_REQUESTED,
  emailStatus: MFAState.NOT_REQUESTED,
});

const emit = defineEmits<{ (e: 'proceed'): void; (e: 'back'): void; (e: 'login-error', error: any): void }>();

const staticOTP = computed(() => config.staticOTP);

const otpPhone = computed(
  () => authStore.loggedInUser?.phoneNumber.substring(authStore.loggedInUser!.phoneNumber.length - 4) || ''
);

const otpEmail = computed(() => (authStore.loggedInUser ? maskedEmail(authStore.loggedInUser.email) : '-'));

const phoneMFA = computed(() => ({ actionType: MFAActionType.REGISTRATION, type: MFAType.SMS_OTP }));

const emailMFA = computed(() => ({ actionType: MFAActionType.REGISTRATION, type: MFAType.EMAIL_OTP }));

const anyPending = computed(
  () =>
    requestManager.anyPending ||
    requestManagerParent.getAllRequestsInState('pending').filter((r) => r.request.startsWith('submitMFA')).length > 0
);

const allSubmitted = computed(() => state.phoneStatus === MFAState.SUCCESS && state.emailStatus === MFAState.SUCCESS);

function refreshCodes() {
  if (state.phoneStatus === MFAState.EXPIRED) {
    phoneOTP.value?.refreshMFA();
  }
  if (state.emailStatus === MFAState.EXPIRED) {
    emailOTP.value?.refreshMFA();
  }
}

function submitRegistration() {
  requestManager.newPromise('redeemTokens', authStore.registrationMfa()).then(
    () => emit('proceed'),
    (e) => emit('login-error', e)
  );
}

function back() {
  emit('back');
}

watch(
  () => [state.phoneStatus, state.emailStatus],
  () => {
    if (state.phoneStatus === MFAState.EXPIRED && state.emailStatus === MFAState.EXPIRED) {
      expireModal.value?.show();
    } else if (state.phoneStatus === MFAState.SUCCESS && state.emailStatus === MFAState.SUCCESS) {
      submitRegistration();
    } else {
      expireModal.value?.hide();
    }
  }
);

if (authStore.loggedInUser?.status === UserStatus.VERIFIED) {
  emit('proceed');
}
</script>

<template>
  <div x-test-name="account-verification-form">
    <h2>Verification</h2>
    <template v-if="authStore.loggedInUser?.status === UserStatus.TEMPORARY">
      <BModal ref="expireModal" modal-class="text-center" centered ok-title="Send new codes" @ok="refreshCodes">
        <template #modal-header>
          <div class="modal-title-wrapper">
            <IconAlertCircle class="icon" />
            <h3>Verification codes expired</h3>
          </div>
        </template>
        <p>
          The 6-digit SMS verification codes we sent to your phone number, ending in {{ otpPhone }}, and email address,
          ending in {{ otpEmail }}, have timed out and their validity has now expired.
        </p>
      </BModal>

      <LoadingOverlay :state="requestManager.requestStates.getRegistrationToken">
        <div class="card-block mb-4">
          <h3>Please confirm your phone number</h3>
          <MFAForm
            ref="phoneOTP"
            class="mb-5"
            :value.sync="state.phoneOtp"
            :status.sync="state.phoneStatus"
            :destination="otpPhone"
            :mfa="phoneMFA"
            :staticMFA="staticOTP"
            auto-request
          />
        </div>

        <div class="card-block">
          <h3>Please confirm your email address</h3>
          <MFAForm
            ref="emailOTP"
            class="mb-5"
            :value.sync="state.emailOtp"
            :status.sync="state.emailStatus"
            :destination="otpEmail"
            :mfa="emailMFA"
            :staticMFA="staticOTP"
            auto-request
          />
        </div>
      </LoadingOverlay>
      <div class="buttons-holder text-center">
        <VButton blurOnClick class="btn-stroked text-center" @click="back" :disabled="anyPending || allSubmitted">
          Back
        </VButton>
      </div>
    </template>
    <LoadingOverlay loadingText="Verifying your account..." state="pending" v-else></LoadingOverlay>
  </div>
</template>

<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0;
  .btn {
    width: 10rem;
  }
}

.modal-title-wrapper {
  margin: 0 auto;
  display: inline-flex;
  align-items: center;

  h3 {
    margin-bottom: 0;
  }

  .icon {
    @include themedTextColor($color-danger);
    width: 1.5rem;
    margin-right: 0.5rem;
    height: auto;
  }
}
</style>
