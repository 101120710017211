<script lang="ts" setup>
import { required } from '@vuelidate/validators';
import { minDate, maxDate, date, ifTest } from 'ah-common-lib/src/form/validators';
import { Validators } from 'ah-common-lib/src/form';
import { makeFormModel, setState, submitForm, getState } from 'ah-common-lib/src/form/helpers';
import { dateField, radioField } from 'ah-common-lib/src/form/models';
import { NonTradeableDays, HedgingInstruments, CutoffTimeModel, FxOperation } from 'ah-api-gateways';
import { TimeFrameDate, DrawdownDate } from '../../models/date';
import { endOfDay, startOfToday, addSeconds, addDays, max, format } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { combineLatest } from 'rxjs';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { stripZoneOffset } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch, onMounted, onUnmounted } from 'vue';
import { useAhTradesState } from '../..';

import { ApiError } from 'ah-requests';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const emit = defineEmits<{
  /** Emits:
   * 'update:drawdownDate' with a DrawdownDate object
   */
  (e: 'update:drawdownDate', payload: DrawdownDate): void;
  (e: 'validation-error', payload: ApiError): void;
}>();

const props = withDefaults(
  defineProps<{
    /**
     * Trade direction
     * May be null to account for synchronous actions, but is expected for proper behaviour
     *
     * When set, date constraints for a Forward drawdown date are updated accordingly
     */
    tradeDirection?: string | null;
    /**
     * DrawdownDate object
     *
     * synchronizable via `update:drawdownDate` or .sync modifier
     */
    drawdownDate?: DrawdownDate | null;
    /**
     * TimeFrameDate object
     *
     * When set, date constraints for a Forward drawdown date are updated accordingly
     */
    timeFrame?: TimeFrameDate | null;
  }>(),
  {
    tradeDirection: null,
    drawdownDate: null,
    timeFrame: null,
  }
);

const shouldDrawdownFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'shouldDrawdownForm',
    fieldType: 'form',
    fields: [
      radioField(
        'fullFlexibilityRequired',
        '',
        [
          {
            label: 'Yes, fully flexible',
            value: true,
          },
          {
            label: 'No, choose conversion dates',
            value: false,
          },
        ],
        {
          defaultValue: true,
          inline: false,
          fieldWrapperClass: 'col col-12 mb-0',
        }
      ),
      radioField(
        'shouldDrawdown',
        '',
        [
          {
            label: 'No flexibility is needed. Convert on settlement date.',
            value: false,
          },
          {
            label: 'Need flexibility to convert after specific date',
            value: true,
          },
        ],
        {
          defaultValue: false,
          inline: false,
          fieldWrapperClass: 'col col-12 mb-2 pl-5',
        }
      ),
    ],
  }),
  validation: null,
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'setUpDateForImmediateSpot') {
      getNonTradeableDays();
    }
  },
});

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const state = reactive<{
  minDrawdownDate: Date;
  checkCutoffTimeTimeout: number | null;
}>({
  minDrawdownDate: stripZoneOffset(addSeconds(startOfToday(), -1)),
  checkCutoffTimeTimeout: null,
});

const drawdownDateFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'drawdownDateForm',
    title: '',
    fieldType: 'form',
    fields: [
      dateField(
        'drawdownDate',
        '',
        {
          fieldType: 'date',
          defaultValue: null,
          firstErrorMessageOnly: true,
          errorMessages: {
            minDate: 'Earliest possible drawdown date is today',
            maxDate: 'Drawdown date must be before the settlement date',
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(required, (a) => !(a instanceof Date)),
          minDate: minDate(() => {
            return state.minDrawdownDate;
          }),
          maxDate: maxDate(() => maxDrawdownDate.value),
          disallowedDate: Validators.disallowStateDates('drawdownDate'),
        }
      ),
    ],
  }),
  validation: null,
});

const clientId = computed(() => {
  return onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id;
});

function touchForms() {
  if (shouldDrawdownFormDef.validation) {
    submitForm(shouldDrawdownFormDef.validation);
  }
  if (drawdownDateFormDef.validation) {
    submitForm(drawdownDateFormDef.validation);
  }
}

function isValid() {
  if (props.drawdownDate?.type === 'windowed') {
    return !drawdownDateFormDef.validation?.$invalid;
  } else {
    return props.drawdownDate?.valid;
  }
}

onMounted(() => {
  if (props.drawdownDate) {
    touchForms();
  }
  window.setTimeout(() => onDateChange());
});

const maxDrawdownDate = computed(() => {
  return props.timeFrame ? stripZoneOffset(endOfDay(addDays(new Date(props.timeFrame!.targetDate), -1))) : undefined;
});

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    window.setTimeout(() => onDateChange());
  }
}

watch(
  () => [props.drawdownDate, drawdownDateFormDef.form],
  () => {
    if (props.drawdownDate && drawdownDateFormDef.form) {
      shouldDrawdownFormDef.form.fullFlexibilityRequired = props.drawdownDate.type === 'flexible';
      shouldDrawdownFormDef.form.shouldDrawdown = props.drawdownDate.type === 'windowed';

      if (!drawdownDateFormDef.form.drawdownDate && props.drawdownDate.value) {
        drawdownDateFormDef.form.drawdownDate = props.drawdownDate.value;
        window.setTimeout(() => {
          if (drawdownDateFormDef.validation) {
            submitForm(drawdownDateFormDef.validation);
          }
        });
      }
    }
  },
  { immediate: true }
);

function getNonTradeableDays() {
  if (props.tradeDirection && drawdownDateFormDef.form && clientId.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'setUpDateForImmediateSpot',
        combineLatest([
          tradeState.services.pricingEngine.getNonTradeableDays(
            HedgingInstruments.FX_SPOT,
            props.tradeDirection,
            onBehalfOfClient?.value?.id
          ),
          tradeState.services.pricingEngine.getCutoffTime(
            props.tradeDirection,
            clientId.value,
            FxOperation.FX_FORWARD,
            undefined,
            onBehalfOfClient?.value?.id
          ),
        ]),
        props.tradeDirection
      )
      .pipe(
        catchError((error) => {
          if (error.response.status === 400) {
            emit('validation-error', error.response.data);
            return of(null);
          } else {
            throw error;
          }
        })
      )
      .subscribe((result: [NonTradeableDays, CutoffTimeModel] | null) => {
        if (!result) return;
        const drawdownDate = drawdownDateFormDef.form!.$fields.find((f) => f.$name === 'drawdownDate');
        setState(drawdownDate!, 'disallowedDates', [...result[0].holidays, ...result[0].weekend]);
        if (state.checkCutoffTimeTimeout) {
          clearTimeout(state.checkCutoffTimeTimeout);
        }

        const checkCutoffTime = () => {
          const minDate = new Date(result[1].firstConversionDate);
          state.minDrawdownDate = minDate;
          setState(drawdownDate!, 'errorMessages', {
            ...(getState(drawdownDate!, 'errorMessages') || {}),
            minDate: `Earliest possible drawdown date is ${format(minDate, 'yyyy-MM-dd')}`,
          });
          if (
            !drawdownDateFormDef.form!.drawdownDate ||
            new Date(drawdownDateFormDef.form!.drawdownDate) < state.minDrawdownDate
          ) {
            drawdownDateFormDef.form!.drawdownDate = state.minDrawdownDate.toISOString();
          }
          state.checkCutoffTimeTimeout = window.setTimeout(checkCutoffTime, 5 * MINUTE);
        };

        checkCutoffTime();
      });
  }
}

watch(() => [props.tradeDirection, drawdownDateFormDef.form], getNonTradeableDays, { immediate: true });

watch(
  () => shouldDrawdownFormDef.form.fullFlexibilityRequired,
  () => {
    const shouldDrawdown = shouldDrawdownFormDef.form.$fields.find((f) => f.$name === 'shouldDrawdown');
    setState(shouldDrawdown!, 'hidden', shouldDrawdownFormDef.form.fullFlexibilityRequired);
  },
  { immediate: true }
);

function onDateChange() {
  const drawdownDate: DrawdownDate = {
    type: 'simple',
    value: null,
    valid: false,
  };

  if (shouldDrawdownFormDef.form.fullFlexibilityRequired) {
    drawdownDate.type = 'flexible';
    drawdownDate.valid = true;
  } else {
    if (shouldDrawdownFormDef.form.shouldDrawdown) {
      drawdownDate.type = 'windowed';
      if (drawdownDateFormDef.validation) {
        drawdownDate.valid = !drawdownDateFormDef.validation.$invalid;
      }
      if (drawdownDateFormDef.form!.drawdownDate) {
        drawdownDate.value = max([
          new Date(drawdownDateFormDef.form!.drawdownDate),
          state.minDrawdownDate,
        ]).toISOString();
      }
    } else {
      drawdownDate.valid = true;
      drawdownDate.type = 'simple';
    }
  }
  if (!isEqual(drawdownDate, props.drawdownDate)) {
    emit('update:drawdownDate', drawdownDate);
  }
}

onUnmounted(() => {
  if (state.checkCutoffTimeTimeout) {
    clearTimeout(state.checkCutoffTimeTimeout);
  }
});

defineExpose({ isValid, touchForms });
</script>

<template>
  <div>
    <p>Do you require the flexibility to Drawdown and early settle your forward?</p>
    <VRow>
      <VCol cols="11">
        <ValidatedForm
          :fm="shouldDrawdownFormDef.form"
          :validation.sync="shouldDrawdownFormDef.validation"
          @form-event="onFormEvent"
        />
      </VCol>
      <VCol>
        <InfoTooltip
          :text="`<p>${$ahTradesState.i18n.t('tooltips.drawdownFlexibleInfo')}</p><p>${$ahTradesState.i18n.t(
            'tooltips.drawdownNoFlexibilityInfo'
          )}</p><p>${$ahTradesState.i18n.t('tooltips.drawdownWindowInfo')}</p>`"
        />
      </VCol>
    </VRow>
    <div class="pl-5" v-if="shouldDrawdownFormDef.form.shouldDrawdown">
      <ValidatedForm
        :fm="drawdownDateFormDef.form"
        :validation.sync="drawdownDateFormDef.validation"
        @form-event="onFormEvent"
        class="mb-3"
      />
    </div>
  </div>
</template>
