<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import UpdatesListing from './UpdatesListing.vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { UpdateTypeDescriptions } from 'ah-api-gateways';
import { reactive, ref } from 'vue';
import { FormModel } from 'ah-common-lib/src/form/interfaces';

const modal = ref<InstanceType<typeof BModal>>();

const updatesTypeForm = reactive<FormModel>(
  makeFormModel({
    name: 'updatesTypeFm',
    fieldType: 'form',
    fields: [
      selectField(
        'updatesType',
        '',
        [
          {
            value: null,
            label: 'All update types',
          },
          ...Object.keys(UpdateTypeDescriptions).map((key) => ({
            value: key,
            label: (UpdateTypeDescriptions as any)[key],
          })),
        ],
        {
          required: false,
          defaultValue: null,
        }
      ),
    ],
  })
);

function show() {
  modal.value?.show();
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal" ref="modal" hide-footer>
      <div class="d-md-flex d-sm-block justify-content-between mb-sm-2">
        <span class="modal-title" v-on-intersect>Updates</span>
      </div>
      <div class="w-50 mb-md-5 mb-sm-2">
        <ValidatedForm :fm="updatesTypeForm" />
      </div>
      <UpdatesListing class="updates-list" :updateTypeSelected="updatesTypeForm.updatesType" />
    </BModal>
    <slot v-bind="{ show }"> </slot>
  </span>
</template>

<style lang="scss" scoped>
.modal-title {
  font-size: 1.5em;
  padding-right: 1.5em;
  font-weight: 600;
}

.updates-list {
  width: 330px;
}
</style>
