<script setup lang="ts">
import BoxGrid from '../common/boxGrid/BoxGrid.vue';
import BoxGridItem from '../common/boxGrid/BoxGridItem.vue';
import GroupMemberBlock from '@/app/components/groups/GroupMemberBlock.vue';
import TargetSearchSelect from '@/app/components/user/TargetSearchSelect.vue';
import { BModal } from 'bootstrap-vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { IndividualGroup, IndividualTargetSelection, IndividualReference, RegistrationStatus } from 'ah-api-gateways';
import { Observable } from 'rxjs';
import { VButton } from 'ah-common-lib/src/common/components';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { computed, PropType, reactive, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { mergeMap } from 'rxjs/operators';
import { textField } from 'ah-common-lib/src/form/models';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';

const props = defineProps({
  group: {
    type: Object as PropType<IndividualGroup | null>,
    default: () => null,
  },
});

const emit = defineEmits({
  'update:group': (_group: IndividualGroup) => true,
});

const authStore = useAuthStore();

const services = getServices();

const toast = useToast();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'saveGroup') {
      save();
    }
  },
}).manager;

const groupNameFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'groupNameForm',
    fieldType: 'form',
    fields: [textField('name', 'Group Name')],
  }),
  validation: null,
});

const modal = ref<InstanceType<typeof BModal>>();

const groupMembers = ref<IndividualReference[]>([]);

const groupService = computed(() => (authStore.isClientUser ? services.clientGroup : services.partnerGroup));

const groupParentId = computed(() => {
  if (authStore.isClientUser) {
    return authStore.loggedInIdentity!.client!.id;
  } else {
    // Partner group endpoints do not use id in path, when called from a partner user
    return undefined;
  }
});

const hasRecipients = computed(() => !!groupMembers.value.length);

const disallowedIndividualIds = computed(() => groupMembers.value.map((r) => r.id));

const individualFilters = computed(() => ({
  status: [RegistrationStatus.PENDING, RegistrationStatus.APPROVED, RegistrationStatus.REJECTED],
}));

function isInRecipientsList(target: IndividualReference) {
  return !!groupMembers.value.find((i) => i.id === target.id);
}

function onTargetSelected(target: IndividualTargetSelection) {
  if (!isInRecipientsList(target.individual)) {
    groupMembers.value.push(target.individual);
  }
}

function deleteTarget(target: IndividualReference) {
  const index = groupMembers.value.findIndex((i) => i.id === target.id);
  if (index > -1) {
    groupMembers.value.splice(index, 1);
  }
}

function resetData() {
  groupNameFormDef.form.name = props.group?.name || '';
  groupMembers.value = [...(props.group?.individuals || [])];
}

function show() {
  modal.value?.show();
  resetData();
}

function save() {
  if (groupNameFormDef.validation) {
    submitForm(groupNameFormDef.validation);
    if (groupNameFormDef.validation.$invalid) {
      return;
    }

    let request!: Observable<IndividualGroup>;

    if (props.group) {
      request = groupService.value.editGroup(
        {
          ...props.group,
          name: groupNameFormDef.form.name,
          individuals: groupMembers.value.map((m) => m.id),
        },
        groupParentId.value
      );
    } else {
      request = groupService.value.createGroup(
        {
          name: groupNameFormDef.form.name,
          individuals: groupMembers.value.map((m) => m.id),
        },
        groupParentId.value
      );
    }

    requestManager
      .new('saveGroup', request)
      .pipe(mergeMap((response) => groupService.value.getGroup(response.id, groupParentId.value)))
      .subscribe((group) => {
        toast.success(`Group has been successfully ${props.group ? 'updated' : 'created'}.`);
        emit('update:group', group);
        modal.value?.hide();
      });
  }
}

function cancel() {
  modal.value?.hide();
}

watch(() => props.group, resetData, { immediate: true });
</script>

<template>
  <span>
    <BModal :title="group ? 'Manage group' : 'Add group'" modal-class="side-modal modal-lg" ref="modal" hide-footer>
      <ValidatedForm :fm="groupNameFormDef.form" :validation.sync="groupNameFormDef.validation" />
      <label class="mt-4">Add users to group</label>
      <TargetSearchSelect
        class="mt-3 mb-3"
        @target-selected="onTargetSelected"
        :types="{
          individual: {
            filters: individualFilters,
            disallowed: disallowedIndividualIds,
          },
        }"
      />
      <div class="card-block recipients-list mb-3" v-if="hasRecipients">
        <template v-if="groupMembers.length">
          <h3 class="recipients-list-title">Users</h3>
          <BoxGrid align-content="start" align-h="start" class="group-targets-holder">
            <BoxGridItem class="group-target-item-holder" :key="individual.id" v-for="individual in groupMembers">
              <GroupMemberBlock class="group-target-item" :individual="individual" @delete="deleteTarget(individual)" />
            </BoxGridItem>
          </BoxGrid>
        </template>
      </div>
      <div class="buttons">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton class="done-btn btn-success" :loading="requestManager.anyPending" @click="save">Save Changes</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<style lang="scss">
.recipients-list-title {
  margin-bottom: math.div($padded-space, 3);
}

.group-targets-holder {
  display: flex;
  margin-bottom: math.div($padded-space, 2);

  &:last-child {
    margin-bottom: 0;
  }

  .group-target-item-holder {
    flex-basis: 33%;
    flex-grow: 0;
    min-width: 200px;

    .group-target-item {
      width: 100%;
    }
  }
}
</style>
