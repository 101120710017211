import { PaymentsService, TradeService, WalletService, BeneficiaryService } from 'ah-api-gateways';
import { Toast } from 'ah-common-lib';
import { MediaQueries } from 'ah-common-lib/src/mediaQuery';
import _Vue, { PluginObject } from 'vue';
import { SettingsStore } from 'ah-common-lib/src/models';
import { AuthStore } from 'ah-common-stores';
import { NotificationsStore } from 'ah-notifications/src/store';
import VueI18n from 'vue-i18n';

let paymentState: AhPaymentState | undefined = undefined;

export interface AhPaymentsPluginOptions {
  state: AhPaymentState;
}

export interface AhPaymentState {
  toast: Toast.Toast;
  mediaQuery: MediaQueries;
  i18n: VueI18n;
  showDevTools: boolean;
  store: {
    useSettingsStore: () => SettingsStore;
    useNotificationsStore: () => NotificationsStore;
    useAuthStore: () => AuthStore;
  };
  services: {
    payments: PaymentsService;
    trade: TradeService;
    wallet: WalletService;
    beneficiary: BeneficiaryService;
  };
}

export const AhPaymentsPlugin = {
  install: function install(Vue: typeof _Vue, options: AhPaymentsPluginOptions) {
    const culledState: AhPaymentState = {
      mediaQuery: options.state.mediaQuery,
      toast: options.state.toast,
      showDevTools: options.state.showDevTools,
      store: options.state.store,
      services: options.state.services,
      i18n: options.state.i18n,
    };
    paymentState = new _Vue({
      data: culledState,
    });
  },
} as PluginObject<AhPaymentsPluginOptions>;

export function useAhPaymentState(): AhPaymentState {
  if (!paymentState) {
    throw 'Payments plugin not instantiated!';
  }
  return paymentState;
}
