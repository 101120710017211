import {
  watch,
  type Ref,
  type UnwrapNestedRefs,
  type UnwrapRef,
  type WatchStopHandle,
  isRef,
  isReactive,
  ref,
  reactive,
} from 'vue';
import VueRouter from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { editRoute } from './route';
import { isEqual } from 'lodash';
import { tryOnBeforeUnmount } from '@vueuse/core';

export enum QueryChangeMethod {
  PUSH = 'PUSH',
  REPLACE = 'REPLACE',
}

export interface UseQueryParamOptions<T> {
  serialize?: (val: T | UnwrapRef<T> | UnwrapNestedRefs<T>) => string | (string | null)[] | undefined;
  deserialize?: (param: string | (string | null)[] | undefined) => T;
  /**
   * Vue router to use
   * If running Querify outside Vue component setup (like in a timeout) a Router should be provided
   */
  router?: VueRouter;
  /**
   * Method to use on ref changes. Defaults to 'replace'
   */
  changeMethod?: QueryChangeMethod;
}

export function useQueryParamRef<T = any>(
  key: string,
  defaultValue: T,
  opts?: UseQueryParamOptions<T>
): Ref<UnwrapRef<T>> {
  const val = ref<T>(defaultValue);
  querifyRef(key, val, opts);

  return val;
}

export function useQueryParamReactive<T extends object>(
  key: string,
  defaultValue: T,
  opts?: UseQueryParamOptions<T>
): UnwrapNestedRefs<T> {
  const val = reactive(defaultValue);
  querifyReactive(key, val, opts);

  return val;
}

function getRouter() {
  try {
    const router = useRouter();
    if (!router) {
      throw null;
    }
    return router;
  } catch (e) {
    throw 'No Router available! If querifying outside component setup, Router should be provided in options.';
  }
}

export function querifyValue<T = any>(key: string, val: UnwrapNestedRefs<T>, opts?: UseQueryParamOptions<T>) {
  if (isRef(val)) {
    return querifyRef<T>(key, val as Ref<UnwrapRef<T>>, opts);
  }
  if (isReactive(val)) {
    return querifyReactive(key, val as UnwrapNestedRefs<object>, opts as any as UseQueryParamOptions<object>);
  }
  throw 'Must use a ref or reactive object!';
}

function querifyRef<T = any>(key: string, refVal: Ref<UnwrapRef<T>>, opts?: UseQueryParamOptions<T>) {
  const router = opts?.router || getRouter();
  const stopWatchers: WatchStopHandle[] = [];
  const serialize = opts?.serialize ?? ((val: UnwrapRef<T>) => val);
  const deserialize = opts?.deserialize ?? ((val) => val as T);
  let timeout: undefined | ReturnType<typeof setTimeout>;

  stopWatchers.push(
    watch(refVal, () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const routeTo = editRoute(router.currentRoute, {
          query: {
            add: {
              [key]: serialize(refVal.value),
            },
          },
        });
        if (!isEqual(routeTo.query![key], router.currentRoute.query[key])) {
          (opts?.changeMethod === QueryChangeMethod.PUSH ? router.push(routeTo) : router.replace(routeTo)).catch(() => {
            // We ignore navigation cancelled issues as they will either be guards or overlapping URL change requests
          });
        }
      });
    })
  );

  stopWatchers.push(
    watch(
      () => router.currentRoute,
      () => {
        const val = router.currentRoute.query[key];
        if (val !== undefined) {
          refVal.value = deserialize(val) as any;
        }
      },
      { immediate: true }
    )
  );

  return () => {
    stopWatchers.forEach((w) => w());
  };
}

function querifyReactive<T extends object>(
  key: string,
  reactiveObj: UnwrapNestedRefs<T>,
  opts?: UseQueryParamOptions<T>
) {
  const router = opts?.router || getRouter();
  const stopWatchers: WatchStopHandle[] = [];
  const serialize = opts?.serialize ?? JSON.stringify;
  const deserialize = opts?.deserialize ?? JSON.parse;
  let timeout: undefined | ReturnType<typeof setTimeout>;

  stopWatchers.push(
    watch(
      reactiveObj,
      () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          const routeTo = editRoute(router.currentRoute, {
            query: {
              add: {
                [key]: serialize(reactiveObj),
              },
            },
          });
          if (!isEqual(routeTo.query![key], router.currentRoute.query[key])) {
            opts?.changeMethod === QueryChangeMethod.PUSH ? router.push(routeTo) : router.replace(routeTo);
          }
        });
      },
      {
        deep: true,
      }
    )
  );

  stopWatchers.push(
    watch(
      () => router.currentRoute,
      () => {
        const val = router.currentRoute.query[key];
        if (val !== undefined) {
          if (typeof val === 'string') {
            Object.assign(reactiveObj, deserialize(val));
          }
        }
      },
      { immediate: true }
    )
  );

  return () => {
    stopWatchers.forEach((w) => w());
  };
}

/**
 * Helper method to setup a query param sync of a ref inside a composable
 *
 * It accepts two refs, one for the queryParam key string, and one for the value to sync, and returns the following behaviour:
 * - QueryParamRef is watched for changes, and:
 *   - if it has a value, synchronization is established using that value as key
 *   - if it does not, synchronization is stopped
 * - synchronization is stopped on component unmount
 */
export function setupComposableQueryParam<F = any>(
  queryParamRef: Ref<string | undefined>,
  valueRef: Ref<UnwrapRef<F>>,
  overrides?: Partial<UseQueryParamOptions<F>>
) {
  const router = useRouter();
  let unsubQP: (() => void) | undefined = undefined;

  tryOnBeforeUnmount(() => unsubQP && unsubQP());
  watch(
    queryParamRef,
    (val, oldVal) => {
      if (val !== oldVal) {
        unsubQP && unsubQP();
        if (val) {
          unsubQP = querifyRef(val as string, valueRef, {
            serialize: (val) => JSON.stringify(val),
            deserialize: (str) => (typeof str === 'string' ? JSON.parse(str) : undefined),
            changeMethod: QueryChangeMethod.REPLACE,
            router,
            ...overrides,
          });
        }
      }
    },
    {
      immediate: true,
    }
  );
}
