<script lang="ts" setup>
import { PartnerTrade, tradeExecutionMethodLabels, formatHedgingInstrument, HedgingInstruments } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate, FULL_DATE_AND_TIME_FORMAT } from 'ah-common-lib/src/helpers/time';
import { computed } from 'vue';

const props = defineProps<{
  trade: PartnerTrade;
}>();

const executionMethod = computed(() => tradeExecutionMethodLabels[props.trade.executionMethod] || '-');

const isVanillaOptions = computed(() => {
  return props.trade.hedgingProduct === HedgingInstruments.VANILLA_OPTIONS;
});
</script>

<template>
  <div>
    <DataRow md="7" class="trade-instrument" label="Instrument">
      {{ formatHedgingInstrument(trade.hedgingProduct) }}
    </DataRow>
    <DataRow md="7" class="trade-original-sell-amount" label="Original Sell Amount">
      {{ trade.sellCurrency }} {{ formatCurrencyValue(trade.sellAmount) }}
    </DataRow>
    <DataRow md="7" class="trade-original-buy-amount" label="Original Buy Amount">
      {{ trade.buyCurrency }} {{ formatCurrencyValue(trade.buyAmount) }}
    </DataRow>
    <DataRow md="7" class="trade-booked-fx-rate" label="Booked FX Rate">
      {{ $ahTradesState.store.useSettingsStore().displayRate(trade.clientRate) }}
    </DataRow>
    <DataRow md="7" class="trade-settlement-date" label="Settlement Date">
      {{ formatDate(trade.settlementDate) }}
    </DataRow>
    <DataRow md="7" class="trade-remaining-sell-amount" label="Remaining Sell Amount">
      {{ trade.sellCurrency }} {{ formatCurrencyValue(trade.remainingSellAmount) }}
    </DataRow>
    <DataRow md="7" class="trade-buy-amount" label="Remaining Buy Amount">
      {{ trade.buyCurrency }} {{ formatCurrencyValue(trade.remainingBuyAmount) }}
    </DataRow>
    <DataRow md="7" class="trade-fx-rate" label="Clearing FX Rate" v-if="!isVanillaOptions">
      {{ $ahTradesState.store.useSettingsStore().displayRate(trade.lpClearingFxRate) }}
    </DataRow>
    <DataRow md="7" class="trade-execution-method" label="Execution Method">
      {{ executionMethod }}
    </DataRow>
    <DataRow md="7" class="trade-execution-trader" label="Execution Trader">
      {{ trade.executedByName }}
    </DataRow>
    <DataRow md="7" class="trade-timestamp" label="Timestamp">
      {{ formatDate(trade.createdAt, FULL_DATE_AND_TIME_FORMAT) }}
    </DataRow>
  </div>
</template>

<style lang="scss" scoped>
.data-row ::v-deep {
  .label-col {
    font-size: $font-size-base;
  }
  .value-col {
    font-size: $font-size-sm;
    min-height: 2rem;
  }
}
</style>
