<script setup lang="ts">
import { Client } from 'ah-api-gateways';
import { ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useRouter } from 'vue-router/composables';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';

const props = defineProps({ clientId: { type: String, required: true } });
const client = ref<Client>();

const requestManager = useRequestManager().manager;
const router = useRouter();
const services = getServices();

watch(() => props.clientId, updateClient, { immediate: true });

function updateClient() {
  requestManager
    .currentOrNew('getClient', services.client.getClient(props.clientId))
    .subscribe((response) => (client.value = response));
}

function exitOBO() {
  const baseUrl = '/admin/activity/clients';
  router.push({ path: `${baseUrl}/${props.clientId}` });
}
</script>

<template>
  <div class="padded-space" id="activity-clients-obo-view">
    <h2 class="mb-2">Clients</h2>
    <div class="obo-wrapper">
      <LoadingOverlay :state="requestManager.requestStates.getClient" show-retry @retry="updateClient">
        <template v-if="client">
          <VButton class="exit-obo-button" @click="exitOBO"> Exit On Behalf of Client </VButton>
          <div class="router-view">
            <div class="menu-header text-md-left text-sm-center">
              you are now acting on behalf of your client, {{ client.name }}
            </div>
            <OnBehalfOf :client="client">
              <RouterView />
            </OnBehalfOf>
          </div>
        </template>
      </LoadingOverlay>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.obo-wrapper {
  position: relative;
  min-height: 75vh;
  min-width: 80vw;
  border: 7px solid;
  @include themedBorderColor($color-widgets-green, $color-widgets-green, '', ' !important');
  border-radius: 10px;

  .menu-header {
    position: relative;
    z-index: 11;
    padding: math.div($padded-space, 3) $padded-space;
    padding-bottom: 0;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    font-size: $font-size-sm;
    @include tablet {
      max-width: 60%;
    }
    @include upToResolution($tabletResolution) {
      padding-top: 3rem;
      margin-bottom: 0;
    }
  }

  .router-view {
    position: relative;
    min-height: 75vh;
    @include themedBackgroundColor($color-box-bg, $color-dark-box-bg);
    ::v-deep {
      .card-block {
        @include themedProp(
          'background-color',
          getColorWithOffsetLightness($color-box-bg, -5%),
          getColorWithOffsetLightness($color-dark-box-bg, 5%)
        );
      }
      .bg-transparent > .box-grid-item,
      .info-block > .box-grid-item {
        background-color: transparent !important;
      }
    }
  }

  ::v-deep .exit-obo-button {
    position: absolute;
    z-index: 12;
    top: -2px;
    right: -4px;
    @include themedBackgroundColor($color-widgets-green, $color-widgets-green);

    &:hover,
    &:focus {
      @include themedBackgroundColor($color-widgets-green, $color-widgets-green, '', ' !important');
    }
  }
}

::v-deep .btn-primary {
  @include themedBorderColor($color-widgets-green, $color-widgets-green, '', ' !important');
  @include themedBackgroundColor($color-widgets-green, $color-widgets-green, '', ' !important');
}
::v-deep .btn-secondary {
  @include themedBorderColor($color-widgets-green, $color-widgets-green, '', ' !important');
}
</style>
