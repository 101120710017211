<script lang="ts" setup>
import { FutureCommissionSettlementSchedule } from 'ah-api-gateways';
import SwipeableBox from '@/app/components/common/SwipeableBox.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, ref, watch } from 'vue';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadfutureCommissionData') {
      loadData();
    }
  },
}).manager;

const mediaQuery = useMediaQuery();

const services = getServices();

const data = ref<FutureCommissionSettlementSchedule>();

const headerKeys = ref<string[]>([]);

const index = ref(0);

const STEP = 4;

const isStackedView = computed(() => mediaQuery.is('smDown'));

const max = computed(() => headerKeys.value.length - 1);

function loadData() {
  requestManager
    .sameOrCancelAndNew('loadfutureCommissionData', services.trade.getFutureCommissionReport())
    .subscribe((response) => {
      headerKeys.value = [];
      data.value = response;
      headerKeys.value = Object.keys(data.value?.totalCommissionsByCurrency);
    });
}

watch(isStackedView, () => (index.value = 0), { immediate: true });

loadData();

defineExpose({ loadData: loadData });
</script>

<template>
  <div>
    <LoadingIcon v-if="requestManager.requestStates.loadfutureCommissionData === 'pending'" class="svg" />
    <div v-else-if="data">
      <div v-if="data.totalCommissionsByCurrency">
        <!-- Table headers construction -->
        <!-- We use a swipeable box for mobile screens -->
        <VRow class="row">
          <VCol class="col-md-2 col-sm-4"> <label> Settlement date </label> </VCol>
          <template v-if="!isStackedView && headerKeys.length <= STEP">
            <VCol class="col-2 text-right" v-for="head in headerKeys" :key="head">
              <label> {{ head }} </label>
            </VCol>
          </template>
          <VCol class="col-md-8" v-else-if="!isStackedView">
            <SwipeableBox :index.sync="index" :max="max" :STEP="STEP" showArrows>
              <template #content>
                <VRow alighn-h="start">
                  <VCol v-for="i in STEP" :key="i" class="text-right">
                    <label>
                      {{ headerKeys[index + i - 1] }}
                    </label>
                  </VCol>
                </VRow>
              </template>
            </SwipeableBox>
          </VCol>
          <VCol class="col-4" v-else>
            <SwipeableBox :index.sync="index" :max="max" showArrows>
              <template #content>
                <label class="m-0">
                  {{ headerKeys[index] }}
                </label>
              </template>
            </SwipeableBox>
          </VCol>
          <VCol class="col-md-2 col-sm-4 column-total text-right mr-3">
            <label> Total (GBP) </label>
          </VCol>
        </VRow>
        <hr />

        <!-- Table list content construction -->
        <div class="table-content">
          <VRow class="row" v-for="row in data.commissionsBySettlementDate" :key="row.settlementDate">
            <VCol class="col-md-2 col-sm-4">
              <label> {{ row.settlementDate }} </label>
            </VCol>
            <template v-if="!isStackedView && headerKeys.length <= STEP">
              <VCol class="col-2 text-right" v-for="head in headerKeys" :key="head">
                <label v-if="row['commissionsByCurrency'][head]">
                  {{ formatCurrencyValue(row.commissionsByCurrency[head].totalPartnerProfit) }}
                </label>
                <label v-else> - </label>
              </VCol>
            </template>
            <VCol class="col-md-8" v-else-if="!isStackedView">
              <SwipeableBox :index.sync="index" :max="max" :STEP="STEP">
                <template #content>
                  <VRow>
                    <VCol v-for="i in STEP" :key="i" class="text-right">
                      <label v-if="row['commissionsByCurrency'][headerKeys[index + i - 1]]">
                        {{
                          formatCurrencyValue(row.commissionsByCurrency[headerKeys[index + i - 1]].totalPartnerProfit)
                        }}
                      </label>
                      <label v-else-if="index + i <= headerKeys.length"> - </label>
                    </VCol>
                  </VRow>
                </template>
              </SwipeableBox>
            </VCol>
            <VCol class="col-4" v-else>
              <SwipeableBox :index.sync="index" :max="max">
                <template #content>
                  <label v-if="row['commissionsByCurrency'][headerKeys[index]]">
                    {{ formatCurrencyValue(row.commissionsByCurrency[headerKeys[index]].totalPartnerProfit) }}
                  </label>
                  <label v-else> - </label>
                </template>
              </SwipeableBox>
            </VCol>
            <VCol class="col-md-2 col-sm-4 column-total text-right">
              <label v-if="row.equivalentTotalValues">
                {{ formatCurrencyValue(row.equivalentTotalValues.totalPartnerProfit) }}
              </label>
            </VCol>
          </VRow>
        </div>
        <hr />

        <!-- Table totals construction -->
        <VRow>
          <VCol class="col-md-2 col-sm-4">
            <label> Total (in currency) </label>
          </VCol>
          <template v-if="!isStackedView && headerKeys.length <= STEP">
            <VCol class="col-2 text-right" v-for="(row, rowKey) in data.totalCommissionsByCurrency" :key="rowKey">
              <label v-if="row.totalPartnerProfit">
                {{ formatCurrencyValue(row.totalPartnerProfit) }}
              </label>
              <label v-else> - </label>
            </VCol>
          </template>

          <VCol class="col-md-8" v-else-if="!isStackedView">
            <SwipeableBox :index.sync="index" :max="max" :STEP="STEP">
              <template #content>
                <VRow>
                  <VCol v-for="i in STEP" :key="i" class="text-right">
                    <label
                      v-if="
                        data &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]] &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]].totalPartnerProfit
                      "
                    >
                      {{
                        formatCurrencyValue(
                          data.totalCommissionsByCurrency[headerKeys[index + i - 1]].totalPartnerProfit
                        )
                      }}
                    </label>
                    <label v-else-if="index + i <= headerKeys.length"> - </label>
                  </VCol>
                </VRow>
              </template>
            </SwipeableBox>
          </VCol>

          <VCol class="col-4" v-else>
            <SwipeableBox :index.sync="index" :max="max">
              <template #content>
                <label
                  v-if="
                    data.totalCommissionsByCurrency[headerKeys[index]] &&
                    data.totalCommissionsByCurrency[headerKeys[index]].totalPartnerProfit
                  "
                >
                  {{ formatCurrencyValue(data.totalCommissionsByCurrency[headerKeys[index]].totalPartnerProfit) }}
                </label>
                <label v-else> - </label>
              </template>
            </SwipeableBox>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="col-md-2 col-sm-4">
            <label> Total (GBP equiv) </label>
          </VCol>
          <template v-if="!isStackedView && headerKeys.length <= STEP">
            <VCol class="col-2 text-right" v-for="(row, rowKey) in data.totalCommissionsByCurrency" :key="rowKey">
              <label v-if="row.equivalentValues.totalPartnerProfit">
                {{ formatCurrencyValue(row.equivalentValues.totalPartnerProfit) }}
              </label>
              <label v-else> - </label>
            </VCol>
          </template>

          <VCol class="col-md-8" v-else-if="!isStackedView">
            <SwipeableBox :index.sync="index" :max="max" :STEP="STEP">
              <template #content>
                <VRow>
                  <VCol v-for="i in STEP" :key="i" class="text-right">
                    <label
                      v-if="
                        data &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]] &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]].equivalentValues.totalPartnerProfit
                      "
                    >
                      {{
                        formatCurrencyValue(
                          data.totalCommissionsByCurrency[headerKeys[index + i - 1]].equivalentValues.totalPartnerProfit
                        )
                      }}
                    </label>
                    <label v-else-if="index + i <= headerKeys.length"> - </label>
                  </VCol>
                </VRow>
              </template>
            </SwipeableBox>
          </VCol>

          <VCol class="col-4" v-else>
            <SwipeableBox :index.sync="index" :max="max">
              <template #content>
                <label
                  v-if="
                    data.totalCommissionsByCurrency[headerKeys[index]] &&
                    data.totalCommissionsByCurrency[headerKeys[index]].equivalentValues.totalPartnerProfit
                  "
                >
                  {{
                    formatCurrencyValue(
                      data.totalCommissionsByCurrency[headerKeys[index]].equivalentValues.totalPartnerProfit
                    )
                  }}
                </label>
                <label v-else> - </label>
              </template>
            </SwipeableBox>
          </VCol>
          <VCol class="col-md-2 col-sm-4 column-total text-right mr-4">
            <label v-if="data.equivalentTotalValues.totalPartnerProfit">
              {{ formatCurrencyValue(data.equivalentTotalValues.totalPartnerProfit) }}
            </label>
            <label v-else> - </label>
          </VCol>
        </VRow>
        <span class="chart-label">GBP equivalents are based on the current exchange rate and subject to change.</span>
      </div>

      <!-- No content found message -->
      <div v-else>No data found</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-content {
  position: relative;
  @include upToResolution($tabletResolution) {
    max-height: calc(30px * 10);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.column-total {
  position: absolute;
  right: 0;
}

.chart-label {
  font-size: 13px;
  opacity: 0.5;
}
</style>
