<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { computed, ref } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import config from '@/config';
import { getServices } from '@/app/services';
import { useToast } from 'ah-common-lib/src/toast';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import UsernameChangeEditor from '../common/UsernameChangeEditor.vue';

const authStore = useAuthStore();
const services = getServices();
const toast = useToast();
const requestManager = useRequestManager().manager;

const setupUsernameModal = ref();
const currentPhoneNumber = computed(() => authStore.loggedInUser?.phoneNumber ?? '');
const currentPhoneLastDigits = computed(() =>
  currentPhoneNumber.value.substring(Math.max(0, currentPhoneNumber.value.length - 3))
);
const staticOTP = computed(() => config.staticOTP);
const tfaEnabled = computed(() => !!authStore.userData?.tfaEnabled);
const isUsernameSetupModalDismissed = computed(() => authStore.userData?.usernameSetupModalDismissed);

const show = () => {
  setupUsernameModal.value?.show();
};

const hide = (showToast = false) => {
  requestManager
    .sameOrCancelAndNew(
      'dismissUsernameModal',
      services.account.dismissUsernameSetupModal(!isUsernameSetupModalDismissed.value)
    )
    .subscribe(() => {
      setupUsernameModal.value?.hide();
      if (showToast) {
        toast.info('', {
          message: 'You can set up your username in <strong>Account Settings</strong>.  You can do it anytime.',
          isHtml: true,
          title: '',
        });
      }
    });
};

const handleSuccess = () => {
  setupUsernameModal.value?.hide();
};

defineExpose({
  show,
  hide,
});
</script>

<template>
  <BModal
    modal-class="username-modal"
    ref="setupUsernameModal"
    centered
    no-stacking
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hide-header-close
  >
    <div class="username-setup-modal">
      <div>
        <span class="new-label">New</span>
        <p class="new-username-text">We have introduced <span>usernames!</span></p>
        <p class="mb-4 sub-text">
          Usernames are now used as unique identifiers for logging in, instead of your email address. Please choose a
          distinctive and memorable username.
        </p>
      </div>
      <UsernameChangeEditor
        :current-username="authStore.loggedInUser?.username"
        :tfa-enabled="tfaEnabled"
        :static-OTP="staticOTP"
        :current-phone-last-digits="currentPhoneLastDigits"
        @success="handleSuccess"
        @cancel="() => hide(true)"
      >
        <template #actions="{ isInvalid, onSubmit }">
          <p class="text-center mb-0">
            <VButton
              :loading="requestManager.requestStates.dismissUsernameModal === 'pending'"
              @click="() => hide(true)"
              class="btn-secondary mr-3"
            >
              I'll do it later
            </VButton>
            <VButton :disabled="isInvalid" :loading="requestManager.anyPending" @click="onSubmit">
              Save Username
            </VButton>
          </p>
        </template>
      </UsernameChangeEditor>
    </div>
  </BModal>
</template>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 38rem;
  .modal-content {
    padding: 15px;
    .modal-header {
      display: none;
    }
  }
}

.username-setup-modal {
  .new-label {
    padding: 5px 10px;
    background-color: $x-ui-light-tag-green-bg-hover;
    border-radius: 30px;
    color: $common-color-black;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
  }

  .new-username-text {
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    color: $common-color-black;
    margin-top: 10px;
    span {
      font-size: $font-size-lg;
      color: $x-ui-light-fg-interactive;
    }
  }

  .sub-text {
    font-weight: $font-weight-regular;
  }
}
</style>
