<script setup lang="ts">
/**
 * Replaces DynamicTable from common-lib, including some default templates for sorting and toggling
 *
 * MUST be imported into Vue as "DynamicTable" to effectively replace the base implementation
 */

import BaseDynamicTable, { dynamicTableProps } from 'ah-common-lib/src/common/components/listing/DynamicTable.vue';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import { ref, computed, useAttrs } from 'vue';

const props = defineProps({
  ...dynamicTableProps,
  withRowSubView: {
    type: [Boolean, String, Function],
    default: false,
  },
  flush: {
    type: [Boolean, String],
    default: true,
  },
  /**
   * Breakpoint at which the table converts to a stacked view
   *
   * Also affects the application of the `table-stacked` class
   */
  breakpoint: {
    type: String,
    default: '900px',
  },
});

const expandedRows = ref<any[]>([]);

const tableFields = computed(() => {
  const fields: TableFieldDefinition[] = [...((useAttrs().fields as any) || [])];

  if (props.withRowDetails !== false || props.withRowSubView !== false) {
    fields.push({
      header: '',
      key: 'xHedgeSelectableToggle',
      class: 'toggle-col',
      sortable: false,
    });
  }

  return fields;
});

function xHedgeRowClass(item: any, type: any) {
  const classes = [];
  if (props.rowClass) {
    classes.push(typeof props.rowClass === 'function' ? props.rowClass(item, type) : props.rowClass);
  }

  if (props.selectedItems.includes(item[props.primaryKey])) {
    classes.push('selected-row');
  }

  if (hasDetailsOpen(item)) {
    classes.push('b-table-has-details');
  }

  if (hasRowDetails(item)) {
    classes.push('with-details');
  }

  if (hasRowSubView(item)) {
    classes.push('with-subview');
  }

  return classes.join(' ');
}

function hasDetailsOpen(item: any) {
  return item[props.primaryKey] && expandedRows.value.includes(item[props.primaryKey]);
}

function hasRowDetails(item: any) {
  return typeof props.withRowDetails === 'function' ? props.withRowDetails(item) : props.withRowDetails !== false;
}

function hasRowSubView(item: any) {
  return typeof props.withRowSubView === 'function' ? props.withRowSubView(item) : props.withRowSubView !== false;
}
</script>

<template>
  <BaseDynamicTable
    :class="[
      'xh-dynamic-table',
      {
        'row-clickable': withRowDetails,
        flush,
        'table-stacked':
          !withFlexScroll && !withSimpleScroll && $mediaQuery.expr(`(max-width: calc(${breakpoint} - 0.02px))`),
      },
    ]"
    v-on="$listeners"
    v-bind="$attrs"
    :fields="tableFields"
    :dataLoadState="dataLoadState"
    :data="data"
    :withFlexScroll="withFlexScroll"
    :withSimpleScroll="withSimpleScroll"
    :animateCols="animateCols"
    :itemsLabel="itemsLabel"
    :primaryKey="primaryKey"
    :withRowDetails="withRowDetails"
    :bordered="bordered"
    :customSearch="customSearch"
    :selectedItems="selectedItems"
    thead-class="xh-sortable"
    :rowClass="xHedgeRowClass"
    :expandedRows.sync="expandedRows"
    :breakpoint="breakpoint"
  >
    <!-- header with sort -->
    <template #head()="{ fieldData, sort, sortBy, sortDesc }">
      <span @click="fieldData.sortable && sort()" class="table-title-wrapper">
        <span :class="['table-title', { 'with-sort': fieldData.sortable }]">
          <span class="title-inner">
            <slot :name="`headInner(${fieldData.key})`">{{ fieldData.header }} </slot>
            <InfoTooltip
              v-if="fieldData.info"
              class="th-tooltip search-info-icon"
              :text="$ahTradesState.i18n.t(fieldData.labelInfo)"
            />
          </span>
          <div
            class="sort-icon"
            :class="{ selected: (sortBy === fieldData.key || sortBy === fieldData.sortKey) && sortBy }"
            v-if="fieldData.sortable"
          >
            <IconFilter v-if="(sortBy === fieldData.key || sortBy === fieldData.sortKey) && sortBy && sortDesc" />
            <IconFilterUp v-else />
          </div>
        </span>
      </span>
    </template>

    <!-- cell with selectable item chevron -->
    <template #cell(xHedgeSelectableToggle)="{ item, isSelected }">
      <span class="selectable-icon-holder">
        <IconChevronRight v-if="hasRowSubView(item)" :class="['selectable-icon', { open: isSelected }]" />
        <IconChevronDown v-if="hasRowDetails(item)" :class="['selectable-icon', { open: hasDetailsOpen(item) }]" />
      </span>
    </template>

    <template #tableNoSearchResultsState>
      <p class="no-results">No {{ itemsLabel }} found for the filters applied</p>
    </template>
    <template #tableNoResultsState>
      <p class="no-results">No {{ itemsLabel }} found.</p>
    </template>

    <!-- slots from parent -->
    <template v-for="(_, name) in $scopedSlots" #[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </BaseDynamicTable>
</template>

<style lang="scss" scoped>
.xh-dynamic-table ::v-deep {
  .selectable-icon-holder {
    display: block;

    .selectable-icon {
      width: 1.5em;
      height: 1.5em;
      transition: transform 0.6s;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .table-title-wrapper {
    display: block;
    width: 100%;

    .table-title {
      display: inline-block;
      max-width: 100%;
      position: relative;

      &.with-sort {
        padding-right: 1.5em;
        margin-right: 0.3em;

        .sort-icon {
          @include themedProp('color', getColorHSLA($color-primary, 50%), getColor($color-dark-text));
          @include themedBackgroundColor($color-main-bg);
          border-radius: 50%;
          text-align: center;
          display: inline-block;
          width: 1.3em;
          opacity: 0.3;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -0.85em;
          height: 1.3em;

          svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &.selected {
            opacity: 1;
          }
        }
      }

      .title-inner {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
      }

      .th-tooltip {
        display: initial;
      }
    }
  }

  .table-list-standin {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    min-height: 6em;

    h2 {
      font-size: 1.2em;
    }

    .not-found {
      height: 1.4em;
      width: 1.4em;
      display: inline-block;
      position: relative;
      top: -0.7em;
      margin-right: 0.6em;
    }
  }

  &.loading-no-items .b-table-bottom-row {
    height: 6em;
  }

  &.flush .table-wrapper {
    margin: -$table-cell-padding;
  }

  .no-results {
    font-size: 14px;
    width: 100%;
    text-align: left;
  }

  .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    width: 100%;

    .download-button {
      margin-left: auto;
      margin-top: 0;
    }

    .pagination-wrapper {
      margin-right: auto;

      ul.pagination {
        float: left;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: math.div($padded-space, 2);

        > li {
          display: inline-block;

          &.active {
            > button,
            > span {
              cursor: default;
              border-radius: 100%;
              @include themedProp(
                'background',
                getColorWithAlpha($color-primary, 0.5),
                getColorWithAlpha($color-dark-primary, 0.5)
              );
              @include themedProp(
                'color',
                getColorWithOffsetLightness($color-primary, -10%),
                getColorWithOffsetLightness($color-dark-primary, 20%)
              );
            }
          }

          > button,
          > span {
            display: block;
            border: none;
            background: none;
            padding: 0 0.5em;
            border-radius: 50px;
            @include themedTextColor($color-text-secondary);

            &:focus {
              outline: none;
            }
          }

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
