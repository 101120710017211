<script setup lang="ts">
import { computed, reactive } from 'vue';
import { ComplianceStatus, OnboardingStatusReport } from 'ah-api-gateways';
import { sum } from 'lodash';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadCounts') {
      loadCounts();
    }
  },
});

const state = reactive<{
  counts: OnboardingStatusReport[] | null;
}>({
  counts: null,
});

function loadCounts() {
  requestManager.manager.new('loadCounts', getServices().client.getClientOnboardingReportStatus()).subscribe((r) => {
    state.counts = r.summary;
  });
}

const total = computed(() => {
  if (!state.counts) return 0;
  return sum(state.counts.filter((c) => !!c.status).map((c) => c.total));
});

function findCount(status: ComplianceStatus) {
  if (!state.counts) return 0;
  return state.counts.find((c) => c.status === status)?.total || 0;
}

const statusItems = computed(() => [
  { label: 'Pending Questionnaire', count: findCount(ComplianceStatus.PENDING), class: 'pending' },
  {
    label: 'Incomplete',
    count: findCount(ComplianceStatus.TERMS_AND_CONDITIONS) + findCount(ComplianceStatus.DOCUMENTS_REQUIRED),
    class: 'incomplete',
  },
  { label: 'In Review', count: findCount(ComplianceStatus.SUBMITTED), class: 'in-review' },
  { label: 'Approved', count: findCount(ComplianceStatus.APPROVED), class: 'verified' },
  { label: 'Rejected', count: findCount(ComplianceStatus.REJECTED), class: 'rejected' },
  { label: 'Suspended', count: findCount(ComplianceStatus.SUSPENDED), class: 'suspended' },
  {
    label: "Suspended - T&C's outstanding",
    count: findCount(ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED),
    class: 'tc-outstanding',
  },
  { label: 'Deactivated', count: findCount(ComplianceStatus.DEACTIVATED), class: 'deactivated' },
  {
    label: 'Updated T&C Outstanding',
    count: findCount(ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS),
    class: 'tc-updated',
  },
]);

loadCounts();
</script>

<template>
  <div class="card-block onboarding-statuses-wrapper">
    <LoadingOverlay
      variant="box"
      :state="requestManager.manager.requestStates.loadCounts"
      show-retry
      @retry="loadCounts"
    >
      <div class="onboarding-statuses" v-if="state.counts">
        <div class="status-item total">
          <h3 class="status-label">Total:</h3>
          <span class="status-value">{{ total }}</span>
        </div>
        <div class="status-grid">
          <div v-for="status in statusItems" :key="status.label" :class="['status-item', status.class]">
            <div class="status-content">
              <span class="status-dot" :aria-label="`${status.label} status indicator`" />
              <h4 class="status-label">{{ status.label }}:</h4>
              <span class="status-value">{{ status.count }}</span>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-statuses-wrapper {
  .onboarding-statuses {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .status-item {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .status-dot {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        margin-right: 0.75rem;
      }

      .status-label {
        font-weight: $font-weight-semibold;
        margin: 0;
        font-size: 1rem;
      }

      .status-value {
        font-weight: $font-weight-bold;
        margin-left: auto;
        font-size: 1.1rem;
      }

      &.total {
        font-size: 1.5em;
      }
    }

    .status-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1rem;

      .status-item {
        flex-direction: column;
        align-items: flex-start;

        .status-content {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .status-value {
          margin-left: auto;
        }
      }
    }
  }
}

// Status-specific styles
.pending .status-dot {
  @include themedBackgroundColor($color-blue-highlight);
}
.incomplete .status-dot {
  @include themedBackgroundColor($color-orange-highlight);
}
.in-review .status-dot {
  @include themedBackgroundColor($color-yellow-highlight);
}
.verified .status-dot {
  @include themedBackgroundColor($color-green-highlight);
}
.rejected .status-dot {
  @include themedBackgroundColor($color-danger);
}
.suspended .status-dot {
  @include themedBackgroundColor($color-pink-highlight);
}
.tc-outstanding .status-dot {
  @include themedBackgroundColor($color-soft-red-bg);
}
.deactivated .status-dot {
  @include themedBackgroundColor($color-lavender-highlight);
}
.tc-updated .status-dot {
  @include themedBackgroundColor($color-lavender-highlight-light);
}

@media (max-width: 768px) {
  .onboarding-statuses-wrapper {
    .status-grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
