export enum PaymentPurpose {
  CROSS_BORDER_SERVICE_TRADE = 'CROSS_BORDER_SERVICE_TRADE',
  CROSS_BORDER_INDIVIDUAL_REMITTANCE = 'CROSS_BORDER_INDIVIDUAL_REMITTANCE',
  CROSS_BORDER_CAPITAL_TRANSFER = 'CROSS_BORDER_CAPITAL_TRANSFER',
}

export const paymentPurposeLabels: Record<PaymentPurpose, { label: string }> = {
  [PaymentPurpose.CROSS_BORDER_SERVICE_TRADE]: {
    label: 'Cross-border service trade',
  },
  [PaymentPurpose.CROSS_BORDER_INDIVIDUAL_REMITTANCE]: {
    label: 'Cross-border individual remittance',
  },
  [PaymentPurpose.CROSS_BORDER_CAPITAL_TRANSFER]: {
    label: 'Cross-border capital transfer',
  },
};
