/**
 * Token default expiry time in minutes
 */
export const DEFAULT_OTP_EXPIRY_TIME = 5;

export const RESEND_CODE_TIMEOUT_DURATION = 60 * 1000;

export const otpMessage = (destination?: string, timeToExpire = DEFAULT_OTP_EXPIRY_TIME, email = false) => {
  return `A 6-digit SMS verification code has been sent to your ${email ? 'email address' : 'phone number'}${
    destination ? ` ending in ${destination}` : ''
  }.<br />
  The code will be valid for ${timeToExpire} minute${timeToExpire > 1 ? 's' : ''}.`;
};

export type OTPRequestStatus = 'unsent' | 'sending' | 'sent' | 'invalid' | 'expired' | 'valid';
