<script lang="ts">
// FIXME importing a composition API component with multiple scripts from a Class Component fails, need to ignore TS checks
// @ts-ignore
import DynamicTable from './DynamicTable.vue';
import { ref, onBeforeUnmount, provide, inject } from 'vue';

export const TABLE_AGGREGATOR_PROVIDE_KEY = Symbol('tableAggregator');

export interface TableAggregatorRegistration {
  registerList: (list: any) => void;
  deregisterList: (list: any) => void;
}

export function useInjectedTableAggregator() {
  return inject<TableAggregatorRegistration | null>(TABLE_AGGREGATOR_PROVIDE_KEY, null);
}
</script>

<script setup lang="ts">
import { debounce, DebouncedFunc } from 'lodash';

/**
 * Table aggregator compontent
 *
 * Meant for usage alongside multiple <Listing> components.
 * Tables will stack vertically and scroll simultaneously.
 */

defineProps({
  withFooter: {
    type: [Boolean, String],
    default: true,
  },
});

const onScroll = ref<DebouncedFunc<(event: any) => void>>(debounce(handleScroll, 0));

const tables = ref<InstanceType<typeof DynamicTable>[]>([]);

provide<TableAggregatorRegistration>(TABLE_AGGREGATOR_PROVIDE_KEY, {
  registerList: (list: any) => {
    // FIXME - 'instanceof DynamicTable' is not working
    if (list) {
      // Setting 'as any' to avoid recursive type casting issues in typescript
      tables.value.push(list as any);
    }
  },
  deregisterList: (list: any) => {
    const index = tables.value.indexOf(list);

    if (index > -1) {
      tables.value.splice(index, 1);
    }
  },
});

function handleScroll(event: any) {
  tables.value.forEach((t) => {
    t.scrollTo(event.srcElement.scrollLeft);
  });
}

onBeforeUnmount(() => {
  onScroll.value!.cancel();
});

defineExpose({ onScroll: onScroll });
</script>

<template>
  <div class="list-aggregator" :class="{ 'with-footer': withFooter !== false }">
    <slot v-bind="{ onScroll }" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .table-actions {
    position: relative;
    z-index: 3;
  }
}

.with-footer > ::v-deep.paginated-table:not(:first-child) > .table-wrapper {
  border-top: 1px solid $common-color-black;

  .table-list-standin {
    background-color: transparent;
    box-shadow: none;
  }
}
</style>
