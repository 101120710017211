<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: 'Back to list',
  },
  narrow: {
    type: [Boolean, String],
    default: false,
  },
  small: {
    type: [Boolean, String],
    default: false,
  },
});

const isLabelShown = computed(() => props.narrow === false && !!props.label);
</script>

<template>
  <VButton class="back-button transparent" :class="{ small: small !== false }" v-on="$listeners">
    <IconArrowRight :class="['back-arrow', { 'with-text': isLabelShown }]" />
    <span v-if="isLabelShown">{{ label }}</span>
  </VButton>
</template>

<style lang="scss" scoped>
.back-button {
  text-transform: uppercase;
  font-size: $font-size-xs;
  font-weight: 700;

  &.small > .label {
    span {
      font-size: $font-size-xxs;
    }
    svg {
      font-size: $font-size-sm;
    }
  }

  .back-arrow {
    transform: rotate(180deg);
    font-size: 2.5em;
    position: relative;
    top: -0.05em;

    &.with-text {
      margin-right: 0.2em;
    }
  }
}
</style>
