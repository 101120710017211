import { HttpOptions, HttpRequestOptions, HttpService, HttpBasedService } from 'ah-requests';
import {
  PaginatedQuery,
  PaginatedResponse,
  UserEntityType,
  UserRole,
  VersionedObject,
  UserRoleObject,
} from '../models';
import { Authority, Permission, RoleData } from '../models/authorization';

export class AuthorizationService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private adminUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'authorizationService' } },
      },
    });
  }

  public getUserPermissions(userId: string, options?: HttpOptions<Permission[]>) {
    return this.get<Permission[]>(`${this.baseUrl}users/${userId}/permissions`, { options });
  }

  public setUserPermissions(userId: string, permissions: Permission, options?: HttpOptions<Permission[]>) {
    return this.put<Permission[]>(`${this.baseUrl}users/${userId}/permissions`, permissions, { options });
  }

  public getGroupPermissions(groupId: string, options?: HttpOptions<Permission[]>) {
    return this.get<Permission[]>(`${this.baseUrl}groups/${groupId}/permissions`, { options });
  }

  public setGroupPermissions(groupId: string, permissions: Permission, options?: HttpOptions<Permission[]>) {
    return this.put<Permission[]>(`${this.baseUrl}groups/${groupId}/permissions`, permissions, { options });
  }

  public addGroupPermission(groupId: string, permission: Permission, options?: HttpOptions<Permission[]>) {
    return this.post<Permission[]>(`${this.baseUrl}groups/${groupId}/permissions`, permission, { options });
  }

  public deleteGroupPermission(groupId: string, permission: Permission, options?: HttpOptions<Permission[]>) {
    return this.delete<Permission[]>(`${this.baseUrl}groups/${groupId}/permissions`, {
      ...options,
      axiosConfig: {
        data: permission,
      },
    });
  }

  public getClientsPermissions(clientId: string, options?: HttpOptions<Permission[]>) {
    return this.get<Permission[]>(`${this.baseUrl}clients/${clientId}/permissions`, { options });
  }

  public setClientsPermissions(clientId: string, permission: Permission, options?: HttpOptions<Permission[]>) {
    return this.put<Permission[]>(`${this.baseUrl}clients/${clientId}/permissions`, permission, { options });
  }

  public getUserAuthorizedActions(userId: string, options?: HttpRequestOptions<string[]>) {
    return this.get<string[]>(`${this.baseUrl}users/${userId}/authorities`, options);
  }

  public listRoles(params?: PaginatedQuery, options?: HttpOptions<PaginatedResponse<RoleData>>) {
    return this.get<PaginatedResponse<RoleData>>(`${this.baseUrl}roles`, {
      options,
      axiosConfig: {
        params,
      },
    });
  }

  public getPublicAuthorities(options?: HttpOptions<Authority[]>) {
    return this.get<Authority[]>(`${this.baseUrl}roles/client/authorities`, { options });
  }

  public getRoleAuthorities(roleName: UserRole, entityType?: UserEntityType, options?: HttpOptions<Authority[]>) {
    return this.get<Authority[]>(`${this.baseUrl}roles/${roleName}`, {
      axiosConfig: {
        params: {
          entityType,
        },
      },
      options,
    });
  }

  /* This is a admin endpoint */
  public getRoleManagementAuthorities(
    roleName: UserRole,
    entityType?: UserEntityType,
    options?: HttpOptions<Authority[]>
  ) {
    return this.get<Authority[]>(`${this.adminUrl}roles/manage/${roleName}`, {
      axiosConfig: {
        params: {
          entityType,
        },
      },
      options,
    });
  }

  public updateRoleManagementAuthorities(
    roleName: UserRole,
    authorities?: { authorities: Authority[] },
    options?: HttpOptions<Authority[]>
  ) {
    return this.put<Authority[]>(`${this.adminUrl}roles/manage/${roleName}`, authorities, { options });
  }

  public getUserRole(userId: string, options?: HttpOptions<UserRoleObject>) {
    return this.get<UserRoleObject>(`${this.adminUrl}roles/entity/${userId}`, { options });
  }

  public setUserRole(userId: string, role: UserRole, options?: HttpOptions<VersionedObject>) {
    return this.put<VersionedObject>(`${this.adminUrl}roles/entity/${userId}`, { role }, { options });
  }
}
