import { render, staticRenderFns } from "./RateAlertSettingsEditModal.vue?vue&type=template&id=40328855&scoped=true"
import script from "./RateAlertSettingsEditModal.vue?vue&type=script&lang=ts&setup=true"
export * from "./RateAlertSettingsEditModal.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./RateAlertSettingsEditModal.vue?vue&type=style&index=0&id=40328855&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40328855",
  null
  
)

export default component.exports