export enum LogEntryType {
  BENEFICIARY = 'BENEFICIARY',
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  PARTNER = 'PARTNER',
  QUOTE = 'QUOTE',
  SESSION = 'SESSION',
  TRADE = 'TRADE',
  WALLET = 'WALLET',
}

export interface LogEntry {
  id: string;
  correlationId: string;
  systemUserId: string;
  timestamp: string;
  type: LogEntryType;
  description: string;
  serviceName: string;
}

export type VLogEntry = LogEntry;
