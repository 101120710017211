import { Address } from '../common';

export interface ClientInviteListFilters {
  state?: ClientInviteState[];
  decoratedState?: ClientInviteState[];
  id?: string[];
  entityType?: string[];
  createdAtFrom?: string;
  createdAtTo?: string;
}

export interface ClientInviteCreateRequest {
  partnerId: string;
  applicantTitle: string;
  applicantFirstName: string;
  applicantLastName: string;
  applicantEmail: string;
  companyName: string;
  companyCountry: string;
  entityType: string;
  registrationNumber: string;
}

export interface ClientInvite extends ClientInviteCreateRequest {
  id: string;
  token: string;
  state: ClientInviteState;
  decoratedState: ClientInviteState;
  createdAt: string;
  createdBy: string;
  acceptedBy: string;
  lastSentAt: string;
  closedAt: string;
  expirationSeconds: number;
  expires: boolean;
  applicantFullName: string;
}

export interface ClientInviteAcceptRequest {
  password: string;
  phoneNumber: string;
  inviteToken: string;
}

export type ClientInviteCompanyAddress = Omit<Address, 'countryCode'>;

export interface ClientInviteRegistrationDetails {
  companyDetails: {
    address: ClientInviteCompanyAddress;
  };
}

export enum ClientInviteState {
  ACTIVE = 'ACTIVE',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',

  // Only available in decoratedState:
  EXPIRED = 'EXPIRED',
  DUE_A_REMINDER = 'DUE_A_REMINDER',
}
