<script lang="ts" setup>
import { beneficiaryTypeLabels, beneficiaryName, FeePaymentType } from 'ah-api-gateways';
import CurrencyFundingFee from '../fees/CurrencyFundingFee.vue';
import { TradeDestination } from '../forms/tradeDestination';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { useAhTradesState } from '../..';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    /**
     * Trade destination object (managed by TradeDestinationForm)
     */
    tradeDestination: Partial<TradeDestination> | TradeDestination;
    /**
     * Data row cols label
     */
    cols?: string | number;
  }>(),
  {
    cols: 4,
  }
);

const tradeState = useAhTradesState();

tradeState.store.useSettingsStore().loadRoutingCodeTypes();

const beneficiary = computed(() => {
  return props.tradeDestination.beneficiary!;
});

const routingCode = computed(() => {
  const routingData = tradeState.store
    .useSettingsStore()
    .routingCodes.find((r) => r.currency === beneficiary.value.currency);
  if (routingData) {
    return routingData.title;
  }
  return 'Routing Code';
});
</script>

<template>
  <div v-if="tradeDestination && tradeDestination.beneficiary">
    <DataRow :cols="cols" md="4" sm="3" label="Beneficiary" class="mb-2 beneficiary-name">
      {{ beneficiaryName(beneficiary) }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="Reference" class="mb-2 reference" v-if="tradeDestination.reference">
      {{ tradeDestination.reference }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="Payment Reason" class="mb-2 reference" v-if="tradeDestination.reason">
      {{ tradeDestination.reason }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="IBAN" v-if="beneficiary.iban" class="mb-2 beneficiary-iban">
      {{ beneficiary.iban }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="SWIFT" v-if="beneficiary.bicSwift" class="mb-2 beneficiary-swift">
      {{ beneficiary.bicSwift }}
    </DataRow>
    <DataRow
      :cols="cols"
      md="4"
      sm="3"
      :label="routingCode"
      v-if="beneficiary.routingNumber1"
      class="mb-2 beneficiary-routing-code"
    >
      {{ beneficiary.routingNumber1 }}
    </DataRow>
    <DataRow
      :cols="cols"
      md="4"
      sm="3"
      label="Account Number"
      v-if="beneficiary.accountNumber"
      class="mb-2 beneficiary-account-number"
    >
      {{ beneficiary.accountNumber }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="Type" class="mb-2 beneficiary-type">
      {{ beneficiaryTypeLabels[beneficiary.type] }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="Address" class="mb-2 beneficiary-address-line">
      {{ beneficiary.address.addressLine }}
    </DataRow>
    <DataRow :cols="cols" md="4" sm="3" label="Country" class="mb-2 beneficiary-address-country">
      {{ countryNameFromCC(beneficiary.address.countryCode)
      }}<InfoTooltip :text="$ahTradesState.i18n.t('tooltips.countryBeneficiary')" />
    </DataRow>
    <DataRow
      :cols="cols"
      v-if="tradeDestination.chargeType"
      md="4"
      sm="3"
      label="SWIFT Fee Type"
      class="mb-2 swift-fee"
    >
      {{ tradeDestination.chargeType }}
    </DataRow>
    <CurrencyFundingFee
      :md="cols"
      :paymentCurrency="beneficiary.currency"
      :bankingScheme="beneficiary.bankingScheme"
      :chargeType="tradeDestination.chargeType"
      :beneficiary="tradeDestination.beneficiary"
      :feePaymentType="FeePaymentType.PAYMENT"
      v-bind="$attrs"
    />
  </div>
</template>
