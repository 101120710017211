var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['nav-view-grid', { ['no-sidebar']: !_setup.isSidebarShown || _vm.$mediaQuery.is('smDown') }]},[_c(_setup.TopNav,{staticClass:"header"}),_c('BoxGrid',{attrs:{"alignV":"start"}},[(_setup.isSidebarShown)?[_c('div',{staticClass:"d-flex flex-column col-12"},[(_setup.isClientInOnboarding)?_c(_setup.OnboardingStatusBanner):_c(_setup.AccountStatusBanner),_c(_setup.ActiveMarginCallBanner),_c(_setup.AppUpdaterBanner),_c(_setup.FeeWarningBanner)],1)]:_vm._e(),_c('BoxGridItem',{attrs:{"align-self":"stretch","cols":"12"}},[_c('RouterView',{attrs:{"name":"banner"}})],1)],2),(_setup.isSidebarShown)?_c(_setup.SideNav,{staticClass:"sidebar"}):_vm._e(),_c('div',{staticClass:"main-area"},[_c('div',{class:[
        'main-area-inner',
        {
          fullscreen: _setup.isFullscreenView,
          'with-sidebar': _setup.isSidebarShown,
          'with-sidebar-menu': _setup.isSidebarShown && _setup.hasSidebarView,
        },
      ]},[_c('RouterView')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }