<script setup lang="ts">
import ListBlock from 'ah-common-lib/src/common/components/listing/ListBlock.vue';
import OutstandingFeesFilters from './OutstandingFeesFilters.vue';
import OutstandingFeesListing from './OutstandingFeesListing.vue';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { AuthorityType, ExportListType } from 'ah-api-gateways';
import { outstandingFeesTableFields } from './tableFields/outstandingFeeTableFields';
import { ref, computed } from 'vue';
import { useAhTradesState } from '../..';
import { RequestState } from 'ah-requests';

const props = defineProps({
  exportOutside: {
    type: [String, Boolean],
    required: false,
    default: false,
  },
});

const outstandingFeesListing = ref<InstanceType<typeof OutstandingFeesListing> | null>(null);

// Automatically syncronised via .sync
const dataDownloadState = ref<RequestState>('idle');

const tradeState = useAhTradesState();

function downloadList(format: ExportListType) {
  outstandingFeesListing.value?.downloadData(format);
}

const showExportOutside = computed(() => props.exportOutside !== false);

const canSeeExport = computed(() =>
  tradeState.store.useAuthStore().hasAuthorities(AuthorityType.EXPORT_OUTSTANDING_FEE_REPORT)
);
</script>

<template>
  <BoxGrid align-h="start" align-v="stretch">
    <ListBlock
      :defaultSortAndPageParams="{ sort: 'feesOutstanding', sortDirection: 'DESC' }"
      class="w-100"
      v-bind="$attrs"
    >
      <template #filters="{ onFilterChange, filter, onSortAndPageParamsChange, sortAndPageParams }">
        <BoxGridBlock class="p-0" :loadingOverlayProps="{ showRetry: true }" useLoadingOverlay>
          <OutstandingFeesFilters
            :filter="filter"
            @update:filter="onFilterChange"
            :sortAndPageParams="sortAndPageParams"
            @update:sortAndPageParams="onSortAndPageParamsChange"
            v-bind="$attrs"
          />
        </BoxGridBlock>
      </template>
      <template #list="{ filter, setFilter, setSortAndPageParams, sortAndPageParams }">
        <SortSelector
          :fields="outstandingFeesTableFields"
          :sortAndPageParams="sortAndPageParams"
          v-if="$mediaQuery.is('smDown')"
          class="w-100"
          @update:sortAndPageParams="setSortAndPageParams"
        />
        <BoxGridBlock class="p-0" :loadingOverlayProps="{ showRetry: true }" useLoadingOverlay>
          <OutstandingFeesListing
            ref="outstandingFeesListing"
            :filter="filter"
            :sortAndPageParams="sortAndPageParams"
            :dataDownloadState.sync="dataDownloadState"
            :hideDownloadButton="showExportOutside || !canSeeExport"
            @update:filter="setFilter"
            @update:sortAndPageParams="setSortAndPageParams"
            v-bind="$attrs"
          />
        </BoxGridBlock>
      </template>
    </ListBlock>
    <ListDownloadButton
      v-if="showExportOutside && canSeeExport"
      :loading="dataDownloadState === 'pending'"
      class="download-button"
      @download="downloadList"
    />
  </BoxGrid>
</template>
