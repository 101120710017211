import {
  AcoTier,
  BankingScheme,
  FeePaymentType,
  PaymentRail,
  FeeChargeType,
  TransactionState,
  PaginatedQuery,
} from '..';

export enum FeeStatus {
  OK = 'OK',
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
}

export const feeStatusBadge: Record<FeeStatus, { label: string; class: string }> = {
  [FeeStatus.OK]: {
    label: 'Ok',
    class: 'green',
  },
  [FeeStatus.DUE]: {
    label: 'Due',
    class: 'orange',
  },
  [FeeStatus.OVERDUE]: {
    label: 'Overdue',
    class: 'red',
  },
};

export interface FeesFilters {
  bankingScheme: BankingScheme;
  paymentCurrency: string;
  tier: AcoTier;
  type: FeePaymentType;
  allCurrencies: boolean;
  /**
   * Partner ID of the Fee
   * 00000000-0000-0000-0000-000000000000 represents global fees
   *
   * Only usable for admins
   */
  partnerId: string;
  /**
   * Client ID of the Fee
   * A null value represents partner global fees
   */
  clientId: string | null;
  paymentRail: PaymentRail;
  countryCode: string;
}

export interface OutstandingFee {
  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;

  currency: string;
  feesAccrued: number;
  feesPaid: number;
  feesOutstanding: number;
  feeStatus: FeeStatus;

  paidAt: string;
  createdAt: string;
}

export interface SingleClientOutstandingFee {
  feeId: string;

  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;

  amount: number;
  currency: string;
  paymentMethod: FeeChargeType;
  bankingScheme: BankingScheme;
  state: TransactionState;
  feeType: FeePaymentType;

  paidAt: string;
  createdAt: string;
}

export type ListOwnerOutstandingFeesQueryParams = PaginatedQuery<{
  partnerId?: string;
  clientId?: string;
  excludeClient?: boolean;
}>;

export interface CustomFeeReason {
  value: string;
  description: string;
}

export interface FeeType {
  value: string;
  description: string;
}
