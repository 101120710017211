<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { ref } from 'vue';
import { PaginatedParams } from 'ah-api-gateways';
import OptionPremiumSchedulePaymentsListing from 'ah-trades/src/components/lists/OptionPremiumSchedulePaymentsListing.vue';

defineProps({
  tradeId: {
    type: String,
    required: true,
  },
  buttonLabel: {
    type: String,
    default: 'View Premium Settlement Schedule',
  },
  modalTitle: {
    type: String,
    default: 'Premium Settlement Schedule',
  },
});

const modalShown = ref(false);

function show() {
  modalShown.value = true;
}

function hide() {
  modalShown.value = false;
}

const defaultSortAndPageParams = {
  sort: 'date',
  sortDirection: 'ASC',
  pageSize: 50,
} as Partial<PaginatedParams>;
</script>

<template>
  <span>
    <BModal :title="modalTitle" v-model="modalShown" centered>
      <OptionPremiumSchedulePaymentsListing :tradeId="tradeId" :sortAndPageParams="defaultSortAndPageParams" />
      <template #modal-footer>
        <VButton class="btn-primary ml-auto" @click="hide">Close</VButton>
      </template>
    </BModal>
    <slot v-bind="{ show }">
      <a class="link" @click="show"> {{ buttonLabel }} </a>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep {
  .link {
    text-decoration: underline;
    color: $common-color-black;
  }
}
</style>
