<script setup lang="ts">
import { computed, PropType } from 'vue';
import {
  PremiumPaymentResponse,
  PremiumPaymentSchedule,
  isPaidPremiumToHumanReadable,
  isPaidPremiumToHumanReadableForClient,
} from 'ah-api-gateways';
import { formatDate, SIMPLE_DATE_WITH_FULL_MONTH_FORMAT } from 'ah-common-lib/src/helpers/time';
import { useManagedListing, defineUseManagedListingProps, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { optionPremiumPaymentSettlementFullTableFields } from 'ah-trades/src/models/optionPremiumPaymentSettlementTableFields';
import { useAhTradesState } from 'ah-trades';
import { of } from 'rxjs';

const formatter = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const props = defineProps({
  tradeId: {
    type: String,
  },
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: optionPremiumPaymentSettlementFullTableFields }),
  },
  data: {
    type: Array as PropType<PremiumPaymentSchedule[]>,
  },
  ...defineUseManagedListingProps<PremiumPaymentResponse | PremiumPaymentSchedule>(),
});

const tradeState = useAhTradesState();

interface PremiumPaymentScheduleEmit extends UseManagedListingEmits<PremiumPaymentResponse | PremiumPaymentSchedule> {}

const emit = defineEmits<PremiumPaymentScheduleEmit>();

const isAHUser = computed(() => tradeState.store.useAuthStore().isAHUser);

function isPaidPremiumToHuman(isPaid: boolean) {
  return isAHUser.value ? isPaidPremiumToHumanReadable(isPaid) : isPaidPremiumToHumanReadableForClient(isPaid);
}

const managedListing = useManagedListing<PremiumPaymentResponse | PremiumPaymentSchedule>({
  loadDataRequest(query) {
    if (props.data) {
      return of({
        pageNumber: 0,
        pageSize: props.data.length,
        total: props.data.length,
        list: props.data,
      });
    } else {
      return tradeState.services.premiumService.listPremiumPayments({ ...query, tradeId: props.tradeId });
    }
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});
</script>

<template>
  <DynamicTable
    hover
    animateCols
    :striped="false"
    emptyCellValue="-"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(date)="{ item }">
      {{ formatDate(item.date, SIMPLE_DATE_WITH_FULL_MONTH_FORMAT) }}
    </template>
    <template #cell(amount)="{ item }">
      {{ `${item.currency} ${formatCurrencyValue(item.amount, '-', formatter)}` }}
    </template>
    <template #cell(isPaid)="{ item }">
      {{ isPaidPremiumToHuman(item.isPaid) }}
    </template>
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </DynamicTable>
</template>

<style lang="scss" scoped>
::v-deep {
  thead tr > th {
    border-bottom: 0 !important;
  }
}
</style>
