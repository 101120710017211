import { makeStoreSupportData, StoreSupportData } from './supportData';
import {
  RateAlertSetting,
  NotificationSetting,
  TimedNotificationSetting,
  ClientType,
  NotificationSettingConfig,
  NotificationType,
} from 'ah-api-gateways';
import { map, tap } from 'rxjs/operators';
import mapValues from 'lodash/mapValues';
import { isTempUUID } from 'ah-common-lib/src/helpers/uuid';
import Vue from 'vue';
import { Observable } from 'rxjs';
import { CachedItem } from 'ah-common-lib/src/helpers/cachedItem';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { useAuthStore } from './authStore';
import { defineStore } from 'pinia';

function getService(sd: StoreSupportData) {
  const authStore = useAuthStore();
  return authStore.isClientUser ? sd.s.clientNotificationSettings : sd.s.partnerNotificationSettings;
}

let sd!: StoreSupportData;

export const useNotificationSettingsStore = defineStore('notificationSettingsModule', {
  persist: true,
  state: () => {
    if (!sd) {
      sd = makeStoreSupportData();
    }
    return {
      _timedNotificationSettings: {} as Partial<{ [key: string]: CachedItem<TimedNotificationSetting[]> }>,
      _notificationSettings: {} as Partial<{ [key: string]: CachedItem<NotificationSetting> }>,
      _rateAlertSettings: new CachedItem<RateAlertSetting[]>('rateAlertSettings'),
      _notificationConfig: new CachedItem<NotificationSettingConfig[]>('notificationConfig'),
    };
  },
  getters: {
    notificationSettings(state) {
      return {
        notifications: mapValues(state._notificationSettings, (cache) => cache?.item),
        timedNotifications: mapValues(state._timedNotificationSettings, (cache) => cache?.item),
      };
    },
    notificationConfig(state) {
      return state._notificationConfig.item;
    },
    rateAlertSettings(state) {
      return state._rateAlertSettings.item;
    },
  },
  actions: {
    async [commonStoreActions.onSetup]() {
      sd = makeStoreSupportData();
      this.setupItems();
    },
    async [commonStoreActions.onLogout]() {
      this.setupItems(true);
    },
    async [commonStoreActions.onLogin]() {
      this.loadAllItems(true);
    },
    async [commonStoreActions.onOutMaintenance]() {
      const authStore = useAuthStore();
      if (authStore.isLoggedIn) {
        this.loadAllItems(true);
      }
    },
    loadRateAlertSettings(force = false) {
      const authStore = useAuthStore();
      if (!authStore.loggedInIdentity?.client) {
        throw new Error('No client in logged in identity');
      }
      const clientId = authStore.loggedInIdentity!.client.id;

      return CachedItem.loadCachedItem(
        this._rateAlertSettings,
        sd.s.clientNotificationSettings.listRateAlerts(clientId, { pageSize: 200 }).pipe(map((r) => r.list)),
        force
      );
    },
    deleteRateAlertSetting(setting: RateAlertSetting) {
      const authStore = useAuthStore();
      if (!authStore.loggedInIdentity?.client) {
        throw new Error('No client in logged in identity');
      }

      const clientId = authStore.loggedInIdentity.client.id;
      this.setRateAlertSetting({ setting, remove: true });

      return sd.reqManager
        .currentOrNew(
          `deleteRateAlertSetting-${setting.id}`,
          sd.s.clientNotificationSettings.deleteRateAlert(clientId, setting.id)
        )
        .toPromise();
    },
    saveRateAlertSetting(setting: RateAlertSetting) {
      const authStore = useAuthStore();
      if (!authStore.loggedInIdentity?.client) {
        throw new Error('No client in logged in identity');
      }
      const client = authStore.loggedInIdentity.client!;
      const payload = { ...setting } as Partial<RateAlertSetting>;
      let request!: Observable<RateAlertSetting>;

      /**
       * If client is Individual, destination controls will be hidden,
       * and `to` is always set to the client
       */
      if (client.type === ClientType.INDIVIDUAL) {
        payload.to = [];
      }

      if (isTempUUID(setting.id)) {
        delete payload.id;

        request = sd.s.clientNotificationSettings.createRateAlert(client.id, payload);
      } else {
        request = sd.s.clientNotificationSettings.editRateAlert(client.id, payload as RateAlertSetting);
      }

      return sd.reqManager
        .sameOrCancelAndNew(`saveRateAlertSetting-${setting.id}`, request, setting)
        .toPromise()
        .then((setting) => {
          this.setRateAlertSetting({ setting });
        });
    },
    setRateAlertSetting(payload: { setting: RateAlertSetting; remove?: boolean }) {
      const { setting, remove } = { remove: false, ...payload };

      if (this._rateAlertSettings.item) {
        const value = [...this._rateAlertSettings.item];
        const index = value.findIndex((i) => i.id === setting.id);
        if (index > -1) {
          remove ? value.splice(index, 1) : value.splice(index, 1, setting);
        } else if (!remove) {
          value.push(setting);
        }
        CachedItem.setCachedItem(this._rateAlertSettings, value);
      }
    },
    loadNotificationConfig(force = false) {
      const authStore = useAuthStore();
      const entityType = authStore.isClientUser ? 'CLIENT' : 'PARTNER';

      return CachedItem.loadCachedItem(
        this._notificationConfig,
        getService(sd)
          .listNotificationConfig()
          .pipe(map((i) => i.filter((setting) => setting.entityType === entityType))),
        force
      );
    },
    loadNotificationSettings(force = false) {
      /**
       * Use the first element to verify if notifications are loaded
       */
      if (!force && Object.keys(this._notificationSettings).length) {
        const item = this._notificationSettings[Object.keys(this._notificationSettings)[0]]!;
        if (!CachedItem.isCachedItemExpired(item)) {
          return Promise.resolve(this.notificationSettings);
        }
      }

      /**
       * Request consequences in tap operator to leverage the request reuse mechanic
       */
      const request = getService(sd)
        .listNotificationSettings({ pageSize: 200 })
        .pipe(
          tap((response) => {
            const groups = response.list.reduce((grouped, item) => {
              grouped[item.type] = grouped[item.type] || [];
              grouped[item.type]!.push(item);
              return grouped;
            }, {} as { [key: string]: NotificationSetting[] });

            Object.keys(groups).forEach((k) => {
              if (this.notificationConfig?.find((s) => s.type === k)?.timed || k === NotificationType.RATE_ALERT) {
                groups[k].forEach((setting) => this.setNotificationSetting({ setting }));
              } else {
                if (groups[k].length > 1) {
                  for (let i = 1; i < groups[k].length; i++) {
                    getService(sd).deleteNotificationSetting(groups[k][i].id).subscribe();
                  }
                }
                this.setNotificationSetting({ setting: groups[k][0] });
              }
            });
          })
        );

      return sd.reqManager
        .sameOrNew('loadNotifications', request)
        .toPromise()
        .then(() => this.notificationSettings);
    },
    deleteNotificationSetting(setting: NotificationSetting) {
      this.setNotificationSetting({ setting, remove: true });

      return sd.reqManager
        .currentOrNew(`deleteNotificationSetting-${setting.id}`, getService(sd).deleteNotificationSetting(setting.id))
        .toPromise();
    },
    saveNotificationSetting(setting: NotificationSetting) {
      const payload = { ...setting } as Partial<NotificationSetting>;
      let request!: Observable<NotificationSetting>;

      if (isTempUUID(setting.id)) {
        delete payload.id;

        request = getService(sd).createNotificationSetting(payload);
      } else {
        request = getService(sd).editNotificationSetting(payload as NotificationSetting);
      }

      return sd.reqManager
        .sameOrCancelAndNew(`saveNotificationSetting-${setting.id}`, request, payload)
        .toPromise()
        .then((setting) => {
          this.setNotificationSetting({ setting });
        });
    },
    setTimedNotificationSetting(payload: { setting: TimedNotificationSetting; remove?: boolean }) {
      const { setting, remove } = { remove: false, ...payload };

      if (!this._timedNotificationSettings[setting.type]) {
        Vue.set(
          this._timedNotificationSettings,
          setting.type,
          new CachedItem<NotificationSetting[]>(`timedNotificationSetting-${setting.type}`)
        );
      }
      const cached = this._timedNotificationSettings[setting.type]!;
      const value = [...(cached.item || [])];
      const index = value.findIndex((i) => i.id === setting.id);
      if (index > -1) {
        remove ? value.splice(index, 1) : value.splice(index, 1, setting);
      } else if (!remove) {
        value.push(setting);
      }
      CachedItem.setCachedItem(this._timedNotificationSettings[setting.type]!, value);
    },
    setNonTimedNotificationSetting(payload: { setting: NotificationSetting; remove?: boolean }) {
      const { setting, remove } = { remove: false, ...payload };

      if (!this._notificationSettings[setting.type]) {
        Vue.set(
          this._notificationSettings,
          setting.type,
          new CachedItem<NotificationSetting>(`notificationSetting-${setting.type}`)
        );
      }
      CachedItem.setCachedItem(this._notificationSettings[setting.type]!, remove ? null : setting);
    },
    async setNotificationSetting(payload: { setting: NotificationSetting; remove?: boolean }) {
      const { setting, remove } = { remove: false, ...payload };

      if (this.notificationConfig?.find((s) => s.type === setting.type)?.timed) {
        this.setTimedNotificationSetting({ setting: setting as TimedNotificationSetting, remove });
      } else {
        this.setNonTimedNotificationSetting({ setting: setting, remove });
      }
    },
    async setupItems(clear = false) {
      this._timedNotificationSettings = (!clear && this._timedNotificationSettings) || {};
      this._notificationSettings = (!clear && this._notificationSettings) || {};
      this._rateAlertSettings = (!clear && this._rateAlertSettings) || new CachedItem('rateAlertSettings');
      this._notificationConfig = (!clear && this._notificationConfig) || new CachedItem('notificationConfig');
    },
    loadAllItems(force = false) {
      return Promise.all([this.loadNotificationConfig(force)]);
    },
  },
});
