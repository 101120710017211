import { render, staticRenderFns } from "./ClientRejectionModal.vue?vue&type=template&id=618a0c02&scoped=true"
import script from "./ClientRejectionModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClientRejectionModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ClientRejectionModal.vue?vue&type=style&index=0&id=618a0c02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618a0c02",
  null
  
)

export default component.exports