<script setup lang="ts">
import { PaginatedQuery, ExportListType, Address, ClientListResponse } from 'ah-api-gateways';
import { clientTableFields } from '@/app/components/clients/tableFields/clientTableFields';
import ClientOnboardingStatusPill from '@/app/components/clients/ClientOnboardingStatusPill.vue';
import ClientAssigneesList from './ClientAssigneesList.vue';
import ClientIndividualsList from './ClientIndividualsList.vue';
import { useSettingsStore } from '@/app/store/settingsModule';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';

const props = defineProps({
  ...defineUseManagedListingProps<ClientListResponse>(),
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: clientTableFields }),
  },
  documentsTitle: {
    type: String,
  },
});

interface ClientListResponseEmits extends UseManagedListingEmits<ClientListResponse> {}

const emit = defineEmits<ClientListResponseEmits>();

const requestManager = useRequestManager();

const services = getServices();

const settingsStore = useSettingsStore();

const mediaQuery = useMediaQuery();

const managedListing = useManagedListing<ClientListResponse>({
  loadDataRequest(query: PaginatedQuery) {
    return services.client.listClients(query);
  },
  downloadDataRequest(type: string, query: { [key: string]: any }) {
    return services.client.downloadClientList(query, type as ExportListType, props.documentsTitle);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

const isStackedView = computed(() => mediaQuery.is('smDown'));

function countryNameFromCCValue(value: string) {
  return countryNameFromCC(value, settingsStore.allCountries) || '';
}

function countryName(address?: Address) {
  return address?.countryCode ? countryNameFromCCValue(address.countryCode) : '';
}

settingsStore.loadCountries();

defineExpose({ downloadData: managedListing.listeners.download });
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    :nowrap="!isStackedView"
    class="clients-table"
    items-label="clients"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(individuals)="{ item }">
      <span v-if="item.type === 'INDIVIDUAL'"> - </span>
      <ClientIndividualsList v-else :client="item" :maxItems="3" />
    </template>
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, SIMPLE_DATE_FORMAT) }}
    </template>
    <template #cell(assignees)="{ item }">
      <ClientAssigneesList :client="item" />
    </template>
    <template #cell(email)="{ item }">
      <span v-if="item.type === 'INDIVIDUAL'">
        {{ item.individualDetails ? item.individualDetails.email : '-' }}
      </span>
    </template>
    <template #cell(country)="{ item }">
      <span v-if="item.type === 'INDIVIDUAL'">
        {{ countryName(item.individualDetails.address) }}
      </span>
      <span v-else class="country-name">
        {{ countryName(item.companyDetails.address) }}
      </span>
    </template>
    <template #cell(type)="{ item }">
      {{ item.type === 'INDIVIDUAL' ? 'Individual' : 'Business' }}
    </template>
    <template #cell(status)="{ item }">
      <ClientOnboardingStatusPill :client="item" />
    </template>
    <!-- collapse row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.edit-icon-block {
  cursor: pointer;

  .edit-icon {
    @include themedTextColor($color-text-secondary);

    &:not(.disabled):hover {
      @include themedTextColor($color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.country-name {
  opacity: 0.5;
}
</style>
