import {
  ClientCollateralsConfigurations,
  ClientCollateralSettings,
  CollateralTransaction,
  CollateralVariationMarginConfig,
  CollateralVariationMarginConfigRequest,
  VersionedObject,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';

export class ClientCollateralService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, protected adminBaseUrl?: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'clientCollateralService' } },
      },
    });
  }

  public getCollateralProfileVariationMargin(clientId: string, options?: HttpOptions<CollateralVariationMarginConfig>) {
    if (!this.adminBaseUrl) throw 'Forbidden action';

    return this.get<CollateralVariationMarginConfig>(
      `${this.adminBaseUrl}collateral-profile/${clientId}/variation-margin-configuration`,
      {
        options,
      }
    );
  }

  public updateCollateralProfileVariationMargin(
    variationMarginConfig: CollateralVariationMarginConfigRequest,
    options?: HttpOptions<VersionedObject>
  ) {
    if (!this.adminBaseUrl) throw 'Forbidden action';

    return this.put<VersionedObject>(
      `${this.adminBaseUrl}collateral-profile/${variationMarginConfig.clientId}/variation-margin-configuration`,
      variationMarginConfig,
      { options }
    );
  }

  public withdrawCollateral(
    clientId: string,
    payload: { amount: number; currency: string },
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId && !this.adminBaseUrl) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VersionedObject>(`${this.adminBaseUrl ?? this.baseUrl}collateral/${clientId}/withdraw`, payload, {
      axiosConfig: { headers },
      options,
    });
  }

  public postCollateral(
    clientId: string,
    payload: { amount: number; currency: string },
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId && !this.adminBaseUrl) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VersionedObject>(`${this.adminBaseUrl ?? this.baseUrl}collateral/${clientId}/post`, payload, {
      axiosConfig: { headers },
      options,
    });
  }

  public getCollateralTransaction(
    id: string,
    oboClientId?: string,
    options?: Partial<HttpOptions<CollateralTransaction>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.get<CollateralTransaction>(`${this.baseUrl}collateral/transactions/${id}`, {
      axiosConfig: { headers },
      options,
    });
  }

  public getClientCollateralConfigurations() {
    if (!this.adminBaseUrl) throw 'Forbidden action';

    return this.get<ClientCollateralsConfigurations>(`${this.adminBaseUrl}collaterals/configurations`);
  }

  public setClientCollateralConfigurations(saveFormData: ClientCollateralsConfigurations) {
    if (!this.adminBaseUrl) throw 'Forbidden action';

    return this.put<ClientCollateralsConfigurations>(`${this.adminBaseUrl}collaterals/configurations`, saveFormData);
  }

  public createCollateralSettings(saveFormData: Partial<ClientCollateralSettings>) {
    if (!this.adminBaseUrl) throw 'Forbidden action';

    return this.post<ClientCollateralSettings>(`${this.adminBaseUrl}collateral-settings`, saveFormData);
  }

  public getCollateralSettings() {
    if (!this.adminBaseUrl) throw 'Forbidden action';

    return this.get<ClientCollateralSettings>(`${this.adminBaseUrl}collateral-settings`);
  }
}
