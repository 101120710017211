import { computed, reactive, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { ApiError, HttpError } from 'ah-requests';
import { CoreValidationValues } from 'ah-common-lib/src/form/interfaces';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { debounce } from 'lodash';
import { AuthErrorCodes } from 'ah-api-gateways';

export function usernameFromName(firstName: string = '', lastName: string = ''): string {
  if (!firstName || !lastName) {
    return '';
  }

  const parts = [firstName, lastName]
    .filter(Boolean)
    .map((name) => name.trim())
    .filter((name) => name.length > 0);

  return parts.join('').toLowerCase().replace(/\s+/g, '');
}

export function useUsernameValidation(options?: { debounceTime?: number; currentUsername?: string }) {
  const { manager: requestManager } = useRequestManager();
  const services = getServices();

  const apiError = ref<ApiError>();
  const currentUsernameValue = ref<string>('');

  const apiErrorPayload = computed(() => (apiError.value ? constructPayloadErrors(apiError.value) : undefined));

  const validation = reactive<CoreValidationValues>({
    $pending: false,
    $invalid: false,
    $dirty: false,
  });

  const validateUsernameRequest = (username: string) => {
    currentUsernameValue.value = username;
    return requestManager.sameOrCancelAndNew(
      'validateUsername',
      services.registration.usernameValidation({ username }, { errors: { silent: true } }).pipe(
        map(() => {
          apiError.value = undefined;
          validation.$pending = false;
          validation.$invalid = false;
          return true;
        }),
        catchError((error: HttpError) => {
          apiError.value = error.response!.data;
          validation.$pending = false;
          validation.$invalid = true;
          return of(false);
        })
      ),
      username
    );
  };

  const generateUniqueUsername = async (username: string): Promise<string | undefined> => {
    const usedNumbers = new Set<number>();
    const MAX_API_ATTEMPTS = 5;
    let isAvailable = await validateUsernameRequest(username).toPromise();

    while (!isAvailable && usedNumbers.size < MAX_API_ATTEMPTS) {
      const randomNum = Math.floor(Math.random() * 1000);
      if (usedNumbers.has(randomNum)) continue;

      usedNumbers.add(randomNum);
      username = `${username}${randomNum}`;
      isAvailable = await validateUsernameRequest(username).toPromise();
    }

    return isAvailable ? username : undefined;
  };

  const automaticallyGenerateUsername = async (firstName: string, lastName: string) => {
    const baseUsername = usernameFromName(firstName, lastName);

    validation.$pending = true;
    const uniqueUsername = await generateUniqueUsername(baseUsername);

    validation.$pending = false;
    validation.$invalid = !uniqueUsername;

    return uniqueUsername || baseUsername;
  };

  const runUsernameValidation = async (value: string) => {
    if (!value) return;

    validation.$pending = true;
    const isAvailable = await validateUsernameRequest(value).toPromise();

    validation.$pending = false;
    validation.$invalid = !isAvailable;

    return isAvailable;
  };

  const debounceTime = options?.debounceTime ?? 500;

  const debouncedValidation = debounce(runUsernameValidation, debounceTime);

  const validateUsername = async (value: string, immediate = false) => {
    debouncedValidation.cancel();
    if (immediate) {
      return Promise.resolve(runUsernameValidation(value));
    } else {
      validation.$pending = true;
      return debouncedValidation(value);
    }
  };

  const validationMessage = computed(() => {
    if (validation.$pending) {
      return 'Validating username...';
    }
    if (!validation.$invalid) {
      return 'Username is available.';
    }
    if (apiError.value?.code === AuthErrorCodes.CLIENT_USERNAME_CONFLICT) {
      const isSameAsCurrentUsername = options?.currentUsername === currentUsernameValue.value;
      if (isSameAsCurrentUsername) {
        return 'Cannot be the same as the current username.';
      }
      return 'This username is already in use. Please choose another one.';
    }

    return apiErrorPayload.value?.fields?.username?.errors?.[0]?.message ?? apiError.value?.message ?? '';
  });

  return {
    validation,
    validationState: computed(() => {
      if (validation.$pending) {
        return 'pending';
      } else if (validation.$invalid) {
        return 'invalid';
      }
      return 'valid';
    }),
    validationMessage,
    automaticallyGenerateUsername,
    validateUsername,
  };
}
