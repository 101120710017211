<script lang="ts">
import {
  previousAddressesFormsEmitsDef,
  previousAddressesFormsProps,
} from '../../registration/forms/common/PreviousAddressesForms.vue';
const { previousAddressesParentClass, ...props } = previousAddressesFormsProps;
</script>

<script setup lang="ts">
import PreviousAddressesForms from '../../registration/forms/common/PreviousAddressesForms.vue';
defineProps(props);

defineEmits(previousAddressesFormsEmitsDef);
</script>

<template>
  <PreviousAddressesForms class="review-screen-previous-addresses-form" v-bind="$props" v-on="$listeners" />
</template>

<style lang="scss" scoped>
.review-screen-previous-addresses-form ::v-deep {
  .address-requirement-info-block {
    display: none;
  }

  .previous-address {
    .previous-address-title {
      margin-top: 1em;
    }
    .previous-address-subtitle {
      margin-left: 1em;
    }

    .address-form {
      .previous-address-date-label {
        margin-left: 1em;
      }
      .remove-btn-review-styling {
        margin-right: 1em;
      }
    }
  }
}
</style>
