import {
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  VoiceTradeAuditData,
  ExportListType,
  DocumentExport,
  ListQuery,
  TransactionAuditData,
  BankAccountAuditData,
  ComplianceCaseAuditData,
  ListPageParams,
} from '../models';
import { HttpBasedService, HttpRequestOptions, HttpService } from 'ah-requests';

export class SecurityReportsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'securityService' } },
      },
    });
  }

  public listComplianceCaseAudits(
    params: PaginatedQuery,
    options?: HttpRequestOptions<PaginatedResponse<ComplianceCaseAuditData>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<ComplianceCaseAuditData>>(`${this.baseUrl}audits/compliancecases`, {
      ...options,
      axiosConfig: { ...options?.axiosConfig, params },
    });
  }

  public listComplianceCaseAcceptanceAuditEntries(
    complianceCaseId: string,
    pagination: ListPageParams,
    options?: HttpRequestOptions<PaginatedResponse<ComplianceCaseAuditData>>
  ) {
    return this.get<PaginatedResponse<ComplianceCaseAuditData>>(
      `${this.baseUrl}audits/compliancecases/${complianceCaseId}/terms-and-conditions`,
      {
        ...options,
        axiosConfig: { params: pagination, ...options?.axiosConfig },
      }
    );
  }

  public listVoiceTradeAudits(
    params: PaginatedQuery,
    options?: HttpRequestOptions<PaginatedResponse<VoiceTradeAuditData>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<VoiceTradeAuditData>>(`${this.baseUrl}audits/voicetrades`, {
      ...options,
      axiosConfig: { ...options?.axiosConfig, params },
    });
  }

  public getVoiceTradeAudit(id: string, options?: HttpRequestOptions<VoiceTradeAuditData>) {
    return this.get<VoiceTradeAuditData>(`${this.baseUrl}audits/voicetrades/${id}`, options);
  }

  public downloadVoiceTradeAudit(
    query: PaginatedQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Voice Trade Audits',
    options?: HttpRequestOptions<DocumentExport>
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}audits/voicetrades/export`, query, fileFormat, documentTitle, options);
  }

  public listTransactionAudits(
    params: PaginatedQuery,
    options?: HttpRequestOptions<PaginatedResponse<TransactionAuditData>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<TransactionAuditData>>(`${this.baseUrl}audits/cashtransactions`, {
      ...options,
      axiosConfig: { ...options?.axiosConfig, params },
    });
  }

  public getTransactionAudit(id: string, options?: HttpRequestOptions<TransactionAuditData>) {
    return this.get<TransactionAuditData>(`${this.baseUrl}audits/cashtransactions/${id}`, options);
  }

  public downloadTransactionAudit(
    query: PaginatedQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Cash Transaction Audits',
    options?: HttpRequestOptions<DocumentExport>
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}audits/cashtransactions/export`,
      query,
      fileFormat,
      documentTitle,
      options
    );
  }

  public listBankAccountAudit(
    params: PaginatedQuery,
    options?: HttpRequestOptions<PaginatedResponse<BankAccountAuditData>>
  ) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<BankAccountAuditData>>(`${this.baseUrl}audits/bankaccounts`, {
      ...options,
      axiosConfig: { ...options?.axiosConfig, params },
    });
  }

  public getBankAccountAudit(id: string, options?: HttpRequestOptions<BankAccountAuditData>) {
    return this.get<BankAccountAuditData>(`${this.baseUrl}audits/bankaccounts/${id}`, options);
  }

  public downloadBankAccountAudit(
    query: PaginatedQuery,
    fileFormat?: ExportListType,
    documentTitle = 'Bank Accounts Audits',
    options?: HttpRequestOptions<DocumentExport>
  ) {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}audits/bankaccounts/export`, query, fileFormat, documentTitle, options);
  }

  private downloadList(
    url: string,
    query: ListQuery,
    fileFormat?: ExportListType,
    documentTitle?: string,
    options?: HttpRequestOptions<DocumentExport>
  ) {
    return this.get<DocumentExport>(url, {
      ...options,
      axiosConfig: {
        ...options?.axiosConfig,
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }
}
