<script lang="ts" setup>
import { Client, ClientOnboardingStatus } from 'ah-api-gateways';
import StatusPill from 'ah-common-lib/src/common/components/StatusPill.vue';
import { PropType } from 'vue';

defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
});

const STATUS_COLORS: Record<ClientOnboardingStatus, string> = {
  [ClientOnboardingStatus.CREATED]: 'grey',
  [ClientOnboardingStatus.PENDING]: 'warning',
  [ClientOnboardingStatus.APPROVED]: 'success',
  [ClientOnboardingStatus.REJECTED]: 'danger',
};

const STATUS_LABELS: Record<ClientOnboardingStatus, string> = {
  [ClientOnboardingStatus.CREATED]: 'Info required',
  [ClientOnboardingStatus.PENDING]: 'In-review',
  [ClientOnboardingStatus.APPROVED]: 'Approved',
  [ClientOnboardingStatus.REJECTED]: 'Rejected',
};
</script>

<template>
  <StatusPill :color="STATUS_COLORS[client.status]" :label="STATUS_LABELS[client.status]" />
</template>
