import {
  PaginatedQuery,
  PaginatedResponse,
  Trade,
  AdminTrade,
  TradeState,
  CreateTradeRequest,
  ListQuery,
  ExportListType,
  TradeCommissionsReport,
  TradeCountReport,
  TradeWithCommissionReport,
  UserRole,
  ClientCommission,
  ProductCommission,
  CommissionSchedule,
  TopClientAccountsReportEntry,
  CommissionSettlementSchedule,
  FutureCommissionSettlementSchedule,
  TradeSuspendResume,
  VersionedObject,
  AgentCommission,
  DrawdownTradeRequest,
  TopPartnerAccountsReportEntry,
  ClientMonthlyTradeCountReportEntry,
  ClientMonthlyTradeVolumeReportEntry,
  HedgingInstruments,
  CancelTrade,
  DocumentExport,
  RollForwardTrade,
  standardiseQuerySort,
  TradeFundingDetails,
  CancelAdminTrade,
  HouseCash,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions, RequestManager } from 'ah-requests';
import { ModelStateMachineActions } from '../models/modelStateMachineActions';
import { format } from 'date-fns';
import { mergeMap } from 'rxjs/operators';

const TRADE_CACHE = 'trade';

const stateChangeErrorMessages = [
  {
    message: `The requested state change is not valid`,
    name: 'invalid-state-change',
    code: 409,
    toastType: 'danger',
  },
];

export class TradeService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private adminBaseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'tradeService' } },
      },
    });
  }

  private requestManager = new RequestManager();

  public get requestStates() {
    return this.requestManager.requestStates;
  }

  public listTrades(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<Trade>>(`${this.baseUrl}trades`, {
      axiosConfig: { params },
    });
  }

  private downloadList(url: string, query: ListQuery, fileFormat: ExportListType, documentTitle?: string) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public downloadTradeList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Trade List') {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}trades/export`, query, fileFormat, documentTitle);
  }

  public downloadTradeReportList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Trading Report') {
    query = standardiseQuerySort(query);
    return this.downloadList(
      `${this.baseUrl}trades/reports/trades-with-commissions/export`,
      query,
      fileFormat,
      documentTitle
    );
  }

  public getTrade(id: string, options?: Partial<HttpOptions<Trade>>) {
    return this.requestManager.currentOrNew(
      `getTrade-${id}`,
      this.get<Trade>(`${this.baseUrl}trades/${id}`, {
        options: {
          ...options,
        },
      })
    );
  }

  public getAdminTrade(id: string, options?: Partial<HttpOptions<AdminTrade>>) {
    return this.requestManager.currentOrNew(
      `getTrade-${id}`,
      this.get<AdminTrade>(`${this.adminBaseUrl}trades/${id}`, {
        options: {
          ...options,
        },
      })
    );
  }

  public getTradeCostsOfClosing(id: string, options?: Partial<HttpOptions<CancelTrade>>) {
    return this.requestManager.currentOrNew(
      `getTradeCostsOfClosing-${id}`,
      this.get<CancelTrade>(`${this.adminBaseUrl}trades/${id}/cancel`, {
        options: {
          ...options,
        },
      })
    );
  }

  public getTradeCostsOfRollingForward(
    id: string,
    settlementDate: string,
    options?: Partial<HttpOptions<RollForwardTrade>>
  ) {
    return this.requestManager.currentOrNew(
      `getTradeCostsOfRollingForward-${id}`,
      this.get<RollForwardTrade>(`${this.adminBaseUrl}trades/${id}/rollforward`, {
        axiosConfig: {
          params: {
            settlementDate,
          },
        },
        options,
      })
    );
  }

  public cancelTrade(tradeId: string, oboClientId: string, isAdminUser: boolean, cancelFeePayer: string) {
    if (!oboClientId || !isAdminUser) {
      throw 'Trade cancelation is an admin action only';
    }

    return this.put<VersionedObject>(
      `${this.adminBaseUrl}trades/${tradeId}/cancel`,
      { cancelFeePayer },
      {
        options: {
          errors: {
            errorMessages: stateChangeErrorMessages,
          },
        },
      }
    );
  }

  public rollForwardTrade(
    tradeId: string,
    oboClientId: string,
    isAdminUser: boolean,
    newSettlementDate: string,
    feePayer: string
  ) {
    if (!oboClientId || !isAdminUser) {
      throw 'Trade rolling forward is an admin action only';
    }

    return this.put<VersionedObject>(
      `${this.adminBaseUrl}trades/${tradeId}/rollforward`,
      { newSettlementDate, feePayer },
      {
        options: {
          errors: {
            errorMessages: stateChangeErrorMessages,
          },
        },
      }
    );
  }

  /**
   * clientId - must be defined in 'On Behalf Of' requests
   */
  public createTrade(trade: CreateTradeRequest, oboClientId?: string, options?: Partial<HttpOptions<VersionedObject>>) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VersionedObject>(`${this.baseUrl}trades`, trade, { options, axiosConfig: { headers } });
  }

  public updateTrade(
    tradeId: string,
    priceId: string,
    tradeFundingDetails: Partial<TradeFundingDetails>,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    return this.post<VersionedObject>(
      `${this.adminBaseUrl}trades/${tradeId}/amend`,
      { ...tradeFundingDetails, priceId },
      { options }
    );
  }

  /**
   * State Actions
   **/

  // Client actions

  public cashOutNow(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/cashoutnow`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public exerciseNow(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/exerciseearlynow`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  // Trading Desk Actions

  public acceptCashOutNow(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/cashoutnowaccepted`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public rejectCashOutNow(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/cashoutnowrejected`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public executeEarlyCashOut(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/closedearlycashout`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public executeEarlyExercise(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/closedearlyexercise`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public settle(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/closedsettled`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public setPremiumNotPaid(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/openpremiumnotpaid`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public setPremiumPaid(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/openpremiumpaid`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public absorveTrade(tradeId: string) {
    return this.put<void>(`${this.baseUrl}trades/${tradeId}/tradeabsorbed`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public drawdownTrade(
    tradeId: string,
    request: DrawdownTradeRequest,
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VersionedObject>(`${this.baseUrl}trades/${tradeId}/drawdown`, request, {
      options,
      axiosConfig: { headers },
    });
  }

  public listTradesWithCommissionReport(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<TradeWithCommissionReport>>(
      `${this.baseUrl}trades/reports/trades-with-commissions`,
      {
        axiosConfig: { params },
      }
    );
  }

  public getTradeCountReport(startDate: Date, endDate: Date, partnerId?: string) {
    return this.get<TradeCountReport>(`${this.baseUrl}trades/reports/count`, {
      axiosConfig: {
        params: {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          partnerId: partnerId,
        },
      },
      options: {
        cache: {
          type: 'use',
          cacheKey: 'getTradeCountReport',
          itemKey: startDate.toISOString() + endDate.toISOString() + (partnerId ?? ''),
        },
      },
    });
  }

  public getTradeCommissionsReport(startDate: Date, endDate: Date, partnerId?: string) {
    return this.get<TradeCommissionsReport>(`${this.baseUrl}trades/reports/transactions-volume`, {
      axiosConfig: {
        params: {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          partnerId: partnerId,
        },
      },
      options: {
        cache: {
          type: 'use',
          cacheKey: 'getTradeCommissionsReport',
          itemKey: startDate.toISOString() + endDate.toISOString() + (partnerId ?? ''),
        },
      },
    });
  }

  /**
   * list client Commissions
   * @param query PaginatedQuery, with start and end date properties
   * dates must be formated as 'yyyy-MM-dd'
   */
  public listClientCommissions(query: PaginatedQuery<{ startDate: string; endDate: string }>) {
    query = standardiseQuerySort(query);
    return this.get<PaginatedResponse<ClientCommission>>(`${this.baseUrl}trades/reports/commissions/clients`, {
      axiosConfig: {
        params: query,
      },
    });
  }
  /**
   * list product Commissions
   * @param query PaginatedQuery, with start and end date properties
   * dates must be formated as 'yyyy-MM-dd'
   */
  public listProductCommissions(query: PaginatedQuery<{ startDate: string; endDate: string }>) {
    query = standardiseQuerySort(query);
    return this.get<PaginatedResponse<ProductCommission>>(`${this.baseUrl}trades/reports/commissions/products`, {
      axiosConfig: {
        params: query,
      },
    });
  }
  /**
   * list agent Commissions
   * @param query PaginatedQuery, with start and end date properties
   * dates must be formated as 'yyyy-MM-dd'
   */
  public listAgentCommissions(query: PaginatedQuery<{ startDate: string; endDate: string }>) {
    query = standardiseQuerySort(query);
    return this.get<PaginatedResponse<AgentCommission>>(`${this.baseUrl}trades/reports/commissions/assignees`, {
      axiosConfig: {
        params: query,
      },
    });
  }

  public getSettledCommissions() {
    return this.get<CommissionSchedule>(`${this.baseUrl}trades/reports/commissions/settled`);
  }

  public getScheduledCommissions() {
    return this.get<CommissionSchedule>(`${this.baseUrl}trades/reports/commissions/scheduled`);
  }

  public getTotalCommissions() {
    return this.get<CommissionSchedule>(`${this.baseUrl}trades/reports/commissions/total`);
  }

  public getSettlementScheduleReport() {
    return this.get<CommissionSettlementSchedule>(`${this.baseUrl}trades/reports/commissions/settlement-schedule`);
  }

  public getFutureCommissionReport() {
    return this.get<FutureCommissionSettlementSchedule>(`${this.baseUrl}trades/reports/commissions/future`);
  }

  public downloadFutureCommissionReport(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Future Commissions List'
  ) {
    return this.downloadList(
      `${this.baseUrl}trades/reports/commissions/future/export`,
      query,
      fileFormat,
      documentTitle
    );
  }

  public getTradingStatus() {
    return this.get<TradeSuspendResume>(`${this.baseUrl}trades/status`);
  }

  public setTradingStatus(suspendOrResume: string) {
    return this.put<TradeSuspendResume>(`${this.baseUrl}trades/${suspendOrResume}`);
  }

  public get stateActions(): ModelStateMachineActions<TradeState, Trade> {
    return {
      [TradeState.OPEN]: [
        {
          name: 'settle',
          label: 'Settle',
          successLabel: (trade) => `Trade #${trade.referenceNumber} settled`,
          test: (trade, session) => session.role === UserRole.AH_ADMIN && trade.hedgingProduct === 'FX_SPOT',
          action: (trade) =>
            this.settle(trade.id).pipe(
              mergeMap(() =>
                this.getTrade(trade.id, { cache: { type: 'use', cacheKey: TRADE_CACHE, itemKey: trade.id } })
              )
            ),
        },
      ],
      [TradeState.CLOSED_REJECTED]: [
        // Final State
      ],
      [TradeState.CANCELING]: [
        // Stub
      ],
      [TradeState.CLOSED_SETTLED]: [
        // Final State
      ],
      [TradeState.SUBMITTED]: [
        // Stub
      ],
      [TradeState.VERIFIED]: [
        // Stub
      ],
      [TradeState.CLOSED_CANCELED]: [
        // Stub
      ],
      [TradeState.CLOSED_AMENDED]: [
        // Stub
      ],
    };
  }

  public getClientTopAccountsReport(
    startDate: Date,
    endDate: Date,
    partnerId?: string,
    hedgingProducts?: HedgingInstruments[]
  ) {
    const params: any = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      partnerId: partnerId,
      sort: 'tradedAmount',
      sortDirection: 'DESC',
    });
    if (hedgingProducts) {
      params.hedgingProduct = hedgingProducts;
    }
    return this.get<PaginatedResponse<TopClientAccountsReportEntry>>(
      `${this.baseUrl}trades/reports/top-accounts/clients`,
      {
        axiosConfig: { params },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'clientTopAccountsReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + (partnerId ?? '') + hedgingProducts?.toString(),
          },
        },
      }
    );
  }

  public getClientTopNumberTradesReport(startDate: Date, endDate: Date, hedgingProducts?: HedgingInstruments[]) {
    const params: any = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      sort: 'tradesNumber',
      sortDirection: 'DESC',
    });
    if (hedgingProducts) {
      params.hedgingProduct = hedgingProducts;
    }
    return this.get<PaginatedResponse<TopClientAccountsReportEntry>>(
      `${this.baseUrl}trades/reports/top-accounts/clients`,
      {
        axiosConfig: { params },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'clientTopNumberTradesReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + hedgingProducts?.toString(),
          },
        },
      }
    );
  }

  public getClientTopVolumeTradesReport(startDate: Date, endDate: Date, hedgingProducts?: HedgingInstruments[]) {
    const params: any = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      sort: 'tradedAmount',
      sortDirection: 'DESC',
    });
    if (hedgingProducts) {
      params.hedgingProduct = hedgingProducts;
    }
    return this.get<PaginatedResponse<TopClientAccountsReportEntry>>(
      `${this.baseUrl}trades/reports/top-accounts/clients`,
      {
        axiosConfig: { params },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'clientTopVolumeTradesReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + hedgingProducts?.toString(),
          },
        },
      }
    );
  }

  public getPartnerCommissionsEarnedReport(startDate: Date, endDate: Date, hedgingProducts?: HedgingInstruments[]) {
    const params: any = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      sort: 'partnerProfit',
      sortDirection: 'DESC',
    });
    if (hedgingProducts) {
      params.hedgingProduct = hedgingProducts;
    }
    return this.get<PaginatedResponse<TopPartnerAccountsReportEntry>>(
      `${this.baseUrl}trades/reports/top-accounts/partners`,
      {
        axiosConfig: { params },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'partnerCommissionsEarnedReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + hedgingProducts?.toString(),
          },
        },
      }
    );
  }

  public getPartnerNumberTradesReport(startDate: Date, endDate: Date, hedgingProducts?: HedgingInstruments[]) {
    const params: any = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      sort: 'tradesNumber',
      sortDirection: 'DESC',
    });
    if (hedgingProducts) {
      params.hedgingProduct = hedgingProducts;
    }
    return this.get<PaginatedResponse<TopPartnerAccountsReportEntry>>(
      `${this.baseUrl}trades/reports/top-accounts/partners`,
      {
        axiosConfig: { params },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'partnerNumberTradesReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + hedgingProducts?.toString(),
          },
        },
      }
    );
  }

  public getPartnerVolumeTradesReport(startDate: Date, endDate: Date, hedgingProducts?: HedgingInstruments[]) {
    const params: any = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      sort: 'tradedAmount',
      sortDirection: 'DESC',
    });
    if (hedgingProducts) {
      params.hedgingProduct = hedgingProducts;
    }
    return this.get<PaginatedResponse<TopPartnerAccountsReportEntry>>(
      `${this.baseUrl}trades/reports/top-accounts/partners`,
      {
        axiosConfig: { params },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'partnerVolumeTradesReport',
            itemKey: startDate.toISOString() + endDate.toISOString(),
          },
        },
      }
    );
  }

  public getClientTopNumberTradesMonthlyReport(startDate: Date, endDate: Date, clientId?: string) {
    const params = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      clientId: clientId,
      sortDirection: 'DESC',
    });
    return this.get<PaginatedResponse<ClientMonthlyTradeCountReportEntry>>(
      `${this.baseUrl}trades/reports/count/monthly`,
      {
        axiosConfig: {
          params,
        },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'clientTopNumberTradesMonthlyReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + (clientId ?? ''),
          },
        },
      }
    );
  }

  public getClientTopVolumeTradesMonthlyReport(startDate: Date, endDate: Date, clientId?: string) {
    const params = standardiseQuerySort({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      clientId: clientId,
      sortDirection: 'DESC',
    });
    return this.get<PaginatedResponse<ClientMonthlyTradeVolumeReportEntry>>(
      `${this.baseUrl}trades/reports/transactions-volume/monthly`,
      {
        axiosConfig: {
          params,
        },
        options: {
          cache: {
            type: 'use',
            cacheKey: 'clientTopVolumeTradesMonthlyReport',
            itemKey: startDate.toISOString() + endDate.toISOString() + (clientId ?? ''),
          },
        },
      }
    );
  }

  public listDeltaTrades(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<HouseCash>>(`${this.baseUrl}deltatrades`, {
      axiosConfig: { params },
    });
  }

  public downloadDeltaTradeList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'House Cash List') {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}deltatrades/export`, query, fileFormat, documentTitle);
  }

  public settleVanillaTrade(tradeId: string) {
    return this.put<AdminTrade>(`${this.baseUrl}trades/vanilla-option/${tradeId}/settle`, undefined, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }

  public cancelVanillaTrade(tradeId: string, model: CancelAdminTrade) {
    return this.put<AdminTrade>(`${this.baseUrl}trades/vanilla-option/${tradeId}/cancel`, model, {
      options: {
        errors: {
          errorMessages: stateChangeErrorMessages,
        },
      },
    });
  }
}
