<script lang="ts" setup>
import {
  makeFormModel,
  toDataModel,
  getChildModel,
  setState,
  updateModel,
  batchSetState,
  setApiErrorMessages,
} from 'ah-common-lib/src/form/helpers';
import { partnerInformationForm } from 'ah-common-lib/src/form/formModels';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { AuthorityType, Partner } from 'ah-api-gateways';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpError, waitForCQRSEntityChange } from 'ah-requests';
import { useSettingsStore } from '@/app/store/settingsModule';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { isEqual } from 'lodash';
import { of } from 'rxjs';
import { useAuthStore } from '@/app/store/authStore';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';
import { computed, PropType, reactive, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useBvModal } from 'ah-common-lib/src/modals/useBvModal';
import { useToast } from 'ah-common-lib/src/toast';

const makePartnerDetailsFormModel = () => {
  const formModel = makeFormModel(partnerInformationForm(false));
  batchSetState(formModel, 'readonly', ['name', 'registrationNumber'], true);
  return formModel;
};

const props = defineProps({
  partner: {
    type: Object as PropType<Partner>,
    required: true,
  },
});

const emit = defineEmits({
  'update:partner': (_partner: Partner) => true,
});

const authStore = useAuthStore();

const settingsStore = useSettingsStore();

const requestManager = useRequestManager().manager;

const services = getServices();

const bvModal = useBvModal();

const toast = useToast();

const partnerDetailsFormDef = reactive<FormDefinition>({
  form: makePartnerDetailsFormModel(),
  validation: null,
});

const canEditPartner = computed(() => authStore.hasAuthorities(AuthorityType.UPDATE_PARTNER));

const anyDirty = computed(
  () => !isEqual({ ...props.partner, ...toDataModel(partnerDetailsFormDef.form) }, props.partner)
);

function reset() {
  if (props.partner) updateModel(partnerDetailsFormDef.form, props.partner);
}

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    setState(formEvent.model, 'errors', []);
  }
}

function saveModelRequest() {
  const partnerUpdate = toDataModel(partnerDetailsFormDef.form);

  requestManager
    .sameOrCancelAndNew(
      'saveModel',
      services.partner.updatePartner(partnerUpdate).pipe(
        mergeMap((idEntity) =>
          waitForCQRSEntityChange(idEntity, () => services.partner.getPartner({ errors: { silent: true } }), {
            checkProcessRestart: () => {
              return bvModal
                .msgBoxConfirm(
                  'Partner has been updated successfully, but our system may take some time to display it. Would you like to keep waiting?',
                  {
                    title: 'Continue loading?',
                    okTitle: 'Continue',
                    centered: true,
                  }
                )
                .then((value) => !!value);
            },
          }).pipe(catchError(() => of(null)))
        )
      )
    )
    .subscribe(
      (response) => {
        if (response) {
          toast.success('Partner updated successfully.');
          emit('update:partner', {
            ...props.partner,
            ...partnerUpdate,
          } as Partner);
        } else {
          toast.error('An unexpected problem has occurred. Please try again later.');
        }
      },
      (error: HttpError) => {
        const apiErrors = constructPayloadErrors<Partner>(error.response!.data);
        if (apiErrors.fields?.address) {
          setApiErrorMessages(apiErrors.fields.address, partnerDetailsFormDef.form.address);
        }
      }
    );
}

function loadCountryOptions() {
  const countryCode = getChildModel(partnerDetailsFormDef.form, 'address.countryCode')!;
  if (countryCode) {
    setState(countryCode, 'readonly', true);
    settingsStore.loadCountries().then(() => {
      setState(
        countryCode,
        'options',
        settingsStore.allCountries.map((c) => ({
          value: c.cc,
          label: c.name,
        }))
      );
    });
  }
}

watch(
  canEditPartner,
  () => {
    setState(partnerDetailsFormDef.form, 'readonly', !canEditPartner.value);
  },
  { immediate: true }
);

watch(
  () => props.partner,
  () => {
    if (props.partner) {
      updateModel(partnerDetailsFormDef.form, props.partner, undefined, true);
    }
  },
  { immediate: true }
);

loadCountryOptions();
</script>

<template>
  <div class="change-contacts-form">
    <ValidatedForm
      :fm="partnerDetailsFormDef.form"
      :validation.sync="partnerDetailsFormDef.validation"
      @form-event="onFormEvent"
    />
    <div class="form-actions text-right">
      <VButton
        class="btn btn-secondary mx-2"
        :disabled="!anyDirty || requestManager.requestStates.saveModel === 'pending'"
        label="Reset"
        @click="reset"
      />
      <VButton
        :disabled="!anyDirty"
        :loading="requestManager.requestStates.saveModel === 'pending'"
        label="Save"
        class="btn btn-success ml-2"
        @click="saveModelRequest()"
      />
    </div>
    <RouteProtectorModal
      :allowChange="!anyDirty"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
  </div>
</template>
