export enum BeneficiaryType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export const beneficiaryTypeLabels: Record<BeneficiaryType, string> = {
  [BeneficiaryType.INDIVIDUAL]: 'Individual',
  [BeneficiaryType.COMPANY]: 'Company',
};

export enum BeneficiaryCategory {
  PARTNER_PERSONAL = 'PARTNER_PERSONAL',
  CLIENT_PERSONAL = 'CLIENT_PERSONAL',
  PARTNER_3RD_PARTY = 'PARTNER_3RD_PARTY',
  CLIENT_3RD_PARTY = 'CLIENT_3RD_PARTY',
}

export enum BeneficiaryStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum BeneficiaryIdentificationType {
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
  GREEN_CARD = 'GREEN_CARD',
  PASSPORT = 'PASSPORT',
  VISA = 'VISA',
  MATRICULA_CONSULAR = 'MATRICULA_CONSULAR',
  REGISTRO_FEDERAL_DE_CONTRIBUYENTES = 'REGISTRO_FEDERAL_DE_CONTRIBUYENTES',
  CLAVE_UNICA_DE_REGISTRO_DE_POBLACION = 'CLAVE_UNICA_DE_REGISTRO_DE_POBLACION',
  CREDENTIAL_DE_ELECTOR = 'CREDENTIAL_DE_ELECTOR',
  SOCIAL_INSURANCE_NUMBER = 'SOCIAL_INSURANCE_NUMBER',
  CITIZENSHIP_PAPERS = 'CITIZENSHIP_PAPERS',
  DRIVERS_LICENSE_CANADIAN = 'DRIVERS_LICENSE_CANADIAN',
  EXISTING_CREDIT_CARD_DETAILS = 'EXISTING_CREDIT_CARD_DETAILS',
  EMPLOYER_IDENTIFICATION_NUMBER = 'EMPLOYER_IDENTIFICATION_NUMBER',
  NATIONAL_ID = 'NATIONAL_ID',
  INCORPORATION_NUMBER = 'INCORPORATION_NUMBER',
  OTHERS = 'OTHERS',
}

export const beneficiaryIdentificationTypeLabels: Record<BeneficiaryIdentificationType, string> = {
  [BeneficiaryIdentificationType.DRIVERS_LICENSE]: 'Drivers License',
  [BeneficiaryIdentificationType.SOCIAL_SECURITY_NUMBER]: 'Social Security Number',
  [BeneficiaryIdentificationType.GREEN_CARD]: 'Green Card',
  [BeneficiaryIdentificationType.PASSPORT]: 'Passport',
  [BeneficiaryIdentificationType.VISA]: 'Visa',
  [BeneficiaryIdentificationType.MATRICULA_CONSULAR]: 'Matricula Consular',
  [BeneficiaryIdentificationType.REGISTRO_FEDERAL_DE_CONTRIBUYENTES]: 'Registro Federal De Contribuyentes',
  [BeneficiaryIdentificationType.CLAVE_UNICA_DE_REGISTRO_DE_POBLACION]: 'Clave Unica De Registro De Población',
  [BeneficiaryIdentificationType.CREDENTIAL_DE_ELECTOR]: 'Credential De Elector',
  [BeneficiaryIdentificationType.SOCIAL_INSURANCE_NUMBER]: 'Social Insurance Number',
  [BeneficiaryIdentificationType.CITIZENSHIP_PAPERS]: 'Citizenship Papers',
  [BeneficiaryIdentificationType.DRIVERS_LICENSE_CANADIAN]: 'Drivers License (Canadian)',
  [BeneficiaryIdentificationType.EXISTING_CREDIT_CARD_DETAILS]: 'Existing Credit Card Details',
  [BeneficiaryIdentificationType.EMPLOYER_IDENTIFICATION_NUMBER]: 'Employer Identification Number',
  [BeneficiaryIdentificationType.NATIONAL_ID]: 'National Id',
  [BeneficiaryIdentificationType.INCORPORATION_NUMBER]: 'Incorporation Number',
  [BeneficiaryIdentificationType.OTHERS]: 'Others',
};

export const beneficiaryStatusLabels: Record<BeneficiaryStatus, string> = {
  [BeneficiaryStatus.APPROVED]: 'Approved',
  [BeneficiaryStatus.PENDING]: 'Pending',
  [BeneficiaryStatus.REJECTED]: 'Rejected',
};
