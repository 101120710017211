<script setup lang="ts">
import SettingsSidebarView from '@/app/views/settings/SettingsSidebarView.vue';
import PartnerSettingsSidebarView from '@/app/views/admin/settings/PartnerSettingsSidebarView.vue';
import { useAuthStore } from '@/app/store/authStore';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

defineProps({
  basePath: {
    type: String,
    default: '/settings',
  },
});

const settingsOpen = ref<boolean>(false);

const authStore = useAuthStore();

const route = useRoute();

const inSettings = computed(() => route.fullPath.includes('/settings'));

const isAgent = computed(() => authStore.isAgent);

watch(
  () => route.fullPath,
  () => (settingsOpen.value = inSettings.value),
  { immediate: true }
);

function toggleSettings() {
  settingsOpen.value = !settingsOpen.value;
}
</script>

<template>
  <span>
    <div tag="li" class="menu-link" :class="{ 'router-link-active': inSettings }" v-if="$mediaQuery.is('smOnly')">
      <!-- Visible only on phablets displays -->
      <IconSettings /> <span class="menu-link-text"> Settings </span>
      <span>
        <span @click="toggleSettings()"><IconChevronDown class="toggle-icon" :class="{ open: settingsOpen }" /></span>
        <ExpandTransition appear>
          <PartnerSettingsSidebarView
            v-if="settingsOpen && isAgent"
            :path="basePath"
            class="router-view"
            name="sidebar"
          />
          <SettingsSidebarView v-if="settingsOpen && !isAgent" :path="basePath" class="router-view" name="sidebar" />
        </ExpandTransition>
      </span>
    </div>

    <RouterLink tag="li" class="menu-link" :to="basePath" v-else>
      <IconSettings />
    </RouterLink>
  </span>
</template>

<style lang="scss" scoped>
.toggle-icon {
  float: right;
  transition: transform 0.5s;
  font-size: 1.5em;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
