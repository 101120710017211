<template>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5297 4.3477L12.1934 0.0438203C12.0482 -0.0146068 11.8851 -0.0146068 11.7399 0.0438203L0.403661 4.3477C0.289975 4.39079 0.191487 4.46492 0.120032 4.56118C0.0485768 4.65744 0.0071939 4.77174 0.000855714 4.89034C-0.00548248 5.00895 0.0234936 5.12681 0.0843024 5.22977C0.145111 5.33272 0.235166 5.4164 0.343646 5.47074L4.41804 7.45715V12.6049C4.4427 13.0074 4.57187 13.3972 4.79347 13.7378C5.01506 14.0784 5.32182 14.3587 5.68503 14.5524C7.13874 15.5066 9.61271 16 12 16C14.3873 16 16.8613 15.5066 18.315 14.5524C18.6782 14.3587 18.9849 14.0784 19.2065 13.7378C19.4281 13.3972 19.5573 13.0074 19.582 12.6049V7.45715L20.8423 6.80799V12.8841C20.8861 13.0139 20.971 13.127 21.0847 13.2072C21.1985 13.2874 21.3354 13.3305 21.4758 13.3305C21.6162 13.3305 21.7531 13.2874 21.8668 13.2072C21.9806 13.127 22.0654 13.0139 22.1093 12.8841V6.22376L23.6564 5.47074C23.7648 5.4164 23.8549 5.33272 23.9157 5.22977C23.9765 5.12681 24.0055 5.00895 23.9991 4.89034C23.9928 4.77174 23.9514 4.65744 23.88 4.56118C23.8085 4.46492 23.71 4.39079 23.5963 4.3477H23.5297ZM2.11744 4.99686L11.9333 1.27072L21.7492 4.99037L12.9736 9.29425L11.9333 9.76813L10.893 9.29425L2.11744 4.99686ZM18.2483 10.6834V12.6309C18.2483 13.5332 15.8477 14.7861 11.9333 14.7861C8.01897 14.7861 5.61835 13.5073 5.61835 12.6049V8.06086L8.65913 9.54742L10.5996 10.5017L11.6199 11.0015C11.7079 11.0481 11.8065 11.0725 11.9066 11.0725C12.0068 11.0725 12.1054 11.0481 12.1934 11.0015L13.267 10.5017L15.2342 9.55391L18.275 8.06735L18.2483 10.6834Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
