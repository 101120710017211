import { CountryInfo } from 'ah-api-gateways';
import countries from '../constants/countries';

export const countriesWithStates = ['MX', 'CA', 'US'];

export function countryHasStates(cc?: string) {
  if (!cc) return false;
  return countriesWithStates.includes(cc);
}

export function countryNameFromCC(value?: string, countriesList?: CountryInfo[]) {
  if (!value) return '';
  if (countriesList) {
    const countryCode = countriesList.find((c) => c.cc === value);
    return countryCode ? countryCode.name : '';
  } else {
    const countryCode = countries.find((c) => c.value === value);
    return countryCode ? countryCode.label : '';
  }
}
