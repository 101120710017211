<script lang="ts" setup>
import annotationPlugin from 'chartjs-plugin-annotation';
import doughnutLabel from 'chartjs-plugin-doughnutlabel-rebourne';

import {
  Chart,
  ChartType,
  ChartData,
  ChartOptions,
  Point,
  DoughnutController,
  LineController,
  Tooltip,
  TimeScale,
  LinearScale,
  BarController,
  ArcElement,
  PointElement,
  CategoryScale,
  LineElement,
  BarElement,
  Filler,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { PropType, ref, watch, onMounted } from 'vue';

Chart.register(
  DoughnutController,
  LineController,
  Tooltip,
  TimeScale,
  LinearScale,
  BarController,
  ArcElement,
  PointElement,
  CategoryScale,
  BarElement,
  LineElement,
  Filler,
  annotationPlugin,
  Legend,
  doughnutLabel
);

const chartRef = ref<InstanceType<typeof HTMLCanvasElement> | null>(null);

const props = defineProps<{
  type: string | PropType<ChartType>;
  options: ChartOptions;
  data: ChartData;
}>();

const emit = defineEmits<{
  (e: 'update:chart', value: Chart): void;
}>();

let chart: Chart | undefined = undefined;

watch(
  () => props.options,
  () => {
    if (chart) {
      const options = cloneDeep(props.options);
      chart.options = options;
      chart.update('none');
    }
  }
);

/**
 * Update on dataset change, with 0 -> value animation (better when changing number of points)
 */
watch(
  () => props.data,
  () => {
    if (chart) {
      const zeroedData = {
        ...props.data,
        datasets: props.data.datasets?.map((d) => {
          const out = { ...d };
          if (out.data) {
            out.data = (out.data as Point[]).map((i) => {
              const out = { ...i };
              if (out.y) {
                out.y = 0;
              }
              return out;
            });
          }
          return out;
        }),
      };
      chart.data = zeroedData;
      chart.update('none');
      setTimeout(() => {
        chart!.data = props.data;
        chart!.update();
      });
    }
  }
);

onMounted(() => {
  const options = cloneDeep(props.options);
  const data = cloneDeep(props.data);

  chart = new Chart(chartRef.value!, {
    options,
    data,
    type: props.type as any,
  });
  emit('update:chart', chart);
});
</script>

<template>
  <div class="chart">
    <div :class="['chart-container', { loaded: chartRef }]">
      <slot />
      <canvas ref="chartRef"></canvas>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  position: relative;
  .chart-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s;

    &.loaded {
      opacity: 1;
    }
  }
}
</style>
