var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"x-test-name":"registration-documents-form"}},[_c('div',[_c('h2',[_vm._v("Documentation")]),(_setup.isCompany)?[(_setup.isOnboardingDocumentsActive)?[_c('div',{staticClass:"card-block individual-documents row mb-3"},[_c('div',{staticClass:"col col-12"},[_c('h3',{staticClass:"mt-3 col col-12"},[_vm._v(" Verification of Authorised Signatory "),(_setup.owner && _setup.secondaryOwner)?[_vm._v("- "+_vm._s(_setup.owner.firstName)+" "+_vm._s(_setup.owner.lastName))]:_vm._e()],2),_c(_setup.ClientDocumentsUploader,{attrs:{"files":_setup.state.files,"categories":[_setup.ClientFileCategories.PHOTO_ID, _setup.ClientFileCategories.PROOF_OF_ADDRESS]},on:{"update:files":function($event){return _vm.$set(_setup.state, "files", $event)}}})],1)]),(_setup.secondaryOwner)?_c('div',{staticClass:"card-block individual-documents row mb-3"},[_c('div',{staticClass:"col col-12"},[_c('h3',{staticClass:"mt-3 col col-12"},[_vm._v(" Verification of Secondary Signatory - "+_vm._s(_setup.secondaryOwner.firstName)+" "+_vm._s(_setup.secondaryOwner.lastName)+" ")]),_c(_setup.ClientDocumentsUploader,{attrs:{"files":_setup.state.files,"categories":[
                _setup.ClientFileCategories.SECONDARY_OWNER_PHOTO_ID,
                _setup.ClientFileCategories.SECONDARY_OWNER_PROOF_OF_ADDRESS,
              ]},on:{"update:files":function($event){return _vm.$set(_setup.state, "files", $event)}}})],1)]):_vm._e(),_c('div',{staticClass:"card-block individual-documents row mb-3"},[_c('div',{staticClass:"col col-12"},[_c('h3',{staticClass:"mt-3 col col-12"},[_vm._v("Company Documents")]),_c(_setup.ClientDocumentsUploader,{attrs:{"files":_setup.state.files,"categories":[
                _setup.ClientFileCategories.INCORPORATED_DOCUMENT,
                _setup.ClientFileCategories.FINANCIAL_STATEMENT,
                _setup.ClientFileCategories.SAMPLE_INVOICE,
              ]},on:{"update:files":function($event){return _vm.$set(_setup.state, "files", $event)}}})],1)])]:_vm._e(),_c(_setup.UBOForm,{staticClass:"my-3",attrs:{"files":_setup.state.files,"ubos":_setup.state.uboUsers,"validation":_setup.state.uboValidation,"showUboAddressHistoryErrors":_setup.showUboAddressHistoryErrors},on:{"update:files":function($event){return _vm.$set(_setup.state, "files", $event)},"update:ubos":function($event){return _vm.$set(_setup.state, "uboUsers", $event)},"update:validation":function($event){return _vm.$set(_setup.state, "uboValidation", $event)}}})]:[_c('div',{staticClass:"card-block individual-documents row mb-3"},[_c('div',{staticClass:"col col-12"},[_c('h3',{staticClass:"mt-3 col col-12"},[_vm._v("Verification of Authorised Signatory")]),_c(_setup.ClientDocumentsUploader,{attrs:{"files":_setup.state.files,"categories":_setup.isOnboardingDocumentsActive
                ? [
                    _setup.ClientFileCategories.PHOTO_ID,
                    _setup.ClientFileCategories.PROOF_OF_ADDRESS,
                    _setup.ClientFileCategories.PROOF_OF_FUNDS,
                  ]
                : [_setup.ClientFileCategories.PROOF_OF_FUNDS]},on:{"update:files":function($event){return _vm.$set(_setup.state, "files", $event)}}})],1)])],(_setup.showUploadLaterCheckbox)?_c('div',[_c('ValidatedForm',{staticClass:"my-3",attrs:{"fm":_setup.uploadLaterFM.form,"validation":_setup.uploadLaterFM.validation},on:{"update:validation":function($event){return _vm.$set(_setup.uploadLaterFM, "validation", $event)}}})],1):_vm._e()],2),(_setup.termsAndConditionsRequired)?_c('div',[_c('h2',[_vm._v("Terms and Conditions of Service")]),_c(_setup.TermsAcceptanceForm,{attrs:{"validation":_setup.state.termsValidation},on:{"update:validation":function($event){return _vm.$set(_setup.state, "termsValidation", $event)}}})],1):_vm._e(),_c('div',{staticClass:"buttons-holder"},[_c('VButton',{attrs:{"loading":_setup.requestManager.anyPending,"disabled":_setup.validation.$invalid},on:{"click":_setup.submit}},[_vm._v(" Continue ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }