import { HttpBasedService, HttpService, HttpOptions, CustomAxiosRequestConfig } from 'ah-requests';
import { Observable } from 'rxjs';
import {
  ExportListType,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  HistoricalTransaction,
  ScheduledTransaction,
  FeeEntry,
  FeesInfo,
  CommissionSchedule,
  TransactionReason,
  ListQuery,
  DocumentExport,
} from '../models';
import {
  WalletsAssociatedFundingDetailsFilters,
  TransactionDirection,
  TransactionState,
  TransactionType,
  Wallet,
  WalletFundingDetails,
  WalletsListFilters,
  WalletAssociatedFundingDetails,
} from '../models/wallet';

export type WalletsPaginatedQuery = PaginatedQuery<WalletsListFilters>;

export type WalletsAssociatedFundingDetailsPaginatedQuery = PaginatedQuery<WalletsAssociatedFundingDetailsFilters>;

export type FeesPaginatedQuery = PaginatedQuery<{
  partnerId: string;
  clientId: string;
  partnerWallet: boolean;
  fromWalletId: string;
}>;

export interface TransactionQuery {
  transactionId: string | string[];
  docCreatedAtFrom: string;
  docCreatedAtTo: string;
  trackingId: string | string[];
  walletId: string | string[];
  partnerId: string | string[];
  clientId: string | string[];
  tradeId: string | string[];
  scheduleTxId: string | string[];
  direction: TransactionDirection;
  type: TransactionType | TransactionType[];
  state: TransactionState | TransactionState[];
  reason: TransactionReason | TransactionReason[];
  otherWalletId: string | string[];
  otherBeneficiaryId: string | string[];
}

export type TransactionPaginatedQuery = PaginatedQuery<TransactionQuery>;

export class WalletService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private nonAdminbaseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'walletService' } },
      },
    });
  }

  public listWallets(
    query?: WalletsPaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<Wallet>>>
  ): Observable<PaginatedResponse<Wallet>> {
    return this.get(`${this.baseUrl}wallets`, {
      axiosConfig: {
        params: query ? standardiseQuerySort(query) : undefined,
      },
      options,
    });
  }

  /**
   * clientId - must be defined in 'On Behalf Of' requests
   */
  public getWallet(walletId: string, oboClientId?: string, options?: Partial<HttpOptions<Wallet>>): Observable<Wallet> {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;
    return this.get(`${this.baseUrl}wallets/${walletId}`, { axiosConfig: { headers }, options });
  }

  public downloadWallets(query: Partial<WalletsListFilters>, fileFormat: ExportListType, documentTitle = 'Wallets') {
    return this.downloadFile(`${this.baseUrl}wallets/export`, query, fileFormat, documentTitle);
  }

  public getWalletAssociatedFundingDetails(
    walletId: string,
    options?: Partial<HttpOptions<WalletAssociatedFundingDetails>>
  ): Observable<WalletAssociatedFundingDetails> {
    return this.get(`${this.baseUrl}wallets/funding-details/${walletId}`, { options });
  }

  public listWalletsAssociatedFundingDetails(
    query?: WalletsAssociatedFundingDetailsPaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<WalletAssociatedFundingDetails>>>
  ): Observable<PaginatedResponse<WalletAssociatedFundingDetails>> {
    return this.get(`${this.baseUrl}wallets/funding-details`, {
      axiosConfig: {
        params: query ? standardiseQuerySort(query) : undefined,
      },
      options,
    });
  }

  public downloadWalletsAssociatedFundingDetails(
    query: Partial<WalletsAssociatedFundingDetailsFilters>,
    fileFormat: ExportListType,
    documentTitle = 'Wallets Funding Details'
  ) {
    return this.downloadFile(`${this.baseUrl}wallets/funding-details/export`, query, fileFormat, documentTitle);
  }

  public getWalletTransaction(txId: string, options?: Partial<HttpOptions<HistoricalTransaction>>) {
    return this.get<HistoricalTransaction>(`${this.baseUrl}wallets/transactions/${txId}`, { options });
  }

  public listWalletTransactions(
    query?: TransactionPaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<HistoricalTransaction>>>
  ) {
    return this.get<PaginatedResponse<HistoricalTransaction>>(`${this.baseUrl}wallets/transactions`, {
      axiosConfig: {
        params: query ? standardiseQuerySort(query) : undefined,
      },
      options,
    });
  }

  public listWalletScheduledTransactions(
    walletId: string,
    query?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<ScheduledTransaction>>>
  ) {
    return this.get<PaginatedResponse<ScheduledTransaction>>(`${this.baseUrl}wallets/${walletId}/schedules`, {
      axiosConfig: {
        params: query ? standardiseQuerySort(query) : undefined,
      },
      options,
    });
  }

  public downloadWalletStatement(
    walletId: string,
    query: Partial<TransactionQuery>,
    fileFormat: ExportListType,
    documentTitle = 'Wallet Statement'
  ) {
    return this.downloadFile(
      `${this.baseUrl}wallets/${walletId}/transactions/export`,
      { ...query },
      fileFormat,
      documentTitle
    );
  }

  public downloadTransactionDetails(
    walletId: string,
    txIds: string[] | string,
    fileFormat: ExportListType,
    documentTitle = 'Transaction Details',
    query?: Partial<TransactionQuery>
  ) {
    return this.downloadFile(
      `${this.baseUrl}wallets/${walletId}/transactions/export`,
      { transactionId: txIds, ...query },
      fileFormat,
      documentTitle
    );
  }

  public downloadFeeTransactionDetails(
    feeId: string,
    fileFormat: ExportListType,
    documentTitle = 'Fee Transaction Details'
  ) {
    return this.downloadFile(`${this.baseUrl}wallets/fees/${feeId}/transactions/export`, {}, fileFormat, documentTitle);
  }

  public downloadBeneficiaryTransactionDetails(
    beneficiaryId: string,
    query: Partial<TransactionQuery>,
    fileFormat: ExportListType,
    documentTitle = 'Beneficiary Transaction Statement'
  ) {
    return this.downloadFile(
      `${this.baseUrl}wallets/beneficiary/${beneficiaryId}/transactions/export`,
      { ...query },
      fileFormat,
      documentTitle
    );
  }

  public downloadBalanceCertificate(
    oboClientId?: string,
    fileFormat: ExportListType = ExportListType.PDF,
    documentTitle = 'Balance Certificate'
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.downloadFile(
      `${this.nonAdminbaseUrl}wallets/balances/certificate/export`,
      {},
      fileFormat,
      documentTitle,
      {
        headers,
      }
    );
  }

  private downloadFile(
    url: string,
    query?: ListQuery,
    fileFormat?: ExportListType,
    documentTitle?: string,
    axiosConfig?: CustomAxiosRequestConfig<DocumentExport>
  ) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
        ...axiosConfig,
      },
    });
  }

  public getWalletFundingDetails(
    currency: string,
    clientId?: string,
    oboClientId?: string,
    options?: Partial<HttpOptions<WalletFundingDetails[]>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.get<WalletFundingDetails[]>(`${this.baseUrl}funding-details`, {
      axiosConfig: {
        headers,
        params: {
          clientId,
          currency,
        },
      },
      options,
    });
  }

  public getPartnerFeesInfo(options?: Partial<HttpOptions<FeesInfo>>) {
    return this.get<FeesInfo>(`${this.baseUrl}wallets/fees-debt/partner/`, { options });
  }

  public getClientFeesInfo(clientId: string, options?: Partial<HttpOptions<FeesInfo>>) {
    return this.get<FeesInfo>(`${this.baseUrl}wallets/fees-debt/client/${clientId}`, { options });
  }

  public listFeeEntries(query: FeesPaginatedQuery, options?: Partial<HttpOptions<PaginatedResponse<FeeEntry>>>) {
    return this.get<PaginatedResponse<FeeEntry>>(`${this.baseUrl}fees/transactions`, {
      axiosConfig: {
        params: standardiseQuerySort(query),
      },
      options,
    });
  }

  public settleFees(clientId: string, oboClientId?: string, options?: Partial<HttpOptions<void>>) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<void>(`${this.baseUrl}wallets/fees/client/${clientId}/settle`, null, {
      axiosConfig: { headers },
      options,
    });
  }

  public getWithdrawalCommissions() {
    return this.get<CommissionSchedule>(`${this.baseUrl}wallets/reports/withdrawals/commissions`);
  }
}
