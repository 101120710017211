<script lang="ts" setup>
import { NotificationSetting, NotificationChannelType, NotificationChannelSetting } from 'ah-api-gateways';
import cloneDeep from 'lodash/cloneDeep';
import { computed, PropType } from 'vue';

const props = defineProps({
  showTitles: {
    default: false,
    type: [Boolean, String],
  },
  notification: {
    required: true,
    type: Object as PropType<NotificationSetting>,
  },
  channels: {
    required: true,
    type: Array as PropType<NotificationChannelSetting[]>,
  },
});

const emit = defineEmits({
  'update:notification': (_notification: NotificationSetting) => true,
});

const titlesShown = computed(() => props.showTitles !== false);

const isTargettingEmail = computed(
  () => !!props.notification?.channels?.find((e) => e.type === NotificationChannelType.EMAIL && e.enabled)
);

const isTargettingInApp = computed(
  () => !!props.notification?.channels?.find((e) => e.type === NotificationChannelType.IN_APP && e.enabled)
);

const isTargettingSms = computed(
  () => !!props.notification?.channels?.find((e) => e.type === NotificationChannelType.SMS && e.enabled)
);

const isConfigurable = computed(() => {
  return (channelType: NotificationChannelType) => {
    const channel = props.channels.filter((channel) => channel.channelType === channelType)![0];
    if (!channel.editable) return null;
    return channel.configurable;
  };
});

const defaultValue = computed(() => {
  return (channelType: NotificationChannelType) => {
    const channel = props.channels.filter((channel) => channel.channelType === channelType)![0];
    return channel.defaultEnabledValue;
  };
});

function onTargetChange(type: NotificationChannelType, enabled: boolean) {
  const channels = cloneDeep(props.notification?.channels || []);
  const index = channels.findIndex((i) => i.type === type);
  if (index === -1) {
    channels.push({ type, enabled });
  } else {
    channels[index].enabled = enabled;
  }
  emit('update:notification', { ...props.notification, channels });
}
</script>

<template>
  <VRow>
    <VCol class="switch-col email-col" v-if="isConfigurable(NotificationChannelType.EMAIL) !== null">
      <h4 v-if="titlesShown" class="col-inner-title">Email notification</h4>
      <BFormCheckbox
        v-if="isConfigurable(NotificationChannelType.EMAIL)"
        :checked="isTargettingEmail"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.EMAIL, $event)"
      />
      <div class="mr-2" v-else-if="defaultValue(NotificationChannelType.EMAIL)">
        <IconCircleCheck class="check" />
      </div>
    </VCol>
    <VCol class="switch-col in-app-col" v-if="isConfigurable(NotificationChannelType.IN_APP) !== null">
      <h4 v-if="titlesShown" class="col-inner-title">In-app notification</h4>
      <BFormCheckbox
        v-if="isConfigurable(NotificationChannelType.IN_APP)"
        :checked="isTargettingInApp"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.IN_APP, $event)"
      />
      <div class="mr-2" v-else-if="defaultValue(NotificationChannelType.IN_APP)">
        <IconCircleCheck class="check" />
      </div>
    </VCol>
    <VCol class="switch-col sms-col" v-if="isConfigurable(NotificationChannelType.SMS) !== null">
      <h4 v-if="titlesShown" class="col-inner-title">SMS notification</h4>
      <BFormCheckbox
        v-if="isConfigurable(NotificationChannelType.SMS)"
        :checked="isTargettingSms"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.SMS, $event)"
      />
      <div class="mr-2" v-if="defaultValue(NotificationChannelType.SMS)">
        <IconCircleCheck class="check" />
      </div>
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.check {
  @include themedTextColor($color-success);
  width: 29px;
  height: 29px;
}
</style>
