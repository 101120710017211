<script setup lang="ts">
import { computed } from 'vue';
import { FundingType, fundingTypesToHuman, PartnerTrade, Trade } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

const props = defineProps<{
  trade: Trade | PartnerTrade;
}>();

const isBoth = computed(() => props.trade.initialMarginFundingType === FundingType.COLLATERAL_AND_CREDIT);

const fundingType = computed(() => fundingTypesToHuman[props.trade.initialMarginFundingType ?? FundingType.UNDEFINED]);
</script>

<template>
  <div v-if="trade.initialMarginFundingType === FundingType.COLLATERAL">
    {{ fundingTypesToHuman[FundingType.COLLATERAL] }}
    {{ trade.initialMarginFundingCurrency }}
    {{ formatCurrencyValue(trade.initialMarginCollateralFundingAmount) }}
  </div>
  <div v-else-if="trade.initialMarginFundingType === FundingType.CREDIT">
    {{ fundingTypesToHuman[FundingType.CREDIT] }}
    {{ trade.initialMarginFundingCurrency }}
    {{ formatCurrencyValue(trade.initialMarginCreditFundingAmount) }}
  </div>
  <div v-else-if="isBoth">
    <VRow class="mb-lg-0 mb-md-3">
      <VCol class="funding-type-label" lg="6" md="12">{{ fundingTypesToHuman[FundingType.COLLATERAL] }}</VCol>
      <VCol class="ml-3">
        {{ trade.initialMarginFundingCurrency }}
        {{ formatCurrencyValue(trade.initialMarginCollateralFundingAmount) }}
      </VCol>
    </VRow>
    <VRow class="mb-lg-0 mb-md-3">
      <VCol class="funding-type-label" lg="6" md="12">
        {{ fundingTypesToHuman[FundingType.CREDIT] }}
      </VCol>
      <VCol class="ml-3">
        {{ trade.initialMarginFundingCurrency }}
        {{ formatCurrencyValue(trade.initialMarginCreditFundingAmount) }}
      </VCol>
    </VRow>
  </div>
  <div v-else>
    {{ fundingType }}
  </div>
</template>

<style scoped lang="scss">
.funding-type-label {
  white-space: nowrap;
}
</style>
