import Hotjar from '@hotjar/browser';
import config from '@/config';
import { storeSetupDone } from './store';
import { useAuthStore } from './store/authStore';
import { watch } from 'vue';

export function setupHotjar() {
  if (config.hotjarId) {
    Hotjar.init(config.hotjarId, 6, {
      debug: !config.isProduction,
    });

    storeSetupDone.then(() => {
      const authStore = useAuthStore();
      watch(
        () => authStore.isLoggedIn,
        (isLoggedIn) => {
          Hotjar.identify(
            authStore.loggedInIdentity?.id || null,
            authStore.loggedInIdentity
              ? {
                  email: authStore.loggedInIdentity.email,
                  username: authStore.loggedInIdentity.username || '',
                }
              : {}
          );

          Hotjar.event(isLoggedIn ? 'login' : 'logout');
        },
        { immediate: true }
      );
    });
  }
}
