import { Observable, of, from, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { HttpError } from '..';

export interface WaitOptions<T> {
  /**
   * Total number of attempts to make
   *
   * After these, the process will stop, and checkProcessRestart/checkContinueWithLastRetry will be triggered (see below)
   */
  retries: number;
  /**
   * Delay between attempts
   */
  delay: number;
  /**
   * If true, 404s will not throw errors.
   *
   * This is useful when creating entities
   */
  ignoreErrors?: (e: any) => boolean;
  /**
   * Potential restart process promise
   *
   * Triggered when retries run out
   * If successful (promise resolving to `true`) the process will restart
   */
  checkProcessRestart?: (
    lastResponse?: T
  ) => boolean | { retries: number; delay: number } | Promise<boolean | { retries: number; delay: number }>;
  /**
   * Potential continue process promise
   *
   * Triggered when retries run out, if checkProcessRestart fails or does not exist
   * Only triggered if response was SUCCESSFUL, and yet failed the wait checks
   * If unsuccessful (promise rejecting or returning `false`) the process will be cancelled with the rejection message
   */
  checkContinueWithLastRetry?: (lastResponse: T) => T | Promise<T>;
}

let optionDefaults: WaitOptions<any> = {
  retries: 15,
  delay: 1500,
};

export function setWaitDefaults<T>(options?: Partial<WaitOptions<T>>) {
  optionDefaults = {
    ...optionDefaults,
    ...options,
  };
}

/**
 * Waits for an entity to change (performing multiple GET requests on that entity), and returns that entity when changed
 */
export function waitForEntityChange<T>(
  requestFactory: () => Observable<T>,
  changeTest: (payload: T) => boolean,
  options?: Partial<WaitOptions<T>>
): Observable<T> {
  return new Observable((sub) => {
    const opts: WaitOptions<T> = {
      ...optionDefaults,
      ...options,
    };

    function process(currOpts: WaitOptions<T>): Observable<T> {
      return requestFactory().pipe(
        catchError((e) => {
          if (currOpts.ignoreErrors && currOpts.ignoreErrors(e)) {
            return of(undefined);
          }
          return throwError(e);
        }),
        mergeMap((response) => {
          const entityChanged = !!response && changeTest(response);
          if (entityChanged) {
            return of(response);
          }
          if (currOpts.retries > 0) {
            return timer(currOpts.delay).pipe(
              take(1),
              mergeMap(() => process({ ...currOpts, retries: currOpts.retries - 1 }))
            );
          }
          return from(Promise.resolve(currOpts.checkProcessRestart?.(response) || false)).pipe(
            take(1),
            mergeMap((val) => {
              if (val) {
                let options = { ...opts };
                if (typeof val === 'object') {
                  options = { ...options, ...val };
                }
                return process(options);
              }
              if (response && currOpts.checkContinueWithLastRetry) {
                return Promise.resolve(currOpts.checkContinueWithLastRetry(response));
              }
              return throwError('Change not detected after all delays expired');
            })
          );
        })
      );
    }

    process(opts).subscribe(sub);
  });
}

/**
 * Waits for an entity to be created (performig multiple GET requests on that entity), and returns that entity when created
 */
export function waitForEntityCreation<T>(
  requestFactory: () => Observable<T>,
  options?: Partial<WaitOptions<T>>
): Observable<T> {
  return waitForEntityChange(requestFactory, () => true, {
    ...options,
    ignoreErrors(e: HttpError) {
      return (options?.ignoreErrors && options?.ignoreErrors(e)) || e.response?.status === 404;
    },
  });
}

/**
 * Waits for an entity to be created (performig multiple GET requests on that entity), and returns that entity when created
 */
export function waitForCQRSEntityChange<T extends { version: number }>(
  entityToCompare: { version: number },
  requestFactory: () => Observable<T>,
  options?: Partial<WaitOptions<T>>
): Observable<T> {
  return waitForEntityChange(requestFactory, (item: T) => item.version >= entityToCompare.version, {
    ...options,
  });
}

/**
 * Waits for an entity to be deleted (performig multiple GET requests on that entity), and true when that happens
 */
export function waitForEntityDeletion<T>(
  requestFactory: () => Observable<T>,
  options?: Partial<WaitOptions<T>>
): Observable<boolean> {
  return waitForEntityChange(
    requestFactory,
    (item) => {
      return (item as any)?.deleted || (item as any).deletedAt;
    },
    {
      ...options,
      ignoreErrors(e: HttpError) {
        // Ignore the 500 error because of CQRS weirdness until we get a 404
        return (options?.ignoreErrors && options?.ignoreErrors(e)) || e.response?.status === 500;
      },
    }
  ).pipe(
    catchError((e) => {
      if ((e as HttpError).response?.status === 404) {
        return of(true);
      }
      throw e;
    }),
    map((i) => {
      if (typeof i !== 'boolean') {
        return false;
      }
      return i;
    })
  );
}
