<script setup lang="ts">
import { UploadedFileUpdate } from 'ah-requests';
import { computed, PropType } from 'vue';

/**
 * Emits:
 * - cancel (no payload): when user cancels on going file upload
 */

const props = defineProps({
  file: {
    type: File,
    required: true,
  },
  uploadStatus: {
    type: Object as PropType<UploadedFileUpdate<any> | null>,
    default: null,
  },
});

const completedBarWidth = computed(() => {
  return {
    width: `${uploadPercentage.value}%`,
  };
});

const startedUpload = computed(() => {
  return props.uploadStatus?.finished === false;
});

const finishedUpload = computed(() => {
  return props.uploadStatus?.finished;
});

const fileSize = computed(() => {
  if (props.uploadStatus?.bytesTotal) {
    if (props.uploadStatus.bytesTotal < 100000) {
      return (props.uploadStatus.bytesTotal / 1000).toFixed(2) + ' KB';
    }
    return (props.uploadStatus.bytesTotal / 1000000).toFixed(2) + ' MB';
  }
  return 'Unknown';
});

const uploadPercentage = computed(() => {
  if (props.uploadStatus?.bytesTotal) {
    if (props.uploadStatus.bytesLoaded === 0 || props.uploadStatus.bytesTotal === Infinity) {
      return 0;
    } else {
      return (props.uploadStatus.bytesLoaded / props.uploadStatus.bytesTotal) * 100;
    }
  }
  return 0;
});

const uploadPercentageText = computed(() => {
  return Math.floor(uploadPercentage.value).toString();
});
</script>

<template>
  <div class="file-upload-status">
    <div class="file-info">
      <div class="file-text-info">
        <div class="file-name">
          {{ file.name }}
          <span class="file-size" v-if="startedUpload"> {{ fileSize }} </span>
        </div>
        <div class="upload-status mr-2">
          <span @click="$emit('cancel')" class="close" v-if="!finishedUpload"> <CloseIcon /> </span>
          <span class="ready" v-else-if="finishedUpload"> <CheckIcon /> </span>
        </div>
      </div>

      <div class="load-bar">
        <div class="load-bar-completed" :style="completedBarWidth"></div>
      </div>

      <div class="file-load-percentage">
        <span v-if="startedUpload"> {{ uploadPercentageText }}% </span>
        <span v-else-if="finishedUpload"> 100% </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-upload-status {
  .file-info {
    .file-text-info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr min-content;

      .file-name {
        width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 1.4em;
        font-weight: $font-weight-semibold;
        color: $common-color-darkPurple;

        .file-size {
          font-size: 10px;
          font-weight: $font-weight-regular;
          color: $common-color-grey;
        }
      }

      .upload-status {
        width: 10px;
        height: 10px;
        ::v-deep {
          .close path {
            cursor: pointer;
            fill: $common-color-grey;
          }
          .ready path {
            fill: $common-color-green;
          }
        }
      }
    }

    .load-bar {
      display: block;
      width: 100%;
      height: 2px;
      margin-top: 6px;
      position: relative;

      .load-bar-completed {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        transition: width 0.2s;
        transition-timing-function: ease-in-out;
      }
    }

    .file-load-percentage {
      width: 20%;
      white-space: nowrap;
      margin-top: 6px;
      span {
        font-size: 14px;
        color: $common-color-grey;
      }
    }
  }
}
</style>
