import { MfaExpiryTime } from './expiry';

export enum MFAActionType {
  REGISTRATION = 'REGISTRATION',
  CLIENT_INVITE = 'CLIENT_INVITE',
}

export enum MFAType {
  SMS_OTP = 'SMS_OTP',
  EMAIL_OTP = 'EMAIL_OTP',
}

export enum MFAState {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  NOT_REQUESTED = 'NOT_REQUESTED',

  // Internal MFA states
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
}

export interface MFAResponse extends MfaExpiryTime {
  state: MFAState;
  mfaActionType: MFAActionType;
  mfaMethodType: MFAType;
}

export interface MFARequest {
  actionType: MFAActionType;
  type: MFAType;
}
