<script lang="ts" setup>
import { fillDates } from '../helpers/fillDates';
import { TradeCountReport } from 'ah-api-gateways';
import VChart from './VChart.vue';
import { makeBarChartOptions, makeBarChartDataset } from '../helpers/chartConfig';
import { differenceInMonths, differenceInDays, startOfDay, format } from 'date-fns';
import { linesColorPrimary, dataColorPrimary } from '../helpers/graphColors';
import { ScaleOptions, Point } from 'chart.js';
import { computed, reactive, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhReportsState } from '../..';
import { makeTimeLineChartDataset, makeTimeLineChartOptions } from '../helpers';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadData') {
    loadDates();
  }
}

const props = withDefaults(
  defineProps<{
    fromDate: Date;
    toDate: Date;
    partnerId?: string;
    isLineChart?: boolean | string;
  }>(),
  {
    isLineChart: false,
  }
);

const state = reactive({
  report: null as TradeCountReport | null,
  options:
    props.isLineChart !== false
      ? makeTimeLineChartOptions(
          { color: linesColorPrimary },
          {
            plugins: {
              tooltip: {
                callbacks: {
                  label(item) {
                    return `${item.parsed.y} Trades`;
                  },
                },
              },
            },
          }
        )
      : makeBarChartOptions(
          { horizontal: false, gridColor: linesColorPrimary },
          {
            plugins: {
              tooltip: {
                callbacks: {
                  label(item) {
                    return `${item.parsed.y} Trades`;
                  },
                  title(items) {
                    if (items.length > 0 && (items[0].raw as Point).x) {
                      return format(new Date((items[0].raw as Point).x), 'dd-MM-yyyy');
                    }
                    return '';
                  },
                },
              },
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'month',
                },
              },
            },
          }
        ),
  data:
    props.isLineChart !== false
      ? makeTimeLineChartDataset({ label: 'Commission', color: dataColorPrimary })
      : makeBarChartDataset('primary'),
});

const dates = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
}));

const reportsState = useAhReportsState();

function loadDates() {
  requestManager.manager
    .cancelAndNew(
      'loadData',
      reportsState.services.trade.getTradeCountReport(props.fromDate, props.toDate, props.partnerId)
    )
    .subscribe((response) => {
      state.data.datasets![0].data! = fillDates(
        response.dailyReports.map((i) => ({
          x: startOfDay(new Date(i.date)),
          y: i.count,
        })),
        startOfDay(props.fromDate),
        startOfDay(props.toDate)
      );
      const timeRange = differenceInDays(props.toDate, props.fromDate);
      let unit: 'day' | 'month' | 'week' = 'day';
      if (timeRange > 10) {
        unit = timeRange > 60 ? 'month' : 'week';
      }
      const xScale: ScaleOptions<'time'> = state.options.scales?.x as any;
      if (xScale && xScale.time) {
        xScale.time.unit = unit;
        if (xScale.ticks) {
          xScale.ticks.minRotation = differenceInMonths(props.fromDate, props.toDate) === -1 ? 13 : 0;
        }
      }

      // Force update by replacing prop reference entirely
      state.options = { ...state.options };
      state.data = { ...state.data };

      state.report = response;
    });
}

watch(dates, loadDates, { immediate: true });
</script>

<template>
  <div>
    <template v-if="state.report && !requestManager.manager.anyPending">
      <VChart v-if="isLineChart !== false" class="chart" type="line" :options="state.options" :data="state.data" />
      <VChart v-else class="chart" type="bar" :options="state.options" :data="state.data" />
      <div class="trade-count text-center">
        <h4 class="count">{{ state.report ? state.report.count : '...' }}</h4>
        <p class="count-label">Trades</p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  padding-bottom: 33%;
}

.trade-count {
  @include phablet {
    text-align: left !important;
    font-size: 0.5rem;
  }
  @include tablet {
    text-align: center !important;
    font-size: 0.5rem;
  }
  @include desktop {
    font-size: 0.9rem;
  }
  @include hd {
    font-size: 1rem;
  }
  .count {
    font-size: 4em;
    @include themedTextColor($color-primary);
  }

  .count-label {
    font-size: 1.5em;
  }
}

.buttons {
  @include phablet {
    margin-top: -3rem;
  }
  @include tablet {
    margin-top: -2rem;
  }
  @include desktop {
    margin-top: 0;
  }
}
</style>
