<script lang="ts">
import { DatePicker } from 'v-calendar/lib/v-calendar.umd';
import {
  addMonths,
  addWeeks,
  addYears,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isSameDay,
  format,
  isSameMonth,
  isSameYear,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  isValid,
} from 'date-fns';
import { isDescendant } from '../../helpers/dom';
import { uniqueId } from 'lodash';
import { PropType, watch, computed, ref, onBeforeMount, onBeforeUnmount } from 'vue';

export interface DateFilterActions extends DateRange {
  label: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export const defaultChoices = [
  { label: 'Today', start: startOfToday(), end: endOfToday() },
  { label: 'Tomorrow', start: startOfTomorrow(), end: endOfTomorrow() },
  { label: 'This week', start: startOfWeek(new Date()), end: endOfWeek(new Date()) },
  { label: 'Next week', start: startOfWeek(addWeeks(new Date(), 1)), end: endOfWeek(addWeeks(new Date(), 1)) },
  { label: 'This month', start: startOfMonth(new Date()), end: endOfMonth(new Date()) },
  { label: 'Next month', start: startOfMonth(addMonths(new Date(), 1)), end: endOfMonth(addMonths(new Date(), 1)) },
  { label: 'This year', start: startOfYear(new Date()), end: endOfYear(new Date()) },
  { label: 'Next year', start: startOfYear(addYears(new Date(), 1)), end: endOfYear(addYears(new Date(), 1)) },
];
</script>

<script setup lang="ts">
/**
 * Range date selector
 *
 * Emits:
 * - date (payload: DateRange): .sync'able date range
 * - submit (payload: Date | DateRange): date selected on submittion
 */

const emit = defineEmits({
  'update:date': (_date: DateRange | Date | null) => true,
  submit: (_date: DateRange | Date | null) => true,
});

const props = defineProps({
  /**
   * Title of the Date selector
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * Confirm button label
   */
  confirmButtonLabel: {
    type: String,
    default: 'Confirm',
  },
  /**
   * Prefix to show in date Selector button
   */
  popupButtonPrefix: {
    type: String,
    default: '',
  },
  /**
   * Whether date picker is a range of dates
   */
  isRanged: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Known date ranges that the user can select to auto fill the date picker
   *
   * if not ranged date this will be ignored
   */
  commonChoices: {
    type: Array as PropType<DateFilterActions[]>,
    default: () => defaultChoices,
  },
  /**
   * Date selected in the date picker
   */
  date: {
    type: [Object, Date] as PropType<DateRange | Date | null>,
    default: null,
  },

  /**
   * Dates not able to be selected
   */
  excludedDates: {
    type: Array as PropType<Date[] | null>,
    default: null,
  },
  /**
   * Whether clear option is available
   */
  clearable: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Clear value
   *
   * Will be ignored if prop `clearable` is set to false
   */
  clearValue: {
    type: [Object, Date] as PropType<DateRange | Date | null>,
    default: null,
  },
  /**
   * Additional changes to the calendar options
   * Same values for selected attribute and dragable attribute is being used
   */
  options: {
    type: Array as PropType<any[]>,
  },
  /**
   * Actions menu target key
   * Should be set whether not to have common choices list.
   */
  hideChoices: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Placeholder value
   * If a date is not selected shows this text
   */
  placeholder: {
    type: String,
    default: 'All dates',
  },
  /**
   * Format value
   * Shows the data selected with this format
   */
  dateFormat: {
    type: String,
    default: 'dd MMMM',
  },
  /**
   * Whether to autosubmit, i.e. change date values as soon as they are selected on the UI (removing confirm/cancel buttons)
   */
  autoSubmit: {
    type: [String, Boolean],
    default: false,
  },
  /**
   * Class to set on the trigger button
   */
  btnClass: {
    type: String,
    default: 'btn-primary',
  },
});

const popover = ref<InstanceType<typeof HTMLDivElement>>();

const datePicker = ref<InstanceType<typeof DatePicker>>();

const show = ref(false);

const fromPage = ref<{ month: number; year: number }>({ month: 0, year: 0 });

const toPage = ref<{ month: number; year: number }>({ month: 0, year: 0 });

const popOverTarget = ref(`date-menu-toggle-${uniqueId()}`);

const selectedDate = ref<DateRange | Date | null>(null);

const dateToSelect = ref<DateRange | Date | null>(null);

const windowClickListener = ref<(event: MouseEvent) => void>();

const attributes = computed(() => ({
  highlight: {
    class: 'date-highlight',
    contentClass: 'date-highlight-content',
  },
  ...props.options,
}));

const isDateSet = computed(() => {
  if (!selectedDate.value) {
    return false;
  }
  if (!isRangedDatePicker.value) {
    return selectedDate.value instanceof Date && isValid(selectedDate.value);
  }
  return isValid((selectedDate.value as DateRange).start) && isValid((selectedDate.value as DateRange).end);
});

const selectedDateDescription = computed(() => {
  try {
    if (start.value && end.value && isRangedDatePicker.value) {
      // if is a known date range, display the known label
      const knownDate = props.commonChoices.find((choice) => {
        return isSameDay(start.value!, choice.start) && isSameDay(end.value!, choice.end);
      });
      if (knownDate) return knownDate.label;

      // else try to figure out what's the best label to display
      if (isSameDay(start.value, end.value!)) {
        return format(start.value, 'dd MMMM');
      }
      if (isSameMonth(start.value, end.value)) {
        return `${format(start.value!, 'dd')}-${format(end.value, 'dd')} ${format(start.value, 'MMM')}`;
      }
      if (isSameYear(start.value, end.value)) {
        return `${format(start.value, 'dd MMM')}-${format(end.value, 'dd MMM')}`;
      }
      return `${format(start.value, 'dd MMM yy')}-${format(end.value, 'dd MMM yy')}`;
    } else if (start.value) {
      return format(start.value, props.dateFormat!);
    } else {
      return props.placeholder;
    }
  } catch (e) {
    return props.placeholder;
  }
});

const start = computed(() => {
  if (!selectedDate.value) {
    return null;
  }

  if (selectedDate.value instanceof Date) {
    return selectedDate.value;
  } else {
    return (selectedDate.value as DateRange).start;
  }
});

const end = computed(() => {
  if (!selectedDate.value) {
    return null;
  }

  if (selectedDate.value instanceof Date) {
    return selectedDate.value;
  } else {
    return (selectedDate.value as DateRange).end;
  }
});

const shouldHideChoices = computed(() => props.hideChoices !== false);

const isRangedDatePicker = computed(() => props.isRanged !== false);

const useAutoSubmit = computed(() => props.autoSubmit !== false);

const isDirty = computed(() => {
  if (!selectedDate.value) return false;
  if (!isNaN(new Date(selectedDate.value as Date).getDate())) {
    return !isSameDay(selectedDate.value as Date, props.clearValue as Date);
  } else if (isRangedDatePicker.value) {
    const range = selectedDate.value as DateRange;
    const defaultRange = props.clearValue as DateRange;
    return (
      (!defaultRange && !!selectedDate.value) ||
      !(isSameDay(defaultRange.start, range.start) && isSameDay(defaultRange.end, range.end))
    );
  }

  return selectedDate.value !== props.clearValue;
});

function onDateSelected(value: DateRange | Date | null) {
  dateToSelect.value = value;
  if (useAutoSubmit.value) {
    submit();
  }
}

onBeforeMount(() => {
  windowClickListener.value = (event: any) => {
    if (show.value && popover.value && !isDescendant(popover.value, event.target)) {
      show.value = false;
    }
  };
  window.addEventListener('click', windowClickListener.value);
});

onBeforeUnmount(() => {
  if (windowClickListener.value) {
    window.removeEventListener('click', windowClickListener.value);
  }
});

function showModal() {
  if (show.value === false) {
    setTimeout(() => {
      show.value = true;
    });
  }
}

function isActive(choice: DateFilterActions) {
  if (isRangedDatePicker.value && dateToSelect.value) {
    const range = dateToSelect.value as DateRange;
    return isSameDay(choice.start, range.start) && isSameDay(choice.end, range.end);
  }
  return false;
}

function clear() {
  dateToSelect.value = props.clearValue;
  selectedDate.value = props.clearValue;
  emit('update:date', selectedDate.value);
}

function cancel() {
  show.value = false;
}

/**
 * Select date/date range from one of the choices provided.
 *
 * If current view does not include any of the dates being selected, it will auto scroll to the beginning of the range
 */
function selectFromChoice(date: DateRange | Date) {
  dateToSelect.value = date;
  const minPageDate = new Date(fromPage.value.year, fromPage.value.month - 1, 1);
  const maxPageDate = endOfMonth(new Date(toPage.value.year, toPage.value.month - 1, 1));
  const minDate = !(date instanceof Date) ? (date as DateRange).start : (date as Date);
  const maxDate = !(date instanceof Date) ? (date as DateRange).end : (date as Date);
  if (!maxDate || !minDate || minPageDate > maxDate || maxPageDate < minDate) {
    datePicker.value.move({ month: minDate!.getMonth() + 1, year: minDate!.getFullYear() }, { position: 1 });
  }
  if (props.autoSubmit) {
    submit();
  }
}

function submit() {
  show.value = false;
  selectedDate.value = dateToSelect.value;
  emit('submit', selectedDate.value);
  emit('update:date', selectedDate.value);
}

function onDateChange() {
  selectedDate.value = props.date;
}

watch(() => props.date, onDateChange, { immediate: true });

function onSelectedDateChange() {
  dateToSelect.value = selectedDate.value;
}

watch(selectedDate, onSelectedDateChange, { immediate: true });
</script>

<template>
  <div class="date-selector">
    <div class="title-wrapper clearfix" v-if="clearable !== false || title">
      <label class="title" v-if="title">{{ title }}</label>
    </div>

    <div class="position-relative">
      <div class="date-clear-item" v-show="isDirty && clearable !== false">
        <slot name="clear-label">
          <a class="field-group-clear-link" @click="clear"> clear </a>
        </slot>
      </div>
      <div class="date-selector-popover-target" :id="popOverTarget">
        <slot name="button" v-bind="{ showModal, selectedDateDescription, selectedDate, isDateSet, isOpen: show }">
          <VButton :class="[btnClass, 'btn-date-picker', { 'unset-value': !selectedDate }]" @click="showModal">
            <span class="btn-date-picker-text" v-if="popupButtonPrefix">{{ popupButtonPrefix }} - </span>
            <span class="btn-date-picker-text">{{ selectedDateDescription }}</span>
            <slot name="icon" />
          </VButton>
        </slot>

        <BPopover
          :placement="$attrs.placement"
          :custom-class="`date-selector-popover arrowless mb-0 ${isRangedDatePicker ? 'ranged' : ''}`"
          :target="popOverTarget"
          triggers="manual"
          :show="show"
        >
          <div class="dropdown-menu-wrapper" ref="popover">
            <template v-if="isRangedDatePicker">
              <div v-if="!shouldHideChoices">
                <BDropdownItem
                  class="choice-button btn-stroke text-left"
                  :active="isActive(choice)"
                  v-for="choice in commonChoices"
                  :key="choice.label"
                  @click="selectFromChoice(choice)"
                >
                  {{ choice.label }}
                </BDropdownItem>
              </div>
              <div>
                <DatePicker
                  :value="dateToSelect"
                  @input="onDateSelected"
                  ref="datePicker"
                  @update:to-page="toPage = $event"
                  @update:from-page="fromPage = $event"
                  :select-attribute="attributes"
                  :drag-attribute="attributes"
                  :columns="2"
                  :disabled-dates="excludedDates"
                  locale="eng"
                  is-range
                  trim-weeks
                  is-expanded
                />
                <VRow class="mx-0 mb-3 mt-4" align-h="end" align-v="end" v-if="!useAutoSubmit">
                  <VCol cols="3">
                    <VButton blurOnClick class="w-100 btn-secondary" @click="cancel"> Cancel </VButton>
                  </VCol>
                  <VCol cols="3">
                    <VButton class="w-100 btn-primary" @click="submit"> {{ confirmButtonLabel }} </VButton>
                  </VCol>
                </VRow>
              </div>
            </template>
            <template v-else>
              <div>
                <DatePicker
                  :value="dateToSelect"
                  @input="onDateSelected"
                  :select-attribute="attributes"
                  :drag-attribute="attributes"
                  :disabled-dates="excludedDates"
                  locale="eng"
                  trim-weeks
                  is-expanded
                />
                <VRow class="wrapper-btn mx-0 mb-3 mt-4" align-h="end" v-if="!useAutoSubmit">
                  <VCol cols="6">
                    <VButton blurOnClick class="btn-secondary" @click="cancel"> Cancel </VButton>
                  </VCol>
                  <VCol cols="6">
                    <VButton class="btn-primary" @click="submit"> {{ confirmButtonLabel }} </VButton>
                  </VCol>
                </VRow>
              </div>
            </template>
          </div>
        </BPopover>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.date-selector {
  .title-wrapper {
    position: relative;

    .title {
      float: left;
      margin-bottom: 0.375rem;
    }
  }

  .btn {
    width: 100%;
  }
  .date-clear-item {
    position: absolute;
    top: -1.8rem;
    right: 0;
    a {
      @include themedTextColor($color-primary, $color-primary);
    }
  }

  .date-selector-popover-target {
    width: 100%;
    .btn-date-picker {
      border-radius: 4px;
      top: 1px;
    }
  }
}

.b-popover.date-selector-popover {
  max-width: none;
  z-index: 100;
  min-width: 20rem;
  .dropdown-menu-wrapper {
    display: flex;
    flex-wrap: nowrap;
  }
  .arrow {
    opacity: 0 !important;
  }
  .popover-body {
    padding: 0.5em;
  }
  .vc-container {
    border: none;
  }

  .date-highlight {
    @include themedBackgroundColor($color-sidebar-border);
  }

  .date-highlight-content {
    @include themedTextColor($color-text);
  }

  .vc-popover-content {
    border: none;
    @include themedBackgroundColor($color-sidebar-border);
    * {
      @include themedTextColor($color-text);
      &.vc-nav-item {
        &:hover {
          @include themedBackgroundColor($color-widgets-grey, $color-primary);
        }
        &.is-active {
          @include themedBackgroundColor($color-widgets-grey, $color-primary);
        }
        &.is-current,
        &:focus,
        &:active {
          @include themedBorderColor($color-primary, $color-dark-primary);
        }
      }
    }
  }
  .choice-button {
    width: 100%;
    list-style-type: none;
    ::v-deep .dropdown-item {
      padding: 0.5em 1em;
      font-weight: $font-weight-bold;
      @include themedTextColor($color-text, $color-dark-text);
      &:focus,
      &.active {
        @include themedBackgroundColor($color-text-secondary, $color-primary);
        @include themedTextColor($color-dark-text, $color-dark-text);
      }
      &:hover {
        @include themedBackgroundColor($color-text-secondary, $color-primary);
        @include themedTextColor($color-dark-text, $color-dark-text);
      }
    }
  }
}
</style>
