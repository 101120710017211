<script setup lang="ts">
import BoxGridBlock from '../common/boxGrid/BoxGridBlock.vue';
import ConfirmModal from 'ah-common-lib/src/common/components/ConfirmModal.vue';
import GroupEditModal from '@/app/components/groups/GroupEditModal.vue';
import PermissionsEditor from '../settings/PermissionsEditor.vue';
import VButton from 'ah-common-lib/src/common/components/VButton.vue';
import { IndividualGroup, UserRole } from 'ah-api-gateways';
import { computed, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits({
  'group-deleted': (_groupId: string) => true,
  'update:group': (_group: IndividualGroup) => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadGroup') {
      onGroupChange();
    }
    if (k === `deleteGroup-${props.groupId}`) {
      deleteGroup();
    }
  },
}).manager;

const authStore = useAuthStore();

const services = getServices();

const group = ref<IndividualGroup | null>(null);

const groupService = computed(() => (authStore.isClientUser ? services.clientGroup : services.partnerGroup));

const groupParentId = computed(() =>
  // Partner group endpoints do not use id in path, when called from a partner user
  authStore.isClientUser ? authStore.loggedInIdentity!.client!.id : undefined
);

const groupRole = computed(() => (authStore.isClientUser ? UserRole.CLIENT_INDIVIDUAL : UserRole.PARTNER_AGENT));

function onGroupChange() {
  requestManager
    .sameOrCancelAndNew('loadGroup', groupService.value.getGroup(props.groupId, groupParentId.value), props.groupId)
    .subscribe((groupResponse) => {
      group.value = groupResponse;
    });
}

function deleteGroup() {
  requestManager
    .currentOrNew(`deleteGroup-${props.groupId}`, groupService.value.deleteGroup(props.groupId, groupParentId.value))
    .subscribe(() => {
      emit('group-deleted', props.groupId);
    });
}

watch(() => props.groupId, onGroupChange, { immediate: true });
</script>

<template>
  <BoxGridBlock :loadingOverlayProps="{ state: requestManager.currentStates }" useLoadingOverlay overlayType="simple">
    <template v-if="group">
      <div class="corner-buttons">
        <GroupEditModal
          class="mr-2"
          :group.sync="group"
          v-slot="{ show }"
          @update:group="$emit('update:group', $event)"
        >
          <VButton class="manage-group-button" blurOnClick @click="show">Manage Group</VButton>
        </GroupEditModal>
        <ConfirmModal modalText="Permanently delete this group?" @ok="deleteGroup" v-slot="{ showModal }">
          <VButton blurOnClick class="delete-button btn-danger-secondary" @click="showModal">Delete</VButton>
        </ConfirmModal>
      </div>
      <div class="d-flex justify-content-between">
        <h3>{{ group.name }}</h3>
      </div>
      <PermissionsEditor targetType="group" :targetId="groupId" :role="groupRole" />
    </template>
  </BoxGridBlock>
</template>

<style lang="scss" scoped>
.corner-buttons {
  position: absolute;
  right: math.div($padded-space, 2);
  top: math.div($padded-space, 2);
}
</style>
