<script lang="ts" setup>
import { Trade, getCurrencyPair, getPrimaryCcy } from 'ah-api-gateways';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    item: Trade;
    showCurrencyPair?: boolean | string;
  }>(),
  {
    showCurrencyPair: false,
  }
);

const tradeCurrencyPair = computed(() => getCurrencyPair(props.item));

const getProductRate = computed(() => getPrimaryCcy(props.item).clientRate);
</script>

<template>
  <div>
    <span>{{ getProductRate.toFixed($ahTradesState.store.useSettingsStore().ratePrecision) }}</span>
    <span v-if="showCurrencyPair !== false"> {{ tradeCurrencyPair }} </span>
  </div>
</template>
