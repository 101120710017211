<script setup lang="ts">
import { minDate, maxDate, date, ifTest, requiredIfWithContext } from 'ah-common-lib/src/form/validators';
import { Validators } from 'ah-common-lib/src/form';
import { oneYearFromNow } from 'ah-common-lib/src/helpers/time';
import { timeFramesTranslations, TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { makeFormModel, setState, updateModel } from 'ah-common-lib/src/form/helpers';
import { selectField, dateField } from 'ah-common-lib/src/form/models';
import { HedgingInstruments, NonTradeableDays } from 'ah-api-gateways';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { TimeFrameDate } from 'ah-trades/src/models/date';
import { PropType, reactive, watch } from 'vue';
import { getServices } from '@/app/services';
import { ValidatedForm } from 'ah-common-lib/src/form/components';

const props = defineProps({
  hedgingInstrument: {
    type: String as PropType<HedgingInstruments>,
    default: HedgingInstruments.FX_FORWARD,
  },
  tradeDirection: {
    type: String as PropType<string | null>,
    default: null,
  },
  timeFrame: {
    type: Object as PropType<TimeFrameDate | null>,
    default: null,
  },
});

const emit = defineEmits({
  'update:timeFrame': (_model: { targetDate: string; targetTimeFrame: TimeFrames }) => true,
});

const services = getServices();

const timeFrameFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'timeFrameForm',
    title: '',
    fieldType: 'form',
    fields: [
      selectField('targetTimeFrame', '', timeFramesTranslations, {
        placeholder: 'Execute immediately',
        clearable: true,
        required: false,
      }),
      dateField(
        'targetDate',
        '',
        {
          fieldType: 'date',
          hidden: (model: any) => model.$parent().targetTimeFrame !== TimeFrames.OTHER,
          defaultValue: null,
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(
            requiredIfWithContext((form) => form.targetTimeFrame === TimeFrames.OTHER),
            (a) => !(a instanceof Date)
          ),
          minDate: minDate(),
          maxDate: maxDate(oneYearFromNow()),
          disallowedDate: Validators.disallowStateDates('targetDate'),
        }
      ),
    ],
  }),
  validation: null,
});

function onTimeFrameChange() {
  if (props.timeFrame) {
    updateModel(timeFrameFormDef.form, props.timeFrame);
  }
}

watch(() => props.timeFrame, onTimeFrameChange, { immediate: true });

function getNonTradeableDays() {
  if (props.tradeDirection) {
    services.pricingEngine
      .getNonTradeableDays(props.hedgingInstrument, props.tradeDirection)
      .subscribe((result: NonTradeableDays) => {
        const targetDate = timeFrameFormDef.form.$fields.find((f) => f.$name === 'targetDate');
        setState(targetDate!, 'disallowedDates', [...result.holidays, ...result.weekend]);
      });
  }
}

watch(() => props.tradeDirection, getNonTradeableDays);

function onDateChange(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    emit('update:timeFrame', {
      targetDate: timeFrameFormDef.form.targetDate,
      targetTimeFrame: timeFrameFormDef.form.targetTimeFrame,
    });
  }
}
</script>

<template>
  <ValidatedForm
    :fm="timeFrameFormDef.form"
    :validation.sync="timeFrameFormDef.validation"
    @form-event="onDateChange"
  />
</template>

<style lang="scss" scoped></style>
