<script setup lang="ts">
import TradeCountReportDashboardWidget from '@/app/components/trades/widgets/TradeCountReportDashboardWidget.vue';
import CommissionCountReportDashboardWidget from '@/app/components/trades/widgets/CommissionCountReportDashboardWidget.vue';
import TransactionCountReportDashboardWidget from '@/app/components/trades/widgets/TransactionCountReportDashboardWidget.vue';
import MyAccountsDashboardWidget from '@/app/components/trades/widgets/MyAccountsDashboardWidget.vue';
import TopAccountsDashboardWidget from '@/app/components/trades/widgets/TopAccountsDashboardWidget.vue';
import { add } from 'date-fns';
import LastLoginInfo from '@/app/components/session/LastLoginInfo.vue';
import { ref } from 'vue';

interface DateObj {
  date: Date;
  title: string;
}

const dates: DateObj[] = [
  { date: add(new Date(), { weeks: -1 }), title: 'Past week' },
  { date: add(new Date(), { months: -1 }), title: 'Past month' },
  { date: add(new Date(), { months: -3 }), title: 'Past 3 months' },
  { date: add(new Date(), { months: -6 }), title: 'Past 6 months' },
  { date: add(new Date(), { years: -1 }), title: 'Past year' },
];

const showDatesMenu = ref<boolean>(false);
const fromDateObj = ref<DateObj>(dates[0]);
const toDate = new Date();

function setDate(dateObj: DateObj) {
  fromDateObj.value = dateObj;
  showDatesMenu.value = false;
}
</script>

<template>
  <div class="padded-space">
    <div class="title-bar d-md-flex d-sm-block">
      <h2>Partner Dashboard</h2>
      <div class="head-info">
        <LastLoginInfo />
        <div class="date-content">
          <span class="date-picker-content" id="date-chooser">
            <span class="text-secondary">Date range: </span>
            <span class="date-range">{{ fromDateObj.title }}</span>
            <IconCalendar class="calendar-icon ml-2" />
            <IconChevronDown :class="['menu-toggle ml-2', { open: showDatesMenu }]" />
          </span>
        </div>
        <BPopover
          placement="bottomright"
          custom-class="arrowless"
          target="date-chooser"
          triggers="click blur"
          :show.sync="showDatesMenu"
        >
          <div class="popover-container dropdown-menu-block">
            <div class="popover-section">
              <ul>
                <li v-for="(dateObj, index) in dates" :key="index" @click="setDate(dateObj)">
                  {{ dateObj.title }}
                </li>
              </ul>
            </div>
          </div>
        </BPopover>
      </div>
    </div>
    <BoxGrid alignV="start" class="mr-md-2">
      <BoxGridBlock
        align-self="stretch"
        cols="12"
        lg="4"
        sm="12"
        useLoadingOverlay
        :loadingOverlayProps="{ showRetry: true }"
      >
        <TradeCountReportDashboardWidget :fromDate="fromDateObj.date" :toDate="toDate" />
      </BoxGridBlock>
      <BoxGridBlock
        align-self="stretch"
        cols="12"
        lg="4"
        sm="12"
        useLoadingOverlay
        :loadingOverlayProps="{ showRetry: true }"
      >
        <CommissionCountReportDashboardWidget :fromDate="fromDateObj.date" :toDate="toDate" />
      </BoxGridBlock>
      <BoxGridBlock
        align-self="stretch"
        cols="12"
        lg="4"
        sm="12"
        useLoadingOverlay
        :loadingOverlayProps="{ showRetry: true }"
      >
        <TransactionCountReportDashboardWidget :fromDate="fromDateObj.date" :toDate="toDate" />
      </BoxGridBlock>
    </BoxGrid>
    <BoxGrid alignV="start">
      <BoxGridBlock
        align-self="stretch"
        cols="12"
        lg="6"
        sm="12"
        useLoadingOverlay
        :loadingOverlayProps="{ showRetry: true }"
      >
        <MyAccountsDashboardWidget :fromDate="fromDateObj.date" :toDate="toDate" />
      </BoxGridBlock>
      <BoxGridBlock
        align-self="stretch"
        cols="12"
        lg="6"
        sm="12"
        useLoadingOverlay
        :loadingOverlayProps="{ showRetry: true }"
      >
        <TopAccountsDashboardWidget :fromDate="fromDateObj.date" :toDate="toDate" />
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .box-grid {
  @include tablet {
    display: inline-block !important;
    width: 48%;
  }
  @include desktop {
    display: flex;
    width: 100%;
  }
}

.head-info {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

@include upToResolution($tabletResolution) {
  h2 {
    margin-bottom: 0;
  }

  .date-content > *,
  span {
    font-size: $font-size-xs !important;
  }
}

.title-bar {
  justify-content: space-between;
  @include upToResolution($tabletResolution) {
    margin-bottom: 8px;
  }

  .date-picker-content {
    cursor: pointer;

    .date-range {
      font-weight: 600;
      @include themedTextColor($color-primary-light, $color-dark-primary);
    }

    .calendar-icon {
      font-size: 1.4em;
      position: relative;
      top: -0.1em;
      @include themedTextColor($color-text-secondary);
    }
  }
}
</style>
