import { render, staticRenderFns } from "./InfoBlock.vue?vue&type=template&id=6158bf62&scoped=true"
import script from "./InfoBlock.vue?vue&type=script&setup=true&lang=ts"
export * from "./InfoBlock.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./InfoBlock.vue?vue&type=style&index=0&id=6158bf62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6158bf62",
  null
  
)

export default component.exports