<script setup lang="ts">
import { AssigneeReference, AuthorityType, Client, Permission } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { computed, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useRoute, useRouter } from 'vue-router/composables';

const props = defineProps({ clientId: { type: String, required: true } });

const client = ref<Client>();

const clientPermissions = ref<Permission[]>([]);

const clientAssignees = ref<AssigneeReference[]>([]);

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const route = useRoute();

const router = useRouter();

function onClientIdChange() {
  requestManager
    .sameOrCancelAndNew('getClientPermissions', services.authz.getClientsPermissions(props.clientId))
    .subscribe((response) => (clientPermissions.value = response));

  requestManager
    .sameOrCancelAndNew('getClient', services.client.getClient(props.clientId), props.clientId)
    .subscribe((response) => (client.value = response));

  requestManager
    .sameOrCancelAndNew('getAssignees', services.client.getClientAssignees(props.clientId), props.clientId)
    .subscribe((response) => (clientAssignees.value = response));
}

const requestStates = computed(() => Object.values(requestManager.requestStates));

const OBOallowed = computed(() => {
  if (client.value) {
    if (!authStore.authorities.includes(AuthorityType.ACT_ON_BEHALF_OF)) {
      return false;
    } else if (!authStore.isAdminUser) {
      const assignee = clientAssignees.value.find(
        (assignee) => assignee.individual.id === authStore.loggedInUser!.individual!.id
      );
      return !!assignee;
    }
    return true;
  }
  return false;
});

/**
 * Whether we are on a child view, and should return to the root of the Activity Single Client view,
 * rather than back to the list of Clients
 *
 * This expects the first child route (and ONLY the first child route) to have the `rootChild` meta value set to true
 */
const onChildView = computed(() => {
  return !route.matched[route.matched.length - 1].meta.rootChild;
});

function go(back: boolean) {
  if (back) {
    const append = onChildView.value ? '/' + props.clientId : '';
    router.push({ path: `/admin/activity/clients${append}` });
  } else {
    router.push({ path: `/admin/activity/clients/obo/${props.clientId}` });
  }
}

watch(() => props.clientId, onClientIdChange, { immediate: true });
</script>

<template>
  <LoadingOverlay :state="requestStates" show-retry @retry="onClientIdChange">
    <div v-if="client" id="activity-single-client-view">
      <VRow class="client-header padded-space pb-0 mb-n3 mr-2" align-h="between" align-v="end">
        <VCol>
          <h2 class="mb-1">{{ client.name }}</h2>
          <BackButton class="p-0" @click="go(true)" :label="`GO BACK ${!onChildView ? 'TO CLIENTS' : ''}`" />
        </VCol>
        <VCol class="text-right">
          <VButton v-if="OBOallowed" @click="go(false)">Act On Behalf of Client</VButton>
        </VCol>
      </VRow>
      <RouterView />
    </div>
  </LoadingOverlay>
</template>

<style lang="scss" scoped>
::v-deep .back-button {
  margin: 0;
  span {
    font-size: $font-size-sm;
  }
}
</style>
