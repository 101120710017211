import { BankingScheme, PaymentRail } from '..';

export enum AcoTier {
  ACO_TIER_1 = 'ACO_TIER_1',
  ACO_TIER_2 = 'ACO_TIER_2',
  ACO_TIER_3 = 'ACO_TIER_3',
  ACO_TIER_4 = 'ACO_TIER_4',
}

export enum FeePaymentType {
  PAYMENT = 'PAYMENT',
  RECEIPT = 'RECEIPT',
  FUNDING = 'FUNDING',
}

export enum FeeChargeType {
  ACO = 'ACO',
  SHA = 'SHA',
}

export interface FundingCharge {
  id: string;
  bankingScheme: BankingScheme;
  paymentCurrency: string | null;
  paymentDate: string;
  tier: AcoTier | null;
  type: FeePaymentType;
  chargeType: FeeChargeType;
  paymentRail: PaymentRail;
  countryCode: string;
  allCurrencies: boolean;
  clientId: string;
  fee: number;
  feeCurrency: string;
}

export interface Funding {
  currency: string;
  amount: number;
  clientId: string;
  partnerId?: string;
}
