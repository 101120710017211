<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7 2.16797H4.90556C3.63889 2.17352 2.61111 3.2013 2.61111 4.47352V4.5013H2.3C1.03333 4.5013 0.00555556 5.52352 0 6.79019V19.5291C0.00555556 20.7957 1.02778 21.8291 2.3 21.8346H19.0944C20.3611 21.8291 21.3889 20.8013 21.3889 19.5291V19.5013H21.7C22.9667 19.5013 23.9944 18.4791 24 17.2124V4.47352C23.9944 3.2013 22.9722 2.17352 21.7 2.16797ZM19.0944 20.7235H2.3C1.64444 20.718 1.11667 20.1846 1.11111 19.5291V6.79019C1.11667 6.14019 1.64444 5.61241 2.3 5.61241H19.0944C19.7444 5.61241 20.2778 6.13463 20.2778 6.79019V9.96797C20.1778 9.99019 20.0778 10.0013 19.9833 10.0013H16.7778C15.0333 10.0013 13.6167 11.418 13.6167 13.1624C13.6167 14.9069 15.0333 16.3235 16.7778 16.3291H19.9778C20.0778 16.3235 20.1778 16.3124 20.2722 16.3013L20.2778 19.5291C20.2778 20.1846 19.75 20.718 19.0944 20.7235ZM22.8889 17.2124C22.8833 17.8624 22.3556 18.3902 21.7 18.3902H21.3889V15.9013C21.6278 15.7402 21.8444 15.5402 22.0278 15.318L22.8889 14.2069V17.2124ZM21.15 14.6457C20.8722 15.0069 20.4389 15.218 19.9833 15.2235H16.7778C15.6444 15.218 14.7278 14.3013 14.7278 13.168C14.7278 12.0346 15.6444 11.118 16.7778 11.1124H19.9778C20.3611 11.1124 20.7389 11.0291 21.0833 10.8624C21.45 10.6902 21.7722 10.4291 22.0222 10.1069L22.8833 8.99575L22.8889 12.3902L21.15 14.6457ZM22.8889 7.17908L21.3889 9.11241V6.79019C21.3889 5.52352 20.3611 4.5013 19.0944 4.5013H3.72222V4.47352C3.72222 3.81797 4.25 3.28464 4.90556 3.27908H21.7C22.3556 3.28464 22.8833 3.81797 22.8889 4.47352V7.17908Z"
      fill="currentColor"
    />
    <path
      d="M18.0217 12.6133H16.6884C16.3828 12.6133 16.1328 12.8633 16.1328 13.1688C16.1328 13.4744 16.3828 13.7244 16.6884 13.7244H18.0217C18.3273 13.7244 18.5773 13.4744 18.5773 13.1688C18.5773 12.8633 18.3273 12.6133 18.0217 12.6133Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
