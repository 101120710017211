import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const agentTableFieldsFull: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
  {
    header: 'Email',
    key: 'email',
  },
];

export const agentTableFieldsMinimal: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
];
