import _Vue, { ObjectDirective } from 'vue';

function getTransitionEndEventName() {
  const transitions: any = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };
  const bodyStyle = document.body.style;
  for (const transition in transitions) {
    if (bodyStyle[transition as any] !== undefined) {
      return transitions[transition];
    }
  }
}

function getTransitionStartEventName() {
  const transitions: any = {
    transition: 'transitionrun',
    OTransition: 'oTransitionRun',
    MozTransition: 'transitionrun',
    WebkitTransition: 'webkitTransitionRun',
  };
  const bodyStyle = document.body.style;
  for (const transition in transitions) {
    if (bodyStyle[transition as any] !== undefined) {
      return transitions[transition];
    }
  }
}

const endAnimation = (el: HTMLElement) => {
  el.classList.remove(el.dataset?.transitionClassName as string);
  el.dispatchEvent(new Event('transition-end'));
  el.dataset.transitionState = 'end';
};

const removeAnimateListener = (event: TransitionEvent) => {
  const target = event.target as HTMLElement;
  if (!target) {
    return;
  }
  if (target.dataset.transitionState === 'transitioning') {
    endAnimation(target);
  }
};

const checkAnimateListener = (event: TransitionEvent) => {
  const target = event.target as HTMLElement;
  if (!target) {
    return;
  }
  if (target.dataset.transitionState === 'start') {
    target.dataset.transitionState = 'transitioning';
  }
};

export default {
  install: function install(Vue: typeof _Vue) {
    Vue.directive('transition-class', {
      bind(el: HTMLElement, binding, vNode) {
        el.addEventListener(getTransitionEndEventName(), removeAnimateListener);
        el.addEventListener(getTransitionStartEventName(), checkAnimateListener);
        el.dataset.transitionTargetClasses = (vNode.elm as HTMLElement)?.classList?.value || '';
      },
      update(el: HTMLElement, binding, vNode) {
        const classes = (vNode.elm as HTMLElement)?.classList?.value || '';
        if (binding.value && el.dataset.transitionTargetClasses !== classes) {
          el.dataset.transitionTargetClasses = classes;
          el.dataset.transitionState = 'start';
          el.dataset.transitionClassName = binding.value;
          el.classList.add(el.dataset.transitionClassName as string);
          el.dispatchEvent(new Event('transition-start'));
          setTimeout(() => {
            if (el.dataset.transitionState === 'start') {
              endAnimation(el);
            }
          }, 200);
        }
      },
      unbind(el) {
        el.removeEventListener(getTransitionEndEventName(), removeAnimateListener);
        el.removeEventListener(getTransitionStartEventName(), checkAnimateListener);
      },
    } as ObjectDirective);
  },
};
