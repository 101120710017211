<script setup lang="ts">
import { PaginatedQuery, ClientIntegration, integrationStateLabels } from 'ah-api-gateways';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../../';
import { VButton } from 'ah-common-lib/src/common/components';
import { useToast } from 'ah-common-lib/src/toast';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({
      tableFields: [
        {
          header: 'Type',
          key: 'integration',
        },
        {
          header: 'State',
          key: 'state',
        },
        {
          header: 'Key',
          key: 'resourceKey',
        },
        {
          header: '',
          key: 'actions',
        },
      ],
    }),
  },
  ...defineUseManagedListingProps<ClientIntegration>(),
});

const tradeState = useAhTradesState();

interface OnboardedClientsEmit extends UseManagedListingEmits<ClientIntegration> {}

const emit = defineEmits<OnboardedClientsEmit>();

const toast = useToast();

const reqManager = useRequestManager().manager;

const managedListing = useManagedListing<ClientIntegration>({
  loadDataRequest(query: PaginatedQuery) {
    return tradeState.services.integrations.listIntegrations({ ...query, pageSize: 5 });
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager,
});

function deleteIntegration(integration: ClientIntegration) {
  reqManager
    .currentOrNew(`delete-${integration.id}`, tradeState.services.integrations.deletePlaidPublicToken(integration.id))
    .subscribe(() => {
      toast.success('Deleted integration');
      managedListing.loadData();
    });
}

defineExpose({ loadData: managedListing.loadData });
</script>

<template>
  <PaginatedTable
    with-simple-scroll
    class="integrations-table"
    hover
    items-label="integrations"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    v-on="{ ...managedListing.listeners, ...$listeners }"
  >
    <template #cell(state)="{ item }">
      {{ integrationStateLabels[item.state] }}
    </template>
    <template #cell(actions)="{ item }">
      <VButton
        class="btn-danger delete-button"
        @click="deleteIntegration(item)"
        :loading="reqManager.requestStates[`delete-${item.id}`] === 'pending'"
        >Delete</VButton
      >
    </template>
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.delete-button {
  float: right;
  padding: 0.2em 0.6em;
}
</style>
