<script setup lang="ts">
import { AuthorityType, ClientRiskCollateralProfile, ClientRiskLimitsProfile } from 'ah-api-gateways';
import { computed, PropType, ref, onMounted, nextTick, onBeforeUnmount } from 'vue';
import { getChartColorsForProfileUsageLevel } from '../../helpers/chartConfig';
import SimpleDoughnutChart from 'ah-reports/src/components/SimpleDoughnutChart.vue';
import FormattedNumber from 'ah-common-lib/src/number/FormattedNumber.vue';
import CreateCreditFacilityRequestModal from '../modals/createCreditFacilityRequestModal/CreateCreditFacilityRequestModal.vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps({
  clientId: {
    type: String,
  },
  collateralProfileData: {
    type: Object as PropType<ClientRiskCollateralProfile>,
    required: true,
  },
  limitsProfileData: {
    type: Object as PropType<ClientRiskLimitsProfile>,
  },
});

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const creditInfo = computed(() => [
  { label: 'Variation Margin Limit', value: props.collateralProfileData.variationMarginAmount },
  { label: 'Your Mark-to-Market Position', value: props.collateralProfileData.totalMarkToMarket },
  { label: 'Posted Collateral', value: props.collateralProfileData.totalPostedCollateral },
  { label: 'IM Requirement', value: props.collateralProfileData.totalInitialMarginCollateralFunding },
]);

const canCreateCreditFacilityRequest = computed(() =>
  tradeState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_CREDIT_FACILITY)
);

const showCreditFacilityRequestOption = computed(
  () => !onBehalfOfClient.value?.id && canCreateCreditFacilityRequest.value
);

const isSpotLimitsGreaterThanZero = computed(() => props.limitsProfileData!.notionalLimitSpots > 0);

/**
 * The code bellow has the purpose to get the component's height and pass it to the parent
 * This will guarantee that the Your Mark-to-Market Position's component always has the same height that this one
 */
const component = ref<InstanceType<typeof HTMLElement>>();

const emit = defineEmits<{ (e: 'height-updated', height: number): void }>();

const totalCreditAmountInUse = computed(() => {
  if (props.collateralProfileData.creditUsage < 0) {
    return 0;
  } else if (props.collateralProfileData.creditUsage > 1) {
    return 1;
  }

  return props.collateralProfileData.creditUsage;
});

const formatter = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const totalCreditAmountInUseFormatted = computed(() => formatter.format(totalCreditAmountInUse.value * 100));

const totalCreditAmountRemaining = computed(() => 1 - totalCreditAmountInUse.value);

const updateHeight = () => {
  if (component.value) {
    const componentHeight = component.value.clientHeight;
    emit('height-updated', componentHeight);
  }
};

const handleResize = () => {
  nextTick(updateHeight);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  nextTick(updateHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <div ref="component">
    <div class="d-flex chart-section">
      <SimpleDoughnutChart
        :colors="getChartColorsForProfileUsageLevel(totalCreditAmountInUse)"
        :fractions="[totalCreditAmountInUse, totalCreditAmountRemaining]"
        secondaryLabel="Utilised"
      >
        <template #mainLabel>
          <div>{{ totalCreditAmountInUseFormatted }}</div>
          <div style="font-size: medium" class="mt-2">%</div>
        </template>
      </SimpleDoughnutChart>

      <div class="chart-info">
        <p v-for="item in creditInfo" :key="item.label">
          {{ item.label }}:
          <strong>
            {{ collateralProfileData.currency }}
            <FormattedNumber :value="item.value" class="formatted-value" />
          </strong>
        </p>
      </div>
    </div>
    <div class="my-3" v-if="showCreditFacilityRequestOption && clientId && limitsProfileData">
      If you would like to {{ isSpotLimitsGreaterThanZero ? 'increase your' : 'request a' }} credit offering, please
      <CreateCreditFacilityRequestModal
        v-slot="{ showCreditFacilityModal }"
        :limitsProfileData="limitsProfileData"
        :variationMarginPercentage="collateralProfileData.variationMarginPercentage"
        :clientId="clientId"
      >
        <a class="plain-color-link" href="#" @click="showCreditFacilityModal">click here</a>
      </CreateCreditFacilityRequestModal>
      to submit a request.
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-section {
  margin: 0.5em 0;

  h1 {
    margin-bottom: 1rem;
  }
  .chart-info {
    margin-left: 2rem;
    align-content: center;
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.formatted-value ::v-deep {
  .number-segment {
    font-size: 16px;
  }

  .number-segment-fraction,
  .number-segment-decimal {
    font-size: 14px;
  }
}

::v-deep .doughnut-chart {
  width: 160px;
  height: 160px;
}
</style>
