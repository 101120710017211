import { HttpService, HttpBasedService, HttpOptions } from 'ah-requests';
import { FeatureFlagRequest, FeatureFlagResponse } from '../models';

export class FeatureFlagService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'accountService' } },
      },
    });
  }

  public getfeatureFlag(
    params: FeatureFlagRequest,
    oboClientId?: string,
    options?: Partial<HttpOptions<FeatureFlagResponse>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<FeatureFlagResponse>(`${this.baseUrl}feature-flag`, {
      axiosConfig: {
        params,
        headers,
      },
      options,
    });
  }
}
