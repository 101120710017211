<script setup lang="ts">
import PaymentListing from 'ah-payments/src/components/PaymentListing.vue';
import PaymentsListFilters from './PaymentsListFilters.vue';
import ListBlock from 'ah-common-lib/src/common/components/listing/ListBlock.vue';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { ExportListType, PaginatedParams, PaymentType } from 'ah-api-gateways';
import { computed, reactive, ref, watch } from 'vue';
import { paymentsTableFields } from './tableFields/paymentsTableFields';
import { RequestState } from 'ah-requests';
import { useAuthStore } from '@/app/store/authStore';
import SizeSelector from 'ah-common-lib/src/common/components/SizeSelector.vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useAggregatedPaymentLinkCheck } from 'ah-trades/src/composables/aggregatedPaymentLimitChecker';
import { throwError } from 'rxjs';

withDefaults(
  defineProps<{
    /**
     * Whether to displayed export
     */
    showExport: boolean | string;
  }>(),
  {
    showExport: true,
  }
);

const paymentListing = ref<InstanceType<typeof PaymentListing> | null>(null);

const authStore = useAuthStore();

const router = useRouter();

const route = useRoute();

const commonFilters = computed(() => ({
  partnerId: authStore.loggedInIdentity?.partner.id,
  clientId: authStore.loggedInIdentity?.client?.id,
  excludeClients: !authStore.isClientUser,
}));

const state = reactive<{
  // Automatically syncronised via .sync
  dataDownloadState: RequestState;
  pageAndSortParams: Partial<PaginatedParams>;
}>({
  dataDownloadState: 'idle',
  pageAndSortParams: { sort: 'createdAt', sortDirection: 'DESC' },
});

const config = computed(() => {
  return {
    tableFields: paymentsTableFields,
  };
});

function downloadList(format: ExportListType, options: { [key: string]: any }) {
  paymentListing.value?.downloadData(format, options);
}

function loadData() {
  paymentListing.value?.loadData();
}

const pageOptions = ref([
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]);

/**
 * Logic for handling links coming from aggregated limit warnings
 *
 * Accepts the query string `forAggregatedReview`, will remove it and return state accordingly
 */
watch(
  () => route.query,
  () =>
    useAggregatedPaymentLinkCheck(router)
      .then(() =>
        setTimeout(() => {
          paymentListing.value?.loadData({ type: [PaymentType.SCHEDULED, PaymentType.TRADE_SEND_TO_BENEFICIARY] });
        })
      )
      .catch(() => throwError('Query param forAggregatedReview not found')),
  { immediate: true }
);

defineExpose({ loadData: loadData });
</script>

<template>
  <BoxGrid alignH="stretch" alignV="start">
    <BoxGridBlock cols="12">
      <ListBlock :commonFilters="commonFilters">
        <template #filters="{ onFilterChange, filter }">
          <PaymentsListFilters :filters="filter" @update:filter="onFilterChange" />
        </template>
        <template #list="{ filter, setFilter }">
          <PaymentListing
            ref="paymentListing"
            :config="config"
            :dataDownloadState.sync="state.dataDownloadState"
            :filter="filter"
            :sortAndPageParams.sync="state.pageAndSortParams"
            :showExport="false"
            @update:filter="setFilter"
            fixed
            with-simple-scroll
            nowrap
            v-on="$listeners"
            v-bind="$attrs"
          >
            <template #row-details="row">
              <slot name="row-details" v-bind="row" />
            </template>
          </PaymentListing>
        </template>
      </ListBlock>
    </BoxGridBlock>
    <BoxGridItem :itemClass="`flex-row align-items-end`" cols="12">
      <ListDownloadButton
        v-if="showExport !== false"
        :loading="state.dataDownloadState === 'pending'"
        class="download-button mr-auto"
        @download="downloadList"
        allowExportWithDetails
      >
        <template #list-download-modal-title>
          <h3 class="text-center">Include full details for all payments?</h3>
        </template>
        <template #list-download-modal-description
          ><p class="text-center my-b">
            Apart from the columns being shown in the table, do you want to include the full details for each one of the
            payments?
          </p></template
        >
      </ListDownloadButton>

      <SizeSelector
        class="ml-auto"
        :selected-value.sync="state.pageAndSortParams.pageSize"
        :customRowsOptions="pageOptions"
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.btn {
  .icon {
    &.icon {
      position: absolute;
      top: 50%;
      left: math.div($padded-space, 4);
      transform: translate(0, -50%);
    }
  }
}
</style>
