import { getChildModel, setState } from '../form/helpers';

export const defaultImageFileTypes = 'image/bmp,image/jpeg,image/jpg,image/gif,image/tiff,image/png';

export const defaultAcceptanceFileTypes = `${defaultImageFileTypes},application/pdf`;

export const defaultAcceptanceFileExtension = `.bmp,.jpeg,.jpg,.gif,.tiff,.png,.pdf`;

export function updateUploadedFiles(form: any, model: any, fileNames: string[]) {
  fileNames.forEach((key) => {
    const field = getChildModel(form, key);
    if (field && model[`has${key[0].toUpperCase() + key.substring(1)}`]) {
      setState(field, 'required', false);
      setState(field, 'defaultFileName', 'A file is already uploaded in our system.');
    }
  });
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function checkMIMEType(file: File, accept: string) {
  const types = accept.split(',');

  return types.find((t) => {
    if (t.endsWith('/*')) {
      return file.type.startsWith(t.substr(0, t.length - 1));
    }

    return file.type === t;
  });
}
