import { FileUploadRequest, VersionedObject } from '../models';
import { HttpBasedService, HttpService, uploadFileWithUpdates } from 'ah-requests';

export class CouttsService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'couttsService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public uploadCouttsBalance(file: File) {
    const formData = new FormData();

    formData.append(
      'model',
      JSON.stringify(<FileUploadRequest>{
        name: file.name,
      })
    );

    formData.append('file', file);

    return uploadFileWithUpdates<VersionedObject>(
      {
        axiosConfig: {
          url: `${this.baseUrl}coutts/balance`,
          method: 'POST',
          data: formData,
        },
        options: {
          errors: { silent: true },
        },
      },
      (c) => this.request(c)
    );
  }
}
