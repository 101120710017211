<script lang="ts" setup>
import { PaginatedQuery, FeeEntry, feeStateLabels, CustomFeeReason } from 'ah-api-gateways';
import { feeEntryTableFields } from '../../models/feesTableFields';
import { PaginatedTable } from 'ah-common-lib/src/common/components';
import FeeTransactionDetails from './FeeTransactionDetails.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { computed, onBeforeMount, PropType, ref } from 'vue';
import { useAhTradesState } from '../..';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: feeEntryTableFields }),
  },
  /**
   * Wallet ID for wallet outgoing/incoming reference
   *
   * As all fees come/go from/to clients/partners GBP wallet, a single `walletId` can be used to determine
   * whether these fees are outgoing or incoming, using the `fromWalletId` property
   */
  referenceWalletId: {
    type: String,
    required: true,
  },
  ...defineUseManagedListingProps<FeeEntry>(),
});

const tradeState = useAhTradesState();

const requestManager = useRequestManager().manager;

const paginatedTable = ref<InstanceType<typeof PaginatedTable> | null>(null);

const onBehalfOfClient = useOnBehalfOf();

interface FeeEntryEmits extends UseManagedListingEmits<FeeEntry> {}

const emit = defineEmits<FeeEntryEmits>();

const managedListing = useManagedListing<FeeEntry>({
  loadDataRequest(query: PaginatedQuery) {
    const filter: PaginatedQuery = {
      ...query,
      fromWalletId: props.referenceWalletId,
    };
    return tradeState.services.wallet.listFeeEntries(filter);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager,
});

function getAmount(item: FeeEntry) {
  return item.fromWalletId === props.referenceWalletId ? -item.amount : item.amount;
}

function getFeeStateLabels(item: FeeEntry) {
  return feeStateLabels[item.state];
}

const customFeeReasonOptions = ref<CustomFeeReason[]>([]);

function getCustomFeeReasons() {
  requestManager
    .sameOrCancelAndNew('getCustomFeeReasons', tradeState.services.fees.getCustomFeeReasons())
    .subscribe((getCustomFeeReasonsResponse: CustomFeeReason[]) => {
      customFeeReasonOptions.value = getCustomFeeReasonsResponse;
    });
}

function getCustomFeeReasonDescription(feeReason: string): string {
  const reason = customFeeReasonOptions.value.find((r) => r.value === feeReason);
  return reason ? reason.description : '';
}

onBeforeMount(() => {
  getCustomFeeReasons();
});

defineExpose({ loadData: managedListing.loadData });
</script>

<template>
  <PaginatedTable
    ref="paginatedTable"
    fixed
    hover
    nowrap
    animateCols
    class="fees-table"
    items-label="fees"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    withRowDetails
    :stacked="$ahTradesState.mediaQuery.is('smDown')"
  >
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template #cell(state)="{ item }">
      <IconAlertCircle class="status-icon danger" v-if="item.state === 'OUTSTANDING'" />
      {{ getFeeStateLabels(item) }}
    </template>
    <template #cell(amount)="{ item }"> {{ item.currency }} {{ formatCurrencyValue(getAmount(item)) }} </template>
    <template #row-details="{ item }">
      <FeeTransactionDetails
        :fee="item"
        :customFeeReasonDescription="getCustomFeeReasonDescription(item.customFeeReason)"
        :referenceWalletId="referenceWalletId"
      />
    </template>
    <template #tableNoResultsState>
      <h2 class="text-secondary">{{ onBehalfOfClient ? "Client doesn't" : "You don't" }} have any fees yet.</h2>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.status-icon.danger {
  font-size: 1.6em;
  margin-right: 0.2em;
  @include themedTextColor($color-danger);
}
</style>
