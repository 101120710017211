<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router/composables';
import { storeSetupDone } from '@/app/store';
import { useAuthStore } from '@/app/store/authStore';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';

const toast = useToast();
const services = getServices();

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const authStore = useAuthStore();

const settingsBase = computed(() => {
  return authStore.isAgent ? '/admin/settings' : '/settings';
});

onBeforeMount(() => {
  if (!props.token) {
    router.replace(`${settingsBase.value}/account`);
    toast.error('Incorrect email token provided');
  } else {
    storeSetupDone
      .then(() => services.account.verifyEmailChange(props.token, { errors: { silent: true } }).toPromise())
      .then(
        () => {
          authStore.refreshSession();
          toast.success('Email verified successfully.');
          router.replace(`${settingsBase.value}/account`);
        },
        () => {
          toast.error('Email confirmation failed.');
          router.replace(`${settingsBase.value}/account`);
        }
      );
  }
});
</script>

<template>
  <div class="email-verification">
    <h2>Verifying your email...</h2>
    <LoadingIcon class="loading-icon" />
  </div>
</template>

<style lang="scss" scoped>
.email-verification {
  text-align: center;

  .loading-icon {
    margin-top: -0.5em;
    font-size: 4em;
  }
}
</style>
