import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';
import Qs from 'qs';
import { LogService } from '../logs/logService';
import { HttpNotifier } from './httpNotifier';

export type CacheType = 'use' | 'override' | 'bypass' | 'delete';

export interface BypassCacheOptions {
  type: 'bypass';
}

export interface OverrideCacheOptions<T = any> {
  type: 'override';
  cacheKey: string;
  itemKey: string | ((item: T) => string);
}

export interface SimpleCacheOptions {
  type: 'use' | 'delete';
  cacheKey: string;
  itemKey: string;
}

export interface ErrorOptions {
  errorMessages?: ErrorMessage[];
  messageDefaults?: Partial<ErrorMessage>;
  catchAll?: ErrorMessage;
  silent?: boolean | ((error: HttpError) => boolean);
  useGenericErrors?: boolean;
}

export interface ErrorMessage {
  code?: number | ((code?: AxiosResponse<any>) => boolean);
  group?: string;
  name?: string;
  message?: string;
  toastType?: string;
}

export interface CustomAxiosRequestConfig<T> extends AxiosRequestConfig {
  userConfig?: {
    // userConfig needs to be a flexible object, as clients of the service may use it themselves (as in a 401 refresh cycle)
    [key: string]: any;
  } & HttpOptions<T>;
}

export type InternalCustomAxiosRequestConfig<T> = CustomAxiosRequestConfig<T> & InternalAxiosRequestConfig;

export interface HttpOptions<T = any> {
  skipAuth?: boolean;
  skipMaintenanceCheck?: boolean;
  cache?: OverrideCacheOptions<T> | BypassCacheOptions | SimpleCacheOptions;
  errors?: ErrorOptions;
}

export interface HttpRequestOptions<T = any> {
  options?: HttpOptions<T>;
  axiosConfig: CustomAxiosRequestConfig<T>;
}

export interface HttpError<T = any> extends AxiosError<ApiError> {
  config: InternalCustomAxiosRequestConfig<T>;
}

export interface BaseApiError {
  category: string;
  code: string;
  message: string;
}

export interface ApiError extends BaseApiError {
  occurrenceId: string;
  subErrors?: ApiSubError[];
  timestamp: string;
}

export interface ApiSubError extends BaseApiError {
  field: string;
}

export interface PayloadErrorData<M = any> {
  occurrenceId: string;
  errors?: ApiSubError[];
  fields?: {
    [key in keyof M]?: PayloadErrorData<M[key]>;
  };
}

/**
 * Axios based Http service wrapper.
 * Creates Axios instance and wraps promise based requests to observables
 */
export class HttpService {
  private axios: AxiosInstance = axios.create({
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  public logger?: LogService;

  public notifier?: HttpNotifier;

  constructor(logger?: LogService, notifier?: HttpNotifier) {
    this.logger = logger;
    this.notifier = notifier;
  }

  /**
   * performs an HTTP request returning an Observable
   *
   * @param options the custom options to handle the request and response
   */
  public request<T>(options: HttpRequestOptions) {
    return this.performRequest<T>(options);
  }

  /**
   * add http response interceptor
   *
   * @param onFulfilled function to be called when response is succefful
   * @param onRejected function to be called when response is an error
   * @returns the function to eject the added interceptor
   */
  public interceptResponse(
    onFulfilled?: ((value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>) | undefined,
    onRejected?: ((error: any) => any) | undefined
  ) {
    const interceptor = this.axios.interceptors.response.use(onFulfilled, onRejected);

    return () => {
      this.axios.interceptors.response.eject(interceptor);
    };
  }

  /**
   * add http request interceptor
   *
   * @param onFulfilled function to be called when request is succefful
   * @param onRejected function to be called when request is an error
   * @returns the function to eject the added interceptor
   */
  public interceptRequest(
    onFulfilled?:
      | ((value: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>)
      | undefined,
    onRejected?: ((error: any) => any) | undefined
  ) {
    const interceptor = this.axios.interceptors.request.use(onFulfilled, onRejected);

    return () => {
      this.axios.interceptors.response.eject(interceptor);
    };
  }

  // wraps an axios request with an Observable
  private performRequest<T>(requestOptions: HttpRequestOptions): Observable<AxiosResponse<T>> {
    // assign all custom options to userConfig, so they are available on responses
    // FIXME this results in a strange recursive nested options object, should delete options.config from userConfig
    requestOptions.axiosConfig.userConfig = {
      ...requestOptions.options,
      ...requestOptions.axiosConfig.userConfig,
    };

    return new Observable<any>((observer) => {
      let cancel!: () => void;

      this.axios
        .request({
          ...requestOptions.axiosConfig,
          cancelToken: new axios.CancelToken((c) => {
            cancel = c;
          }),
        })
        .then(
          (res) => {
            observer.next(res);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );

      return () => {
        if (cancel) {
          cancel();
        }
      };
    });
  }
}
