<script setup lang="ts">
import { PaginatedResponse } from 'ah-api-gateways';
import { BPagination } from 'bootstrap-vue';
import { computed, PropType } from 'vue';

/**
 * Wrapper for pagination in Listing
 *
 * Emits:
 * - page-changed (payload: number): number of page changed to (zero-based)
 */

const props = defineProps({
  data: {
    type: Object as PropType<PaginatedResponse<any> | null>,
    default: null,
  },
  showTotals: {
    type: [Boolean, String],
    default: true,
  },
});

const emit = defineEmits({
  'page-changed': (_pageNumber: number) => true,
});

const size = computed(() => props.data?.pageSize ?? 10);

const page = computed(() => props.data?.pageNumber ?? 0);

const dataSize = computed(() => Math.min(props.data?.list.length ?? 0, props.data?.total ?? 0));

function setPage(pageNumber: number) {
  emit('page-changed', pageNumber);
}
</script>

<template>
  <div v-if="data && data.total && (data.total > size || showTotals)">
    <div class="pagination-totals" v-if="showTotals">
      Showing
      <span v-if="data.list.length === 0"> 0 </span>
      <span v-if="dataSize < size"> {{ size * page + 1 }} - {{ size * page + dataSize }} </span>
      <span v-else> {{ size * page + 1 }} - {{ size * (page + 1) }} </span> of {{ data.total }} results
    </div>
    <BPagination
      v-if="data.total > size"
      class="pagination"
      :value="page + 1"
      :per-page="size"
      :total-rows="data.total"
      align="right"
      @change="setPage($event - 1)"
    />
  </div>
</template>
