import { AxiosResponse } from 'axios';

export function responseToBlob(response: AxiosResponse<any>) {
  const filename = '';
  const type = response.headers['content-type'];

  let blob;
  if (typeof File === 'function') {
    try {
      blob = new File([response.data], filename, { type });
    } catch (e) {
      blob = new Blob([response.data], { type });
    }
  }

  return blob;
}

export function downloadFile(response: AxiosResponse<any>) {
  const blob = responseToBlob(response);
  let filename = '';
  const disposition = response.headers['content-disposition'];
  if (disposition && disposition.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
  }

  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    (window.navigator as any).msSaveBlob(blob, filename);
  } else if (blob instanceof Blob) {
    downloadBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob as any);

    if (filename) {
      const a = document.createElement('a');
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl as any;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      window.location = downloadUrl as any;
    }

    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
}

export function downloadBlob(blob: Blob, fileName: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function fileToBlob(response: AxiosResponse<any>) {
  return responseToBlob(response);
}
