<script setup lang="ts">
import { UpdateEntry, UpdateType } from 'ah-api-gateways';
import UpdateEntryCard from './UpdateEntryCard.vue';
import { format, isSameDay, isToday, isYesterday } from 'date-fns';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const props = defineProps({
  updateTypeSelected: {
    type: String as PropType<UpdateType | null>,
    default: null,
  },
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadUpdates') {
      loadMoreItems();
    }
  },
}).manager;

const services = getServices();

const updates = ref<UpdateEntry[]>([]);

const total = ref(-1);

const notificationsStore = useNotificationsStore();

onMounted(() => {
  if (notificationsStore.updateUnreadCount > 0) {
    notificationsStore.markAllAsRead('updates');
  }
});

const noUpdates = computed(() => !updates.value || updates.value.length === 0);

const hasMoreUpdates = computed(() => total.value === -1 || updates.value.length >= total.value);

function onInfiniteLoad(intersection: IntersectionObserverEntry) {
  if (intersection.isIntersecting) {
    loadMoreItems();
  }
}

function isSameDayDate(a: UpdateEntry, b: UpdateEntry) {
  return isSameDay(new Date(a.date), new Date(b.date));
}

function loadMoreItems() {
  requestManager
    .currentOrNew(
      'loadUpdates',
      services.updates.listUpdateEntries({
        sort: 'date,desc',
        type: props.updateTypeSelected || undefined,
      })
    )
    .subscribe((list) => {
      total.value = list.total;
      updates.value.push(...list.list);
    });
}

function formatDateValue(date: string) {
  if (isToday(new Date(date))) {
    return 'TODAY';
  } else if (isYesterday(new Date(date))) {
    return 'YESTERDAY';
  }

  return format(new Date(date), 'EEEE, LLLL d yyyy');
}

function onUpdateTypeChanges() {
  updates.value = [];
  total.value = -1;
  loadMoreItems();
}

watch(() => props.updateTypeSelected, onUpdateTypeChanges, { immediate: true });
</script>

<template>
  <div class="notifications-list">
    <div v-for="(item, index) in updates" :key="item.id">
      <span v-if="index === 0 || !isSameDayDate(updates[index - 1], item)" class="date-label text-secondary d-block">
        <hr v-if="index !== 0" class="mb-4" />
        <h3>{{ formatDateValue(item.date) }}</h3>
      </span>
      <UpdateEntryCard :update="item" />
    </div>
    <div
      v-if="!noUpdates && hasMoreUpdates"
      v-on-intersect="($event) => onInfiniteLoad"
      class="loader d-flex justify-content-center"
    >
      <LoadingIcon v-if="requestManager.anyPending" class="loading-icon" />
    </div>
    <LoadingIcon v-if="noUpdates && requestManager.anyPending" class="loading-icon" />
    <div v-else-if="noUpdates" class="notification-card card-block text-center">No updates found.</div>
  </div>
</template>

<style lang="scss" scoped>
.loading-icon {
  width: 1.5em;
  height: 1.5em;
}

.date-label {
  font-size: 0.7em;
  font-weight: 700;
}

hr {
  @include themedBorderColor($color-box-border, $color-box-border);
}
</style>
