<script lang="ts" setup>
import AppBanner from '@/app/components/common/AppBanner.vue';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { onBeforeUnmount, ref } from 'vue';
import { useAppUpdaterState } from 'ah-app-updater';

const UPDATE_TIMEOUT = MINUTE * 30;

const appUpdaterState = useAppUpdaterState();

const shouldShowUpdate = ref(false);

let updateCheckTimeout: ReturnType<typeof setTimeout> | null = null;

/**
 * Updates the `shouldShowUpdate` value according to the following requirements (all must be true):
 * - App is not up to date
 * - last version check was at least UPDATE_TIMEOUT ago
 * - last update attempt was not to the latest version, or it was at least UPDATE_TIMEOUT ago
 */
const updateShouldUpdate = () => {
  shouldShowUpdate.value =
    !appUpdaterState.isUpToDate &&
    appUpdaterState.lastVersionCheck + UPDATE_TIMEOUT < Date.now() &&
    (appUpdaterState.latestAttemptedVersion !== appUpdaterState.latestVersion ||
      appUpdaterState.latestUpdateAttempt + UPDATE_TIMEOUT < Date.now());
  updateCheckTimeout = setTimeout(updateShouldUpdate, MINUTE * 2);
};
updateShouldUpdate();

function updateApp() {
  appUpdaterState.reloadToUpdate(true);
}

onBeforeUnmount(() => updateCheckTimeout && clearTimeout(updateCheckTimeout));
</script>

<template>
  <AppBanner class="text-center info" v-if="shouldShowUpdate">
    <p class="mb-0">
      An update for this application is available. Please refresh your browser or
      <a class="link" @click="updateApp">click here to update.</a>
    </p>
  </AppBanner>
</template>
