<script setup lang="ts">
import { Stoppable, useIntervalFn, useTimeoutFn } from '@vueuse/core';
import { SECOND, DAY, HOUR, MINUTE } from 'ah-common-lib/src/constants/time';
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    targetDate: Date;
    pause?: boolean;
  }>(),
  { pause: false }
);

const difference = ref<string[]>([]);

const emit = defineEmits<{
  (e: 'timer-end'): void;
}>();

function updateUI() {
  let differenceDate = new Date(props.targetDate).getTime() - new Date().getTime();

  if (differenceDate >= 0) {
    let daysDifference = Math.floor(differenceDate / 1000 / 60 / 60 / 24);
    differenceDate -= daysDifference * DAY;
    let hoursDifference = Math.floor(differenceDate / 1000 / 60 / 60);
    differenceDate -= hoursDifference * HOUR;
    let minutesDifference = Math.floor(differenceDate / 1000 / 60);
    differenceDate -= minutesDifference * MINUTE;
    let secondsDifference = Math.floor(differenceDate / SECOND);

    const value = [
      daysDifference.toString(),
      hoursDifference.toString(),
      minutesDifference.toString(),
      secondsDifference.toString(),
    ].map((elem) => (elem.length === 1 ? '0' + elem : elem));

    if (value[0] === '00') {
      value.shift();
    }

    difference.value = value;
  } else {
    difference.value = ['00', '00', '00'];
  }
}

useIntervalFn(updateUI, SECOND);

let timerTimeout: Stoppable | null = null;

watch(
  () => [props.targetDate, props.pause],
  () => {
    if (timerTimeout) {
      timerTimeout.stop();
    }
    if (new Date(props.targetDate) >= new Date()) {
      timerTimeout = useTimeoutFn(() => {
        if (!props.pause) {
          emit('timer-end');
        }
      }, props.targetDate.valueOf() - Date.now());
    } else {
      if (!props.pause) {
        emit('timer-end');
      }
    }
    updateUI();
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <template v-for="(differenceElement, index) in difference">
      <span class="counter-text counter-box" :key="`counter-${index}`">{{ differenceElement }}</span>
      <span class="counter-text" v-if="index < difference.length - 1" :key="`separator-${index}`">:</span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.counter-text {
  @include themedTextColor($color-primary);
  margin-right: 0.5rem;
  &.counter-box {
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    @include themedBorderColor($color-primary);
  }
}
</style>
