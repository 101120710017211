import {
  CapitalCostMonitor,
  CollateralMonitor,
  DeltaRiskReport,
  DeltaRiskTotalReport,
  DocumentExport,
  ExportListType,
  ListQuery,
  PaginatedQuery,
  PaginatedResponse,
  PositionLimitsMonitor,
  standardiseQuerySort,
  MarginCreditUsageReport,
  ClientMarginCreditUsageReport,
  ClientPositionUsageReport,
  PositionUsageReport,
  EODSpotRate,
  ClientCollaterals,
  ClientRiskProfileReportParams,
  ClientRiskProfileReport,
  LegalFileType,
  ExportFileType,
  OpenPositionsMtmMonitor,
  ClientMarginCreditAndLimitsListReport,
} from '../models';
import { downloadFile, HttpBasedService, HttpOptions, HttpRequestOptions, HttpService } from 'ah-requests';
import { AMLReportData } from '../models/analytics/aml';
import { map } from 'rxjs/operators';

export class RiskReportsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'riskReportsService' } },
      },
    });
  }

  public getClientRiskProfile(
    params: ClientRiskProfileReportParams,
    options?: Partial<HttpRequestOptions<ClientRiskProfileReport>>
  ) {
    const { clientId, oboClientId } = params;
    const headers: Record<string, string> = {};

    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.get<ClientRiskProfileReport>(`${this.baseUrl}reports/risk-profile/${clientId}`, {
      ...options,
      axiosConfig: { ...options?.axiosConfig, headers },
    });
  }

  public positionLimitsUsage(params?: PaginatedQuery) {
    params = standardiseQuerySort(params || {});
    return this.get<PaginatedResponse<ClientPositionUsageReport>>(`${this.baseUrl}risk/usage`, {
      axiosConfig: { params },
    });
  }

  public getClientPositionLimitsUsage(clientId: string, options?: HttpOptions<ClientPositionUsageReport>) {
    return this.get<ClientPositionUsageReport>(`${this.baseUrl}risk/usage/${clientId}`, {
      options,
    });
  }

  public getClientCreditUsage(clientId: string, options?: HttpOptions<ClientMarginCreditUsageReport>) {
    return this.get<ClientMarginCreditUsageReport>(`${this.baseUrl}risk/margin-credits-usage/${clientId}`, {
      options,
    });
  }

  public downloadUsageAnalytics(params: ListQuery, fileFormat: ExportListType, documentTitle?: string) {
    params = standardiseQuerySort(params || {});
    return this.downloadAnalytics(`${this.baseUrl}risk/usage/export`, params, fileFormat, documentTitle);
  }

  public positionLimitsUsageTotal() {
    return this.get<PositionUsageReport>(`${this.baseUrl}risk/usage/total`);
  }

  public marginCreditsUsage(params?: PaginatedQuery) {
    params = standardiseQuerySort(params || {});
    return this.get<PaginatedResponse<ClientMarginCreditUsageReport>>(`${this.baseUrl}risk/margin-credits-usage`, {
      axiosConfig: { params },
    });
  }

  public marginCreditsUsageTotal() {
    return this.get<MarginCreditUsageReport>(`${this.baseUrl}risk/margin-credits-usage/total`);
  }

  public downloadMarginCreditAnalytics(params: ListQuery, fileFormat: ExportListType, documentTitle?: string) {
    params = standardiseQuerySort(params || {});
    return this.downloadAnalytics(`${this.baseUrl}risk/margin-credits-usage/export`, params, fileFormat, documentTitle);
  }

  public collateralsAnalytics(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<CollateralMonitor>>(`${this.baseUrl}risk/collaterals`, {
      axiosConfig: { params },
    });
  }

  public getClientCollateral(clientId: string) {
    return this.get<ClientCollaterals>(`${this.baseUrl}risk/collaterals/clients/${clientId}`);
  }

  public downloadCollateralsAnalytics(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Collaterals Report'
  ) {
    return this.downloadAnalytics(`${this.baseUrl}risk/collaterals/export`, query, fileFormat, documentTitle);
  }

  public capitalCostAnalytics(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<CapitalCostMonitor>>(`${this.baseUrl}risk/capital-costs`, {
      axiosConfig: { params },
    });
  }

  public downloadCapitalCostAnalytics(
    params: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Capital Cost Report'
  ) {
    params = standardiseQuerySort(params);
    return this.downloadAnalytics(`${this.baseUrl}risk/capital-costs/export`, params, fileFormat, documentTitle);
  }

  public positionLimitsAnalytics(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<PositionLimitsMonitor>>(`${this.baseUrl}risk/position-limits`, {
      axiosConfig: { params },
    });
  }

  public downloadLimitsAnalytics(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Position Limits Report'
  ) {
    return this.downloadAnalytics(`${this.baseUrl}risk/position-limits/export`, query, fileFormat, documentTitle);
  }

  public getTotalDeltaRiskReport(params: PaginatedQuery, options?: Partial<HttpOptions<DeltaRiskTotalReport>>) {
    const standardiseParams = standardiseQuerySort(params);

    return this.get<DeltaRiskTotalReport>(`${this.baseUrl}risk/delta-risk/total`, {
      options,
      axiosConfig: {
        params: standardiseParams,
      },
    });
  }

  public getDeltaRiskReport(
    params: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<DeltaRiskReport>>>
  ) {
    const standardiseParams = standardiseQuerySort(params);

    return this.get<PaginatedResponse<DeltaRiskReport>>(`${this.baseUrl}risk/delta-risk/clients`, {
      options,
      axiosConfig: {
        params: standardiseParams,
      },
    });
  }

  public downloadDeltaRiskReport(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Delta Risk Report') {
    return this.downloadAnalytics(`${this.baseUrl}risk/delta-risk/clients/export`, query, fileFormat, documentTitle);
  }

  public listEODSpotRates(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<EODSpotRate>>(`${this.baseUrl}risk/eod-spot-rates`, {
      axiosConfig: { params },
    });
  }

  public downloadEODSpotRates(query: ListQuery, fileFormat: ExportListType, documentTitle: string) {
    return this.downloadAnalytics(`${this.baseUrl}risk/eod-spot-rates/export`, query, fileFormat, documentTitle);
  }

  public listAMLReport(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<AMLReportData>>(`${this.baseUrl}risk/aml-monitoring`, {
      axiosConfig: { params },
    });
  }

  public listRiskProfileReport(params?: PaginatedQuery) {
    params = standardiseQuerySort(params || {});
    return this.get<PaginatedResponse<ClientMarginCreditAndLimitsListReport>>(`${this.baseUrl}reports/risk-profile`, {
      axiosConfig: { params },
    });
  }

  public downloadAMLReport(query: ListQuery, fileFormat: ExportListType, documentTitle = 'AML Report') {
    return this.downloadAnalytics(`${this.baseUrl}risk/aml-monitoring/export`, query, fileFormat, documentTitle);
  }

  public downloadLegalDocument(documentType: LegalFileType, exportType: ExportFileType) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: `${this.baseUrl}risk/documents/${documentType}/content/${exportType}/download`,
      },
    }).pipe(map(downloadFile));
  }

  private downloadAnalytics(url: string, query: ListQuery, fileFormat: ExportListType, documentTitle?: string) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public listOpenPositionsMtm(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<OpenPositionsMtmMonitor>>(`${this.baseUrl}risk/open-positions/mtm`, {
      axiosConfig: { params },
    });
  }
}
