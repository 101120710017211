import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const adminPaymentsTableFields: TableFieldDefinition[] = [
  {
    header: 'Payment ID',
    key: 'composedReferenceNumber',
    sortable: true,
  },
  {
    header: 'Client',
    key: 'clientName',
    sortable: true,
  },
  {
    header: 'Type',
    key: 'type',
    sortable: true,
  },
  {
    header: 'Currency',
    key: 'currency',
    sortable: true,
  },
  {
    header: 'Amount',
    key: 'amount',
    sortable: true,
  },
  {
    header: 'Beneficiary',
    key: 'beneficiaryName',
    sortable: true,
  },
  {
    header: 'Method',
    key: 'beneficiaryPaymentRail',
    sortable: true,
  },
  {
    header: 'State',
    key: 'state',
    sortable: true,
  },
  {
    header: 'Creation Date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Payment Date',
    key: 'executionDate',
    sortable: true,
  },
];

export const paymentsTableFields: TableFieldDefinition[] = [
  {
    header: 'Payment ID',
    key: 'composedReferenceNumber',
    sortable: true,
  },
  {
    header: 'Type',
    key: 'type',
    sortable: true,
  },
  {
    header: 'Currency',
    key: 'currency',
    sortable: true,
    tdClass: 'text-center',
  },
  {
    header: 'Amount',
    key: 'amount',
    sortable: true,
  },
  {
    header: 'Beneficiary',
    key: 'beneficiaryName',
    sortable: true,
  },
  {
    header: 'Method',
    key: 'beneficiaryPaymentRail',
    sortable: true,
  },
  {
    header: 'State',
    key: 'state',
    sortable: true,
  },
  {
    header: 'Creation Date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Payment Date',
    key: 'executionDate',
    sortable: true,
  },
];
