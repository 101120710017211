<script setup lang="ts">
import OnboardingReview from './OnboardingReview.vue';
import { RegistrationStepKeys } from '@/app/helpers/registration/flow';
import { Client } from 'ah-api-gateways';

defineProps<{ client: Client }>();

const emit = defineEmits<{ (e: 'flow-finished'): void }>();

function finish() {
  emit('flow-finished');
}
</script>

<template>
  <div>
    <h2>Almost there!</h2>
    <div class="text-secondary">
      <p>
        You have completed an application on behalf of {{ client.name }} and it is currently being verified by the
        authorised signatory named in the form.
      </p>
    </div>
    <OnboardingReview @application-accepted="finish" :allowEdit="[RegistrationStepKeys.SIGNATORY]" :client="client" />
  </div>
</template>
