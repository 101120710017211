import { render, staticRenderFns } from "./EntityNameCell.vue?vue&type=template&id=36bdadb0&scoped=true"
import script from "./EntityNameCell.vue?vue&type=script&setup=true&lang=ts"
export * from "./EntityNameCell.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./EntityNameCell.vue?vue&type=style&index=0&id=36bdadb0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bdadb0",
  null
  
)

export default component.exports