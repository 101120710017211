import { BaseFormModel, CompoundValidation, FormValidation } from '../interfaces';
import { ValidationRule, ValidatorFn } from '@vuelidate/core';

export function makeCompoundValidation<T = BaseFormModel>(validations: FormValidation<T>[]): CompoundValidation<T> {
  return {
    validations,
    $pending: validations.reduce<boolean>((pending, validation) => pending || validation.$pending, false),
    $dirty: validations.reduce<boolean>((dirty, validation) => dirty || validation.$dirty, false),
    $invalid: validations.reduce<boolean>((dirty, validation) => dirty || validation.$invalid, false),
  };
}

export function getValidatorFn(validator: ValidationRule): ValidatorFn {
  if (typeof validator === 'function') {
    return validator as ValidatorFn;
  }

  return validator.$validator;
}
