<script setup lang="ts">
import OutstandingFeesBlock from 'ah-trades/src/components/fees/OutstandingFeesBlock.vue';
import { clientOutstandingFeesTableFields } from 'ah-trades/src/components/fees/tableFields/outstandingFeeTableFields';
import { computed } from 'vue';

const tableConfig = computed(() => ({
  tableFields: clientOutstandingFeesTableFields,
}));
</script>

<template>
  <div class="padded-space" id="position-limits-view">
    <div class="inner">
      <h2>Outstanding Fees</h2>
      <OutstandingFeesBlock
        paginationQueryParam="outstandingFeesSort"
        filterQueryParam="outstandingFeesFilter"
        :config="tableConfig"
        highlight="both"
        hide-owner-type
        export-outside
      />
    </div>
  </div>
</template>
