<script lang="ts" setup>
import { Trade } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import DrawdownCreator from '../DrawdownCreator.vue';
import { ref } from 'vue';

const modal = ref<BModal | null>(null);

withDefaults(
  defineProps<{
    trade: Trade;
    buttonTitle?: string;
  }>(),
  {
    buttonTitle: 'Drawdown',
  }
);

function show() {
  modal.value?.show();
}

function hide() {
  modal.value?.hide();
}
</script>

<template>
  <span>
    <BModal title="Drawdown" modal-class="side-modal" ref="modal" hide-footer>
      <DrawdownCreator :trade="trade" narrow @cancel="hide" @drawdown-executed="hide" v-on="$listeners" />
    </BModal>
    <slot v-bind="{ show }">
      <VButton blurOnClick @click="show" class="button-success">
        {{ buttonTitle }}
      </VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .modal-body {
  @include upToResolution($tabletResolution) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
</style>
