<script setup lang="ts">
import SearchableClientList from '@/app/components/clients/SearchableClientList.vue';
import ClientListFilters from '@/app/components/clients/ClientListFilters.vue';
import { AuthorityType, Client } from 'ah-api-gateways';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';
import { clientTableFields } from '@/app/components/clients/tableFields/clientTableFields';
import { useAuthStore } from '@/app/store/authStore';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';

const authStore = useAuthStore();
const router = useRouter();

const fields = computed(() => clientTableFields);
const baseUrl = '/admin/activity/clients';

function onClientSelect(client: Client) {
  router.push({ path: `${baseUrl}/${client.id}` });
}

const canExport = computed(() => authStore.hasAuthorities(AuthorityType.EXPORT_CLIENT_REPORTS));
</script>

<template>
  <div class="centered-space padded-space" id="activity-clients-view">
    <h2>Clients</h2>
    <SearchableClientList
      :showExport="canExport"
      :commonFilters="{ withAssignees: true, withSettings: true, withIndividuals: true }"
      @row-clicked="onClientSelect"
    >
      <template #filters="{ setFilters }">
        <ClientListFilters @update:filter="setFilters" />
      </template>
      <template #sorter="{ sortAndPageParams, setSortAndPageParams }">
        <SortSelector
          :fields="fields"
          v-if="$mediaQuery.is('smDown')"
          :sortAndPageParams="sortAndPageParams"
          @update:sortAndPageParams="setSortAndPageParams"
          class="w-100"
        />
      </template>
    </SearchableClientList>
  </div>
</template>
