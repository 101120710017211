var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"simple-centered-page"},[_c('div',{class:[
      'main-area',
      {
        narrow: _vm.narrow !== false,
        padded: _vm.padded !== false,
        'full-width': _vm.fullWidth !== false,
        'full-height': _vm.fullHeight !== false,
      },
    ]},[_vm._t("default")],2),(_vm.$scopedSlots.footer)?_c('div',{class:['footer', _vm.footerClass]},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }