<script lang="ts" setup>
import { computed } from 'vue';
import { Trade } from 'ah-api-gateways';
import { formatHedgingInstrument, getBuyCcy, getPrimaryCcy, getSellCcy } from 'ah-api-gateways/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { formatDate } from 'ah-common-lib/src/helpers/time';

const props = defineProps<{
  parentTrade: Trade;
  drawdown: Trade;
}>();

const clientRate = computed(() => getPrimaryCcy(props.drawdown).clientRate);

const buyCcy = computed(() => getBuyCcy(props.drawdown).currency);

const sellCcy = computed(() => getSellCcy(props.drawdown).currency);

const buyAmount = computed(() => getBuyCcy(props.drawdown).clientAmount);

const sellAmount = computed(() => getSellCcy(props.drawdown).clientAmount);

const remainingSellAmount = computed(() => {
  const amount = getSellCcy(props.parentTrade).clientAmount - sellAmount.value;
  if (amount <= 0) return 0;
  return amount;
});

const remainingBuyAmount = computed(() => {
  const amount = getBuyCcy(props.parentTrade).clientAmount - buyAmount.value;
  if (amount <= 0) return 0;
  return amount;
});
</script>

<template>
  <div>
    <DataRow cols="6" label="Instrument" class="mb-2">
      {{ formatHedgingInstrument(drawdown.hedgingProduct) }}
    </DataRow>
    <DataRow cols="6" label="Selling" class="mb-2"> {{ sellCcy }} {{ formatCurrencyValue(sellAmount) }} </DataRow>
    <DataRow cols="6" label="Buying" class="mb-2"> {{ buyCcy }} {{ formatCurrencyValue(buyAmount) }} </DataRow>
    <DataRow cols="6" label="New FX Rate" class="mb-2">
      {{ $ahTradesState.store.useSettingsStore().displayRate(clientRate) }}
    </DataRow>
    <DataRow cols="6" label="New Settlement Date" class="mb-2">
      {{ formatDate(drawdown.settlementDate, SIMPLE_DATE_FORMAT) }}
    </DataRow>
    <hr class="line my-3" />
    <DataRow cols="6" label="Remaining notionals">
      <div class="mb-2">{{ sellCcy }} {{ formatCurrencyValue(remainingSellAmount) }}</div>
      <div>{{ buyCcy }} {{ formatCurrencyValue(remainingBuyAmount) }}</div>
    </DataRow>
  </div>
</template>

<style lang="scss" scoped>
.line {
  border-width: 2px;
  @include themedBorderColor($color-widgets-grey, $color-widgets-grey);
}
</style>
