<script setup lang="ts">
import SearchableTradeList from 'ah-trades/src/components/lists/SearchableTradeList.vue';
import {
  Client,
  NotificationEventType,
  NotificationType,
  openTradeStatuses,
  openTradeStates,
  fxHedgingInstruments,
} from 'ah-api-gateways';
import { Subscription } from 'rxjs';
import CollateralInfoBlock from 'ah-trades/src/components/collaterals/CollateralInfoBlock.vue';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { useWalletsStore } from '@/app/store/walletsModule';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { useAuthStore } from '@/app/store/authStore';
import { partnerTradeReportTableFieldsFull } from 'ah-trades/src/models/tradeTableFields';
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useRoute, useRouter } from 'vue-router/composables';

const tradeEvents = [
  NotificationType.TRADE_CONFIRMED,
  NotificationType.TRADE_REJECTED,
  NotificationType.POSITION_STATUS_OPEN,
  NotificationType.POSITION_STATUS_CLOSE,
];

const props = defineProps({ clientId: { type: String, required: false } });

const tradeList = ref<InstanceType<typeof SearchableTradeList>>();

const onBehalfOfClient = ref<Client>();

const eventsSubscription = ref<Subscription>();

const notificationsStore = useNotificationsStore();

const walletStore = useWalletsStore();

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const services = getServices();

const route = useRoute();

const router = useRouter();

const isPartnerUser = computed(() => !onBehalfOfClient.value && !authStore.isClientUser);

const partnerId = computed(() => {
  if (isPartnerUser.value) {
    return authStore.loggedInIdentity?.partner.id;
  }

  return undefined;
});

const config = computed(() => {
  if (isPartnerUser.value) {
    return { tableFields: partnerTradeReportTableFieldsFull };
  }

  return undefined;
});

onBeforeMount(() => {
  eventsSubscription.value = notificationsStore.notificationEvents.subscribe((event) => {
    if (event.eventType === NotificationEventType.NOTIFICATION_CREATED && tradeEvents.includes(event.payload?.type!)) {
      tradeList.value!.reload();
    }
  });
  walletStore.loadCollaterals({ force: true });
});

onUnmounted(() => {
  eventsSubscription.value!.unsubscribe();
});

function onClientIdChange() {
  if (props.clientId) {
    requestManager
      .sameOrCancelAndNew(`getClient-${props.clientId}`, services.client.getClient(props.clientId))
      .subscribe((response) => (onBehalfOfClient.value = response));
  }
}

watch(() => props.clientId, onClientIdChange, { immediate: true });

const openTrade = ref<string>();

const readonly = computed(() => route.meta?.readonly || false);

onMounted(() => {
  if (route.query?.drawdown) {
    const { drawdown, ...query } = route.query;
    router.replace({ path: route.path, query });

    openTrade.value = drawdown as string;
  }
});
</script>

<template>
  <OnBehalfOf :client="onBehalfOfClient">
    <div class="padded-space" id="open-positions-view">
      <div class="inner">
        <h2>Open Spot and Forward Positions</h2>
        <CollateralInfoBlock v-if="!isPartnerUser" class="mb-3 info-block" />
        <SearchableTradeList
          ref="tradeList"
          showSearch
          withRowDetails
          showExport
          :possibleStatuses="openTradeStatuses"
          :possibleStates="openTradeStates"
          :possibleInstruments="fxHedgingInstruments"
          :commonFilters="{ clientId: clientId, partnerId: partnerId }"
          :config="config"
          :openTrade="openTrade"
          :readonly="readonly"
          paginationQueryParam="openFxPositionsSort"
          filterQueryParam="openFxPositionsFilter"
          documentTitle="Open FX Positions List"
        />
      </div>
    </div>
  </OnBehalfOf>
</template>

<style lang="scss" scoped>
.inner {
  position: relative;
}

.trade-button {
  position: absolute;
  right: 0;
  top: 0;
}

.info-block {
  max-width: 600px;
}
</style>
