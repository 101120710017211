<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7458 0.920569C12.5216 -0.306856 10.5307 -0.306856 9.30641 0.920569L1.16697 9.05794C1.10333 9.12159 1.06394 9.20342 1.05182 9.29131L0.448785 13.7585C0.430603 13.8858 0.476057 14.0131 0.563937 14.101C0.639695 14.1768 0.745756 14.2222 0.851817 14.2222C0.869999 14.2222 0.888181 14.2222 0.906363 14.2192L3.59729 13.8555C3.82153 13.8252 3.97911 13.6191 3.9488 13.3948C3.9185 13.1706 3.71244 13.013 3.4882 13.0433L1.33061 13.3342L1.75182 10.2187L5.03063 13.4979C5.10639 13.5737 5.21245 13.6191 5.31851 13.6191C5.42457 13.6191 5.53063 13.5767 5.60639 13.4979L13.7458 5.36051C14.3398 4.7665 14.667 3.97852 14.667 3.13903C14.667 2.29953 14.3398 1.51155 13.7458 0.920569ZM9.46399 1.92069L10.8307 3.28753L3.40335 10.7157L2.03667 9.34889L9.46399 1.92069ZM5.32154 12.6311L3.98517 11.2946L11.4125 3.86639L12.7489 5.20292L5.32154 12.6311ZM13.3186 4.618L10.0488 1.34789C10.464 1.00543 10.9822 0.817526 11.5276 0.817526C12.1488 0.817526 12.7307 1.05998 13.1701 1.4964C13.6095 1.93282 13.8489 2.51774 13.8489 3.13903C13.8489 3.68758 13.661 4.20279 13.3186 4.618Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
