<script setup lang="ts">
import { useRouter } from 'vue-router/composables';

const router = useRouter();

defineProps<{ createdTrade: string }>();
</script>

<template>
  <BoxGrid class="mt-5 ml-md-5">
    <BoxGridBlock>
      <BoxGrid class="p-md-5 p-sm-2">
        <BoxGridItem class="text-center">
          <div>
            <IconCircleCheck class="icon" />
          </div>

          <h1 class="mt-3">Trade order received</h1>
          <p>
            Your Vanilla Option trade with ID
            <span class="reference-number">{{ createdTrade }}</span> has been successfully received.
          </p>
          <p>
            Instructions on how to settle the transaction, along with any premiums payable, can be found in your
            confirmation email.
          </p>
          <p>
            To review this trade and others, please visit your Open Positions page or check your confirmation email for
            more details.
          </p>

          <div>
            <VButton @click="router.replace('/dashboard/trades/open-options')">See Open Positions</VButton>
          </div>
        </BoxGridItem>
      </BoxGrid>
    </BoxGridBlock>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.icon {
  @include themedTextColor($color-primary);
  width: 4rem;
  height: 4rem;
}

.reference-number {
  font-weight: bold;
}
</style>
