import { StoreSupportData } from 'ah-common-lib/src/store';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  Notification,
  PaginatedQuery,
  NotificationEventType,
  NotificationAlertType,
  NotificationEvent,
  publicNotifications,
  updateNotifications,
  NotificationType,
  fileExportNotifications,
  NotificationInfo,
  NotificationService,
  DocumentExport,
  DocumentsService,
} from 'ah-api-gateways';
import { RequestState } from 'ah-requests';
import { isActionRequestMessage, SyncMessage } from 'ah-common-lib/src/tabSync/syncMessage';
import { isEqual } from 'lodash';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { Toast } from 'ah-common-lib';
import { AuthStore } from 'ah-common-stores';
import Vue from 'vue';
import { defineStore } from 'pinia';

// Importing persisted state plugin for type completion
import 'pinia-plugin-persistedstate';

let useAuthStore: () => AuthStore = (() => {
  throw 'No AuthStore set for notificationsModule!';
}) as any;

export const setAuthStoreGetter = (getter: () => AuthStore) => {
  useAuthStore = getter;
};

export interface NotificationModuleSupportMetadata {
  notificationWatcher: Subscription | null;
  notificationEvents: Subject<NotificationEvent>;
  toast: Toast.Toast;
}

export type NotificationModuleSupportData = StoreSupportData<
  NotificationModuleSupportMetadata,
  { notification: NotificationService; documents: DocumentsService }
>;

let lastReconnectionTime = 0;
let reconnectTimeout: number | null = null;

const NOTIFICATION_ACTION_TRIGGER = 'NOTIFICATION_ACTION_TRIGGER';
const WATCH_NOTIFICATIONS = 'WATCH_NOTIFICATIONS';
const HIDE_TOAST = 'HIDE_TOAST';

let sd!: NotificationModuleSupportData;

function getNotifArrayKey(type: NotificationType) {
  if (publicNotifications.includes(type)) {
    return '_notifications';
  }
  if (fileExportNotifications.includes(type)) {
    return '_fileExportNotifications';
  }
}

let makeStoreSupportData: (
  data: Omit<NotificationModuleSupportMetadata, 'toast'>
) => NotificationModuleSupportData = () => {
  throw 'Must implement store factory function!';
};

export function setNotificationsModuleSupportDataFactory(
  factory: (data: Omit<NotificationModuleSupportMetadata, 'toast'>) => NotificationModuleSupportData
) {
  makeStoreSupportData = factory;
}

export type NotificationsStore = ReturnType<typeof useNotificationsStore>;

export const useNotificationsStore = defineStore('notificationsModule', {
  persist: true,
  state: () => {
    if (!sd) {
      sd = makeStoreSupportData({
        notificationWatcher: null,
        notificationEvents: new Subject<NotificationEvent>(),
      });
    }
    return {
      _requestStates: {} as { [key: string]: RequestState },
      /**
       * All regular/urgent notifications, to be shown in lists. Low priority notifications are not listed (but show up in the socket stream)
       *
       * Update Notifications are excluded from this list
       */
      _notifications: [] as Notification[],
      _fileExportNotifications: [] as Notification[],
      _unreadCount: 0 as number,
      _updateUnreadCount: 0 as number,
      _fileExportUnreadCount: 0 as number,
      _allNotificationCount: 0 as number,
      _allFileExportCount: 0 as number,
      _awaitingDocuments: [] as DocumentExport[],
      // All unread urgent notifications, to be shown as popups
      _urgentUnreads: [] as Notification[],
      // list of manually marked read notifications - used as an ignore list to avoid desyncing after socket event notification
      _manuallyMarkedAsRead: [] as string[],
    };
  },
  getters: {
    updateUnreadCount(state) {
      return state._updateUnreadCount;
    },
    fileExportUnreadCount(state) {
      return state._fileExportUnreadCount;
    },
    /**
     * Subscribable observable of notification events. Subscription to this is not affected by session status
     *  - events will/will not flow depending on the user session state, but the Observable is kept open regardless
     */
    notificationEvents() {
      return sd.data.notificationEvents.asObservable();
    },
    notifications(state) {
      return state._notifications;
    },
    fileExportNotifications(state) {
      return state._fileExportNotifications;
    },
    urgentUnreads(state) {
      return state._urgentUnreads;
    },
    allCount(state) {
      return state._allNotificationCount;
    },
    fileCount(state) {
      return state._allFileExportCount;
    },
    unreadCount(state) {
      return state._unreadCount;
    },
    loading(state) {
      return state._requestStates.loadNotifications === 'pending';
    },
  },
  actions: {
    async [commonStoreActions.onSetup]() {
      sd = makeStoreSupportData({
        notificationWatcher: null,
        notificationEvents: new Subject<NotificationEvent>(),
      });
      this._requestStates = sd.reqManager.requestStates;

      window.addEventListener('online', () => {
        this.setupNotificationWatcher({ reset: true });
      });

      window.addEventListener('offline', () => {
        this.unwatchNotifications();
      });

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          this.watchNotifications();
        }
      });

      sd.tabSync.onIsLeader(() => {
        if (useAuthStore().isLoggedIn) {
          this.watchNotifications();
        }
      });

      sd.tabSync.onActionTrigger(NOTIFICATION_ACTION_TRIGGER, (message: SyncMessage<NotificationEvent>) => {
        sd.data.notificationEvents.next(message.payload);
        if (isActionRequestMessage(message) && message.payload.data) {
          this.triggerFileExportNotification(message.payload.data.payload);
        }
      });

      sd.tabSync.onActionTrigger(HIDE_TOAST, (message: SyncMessage<NotificationEvent>) => {
        if (isActionRequestMessage(message) && message.payload.data?.id) {
          Vue.toast.remove(message.payload.data.id);
        }
      });

      sd.tabSync.onActionTrigger(WATCH_NOTIFICATIONS, (message: SyncMessage<{ reset: boolean }>) => {
        if (isActionRequestMessage(message) && message.payload.data) {
          this.watchNotifications(message.payload.data);
        }
      });
    },
    async [commonStoreActions.afterSetup]() {
      await sd.tabSync.ready;

      this.setupNotificationWatcher();
    },
    async loginReaction() {
      this.clearData();
      this.setupNotificationWatcher({ reset: true });
      this.checkUnreadCount();
    },
    async [commonStoreActions.onLogin]() {
      this.loginReaction();
    },
    async [commonStoreActions.onOutMaintenance]() {
      if (useAuthStore().isLoggedIn) {
        this.loginReaction();
      }
    },
    async [commonStoreActions.onLogout]() {
      this.clearData();
      this.unwatchNotifications();
    },
    clearData() {
      this._notifications = [];
      this._fileExportNotifications = [];
      this._unreadCount = 0;
      this._updateUnreadCount = 0;
      this._fileExportUnreadCount = 0;
      this._allNotificationCount = 0;
      this._allFileExportCount = 0;
      this._awaitingDocuments = [];
      this._urgentUnreads = [];
      sd.reqManager.clear();
    },

    async [commonStoreActions.onLogoutOtherTab]() {
      this.unwatchNotifications();
    },
    async setupNotificationWatcher(payload?: { reset: boolean }) {
      if (useAuthStore().isLoggedIn && !useAuthStore().isInMaintenance) {
        return Promise.all([
          this.checkUnreadCount(),
          this.loadSidebarNotifications({ initial: true, silent: true }),
          this.loadFileNotifications({ initial: true, silent: true }),
        ]).then(() => this.watchNotifications(payload));
      }
    },
    async watchNotifications(payload: { reset: boolean } = { reset: false }) {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      if (!useAuthStore().isLoggedIn || useAuthStore().isInMaintenance) {
        return;
      }
      if (!(await sd.tabSync.isLeaderPromise)) {
        sd.tabSync.awaitLeaderAction(WATCH_NOTIFICATIONS, payload);
        return;
      }
      if (payload.reset) {
        this.unwatchNotifications();
      } else if (sd.data.notificationWatcher?.closed === false) {
        return;
      }
      sd.data.notificationWatcher = sd.s.notification.watchNotifications().subscribe(
        (notifEvent: any) => {
          try {
            sd.data.notificationEvents.next(notifEvent);
            if (notifEvent.payload && notifEvent.eventType === NotificationEventType.NOTIFICATION_CREATED) {
              this.addNewNotification(notifEvent.payload);
              if (notifEvent.payload.alertType !== NotificationAlertType.BACKGROUND) {
                sd.tabSync.triggerAction(NOTIFICATION_ACTION_TRIGGER, notifEvent, true);
              }
            }
            if (notifEvent.eventType === NotificationEventType.NOTIFICATION_ALL_READ) {
              this._notifications.forEach((n) => (n.read = true));
              this.clearReadCount({ types: notifEvent.payload.type });
            }
            if (notifEvent.payload && notifEvent.eventType === NotificationEventType.NOTIFICATION_READ) {
              this.markNotificationAsReadFromEvent(notifEvent.payload);
            }
            if (notifEvent.payload && notifEvent.eventType === NotificationEventType.NOTIFICATION_DELETED) {
              this.removeNotificationFromEvent(notifEvent.payload);
            }
          } catch (e) {
            console.error(e);
          }
        },
        () => {
          // If watcher fails, retry after 1s, OR at least 15s from the last start of reconnection attempt
          reconnectTimeout = window.setTimeout(() => {
            if (useAuthStore().isLoggedIn) {
              lastReconnectionTime = Date.now();
              this.watchNotifications();
            }
          }, Math.max(1000, lastReconnectionTime + 15000 - Date.now()));
        }
      );
    },
    async unwatchNotifications() {
      sd.data.notificationWatcher?.unsubscribe();
      sd.s.notification.disconnectFromNotifications();
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    },
    /**
     * Sets a new notification in the notification arrays, updating unread counts if applicable
     */
    async addNewNotification(notification: Notification) {
      // Add all relevant notifications to either notification array
      if ([NotificationAlertType.REGULAR, NotificationAlertType.URGENT].includes(notification.alertType)) {
        const arrayKey = getNotifArrayKey(notification.type);
        if (!arrayKey) {
          return;
        }
        const notifIndex = this[arrayKey].findIndex((i) => i && i.id === notification.id);
        if (notifIndex === -1) {
          this[arrayKey].unshift(notification!);
          this._allNotificationCount += 1;

          if (!notification.read) {
            this.changeSingleReadCount({ type: notification.type, add: true });
          }
        } else {
          this[arrayKey].splice(notifIndex, 1, notification);
        }
      }
      // Add all URGENT notifications to the _urgentUnreads array
      if (notification.alertType === NotificationAlertType.URGENT && notification.read === false) {
        const notifIndex = this._urgentUnreads.findIndex((i) => i && i.id === notification.id);
        if (notifIndex === -1) {
          this._urgentUnreads.unshift(notification!);
        } else {
          this._urgentUnreads.splice(notifIndex, 1, notification);
        }
      }
    },

    /**
     * Change unread count for a single notification, by type
     *
     * Used to react to socket events
     */
    changeSingleReadCount(payload: { type: NotificationType; add: boolean }) {
      if (publicNotifications.includes(payload.type)) {
        this._unreadCount = Math.max(this._unreadCount + (payload.add ? 1 : -1), 0);
      }
      if (fileExportNotifications.includes(payload.type)) {
        this._fileExportUnreadCount = Math.max(this._fileExportUnreadCount + (payload.add ? 1 : -1), 0);
      }
      if (updateNotifications.includes(payload.type)) {
        this._updateUnreadCount = Math.max(this._updateUnreadCount + (payload.add ? 1 : -1), 0);
      }
    },

    /**
     * Alter read count for a type
     *
     * Used to react to socket events
     */
    clearReadCount(payload: { types: NotificationType[] }) {
      if (payload.types.length === 0 || isEqual(payload.types, publicNotifications)) {
        this._unreadCount = 0;
      }
      if (payload.types.length === 0 || isEqual(payload.types, fileExportNotifications)) {
        this._fileExportUnreadCount = 0;
      }
      if (payload.types.length === 0 || isEqual(payload.types, updateNotifications)) {
        this._updateUnreadCount = 0;
      }
    },

    /**
     * Socket event reaction to mark a notification as read, removing it from _urgentUnreads if set, and updating unread count
     */
    async markNotificationAsReadFromEvent(notifInfo: NotificationInfo) {
      const notification = this.getNotification(notifInfo);

      // If the notification does not exist/is unread in the Store AND has not been marked as read manually,
      // we update the unread count optimistically
      if (!this._manuallyMarkedAsRead.includes(notifInfo.id)) {
        if (!notification?.read) {
          this.changeSingleReadCount({ type: notifInfo.type, add: false });
        }
      } else {
        this._manuallyMarkedAsRead = this._manuallyMarkedAsRead.filter((i) => i !== notifInfo.id);
      }

      if (notification) {
        notification.read = true;
      }

      const urgentUnreadIndex = this._urgentUnreads.findIndex((i) => i && i.id === notifInfo.id);
      if (urgentUnreadIndex > -1) {
        this._urgentUnreads.splice(urgentUnreadIndex, 1);
      }
    },

    /**
     * Remove a notification, removing it from _urgentUnreads if set, and updating unread count
     */
    async removeNotificationFromEvent(notifInfo: NotificationInfo) {
      const notifArray = publicNotifications.includes(notifInfo.type)
        ? this._notifications
        : this._fileExportNotifications;
      const notifIndex = notifArray.findIndex((i) => i && i.id === notifInfo.id);

      if (notifIndex > -1) {
        const wasUnread = !notifArray[notifIndex].read;
        notifArray.splice(notifIndex, 1);

        if (wasUnread) {
          this.changeSingleReadCount({ type: notifInfo.type, add: false });
        }
      } else {
        this.changeSingleReadCount({ type: notifInfo.type, add: false });
      }

      const urgentUnreadIndex = this._urgentUnreads.findIndex((i) => i && i.id === notifInfo.id);
      if (urgentUnreadIndex > -1) {
        this._urgentUnreads.splice(urgentUnreadIndex, 1);
      }
    },
    loadNotifications(options: {
      listKey: '_notifications' | '_fileExportNotifications' | '_urgentUnreads';
      alertTypes: NotificationAlertType[];
      types?: NotificationType[];
      initial?: boolean;
      silent?: boolean;
    }) {
      const query: PaginatedQuery = {
        sort: 'createdAt',
        sortDirection: 'DESC',
        pageSize: 10,
        alertType: options.alertTypes,
        type: options.types,
      };
      const initialLoad = options.initial || this[options.listKey].length <= 0;

      if (!initialLoad) {
        query.pageNumber = Math.floor(this[options.listKey].length / query.pageSize!);
      }

      return sd.reqManager
        .sameOrCancelAndNew(
          'loadNotifications' + options.listKey,
          sd.s.notification.listNotifications(query, { errors: { silent: options.silent } }),
          query
        )
        .pipe(
          tap((r) => {
            if (initialLoad) {
              this[options.listKey] = r.list;
            } else {
              r.list.forEach((notification) => {
                const notifIndex = this[options.listKey].findIndex((i) => i.id === notification.id);
                if (notifIndex > -1) {
                  this[options.listKey].splice(notifIndex, 1, notification);
                } else {
                  this[options.listKey].push(notification);
                }
              });
            }
          })
        )
        .toPromise();
    },
    loadSidebarNotifications(options: { initial?: boolean; silent?: boolean } = {}) {
      const initialLoad = options.initial || this._notifications.length >= 0;
      return this.loadNotifications({
        ...options,
        alertTypes: [NotificationAlertType.REGULAR, NotificationAlertType.URGENT],
        types: publicNotifications,
        listKey: '_notifications',
      }).then((r) => {
        if (initialLoad) {
          this._allNotificationCount = r.total;
        }
      });
    },
    loadFileNotifications(options: { initial?: boolean; silent?: boolean } = {}) {
      const initialLoad = options.initial || this._fileExportNotifications.length >= 0;
      return this.loadNotifications({
        ...options,
        types: fileExportNotifications,
        alertTypes: [NotificationAlertType.REGULAR, NotificationAlertType.URGENT],
        listKey: '_fileExportNotifications',
      }).then((r) => {
        if (initialLoad) {
          this._allFileExportCount = r.total;
        }
      });
    },

    loadUrgentUnreadNotifications(options: { initial?: boolean; silent?: boolean } = {}) {
      return this.loadNotifications({
        ...options,
        alertTypes: [NotificationAlertType.URGENT],
        listKey: '_urgentUnreads',
      });
    },
    checkUnreadCount() {
      return sd.reqManager
        .currentOrNew(
          'checkUnreadCount',
          forkJoin([
            sd.s.notification
              .listNotifications(
                {
                  read: false,
                  type: publicNotifications,
                  pageSize: 1 /* TODO: API doesn't accept 0, remove after fix */,
                },
                { errors: { silent: true } }
              )
              .pipe(catchError(() => of({ total: 0 }))),
            sd.s.notification
              .listNotifications(
                {
                  read: false,
                  type: updateNotifications,
                  pageSize: 1 /* TODO: API doesn't accept 0, remove after fix */,
                },
                { errors: { silent: true } }
              )
              .pipe(catchError(() => of({ total: 0 }))),
            sd.s.notification
              .listNotifications(
                {
                  read: false,
                  type: fileExportNotifications,
                  pageSize: 1 /* TODO: API doesn't accept 0, remove after fix */,
                },
                { errors: { silent: true } }
              )
              .pipe(catchError(() => of({ total: 0 }))),
          ])
        )
        .toPromise()
        .then((r) => {
          this._unreadCount = r[0].total;
          this._updateUnreadCount = r[1].total;
          this._fileExportUnreadCount = r[2].total;
        });
    },
    getNotification(notifInfo: NotificationInfo) {
      const notifArray = publicNotifications.includes(notifInfo.type)
        ? this._notifications
        : this._fileExportNotifications;
      return notifArray.find((i) => i && i.id === notifInfo.id);
    },
    async markAsRead(notifInfo: NotificationInfo) {
      const notification = this.getNotification(notifInfo);
      if (notification) {
        notification.read = true;
      }
      this.changeSingleReadCount({ type: notifInfo.type, add: false });
      this._manuallyMarkedAsRead.push(notifInfo.id);

      try {
        await sd.s.notification.markAsRead(notifInfo.id).toPromise();
      } catch (e) {
        this._manuallyMarkedAsRead = this._manuallyMarkedAsRead.filter((i) => i !== notifInfo.id);
        this.changeSingleReadCount({ type: notifInfo.type, add: true });
        if (notification) {
          notification.read = false;
        }
        sd.data.toast.error('A problem occurred marking the notification as read. Please try again later.');
      }
    },
    async markAllAsRead(type: 'notifications' | 'updates' | 'fileExports' = 'notifications') {
      const notifications = this._notifications;
      const types = {
        updates: updateNotifications,
        notifications: publicNotifications,
        fileExports: fileExportNotifications,
      }[type];
      const unreadCount = type === 'updates' ? this._updateUnreadCount : this._unreadCount;
      let listKey: '_notifications' | '_fileExportNotifications' | '' = '';

      if (type === 'updates') {
        this._updateUnreadCount = 0;
      } else if (type === 'fileExports') {
        this._fileExportUnreadCount = 0;
        listKey = '_fileExportNotifications';
      } else {
        this._unreadCount = 0;
        listKey = '_notifications';
      }

      if (listKey !== '') {
        this[listKey] = this[listKey].map((i) => {
          if (i) {
            return {
              ...i,
              read: true,
            };
          }
          return i;
        });
      }

      try {
        await sd.s.notification.markAllAsRead(types).toPromise();
      } catch (e) {
        if (type === 'updates') {
          this._updateUnreadCount = unreadCount;
        } else {
          this._notifications = notifications;
          this._unreadCount = unreadCount;
        }
        sd.data.toast.error('A problem occurred marking notifications as read. Please try again later.');
      }
    },
    async triggerFileExportNotification(
      notification: Pick<Notification, 'resource' | 'title' | 'type' | 'message' | 'resourceOrigin'>
    ) {
      if (!fileExportNotifications.includes(notification.type)) {
        return;
      }

      const id = notification.resource;

      sd.data.toast
        .show(
          '',
          {
            id,
            toastType: notification.type === NotificationType.DOCUMENT_STATUS_FAILED ? 'danger' : 'info',
            title: notification.title,
            message: notification.message,
            actions:
              notification.type === NotificationType.DOCUMENT_STATUS_EXPORTED
                ? [
                    {
                      title: 'Download',
                      class: 'btn-success btn-small',
                      method: () => {
                        return sd.s.documents
                          .downloadSyncDocument(notification.resourceOrigin!, notification.resource!)
                          .toPromise()
                          .then(
                            () => {
                              sd.data.toast.remove(notification.resource!);
                              sd.data.toast.success('File downloaded successfully.');
                            },
                            () => {
                              sd.data.toast.error('File failed to download.');
                            }
                          );
                      },
                    },
                  ]
                : [],
          },
          { noAutoHide: notification.type === NotificationType.DOCUMENT_STATUS_EXPORTED }
        )
        .then(() => {
          sd.tabSync.triggerAction('HIDE_TOAST', { id }, true);
        });
    },
    async triggerFileExportRequestNotification(fileRequest: DocumentExport) {
      return this.triggerFileExportNotification({
        message: 'Document export request sent',
        title: fileRequest.title,
        type: NotificationType.DOCUMENT_STATUS_EXPORTING,
        resource: fileRequest.id,
        resourceOrigin: fileRequest.type,
      });
    },
  },
});
