<script lang="ts" setup>
import { beneficiaryTableFields } from '../models/beneficiaryTableFields';
import { BeneficiaryCategory, BeneficiaryStatus, PaginatedResponse, Beneficiary } from 'ah-api-gateways';

import BeneficiaryListing from './BeneficiaryListing.vue';
import { waitForCQRSEntityChange } from 'ah-requests';
import { mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { computed, reactive, ref } from 'vue';
import { useAhBeneficiariesState } from '..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const requestManager = useRequestManager({
  exposeToParent: false,
});

const beneficiaryListing = ref<InstanceType<typeof BeneficiaryListing> | null>(null);

const state = reactive({
  data: null as PaginatedResponse<Beneficiary> | null,
});

const beneficiarieState = useAhBeneficiariesState();

const partnerName = computed(() => {
  return beneficiarieState.theme.name;
});

const filter = computed(() => {
  return {
    status: [BeneficiaryStatus.PENDING],
    category: BeneficiaryCategory.CLIENT_3RD_PARTY,
  };
});

const size = computed(() => {
  return state.data ? state.data.total : 0;
});

const beneficiaryTableConfig = computed(() => {
  return {
    tableFields: beneficiaryTableFields,
  };
});

function isLoading(beneficiary: Beneficiary, status: BeneficiaryStatus) {
  return requestManager.manager.requestStates[beneficiaryRequestKey(beneficiary, status)] === 'pending';
}

function beneficiaryRequestKey(beneficiary: Beneficiary, status: BeneficiaryStatus) {
  return `change-beneficiary-${beneficiary.id}-${status}-status`;
}

function setBeneficiaryApproval(beneficiary: Beneficiary, status: BeneficiaryStatus) {
  requestManager.manager
    .sameOrCancelAndNew(
      beneficiaryRequestKey(beneficiary, status),
      beneficiarieState.services.beneficiary.setBeneficiaryApproval(beneficiary.id, status).pipe(
        mergeMap((idEntity) =>
          waitForCQRSEntityChange(idEntity, () =>
            beneficiarieState.services.beneficiary.getBeneficiary(idEntity.id, {
              errors: { silent: true },
            })
          )
        )
      )
    )
    .pipe(catchError(() => of(beneficiary)))
    .subscribe(() => {
      const entryIndex = state.data?.list.findIndex((e) => e.id === beneficiary.id) ?? -1;
      if (entryIndex > -1) {
        state.data!.list.splice(entryIndex, 1);
      }
    });
}
</script>

<template>
  <BoxGrid alignH="start" v-show="size > 0">
    <BoxGridBlock cols="12" md="12">
      <div class="d-inline-flex">
        <IconAlertCircle class="status-icon-warning mr-2" />
        <h3 class="mb-0">Beneficiary approval required by you</h3>
      </div>
      <div class="secondary-label mb-4">
        <p>
          {{ size === 1 ? 'A new beneficiary has been added by' : 'New beneficiaries were added by' }}
          {{ partnerName }} on your behalf and {{ size === 1 ? 'needs' : 'they need' }} your approval.
        </p>
      </div>
      <VRow cols="12">
        <VCol lg="8" md="7" sm="8">
          <BeneficiaryListing
            ref="beneficiaryListing"
            withRowSubView
            :filter="filter"
            :config="beneficiaryTableConfig"
            :tableData.sync="state.data"
            v-on="$listeners"
            v-bind="$attrs"
          />
        </VCol>
        <VCol lg="4" md="5" sm="4" v-if="state.data && state.data.list.length">
          <div class="buttons-holder ml-md-5 ml-sm-2">
            <div v-for="beneficiary in state.data.list" :key="beneficiary.id" class="button-row">
              <VButton
                :loading="isLoading(beneficiary, BeneficiaryStatus.REJECTED)"
                :disabled="isLoading(beneficiary, BeneficiaryStatus.APPROVED)"
                @click="setBeneficiaryApproval(beneficiary, BeneficiaryStatus.REJECTED)"
              >
                Reject
              </VButton>
              <VButton
                :loading="isLoading(beneficiary, BeneficiaryStatus.APPROVED)"
                :disabled="isLoading(beneficiary, BeneficiaryStatus.REJECTED)"
                @click="setBeneficiaryApproval(beneficiary, BeneficiaryStatus.APPROVED)"
              >
                Approve
              </VButton>
            </div>
          </div>
        </VCol>
      </VRow>
    </BoxGridBlock>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.status-icon-warning {
  width: 25px;
  height: 25px;
  @include themedTextColor($color-yellow-highlight);
}

.secondary-label {
  @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
}

.buttons-holder {
  margin-top: 48px;

  .button-row {
    display: inline-flex;
    .btn {
      min-width: 100px;
      @include desktop {
        min-width: 120px;
      }
      margin-bottom: 7.5px;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

@include upToResolution($tabletResolution) {
  .buttons-holder {
    margin-top: 0px;
    .button-row {
      padding: 29px 0px;
      display: block;
      .btn {
        display: block;
        min-width: 94px;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
