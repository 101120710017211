import { render, staticRenderFns } from "./UbosSettingsView.vue?vue&type=template&id=d361d11a&scoped=true"
import script from "./UbosSettingsView.vue?vue&type=script&setup=true&lang=ts"
export * from "./UbosSettingsView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UbosSettingsView.vue?vue&type=style&index=0&id=d361d11a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d361d11a",
  null
  
)

export default component.exports