import { tryOnBeforeUnmount } from '@vueuse/core';

function loadScript(src: string) {
  return new Promise<void>(function (resolve, reject) {
    if (document.querySelector('script[src="' + src + '"]')) {
      resolve();
      return;
    }

    const el = document.createElement('script');

    el.type = 'text/javascript';
    el.async = true;
    el.src = src;

    el.addEventListener('load', () => resolve());
    el.addEventListener('error', () => reject());
    el.addEventListener('abort', () => reject());

    document.head.appendChild(el);
  });
}

const PLAID_URL = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

export interface PlaidError {
  error_type: string;
  error_code: string;
  error_message: string;
  display_message: string;
}

export interface PlaidOptions {
  token: string;
  onSuccess?: (publicToken: string) => void;
  onExit?: (err?: PlaidError) => void;
}

export function preloadPlaid() {
  loadScript(PLAID_URL);
}

export function usePlaidLink(options: PlaidOptions) {
  let handler: any;

  const loader = loadScript(PLAID_URL).then(() => {
    handler = (window as any).Plaid.create({
      token: options.token,
      onSuccess: options.onSuccess,
      onExit: options.onExit,
    });

    tryOnBeforeUnmount(() => {
      handler.exit();
      handler.destroy();
    });
  });

  return {
    open: () => loader.then(() => handler.open()),
    exit: () => loader.then(() => handler.exit()),
    destroy: () => loader.then(() => handler.destroy()),
  };
}
