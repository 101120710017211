import { IndividualGroup, IndividualReference, getIndividualName } from '.';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

export interface EmailTargetSelection {
  email: string;
}

export interface PhoneTargetSelection {
  phoneNumber: string;
}

export interface IndividualTargetSelection {
  individual: IndividualReference;
}

export interface GroupTargetSelection {
  group: IndividualGroup;
}

export type TargetSelection =
  | GroupTargetSelection
  | IndividualTargetSelection
  | PhoneTargetSelection
  | EmailTargetSelection;

export function matchesRecipient(a: TargetSelection, b: TargetSelection) {
  return (
    ((a as any).group && (a as GroupTargetSelection).group.id === (b as GroupTargetSelection).group?.id) ||
    ((a as any).individual &&
      (a as IndividualTargetSelection).individual.id === (b as IndividualTargetSelection).individual?.id) ||
    ((a as any).email && (a as EmailTargetSelection).email === (b as EmailTargetSelection).email) ||
    ((a as any).phoneNumber && (a as PhoneTargetSelection).phoneNumber === (b as PhoneTargetSelection).phoneNumber)
  );
}

export function targetSelectionName(recipient: TargetSelection) {
  if ((recipient as PhoneTargetSelection).phoneNumber) {
    const parsed = parsePhoneNumberFromString((recipient as PhoneTargetSelection).phoneNumber);
    if (parsed && parsed.isValid()) {
      return parsed.format('INTERNATIONAL');
    }
  }
  if ((recipient as EmailTargetSelection).email) {
    return (recipient as EmailTargetSelection).email;
  }
  if ((recipient as GroupTargetSelection).group) {
    return (recipient as GroupTargetSelection).group.name;
  }
  if ((recipient as IndividualTargetSelection).individual) {
    return getIndividualName((recipient as IndividualTargetSelection).individual);
  }
  return '';
}

export function targetSelectionId(recipient: TargetSelection) {
  if ((recipient as PhoneTargetSelection).phoneNumber) {
    return (recipient as PhoneTargetSelection).phoneNumber;
  }
  if ((recipient as EmailTargetSelection).email) {
    return (recipient as EmailTargetSelection).email;
  }
  if ((recipient as GroupTargetSelection).group) {
    return (recipient as GroupTargetSelection).group.id;
  }
  if ((recipient as IndividualTargetSelection).individual) {
    return (recipient as IndividualTargetSelection).individual.id;
  }
  return '';
}
