import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const tradeReportTableFieldsFull: TableFieldDefinition[] = [
  {
    header: 'Trade date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'hedgingProduct',
    sortable: true,
  },
  {
    header: 'Trade ID',
    key: 'referenceNumber',
    sortable: true,
  },
  {
    header: 'Booked Rate',
    key: 'fxRateWithCCY',
    sortable: true,
    sortKey: 'primaryRate',
  },
  {
    header: 'Notional Values',
    key: 'notionalValues',
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
    sortable: true,
  },
  {
    header: 'State',
    key: 'status',
    sortable: true,
  },
];

export const partnerTradeReportTableFieldsFull: TableFieldDefinition[] = [
  {
    header: 'Trade date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Client name',
    key: 'clientName',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'hedgingProduct',
    sortable: true,
  },
  {
    header: 'Trade ID',
    key: 'referenceNumber',
    sortable: true,
  },
  {
    header: 'Booked Rate',
    key: 'fxRateWithCCY',
    sortable: true,
    sortKey: 'primaryRate',
  },
  {
    header: 'Notional Values',
    key: 'notionalValues',
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
    sortable: true,
  },
  {
    header: 'State',
    key: 'status',
    sortable: true,
  },
];

export const tradeOptionsReportTableFields: TableFieldDefinition[] = [
  {
    header: 'Trade Date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Trade ID',
    key: 'referenceNumber',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'hedgingProduct',
    sortable: true,
  },
  {
    header: 'Currency Pair',
    key: 'tradeDirection',
    sortable: true,
  },
  {
    header: 'Claim Type',
    key: 'claimType',
    sortable: true,
  },

  {
    header: 'Notional Currency',
    key: 'notionalCurrency',
    sortable: true,
  },
  {
    header: 'Notional Amount',
    key: 'notionalAmount',
    sortable: true,
  },
  {
    header: 'Expiry date',
    key: 'expiryDate',
    sortable: true,
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
    sortable: true,
  },
  {
    header: 'Strike rate',
    key: 'strikeRate',
    sortable: true,
  },
  {
    header: 'State',
    key: 'status',
    sortable: true,
  },
];

export const partnerTradeOptionsReportTableFields: TableFieldDefinition[] = [
  {
    header: 'Trade Date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Customer Name',
    key: 'clientName',
    sortable: true,
  },
  {
    header: 'Trade ID',
    key: 'referenceNumber',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'hedgingProduct',
    sortable: true,
  },
  {
    header: 'Currency Pair',
    key: 'tradeDirection',
    sortable: true,
  },
  {
    header: 'Claim Type',
    key: 'claimType',
    sortable: true,
  },

  {
    header: 'Notional Currency',
    key: 'notionalCurrency',
    sortable: true,
  },
  {
    header: 'Notional Amount',
    key: 'notionalAmount',
    sortable: true,
  },
  {
    header: 'Expiry date',
    key: 'expiryDate',
    sortable: true,
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
    sortable: true,
  },
  {
    header: 'Strike rate',
    key: 'strikeRate',
    sortable: true,
  },
  {
    header: 'State',
    key: 'status',
    sortable: true,
  },
];

export const shortOpenPositionsTradeTableFields: TableFieldDefinition[] = [
  {
    header: 'Trade date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Booked Rate',
    key: 'fxRate',
    sortable: true,
  },
  {
    header: 'Notional Values',
    key: 'notionalValues',
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
    sortable: true,
  },
];

export const openPositionsDashboardTradeTableFields: TableFieldDefinition[] = [
  {
    header: 'Trade date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Instrument',
    key: 'hedgingProduct',
    sortable: true,
  },
  {
    header: 'Trade ID',
    key: 'referenceNumber',
    sortable: true,
  },
  {
    header: 'Booked Rate',
    key: 'fxRate',
    sortable: true,
    sortKey: 'primaryRate',
  },
  {
    header: 'Currency Pair',
    key: 'currencyPair',
    sortable: true,
  },
  {
    header: 'Notional Values',
    key: 'notionalValues',
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
  },
];

export const tradeAndComissionReportTableFields = (homeCurrency = 'GBP'): TableFieldDefinition[] => {
  return [
    {
      header: 'Trade ID',
      key: 'composedReferenceNumber',
      tdClass: 'text-nowrap',
      sortable: true,
    },
    {
      header: 'Client',
      key: 'clientName',
      sortable: true,
    },
    {
      header: 'Trade Date',
      key: 'createdAt',
      sortable: true,
    },
    {
      header: 'Purpose Type',
      key: 'purposeType',
      sortable: true,
    },
    {
      header: 'Instrument Type',
      key: 'hedgingProduct',
      sortable: true,
    },
    {
      header: 'Currency Pair',
      key: 'currencyPair',
      sortable: true,
    },
    {
      header: 'Buy/Sell',
      key: 'currencyPairDirection',
      sortable: true,
    },
    {
      header: 'Booked FX Rate',
      key: 'clientRate',
      sortable: true,
      sortKey: 'clientRate',
    },
    {
      header: 'Trade Status',
      key: 'status',
      sortable: true,
    },
    {
      header: `Commissions ${homeCurrency} Equiv.`,
      key: 'gbpPartnerCommissions',
      sortable: true,
      info: true,
      labelInfo: 'tooltips.commissionsEquiv',
    },
    {
      header: `IMCC ${homeCurrency} Equiv.`,
      key: 'partnerInitialMarginCreditChargeAmount',
      sortable: true,
      info: true,
      labelInfo: 'tooltips.imccEquiv',
    },
    {
      header: 'Net Commission',
      key: 'gbpPartnerNetCommission',
      sortable: true,
      info: true,
      labelInfo: 'tooltips.netCommission',
    },
    {
      header: 'Mark Up (bp)',
      key: 'totalMarkupBasisPoints',
      sortable: true,
      info: true,
      labelInfo: 'tooltips.markUpBp',
    },
    {
      header: 'Apportioned Comm. Date',
      key: 'commissionsDate',
      sortable: true,
      info: true,
      labelInfo: 'tooltips.apportionedCommDate',
    },
  ];
};

export const oboTransactionTradeList: TableFieldDefinition[] = [
  {
    header: 'Trade date',
    key: 'createdAt',
  },
  {
    header: 'Type',
    key: 'hedgingProduct',
  },
  {
    header: 'Buy/Sell',
    key: 'amountType',
  },
  {
    header: 'CCY Pair',
    key: 'ccyPair',
  },
  {
    header: 'Notional Values',
    key: 'notionalValues',
  },
  {
    header: 'Booked Rate',
    key: 'fxRate',
  },
  {
    header: 'Settlement date',
    key: 'settlementDate',
  },
  {
    header: 'Window date',
    key: 'windowStartDate',
  },
  {
    header: 'Markup %',
    key: 'partnerMarkupPercentage',
  },
  {
    header: 'Status',
    key: 'status',
  },
];
