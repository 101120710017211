<template>
  <div class="padded-space">
    <h2 class="mb-0">Documents</h2>
    <BackButton label="go back" class="ml-n2 mb-1" @click="goBack" small />
    <BoxGrid alignH="start" class="document-manager-view" id="activity-client-documents-obo-view">
      <LoadingOverlay :state="requestManager.manager.anyPending" noWrap show-retry @retry="loadData" />
      <template v-if="!requestManager.manager.anyPending && state.client">
        <BoxGridItem :cols="outerCol">
          <BoxGrid alignH="start">
            <template v-if="!isIndividual">
              <BoxGridBlock :cols="innerCol">
                <h3>Company Documents</h3>
                <ClientDocumentReplacer :entity="state.client" :hide-files="hiddenFiles" />
              </BoxGridBlock>

              <BoxGridBlock :cols="innerCol">
                <h3>Authorised Signatory</h3>
                <ClientDocumentReplacer
                  :entity="state.client"
                  force-individual
                  :hide-files="[ClientFileCategories.PROOF_OF_FUNDS]"
                />
              </BoxGridBlock>
            </template>
            <template v-else>
              <BoxGridBlock :cols="6">
                <h3>Personal Documents</h3>
                <ClientDocumentReplacer :entity="state.client" />
              </BoxGridBlock>
            </template>
          </BoxGrid>
        </BoxGridItem>

        <BoxGridBlock v-if="hasUbos">
          <h3>UBOs</h3>
          <BoxGridItem v-for="ubo in state.ubos" :key="ubo.id">
            <h3>{{ ubo.firstName }} {{ ubo.lastName }}</h3>
            <ClientDocumentReplacer
              :entity="ubo"
              force-individual
              :hide-files="[ClientFileCategories.PROOF_OF_FUNDS]"
              :getDocumentListRequest="getUboDocDownloadRequest"
              :uploadDocumentsRequest="uploadUboDocumentRequest"
              :hideFooter="!isLastUbo(ubo)"
              :class="{ 'mb-5': !isLastUbo(ubo) }"
            />
          </BoxGridItem>
        </BoxGridBlock>
      </template>
    </BoxGrid>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import { BoxGrid, BoxGridBlock, BoxGridItem } from '@/app/components/common/boxGrid';
import { getServices } from '@/app/services';
import { Client, ClientFileCategories, ClientType, Individual } from 'ah-api-gateways';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { forkJoin } from 'rxjs';
import ClientDocumentReplacer from '@/app/components/settings/partner/ClientDocumentReplacer.vue';
import BackButton from '@/app/components/common/BackButton.vue';
import { useRouter } from 'vue-router/composables';

const props = defineProps<{
  /**
   * Client id of client to be displayed
   */
  clientId: string;
}>();

const state = reactive<{
  client: Client | null;
  ubos: Individual[] | null;
}>({
  client: null,
  ubos: null,
});

const requestManager = useRequestManager();

const router = useRouter();

const services = getServices();

const hasUbos = computed(() => state.ubos && state.ubos.length > 0);

const isIndividual = computed(() => state.client?.type === ClientType.INDIVIDUAL);

const innerCol = computed(() => {
  return hasUbos.value ? 12 : 6;
});

const outerCol = computed(() => {
  return hasUbos.value ? 6 : 12;
});

const hiddenFiles = computed(() => {
  return hasUbos.value ? [ClientFileCategories.RESOLUTION_LETTER] : [];
});

function getUboDocDownloadRequest(uboId: string) {
  return services.compliance.getUboDocuments(state.client!.id, uboId);
}

function uploadUboDocumentRequest(id: string, category: ClientFileCategories, file: File) {
  return services.compliance.submitUboDocument(state.client!.id, id, category, file);
}

function goBack() {
  router.push('.');
}

function loadData() {
  requestManager.manager
    .sameOrCancelAndNew(
      'loadData',
      forkJoin([services.client.getClient(props.clientId), services.compliance.listUbos(props.clientId)])
    )
    .subscribe(([client, ubos]) => {
      state.client = client;
      state.ubos = ubos.list as Individual[];
    });
}

function isLastUbo(ubo: Individual) {
  if (!state.ubos) return false;
  return state.ubos.findIndex((u) => u.id === ubo.id) === state.ubos.length - 1;
}

loadData();
</script>

<style lang="scss" scoped>
.document-manager-view {
  @include hd {
    width: 80%;
    min-width: 60rem;
  }
}
</style>
