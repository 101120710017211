<script lang="ts" setup>
import { ClientType } from 'ah-api-gateways';
import NotificationSettingsTable from '@/app/components/settings/notifications/NotificationSettingsTable.vue';
import NotificationGroupSettingEditForm from './NotificationGroupSettingEditForm.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationSettingsStore } from '@/app/store/notificationSettingsModule';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed } from 'vue';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'loadNotifications' || k === 'loadNotificationsConfig') {
      loadNotifications();
    }
  },
}).manager;

const authStore = useAuthStore();

const notificationSettingsStore = useNotificationSettingsStore();

const notificationSettingConfigs = computed(
  () => notificationSettingsStore.notificationConfig?.filter((config) => config.editable) ?? []
);

const client = computed(() => authStore.loggedInIdentity?.client ?? null);

const isIndividualClient = computed(() => authStore.isClientUser && client.value!.type === ClientType.INDIVIDUAL);

const notifications = computed(() => notificationSettingsStore.notificationSettings);

const loadingNotifications = computed(
  () =>
    Object.keys(notifications.value.notifications).length === 0 &&
    requestManager.requestStates.loadNotifications === 'pending'
);

function loadNotifications() {
  requestManager.currentOrNewPromise('loadNotificationsConfig', () =>
    notificationSettingsStore.loadNotificationConfig(true)
  );
  requestManager.currentOrNewPromise('loadNotifications', () =>
    notificationSettingsStore.loadNotificationSettings(true)
  );
}

loadNotifications();
</script>

<template>
  <div>
    <h2>Notifications Preferences</h2>
    <p class="text-secondary mt-n4">
      Setup which events you'd like {{ isIndividualClient ? '' : 'your team' }} to be notified about.
    </p>
    <BoxGrid>
      <BoxGridBlock>
        <NotificationSettingsTable
          :loading="loadingNotifications"
          v-slot="{ isStackedView }"
          :hideTargets="isIndividualClient"
        >
          <NotificationGroupSettingEditForm :settings="notificationSettingConfigs" :stacked="isStackedView" />
        </NotificationSettingsTable>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>
