import { CallStatus } from './callStatus';

export enum CallIndividualType {
  PARTNER = 'PARTNER',
  CLIENT = 'CLIENT',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}
export interface Call {
  id: string;
  startedTime?: string;
  recordingAvailable?: boolean;
  toGroupId?: string;
  callees: CallConnection[];
  toRedirectId?: string;
  answeredTime?: string;
  isIncomingCall: boolean;
  conference: boolean;
  fromRedirectId?: string;
  duration?: number;
  caller: CallConnection;
  endedTime?: string;
  status: CallStatus;
}

export interface CallTargetData {
  individualId?: string;
  accountId?: string;
  number?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface CallConnection extends CallTargetData {
  clientId?: string;
  individualId?: string;
  individualType?: CallIndividualType;
  id: string;
  accountId?: string;
  partnerId?: string;
  status: CallStatus;
}

export interface CallListFilters {
  partnerId?: string;
  clientId?: string;
  individualId?: string;
  accountId?: string;
  callIds?: string[];
  status?: CallStatus[];
  query?: string;

  // FIXME FE assumes API has these filters
  fromStartDate?: string;
  toStartDate?: string;
  fromEndDate?: string;
  toEndDate?: string;
  individualType: CallIndividualType[];
  toIndividualType: CallIndividualType[];
  fromIndividualType: CallIndividualType[];
}

export interface CallUpdateEvent {
  id: string;
  callStatus?: CallStatus;
  callConnection?: CallConnection;
  timestamp: string;
}

export function callHasConnection(call: Call, connection: Partial<CallConnection>) {
  return !![call.caller, ...call.callees].find((conn) => connectionMatcher(connection, conn));
}

export function connectionMatcher(connection: Partial<CallConnection>, compare: CallConnection) {
  return ['id', 'accountId', 'number', 'individualId'].find(
    (k) => (connection as any)[k] && (connection as any)[k] === (compare as any)[k]
  );
}
