<script setup lang="ts">
import { computed, PropType } from 'vue';
import { Authority, Permission, IndividualType, OnboardingIndividualInfo, Individual } from 'ah-api-gateways';
import PermissionsForm from '@/app/components/settings/PermissionsForm.vue';
import AccordionBox from '@/app/components/common/AccordionBox.vue';

const props = defineProps({
  individual: { type: Object as PropType<Individual | Partial<OnboardingIndividualInfo>>, required: true },
  authorities: { type: Array as PropType<Authority[]>, required: false, default: () => [] },
});

const emit = defineEmits<{
  (e: 'update:individual', value: Individual | Partial<OnboardingIndividualInfo>): void;
}>();

const isAdmin = computed(() => props.individual.type === IndividualType.CLIENT_ADMIN);

function setAsAdmin(admin: boolean) {
  const type = admin ? IndividualType.CLIENT_ADMIN : IndividualType.CLIENT_INDIVIDUAL;
  emit('update:individual', { ...props.individual, proposedPermissions: [], type });
}

function setPermissions(proposedPermissions: Permission[]) {
  emit('update:individual', { ...props.individual, proposedPermissions });
}
</script>

<template>
  <AccordionBox :toggleable="false" open>
    <template #title="{ toggleOpen }">
      <h4 @click="toggleOpen()">Requested Permissions</h4>
      <div class="admin-checkbox">
        <BFormCheckbox :checked="isAdmin" :name="`check-button-user-${individual.id}`" @input="setAsAdmin" />
        <div>
          <h4>Superuser (Admin)</h4>
          <div id="superuser-description" class="text-secondary text-small">All permissions bellow</div>
        </div>
      </div>
      <hr />
    </template>

    <div :class="{ 'admin-user': isAdmin }">
      <PermissionsForm
        hideSwitch
        hideGroupTitle
        :readonly="isAdmin"
        :authorities="authorities"
        :value="individual.proposedPermissions || []"
        @update:value="setPermissions"
      />
    </div>
  </AccordionBox>
</template>

<style lang="scss" scoped>
::v-deep {
  h4 {
    font-weight: $font-weight-semibold;
  }
  .text-secondary {
    font-size: $font-size-sm;
  }
  .admin-checkbox {
    display: inline-flex;
    margin-top: 1rem;
  }

  hr {
    margin-top: 1rem;
  }
}
</style>
