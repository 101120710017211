<script setup lang="ts">
import TradingLimits from './TradingLimits.vue';
import PostedCollateral from './PostedCollateral.vue';
import ManageCollateral from './manageCollateral/ManageCollateral.vue';
import { computed, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { ClientRiskProfileReport } from 'ah-api-gateways';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

// FIXME TileCard used in this component is expected to be setup globally
// Should become an explicit import once BoxGrid family of components is moved to ah-common-lib

const props = defineProps({
  /**
   * Client ID to display limits and collateral for
   *
   * This component assumes that if there is a clientId assigned, it should display limits and collateral in "read-only" mode
   * Otherwise, owner context will be used (obo client id or session client id)
   */
  clientId: {
    type: String,
  },
});

const onBehalfOfClient = useOnBehalfOf();

const requestManager = useRequestManager().manager;

const tradeState = useAhTradesState();

const ownerId = computed(
  () => props.clientId ?? onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
);

const clientRiskProfileReportData = computed<ClientRiskProfileReport | null>(() =>
  ownerId.value ? tradeState.store.useWalletsStore().getClientRiskProfile(ownerId.value) : null
);

function loadClientRiskProfileReport() {
  if (!ownerId?.value) {
    throw 'Cannot load client risk profile report with incomplete data';
  }

  // Load happens with force: true as this component sits at the root of a view
  return requestManager.newPromise(
    'loadClientRiskProfileReport',
    tradeState.store.useWalletsStore().loadClientRiskProfile({ force: true, clientId: ownerId.value })
  );
}

watch(ownerId, () => ownerId.value && loadClientRiskProfileReport(), { immediate: true });
</script>

<template>
  <div class="limits-and-collateral-management">
    <LoadingOverlay
      :state="requestManager.requestStates.loadClientRiskProfileReport"
      show-retry
      @retry="loadClientRiskProfileReport"
    />
    <template
      v-if="clientRiskProfileReportData && requestManager.requestStates.loadClientRiskProfileReport !== 'error'"
    >
      <TileCard tileTitle="Trading Limits">
        <TradingLimits
          :limitsProfileData="clientRiskProfileReportData.limitsProfile"
          :isActiveMarginCall="clientRiskProfileReportData.collateralProfile.activeMarginCall"
          :clientId="clientId"
          :variationMarginPercentage="clientRiskProfileReportData.collateralProfile.variationMarginPercentage"
        />
      </TileCard>
      <TileCard tileTitle="Your Posted Collateral">
        <PostedCollateral :collateralProfileData="clientRiskProfileReportData.collateralProfile" />
      </TileCard>
      <TileCard tileTitle="Manage Collateral" v-if="!clientId">
        <ManageCollateral
          @reload-collateral="loadClientRiskProfileReport"
          :collateralProfileData="clientRiskProfileReportData.collateralProfile"
        />
      </TileCard>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.limits-and-collateral-management {
  font-family: $font-family-inter;

  ::v-deep .tile-card {
    margin-bottom: 3rem;
  }
}
</style>
