<template>
  <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.401,3.839C17.208,4.339 17.08,4.872 17.027,5.427L1.795,8.096L1.5,8.362L1.5,16.35L1.688,16.662L4.05,17.085C4.224,17.116 4.33,17.186 4.387,17.279C4.444,17.284 4.505,17.333 4.572,17.443L4.749,17.735L4.743,17.724C5.317,18.669 7.216,20.976 8.339,22.317C8.298,21.422 8.267,20.255 8.334,19.506L8.406,18.692C8.425,18.482 8.53,18.29 8.697,18.163C8.864,18.035 9.077,17.983 9.284,18.019L22.13,20.292L22.5,20.092L22.5,11.979C22.665,11.993 22.832,12 23,12C23.341,12 23.675,11.972 24,11.917L24,20.221C24.001,20.582 23.832,20.92 23.541,21.19C23.135,21.566 22.462,21.788 22.462,21.788C22.346,21.824 22.224,21.832 22.105,21.811L9.828,19.639C9.72,20.854 9.89,23.238 9.907,23.463C10.016,24.425 8.825,25.033 8.11,24.363C8.089,24.344 8.07,24.324 8.052,24.303C8.052,24.303 4.322,19.92 3.461,18.503L3.472,18.52L3.461,18.503C3.461,18.503 1.33,18.122 1.338,18.124C1.062,18.077 0.791,17.916 0.571,17.675C0.289,17.364 0.094,16.914 0.059,16.827C0.054,16.815 0.05,16.806 0.05,16.806C0.05,16.806 0,16.536 0,16.536C0,16.536 0,8.24 0,8.246C-0.002,7.989 0.106,7.694 0.315,7.429C0.563,7.115 0.957,6.832 1.035,6.779C1.045,6.772 1.052,6.767 1.052,6.767L1.338,6.653L17.401,3.839ZM3.487,18.544L3.495,18.554L3.483,18.537L3.487,18.544ZM4.786,17.806L4.789,17.815L4.786,17.806Z"
      fill="currentColor"
    />
    <path
      d="M22.955,9.91C20.771,9.91 19,8.139 19,5.955C19,3.771 20.771,2 22.955,2C25.139,2 26.91,3.771 26.91,5.955C26.91,8.139 25.139,9.91 22.955,9.91Z"
      fill="#EF6172"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
