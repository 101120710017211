<script lang="ts" setup>
import { BeneficiaryStatus, Beneficiary } from 'ah-api-gateways';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import capitalize from 'lodash/capitalize';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    beneficiary: Beneficiary;
    addAccount?: boolean;
    addBankAllowed?: boolean | string;
    removeBankAllowed?: boolean | string;
  }>(),
  {
    addAccount: false,
    addBankAllowed: true,
    removeBankAllowed: true,
  }
);

const beneficiaryTypeText = computed(() => {
  if (props.beneficiary) {
    return capitalize(props.beneficiary.type);
  }
  return '';
});

const beneficiaryStatusText = computed(() => {
  if (props.beneficiary) {
    return capitalize(props.beneficiary.status);
  }
  return '';
});
</script>

<template>
  <div>
    <div class="beneficiary-details mt-2">
      <div class="row">
        <div class="col-3 semi-bold">Status:</div>
        <div class="col">
          <span :class="{ yellow: beneficiary.status === BeneficiaryStatus.PENDING }">&#9679;</span>
          {{ beneficiaryStatusText || 'N/A' }}
          <p v-if="beneficiary.status === BeneficiaryStatus.PENDING" class="pending-message mb-2">
            The beneficiary is currently being reviewed - you may be required to provide additional information.
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3 semi-bold">Type:</div>
        <div class="col">{{ beneficiaryTypeText }}</div>
      </div>
      <div class="row mt-2">
        <div class="col-3 semi-bold">Country:</div>
        <div class="col">{{ countryNameFromCC(beneficiary.address.countryCode) }}</div>
      </div>
    </div>
    <slot name="beneficiary-footer" />
  </div>
</template>

<style lang="scss" scoped>
.yellow {
  color: #ffd12f;
}

.pending-message {
  max-width: 35ch;
  color: #6b7c94;
}
</style>
