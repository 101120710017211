<script lang="ts" setup>
import { required } from '@vuelidate/validators';
import { minDate, maxDate, date, ifTest, disallowStateDates } from 'ah-common-lib/src/form/validators';
import { TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { makeFormModel, setState, submitForm } from 'ah-common-lib/src/form/helpers';
import { dateField } from 'ah-common-lib/src/form/models';
import { NonTradeableDays, HedgingInstruments, TenorLimits, TradeableDays } from 'ah-api-gateways';
import { FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { TimeFrameDate } from '../../models/date';
import { addDays, startOfDay, format, parse, formatDistance } from 'date-fns';
import { tenorToDate, stripZoneOffset } from 'ah-common-lib/src/helpers/time';
import { combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { CutoffTimeModel, FxOperation } from 'ah-api-gateways/models';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../..';

import { computed, reactive, watch, onMounted } from 'vue';
import { ApiError } from 'ah-requests';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const FORWARD_MIN_TRADING_DAYS = 3;

/**
 * Calculate minimum forward date
 *
 * This assumes calculation for t+3 is done by getting the 3rd trading day after today.
 */
function calcMinForwardDate(disallowedDates: string[]) {
  let date = startOfDay(new Date());

  for (let i = 0; i < FORWARD_MIN_TRADING_DAYS; i++) {
    do {
      date = addDays(date, 1);
    } while (disallowedDates.includes(format(date, 'dd-MM-yyyy')));
  }

  return stripZoneOffset(date);
}

const emit = defineEmits<{
  (e: 'validation-error', payload: ApiError): void;
  (e: 'update:timeFrame', payload: TimeFrameDate): void;
}>();

const props = withDefaults(
  defineProps<{
    /**
     * Trade direction
     * May be null to account for synchronous actions, but is expected for proper behaviour
     *
     * When set, date options for a Forward settlement date are updated
     */
    tradeDirection?: string | null;
    /**
     * TimeFrameDate object
     *
     * synchronizable via `update:timeFrame` or .sync modifier
     */
    timeFrame?: TimeFrameDate | null;
  }>(),
  {
    tradeDirection: null,
    timeFrame: null,
  }
);

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'setUpDateForImmediateSpot') {
      getNonTradeableDays();
    }
  },
});

const state = reactive<{
  lastDay: string | null;
  holidays: string[];
  weekends: string[];
  /**
   * Cutoff time and date used for the form values
   *
   * Will be included as part of the timeframe object exported, so as to allow determining the validity of the values
   * (After the cutoff time, form should reload, and values may not be valid anymore)
   */
  cutoffDate: CutoffTimeModel | null;
  minForwardDate: Date;
}>({
  lastDay: null,
  holidays: [],
  weekends: [],
  cutoffDate: null,
  minForwardDate: calcMinForwardDate([]),
});

const settlementDateFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'settlementDateForm',
    title: '',
    fieldType: 'form',
    fields: [
      dateField(
        'targetDate',
        '',
        {
          fieldType: 'date',
          defaultValue: null,
          errorMessages: {
            minDate: 'Earliest possible settlement date for forwards is T+3',
            maxDate: 'Settlement date is too far in the future, please try again',
            holidays: 'Date cannot be on a holiday',
            weekend: 'Date cannot be on a weekend',
            required: 'Date is required',
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(required, (a) => !(a instanceof Date)),
          minDate: minDate(() => state.minForwardDate),
          maxDate: maxDate(() => maxForwardDate.value),
          holidays: ifTest(
            disallowStateDates('targetDate', 'holidays'),
            minDate(() => state.minForwardDate).$validator as (value: any, siblingState: any, vm: any) => boolean
          ),
          weekend: ifTest(
            disallowStateDates('targetDate', 'weekends'),
            minDate(() => state.minForwardDate).$validator as (value: any, siblingState: any, vm: any) => boolean
          ),
        }
      ),
    ],
  }),
  validation: null,
});

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

onMounted(() => {
  emitDate();

  if (settlementDateFormDef.validation) {
    submitForm(settlementDateFormDef.validation);
  }

  tradeState.store.useSettingsStore().loadClientLimitsProfile({ force: false, clientId: onBehalfOfClient?.value?.id });
});

function touchForms() {
  if (settlementDateFormDef.validation) {
    submitForm(settlementDateFormDef.validation);
  }
}

function isValid() {
  return !settlementDateFormDef.validation?.$invalid;
}

const maxForwardDate = computed(() => {
  return stripZoneOffset(
    state.lastDay
      ? addDays(parse(state.lastDay, 'dd-MM-yyyy', new Date()), 1)
      : tenorToDate(
          tradeState.store.useSettingsStore().clientLimitsProfile?.tenorLimitForwards ?? TenorLimits.TWELVE_MONTHS
        )
  );
});

const areFormErrorsShown = computed(() => {
  return settlementDateFormDef.validation?.$invalid && settlementDateFormDef.validation?.targetDate?.$dirty;
});

watch(
  () => props.timeFrame,
  () => {
    if (props.timeFrame && settlementDateFormDef.form) {
      settlementDateFormDef.form.targetDate = props.timeFrame.targetDate;
      capDate();
    }
  },
  { immediate: true }
);

const distanceToSettlementDate = computed(() => {
  const date = new Date(settlementDateFormDef.form?.targetDate);
  if (isNaN(date.valueOf())) {
    return '';
  }
  if (date < new Date()) {
    return '';
  }
  return formatDistance(new Date(), new Date(settlementDateFormDef.form?.targetDate));
});

const clientId = computed(() => {
  return onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id;
});

function capDate() {
  let currentDate = new Date(settlementDateFormDef.form!.targetDate);
  if (!isNaN(currentDate.valueOf())) {
    if (currentDate < state.minForwardDate) {
      currentDate = state.minForwardDate;
    }

    let dateString = format(currentDate, 'dd-MM-yyyy');
    while ([...state.holidays, ...state.weekends].includes(dateString)) {
      currentDate = addDays(currentDate, 1);
      dateString = format(currentDate, 'dd-MM-yyyy');
    }

    if (maxForwardDate.value && currentDate > maxForwardDate.value) {
      currentDate = maxForwardDate.value;
    }
    settlementDateFormDef.form!.targetDate = currentDate.toISOString();
  }
}

function loadCutoffTime() {
  if (props.tradeDirection && clientId.value) {
    return tradeState.services.pricingEngine.getCutoffTime(
      props.tradeDirection,
      clientId.value,
      FxOperation.FX_FORWARD,
      undefined,
      onBehalfOfClient?.value?.id
    );
  }
  return throwError(`Can't load without trade direction`);
}

function getNonTradeableDays() {
  if (props.tradeDirection) {
    requestManager.manager
      .sameOrCancelAndNew(
        'setUpDateForImmediateSpot',
        combineLatest([
          tradeState.services.pricingEngine.getNonTradeableDays(
            HedgingInstruments.FX_FORWARD,
            props.tradeDirection,
            onBehalfOfClient?.value?.id
          ),
          tradeState.services.pricingEngine.getTradeableDays(
            HedgingInstruments.FX_FORWARD,
            props.tradeDirection,
            onBehalfOfClient?.value?.id
          ),
          loadCutoffTime(),
        ]),
        props.tradeDirection
      )
      .pipe(
        catchError((error) => {
          if (error.response.status === 400) {
            emit('validation-error', error.response.data);
            return of(null);
          } else {
            throw error;
          }
        })
      )
      .subscribe((result: [NonTradeableDays, TradeableDays, CutoffTimeModel] | null) => {
        if (!result) return;
        state.holidays = result[0].holidays.filter((d) => !result[0].weekend.includes(d));
        state.weekends = result[0].weekend;
        state.lastDay = result[1].businessDays[result[1].businessDays.length - 1];
        state.cutoffDate = result[2];
      });
  }
}

watch(() => props.tradeDirection, getNonTradeableDays, { immediate: true });

watch(
  () => [state.holidays, state.weekends],
  () => {
    if (settlementDateFormDef.form) {
      const targetDate = settlementDateFormDef.form.$fields.find((f) => f.$name === 'targetDate');
      if (state.holidays) {
        setState(targetDate!, 'holidays', state.holidays);
      }
      if (state.weekends) {
        const targetDate = settlementDateFormDef.form.$fields.find((f) => f.$name === 'targetDate');
        setState(targetDate!, 'weekends', state.weekends);
      }

      state.minForwardDate = calcMinForwardDate([...state.weekends, ...state.holidays]);
      capDate();
      emitDate();
    }
  },
  { immediate: true }
);

function onDateChange(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value' && !settlementDateFormDef.validation?.$invalid) {
    emitDate();
  }
}

function emitDate() {
  emit('update:timeFrame', {
    isForward: true,
    targetDate: settlementDateFormDef.form!.targetDate,
    targetTimeFrame: TimeFrames.OTHER,
    cutoffDateTime: state.cutoffDate?.firstConversionCutoffDatetime,
  } as TimeFrameDate);
}

defineExpose({ isValid, touchForms });
</script>

<template>
  <ValidatedForm
    :fm="settlementDateFormDef.form"
    @form-event="onDateChange"
    :validation.sync="settlementDateFormDef.validation"
  >
    <template #settlementDateForm.targetDate:after>
      <span class="text-muted" v-if="!areFormErrorsShown && distanceToSettlementDate">
        ({{ distanceToSettlementDate }} forward)
      </span>
    </template>
  </ValidatedForm>
</template>
