import { extractIBAN } from 'ibantools';
import { compareState } from '.';

export const validIBANCountry = (fieldName: string) =>
  compareState(fieldName, (val: string, state) => {
    const iban = extractIBAN(val);
    if (iban.valid && state.ibanAllowedCountries?.length > 0) {
      return state.ibanAllowedCountries.indexOf(iban.countryCode) > -1;
    }
    return true;
  });
