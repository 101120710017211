<script lang="ts" setup>
import AppBanner from '@/app/components/common/AppBanner.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useWalletsStore } from '@/app/store/walletsModule';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, watch } from 'vue';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadFeesInfo') {
      onIsLoggedInChange();
    }
  },
}).manager;

const authStore = useAuthStore();

const walletsStore = useWalletsStore();

const feesInfo = computed(() => {
  if (!authStore.isClientUser) {
    return walletsStore.getPartnerFeesInfo();
  } else {
    return walletsStore.getClientFeesInfo(authStore.loggedInIdentity!.client!.id!);
  }
});

const feeLink = computed(() => {
  if (authStore.isClientUser) {
    return `/dashboard/wallets/${debtCurrencyWalletID.value}?tab=fees`;
  } else {
    return `/admin/wallets/${debtCurrencyWalletID.value}?tab=fees`;
  }
});

const withOutstandingFees = computed(() => feesInfo.value && feesInfo.value.debtCumulative > 0);

const debtCurrencyWalletID = computed(() => wallet.value?.id);

const wallet = computed(() => (feesInfo.value ? walletsStore.getWallet(feesInfo.value.debtCurrency) : undefined));

function onIsLoggedInChange() {
  if (!authStore.isLoggedIn) {
    return;
  }
  if (!authStore.isClientUser) {
    requestManager.newPromise('loadFeesInfo', walletsStore.loadPartnerFeesInfo({ force: false }));
  } else {
    requestManager.newPromise(
      'loadFeesInfo',
      walletsStore.loadClientFeesInfo({
        force: false,
        clientId: authStore.loggedInIdentity!.client!.id,
      })
    );
  }
}

watch(() => authStore.isLoggedIn, onIsLoggedInChange, { immediate: true });
</script>

<template>
  <AppBanner
    v-if="!requestManager.anyPending && withOutstandingFees && feesInfo && debtCurrencyWalletID"
    class="text-center"
    :class="[feesInfo.accountState === 'SUSPENDED' ? 'danger' : 'warning']"
  >
    <p class="mb-0">
      You have outstanding fees requiring attention in your GBP wallet. Click
      <RouterLink :to="feeLink" class="plain-color-link">here</RouterLink> to pay them.
    </p>
  </AppBanner>
</template>
