<script setup lang="ts">
import { makeFormModel, getChildModel, setState, updateModel } from 'ah-common-lib/src/form/helpers';
import { checkboxField } from 'ah-common-lib/src/form/models';
import { ClientType, CompanyRegistrationModel, ExportFileType, LegalFileType } from 'ah-api-gateways';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { useTheme } from 'ah-theme';
import { useAuthStore } from '@/app/store/authStore';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

const props = defineProps<{ model?: Partial<CompanyRegistrationModel> }>();

const requestManager = useRequestManager().manager;

const services = getServices();

const emit = defineEmits<{
  (e: 'update:validation', value: FormValidation): void;
}>();

const authStore = useAuthStore();

const TOSHtml = computed(() => selectedFile.value?.html ?? '');

const termsFiles = ref<{ type: LegalFileType; label: string; html: string }[]>([
  {
    type: LegalFileType.TOS_PAYMENT_SERVICE,
    label: 'Payment Services',
    html: '',
  },
  {
    type: LegalFileType.TOS,
    label: 'Terms and Conditions',
    html: '',
  },
]);

const selectedFileType = ref<LegalFileType>(termsFiles.value[0].type);

const selectedFile = computed(() => termsFiles.value.find((i) => i.type === selectedFileType.value));

onBeforeMount(() => {
  requestManager
    .sameOrCancelAndNew(
      'getTOSHtml',
      forkJoin(
        termsFiles.value.map((i) =>
          services.customerReference.getLegalDocument(i.type, ExportFileType.HTML).pipe(
            map((r) => ({
              type: i.type,
              label: i.label,
              html: r.html,
            }))
          )
        )
      )
    )
    .subscribe((res) => (termsFiles.value = res));
});

const termsAndConditionsFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'termsAndConditions',
    fieldType: 'form',
    fields: [
      checkboxField('paymentServices', 'I confirm I have read and agree with the Payment Services.', false, {
        required: true,
        errorMessages: {
          mustAccept: 'Must agree with the Payment Services to continue',
        },
      }),
      checkboxField('termsAndConditions', 'I confirm I have read and agree with to Terms and Conditions.', false, {
        required: true,
        errorMessages: {
          mustAccept: 'Must agree with the Terms and Conditions to continue',
        },
      }),
    ],
  }),
  validation: null,
});

function downloadTerms() {
  requestManager
    .sameOrCancelAndNew(
      'downloadTerms',
      services.customerReference.downloadLegalDocument(selectedFileType.value, ExportFileType.PDF)
    )
    .subscribe();
}

const information = computed(() => {
  return `${useTheme().val.name} are partnered with ALT21. Your data will be processed by ALT21
   in accordance with Data Protection legislation for the purposes of client
    administration and will be maintained in line with our retention policy.
    Further information about your privacy can be located here.`;
});

const validation = computed(() => {
  return {
    $model: termsAndConditionsFM.form.termsAndCondition && termsAndConditionsFM.form.paymentServices,
    $invalid: termsAndConditionsFM.validation?.$invalid,
    $dirty: termsAndConditionsFM.validation?.$dirty,
  } as FormValidation;
});

const isOwner = computed(() => {
  return (
    !authStore.isClientUser ||
    authStore.loggedInIdentity?.client?.type !== ClientType.COMPANY ||
    authStore.loggedInIdentity?.owner
  );
});

watch(
  () => props.model,
  () => {
    updateModel(termsAndConditionsFM.form, props.model ?? {});
    setState(getChildModel(termsAndConditionsFM.form, 'termsAndConditions')!, 'hidden', !isOwner.value);
  },
  { immediate: true }
);

watch(validation, () => emit('update:validation', validation.value), { immediate: true });
</script>

<template>
  <div x-test-name="terms-acceptance-form">
    <div class="file-selector">
      <div
        class="card-block file-button"
        v-for="(file, index) in termsFiles"
        :key="file.type"
        @click="selectedFileType = file.type"
        :class="{ active: selectedFileType === file.type }"
      >
        <div class="doc-count">Document {{ index + 1 }}</div>
        <div class="doc-label">{{ file.label }}</div>
      </div>
    </div>
    <div class="embed-file">
      <LoadingIcon v-if="requestManager.requestStates.getTOSHtml === 'pending'" />
      <div v-html="TOSHtml" v-else />
    </div>
    <div class="buttons-holder px-0">
      <VButton
        blurOnClick
        @click="downloadTerms"
        class="btn-stroked"
        :loading="requestManager.requestStates.downloadTerms === 'pending'"
        :disabled="requestManager.requestStates.downloadTC === 'pending'"
      >
        Download {{ selectedFile ? selectedFile.label : 'File' }}
      </VButton>
    </div>

    <ValidatedForm :fm="termsAndConditionsFM.form" :validation.sync="termsAndConditionsFM.validation">
      <template #termsAndConditions.termsAndConditions:checkbox-label>
        I confirm I have read and agree to the Terms and Conditions of Service.
      </template>
      <template #termsAndConditions.paymentServices:checkbox-label>
        I confirm I have read and agree to the Payment Services.
      </template>
    </ValidatedForm>
    <p class="text-muted">{{ information }}</p>
  </div>
</template>

<style lang="scss" scoped>
.embed-file {
  position: relative;
  height: 35rem;
  border: 2px solid;
  border-radius: 3px;
  @include themedBorderColor($color-primary);
  overflow: auto;
  padding: 0.5rem;

  ::v-deep {
    li {
      display: block;
      position: relative;
      padding-bottom: 0.3em;
    }

    li:before {
      content: counters(item, '.') '. ';
      white-space: nowrap;
      position: absolute;
      counter-increment: item;
      top: 0;
      left: -3.5em;
    }

    li.addon:before {
      content: '';
      display: none !important;
    }

    li.subclause:before {
      content: '(' counter(item, lower-alpha) ')';
    }

    div > ol > li {
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: 2em;
    }

    div > ol > li > ol {
      padding-left: 0em;
      padding-top: 2em;
    }

    ol {
      text-transform: none;
      font-weight: normal;
      padding-left: 3.5em;
      counter-reset: item;
    }
  }
}

.buttons-holder {
  margin: 2rem 0 2rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}

.file-selector {
  text-align: center;
  margin-bottom: 2rem;

  @include upToResolution($tabletResolution) {
    font-size: $font-size-sm;
    white-space: nowrap;
  }

  .file-button {
    width: 30%;
    display: inline-block;
    margin: 0 0.5rem;
    text-align: center;
    cursor: pointer;
    padding: 0.5em 0;
    @include themedTextColor($color-primary);
    &.active {
      @include themedBackgroundColor($color-primary);
      color: white;
    }

    .doc-count {
      font-size: $font-size-sm;
    }

    .doc-label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.2rem;
      font-weight: $font-weight-semibold;
    }
  }
}
</style>
