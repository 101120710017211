import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import { CurrencyPair, ListQuery, PaginatedResponse, standardiseQuerySort } from '../models';

export class FxReferenceService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'referenceService' } },
      },
    });
  }

  listCurrenciesPairs(
    query?: ListQuery,
    oboClientId?: string,
    options?: Partial<HttpOptions<PaginatedResponse<CurrencyPair>>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    if (query) query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<CurrencyPair>>(`${this.baseUrl}currency-pairs`, {
      ...options,
      axiosConfig: { ...headers, params: query },
    });
  }
}
