/* eslint-disable no-console */
import { HttpService, HttpRequestOptions, ErrorMessage, HttpError, ErrorOptions } from '..';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { HttpCacheService } from './httpCacheService';
import { defaultServerErrorMessages, genericServerErrorMessage } from './httpErrorMessages';
import { RequestManager } from '../helpers/requestManager';
import { AxiosResponse } from 'axios';
import defaultsDeep from 'lodash/defaultsDeep';

// FIXME this needs to be removed, issuing a warning on every request that fails
function cleanupResponse<T>(r: AxiosResponse<T>, alert = false) {
  if ((r.data as any).code !== undefined && (r.data as any).payload) {
    if (alert) {
      console.warn(`Request '${r.request.url}' is sending a wrapped response!`);
    }
    return (r.data as any).payload as T;
  } else {
    return r.data;
  }
}

/**
 * This service contains helper functions to make HTTP requests, and is created to be extended by regular services that want to do http requests
 * It also provides a rquest cache which can be used by sending the correct CacheOptions on a request
 */
export class HttpBasedService extends HttpCacheService {
  private http: HttpService;

  protected defaultOptions: Partial<HttpRequestOptions<any>> = {};

  private reqManager = new RequestManager();

  constructor(http: HttpService, defaultOptions?: Partial<HttpRequestOptions<any>>) {
    super();
    this.http = http;
    this.defaultOptions = { ...defaultOptions };
  }

  // perform an http request
  protected request<T>(requestOptions: HttpRequestOptions<T>): Observable<T> {
    const options: HttpRequestOptions<T> = defaultsDeep(requestOptions, this.defaultOptions);

    let request = this.rawRequest<T>(options);

    const cache = options.options?.cache;

    // if no cache is required, return the request
    if (cache && cache.type !== 'bypass') {
      // if looking for item in cache, look for it and return it if existing
      if (cache.type === 'use') {
        if (typeof cache.itemKey === 'string') {
          const item = this.getCache<T>(cache.cacheKey, cache.itemKey);
          if (item) {
            return of(item);
          } else {
            request = this.reqManager.currentOrNew(`${cache.cacheKey}-${cache.itemKey}`, request);
          }
        } else {
          throw new Error('Computed cache key only possible when overriding');
        }
      }

      // do the request and if the response is successfull create and store the result in the cache
      request = request.pipe(
        tap((response) => {
          if (response.status === 200 || response.status === 201) {
            this.setupCache(cache!.cacheKey);
            if (cache.type === 'delete') {
              this.clearCache(cache.cacheKey, cache.itemKey);
            } else {
              this.setCache(cache!.cacheKey, cache!.itemKey, cleanupResponse(response));
            }
          }
        })
      );
    }

    return request.pipe(map((r) => cleanupResponse(r, true)));
  }

  protected rawRequest<T>(options: HttpRequestOptions<any>): Observable<AxiosResponse<T>> {
    return this.http.request<T>(options).pipe(tap({ error: (error) => this.handleHttpError(error) }));
  }

  // ------------------------- HTTP methods requests shortcuts ---------------------------

  public get<T>(url: string, options: Partial<HttpRequestOptions<T>> = {}) {
    return this.request<T>({
      ...options,
      axiosConfig: { ...options.axiosConfig, method: 'get', url },
    });
  }

  public post<T>(url: string, data?: any, options: Partial<HttpRequestOptions<T>> = {}) {
    return this.request<T>({
      ...options,
      axiosConfig: {
        ...options.axiosConfig,
        method: 'post',
        url,
        data,
      },
    });
  }

  public put<T>(url: string, data?: any, options: Partial<HttpRequestOptions<T>> = {}) {
    return this.request<T>({
      ...options,
      axiosConfig: {
        ...options.axiosConfig,
        method: 'put',
        url,
        data,
      },
    });
  }

  public delete<T>(url: string, options: Partial<HttpRequestOptions<T>> = {}) {
    return this.request<T>({
      ...options,
      axiosConfig: { ...options.axiosConfig, method: 'delete', url },
    });
  }

  /**
   * Handle all HTTP errors. If request was made with silent config nothing will be done, otherwise a toast will be shown with a global error message
   * @param error the Axios error response
   */
  private handleHttpError<T>(error: HttpError<T>) {
    this.notifyHttpError(error);
    this.logHttpError(error);
  }

  private notifyHttpError<T>(error: HttpError<T>) {
    error.config.userConfig = error.config.userConfig || {};
    const errorOptions: ErrorOptions = {
      useGenericErrors: true,
      silent: false,
      ...error.config.userConfig.errors,
    };

    const silent = typeof errorOptions.silent === 'function' ? errorOptions.silent(error) : errorOptions.silent;

    if (silent) {
      return error;
    }

    const messageFind = (e: ErrorMessage) =>
      typeof e.code === 'function' ? e.code(error.response) : e.code && e.code === error.response?.status;

    // find first if we have overriden the error message, if not use one of the defaults or a generic one
    let errorMessage = errorOptions.errorMessages?.find(messageFind) || errorOptions.catchAll;

    if (!errorMessage && errorOptions.useGenericErrors) {
      errorMessage = defaultServerErrorMessages.find(messageFind) || genericServerErrorMessage;
    }

    if (errorMessage && errorMessage.message) {
      return this.http.notifier?.error(
        {
          ...errorOptions.messageDefaults,
          ...errorMessage,
        },
        error
      );
    }
    return error;
  }

  private logHttpError<T>(error: HttpError<T>) {
    this.http.logger?.error(
      '[HTTP] The request to the following endpoint resulted in error:',
      error.request?.responseURL,
      error
    );

    // log error messages with different severities...
    // TODO add error messages from backend
    // TODO send this log somewhere?
    switch (error.response?.status) {
      case 400:
        this.http.logger?.debug('[HTTP] Bad request', error.response.data);
        break;

      case 401: // authentication error, logout the user, should remove interceptor from auth module?
        this.http.logger?.debug('[HTTP] User session no longer valid');
        break;

      case 404:
        this.http.logger?.debug('[HTTP] 404 Not found', error.response.data);
        break;

      case 502:
        this.http.logger?.error('[HTTP] Service is down', error.response);

      case 500:
      default:
        this.http.logger?.error('[HTTP] 💥 Unexpected error', error.response?.data);
    }
    return error;
  }
}
