<script setup lang="ts">
import { computed, onBeforeMount, PropType, ref, useSlots, watch } from 'vue';

export interface ScrollRangeEvent {
  first: number;
  last: number;
}

/**
 * Virtual Scroller
 *
 * Used when rendering a big amount of components. Will create a scrollable
 * component that renders the components dynamically on demand (scroll)
 *
 * The scroll size depends on the children height, thus the scroller assumes all
 * elements inside of the scroller got the same height
 *
 * Emits:
 * - scroll-index-change (payload: ScrollRangeEvent): when items in viewport change
 */

const emit = defineEmits({
  scroll: (_event: Event) => true,
  'scroll-index-change': (_model: ScrollRangeEvent) => true,
});

const props = defineProps({
  /**
   * Items displayed in the infinite scroll. Will be listed and displayed in
   * a scrollable container
   */
  items: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  /**
   * Number of bearable items rendered. Defaults to 10, which means only 10
   * items will be displayed simultaneously
   */
  renderedItems: {
    type: Number,
    default: 10,
  },

  /**
   *  Class applied to the scrollable wrapper container
   */
  wrapperClass: {
    type: String,
    default: '',
  },
  /**
   *  Class applied to the outer content container
   */
  containerClass: {
    type: String,
    default: '',
  },
  /**
   *  Class applied to the inner content container
   */
  contentClass: {
    type: String,
    default: '',
  },
});

const slots = useSlots();

const scrollWrapper = ref<InstanceType<typeof HTMLElement>>();
const scrollContainer = ref<InstanceType<typeof HTMLElement>>();
const scrollContent = ref<InstanceType<typeof HTMLElement>>();

/*
 * First item displayed in the list of rendered items
 */
const firstItem = ref<number>(0);

/**
 * Last item displayed in the list of rendered items
 * Will always be `firstItem` * `renderedItems`
 */
const lastItem = ref<number>(0);

/**
 * Height of child element
 */
const elementHeight = ref<number>(30);

onBeforeMount(() => {
  lastItem.value = props.renderedItems;

  // Calculate scroll height after all div have rendered properly

  setTimeout(() => calculateScrollHeight());
});

const loadedItems = computed(() => props.items.slice(firstItem.value, lastItem.value));

const itemsLength = computed(() => props.items.length + (slots['append-option']?.length || 0));

/**
 * Scrolls into `item` if set. Otherwise scrolls to top
 */
function scrollTo(item?: any) {
  const index = Math.max(
    props.items.findIndex((i) => i === item),
    0
  );

  firstItem.value = index;
  setContentPosition();
  setScrollPosition();
}

/**
 * Calculates virtual scroller height.
 *
 * Will change the outer container div to have the same size as the
 * number of items * each item height.
 */
function calculateScrollHeight() {
  if (scrollContent.value?.children.item(0) !== null) {
    elementHeight.value = scrollContent.value!.children.item(0)!.clientHeight;
    scrollContainer.value!.style!.height = `${elementHeight.value * itemsLength.value + 10}px`;
  }
}

/**
 * Sets content position.
 *
 * Will change the inner container div to display in the present view.
 * Will always be the first item size * each element height
 */
function setContentPosition() {
  if (scrollContent.value) {
    scrollContent.value.style.transform = `translateY(${elementHeight.value * firstItem.value}px)`;
  }
}

/**
 * Sets scroll position.
 *
 * Will change the scroll position to the first item index * the element height
 */
function setScrollPosition() {
  if (scrollWrapper.value) {
    scrollWrapper.value.scrollTop = firstItem.value * elementHeight.value;
  }
}

function onScroll(event: Event) {
  emit('scroll', event);

  const target = event.target as Element;
  if (!target) return;

  // calculates the first item matching the current scroll position
  const newFirst = Math.floor(target.scrollTop / elementHeight.value);
  const newLast = newFirst + props.renderedItems;

  if (newFirst !== firstItem.value || newLast !== lastItem.value) {
    firstItem.value = newFirst;
    lastItem.value = newLast;
    setContentPosition();
    emit('scroll-index-change', { first: newFirst, last: newLast });
  }
}

function onItemChange() {
  calculateScrollHeight();
  scrollTo();
}

watch(() => props.items, onItemChange);
</script>

<template>
  <div class="scroller" ref="scrollWrapper" :class="wrapperClass" @scroll="onScroll">
    <slot name="content">
      <div id="scroller-container" class="scroller-container" ref="scrollContainer" :class="containerClass">
        <div class="scroll-content" ref="scrollContent" :class="contentClass">
          <template v-for="(option, index) in loadedItems">
            <slot v-bind="{ option, index, options: loadedItems }" />
          </template>
          <slot name="append-option" v-bind="{ options: loadedItems }" />
        </div>
      </div>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.scroller {
  position: relative;
  overflow: auto;
  contain: strict;
  will-change: scroll-position;

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    contain: content;
    min-height: 100%;
    min-width: 100%;
    will-change: transform;
  }
}
</style>
