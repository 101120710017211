import { Form } from '../../../form/interfaces';
import countries from '../../../constants/countries';
import { textField, selectField, ibanField } from '../../../form/models';
import { requiredIfStateValue } from '../../../form/validators';
import { helpers } from '@vuelidate/validators';

export function bankAccountDetailsFormSchema(withCountry = true): Form {
  return {
    name: 'bankAccountDetails',
    state: {
      title: 'Bank Account details',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: true,
    },
    fieldType: 'form',
    fields: [
      selectField('bankCountry', 'Bank Country', countries, { required: false, hidden: true }),
      textField('reference', 'Reference', { maxLength: 18, showLengthCounter: true }),
      // iban accepts a maximum of 34 alphanumeric characters, but we are adding spaces, thus the limit should be higher (42)
      ibanField('iban', 'IBAN', { titleTooltip: 'The IBAN of the beneficiary bank account.', maxLength: 42 }),
      ...(withCountry ? [selectField('bankCountry', 'Country', countries)] : []),
      textField('accountHolderName', "Account Holder's name", { required: true, maxLength: 255 }),
      textField(
        'bicSwift',
        'BIC/SWIFT',
        {
          required: false,
          maxLength: 11,
          titleTooltip: 'The SWIFT code of the beneficiary bank account. This is also known as the BIC.',
          errorMessages: {
            bicValidity: 'Invalid BIC/SWIFT format.',
          },
        },
        {
          required: requiredIfStateValue('bicSwift'),
          bicValidity: helpers.regex(/^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?/i),
        }
      ),
      textField('bankName', 'Bank', { required: false, maxLength: 100 }),
      textField('bankAddress', 'Bank Address', { required: false, hidden: true, maxLength: 100 }),
      textField('routingNumber1', 'Routing Number', { required: false, hidden: true, maxLength: 50 }),
      textField('routingNumber2', 'Routing Number 2', { required: false, hidden: true, maxLength: 50 }),
      textField('accountNumber', 'Account Number', {
        required: false,
        hidden: true,
        titleTooltip: 'The account number of the beneficiary bank account.',
        maxLength: 50,
      }),
      // These fields are not included in the cank account fields endpoints,
      // and need to be edited using results from the purpose codes endpoint
      textField('purposeCodeText', 'Purpose Code', {
        required: false,
        hidden: true,
      }),
      selectField('purposeCodeSelect', 'Purpose Code', [], {
        required: false,
        hidden: true,
        appendToBody: true,
      }),
    ],
  };
}
