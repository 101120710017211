<script lang="ts" setup>
import { AuthorityType, Client, Permission } from 'ah-api-gateways';
import { computed, PropType } from 'vue';
import { useRouter } from 'vue-router/composables';

type OboAction = 'trade' | 'trades' | 'payment' | 'beneficiary-management' | 'documents' | 'limits-collateral';

/**
 * On behalf of client actions
 *
 * Used by clients to executed action on behalf of a client
 *
 */

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
  clientPermissions: {
    type: Array as PropType<Permission[]>,
    default: () => [],
  },
});

const router = useRouter();

const clientAllowsTrades = computed(() => {
  const permission = props.clientPermissions.find(
    (permission) => permission.authority === AuthorityType.TRADE_ON_BEHALF_OF
  );
  return permission ? permission.allow : false;
});

const clientAllowsPayments = computed(() => {
  const permission = props.clientPermissions.find(
    (permission) => permission.authority === AuthorityType.PAY_ON_BEHALF_OF
  );
  return permission ? permission.allow : false;
});

function goTo(location: OboAction) {
  const baseUrl = `/admin/activity/clients/obo`;
  router.push({ path: `${baseUrl}/${props.client.id}/${location}` });
}

function goToCollateral() {
  goTo('limits-collateral');
}
</script>

<template>
  <VRow class="w-100 pl-3" align-h="center" align-v="center">
    <VCol class="button-col">
      <div>
        <VButton :disabled="!clientAllowsTrades" @click="goTo('trade')">New FX Trade</VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsPayments" @click="goTo('payment')">Payments</VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsTrades" @click="goToCollateral"> Collateral Management </VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsPayments" @click="goTo('beneficiary-management')">
          Beneficiary Management
        </VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsTrades" @click="goTo('trades')">All Trades</VButton>
      </div>
      <div>
        <VButton @click="goTo('documents')">Document Management</VButton>
      </div>
    </VCol>
  </VRow>
</template>

<style scoped lang="scss">
.button-col {
  div {
    text-align: center;
    padding: 25px 0;
    .btn {
      font-size: 16px;
      width: 12rem;

      @include tablet {
        position: relative;

        width: 14rem;
        ::v-deep div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
