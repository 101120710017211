import {
  PaginatedQuery,
  PaginatedResponse,
  WalletsReconciliation,
  TransfersReconciliation,
  PaymentsReconciliation,
  standardiseQuerySort,
} from '../models';
import { HttpBasedService, HttpService } from 'ah-requests';

export class ReconciliationService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'reconciliationService' } },
      },
    });
  }

  public listWalletsReconciliations(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<WalletsReconciliation>>(`${this.baseUrl}reconciliations/wallets/reports`, {
      axiosConfig: { params },
    });
  }

  public listTransfersReconciliations(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<TransfersReconciliation>>(`${this.baseUrl}reconciliations/transfers/reports`, {
      axiosConfig: { params },
    });
  }

  public listPaymentsReconciliations(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<PaymentsReconciliation>>(`${this.baseUrl}reconciliations/payments/reports`, {
      axiosConfig: { params },
    });
  }
}
