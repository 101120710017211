import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Alternate to RXJS's forkJoin that will allow all requests to complete/error,
 * and then behave as a normal forkJoin (returning the first error occurred)
 */
export function forkJoinWithCompletion<T>(requests: Observable<T>[]) {
  let reqError: any = undefined;
  let withError = false;
  return forkJoin(
    requests.map((request) =>
      request.pipe(
        catchError((e) => {
          if (!withError) {
            reqError = e;
            withError = true;
          }
          return of(null);
        })
      )
    )
  ).pipe(
    map((r) => {
      if (withError) {
        throw reqError;
      }
      return r;
    })
  );
}
