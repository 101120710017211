import { Address } from '../common';
import { Questionnaire } from '.';
import { VersionedObject } from '../rest';
import { ClientType } from '../client';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { AuditData } from '../auditData';

export interface ComplianceStatusObject {
  status: ComplianceStatus;
  statusTransitionReason?: string;
}

export interface PublicComplianceCase extends ComplianceStatusObject, VersionedObject {
  id: string;
  questionnaire: Questionnaire;
  documentUploadStatus: ComplianceFilesStatus;
  termsAndConditionsAccepted: boolean;
  termsAndConditionsActionedBy: string;
  termsAndConditionsActionedByFullName: string;
  termsAndConditionsActionedByFirstName: string;
  termsAndConditionsActionedByLastName: string;
  termsAndConditionsActionedByEmail: string;
  termsAndConditionsSentAt: string;
}

export interface SmartSearchIndividualCase {
  smartsearchProofOfAddress: boolean;
  smartsearchProofOfIdentity: boolean;
}

export interface SmartSearchCompaniesCase {
  smartsearchDelphiScore: number | null;
  smartsearchRegistrationNumberVerified: boolean;
}
export interface BaseComplianceCase
  extends PublicComplianceCase,
    ComplianceStatusObject,
    SmartSearchCompaniesCase,
    SmartSearchIndividualCase {
  createdAt: string;
  updatedAt: string;
  clientName: string;
  partnerName: string;
  address: Address;
  applicantFirstName: string;
  applicantLastName: string;
  applicantId: string;
  termsAndConditionsDate: string;
  termsAndConditionsSentBy: string;
  termsAndConditionsSentByFullName: string;
  termsAndConditionsSentByFirstName: string;
  termsAndConditionsSentByLastName: string;
  termsAndConditionsSentByEmail: string;
  clientType: ClientType;
  reportingCurrency: string;
  smartsearchSearchId: string;
  smartsearchSubjectId: string;
  smartsearchStatus: SmartSearchStatus;
  smartsearchStatusDetail?: string;
  smartsearchOutcome: SmartSearchOutcome;
  smartsearchWatchlistStatus: SmartSearchPassFail;
  smartsearchSearchDate: string;
  smartsearchSearchedBy: string;
  smartsearchSearchedByFullName: string;
}

export interface CompanyComplianceCase extends BaseComplianceCase {
  companyName: string;
  entityType: string;
  sectorSicCode?: number;
  sectorDescription: string;
  registrationNumber: string;
  tradingAddress: Address;
}

export interface IndividualComplianceCase extends BaseComplianceCase {
  applicantFirstName: string;
  applicantLastName: string;
  applicantId: string;
  firstName: string;
  lastName: string;
  birthDate: string;
}

export type ComplianceCase = IndividualComplianceCase | CompanyComplianceCase;

export enum ComplianceCaseAuditActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SNAPSHOT = 'SNAPSHOT',
}

export type ComplianceCaseAuditData = ComplianceCase & AuditData<ComplianceCaseAuditActions>;

export function isCompanyCompliance(complianceCase: ComplianceCase): complianceCase is CompanyComplianceCase {
  return (complianceCase as CompanyComplianceCase).clientType === ClientType.COMPANY;
}

export function isComplianceStatusReadyForReview(status: ComplianceStatus) {
  return [ComplianceStatus.SUBMITTED, ComplianceStatus.DOCUMENTS_REQUIRED].includes(status);
}

export function isClosedComplianceStatus(status: ComplianceStatus) {
  return [ComplianceStatus.REJECTED, ComplianceStatus.DEACTIVATED].includes(status);
}

export function isActiveComplianceStatus(status: ComplianceStatus) {
  return [ComplianceStatus.APPROVED, ComplianceStatus.SUSPENDED].includes(status);
}

export function isOpenComplianceCase(complianceCase: ComplianceCase) {
  return [
    ComplianceStatus.DOCUMENTS_REQUIRED,
    ComplianceStatus.PENDING,
    ComplianceStatus.SUBMITTED,
    ComplianceStatus.TERMS_AND_CONDITIONS,
  ].includes(complianceCase.status);
}

export function getCaseName(item: ComplianceCase) {
  if (isCompanyCompliance(item)) {
    return item.companyName;
  }
  return `${item.firstName} ${item.lastName}`;
}

export function getApplicantName(item: ComplianceCase) {
  return `${item.applicantFirstName ?? ''} ${item.applicantLastName ?? ''}`;
}

export enum ComplianceStatus {
  /**
   * UNKNOWN does not exist in the API - it is used to handle missing compliance case responses
   */
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  UPDATED_TERMS_AND_CONDITIONS = 'UPDATED_TERMS_AND_CONDITIONS',
  DOCUMENTS_REQUIRED = 'DOCUMENTS_REQUIRED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DEACTIVATED = 'DEACTIVATED',
  SUSPENDED = 'SUSPENDED',
  SUSPENDED_UPDATED_TCS_NOT_ACCEPTED = 'SUSPENDED_UPDATED_TCS_NOT_ACCEPTED',
}

export const complianceStatusLabels: Record<ComplianceStatus, string> = {
  [ComplianceStatus.UNKNOWN]: 'Unknown',
  [ComplianceStatus.PENDING]: 'Pending Questionnaire',
  [ComplianceStatus.TERMS_AND_CONDITIONS]: 'T&Cs pending',
  [ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS]: 'Updated T&Cs outstanding',
  [ComplianceStatus.DOCUMENTS_REQUIRED]: 'Documents Required',
  [ComplianceStatus.SUBMITTED]: 'Ready for Review',
  [ComplianceStatus.APPROVED]: 'Approved',
  [ComplianceStatus.REJECTED]: 'Rejected',
  [ComplianceStatus.DEACTIVATED]: 'Deactivated',
  [ComplianceStatus.SUSPENDED]: 'Suspended',
  [ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED]: 'Suspended - T&Cs outstanding',
};

export enum ComplianceFilterStatus {
  ALL = 'ALL',
  SUBMITTED = 'SUBMITTED',
  DOCUMENTS_REQUIRED = 'DOCUMENTS_REQUIRED',
  PENDING = 'PENDING',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  UPDATED_TERMS_AND_CONDITIONS = 'UPDATED_TERMS_AND_CONDITIONS',
  SUSPENDED_UPDATED_TCS_NOT_ACCEPTED = 'SUSPENDED_UPDATED_TCS_NOT_ACCEPTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DEACTIVATED = 'DEACTIVATED',
  SUSPENDED = 'SUSPENDED',
}

export const onboardingStatuses: ComplianceStatus[] = [
  ComplianceStatus.SUBMITTED,
  ComplianceStatus.DOCUMENTS_REQUIRED,
  ComplianceStatus.PENDING,
  ComplianceStatus.TERMS_AND_CONDITIONS,
  ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED,
];

export const complianceFilterStatusLabels: Record<ComplianceFilterStatus, string> = {
  [ComplianceFilterStatus.ALL]: 'All Cases',
  [ComplianceFilterStatus.SUBMITTED]: 'Ready for Review',
  [ComplianceFilterStatus.DOCUMENTS_REQUIRED]: 'Documents Required',
  [ComplianceFilterStatus.PENDING]: 'Pending Questionnarie',
  [ComplianceFilterStatus.TERMS_AND_CONDITIONS]: 'T&Cs pending',
  [ComplianceFilterStatus.UPDATED_TERMS_AND_CONDITIONS]: 'Updated T&Cs outstanding',
  [ComplianceFilterStatus.APPROVED]: 'Approved',
  [ComplianceFilterStatus.REJECTED]: 'Rejected',
  [ComplianceFilterStatus.DEACTIVATED]: 'Deactivated',
  [ComplianceFilterStatus.SUSPENDED]: 'Suspended',
  [ComplianceFilterStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED]: 'Suspended - T&C Outstanding',
};

export interface FileStatusResponse {
  documentUploadStatus: ComplianceFilesStatus;
}

export enum ComplianceFilesStatus {
  NOT_UPLOADED = 'NOT_UPLOADED',
  SKIPPED = 'SKIPPED',
  UPLOADED = 'UPLOADED',
}

export const complianceFileStatusLabels: Record<ComplianceFilesStatus, string> = {
  [ComplianceFilesStatus.NOT_UPLOADED]: 'Not uploaded',
  [ComplianceFilesStatus.SKIPPED]: 'Not uploaded',
  [ComplianceFilesStatus.UPLOADED]: 'Uploaded',
};

export enum AboutUsReference {
  INTERNET = 'Internet Search (Google, Bing, Yahoo, etc.)',
  SOCIALS = 'Social Media (Facebook, Twitter, Instagram, LinkedIn)',
  REFERRAL = 'Referral',
  AD = 'Online Ad (Google Ads, Banner Ads)',
  MARKETING = 'Email Marketing',
  CALL = 'Received a Call from us',
  OTHER = 'Other (please specify)',
}

export const aboutUsReferenceOptions: FieldOptionObj[] = [
  { label: AboutUsReference.INTERNET, value: AboutUsReference.INTERNET },
  { label: AboutUsReference.SOCIALS, value: AboutUsReference.SOCIALS },
  { label: AboutUsReference.REFERRAL, value: AboutUsReference.REFERRAL },
  { label: AboutUsReference.AD, value: AboutUsReference.AD },
  { label: AboutUsReference.MARKETING, value: AboutUsReference.MARKETING },
  { label: AboutUsReference.CALL, value: AboutUsReference.CALL },
  { label: AboutUsReference.OTHER, value: AboutUsReference.OTHER },
];

export enum EmirClassification {
  NFC = 'NFC',
  FC = 'FC',
}

export const emirClassificationOptions: FieldOptionObj[] = [
  { label: 'NFC', value: EmirClassification.NFC },
  { label: 'FC', value: EmirClassification.FC },
];

export const emirClassificationOptionsFullLabel: FieldOptionObj[] = [
  { label: 'NFC - Non-Financial Counterparty', value: EmirClassification.NFC },
  { label: 'FC - Financial Counterparty', value: EmirClassification.FC },
];

export enum SmartSearchStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  ABANDONED = 'ABANDONED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export const smartSearchStatusLabels: Record<SmartSearchStatus, string> = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  COMPLETE: 'Complete',
  ABANDONED: 'Abandoned',
  FAILED: 'Failed',
  EXPIRED: 'Expired',
};

export enum SmartSearchPassFail {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export const smartSearchPassFailLabels: Record<SmartSearchPassFail, string> = {
  PASS: 'Pass',
  FAIL: 'Fail',
};

export enum SmartSearchOutcome {
  PENDING = 'PENDING',
  PASS = 'PASS',
  REFER = 'REFER',
  FAIL = 'FAIL',
  ERROR = 'ERROR',
}

export const smartSearchOutcomeLabels: Record<SmartSearchOutcome, string> = {
  PENDING: 'Pending',
  PASS: 'Pass',
  REFER: 'Refer',
  FAIL: 'Fail',
  ERROR: 'Error',
};

export interface PreferredBankingProvider {
  preferredBankingProvider?: string;
}
