<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { Validation } from '@vuelidate/core';
import {
  Client,
  FxMargins,
  HedgingInstrumentsSettingsProps,
  hedgingInstrumentSettingPropToHuman,
  FxMarginsConfiguration,
} from 'ah-api-gateways';
import { submitForm } from 'ah-common-lib/src/form/helpers';
import ClientProfitMarginForm from '@/app/components/clients/ClientProfitMarginForm.vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import Vue, { computed, PropType, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useToast } from 'ah-common-lib/src/toast';

const emit = defineEmits({
  'update:client-settings': (_clientSettings: FxMargins) => true,
});

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
  settings: {
    type: Object as PropType<FxMargins>,
    required: true,
  },
});

const services = getServices();

const toast = useToast();

const modal = ref<InstanceType<typeof BModal>>();

const editedSettings = ref<FxMargins>({ ...props.settings });

const marginFormValidations = ref<Partial<Record<HedgingInstrumentsSettingsProps, FormValidation>>>({});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'saveModel') {
      save();
    }
    if (k === 'getClientSettings') {
      loadSettings();
    }
  },
}).manager;

const profitKeys = computed(() => HedgingInstrumentsSettingsProps);

const labels = computed(() => hedgingInstrumentSettingPropToHuman);

function onUpdateMargins(key: HedgingInstrumentsSettingsProps, value: FxMarginsConfiguration) {
  if (editedSettings.value) {
    Vue.set(editedSettings.value, key, value);
  }
}

function onUpdateValidation(key: HedgingInstrumentsSettingsProps, value: Validation) {
  Vue.set(marginFormValidations.value, key, value);
}

function testForm() {
  let valid = true;

  Object.keys(marginFormValidations.value).forEach((key) => {
    const validation = marginFormValidations.value[key as HedgingInstrumentsSettingsProps];
    if (validation) {
      submitForm(validation);
      if (validation.$invalid) {
        valid = false;
      }
    }
  });

  return valid;
}

function save() {
  if (!testForm() || !editedSettings.value) {
    toast.error('Form contains errors, please review values entered.');
    return false;
  }
  requestManager
    .cancelAndNew(
      'saveModel',
      services.spreads.updateClientSpreads(props.client.id!, {
        ...(editedSettings.value as any),
      })
    )
    .subscribe((settings) => {
      emit('update:client-settings', settings);
      toast.success('Client Margin Settings updated.');
      modal.value?.hide();
    });
}

function loadSettings() {
  requestManager
    .sameOrCancelAndNew('getClientSettings', services.spreads.getClientSpreads(props.client.id), props.client.id)
    .subscribe((settings) => {
      editedSettings.value = settings;
    });
}

function show() {
  modal.value?.show();
  loadSettings();
}

function cancel() {
  modal.value?.hide();
}
</script>

<template>
  <span>
    <BModal title="Edit markup" modal-class="side-modal modal-md" ref="modal" hide-footer>
      <div class="card-block mb-3" v-if="editedSettings">
        <VRow>
          <VCol>
            <h3>Percentage</h3>
            <ClientProfitMarginForm
              v-for="profitKey in profitKeys"
              :key="profitKey"
              :profitKey="profitKey"
              :model="editedSettings"
              :client="client"
              :title="labels[profitKey]"
              unit="percentage"
              :minValue="editedSettings.minProfit"
              :maxValue="editedSettings.maxProfit"
              @update:model="onUpdateMargins(profitKey, $event)"
              @update:validation="onUpdateValidation(profitKey, $event)"
            />
          </VCol>
          <VCol>
            <h3>Basis Points</h3>
            <ClientProfitMarginForm
              v-for="profitKey in profitKeys"
              :key="profitKey"
              :profitKey="profitKey"
              :model="editedSettings"
              :client="client"
              :title="labels[profitKey]"
              unit="basisPoints"
              :minValue="editedSettings.minProfit"
              :maxValue="editedSettings.maxProfit"
              @update:model="onUpdateMargins(profitKey, $event)"
              @update:validation="onUpdateValidation(profitKey, $event)"
            />
          </VCol>
        </VRow>
      </div>
      <div class="buttons mt-5 text-md-left text-sm-center">
        <VButton class="btn-secondary mr-2 mb-3" @click="cancel">Cancel</VButton>
        <VButton class="done-btn btn-success" :loading="requestManager.anyPending" @click="save">Save Changes</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<style lang="scss" scoped>
.form-title {
  .errors,
  .edited {
    font-style: italic;
    font-weight: 200;
  }

  .errors {
    @include themedTextColor($color-danger);
  }

  .edited {
    @include themedTextColor($color-text-secondary);
  }
}
</style>
