import { radioField, selectField, textField } from 'ah-common-lib/src/form/models/commonFields';
import countries from 'ah-common-lib/src/constants/countries';
import { BeneficiaryIdentificationType, beneficiaryIdentificationTypeLabels, BeneficiaryType } from 'ah-api-gateways';
import { Field, Form } from 'ah-common-lib/src/form/interfaces';

function baseBeneficiaryFields(): Field[] {
  return [
    selectField('identificationType', 'Identification Type', [
      ...Object.keys(beneficiaryIdentificationTypeLabels).map((k) => ({
        value: k,
        label: beneficiaryIdentificationTypeLabels[k as BeneficiaryIdentificationType],
      })),
    ]),
    textField('identificationValue', 'Identification Number'),
  ];
}

export function beneficiaryTypeForm(): Form {
  return {
    name: 'beneficiaryTypeForm',
    state: {
      title: 'Beneficiary details',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: true,
    },
    fieldType: 'form',
    fields: [
      radioField(
        'type',
        'Type',
        [
          { label: 'Business', value: BeneficiaryType.COMPANY },
          { label: 'Individual', value: BeneficiaryType.INDIVIDUAL },
        ],
        {
          defaultValue: BeneficiaryType.COMPANY,
          inline: true,
        }
      ),
      selectField('countryCode', 'Country', countries, {
        required: true,
      }),
    ],
  };
}

export function individualDetailsForm(allowNameEdit = true): Form {
  return {
    name: 'individualDetails',
    state: {
      title: 'Beneficiary details',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: true,
    },
    fieldType: 'form',
    fields: [
      textField('firstName', 'First name', {
        fieldWrapperClass: 'col col-6',
        required: true,
        readonly: !allowNameEdit,
      }),
      textField('lastName', 'Last name', {
        fieldWrapperClass: 'col col-6',
        required: true,
        readonly: !allowNameEdit,
      }),
      ...baseBeneficiaryFields(),
    ],
  };
}

export function companyDetailsForm(allowNameEdit = true): Form {
  return {
    name: 'individualDetails',
    state: {
      title: 'Beneficiary details',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: true,
    },
    fieldType: 'form',
    fields: [
      textField('name', 'Business name', {
        required: true,
        readonly: !allowNameEdit,
      }),
      selectField('entityType', 'Entity type', [], {
        required: true,
      }),
      ...baseBeneficiaryFields(),
    ],
  };
}
