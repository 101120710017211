import { render, staticRenderFns } from "./PartnerInformationSettingsView.vue?vue&type=template&id=4a73699a&scoped=true"
import script from "./PartnerInformationSettingsView.vue?vue&type=script&setup=true&lang=ts"
export * from "./PartnerInformationSettingsView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PartnerInformationSettingsView.vue?vue&type=style&index=0&id=4a73699a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a73699a",
  null
  
)

export default component.exports