<script setup lang="ts">
import {
  beneficiaryTableFields,
  shortBeneficiaryTableFields,
} from 'ah-beneficiaries/src/models/beneficiaryTableFields';
import BeneficiaryListing from 'ah-beneficiaries/src/components/BeneficiaryListing.vue';
import BeneficiaryApproval from 'ah-beneficiaries/src/components/BeneficiaryApproval.vue';
import BeneficiaryEditModal from 'ah-beneficiaries/src/components/BeneficiaryEditModal.vue';
import SearchInput from '@/app/components/common/form/SearchInput.vue';
import BeneficiaryInfo from 'ah-beneficiaries/src/components/BeneficiaryInfo.vue';
import { Beneficiary, BeneficiaryCategory, BeneficiaryStatus, Client, PaginatedParams } from 'ah-api-gateways';
import { AnimatedListingWithSubViewState } from '@/app/components/common/animation/AnimatedListingWithSubView.vue';
import { editRoute } from 'ah-common-lib/src/helpers/route';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { useAuthStore } from '@/app/store/authStore';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { TableFieldDefinition } from 'ah-common-lib/src/models';

const props = defineProps({
  clientId: { type: String, required: false },
  beneficiaryId: { type: String, required: false, default: '' },
});

const requestManager = useRequestManager().manager;

const authStore = useAuthStore();

const route = useRoute();

const router = useRouter();

const services = getServices();

const beneficiaryApproval = ref<InstanceType<typeof BeneficiaryApproval>>();

const beneficiaryListing = ref<InstanceType<typeof BeneficiaryListing>>();

const sortAndPageParams = ref<Partial<PaginatedParams>>({ sort: 'name', sortDirection: 'ASC' });

const onBehalfOfClient = ref<Client>();

const animatedState = ref<AnimatedListingWithSubViewState | null>(null);

const query = ref<String>('');

const filter = computed(() => {
  return {
    query: query.value,
    category: authStore.isAgent ? BeneficiaryCategory.PARTNER_3RD_PARTY : BeneficiaryCategory.CLIENT_3RD_PARTY,
    status: [BeneficiaryStatus.APPROVED, BeneficiaryStatus.PENDING],
  };
});

const readonly = computed(() => route.meta?.readonly || false);
const isOBOAction = computed(() => !readonly.value && isAgent.value && onBehalfOfClient.value);
const isAgent = computed(() => authStore.isAgent);

onBeforeMount(() => {
  animatedState.value = {
    wideList: !props.beneficiaryId,
    showInfo: !props.beneficiaryId,
  };
});

function getOBOClient() {
  if (props.clientId) {
    requestManager.currentOrNew('loadClient', services.client.getClient(props.clientId)).subscribe((response) => {
      onBehalfOfClient.value = response;
    });
  }
}

watch(() => props.clientId, getOBOClient, { immediate: true });

const beneficiaryTableConfig = computed(() => {
  return { tableFields: beneficiaryTableFields };
});

const beneficiaryListingConfig = computed<{ tableFields: TableFieldDefinition<any>[] }>(() =>
  !animatedState.value?.wideList ? shortBeneficiaryTableConfig.value : beneficiaryTableConfig.value
);

const shortBeneficiaryTableConfig = computed(() => {
  return { tableFields: shortBeneficiaryTableFields };
});

const fields = computed(() => beneficiaryTableConfig.value.tableFields);
const isPayments = computed(() => route.fullPath.includes('/payment'));

function goToBeneficiary(beneficiary?: Beneficiary) {
  const newRoute = editRoute(route, {
    params: {
      beneficiaryId: !beneficiary || props.beneficiaryId === beneficiary.id ? '' : beneficiary.id,
      clientId: props.clientId,
    } as any,
  });
  router.push(newRoute);
}

function onBeneficiaryAdded(beneficiary: Beneficiary) {
  onBeneficiaryUpdated();
  goToBeneficiary(beneficiary);
}

function onBeneficiaryUpdated() {
  beneficiaryListing.value!.loadData();
}

function onBeneficiaryDeleted() {
  goToBeneficiary();
  beneficiaryListing.value!.loadData();
}

function goBack() {
  router.push('/admin/activity/clients/obo/' + props.clientId);
}
</script>

<template>
  <OnBehalfOf :client="onBehalfOfClient || undefined">
    <LoadingOverlay
      :state="requestManager.requestStates.loadClient"
      show-retry
      @retry="getOBOClient"
      class="beneficiaries-overlay"
    >
      <div class="padded-space" id="beneficiaries-view" v-if="!clientId || onBehalfOfClient">
        <!-- TITLE -->
        <div class="d-flex">
          <div class="mr-4">
            <VRow class="mx-0" align-v="center">
              <h2 class="mb-0">{{ isPayments ? 'Payments' : 'Beneficiaries' }}</h2>
            </VRow>
            <BackButton v-if="isOBOAction" label="go back" @click="goBack()" small />
          </div>
          <div class="mb-3" v-if="!isPayments && !readonly">
            <div class="d-inline float-lg-none float-sm-right">
              <BeneficiaryEditModal
                v-slot="{ showModal }"
                @update:beneficiary="onBeneficiaryAdded"
                :client="onBehalfOfClient"
              >
                <VButton @click="showModal" class="mr-2">Add beneficiary</VButton>
              </BeneficiaryEditModal>
            </div>
          </div>
        </div>
        <BeneficiaryApproval
          ref="beneficiaryApproval"
          v-if="!isAgent"
          :selectedItems="[beneficiaryId]"
          @row-clicked="goToBeneficiary"
        />
        <AnimatedListingWithSubView
          :wideListBlockProps="{ cols: 12, lg: 12, xl: 6 }"
          :narrowListBlockProps="{ cols: 3, lg: 3, xl: 3 }"
          :infoBlockProps="{ cols: 9, sm: 12, lg: 9, xl: 9 }"
          :showInfo="!!beneficiaryId"
          :animatedState.sync="animatedState"
          :listingBlockClass="animatedState && !animatedState.wideList ? 'd-sm-none d-lg-block' : 'd-block'"
        >
          <template #listing>
            <BackButton
              class="back-to-list"
              @click="goToBeneficiary()"
              v-if="animatedState && !animatedState.wideList"
              :narrow="$mediaQuery.is('mdDown')"
            />

            <div class="d-flex flex-column">
              <ExpandTransition>
                <SearchInput
                  v-if="animatedState && animatedState.wideList"
                  :search.sync="query"
                  :placeholder="'Search by beneficiary name'"
                  class="col-12 col-md-6 align-self-end"
                />
              </ExpandTransition>
              <SortSelector
                :fields="fields"
                v-if="$mediaQuery.is('smDown')"
                class="w-100"
                :sortAndPageParams.sync="sortAndPageParams"
              />
              <BeneficiaryListing
                ref="beneficiaryListing"
                withRowSubView
                animateCols
                :filter="filter"
                :sortAndPageParams.sync="sortAndPageParams"
                :config="beneficiaryListingConfig"
                @row-clicked="goToBeneficiary"
                :selectedItems="[beneficiaryId]"
                :clientId="clientId"
              />
            </div>
          </template>
          <template #sub-view>
            <BeneficiaryInfo
              :beneficiary-id="beneficiaryId"
              :clientId="clientId"
              @update:beneficiary="onBeneficiaryUpdated"
              @beneficiary-deleted="onBeneficiaryDeleted"
              @back-to-list="goToBeneficiary()"
              :readonly="readonly"
            />
          </template>
        </AnimatedListingWithSubView>
      </div>
    </LoadingOverlay>
  </OnBehalfOf>
</template>

<style lang="scss" scoped>
.shrink {
  min-width: 15em;
}

.back-to-list {
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 1;
}

.client-name,
.back-button {
  @include themedTextColor($color-dark-primary, $color-primary);
}
</style>
