<script setup lang="ts">
import { PaymentRail, routingCodeTypesLabels, WalletFundingDetails, WalletRoutingCodeType } from 'ah-api-gateways';
import { useAhWalletsState } from '..';
import { computed, onMounted } from 'vue';
import DataRow from 'ah-common-lib/src/common/components/DataRow.vue';

const props = defineProps<{
  fundingDetails: WalletFundingDetails;
}>();

const walletState = useAhWalletsState();

onMounted(() => walletState.store.useSettingsStore().loadCountries());

const countryName = computed(() => {
  const country = walletState.store
    .useSettingsStore()
    .allCountries.find((c) => c.cc === props.fundingDetails.bankCountry);

  return country?.name ?? '';
});
</script>

<template>
  <div>
    <DataRow label="Account Holder" class="account-holder">
      {{ fundingDetails.accountHolderName }}
    </DataRow>
    <DataRow label="Bank" class="account-bank">
      {{ fundingDetails.bankName }}
    </DataRow>
    <DataRow label="Bank Address" class="account-bank-address">
      {{ fundingDetails.bankAddress }}
      <template v-if="countryName">
        <br />
        {{ countryName }}
      </template>
    </DataRow>
    <DataRow label="IBAN" v-if="fundingDetails.iban" class="account-iban">
      {{ fundingDetails.iban }}
    </DataRow>
    <DataRow
      label="Account number"
      v-if="fundingDetails.accountNumber && fundingDetails.accountNumber !== fundingDetails.iban"
      class="account-number"
    >
      {{ fundingDetails.accountNumber }}
    </DataRow>
    <DataRow
      v-if="fundingDetails.paymentRail === PaymentRail.SWIFT"
      :label="routingCodeTypesLabels[WalletRoutingCodeType.BIC_SWIFT]"
      class="account-bic-swift"
    >
      {{ fundingDetails.bicSwift }}
    </DataRow>
    <DataRow v-else :label="routingCodeTypesLabels[fundingDetails.routingCodeType]" class="account-routing-code">
      {{ fundingDetails.routingCode }}
    </DataRow>
  </div>
</template>
