<script setup lang="ts">
import { IndividualGroup } from 'ah-api-gateways';
import GroupListing from '@/app/components/groups/GroupListing.vue';
import GroupInfo from '@/app/components/groups/GroupInfo.vue';
import { AnimatedListingWithSubViewState } from '@/app/components/common/animation/AnimatedListingWithSubView.vue';
import { groupTableFieldsFull, groupTableFieldsMinimal } from '@/app/components/groups/tableFields/groupTableFields';
import GroupEditModal from '@/app/components/groups/GroupEditModal.vue';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import { editRoute } from 'ah-common-lib/src/helpers/route';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';

const props = defineProps({
  groupId: {
    type: String,
    required: false,
  },
});

const mediaQuery = useMediaQuery();

const groupListing = ref<InstanceType<typeof GroupListing> | null>(null);

const route = useRoute();

const router = useRouter();

const animatedState = ref<AnimatedListingWithSubViewState>();

const groupTableConfig = computed(() =>
  mediaQuery.is('mdDown') && !animatedState.value?.wideList
    ? { tableFields: groupTableFieldsMinimal }
    : { tableFields: groupTableFieldsFull }
);

function goToGroup(group?: IndividualGroup, replace = false) {
  const groupRoute = editRoute(route, {
    params: {
      groupId: !group || props.groupId === group.id ? '' : group.id,
    },
  });
  router[replace ? 'replace' : 'push'](groupRoute);
}

function onGroupDeleted() {
  goToGroup(undefined, true);
  refreshGroups();
}

function refreshGroups() {
  if (groupListing.value) {
    groupListing.value.loadData();
  }
}
</script>

<template>
  <div class="padded-space" id="groups-settings-view">
    <div class="centered-space">
      <h2>Groups <InfoTooltip :text="$t('tooltips.groups')" /></h2>
      <GroupEditModal v-slot="{ show }" @update:group="refreshGroups">
        <VButton class="add-group-button" blurOnClick @click="show">Add Group</VButton>
      </GroupEditModal>
      <BackButton
        class="d-block mb-0 back-button-mobile"
        @click="goToGroup()"
        v-if="groupId && $mediaQuery.is('mdDown')"
        small
      />
      <AnimatedListingWithSubView
        :wideListBlockProps="{ cols: 8, lg: 8, sm: 12 }"
        :narrowListBlockProps="{ cols: 4, lg: 6, sm: 6 }"
        :infoBlockProps="{ cols: 8, lg: 6, sm: 12 }"
        :showInfo="!!groupId"
        :animatedState.sync="animatedState"
        :listingBlockClass="groupId ? 'd-sm-none d-lg-block' : 'd-block'"
      >
        <template #listing="{ animatedState }">
          <BackButton
            class="back-to-list"
            @click="goToGroup()"
            v-if="!animatedState.wideList && $mediaQuery.is('lgUp')"
            small
          />
          <GroupListing
            ref="groupListing"
            :config="groupTableConfig"
            showEmptyHeaders
            withRowSubView
            @row-clicked="goToGroup"
            :selectedItems="[groupId]"
          />
        </template>
        <template #sub-view>
          <GroupInfo :groupId="groupId" @update:group="refreshGroups" @group-deleted="onGroupDeleted" />
        </template>
      </AnimatedListingWithSubView>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.centered-space {
  position: relative;
}

.add-group-button {
  position: absolute;
  top: 0em;
  right: 0;
}

.back-to-list {
  position: absolute;
  top: 16px;
  right: 10px;
  z-index: 1;
}

.back-button-mobile {
  margin-top: -1rem;
  ::v-deep * {
    font-size: $font-size-xs;
  }
}
</style>
