<script setup lang="ts">
import { ref, computed, reactive, watch } from 'vue';
import ValidatedForm from 'ah-common-lib/src/form/components/formComponents/ValidatedForm.vue';
import OtpRequestForm from 'ah-common-lib/src/otp/OtpRequestForm.vue';
import { getChildModel, makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { SecurityErrorCodes } from 'ah-api-gateways';
import type { FormDefinition, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import ValidationMessage from '../common/ValidationMessage.vue';
import { useUsernameValidation } from '../registration/forms/common/useUsernameValidation';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { mergeMap } from 'rxjs/operators';
import { HttpError } from 'ah-requests';

const props = defineProps({
  currentUsername: {
    type: String,
    default: undefined,
  },
  tfaEnabled: {
    type: Boolean,
    default: false,
  },
  staticOTP: {
    type: Boolean,
    default: false,
  },
  currentPhoneLastDigits: {
    type: String,
    default: '',
  },
  fieldTitle: {
    type: String,
    default: 'Username',
  },
});

const emit = defineEmits({
  success: () => true,
  cancel: () => true,
  error: (_payload: HttpError) => true,
});

const services = getServices();
const toast = useToast();
const requestManager = useRequestManager().manager;
const authStore = useAuthStore();

const otp = ref('');
const otpValidation = ref<FormValidation>();
const changeOtpRequest = ref<InstanceType<typeof OtpRequestForm>>();

const usernameFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'usernameForm',
    fieldType: 'form',
    fields: [
      textField('username', props.fieldTitle, {
        required: true,
        maxLength: 30,
        minLength: 5,
      }),
    ],
  }),
  validation: null,
});

const {
  validation: usernameValidation,
  validationMessage,
  validationState,
  validateUsername,
} = useUsernameValidation({ currentUsername: props.currentUsername });

const isNewUsernameInvalid = computed(() => {
  if (!usernameFormDef.validation) {
    return true;
  }
  return usernameFormDef.validation.$invalid || usernameValidation.$invalid;
});

const anyInvalid = computed(() => {
  if (!otpValidation.value || !usernameFormDef.validation) {
    return true;
  }
  return otpValidation.value.$invalid || usernameFormDef.validation.$invalid || usernameValidation.$invalid;
});

const onFormEvent = (formEvent: FormEvent) => {
  if (formEvent.event === 'form-field-blur') {
    setState(formEvent.model, 'errors', []);

    if (formEvent.model.$name === 'username') {
      validateUsername(usernameFormDef.form.username);
    }
  }

  if (formEvent.event === 'form-field-set-value' && formEvent.model.$name === 'username') {
    validateUsername(usernameFormDef.form.username);
  }
};

const refreshOtpRequest = () => services.auth.refreshOtp();

const handleSubmit = () => {
  let request = services.account.changeUsername(
    usernameFormDef.form.username,
    props.tfaEnabled ? otp.value : undefined,
    {
      errors: { silent: true },
    }
  );

  requestManager
    .sameOrCancelAndNew('changeUsername', request.pipe(mergeMap(() => services.auth.getSession())))
    .subscribe(
      (session) => {
        authStore.setTokenUser(session);

        if (usernameFormDef.form.username === session.username) {
          toast.success('', {
            message: `Your username is now <strong>${session.usernamePresentation}</strong> <br> If needed, you can change your username on your account settings page.`,
            isHtml: true,
            title: '',
          });
        } else {
          toast.error('Username change failed. Please try again later.');
        }
        emit('success');
      },
      (error) => {
        if ([SecurityErrorCodes.INVALID_OTP, SecurityErrorCodes.EXPIRED_OTP].includes(error.response?.data?.code)) {
          changeOtpRequest.value?.setOtpError(
            error.response?.data?.code === SecurityErrorCodes.INVALID_OTP ? 'invalid' : 'expired'
          );
        } else if (error.response?.status === 409 || error.response?.status === 400) {
          toast.info(error.response.data.message);
        } else {
          toast.error('An unexpected problem has occurred. Please try again later.');
        }
        emit('error', error);
      }
    );
};

watch(
  () => usernameValidation.$invalid,
  () => {
    const usernameField = getChildModel(usernameFormDef.form, 'username')!;
    setState(usernameField, 'errors', usernameValidation.$invalid ? [{ name: 'usernameError', error: '' }] : []);
  }
);
</script>

<template>
  <div class="username-change-form">
    <ValidatedForm :fm="usernameFormDef.form" :validation.sync="usernameFormDef.validation" @form-event="onFormEvent" />
    <div v-if="usernameFormDef.form.username" class="mb-3">
      <ValidationMessage :message="validationMessage" :state="validationState" />
    </div>
    <OtpRequestForm
      v-if="tfaEnabled"
      ref="changeOtpRequest"
      class="mb-5"
      title="For security reasons, before confirming your new username we must verify your request."
      :otp.sync="otp"
      :staticOTP="staticOTP"
      :otpPhone="currentPhoneLastDigits"
      :refreshOtpRequest="refreshOtpRequest"
      :validation.sync="otpValidation"
      :forbidOtpRequest="isNewUsernameInvalid"
    />
    <slot name="actions" :is-invalid="anyInvalid" :on-cancel="() => emit('cancel')" :on-submit="handleSubmit">
      <p class="text-center mb-0">
        <VButton @click="emit('cancel')" class="btn-secondary mr-3">Cancel</VButton>
        <VButton :disabled="anyInvalid" :loading="requestManager.anyPending" @click="handleSubmit"> Confirm </VButton>
      </p>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .field-group-wrapper {
  margin-bottom: 8px;
}
</style>
