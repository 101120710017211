import { addressForm } from '../general/addressForm';
import { Form } from '../../interfaces';
import { textField, selectField } from '../../models';

export function companyDetailsForm(allRequired = true, countryEditable = true): Form {
  const address = addressForm('address', '', {}, allRequired, countryEditable);

  const addressLine = address.fields.find((f) => f.name === 'addressLine');
  if (addressLine) {
    addressLine.state = {
      ...addressLine.state,
      title: 'Head office address',
    };
  }
  const countryCode = address.fields.find((f) => f.name === 'countryCode');
  if (countryCode) {
    countryCode.state = {
      ...countryCode.state,
      title: 'Country of incorporation',
    };
  }
  const requiredMessages = allRequired
    ? {
        requiredText: '* All fields are mandatory.',
      }
    : {};

  return {
    name: 'companyDetails',
    state: {
      title: 'Company Details',
      class: 'form-narrow margin-auto',
      showRequiredMarkers: false,
      ...requiredMessages,
    },
    fieldType: 'form',
    fields: [
      textField('name', 'Name of entity', {
        titleTooltip: 'As given on the company’s certificate of incorporation.',
        required: allRequired,
      }),
      textField('registrationNumber', 'Registration Number', {
        required: allRequired,
      }),
      address,
      selectField('entityType', 'Entity type', [], {
        required: allRequired,
      }),
    ],
  };
}

export function companyDetailsReadForm(): Form {
  const commonState = {
    readonly: true,
    fieldWrapperClass: 'col col-6',
    inputGroupClass: 'readonly-field',
    placeholder: '-',
  };

  return {
    name: 'companyDetails',
    state: {
      class: 'form-narrow margin-auto',
      showRequiredMarkers: false,
    },
    fieldType: 'form',
    fields: [
      textField('name', 'Company Name', commonState, {}),
      textField('entityType', 'Company Type', commonState, {}),
      textField('countryCode', 'Country of incorporation', commonState, {}),
      textField('sectorDescription', 'Sector', commonState, {}),
      textField('registrationNumber', 'Registration number', commonState, {}),
      textField('reportingCurrency', 'Reporting Currency', commonState, {}),
    ],
  };
}

export function addressReadForm(editable = false): Form {
  const commonState = {
    required: editable,
    readonly: !editable,
    fieldWrapperClass: 'col col-12',
    inputGroupClass: !editable ? 'readonly-field' : '',
    placeholder: '-',
  };
  return {
    name: 'addressDetails',
    state: {
      class: 'form-narrow margin-auto',
      showRequiredMarkers: false,
    },
    fieldType: 'form',
    fields: [
      textField('addressLine', 'Street', commonState, {}),
      textField(
        'postalCode',
        'Postcode / ZIP code',
        {
          ...commonState,
          fieldWrapperClass: 'col col-6',
        },
        {}
      ),
      textField(
        'city',
        'City',
        {
          ...commonState,
          fieldWrapperClass: 'col col-6',
        },
        {}
      ),
      textField('stateOrProvince', 'State/Province/County', commonState, {}),
    ],
  };
}
