import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const outstandingFeesTableFields: TableFieldDefinition[] = [
  {
    key: 'clientName',
    header: 'Client',
    sortable: true,
  },
  { key: 'partnerName', header: 'Partner', sortable: true },
  {
    key: 'feesAccrued',
    header: 'Fees accrued',
    tdClass: 'text-right',
    thClass: 'text-right',
    sortable: true,
  },
  {
    key: 'feesPaid',
    header: 'Fees Paid',
    tdClass: 'text-right',
    thClass: 'text-right',
    sortable: true,
  },
  { key: 'feesOutstanding', header: 'Fees outstanding', tdClass: 'text-right', thClass: 'text-right', sortable: true },
  { key: 'feeStatus', header: 'Fee status', tdClass: 'text-center', thClass: 'text-center', sortable: true },
];

export const clientOutstandingFeesTableFields: TableFieldDefinition[] = [
  {
    key: 'clientName',
    header: 'Client',
    tdClass: 'text-md-left text-sm-right',
    sortable: true,
  },
  {
    key: 'feesAccrued',
    header: 'Fees accrued',
    tdClass: 'text-right',
    thClass: 'text-right',
    sortable: true,
  },
  {
    key: 'feesPaid',
    header: 'Fees Paid',
    tdClass: 'text-right',
    thClass: 'text-right',
    sortable: true,
  },
  { key: 'feesOutstanding', header: 'Fees outstanding', tdClass: 'text-right', thClass: 'text-right', sortable: true },
  {
    key: 'feeStatus',
    header: 'Fee status',
    tdClass: 'text-md-center text-sm-right',
    thClass: 'text-center',
    sortable: true,
  },
  { key: 'reminder', header: '' },
];
