import { BrandingImageType, PartnerBrandingData, PartnerBrandingImage, PartnerColorSheme } from '../models/branding';
import { Observable } from 'rxjs';
import { HttpBasedService, HttpRequestOptions, HttpService, uploadFileWithUpdates } from 'ah-requests';
import { downloadFile } from 'ah-requests/helpers/download';
import { map } from 'rxjs/operators';

export class BrandingService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'brandingService' } },
      },
    });
  }

  public getPartnerColorSchemes(
    partnerId: string,
    opts?: Partial<HttpRequestOptions<PartnerColorSheme[]>>
  ): Observable<PartnerColorSheme[]> {
    return this.get<PartnerColorSheme[]>(`${this.baseUrl}brands/${partnerId}/schemes`, opts);
  }

  public setPartnerColorSchemes(
    partnerId: string,
    schemes: PartnerColorSheme[],
    opts?: Partial<HttpRequestOptions<PartnerColorSheme[]>>
  ): Observable<PartnerColorSheme[]> {
    return this.put<PartnerColorSheme[]>(`${this.baseUrl}brands/${partnerId}/schemes`, schemes, opts);
  }

  public getPartnerBrandingData(
    partnerId?: string,
    opts?: Partial<HttpRequestOptions<PartnerBrandingData>>
  ): Observable<PartnerBrandingData> {
    return this.get<PartnerBrandingData>(`${this.baseUrl}brands/${partnerId ?? ''}`, opts);
  }

  public deletePartnerLogo(
    partnerId: string,
    logoId: string,
    opts?: Partial<HttpRequestOptions<void>>
  ): Observable<void> {
    return this.delete<void>(`${this.baseUrl}brands/${partnerId}/logos/${logoId}`, opts);
  }

  public uploadPartnerLogo(partnerId: string, type: BrandingImageType, file: File) {
    const formData = new FormData();

    formData.append('logo', file);

    return uploadFileWithUpdates<PartnerBrandingImage>(
      {
        axiosConfig: {
          url: `${this.baseUrl}brands/${partnerId}/logos/${type}`,
          method: 'POST',
          data: formData,
        },
      },
      (c) => this.request(c)
    );
  }

  public downloadSyncPartnerLogo(partnerId: string, logoId: string) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: `${this.baseUrl}brands/${partnerId}/logos/${logoId}`,
      },
    }).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  public updatePartnerBrandingData(partnerId: string, partnerBrandingData: PartnerBrandingData) {
    return this.put<PartnerBrandingData>(`${this.baseUrl}brands/${partnerId}`, partnerBrandingData);
  }
}
