import { PaginatedQuery, PaginatedResponse, standardiseQuerySort } from '../models';
import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';
import { PlaidLinkTokenData } from '../models/integrations/plaid';
import { ClientIntegration, ClientIntegrationFilters } from '../models/integrations/integration';

export class IntegrationsService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'integrationsService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public requestPlaidLinkToken(options?: HttpOptions<PlaidLinkTokenData>) {
    return this.post<PlaidLinkTokenData>(`${this.baseUrl}plaid/link-token/assets`, {}, { options });
  }

  public exchangePlaidPublicToken(publicToken: string, options?: HttpOptions<ClientIntegration>) {
    return this.post<ClientIntegration>(`${this.baseUrl}plaid/exchange-token`, { publicToken }, { options });
  }

  public deletePlaidPublicToken(id: string, options?: HttpOptions<void>) {
    return this.delete<void>(`${this.baseUrl}plaid/${id}`, { options });
  }

  public listIntegrations(
    query: PaginatedQuery<ClientIntegrationFilters>,
    options?: HttpOptions<PaginatedResponse<ClientIntegration>>
  ) {
    return this.get<PaginatedResponse<ClientIntegration>>(`${this.baseUrl}client-integrations`, {
      axiosConfig: {
        params: standardiseQuerySort(query),
      },
      options,
    });
  }
}
