import { render, staticRenderFns } from "./CountryOfIncorporation.vue?vue&type=template&id=42f78932&scoped=true"
import script from "./CountryOfIncorporation.vue?vue&type=script&setup=true&lang=ts"
export * from "./CountryOfIncorporation.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CountryOfIncorporation.vue?vue&type=style&index=0&id=42f78932&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f78932",
  null
  
)

export default component.exports