import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const companyMemberTableFieldsFull: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
  {
    header: '',
    key: 'status',
  },
  { header: 'Email', key: 'email' },
];

export const companyMemberTableFieldsMinimal: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
];
