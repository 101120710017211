import { submitForm, updateModel } from 'ah-common-lib/src/form/helpers';
import {
  OnboardingIndividualInfo,
  IndividualType,
  clearIndividual,
  Authority,
  Permission,
  EntityTypeAddress,
  CurrentAddressHistoryItem,
  AddressHistoryItem,
  getAddressHistoryError,
  MIN_ADDRESS_HISTORY_AGE,
  getAddressHistoryAge,
  isAddressHistoryRequired,
} from 'ah-api-gateways';
import { FormDefinition, FormModel, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import Vue, { Ref, computed, onBeforeMount, ref, watch } from 'vue';
import { genericServerErrorMessage, HttpError, RequestManager } from 'ah-requests';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { catchError, defaultIfEmpty, mergeMap, mergeMapTo, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { useToast } from 'ah-common-lib/src/toast';
import { forkJoinWithCompletion } from 'ah-common-lib/src/helpers/rxjs';

export type AdditionalUserFormObj = FormDefinition & {
  individual: OnboardingIndividualInfo;
};

const MAX_USERS_ALLOWED = 3;

/**
 * Manage and persist an individual list in an onboarding context
 *
 * Given a ref representing the individuals list, this will produce a managed list of individuals and related forms
 */
export function useRegistrationIndividuals(opts: {
  requestManager: RequestManager;
  individuals: Ref<OnboardingIndividualInfo[]>;
  userFormFactory: (individual?: Partial<OnboardingIndividualInfo>) => FormModel;
}) {
  const individualEntries = ref<AdditionalUserFormObj[]>([]);

  const authorities = ref<Authority[]>([]);
  const permissions = ref<Permission[]>([]);

  const services = getServices();
  const authStore = useAuthStore();
  const toast = useToast();

  const additionalUsers = computed(() =>
    individualEntries.value.filter(
      (e) => !e.individual.applicant && !e.individual.owner && !e.individual.secondaryOwner
    )
  );

  const owner = computed(() => individualEntries.value.find((e) => e.individual.owner));

  const secondaryOwner = computed(() => individualEntries.value.find((e) => e.individual.secondaryOwner));

  const applicant = computed(() => individualEntries.value.find((e) => e.individual.applicant));

  const isApplicantOwner = computed(() => applicant.value && applicant.value.individual.owner);

  const maxUsersAllowed = computed(() => additionalUsers.value.length >= MAX_USERS_ALLOWED);

  const applicantEmail = computed(() => applicant.value?.individual.email || 'Not set');

  const secondaryOwnerEmail = computed(() => secondaryOwner.value?.individual.email || 'Not set');

  const ownerEmail = computed(() => (owner.value || applicant.value)?.individual.email || 'Not set');

  const validation = computed(() => {
    return {
      $model: individualEntries.value.map((i) => clearIndividual(i.individual)),
      $invalid: !!individualEntries.value?.find(
        (val) => val.validation?.$invalid || !hasValidPermissions(val.individual)
      ),
      $dirty: !!individualEntries.value?.find((val) => val.validation?.$dirty),
    } as FormValidation<OnboardingIndividualInfo[]>;
  });

  function makeAdditionalUser(individual?: Partial<OnboardingIndividualInfo>): AdditionalUserFormObj {
    const out = {
      individual: generateEmptyIndividual(individual),
      form: opts.userFormFactory(individual),
      validation: null,
    };

    updateModel(out.form, out.individual);

    return out;
  }

  /**
   * Validate an individual for permissions
   *
   * For registration purposes, we require an non admin individual to have at least one permission set to true
   */
  function hasValidPermissions(individual: OnboardingIndividualInfo) {
    return (
      individual.type === IndividualType.CLIENT_ADMIN ||
      individual.owner ||
      individual.secondaryOwner ||
      !!individual.proposedPermissions?.find((i) => i.allow === true)
    );
  }

  function isDuplicatedEmail(email: string) {
    return individualEntries.value.filter((ind) => ind.individual.email === email).length > 1;
  }

  function removeEntry(id: string) {
    const index = individualEntries.value.findIndex((i) => i.individual.id === id);
    individualEntries.value.splice(index, 1);
  }

  function addEntry(individual?: Partial<OnboardingIndividualInfo>) {
    individualEntries.value.push(makeAdditionalUser(individual) as any);
  }

  function submitForms(forms = individualEntries.value) {
    let valid = true;
    forms.forEach((f) => {
      if (f.validation) {
        submitForm(f.validation);
        valid = valid && !f.validation.$invalid;
      }
    });
    return valid;
  }

  function generateEmptyIndividual(data?: Partial<OnboardingIndividualInfo>): OnboardingIndividualInfo {
    return {
      id: `tempId-${generateUUID()}`,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      owner: false,
      secondaryOwner: false,
      applicant: false,
      type: IndividualType.CLIENT_INDIVIDUAL,
      proposedPermissions: [...permissions.value],
      ...data,
    };
  }

  function updateEntry(newIndividual: Partial<OnboardingIndividualInfo>, formObj: AdditionalUserFormObj) {
    formObj.individual = { ...formObj.individual, ...newIndividual };
  }

  function loadInformation() {
    opts.requestManager.cancelAndNew('loadRole', services.authz.getPublicAuthorities()).subscribe((auths) => {
      authorities.value = auths ?? [];
      permissions.value = auths.map((a) => ({ authority: a.id, allow: false }));
    });
  }

  function cleanupForSave(individual: OnboardingIndividualInfo) {
    const out = {
      ...clearIndividual(individual),
      proposedPermissions: individual.type === IndividualType.CLIENT_ADMIN ? [] : individual.proposedPermissions,
    };

    if (out.currentAddress) {
      (out as any).address = out.currentAddress;
    }
    delete out.currentAddress;
    delete out.previousAddresses;

    if (out.id?.startsWith('tempId')) {
      delete out.id;
    }

    return out;
  }

  /**
   * Saves an entry updating it in the internal data
   * Note: Individual is expected to be passed BY REFERENCE
   * and props will be set on it accordingly - do not pass a cloned value, or behaviour will change
   */
  function saveRequest(individual: OnboardingIndividualInfo) {
    const clientId = authStore.loggedInIdentity!.client!.id;
    const request = individual.id?.startsWith('tempId')
      ? services.registration.proposeUser(clientId, cleanupForSave(individual)).pipe(
          catchError((e) => {
            Vue.set(individual, 'userCreationApiError', e.response?.data);
            throw e;
          }),
          tap((r) => {
            const entry = individualEntries.value.find((i) => i.individual.email === individual.email);
            if (entry) {
              entry.individual.id = r.id;
            }
          })
        )
      : services.registration.editProposedUser(individual.id!, clientId, cleanupForSave(individual));

    return request.pipe(
      mergeMap((r) => {
        if (shouldSaveEntryAddresses(individual)) {
          const addresses = cleanupAddressHistory(individual);
          return services.compliance
            .createAddressHistory({
              entityId: r.id,
              type: EntityTypeAddress.INDIVIDUAL,
              currentAddress: addresses.currentAddress as CurrentAddressHistoryItem,
              previousAddresses: addresses.previousAddresses as AddressHistoryItem[],
            })
            .pipe(
              catchError((e) => {
                Vue.set(individual, 'addressHistoryApiError', e.response?.data);
                throw e;
              })
            );
        }
        return of(null);
      }),
      tap((r) => {
        if (r) {
          const entry = individualEntries.value.find((i) => i.individual.email === individual.email);
          if (entry) {
            const [currentAddress, ...previousAddresses] = r.addresses;
            entry.individual.currentAddress = currentAddress;
            entry.individual.previousAddresses = previousAddresses;
          }
        }
      }),
      catchError((error: HttpError) => {
        triggerAddressHistoryErrorToast(error);
        return throwError(error);
      })
    );
  }

  function triggerAddressHistoryErrorToast(error: HttpError) {
    const errorMessage = getAddressHistoryError(error);

    if (errorMessage) {
      toast.error(errorMessage);
    }
  }

  /**
   * Save all entries that have been updated
   */
  function saveEntries() {
    const clientId = authStore.loggedInIdentity!.client!.id;

    const deleteRequests: Observable<any>[] = [];
    const editRequests: Observable<any>[] = [];

    /**
     * Request to validate any and all address history requests
     * A request will return:
     *  - the response of the validation request otherwise
     */
    const validateHistoryAddresses = forkJoinWithCompletion(
      individualEntries.value
        .filter((entry) => shouldSaveEntryAddresses(entry.individual))
        .map((entry) => {
          Vue.set(entry.individual, 'addressHistoryApiError', undefined);
          Vue.set(entry.individual, 'userCreationApiError', undefined);
          const individual = clearIndividual(entry.individual);
          const addresses = cleanupAddressHistory(individual);
          return services.compliance
            .validateAddressHistory(
              {
                currentAddress: addresses.currentAddress as CurrentAddressHistoryItem,
                previousAddresses: addresses.previousAddresses as AddressHistoryItem[],
              },
              {
                errors: {
                  silent: true,
                },
              }
            )
            .pipe(
              catchError((e: HttpError) => {
                Vue.set(entry.individual, 'addressHistoryApiError', e.response?.data);
                throw e;
              })
            );
        })
    ).pipe(
      catchError((e) => {
        triggerAddressHistoryErrorToast(e);
        toast.error(genericServerErrorMessage.message!);
        throw 'some addresses failed';
      })
    );

    let requestChain: Observable<any> = validateHistoryAddresses;

    function isEntryChanged(entry: OnboardingIndividualInfo) {
      const newEntry = clearIndividual(entry);
      let oldEntry = opts.individuals.value.find((i) => i.id === entry.id);
      if (oldEntry) {
        oldEntry = clearIndividual(oldEntry);
      }
      return !isEqual(newEntry, oldEntry);
    }

    if (isEntryChanged(owner.value!.individual)) {
      requestChain = requestChain.pipe(mergeMapTo(saveRequest(owner.value!.individual)));
    }

    if (secondaryOwner.value) {
      if (isEntryChanged(secondaryOwner.value.individual)) {
        requestChain = requestChain.pipe(mergeMapTo(saveRequest(secondaryOwner.value.individual)));
      }
    }

    individualEntries.value
      .map((i) => i.individual)
      .filter((i) => !i.owner && !i.secondaryOwner)
      .forEach((ind) => {
        if (isEntryChanged(ind)) {
          editRequests.push(saveRequest(ind));
        }
      });

    opts.individuals.value.forEach((i) => {
      const current = individualEntries.value.find((e) => e.individual.id === i.id);
      if (!current) {
        deleteRequests.push(services.registration.deleteProposedUser(i.id!, clientId));
      }
    });

    return opts.requestManager.currentOrNew(
      'submitIndividuals',
      // Save owner (applicant or new owner) first
      requestChain.pipe(
        // Delete any entries removed after
        mergeMap(() => forkJoinWithCompletion(deleteRequests).pipe(defaultIfEmpty([] as any[]))),
        // Save all other entries (applicant included, if not owner)
        mergeMap(() => forkJoinWithCompletion(editRequests).pipe(defaultIfEmpty([] as any[])))
      )
    );
  }

  /**
   * Check to determine whether an entry should save address history
   * This is needed because while only signatories need to save address history,
   * changes in the UX may result in address information being stored in certain entries (such as the applicant)
   * (which should be ignored)
   */
  function shouldSaveEntryAddresses(individual: OnboardingIndividualInfo) {
    return individual.currentAddress && (individual.owner || individual.secondaryOwner);
  }

  function resetEntries() {
    const out: AdditionalUserFormObj[] = [];
    if (!!opts.individuals.value.length) {
      opts.individuals.value.forEach((i) => {
        const formObj = individualEntries.value.find(
          (obj) =>
            (obj.individual.id && obj.individual.id === i.id) ||
            (obj.individual.email && obj.individual.email === i.email)
        );
        if (formObj) {
          formObj.individual = cloneDeep(i);
          updateModel(formObj.form, formObj.individual);
        }
        out.push(formObj ?? makeAdditionalUser(i));
      });
    }
    individualEntries.value = out as any;
  }

  function cleanupAddressHistory(
    individual: OnboardingIndividualInfo
  ): Pick<OnboardingIndividualInfo, 'currentAddress' | 'previousAddresses'> {
    if (!individual.currentAddress) {
      return {};
    }
    const out = {
      currentAddress: cloneDeep(individual.currentAddress),
      previousAddresses: cloneDeep(individual.previousAddresses),
    };
    const historyRequired = isAddressHistoryRequired(out.currentAddress.countryCode);
    const isCurrentAddressSufficientlyOld =
      getAddressHistoryAge({ currentAddress: out.currentAddress }) >= MIN_ADDRESS_HISTORY_AGE;

    if (!historyRequired) {
      out.currentAddress.residingFrom = undefined;
    }
    if (!historyRequired || isCurrentAddressSufficientlyOld) {
      out.previousAddresses = [];
    }

    return out;
  }

  /**
   * The onboarding model will either have a loaded list of individuals, or information on an applicant (if coming from a previous step)
   * If no list of individuals is available, applicant is the owner by default
   */
  watch(opts.individuals, resetEntries, { immediate: true });

  onBeforeMount(loadInformation);

  return {
    individualEntries,
    additionalUsers,
    owner,
    secondaryOwner,
    applicant,
    authorities,
    permissions,
    applicantEmail,
    secondaryOwnerEmail,
    ownerEmail,
    isApplicantOwner,
    maxUsersAllowed,
    validation,
    resetEntries,
    submitForms,
    loadInformation,
    makeAdditionalUser,
    isDuplicatedEmail,
    removeEntry,
    addEntry,
    updateEntry,
    saveEntries,
  };
}
