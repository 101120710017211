import { storeSetupDone } from '../store';
import { inheritedMeta } from 'ah-common-lib/src/helpers/router';
import { CustomNavigationGuard, CustomRoute, CustomRouteMeta } from './routeModels';
import { AuthorityType, ClientType, ComplianceStatus, UserRole } from 'ah-api-gateways';
import { RawLocation } from 'vue-router';
import { useAuthStore } from '../store/authStore';
import { useIndividualSettingsStore } from '../store/individualSettingsModule';

function calcRedirect(redirect: string, to: CustomRoute) {
  if (redirect.startsWith('./')) {
    let basePath = to.matched[Math.max(0, to.matched.length - 2)].path;
    if (basePath.endsWith('/')) {
      basePath = basePath.substr(0, basePath.length - 1);
    }
    return basePath + redirect.substr(1);
  }
  return redirect;
}

/**
 * Function to determine, from the perspective of X-Hedge onboarding, if the questionnaire is finished (and review screen should be shown)
 *
 * This is true for
 * - any non-Client user (they should never encounter this)
 * - any non-applicant user (they should see the review screen)
 * - any non-owner applicant of a Company Client outside PENDING
 * - any owner applicant (Company or Individual) outside PENDING or TERMS_AND_CONDITIONS
 */
export function isQuestionnaireFinished() {
  const authStore = useAuthStore();

  if (!authStore.isClientUser) return true;
  if (!authStore.loggedInIdentity?.applicant) return true;
  if (authStore.complianceStatus === ComplianceStatus.PENDING) {
    return false;
  }
  if (authStore.complianceStatus === ComplianceStatus.TERMS_AND_CONDITIONS) {
    return !authStore.loggedInIdentity?.owner;
  }
  return true;
}

export function checkAccountRegistrationState(next: (to?: RawLocation) => void) {
  const authModule = useAuthStore();
  if (authModule.hasRegistrationSession) {
    next(isQuestionnaireFinished() ? '/review' : 'register');
  } else {
    next();
  }
}

export const requireClientChecks: CustomNavigationGuard<Vue> = (to, from, next) => {
  storeSetupDone.then(() => {
    const inherited = inheritedMeta(to) as CustomRouteMeta;
    const redirect: RawLocation = calcRedirect(inherited.redirectDisallowedRole || '/', to);

    if (inherited.validateXHedgeUserPro || inherited.companyOnly) {
      return useIndividualSettingsStore()
        .loadClient()
        .then((client) => {
          // Either route doesn't require x-hedge user pro or client has flag set as true
          const xHedgeProCheck = !inherited.validateXHedgeUserPro || client?.enableXHedgePro;

          // Either route doesn't require a company client or client is company
          const clientCompanyCheck = !inherited.companyOnly || client?.type === ClientType.COMPANY;

          next(xHedgeProCheck && clientCompanyCheck ? undefined : redirect);
        });
    }
    next();
  });
};

export const requireRouteRolesAndAuthorities: CustomNavigationGuard<Vue> = (to, from, next) => {
  storeSetupDone.then(() => {
    const authModule = useAuthStore();
    const inherited = inheritedMeta(to) as CustomRouteMeta;
    const roles: UserRole[] | undefined = inherited.allowedRoles;
    const requiredAuthorities: AuthorityType[] | undefined = inherited.requiredAuthorities;
    const redirect: RawLocation = calcRedirect(inherited.redirectDisallowedRole || '/', to);

    if (!roles?.length && !requiredAuthorities?.length) {
      next();
    } else if (!authModule.loggedInRole) {
      next(redirect);
    } else if (roles && !roles.includes(authModule.loggedInRole)) {
      next(redirect);
    } else if (requiredAuthorities && !authModule.hasAuthorities(requiredAuthorities)) {
      next(redirect);
    } else {
      next();
    }
  });
};

export const checkAuth: CustomNavigationGuard<Vue> = (to, from, next: (to?: RawLocation) => void) => {
  storeSetupDone.then(() => {
    const authModule = useAuthStore();
    const inherited = inheritedMeta(to) as CustomRouteMeta;
    if (inherited.auth) {
      if (!authModule.hasValidSession) {
        next({ path: '/login', query: { r: to.path, q: JSON.stringify(to.query) } });
      } else {
        checkAccountRegistrationState(next);
      }
    } else if (inherited.unVerifiedOrUnAuth) {
      next(authModule.isLoggedIn && !authModule.isUnverifiedSelfRegister ? '/dashboard' : undefined);
    } else if (inherited.unAuthOnly) {
      next(authModule.isLoggedIn ? '/dashboard' : undefined);
    } else {
      next();
    }
  });
};
