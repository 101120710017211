<script setup lang="ts">
import coinify from 'coinify';
import { CoinifyCurrency } from 'ah-api-gateways';
import { computed } from 'vue';
import TextBlockWithOverflowTooltip from 'ah-common-lib/src/text/TextBlockWithOverflowTooltip.vue';

const props = defineProps({
  currency: {
    type: String,
    required: true,
  },
  highlighted: { type: [Boolean, String], default: false },
  showLabels: { type: [Boolean, String], default: false },
});

const currencyObject = computed(() => coinify.get(props.currency) as CoinifyCurrency);
</script>

<template>
  <div :class="['currency-table-cell', { highlighted }]">
    <div class="currency-icon">
      <span>
        {{ currencyObject.symbol }}
      </span>
    </div>
    <div class="currency-text" v-if="showLabels !== false">
      <span class="currency-accronym">
        {{ currencyObject.code }}
      </span>
      <TextBlockWithOverflowTooltip class="currency-name" :text-content="currencyObject.name" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.currency-table-cell {
  @include upToResolution($tabletResolution) {
    display: flex;
    align-items: center;
    overflow: visible;
    white-space: normal;
  }
}
.currency-icon {
  display: block;
  @include upToResolution($tabletResolution) {
    display: inline;
    height: 1.1em;
    width: 1.1em;
    min-width: 1.1em;
    line-height: 1.1em;
  }
  float: left;
  position: relative;
  font-size: 1.8em;
  top: 0.2em;
  height: 1.4em;
  width: 1.4em;
  min-width: 1.4em;
  line-height: 1.4em;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 0.5em;
  margin-bottom: 0.4em;
  @include themedBackgroundColor($color-highlight-grey);

  span {
    vertical-align: middle;
    line-height: 1em;
    @include upToResolution($tabletResolution) {
      font-size: $font-size-xs !important;
    }
  }

  tr:hover &,
  tr.selected-row & {
    @include themedBackgroundColor($color-box-bg);
  }
}

.currency-text {
  display: block;
  white-space: nowrap;
  @include upToResolution($tabletResolution) {
    height: 1.5em;
  }
  .currency-accronym {
    font-size: 1.2em;
    display: block;
    @include upToResolution($tabletResolution) {
      font-size: 15px;
      display: inline;
    }
  }

  .currency-name {
    opacity: 0.75;
    display: block;
    @include upToResolution($tabletResolution) {
      display: inline;
    }
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
