<script setup lang="ts">
import { Client, Trade, PaginatedParams, PaginatedResponse, HedgingInstruments } from 'ah-api-gateways';
import { openPositionsDashboardTradeTableFields } from 'ah-trades/src/models/tradeTableFields';
import OpenPositionsTradeListing from 'ah-trades/src/components/lists/OpenPositionsTradeListing.vue';
import DashboardViewAllButton from '@/app/components/dashboard/DashboardViewAllButton.vue';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';
import { computed, PropType, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import { TableFieldDefinition } from 'ah-common-lib/src/models';

/**
 * Client to show positions for
 *
 * If set, widget will display the information about the client, otherwise all trades will show
 */
const props = defineProps({
  client: {
    type: Object as PropType<Client | null>,
    default: null,
  },
  config: {
    type: Object as PropType<{ tableFields: TableFieldDefinition<any>[] }>,
    default: () => ({ tableFields: openPositionsDashboardTradeTableFields }),
  },
});

const router = useRouter();

const tableData = ref<PaginatedResponse<Trade>>();

const sortAndPageParams = ref<Partial<PaginatedParams>>({ pageSize: 5 });

const fields = computed(() => props.config.tableFields);

const filters = computed(() => {
  return {
    clientId: props.client?.id,
    hedgingProduct: [
      HedgingInstruments.FX_SPOT,
      HedgingInstruments.FX_FORWARD,
      HedgingInstruments.WINDOW_FORWARD,
      HedgingInstruments.FLEXIBLE_FORWARD,
    ],
  };
});

const hasTrades = computed(() => (tableData.value?.total ?? 0) > 0);

function goToTrades() {
  if (props.client) {
    router.push(`${props.client.id}/trades`);
  } else {
    router.push('/dashboard/trades/open-fx');
  }
}
</script>

<template>
  <div>
    <div v-if="$mediaQuery.is('smOnly')" class="w-100">
      <SortSelector :fields="fields" :sortAndPageParams.sync="sortAndPageParams" class="w-100" />
    </div>
    <OpenPositionsTradeListing
      :config="config"
      :filter="filters"
      :sortAndPageParams.sync="sortAndPageParams"
      :tableData.sync="tableData"
      hidePagination
      class="pb-3"
    >
      <template #tableNoResultsState>
        <div class="no-results-info">
          <div class="chart-icon">
            <LineChartIcon />
          </div>
          There are no open positions
        </div>
      </template>
    </OpenPositionsTradeListing>
    <DashboardViewAllButton @click="goToTrades" v-if="hasTrades" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .no-results-info {
  margin: 4rem 0;
  @include themedTextColor($color-text-secondary, $color-dark-text-secondary);

  .chart-icon {
    margin: 1rem 0;
    svg {
      width: 3rem;
      height: auto;
      path {
        @include themedPropColor('fill ', $color-text-secondary, $color-dark-text-secondary);
      }
    }
  }
}
</style>
