<script lang="ts">
import { DatePicker } from 'v-calendar/lib/v-calendar.umd';
import {
  addMonths,
  addWeeks,
  addYears,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isSameDay,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  addDays,
  subDays,
  endOfDay,
} from 'date-fns';
import { isDescendant } from '../../helpers/dom';
import CalendarIcon from 'ah-common-lib/src/icons/components/CalendarIcon.vue';
import { getFieldErrorList, makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { singleInputDateField } from 'ah-common-lib/src/form/models';
import { date, ifTest, minDate, maxDate } from 'ah-common-lib/src/form/validators';
import { cloneDeep, uniqueId, isEmpty } from 'lodash';
import { getChildModel } from 'ah-common-lib/src/form/helpers';
import { FieldModel } from 'ah-common-lib/src/form/interfaces';
import { computed, onBeforeMount, onBeforeUnmount, PropType, reactive, ref, watch } from 'vue';
import { VRow } from '.';

export interface DateFilterActions extends DateRange {
  label: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export const defaultChoices = [
  { label: 'Today', start: startOfToday(), end: endOfToday() },
  { label: 'Tomorrow', start: startOfTomorrow(), end: endOfTomorrow() },
  { label: 'This week', start: startOfWeek(new Date()), end: endOfWeek(new Date()) },
  { label: 'Next week', start: startOfWeek(addWeeks(new Date(), 1)), end: endOfWeek(addWeeks(new Date(), 1)) },
  { label: 'This month', start: startOfMonth(new Date()), end: endOfMonth(new Date()) },
  { label: 'Next month', start: startOfMonth(addMonths(new Date(), 1)), end: endOfMonth(addMonths(new Date(), 1)) },
  { label: 'This year', start: startOfYear(new Date()), end: endOfYear(new Date()) },
  { label: 'Next year', start: startOfYear(addYears(new Date(), 1)), end: endOfYear(addYears(new Date(), 1)) },
];
</script>

<script setup lang="ts">
/**
 * Range date selector
 *
 * Emits:
 * - date (payload: DateRange): .sync'able date range
 * - submit (payload: Date | DateRange): date selected on submission
 */

const emit = defineEmits({
  'update:dateSelected': (_date: DateRange | Date | null) => true,
  submit: (_date: DateRange | Date | null) => true,
});

const popover = ref<InstanceType<typeof VRow>>();

const datePicker = ref<InstanceType<typeof DatePicker>>();

const props = defineProps({
  /**
   * Title of the Date selector
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * Confirm button label
   */
  confirmButtonLabel: {
    type: String,
    default: 'Confirm',
  },
  /**
   * Cancel button label
   */
  cancelButtonLabel: {
    type: String,
    default: 'Cancel',
  },
  /**
   * Whether date picker is a range of dates
   */
  isRanged: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Only relevant if picker is a range of dates
   *
   * If set, when selecting a date, if the other value is undefined, will automatically select a single day date range
   */
  setSingleDateByDefault: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Known date ranges that the user can select to auto fill the date picker
   *
   * if not ranged date this will be ignored
   */
  commonChoices: {
    type: Array as PropType<DateFilterActions[]>,
    default: () => defaultChoices,
  },
  /**
   * Date selected in the date picker
   */
  dateSelected: {
    type: [Object, Date] as PropType<DateRange | Date | null>,
    default: null,
  },
  /**
   * Default option selected
   */
  defaultDate: {
    type: [Object, Date] as PropType<DateRange | Date | null>,
    default: null,
  },
  /**
   * Dates not able to be selected
   */
  excludedDates: {
    type: Array as PropType<Date[] | null>,
    default: null,
  },
  /**
   * Whether clear option is available
   */
  clearable: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Clear value
   *
   * Will be ignored if prop `clearable` is set to false
   */
  clearValue: {
    type: [Object, Date] as PropType<DateRange | Date | null>,
    default: null,
  },
  /**
   * Additional changes to the calendar options
   * Same values for selected attribute and dragable attribute is being used
   */
  options: {
    type: Array as PropType<any[]>,
  },
  /**
   * Actions menu target key
   * Should be set whether not to have common choices list.
   */
  hideChoices: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to display action choices
   */
  setDateOnInvalid: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to block the dates after the max date
   */
  maximumDate: {
    type: Date as PropType<Date | null>,
    default: null,
  },
  /**
   * When using a date range, whether to set the end date to a end of day (23h 59m 59s)
   * (For filtering purposes we usually want to use end of day)
   */
  useEndOfDayForEndDate: {
    type: [Boolean, String],
    default: true,
  },
});

const show = ref(false);

const fromPage = ref<{ month: number; year: number }>({ month: 0, year: 0 });

const toPage = ref<{ month: number; year: number }>({ month: 0, year: 0 });

const popOverTarget = ref(`date-menu-toggle-${uniqueId()}`);

const selectedDate = ref<DateRange | Date | null>();

const dateToSelect = ref<DateRange | Date | null>();

const windowClickListener = ref<(event: MouseEvent) => void>();

const start = computed(() => {
  if (!selectedDate.value || selectedDate.value === null) {
    return null;
  }

  if (selectedDate.value instanceof Date) {
    return selectedDate.value;
  } else if (typeof selectedDate.value === 'string') {
    return new Date(selectedDate.value);
  } else {
    if (typeof (selectedDate.value as DateRange).start === 'string') {
      return new Date(selectedDate.value.start);
    }
    return (selectedDate.value as DateRange).start;
  }
});

const end = computed(() => {
  if (!selectedDate.value || selectedDate.value === null) {
    return null;
  }

  if (selectedDate.value instanceof Date) {
    return selectedDate.value;
  } else if (typeof selectedDate.value === 'string') {
    return new Date(selectedDate.value);
  } else {
    if (typeof (selectedDate.value as DateRange).end === 'string') {
      return new Date(selectedDate.value.end);
    }
    return (selectedDate.value as DateRange).end;
  }
});

const dateFromFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'dateFrom',
    fieldType: 'form',
    fields: [
      singleInputDateField(
        'date',
        '',
        {
          required: false,
          showCalendar: false,
          fieldWrapperClass: 'col col-12 mb-0',
          useLocalTime: 'true',
          errorMessages: {
            maxDate: 'Invalid Start date',
            date: 'Must be a valid date',
          },
        },
        {
          maxDate: ifTest(
            maxDate(() => (end.value ? addDays(end.value, 1) : undefined)),
            () => end.value !== null
          ),
          date: ifTest(date, (val) => val instanceof Date),
        }
      ),
    ],
  }),
  validation: null,
});

const dateToFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'dateTo',
    fieldType: 'form',
    fields: [
      singleInputDateField(
        'date',
        '',
        {
          required: false,
          showCalendar: false,
          fieldWrapperClass: 'col col-12 mb-0',
          useLocalTime: 'true',
          errorMessages: {
            minDate: 'Invalid End date',
            date: 'Must be a valid date',
          },
        },
        {
          minDate: ifTest(
            minDate(() => (start.value ? addDays(start.value, -1) : undefined)),
            () => start.value !== null
          ),
          date: ifTest(date, (val) => val instanceof Date),
        }
      ),
    ],
  }),
  validation: null,
});

onBeforeMount(() => {
  windowClickListener.value = (event: any) => {
    if (show.value && popover.value && !isDescendant(popover.value.$el, event.target)) {
      show.value = false;
    }
  };
  window.addEventListener('click', windowClickListener.value);
});

onBeforeUnmount(() => {
  if (windowClickListener.value) {
    window.removeEventListener('click', windowClickListener.value);
  }
});

function onClickShowButton() {
  if (show.value === false) {
    setTimeout(() => {
      if (!dateToSelect.value) {
        dateToSelect.value = cloneDeep(props.defaultDate);
      }
      show.value = true;
    });
  }
}

const noChoices = computed(() => props.hideChoices !== false);

const attributes = computed(() => ({
  highlight: {
    class: 'date-highlight',
    contentClass: 'date-highlight-content',
  },
  ...props.options,
}));

const isRangedDatePicker = computed(() => props.isRanged !== false);

const isDirty = computed(() => {
  if (!selectedDate.value) return false;
  if (!isNaN(new Date(selectedDate.value as Date).getDate())) {
    return !isSameDay(selectedDate.value as Date, props.clearValue as Date);
  } else if (isRangedDatePicker.value) {
    const range = selectedDate.value as DateRange;
    const defaultRange = props.clearValue as DateRange;
    return (
      (!defaultRange && !!selectedDate.value) ||
      !(isSameDay(defaultRange.start, range.start) && isSameDay(defaultRange.end, range.end))
    );
  }

  return selectedDate.value !== props.clearValue;
});

const isInvalid = computed(() => fieldErrors.value.length > 0);

const shouldSetDateOnInvalid = computed(() => props.setDateOnInvalid !== false);

const fieldErrors = computed(() => {
  if (!dateFromFormDef.form || !dateToFormDef.form || !dateFromFormDef.validation || !dateToFormDef.validation)
    return [];

  return [
    ...getFieldErrorList(dateFromFormDef.validation!, getChildModel(dateFromFormDef.form, 'date')! as FieldModel),
    ...getFieldErrorList(dateToFormDef.validation!, getChildModel(dateToFormDef.form, 'date')! as FieldModel),
  ];
});

function isActive(choice: DateFilterActions) {
  if (isRangedDatePicker.value && dateToSelect.value) {
    const range = dateToSelect.value as DateRange;
    return isSameDay(choice.start, new Date(range.start)) && isSameDay(choice.end, new Date(range.end));
  }
  return false;
}

function clear() {
  dateToSelect.value = props.clearValue;
  selectedDate.value = props.clearValue;
  emit('update:dateSelected', selectedDate.value);
}

function cancel() {
  show.value = false;
}

/**
 * Select date/date range from one of the choices provided.
 *
 * If current view does not include any of the dates being selected, it will auto scroll to the beginning of the range
 */
function selectFromChoice(date: DateRange | Date) {
  dateToSelect.value = date;
  const minPageDate = new Date(fromPage.value.year, fromPage.value.month - 1, 1);
  const maxPageDate = endOfMonth(new Date(toPage.value.year, toPage.value.month - 1, 1));
  const minDate = !(date instanceof Date) ? (date as DateRange).start : (date as Date);
  const maxDate = !(date instanceof Date) ? (date as DateRange).end : (date as Date);
  if (!maxDate || !minDate || minPageDate > maxDate || maxPageDate < minDate) {
    datePicker.value.move({ month: minDate!.getMonth() + 1, year: minDate!.getFullYear() }, { position: 1 });
  }
}

function onStartDateAdded(event: FormEvent) {
  if (dateFromFormDef.validation?.$invalid) {
    if (shouldSetDateOnInvalid.value) {
      dateToSelect.value = props.dateSelected;
    } else {
      return;
    }
  } else if (event.event === 'form-field-set-value') {
    const dateChoice = dateFromFormDef.form!.date?.toString().length > 0 ? new Date(dateFromFormDef.form!.date) : null;
    if (!isRangedDatePicker.value) {
      if (shouldSetDateOnInvalid.value) {
        dateToSelect.value = dateChoice ?? props.dateSelected;
      } else {
        dateToSelect.value = dateChoice;
      }
    } else if (dateFromFormDef.form!.date.toString().length > 0) {
      (dateToSelect.value as any) = { ...dateToSelect.value, start: dateChoice };
      if (dateChoice && props.setSingleDateByDefault !== false && !(dateToSelect.value as DateRange).end) {
        (dateToSelect.value as DateRange).end = addDays(dateChoice, 1);
      }
    }
  }

  submit();
}

function onEndDateAdded(event: FormEvent) {
  if (dateToFormDef.validation?.$invalid) return;

  if (event.event === 'form-field-set-value') {
    if (dateToFormDef.form!.date.toString().length > 0) {
      const dateChoice =
        props.useEndOfDayForEndDate !== false
          ? endOfDay(new Date(dateToFormDef.form!.date))
          : new Date(dateToFormDef.form!.date);
      dateToSelect.value = { ...(dateToSelect.value as any), end: dateChoice };
      if (dateChoice && props.setSingleDateByDefault !== false && !(dateToSelect.value as DateRange).start) {
        (dateToSelect.value as DateRange).start = subDays(dateChoice, 1);
      }
    }
    submit();
  }
}

function submit() {
  show.value = false;
  selectedDate.value = !isEmpty(dateToSelect.value) ? dateToSelect.value : null;
  if (dateToSelect.value instanceof Date && dateFromFormDef.form) {
    dateFromFormDef.form.date = dateToSelect.value;
  } else if (dateFromFormDef.form && dateToFormDef.form) {
    dateFromFormDef.form.date = (dateToSelect.value as DateRange)?.start;
    dateToFormDef.form.date = (dateToSelect.value as DateRange)?.end;
  }

  emit('update:dateSelected', selectedDate.value);
}

function onDateChange() {
  selectedDate.value = props.dateSelected;
  if (dateFromFormDef.form) {
    dateFromFormDef.form.date = start.value;
  }
  if (dateToFormDef.form) {
    dateToFormDef.form.date = end.value;
  }
}

watch(() => props.dateSelected, onDateChange, { immediate: true });

function onSelectedDateChange() {
  dateToSelect.value = selectedDate.value;
}

watch(selectedDate, onSelectedDateChange, { immediate: true });
</script>

<template>
  <div class="position-relative">
    <div class="field-group">
      <label class="field-group-field-label" v-if="title">{{ title }}</label>
      <span v-show="isDirty && clearable !== false">
        <slot name="clear-label">
          <a class="field-group-clear-link" @click="clear"> clear </a>
        </slot>
      </span>

      <div class="date-input-row">
        <div :class="['date-input-fields-wrapper', 'field-group-field-input form-control', { invalid: isInvalid }]">
          <ValidatedForm
            :fm="dateFromFormDef.form"
            :validation.sync="dateFromFormDef.validation"
            @form-event="onStartDateAdded"
          />
          <div v-if="isRangedDatePicker">-</div>
          <ValidatedForm
            :fm="dateToFormDef.form"
            :validation.sync="dateToFormDef.validation"
            @form-event="onEndDateAdded"
            v-if="isRangedDatePicker"
          />
        </div>

        <div :id="popOverTarget">
          <div @click="onClickShowButton" class="calendar">
            <slot name="icon">
              <CalendarIcon />
            </slot>
          </div>

          <BPopover
            :custom-class="`input-date-popover arrowless mb-0 ${isRangedDatePicker ? 'ranged' : ''}`"
            :target="popOverTarget"
            :show="show"
          >
            <VRow class="dropdown-menu-wrapper" ref="popover">
              <template v-if="isRangedDatePicker">
                <VCol cols="3" class="px-0" v-if="!noChoices">
                  <BDropdownItem
                    class="choice-button btn-stroke text-left"
                    :active="isActive(choice)"
                    v-for="choice in commonChoices"
                    :key="choice.label"
                    @click="selectFromChoice(choice)"
                  >
                    {{ choice.label }}
                  </BDropdownItem>
                </VCol>
                <VCol :cols="!noChoices ? 9 : 12" class="pl-0">
                  <DatePicker
                    v-model="dateToSelect"
                    ref="datePicker"
                    @update:to-page="toPage = $event"
                    @update:from-page="fromPage = $event"
                    :select-attribute="attributes"
                    :drag-attribute="attributes"
                    :columns="2"
                    :disabled-dates="excludedDates"
                    :max-date="maximumDate"
                    locale="eng"
                    is-range
                    trim-weeks
                    is-expanded
                  />
                  <VRow class="mx-0 mb-3 mt-4" align-h="end" align-v="end">
                    <VCol cols="3">
                      <VButton blurOnClick class="w-100 btn-secondary" @click="cancel">
                        {{ cancelButtonLabel }}
                      </VButton>
                    </VCol>
                    <VCol cols="3">
                      <VButton class="w-100 btn-primary" @click="submit"> {{ confirmButtonLabel }} </VButton>
                    </VCol>
                  </VRow>
                </VCol>
              </template>
              <template v-else>
                <VCol cols="12">
                  <DatePicker
                    v-model="dateToSelect"
                    :select-attribute="attributes"
                    :drag-attribute="attributes"
                    :disabled-dates="excludedDates"
                    :max-date="maximumDate"
                    ref="datePicker"
                    locale="eng"
                    trim-weeks
                    is-expanded
                  />
                  <VRow class="wrapper-btn mx-0 mb-3 mt-4" align-h="end">
                    <VCol cols="6">
                      <VButton blurOnClick class="w-100 btn-secondary" @click="cancel">
                        {{ cancelButtonLabel }}
                      </VButton>
                    </VCol>
                    <VCol cols="6">
                      <VButton class="w-100 btn-primary" @click="submit"> {{ confirmButtonLabel }} </VButton>
                    </VCol>
                  </VRow>
                </VCol>
              </template>
            </VRow>
          </BPopover>
        </div>
      </div>

      <div class="form-field-errors" v-if="isInvalid">
        <div class="field-group-errors">
          <div class="d-flex">
            <p class="error" v-for="(error, index) in fieldErrors" :key="index">
              {{ error.error }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vc-container {
  border: none;
}

.b-popover ::v-deep {
  z-index: 100;
  min-width: 20rem;

  .arrow {
    opacity: 0 !important;
  }
  .popover-body {
    padding: 0.5em;
  }

  .date-highlight {
    @include themedBackgroundColor($color-sidebar-border);
  }

  .date-highlight-content {
    @include themedTextColor($color-text);
  }

  .vc-popover-content {
    border: none;
    @include themedBackgroundColor($color-sidebar-border);
    * {
      @include themedTextColor($color-text);
      &.vc-nav-item {
        &:hover {
          @include themedBackgroundColor($color-widgets-grey, $color-primary);
        }
        &.is-active {
          @include themedBackgroundColor($color-widgets-grey, $color-primary);
        }
        &.is-current,
        &:focus,
        &:active {
          @include themedBorderColor($color-primary, $color-dark-primary);
        }
      }
    }
  }
}

.ranged {
  min-width: 46.5rem !important;
}

.calendar {
  position: relative;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.choice-button {
  width: 100%;
  list-style-type: none;
  ::v-deep .dropdown-item {
    padding: 0.5em 1em;
    font-weight: $font-weight-bold;
    @include themedTextColor($color-text, $color-dark-text);
    &:focus,
    &.active {
      @include themedBackgroundColor($color-text-secondary, $color-primary);
      @include themedTextColor($color-dark-text, $color-dark-text);
    }
    &:hover {
      @include themedBackgroundColor($color-text-secondary, $color-primary);
      @include themedTextColor($color-dark-text, $color-dark-text);
    }
  }
}

.date-input-row {
  width: 100%;
  display: inline-flex;
  align-items: center;
  .date-input-fields-wrapper {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    &.invalid {
      border-color: $common-color-red;
    }

    ::v-deep {
      .form,
      .date-wrapper {
        width: 100%;
      }

      .field-group-errors {
        display: none;
      }

      input {
        margin-right: 0;
        text-align: center;
        &,
        &:focus,
        &:hover {
          border: none;
          border-color: transparent;
          background-color: transparent;
          box-shadow: none;
        }
        padding-top: 10px;
      }
    }
  }
}
</style>
