<script lang="ts" setup>
import * as Components from '.';
import FormFieldErrors from './FormFieldErrors.vue';
import { isEqual } from 'lodash';
import { computed, ref } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FormModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';

const props = defineProps(makeUseFormElementProps<FormModel>());

const emit = defineEmits(makeUseFormElementEmits());

const formElement = useFormElement({
  emit,
  props,
});

const {
  fieldClass,
  fieldErrorsShown,
  fieldType,
  fieldTitle,
  htmlTitle,
  showRequired,
  tooltipComponent,
  fieldTitleTooltip,
} = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getSlotName, emitFormEvent, setValue } = formElement;

const item = ref<Vue>();

const hideErrorMessages = modelStateRef('hideErrorMessages', false, true);

const titleClass = modelStateRef('titleClass', null);

const clearable = modelStateRef('clearable', false);

const clearValue = modelStateRef('clearValue', null);

const showClear = computed(() => {
  return (
    clearable.value &&
    field.value.$model !== undefined &&
    field.value.$model !== null &&
    !isEqual(field.value.$model, clearValue.value)
  );
});

function triggerFieldAction(path: string, action: any) {
  const child = (item.value || {}) as any;
  if (child.triggerFieldAction) {
    child.triggerFieldAction(path, action);
  }
}

function clearField() {
  setValue(clearValue.value, true);
}

const fieldEl = computed(() => {
  const key = fieldType.value[0].toUpperCase() + fieldType.value.substring(1) + 'FormField';
  return (Components as Record<string, Object>)[key];
});

defineExpose({
  triggerFieldAction,
});
</script>

<template>
  <div
    :class="[
      'field-group',
      `field-group-${fieldType}`,
      model.$name,
      fieldClass,
      {
        'field-group-error': fieldErrorsShown,
        'field-group-dirty': field.$dirty,
      },
    ]"
    :x-test-name="`field-group-${model.$name}`"
  >
    <slot :name="`${model.$path}:label:before`" v-bind="{ field, model }"></slot>
    <slot :name="`${model.$path}:label`" v-bind="{ field, model }">
      <label v-if="fieldTitle" class="field-group-field-label" :class="titleClass">
        <span v-html="fieldTitle" v-if="htmlTitle" />
        <span v-else>{{ fieldTitle }}</span>
        <span v-if="showRequired" class="required">*</span>
        <Component
          :is="tooltipComponent"
          class="ml-2"
          v-if="tooltipComponent && fieldTitleTooltip"
          :text="fieldTitleTooltip"
        />
        <a v-if="showClear" class="field-group-clear-link" @click="clearField"> clear </a>
      </label>
    </slot>
    <slot :name="`${model.$path}:label:after`" v-bind="{ field, model }"></slot>
    <div class="field-group-field">
      <slot :name="`fieldType:${fieldType}`" v-bind="{ field, model }">
        <slot :name="getSlotName('field')" v-bind="{ field, model }">
          <Component
            :is="fieldEl"
            v-if="fieldType !== 'custom'"
            ref="item"
            :field="field"
            :x-test-name="`field-${model.$name}`"
            :model="model"
            :error="fieldErrorsShown"
            @form-event="emitFormEvent"
          >
            <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </Component>
          <slot />
        </slot>
      </slot>
    </div>
    <slot :name="getSlotName('errors')" v-bind="{ field, model }">
      <FormFieldErrors v-if="!hideErrorMessages" :field="field" :model="model">
        <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </FormFieldErrors>
    </slot>
  </div>
</template>
