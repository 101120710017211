<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { makeFormModel, submitForm, toDataModel } from '../form/helpers';
import { otpField } from '../form/models/commonFields';
import { FormEvent, FormDefinition } from '../form/interfaces';
import { Observable } from 'rxjs';
import { otpMessage } from 'ah-common-lib/src/helpers/otp';
import { PropType, ref, reactive, computed } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { ValidatedForm } from 'ah-common-lib/src/form/components';

const props = defineProps({
  otpPhone: {
    type: String,
  },
  staticOTP: {
    type: Boolean,
    default: false,
  },
  request: {
    type: Function as PropType<(otp: string) => Observable<any>>,
    required: true,
  },
  refreshOtpRequest: {
    type: Function as PropType<() => Observable<any>>,
    required: true,
  },
  otpErrorCheck: {
    type: Function as PropType<(error: any) => boolean>,
    required: true,
  },
});

const emit = defineEmits({
  cancel: () => true,
  completed: (_result: any) => true,
  error: (_error: any) => true,
});

const otpFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'otpForm',
    title: 'OTP',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  }),
  validation: null,
});

const modal = ref<InstanceType<typeof BModal>>();

const requestManager = useRequestManager({
  exposeToParent: ['refreshOtp'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'refreshOtp') {
      refreshOtp();
    }
  },
}).manager;

const otpError = ref<boolean>(false);

const isInvalidForm = computed(() => !!otpFormDef.validation?.$invalid);

function show() {
  refreshOtp();
  modal.value?.show();
}

function cancel() {
  emit('cancel');
}

function refreshOtp() {
  requestManager.currentOrNew('refreshOtp', props.refreshOtpRequest()).subscribe(() => {
    otpError.value = false;
    otpFormDef.form.otp = [];
  });
}

function triggerRequest() {
  if (otpFormDef.validation) {
    submitForm(otpFormDef.validation);
  }

  if (otpFormDef.validation?.$invalid) {
    return;
  }
  const otp: string = toDataModel(otpFormDef.form).otp;

  requestManager.cancelAndNew('otpSubmit', props.request(otp)).subscribe(
    (result) => {
      emit('completed', result);
    },
    (error) => {
      if (props.otpErrorCheck(error)) {
        otpError.value = true;
      } else {
        emit('error', error);
      }
    }
  );
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-submit') {
    triggerRequest();
  }
}

defineExpose({ show: show });
</script>

<template>
  <BModal ref="modal" title="OTP required" hide-header-close no-close-on-esc no-close-on-backdrop>
    <p class="resend-code-text">The security of your account is important to us.</p>
    <p class="resend-code-text" v-html="otpMessage(otpPhone)" />
    <p v-if="staticOTP" class="text-secondary text-center">For testing purposes, code is always 123456</p>
    <ValidatedForm ref="form" :fm="otpFormDef.form" @form-event="onFormEvent" />
    <p v-if="otpError" class="text-error text-center">
      Invalid OTP code sent.
      <a href="" @click.prevent="refreshOtp" :disabled="requestManager.requestStates.refreshOtp === 'pending'">
        {{ requestManager.requestStates.refreshOtp !== 'pending' ? 'Resend code?' : 'Resending...' }}
      </a>
    </p>
    <template v-slot:modal-footer>
      <div class="form-actions text-right">
        <VButton
          class="btn btn-secondary mr-2"
          :disabled="requestManager.requestStates.otpSubmit === 'pending'"
          label="Cancel"
          @click="cancel"
        />
        <VButton
          :disabled="isInvalidForm || requestManager.anyPending"
          :loading="requestManager.requestStates.otpSubmit === 'pending'"
          label="Confirm"
          class="btn btn-success"
          @click="triggerRequest"
        />
      </div>
    </template>
  </BModal>
</template>
