import { cloneDeep, isEqual } from 'lodash';
import { Pinia, PiniaPlugin } from 'pinia';
import { PersistedStateOptions, StorageLike } from 'pinia-plugin-persistedstate';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Vue from 'vue';

/**
 * Pinia tabsynced persistence plugin installer
 *
 * Installs both the pinia-plugin-persistedstate plugin and our tabSyncPlugin, ensuring correct defaults are added to it
 */
export function useTabSyncSafePersistence(pinia: Pinia) {
  pinia.use(piniaPersistenceTabSyncPlugin);
  pinia.use(piniaPluginPersistedstate);
}

/**
 * Pinia plugin to run hydration whenever:
 * - a store's localStorage value is updated from another tab
 * - a tab comes into view (preventing stale data if the tab was hibernating and no storage events were listened to)
 *
 * Will only run on stores that have persistence turned on, and will check for a match with any of the store's persistence keys with the LocalStorage event
 */
const piniaPersistenceTabSyncPlugin: PiniaPlugin = (ctx) => {
  if (ctx.options.persist) {
    if (ctx.options.persist === true) {
      ctx.options.persist = {};
    }

    const persistOptions: PersistedStateOptions[] = Array.isArray(ctx.options.persist)
      ? ctx.options.persist
      : [ctx.options.persist as PersistedStateOptions];

    persistOptions.forEach((o) => {
      o.storage = tabSyncSafeStorage(o.storage);

      // FIXME - ensuring pinia state keeps persistence - not needed after Vue 3.0 update
      const afterRestore = o.afterRestore;
      o.afterRestore = (context) => {
        Object.keys(context.store.$state).forEach((k) =>
          Vue.set(context.store.$state, k, cloneDeep(context.store.$state[k]))
        );
        if (afterRestore) {
          afterRestore(context);
        }
      };
    });

    const storageKeys: string[] = persistOptions.map((c) => {
      if (c.key) {
        return typeof c.key === 'function' ? c.key(ctx.store.$id) : c.key;
      }
      return ctx.store.$id;
    });

    window.addEventListener(
      'storage',
      (event) => {
        if (event.storageArea === localStorage && event.key && storageKeys.includes(event.key)) {
          ctx.store.$hydrate();
        }
      },
      false
    );

    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        ctx.store.$hydrate();
      }
    });
  }
};

/**
 * Tab-sync safe storage (will not store values if there are no changes, avoiding cycles of corss-tab storage event loops)
 */
const tabSyncSafeStorage = (storage: StorageLike = localStorage): StorageLike => ({
  getItem: (key: string) => storage.getItem(key),
  setItem: (key: string, value: string) => {
    try {
      const storedState = JSON.parse(storage.getItem(key) ?? '');
      const newState = JSON.parse(value);
      if (!isEqual(newState, storedState)) {
        storage.setItem(key, value);
      }
    } catch (e) {
      storage.setItem(key, value);
    }
  },
});
