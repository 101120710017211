<script lang="ts" setup>
import { BrandingImageType } from 'ah-api-gateways';
import { useTheme } from 'ah-theme';
import { computed } from 'vue';

const theme = useTheme();

const thumbnailLogoLink = computed(() => theme.val?.logos.find((l) => l.type === BrandingImageType.THUMBNAIL)?.link);
</script>

<template>
  <div class="app-logo">
    <img :src="thumbnailLogoLink" alt="" v-if="thumbnailLogoLink" />
  </div>
</template>

<style lang="scss" scoped>
.app-logo {
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
