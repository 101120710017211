import { HttpBasedService, HttpService } from 'ah-requests';
import { Product } from '../models/products';
import { PaginatedQuery, PaginatedResponse, standardiseQuerySort } from '../models';

export class ProductService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private adminBaseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'productService' } },
      },
    });
  }

  public listProduct(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<Product>>(`${this.baseUrl}products`, {
      axiosConfig: { params },
    });
  }
}
