<script setup lang="ts">
import { PropType, ref } from 'vue';
import { TradeFundingDetails, ClientRiskCollateralProfile } from 'ah-api-gateways';
import { ErrorExclamationIcon } from 'ah-common-lib/src/icons/components';
import PostCollateralTransactionExecutor from '../../limitsAndCollateral/manageCollateral/PostCollateralTransactionExecutor.vue';
import DataRow from 'ah-common-lib/src/common/components/DataRow.vue';
import TradesFormattedCurrency from '../../..//currency/TradesFormattedCurrency.vue';

defineProps({
  collateralProfile: {
    type: Object as PropType<ClientRiskCollateralProfile>,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  isFundWithCollateralPossible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'update:tradeFundingDetails', value: Partial<TradeFundingDetails>): void;
  (e: 'reload-collaterals'): void;
}>();

const hasPostedCollateral = ref(false);

function reCheckCollaterals() {
  hasPostedCollateral.value = true;
  emit('reload-collaterals');
}
</script>

<template>
  <div>
    <template>
      <DataRow cols="6" label="Available Posted Collateral" class="mb-2" labelClass="font-weight-bold">
        <TradesFormattedCurrency :value="collateralProfile.availableCollateral" :currency="currency" />
      </DataRow>
      <p class="text-secondary" v-if="isFundWithCollateralPossible && !hasPostedCollateral">
        You have sufficient margin collateral available to execute this trade.
      </p>
      <p class="text-secondary" v-else-if="isFundWithCollateralPossible && hasPostedCollateral">
        You have posted sufficient collateral for this trade.
      </p>
      <div v-else>
        <div class="form-error-wrapper">
          <div>
            <ErrorExclamationIcon class="icon" />
          </div>
          <div class="text-danger">You need to post collateral to execute this trade</div>
        </div>
        <div class="bordered-box">
          <h2 class="mb-2">Post collateral</h2>
          <PostCollateralTransactionExecutor
            :collateralProfile="collateralProfile"
            @transaction-executed="reCheckCollaterals"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.form-error-wrapper {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  .icon {
    width: 1rem;
    height: auto;
    margin-right: 1rem;
  }
}

.bordered-box {
  border: 1px solid;
  padding: $padded-space;
  @include themedBorderColor($color-widgets-green);
}

.funding-type-form {
  margin-top: 2rem;
  ::v-deep {
    .field-group-input-container {
      display: flex;
    }
  }
}
</style>
