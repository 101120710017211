import { helpers } from '@vuelidate/validators';
import get from 'lodash/get';
import { BaseFormModel, FormModel } from '../interfaces/form';

export type ValueGetter = string | ((obj: FormModel) => any);

export type ValueValidator<T = any> = (val: any, compare: T) => boolean;

function getValue(obj: FormModel, valueGetter: ValueGetter) {
  if (typeof valueGetter === 'string') {
    return get(obj, valueGetter);
  }
  return valueGetter(obj);
}

export const valueValidator = (
  valueGetter: ValueGetter,
  validator: ValueValidator = (val, compare) => !!compare,
  validatorParams: { [key: string]: any } = {}
) =>
  helpers.withParams({ ...validatorParams }, (val: any, obj: FormModel) => validator(val, getValue(obj, valueGetter)));

export const checkParam = (fieldName: string, param: string, validatorParams: { [key: string]: any } = {}) =>
  valueValidator(
    (obj) => {
      const found = obj.$fields.find((f: BaseFormModel) => f.$name === fieldName);
      return found && found.$state[param];
    },
    (val, compare) => !!compare,
    validatorParams
  );

export const compareState = (
  fieldName: string,
  validator?: ValueValidator,
  validatorParams: { [key: string]: any } = {}
) =>
  valueValidator(
    (obj) => {
      const found = obj.$fields.find((f: BaseFormModel) => f.$name === fieldName);
      return found && found.$state;
    },
    validator,
    validatorParams
  );

export const compareParam = (
  fieldName: string,
  param: string,
  validator?: ValueValidator,
  validatorParams: { [key: string]: any } = {}
) =>
  valueValidator(
    (obj) => {
      const found = obj.$fields.find((f: BaseFormModel) => f.$name === fieldName);
      return found && found.$state[param];
    },
    validator,
    validatorParams
  );

export const compareField = (
  fieldName: string,
  validator?: ValueValidator,
  validatorParams: { [key: string]: any } = {}
) => valueValidator((obj) => obj[fieldName], validator, validatorParams);

export const sameAsField = (fieldName: string) => compareField(fieldName, (value, compare) => value === compare);
