<script setup lang="ts">
import { PaginatedParams, PaymentState } from 'ah-api-gateways';
import WalletIcon from '@/app/icons/svgs/WalletIcon.vue';
import PaymentListing from 'ah-payments/src/components/PaymentListing.vue';
import { useAuthStore } from '@/app/store/authStore';
import { upcomingPaymentsTableFields } from './tableFields/upcomingPaymentsTableFields';

const authStore = useAuthStore();

const upcomingPaymentsFilters = {
  state: [PaymentState.PENDING, PaymentState.QUEUED, PaymentState.SCHEDULED],
  excludeClients: !authStore.isClientUser,
  excludePartners: authStore.isClientUser,
  ...(authStore.isClientUser
    ? { clientId: authStore.loggedInIdentity?.client?.id }
    : { partnerId: authStore.loggedInIdentity?.partner.id }),
};

const upcomingPaymentsConfig = {
  tableFields: upcomingPaymentsTableFields,
};

const upcomingPaymentsSortAndPageParams: Partial<PaginatedParams> = {
  sort: 'executionDate',
  sortDirection: 'ASC',
  pageSize: 5,
};
</script>

<template>
  <div class="upcoming-payments-widget">
    <PaymentListing
      :config="upcomingPaymentsConfig"
      :filter="upcomingPaymentsFilters"
      :hidePagination="true"
      :showExport="false"
      :sortAndPageParams="upcomingPaymentsSortAndPageParams"
      :withRowDetails="false"
    >
      <template #tableNoResultsState>
        <div class="no-results-info">
          <div class="wallet-icon">
            <WalletIcon />
          </div>
          There are no upcoming payments
        </div>
      </template>
    </PaymentListing>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .no-results-info {
    margin: 3.5rem 0;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);

    .wallet-icon {
      margin: 1rem 0;
      svg {
        width: 3rem;
        height: auto;
        path {
          @include themedPropColor('fill ', $color-text-secondary, $color-dark-text-secondary);
        }
      }
    }
  }
}
</style>
