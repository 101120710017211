<script lang="ts" setup>
import { AdminTrade } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed } from 'vue';

const props = defineProps<{
  trade: AdminTrade;
}>();

const partnerCommissionPercentage = computed(() => {
  if (props.trade.partnerCommissionPercentage > -1) {
    return `${(props.trade.partnerCommissionPercentage * 100).toFixed(1)}`;
  }
  return '';
});
</script>

<template>
  <div>
    <DataRow cols="6" label="Partner Profit" v-if="trade.gbpPartnerProfit > -1" class="mb-2 trade-margin-in-use">
      GBP
      {{ formatCurrencyValue(trade.gbpPartnerProfit) }}
    </DataRow>
    <DataRow cols="6" label="ALT 21 Profit" v-if="trade.gbpAhProfit > -1" class="mb-2 trade-initial-margin">
      GBP
      {{ formatCurrencyValue(trade.gbpAhProfit) }}
    </DataRow>
    <DataRow
      cols="6"
      label="Partner Commission Percentage"
      v-if="partnerCommissionPercentage"
      class="mb-2 trade-variation-margin align-items-end"
    >
      {{ partnerCommissionPercentage }}%
    </DataRow>
  </div>
</template>
