export enum SyncMessageType {
  actionRequest = 'actionRequest',
  actionResponse = 'actionResponse',
}

export interface SyncMessage<T = any> {
  /**
   * Message type - may be expanded to include other functionalities
   */
  type: SyncMessageType;
  payload: T;
}

export interface ActionRequestSyncMessage extends SyncMessage {
  type: SyncMessageType.actionRequest;
  payload: {
    name: string;
    /**
     * Whether to target only the leader tab
     */
    leaderOnly: boolean;
    /**
     * Whether to include self in the message recipients
     */
    includeSelf: boolean;
    id: string;
    data?: any;
  };
}

export interface ActionResponseSyncMessage extends SyncMessage {
  type: SyncMessageType.actionResponse;
  payload: {
    name: string;
    id: string;
    success: boolean;
  };
}

export function isActionRequestMessage(message: SyncMessage): message is ActionRequestSyncMessage {
  return message.type === SyncMessageType.actionRequest;
}

export function isActionResponseMessage(message: SyncMessage): message is ActionResponseSyncMessage {
  return message.type === SyncMessageType.actionResponse;
}
