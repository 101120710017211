<script setup lang="ts">
import UboInfo from '@/app/components/ubos/UboInfo.vue';
import { BaseIndividual, ClientFileCategories, UploadedFile } from 'ah-api-gateways';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { useAuthStore } from '@/app/store/authStore';
import { tap } from 'rxjs/operators';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, ref } from 'vue';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const services = getServices();

const requestManager = useRequestManager().manager;

const ubos = ref<BaseIndividual[]>([]);

const clientWithUbos = computed(() => ubos.value.length > 0);

const authStore = useAuthStore();

const individualSettingsStore = useIndividualSettingsStore();

const clientId = authStore.loggedInIdentity!.client!.id;

const resolutionLetter = computed(
  () =>
    (individualSettingsStore.clientDocuments || []).find(
      (document) => document.category === ClientFileCategories.RESOLUTION_LETTER
    ) || null
);

function uploadFile(file: File) {
  return services.client.uploadDocument(clientId, ClientFileCategories.RESOLUTION_LETTER, file).pipe(
    tap((update) => {
      if (update.finished) {
        services.client.getDocuments(clientId).subscribe((docs) => {
          const document = docs.find((d) => d.id === update.file.id);

          if (document) {
            individualSettingsStore.setClientDocument({ document });
          }
        });
      }
    })
  );
}

function deleteFile(file: UploadedFile) {
  return services.client.deleteDocument(clientId, file.id).pipe(
    tap(() => {
      individualSettingsStore.unsetClientDocument({ category: file.category as ClientFileCategories });
    })
  );
}

function downloadFile(file: UploadedFile) {
  return services.client.downloadSyncDocument(clientId, file);
}

requestManager.newPromise(
  'loadClientUbos',
  individualSettingsStore.loadClientUbos(true).then((response) => (ubos.value = response || []))
);
</script>

<template>
  <div class="padded-space" id="bank-accounts-settings-view">
    <div class="centered-space">
      <h2>Ultimate Beneficial Owners</h2>
      <BoxGrid alignH="start">
        <BoxGridBlock
          :sm="clientWithUbos ? 12 : 6"
          :lg="clientWithUbos ? 10 : 6"
          :loadingOverlayProps="{ state: requestManager.requestStates['loadClientUbos'] }"
          useLoadingOverlay
          overlayType="simple"
        >
          <template v-if="requestManager.requestStates['loadClientUbos'] !== 'pending'">
            <h3>Ultimate Beneficial Owner Information</h3>
            <template v-if="clientWithUbos">
              <p class="text-small text-muted mb-5">
                An Ultimate Beneficial Owner (UBO) is somebody who owns 25% or more of the organisation.
              </p>
              <UboInfo class="ubo-block" v-for="ubo in ubos" :key="ubo.id" :ubo="ubo" />
            </template>
            <template v-else>
              <FileUploader
                title="Proof of Authorised Signatory Document"
                description="Please provide us with the document to verify the authorised users for the account. i.e. A letter headed letter which has been signed and dated."
                :maxSize="10485760"
                :accept="defaultAcceptanceFileTypes"
                acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                :uploadRequest="uploadFile"
                :downloadRequest="downloadFile"
                :deleteRequest="deleteFile"
                :uploaded="resolutionLetter"
              />
            </template>
          </template>
        </BoxGridBlock>
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ubo-block {
  border-bottom: 2px solid;
  @include themedBorderColor($color-primary, $color-dark-primary);
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;
}
</style>
