<script lang="ts" setup>
import { QuotePriceResponse, getNonFixedSideCcy, PricingCurrency } from 'ah-api-gateways/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    /**
     * Pricing response of a trade.
     * Will always have precedence over `trade`
     */
    price?: QuotePriceResponse | null;
    /**
     * Whether to not display the values and present a loading icon
     * instead.
     */
    calculating: boolean | string;
  }>(),
  {
    calculating: false,
  }
);

const isCalculating = computed(() => props.calculating !== false);

const nonFixedSideCcy = computed(() => {
  if (props.price) {
    return getNonFixedSideCcy(props.price);
  }
  return null;
});

const partnerProfit = computed(() => {
  if (props.price) {
    return (nonFixedSideCcy.value as PricingCurrency).profitData?.gbpPartnerProfit;
  }
  return null;
});

const partnerCommission = computed(() =>
  props.price?.partnerCommissionPercentage
    ? {
        collateralValue: (props.price.partnerCommissionPercentage.collateralValue * 100).toFixed(0),
        creditValue: (props.price.partnerCommissionPercentage.creditValue * 100).toFixed(0),
        noInitialMarginValue: (props.price.partnerCommissionPercentage.noInitialMarginValue * 100).toFixed(0),
      }
    : undefined
);
</script>

<template>
  <div class="text-secondary">
    <table v-if="partnerCommission && partnerProfit">
      <thead>
        <tr>
          <th class="pr-5">IM funded with:</th>
          <th>Partner Profit (Commission %)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="pr-5">Posted Collateral</td>
          <td v-if="!isCalculating">
            GBP {{ formatCurrencyValue(partnerProfit.collateralValue) }} ({{ partnerCommission.collateralValue }}%)
          </td>
          <td v-else>-</td>
        </tr>
        <tr>
          <td class="pr-5">Credit Facility</td>
          <td v-if="!isCalculating">
            GBP {{ formatCurrencyValue(partnerProfit.creditValue) }} ({{ partnerCommission.creditValue }}%)
          </td>
          <td v-else>-</td>
        </tr>
        <tr>
          <td class="pr-5">No IM requirement</td>
          <td v-if="!isCalculating">
            GBP {{ formatCurrencyValue(partnerProfit.noInitialMarginValue) }} ({{
              partnerCommission.noInitialMarginValue
            }}%)
          </td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </table>
    <slot name="after-content" />
  </div>
</template>
