<script setup lang="ts">
import SingleOwnerOutstandingFeesBlock from 'ah-trades/src/components/fees/SingleOwnerOutstandingFeesBlock.vue';
import { Client } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import { useRoute, useRouter } from 'vue-router/composables';

const requestManager = useRequestManager().manager;
const services = getServices();
const route = useRoute();
const router = useRouter();

const client = ref<Client | null>(null);

const clientId = computed(() => route.params.clientOrPartnerId);

function onClientOrPartnerIdChange() {
  requestManager
    .sameOrCancelAndNew('getClient', services.client.getClient(clientId.value))
    .subscribe((response) => (client.value = response));
}

watch(() => route.params.clientOrPartnerId, onClientOrPartnerIdChange, { immediate: true });

function goBack() {
  router.push('../');
}
</script>

<template>
  <LoadingOverlay :state="requestManager.requestStates.getClient" show-retry @retry="onClientOrPartnerIdChange">
    <div class="padded-space" v-if="client">
      <VRow class="mx-0" align-v="center">
        <h2 class="mb-0">Outstanding Fees</h2>
        <p class="client-name ml-3 mb-0">{{ client.name }}</p>
      </VRow>
      <BackButton label="go back" @click="goBack()" />
      <BoxGridBlock class="inner">
        <SingleOwnerOutstandingFeesBlock :clientOrPartnerId="clientId" userType="client" />
      </BoxGridBlock>
    </div>
  </LoadingOverlay>
</template>
