<script setup lang="ts">
import { computed, ref } from 'vue';
import { BModal } from 'bootstrap-vue';
import { useAuthStore } from '@/app/store/authStore';
import config from '@/config';
import UsernameChangeEditor from '../../common/UsernameChangeEditor.vue';

const changeModal = ref<InstanceType<typeof BModal>>();
const authStore = useAuthStore();

const currentUsername = computed(() => authStore.loggedInUser?.usernamePresentation);
const currentPhoneLastDigits = computed(
  () =>
    authStore.loggedInUser?.phoneNumber?.substring(Math.max(0, authStore.loggedInUser?.phoneNumber?.length - 3)) ?? ''
);
const staticOTP = computed(() => config.staticOTP);
const tfaEnabled = computed(() => !!authStore.userData?.tfaEnabled);

const closeChangeModal = () => changeModal.value?.hide();
const openChangeModal = () => changeModal.value?.show();

const handleSuccess = () => {
  closeChangeModal();
};

const handleCancel = () => {
  closeChangeModal();
};
</script>

<template>
  <div class="username-change">
    <div class="field-title mb-1">Username</div>
    <div class="username">{{ currentUsername }}</div>
    <div class="link">
      <a class="text-secondary change-link text-small" @click="openChangeModal">Change username</a>
    </div>
    <BModal modal-class="change-modal" title="Change username" ref="changeModal" hide-footer>
      <div>
        <div class="mb-3">
          <div class="field-title mb-1">Current username</div>
          <div class="username">{{ currentUsername }}</div>
        </div>
        <UsernameChangeEditor
          :current-username="currentUsername"
          :tfa-enabled="tfaEnabled"
          :static-OTP="staticOTP"
          :current-phone-last-digits="currentPhoneLastDigits"
          field-title="New username"
          @success="handleSuccess"
          @cancel="handleCancel"
        />
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.username {
  word-wrap: break-word;
}

.change-link {
  text-decoration: underline;
}
</style>
