export enum TradeStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  AMENDED = 'AMENDED',
  CANCELED = 'CANCELED',
  DATE_ROLLED = 'DATE_ROLLED',
  FULLY_SETTLED = 'FULLY_SETTLED',
  REMAINING_SETTLED = 'REMAINING_SETTLED',
  SUBMITTED = 'SUBMITTED',
  VERIFIED = 'VERIFIED',
  CANCELING = 'CANCELING',
  PARTIALLY_OPEN = 'PARTIALLY_OPEN',
  REMAINING_CLOSED = 'REMAINING_CLOSED',
  REMAINING_CANCELING = 'REMAINING_CANCELING',
  REMAINING_CANCELED = 'REMAINING_CANCELED',
  REMAINING_DATE_ROLLED = 'REMAINING_DATE_ROLLED',
  REJECTED = 'REJECTED',
}

export enum BHTradeStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  AMENDED = 'AMENDED',
  CANCELED = 'CANCELED',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  CANCELING = 'CANCELING',
  SUBMITTED = 'SUBMITTED',
  DATE_ROLLED = 'DATE_ROLLED',
  FULLY_SETTLED = 'FULLY_SETTLED',
  REMAINING_SETTLED = 'REMAINING_SETTLED',
  PARTIALLY_OPEN = 'PARTIALLY_OPEN',
  REMAINING_DATE_ROLLED = 'REMAINING_DATE_ROLLED',
  REMAINING_CANCELING = 'REMAINING_CANCELING',
  REMAINING_CANCELED = 'REMAINING_CANCELED',
  REMAINING_CLOSED = 'REMAINING_CLOSED',
  OVERDUE = 'OVERDUE',
  SETTLES_TODAY = 'SETTLES_TODAY',
}

export const adminOnlyTradeStatus: TradeStatus[] = [TradeStatus.AMENDED];

export const openTradeStatuses = [
  TradeStatus.OPEN,
  TradeStatus.SUBMITTED,
  TradeStatus.VERIFIED,
  TradeStatus.PARTIALLY_OPEN,
];

export const openBHTradeStatuses = [
  BHTradeStatus.OPEN,
  BHTradeStatus.SUBMITTED,
  BHTradeStatus.VERIFIED,
  BHTradeStatus.PARTIALLY_OPEN,
];

/**
 * Active margin statuses for a trade
 *
 * Trades in this state will apply their margin values against the Client's Collaterals
 *
 * Exceptions (will never apply margins):
 *  - Trades with `syncFunds == true`
 *  - Spot Trades with `spotsIm == false`
 */
export const activeMarginTradeStatuses = [TradeStatus.VERIFIED, TradeStatus.OPEN, TradeStatus.PARTIALLY_OPEN];

/**
 * Closed trade statuses
 */
export const closedTradeStatuses = [
  TradeStatus.CANCELED,
  TradeStatus.CANCELING,
  TradeStatus.REMAINING_CANCELING,
  TradeStatus.CLOSED,
  TradeStatus.AMENDED,
  TradeStatus.FULLY_SETTLED,
  TradeStatus.REMAINING_SETTLED,
  TradeStatus.REMAINING_CLOSED,
  TradeStatus.REMAINING_CANCELED,
  TradeStatus.DATE_ROLLED,
  TradeStatus.REMAINING_DATE_ROLLED,
  TradeStatus.REJECTED,
];

export const closedBHTradeStatuses = [
  BHTradeStatus.CANCELED,
  BHTradeStatus.CANCELING,
  BHTradeStatus.REMAINING_CANCELING,
  BHTradeStatus.CLOSED,
  BHTradeStatus.AMENDED,
  BHTradeStatus.FULLY_SETTLED,
  BHTradeStatus.REMAINING_SETTLED,
  BHTradeStatus.REMAINING_CLOSED,
  BHTradeStatus.REMAINING_CANCELED,
  BHTradeStatus.DATE_ROLLED,
  BHTradeStatus.REMAINING_DATE_ROLLED,
  BHTradeStatus.REJECTED,
];

export const tradeStatusBadges: Record<TradeStatus, { label: string; class: string }> = {
  [TradeStatus.SUBMITTED]: {
    label: 'Submitted',
    class: 'light-blue',
  },
  [TradeStatus.VERIFIED]: {
    label: 'Verified',
    class: 'light-blue',
  },
  [TradeStatus.OPEN]: {
    label: 'Open',
    class: 'light-purple',
  },
  [TradeStatus.AMENDED]: {
    label: 'Amended',
    class: 'black',
  },
  [TradeStatus.PARTIALLY_OPEN]: {
    label: 'Partially Open',
    class: 'light-purple',
  },
  [TradeStatus.DATE_ROLLED]: {
    label: 'Date rolled',
    class: 'light-red',
  },
  [TradeStatus.REMAINING_DATE_ROLLED]: {
    label: 'Remaining Date rolled',
    class: 'light-red',
  },
  [TradeStatus.REJECTED]: {
    label: 'Rejected',
    class: 'red',
  },
  [TradeStatus.REMAINING_CANCELED]: {
    label: 'Remaining Cancelled',
    class: 'light-red',
  },
  [TradeStatus.CANCELING]: {
    label: 'Cancelling',
    class: 'light-red',
  },
  [TradeStatus.REMAINING_CANCELING]: {
    label: 'Remaining Cancelling',
    class: 'light-red',
  },
  [TradeStatus.CANCELED]: {
    label: 'Cancelled',
    class: 'light-red',
  },
  [TradeStatus.FULLY_SETTLED]: {
    label: 'Fully Settled',
    class: 'green',
  },
  [TradeStatus.REMAINING_SETTLED]: {
    label: 'Remaining Settled',
    class: 'green',
  },
  [TradeStatus.CLOSED]: {
    label: 'Closed',
    class: 'green',
  },
  [TradeStatus.REMAINING_CLOSED]: {
    label: 'Remaining Closed',
    class: 'green',
  },
};

export const bhTradeStatusBadges: Record<BHTradeStatus, { label: string; class: string }> = {
  [BHTradeStatus.SUBMITTED]: {
    label: 'Submitted',
    class: 'light-blue',
  },
  [BHTradeStatus.VERIFIED]: {
    label: 'Verified',
    class: 'light-blue',
  },
  [BHTradeStatus.OPEN]: {
    label: 'Open',
    class: 'light-purple',
  },
  [BHTradeStatus.AMENDED]: {
    label: 'Amended',
    class: 'black',
  },
  [BHTradeStatus.PARTIALLY_OPEN]: {
    label: 'Partially Open',
    class: 'light-purple',
  },
  [BHTradeStatus.DATE_ROLLED]: {
    label: 'Date rolled',
    class: 'light-red',
  },
  [BHTradeStatus.REMAINING_DATE_ROLLED]: {
    label: 'Remaining Date rolled',
    class: 'light-red',
  },
  [BHTradeStatus.REJECTED]: {
    label: 'Rejected',
    class: 'red',
  },
  [BHTradeStatus.REMAINING_CANCELED]: {
    label: 'Remaining Cancelled',
    class: 'light-red',
  },
  [BHTradeStatus.CANCELING]: {
    label: 'Cancelling',
    class: 'light-red',
  },
  [BHTradeStatus.REMAINING_CANCELING]: {
    label: 'Remaining Cancelling',
    class: 'light-red',
  },
  [BHTradeStatus.CANCELED]: {
    label: 'Cancelled',
    class: 'light-red',
  },
  [BHTradeStatus.FULLY_SETTLED]: {
    label: 'Fully Settled',
    class: 'green',
  },
  [BHTradeStatus.REMAINING_SETTLED]: {
    label: 'Remaining Settled',
    class: 'green',
  },
  [BHTradeStatus.CLOSED]: {
    label: 'Closed',
    class: 'green',
  },
  [BHTradeStatus.REMAINING_CLOSED]: {
    label: 'Remaining Closed',
    class: 'green',
  },
  [BHTradeStatus.OVERDUE]: {
    label: 'Overdue',
    class: 'light-red',
  },
  [BHTradeStatus.SETTLES_TODAY]: {
    label: 'Settles Today',
    class: 'green',
  },
};

export const clientTradeStatus = (Object.keys(tradeStatusBadges) as TradeStatus[]).filter(
  (k) => !adminOnlyTradeStatus.includes(k)
);
