import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const beneficiaryTransactionsTableFields: TableFieldDefinition[] = [
  {
    header: 'Date',
    key: 'docCreatedAt',
    sortable: true
  },
  { header: 'Debit', key: 'debit' },
  { header: 'Status', key: 'status' }
];

export const beneficiaryTransactionsLongTableFields: TableFieldDefinition[] = [
  {
    header: 'Date',
    key: 'docCreatedAt',
    sortable: true
  },
  { header: 'Debit', key: 'debit' },
  { header: 'Credit', key: 'credit' },
  { header: 'Balance', key: 'walletBalance' },
  { header: 'State', key: 'state' }
];

export const walletTransactionsTableFields: TableFieldDefinition[] = [
  {
    header: 'Date',
    key: 'docCreatedAt',
    sortable: true
  },
  { header: 'Debit', key: 'debit' },
  { header: 'Credit', key: 'credit' },
  { header: 'Balance', key: 'walletBalance' },
  { header: 'Category', key: 'type' }
];

export const walletScheduledTransactionsTableFields: TableFieldDefinition[] = [
  {
    header: 'Date',
    key: 'executionDate',
    sortable: true
  },
  { header: 'Debit', key: 'debit' },
  { header: 'Credit', key: 'credit' },
  { header: 'Balance', key: 'walletBalance' },
  { header: 'Category', key: 'type' }
];

export const shortWalletScheduledTransactionsTableFields: TableFieldDefinition[] = [
  { header: 'Value', key: 'value', class: 'short-column' },
  {
    header: 'Date',
    key: 'docCreatedAt',
    class: 'pl-4'
  }
];
