import _Vue from 'vue';

function setWidth(el: HTMLElement, ratio = { x: 0.8, y: 1 }) {
  const textSize = parseFloat(getComputedStyle(el).fontSize);
  if (el.parentElement) {
    const widthRatio = getInnerWidth(el.parentElement) / el.offsetWidth;
    const heightRatio = getInnerHeight(el.parentElement) / el.offsetHeight;
    if (widthRatio || heightRatio) {
      if (widthRatio < heightRatio) {
        el.style.fontSize = `${widthRatio * textSize * ratio.x}px`;
      } else {
        el.style.fontSize = `${heightRatio * textSize * ratio.y}px`;
      }
    }
  }
}

function getInnerHeight(el: HTMLElement) {
  const computed = getComputedStyle(el),
    padding = (parseInt(computed.paddingTop) || 0) + (parseInt(computed.paddingBottom) || 0);

  return el.clientHeight - padding;
}

function getInnerWidth(el: HTMLElement) {
  const computed = getComputedStyle(el),
    padding = (parseInt(computed.paddingRight) || 0) + (parseInt(computed.paddingLeft) || 0);

  return el.clientWidth - padding;
}

export default {
  install: function install(Vue: typeof _Vue) {
    Vue.directive('fit-text', {
      bind(el: HTMLElement, binding: any) {
        setTimeout(() => {
          setWidth(el, binding.value);
        });
      },
      componentUpdated(el: HTMLElement, binding) {
        setWidth(el, binding.value);
      },
    });
  },
};
