<script setup lang="ts">
import config from '@/config';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { makeFormModel, toDataModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { otpField } from 'ah-common-lib/src/form/models';
import { FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { AuthErrorCodes, SecurityErrorCodes } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { otpMessage } from 'ah-common-lib/src/helpers/otp';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';
import { computed, onMounted, PropType, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';

const props = defineProps({
  otpPhone: {
    type: String,
    required: true,
  },
  expiry: {
    type: Object as PropType<ExpiryTime>,
  },
  showBackToLogin: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits({
  error: (_error: any) => true,
  success: () => true,
  cancel: () => true,
});

const requestManager = useRequestManager().manager;

const authStore = useAuthStore();

const toast = useToast();

const services = getServices();

const staticOTP = config.staticOTP;

const otpFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'otpFormDef',
    title: 'OTP',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  }),
  validation: null,
});

const form = ref<InstanceType<typeof ValidatedForm>>();

const minutesToExpire = ref<number>();

const expiredOtp = ref<boolean>(false);

const isInvalid = computed(() => !!otpFormDef.validation?.$invalid);

function onExpiredOtpChange() {
  otpFormDef.form.otp = [];
  const otpField = getChildModel(otpFormDef.form, 'otp');
  if (otpField) {
    setState(otpField, 'readonly', expiredOtp.value);
  }
}

watch(expiredOtp, onExpiredOtpChange);

function onExpiryChange() {
  if (props.expiry?.expiresIn) {
    minutesToExpire.value = props.expiry.expiresIn / 60;
  }
}

watch(() => props.expiry, onExpiryChange, { immediate: true });

/**
 * Makes the OTP filed in focus to enter the code
 */
onMounted(() => {
  form.value?.form.triggerFieldAction('otpFormDef.otp', 'focus');
});

function otpContinue() {
  if (otpFormDef.validation?.$invalid) {
    return;
  }
  otp();
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-submit') {
    otpContinue();
  }
}

function otp() {
  requestManager.currentOrNewPromise('sendOtp', () =>
    authStore.otp(toDataModel(otpFormDef.form).otp).then(
      () => onOtpSuccess(),
      (error: any) => {
        if (error.response) {
          if (error.response?.data?.code === AuthErrorCodes.OTP_ATTEMPTS_EXCEEDED) {
            toast.error(error.response?.data?.message);
            backToLogin();
          } else if (error.response?.data?.code === SecurityErrorCodes.INVALID_OTP) {
            toast.error('Incorrect access code sent');
          } else if (error.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
            toast.error('Expired OTP access code. Please resend code.');
            expiredOtp.value = true;
          } else {
            toast.error('Oops, there was an error while trying to validate your OTP');
          }
        }
        emit('error', error);
      }
    )
  );
}

function refreshOtp() {
  requestManager.currentOrNew('refreshOtp', services.auth.refreshOtp()).subscribe(
    (res) => {
      // clear otp, would be nice to focus on first input of the form
      otpFormDef.form.otp = [];
      expiredOtp.value = false;
      toast.success('Two-step authentication code resent');
      if (res?.expiresIn) {
        minutesToExpire.value = res.expiresIn / 60;
      }
    },
    (error) => {
      if (error.response) {
        if (error.response?.data?.code === SecurityErrorCodes.INVALID_OTP) {
          toast.error('Incorrect access code sent');
        } else if (error.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
          toast.error('Expired OTP access code. Please resend code.');
          expiredOtp.value = true;
        } else {
          toast.error('Oops, there was an error while trying to validate your OTP');
        }
      }
    }
  );
}

function onOtpSuccess() {
  emit('success');
}

/**
 * called when it is needed to go back and change the email; emits cancel
 */
function backToLogin() {
  emit('cancel');
}

defineExpose({ refreshOtp: refreshOtp });
</script>

<template>
  <div class="otp-form">
    <div class="mb-5">
      <p class="resend-code-text">The security of your account is important to us.</p>
      <p class="resend-code-text" v-html="otpMessage(otpPhone, minutesToExpire ?? undefined)" />
    </div>
    <p v-if="staticOTP" class="error text-center text-secondary">For testing purposes, code is always 123456</p>
    <ValidatedForm
      ref="form"
      :fm="otpFormDef.form"
      :validation.sync="otpFormDef.validation"
      @form-event="onFormEvent"
    />
    <div class="form-actions pt-5 text-center">
      <VButton
        v-if="expiredOtp"
        :loading="requestManager.anyPending"
        label="Rerequest OTP code"
        class="btn-secondary"
        @click="refreshOtp"
      />
      <VButton
        v-else
        :disabled="isInvalid"
        :loading="requestManager.anyPending"
        label="Verify and login"
        class="btn-success"
        @click="otpContinue"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.otp-form {
  .resend-code-text {
    margin-bottom: 1em;
  }

  .resend-link {
    margin-bottom: math.div($padded-space, 8);
  }

  .resend-code-link {
    display: block;
    margin-bottom: math.div($padded-space, 2);
    margin-top: math.div($padded-space, 2);
  }
}
</style>
