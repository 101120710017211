import {
  HedgingInstruments,
  LiquidityProvider,
  LiquidityProviderService,
  LiquidityProviderType,
  formatLiquidityProviders,
} from 'ah-api-gateways';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { defineStore } from 'pinia';
import { StoreSupportData } from 'ah-common-lib/src/store';
import { map } from 'rxjs/operators';

// Importing persisted state plugin for type completion
import 'pinia-plugin-persistedstate';
import { CachedItem } from 'ah-common-lib/src/helpers';
import Vue from 'vue';

export type LiquidityProviderStoreSupportData = StoreSupportData<
  {},
  {
    liquidityProvider: LiquidityProviderService;
  }
>;

export interface LiquidityProviderStoreSetupOptions {
  supportData: LiquidityProviderStoreSupportData;
}

let sd: LiquidityProviderStoreSupportData;

const useStore = defineStore('liquidityProviderStore', {
  persist: true,
  state: () => {
    return {
      liquidityProviders: new CachedItem<Partial<LiquidityProvider>[]>(),
      clientLiquidityProviders: {} as { [key: string]: CachedItem<Partial<LiquidityProvider>[]> },
    };
  },
  getters: {
    liquidityProvidersOptions(state) {
      return state.liquidityProviders.item
        ? state.liquidityProviders.item
            ?.reduce((acc, curr: Partial<LiquidityProvider>) => {
              if (curr.liquidityProvider && !acc.includes(curr.liquidityProvider)) {
                acc.push(curr.liquidityProvider);
              }
              return acc;
            }, [] as LiquidityProviderType[])
            .map((lp) => ({
              label: formatLiquidityProviders(lp),
              value: lp,
            }))
        : [];
    },
    liquidityProvidersClientOptions(state) {
      return (id: string, instrumentType: HedgingInstruments) =>
        state.clientLiquidityProviders[id]?.item
          ? state.clientLiquidityProviders[id].item
              ?.reduce((acc, curr: Partial<LiquidityProvider>) => {
                if (
                  curr.liquidityProvider &&
                  !acc.includes(curr.liquidityProvider) &&
                  curr.instrumentType === instrumentType
                ) {
                  acc.push(curr.liquidityProvider);
                }
                return acc;
              }, [] as LiquidityProviderType[])
              .map((lp) => ({
                label: formatLiquidityProviders(lp),
                value: lp,
              }))
          : [];
    },
  },
  actions: {
    loadLiquidityProviders(payload?: {
      enabled?: boolean;
      force?: boolean;
    }): Promise<Partial<LiquidityProvider>[] | null> {
      return CachedItem.loadCachedItem(
        this.liquidityProviders,
        sd.s.liquidityProvider
          .listLiquidityProvidersCapabilities({
            enabled: payload?.enabled ?? true,
            pageSize: 10,
            pageNumber: 0,
          })
          .pipe(map((response) => response.list)),
        payload?.force
      );
    },
    // FIXME - This function will be update to be filtered by bank provider type instead of clientId https://assurehedge.atlassian.net/browse/ST-2803
    loadClientLiquidityProviders(payload?: {
      clientId: string;
      enabled?: boolean;
      force?: boolean;
    }): Promise<Partial<LiquidityProvider>[] | null> {
      if (!payload || (payload && !payload.clientId)) {
        throw 'You must set the client id to access list of currencies';
      }

      if (!this.clientLiquidityProviders[payload.clientId]) {
        Vue.set(this.clientLiquidityProviders, payload.clientId, new CachedItem());
      }

      return CachedItem.loadCachedItem(
        this.clientLiquidityProviders[payload.clientId],
        sd.s.liquidityProvider
          .listLiquidityProvidersCapabilities({
            clientId: payload.clientId,
            enabled: payload.enabled ?? true,
            pageSize: 10,
            pageNumber: 0,
          })
          .pipe(map((response) => response.list)),
        payload?.force
      );
    },
    clearStore() {
      this.liquidityProviders = new CachedItem();
      this.clientLiquidityProviders = {};
    },
    async [commonStoreActions.onOutMaintenance]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogout]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogoutOtherTab]() {
      this.clearStore();
    },
  },
});

export type LiquidityProviderStore = ReturnType<typeof useStore>;

export function setupLiquidityProviderStore(options: LiquidityProviderStoreSetupOptions) {
  sd = options.supportData;
}

export function useLiquidityProviderStore(): LiquidityProviderStore {
  if (!sd) {
    throw 'Liquidity Provider store not setup! Please ensure setup method is called on app boot.';
  }
  return useStore();
}
