<script lang="ts" setup>
import TradeListing from '../../components/lists/TradeListing.vue';
import { tradeReportTableFieldsFull } from '../../models/tradeTableFields';
import { ExportListType, HedgingInstruments, TradeState, PaginatedParams, TradeStatus } from 'ah-api-gateways';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import TradeRowDetails from './TradeRowDetails.vue';
import TradeListFilters from '../../components/lists/TradeListFilters.vue';
import SortSelector from 'ah-common-lib/src/common/components/SortSelector.vue';
import ListBlock from 'ah-common-lib/src/common/components/listing/ListBlock.vue';
import { ref, PropType, reactive, watch } from 'vue';

const tradeListing = ref<InstanceType<typeof TradeListing> | null>(null);

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: tradeReportTableFieldsFull }),
  },
  withRowDetails: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  openTrade: {
    type: Object as PropType<any>,
    required: false,
    default: () => null,
  },
  possibleStates: {
    type: Array as PropType<TradeState[]>,
    required: false,
  },
  possibleStatuses: {
    type: Array as PropType<TradeStatus[]>,
    required: false,
  },
  possibleInstruments: {
    type: Array as PropType<HedgingInstruments[]>,
    required: false,
  },
  showExport: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  readonly: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
});

const defaultSortAndPageParams: Partial<PaginatedParams> = {
  sort: 'createdAt',
  sortDirection: 'DESC',
};

const state = reactive({
  defaultFilter: {} as any,
  // Automatically syncronised via .sync
  dataDownloadState: 'idle',
});

function onOpenTradeChange() {
  if (props.openTrade) {
    state.defaultFilter.referenceNumber = props.openTrade;
  }
}

watch(
  () => props.openTrade,
  () => {
    onOpenTradeChange();
  },
  { immediate: true }
);

function downloadList(format: ExportListType) {
  tradeListing.value!.downloadData(format);
}

function updateTrade() {
  tradeListing.value!.loadData();
}

function reload() {
  tradeListing.value!.loadData(undefined, true);
}

defineExpose({ reload });
</script>

<template>
  <BoxGrid alignH="start" alignV="stretch">
    <ListBlock
      :defaultFilters="state.defaultFilter"
      :defaultSortAndPageParams="defaultSortAndPageParams"
      v-bind="$attrs"
    >
      <template #filters="{ filter, sortAndPageParams, onSortAndPageParamsChange, onFilterChange }">
        <BoxGridBlock cols="12">
          <slot name="filters" v-bind="{ filter, onFilterChange }">
            <TradeListFilters
              showDates
              showSearch
              showStatuses
              :possibleStates="possibleStates"
              :possibleStatuses="possibleStatuses"
              :possibleInstruments="possibleInstruments"
              :openTrade="openTrade"
              :filter="filter"
              @update:filter="onFilterChange"
            />
          </slot>
        </BoxGridBlock>
        <SortSelector
          v-if="$ahTradesState.mediaQuery.is('smOnly')"
          :fields="config.tableFields"
          class="trade-sorter"
          :sortAndPageParams="sortAndPageParams"
          :update:sortAndPageParams="onSortAndPageParamsChange"
        />
      </template>
      <template #list="{ setFilter, filter, sortAndPageParams }">
        <BoxGridBlock cols="12">
          <TradeListing
            fixed
            ref="tradeListing"
            :filter="filter"
            @update:filter="setFilter"
            :sortAndPageParams="sortAndPageParams"
            :dataDownloadState.sync="state.dataDownloadState"
            :withRowDetails="withRowDetails"
            :showExport="false"
            :config="config"
            v-bind="$attrs"
          >
            <template #row-details="slotScope">
              <slot name="trade-details" v-bind="slotScope">
                <div class="trade-details">
                  <TradeRowDetails
                    :trade="slotScope.item"
                    @update:trade="updateTrade"
                    :readonly="readonly"
                    v-bind="$attrs"
                    v-on="$listeners"
                    @cancel-trade-executed="updateTrade"
                    @roll-forward-executed="updateTrade"
                  />
                </div>
              </slot>
            </template>
          </TradeListing>
        </BoxGridBlock>
        <BoxGridItem class="bg-transparent" cols="12">
          <ListDownloadButton
            v-if="showExport !== false"
            :loading="state.dataDownloadState === 'pending'"
            class="download-button"
            @download="downloadList"
          />
        </BoxGridItem>
      </template>
    </ListBlock>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.trade-details {
  padding: 0 math.div($padded-space, 2);
}

.trade-sorter {
  margin-left: 0px;
  width: 100%;
}

.dates-filter-holder {
  flex-basis: 26em;
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep {
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }

    .field-group-field-input.month,
    .field-group-field-input.day {
      width: 3rem !important;
    }

    .field-group-field-input.year {
      width: 4rem !important;
    }
  }
}
</style>
