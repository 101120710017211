<script lang="ts" setup>
import { Client, AssigneeReference } from 'ah-api-gateways';
import ClientAssigneesModal from '../ClientAssigneesModal.vue';
import ClientAssigneesList from '../ClientAssigneesList.vue';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

/**
 * Client Assignees Info block
 *
 * Emits:
 * - saved-assignees (payload: AssigneeReference[]) - remitted from ClientAssigneesModal
 */

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
  },
});

const services = getServices();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'getAssignees') {
      loadAssignees();
    }
  },
}).manager;

const clientAssignees = ref<AssigneeReference[]>([]);

const assigneesList = ref<InstanceType<typeof ClientAssigneesList> | null>(null);

function loadAssignees() {
  if (props.client) {
    requestManager
      .sameOrCancelAndNew('getAssignees', services.client.getClientAssignees(props.client.id), props.client.id)
      .subscribe((response) => {
        clientAssignees.value = response;
        assigneesList.value?.loadAssignees();
      });
  }
}

watch(() => props.client, loadAssignees, { immediate: true });
</script>

<template>
  <div v-if="client">
    <div class="rows-block d-md-block d-sm-none">
      <DataRow xl="5" lg="6" md="6" sm="7" label="Onboarding Date" class="text-wrap mb-2 client-onboarding-date">
        <span class="text-nowrap"> {{ formatDate(client.createdAt, SIMPLE_DATE_FORMAT) }} </span>
      </DataRow>
      <DataRow xl="5" lg="6" md="6" sm="7" label="Assignees" class="mb-2 client-assignees">
        {{ clientAssignees.length }}
      </DataRow>
      <DataRow xl="5" lg="6" md="6" sm="7" label="Users" class="mb-5 client-users">
        <ClientAssigneesList class="fee-words-wrap mr-2" :client="client" ref="assigneesList" />
      </DataRow>
    </div>
    <div class="text-center">
      <ClientAssigneesModal @saved-assignees="loadAssignees" :client="client" v-slot="{ show }">
        <VButton @click="show" class="btn-secondary">Edit Assignees</VButton>
      </ClientAssigneesModal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fee-words-wrap {
  white-space: break-spaces;
}
</style>
