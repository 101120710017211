<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.87565 26H22.209C23.4056 26 24.3756 25.03 24.3756 23.8333V8.4808C24.3756 7.90616 24.1474 7.35506 23.741 6.94873L17.4269 0.634602C17.0206 0.228273 16.4695 0 15.8949 0H4.87565C3.67903 0 2.70898 0.970049 2.70898 2.16667V23.8333C2.70898 25.03 3.67903 26 4.87565 26Z"
      fill="#485560"
    />
    <path
      d="M21.2837 8.82467L15.5497 3.0907C15.2085 2.74947 14.625 2.99114 14.625 3.47372V9.20768C14.625 9.50684 14.8675 9.74935 15.1667 9.74935H20.9006C21.3832 9.74935 21.6249 9.1659 21.2837 8.82467Z"
      fill="white"
    />
    <line
      x1="6.77148"
      y1="20.8535"
      x2="20.8548"
      y2="20.8535"
      stroke="white"
      stroke-width="1.625"
      stroke-linecap="round"
    />
    <line
      x1="6.77148"
      y1="16.5215"
      x2="20.8548"
      y2="16.5215"
      stroke="white"
      stroke-width="1.625"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
