import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { runActions } from './runActions';

let resolvePiniaSetup!: () => void;
const setupStarted = false;

/**
 * Promise representing whether Pinia setup steps have happened, and store actions can be run
 * This is not necessary inside application components, as the app only mounts after this has been done.
 * However, due to timing necessities for compatibility with Pinia (which will eventually replace Vuex altogether)
 * Calls outside components may have timing issues (such as actions in route guards)
 */
export const storeSetupDone = new Promise<true>((resolve) => {
  resolvePiniaSetup = () => resolve(true);
});

export async function setupStore(force = false) {
  if (!setupStarted || force) {
    await runActions(commonStoreActions.onSetup);
    resolvePiniaSetup();
    await runActions(commonStoreActions.afterSetup);
  }
}
