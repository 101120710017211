<script setup lang="ts">
import TransactionsTable from './transactions/TransactionsTable.vue';
import { PaginatedQuery, BeneficiaryManagerErrorCodes, Transaction, TransactionBase } from 'ah-api-gateways';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { computed, PropType, watch } from 'vue';
import { useAhWalletsState } from '..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { walletTransactionsTableFields } from 'ah-trades/src/models/transactionsTableFields';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: walletTransactionsTableFields }),
  },
  walletId: {
    type: String,
    required: true,
  },
  /**
   * Whether to show scheduled transactions. If unset or falsy will return historical transactions
   *
   * Only works in the context of a wallet
   */
  scheduled: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  ...defineUseManagedListingProps<TransactionBase>(),
});

interface TransactionEmits extends UseManagedListingEmits<TransactionBase> {}

const emit = defineEmits<TransactionEmits>();

const walletState = useAhWalletsState();

const requestManager = useRequestManager();

const onBehalfOfClient = useOnBehalfOf();

const managedListing = useManagedListing<TransactionBase>({
  loadDataRequest(query?: PaginatedQuery) {
    // Typescript does not handle Union types well here
    return (
      props.scheduled !== false
        ? walletState.services.wallet.listWalletScheduledTransactions(props.walletId, query)
        : walletState.services.wallet.listWalletTransactions({ ...query, walletId: props.walletId })
    ) as any;
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

function getCancelTransactionRequest(transaction: Transaction) {
  return walletState.services.payments.cancelPayment(transaction.paymentId!, onBehalfOfClient.value?.id, {
    errors: { silent: true },
  });
}

function loadData() {
  managedListing.loadData();
}

function cancelTransaction(transaction: Transaction) {
  requestManager.manager.sameOrCancelAndNew('cancelTransaction', getCancelTransactionRequest(transaction)).subscribe(
    () => {
      walletState.toast.success('Payment cancelled successfully.');
      loadData();
    },
    (e) => {
      const error = e.response?.data;
      if (error && error.code === BeneficiaryManagerErrorCodes.BUSINESS_RULE_NOT_MET && error.message) {
        walletState.toast.error(error.message);
      } else {
        walletState.toast.error('An unexpected problem has occurred. Please try again later.');
      }
    }
  );
}

watch(() => props.walletId, loadData);

watch(
  () => props.scheduled,
  () => {
    managedListing.setData({
      list: [],
      ...(props.tableData as any),
      ...(props.sortAndPageParams as any),
      ...(props.filter as any),
      sort: props.scheduled !== false ? 'executionDate' : 'docCreatedAt',
      sortDirection: 'DESC',
    });
  },
  { immediate: true }
);

defineExpose({ loadData });
</script>

<template>
  <TransactionsTable
    @transaction-cancel="cancelTransaction"
    @withdraw-succeded="loadData"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </TransactionsTable>
</template>
