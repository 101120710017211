<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { emailField, textField, phoneField } from 'ah-common-lib/src/form/models';
import { makeFormModel, setApiErrorMessages, setState, submitForm, toDataModel } from 'ah-common-lib/src/form/helpers';
import { IndividualType, IndividualInvitation, BaseIndividual } from 'ah-api-gateways';
import { optional, checkParam, requiredIfStateValue, validName } from 'ah-common-lib/src/form/validators';
import { mergeMap, catchError } from 'rxjs/operators';
import { waitForEntityCreation } from 'ah-requests';
import { of } from 'rxjs';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { cloneDeep } from 'lodash';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';
import { computed, reactive, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useToast } from 'ah-common-lib/src/toast';

const emailFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'userForm',
    fieldType: 'form',
    fields: [
      textField(
        'firstName',
        'First Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      emailField('email', 'Email Address', { showRequiredMarkers: true }),
      phoneField(
        'phoneNumber',
        'Mobile Number',
        { class: 'col col-md-7' },
        {
          phone: optional(checkParam('phoneNumber', 'valid')),
        }
      ),
    ],
  }),
  validation: null,
});

const authStore = useAuthStore();

const services = getServices();

const toast = useToast();

const emit = defineEmits({
  'individual-invited': () => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'inviteIndividual') {
      save();
    }
  },
}).manager;

const addIndividualModal = ref<InstanceType<typeof BModal>>();

function show() {
  addIndividualModal.value?.show();
}

function cancel() {
  addIndividualModal.value?.hide();
}

const invitationObject = computed(() => {
  let out: IndividualInvitation = {
    type: IndividualType.CLIENT_INDIVIDUAL,
    ...toDataModel(emailFormDef.form),
  };

  if (authStore.isAgent) {
    out.type = IndividualType.PARTNER_AGENT;
  } else {
    out.type = IndividualType.CLIENT_INDIVIDUAL;
    out.client = {
      id: authStore.loggedInIdentity!.client?.id || '',
    };
  }

  return out;
});

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    setState(formEvent.model, 'errors', []);
  }
}

function save() {
  if (emailFormDef.validation) {
    submitForm(emailFormDef.validation);
    if (emailFormDef.validation?.$invalid) {
      return;
    }

    const invitationIndividual = cloneDeep(invitationObject.value);
    if (invitationIndividual.phoneNumber?.length === 0) {
      delete invitationIndividual.phoneNumber;
    }

    requestManager
      .new(
        'inviteIndividual',
        services.individual
          .inviteIndividual(invitationIndividual, { errors: { silent: true } })
          .pipe(
            mergeMap((idEntity) =>
              waitForEntityCreation(() =>
                services.individual.getIndividual(idEntity.id, { errors: { silent: true } })
              ).pipe(catchError(() => of(idEntity)))
            )
          )
      )
      .subscribe(
        () => {
          toast.show('User invited successfully');
          emit('individual-invited');
          addIndividualModal.value?.hide();
        },
        (e) => {
          if (e.response.status === 400) {
            toast.info(e.response.data.message);
            setApiErrorMessages(constructPayloadErrors<BaseIndividual>(e.response!.data), emailFormDef.form);
            return;
          }
        }
      );
  }
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal modal-md" ref="addIndividualModal" title="Invite user" v-bind="$attrs">
      <ValidatedForm
        class="mt-3"
        :fm="emailFormDef.form"
        :validation.sync="emailFormDef.validation"
        @form-event="onFormEvent"
      >
        <template #userForm.email:after>
          <p class="text-small text-muted mt-3">
            Your new user will receive an invite to join the team at this email address.
          </p>
        </template>
        <template #userForm.phoneNumber:after>
          <span class="text-muted">
            <ul class="text-small pl-4 my-1">
              <li>Must include country code</li>
            </ul>
          </span>
        </template>
      </ValidatedForm>
      <template #modal-footer>
        <div class="buttons text-left">
          <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
          <VButton class="btn-success" @click="save" :loading="requestManager.anyPending">Invite User</VButton>
        </div>
      </template>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  width: 44em !important;
}

.buttons {
  width: 100%;
  margin-left: 3.1rem;
  margin-bottom: 2rem;
  .btn-secondary {
    min-width: 6rem;
  }
  .btn-success {
    min-width: 8rem;
  }
}
</style>
