<script lang="ts" setup>
import { BankAccount, Beneficiary, BankingScheme, FeePaymentType, BeneficiaryType } from 'ah-api-gateways';
import { makeFormModel } from 'ah-common-lib/src/form/helpers/formMaker';
import isEqual from 'lodash/isEqual';
import { selectField } from 'ah-common-lib/src/form/models';
import BankAccountDetailsForm from './BankAccountDetailsForm.vue';
import { makeCompoundValidation, setState, getChildModel, batchSetState } from 'ah-common-lib/src/form/helpers';
import { FormValidation, CompoundValidation, FieldOptionObj, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { computed, PropType, reactive, ref, set, watch } from 'vue';
import { useAhBeneficiariesState } from '../..';

const bankAccountDetailsForm = ref<InstanceType<typeof BankAccountDetailsForm> | null>(null);

const props = defineProps({
  beneficiary: { type: Object as PropType<Partial<Beneficiary>> },
  beneficiaryType: { type: String as PropType<BeneficiaryType> },
  countryCode: { type: String },
  /*
   * Currency of the currently adding bank account
   * if set, no other currencies are allowed
   */
  currency: { type: String },
  /*
   * Force banking scheme and don't allow the user
   * to input any other banking scheme
   */
  forceBankingScheme: { type: String as PropType<BankingScheme> },
});

const state = reactive({
  detailsValidation: null as FormValidation | null,
  bankAccountEdit: {
    address: {
      addressLine: '',
      city: '',
      stateOrProvince: '',
      countryCode: '',
      postalCode: '',
    },
  } as Partial<BankAccount>,
  loadStates: {
    bankDetails: false,
    baseData: false,
    address: false,
  },
});

const emit = defineEmits<{
  (e: 'update:compoundValidation', value: CompoundValidation): void;
  (e: 'update:loading', value: boolean): void;
  (e: 'update:bankAccount', value: Partial<BankAccount>): void;
}>();

const beneficiarieState = useAhBeneficiariesState();

const bankAccountBaseFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'bankAccountBaseForm',
    fieldType: 'form',
    fields: [
      selectField(
        'currency',
        'Account Currency',
        () => beneficiarieState.store.useSettingsStore().currenciesAsOptions(),
        {
          showRequiredMarkers: true,
          required: true,
        }
      ),
      selectField(
        'bankingScheme',
        'Payment Method',
        [
          {
            value: BankingScheme.LOCAL,
            label: 'Local',
          },
          {
            value: BankingScheme.SWIFT,
            label: 'SWIFT',
          },
        ],
        {
          showRequiredMarkers: true,
          required: true,
        }
      ),
    ],
  }),
  validation: null,
});

function loadTradeableCurrencies() {
  beneficiarieState.store.useSettingsStore().loadTradeableCurrencies();
}

loadTradeableCurrencies();

function loadPaymentRails() {
  beneficiarieState.store.useSettingsStore().loadPaymentRails();
}

loadPaymentRails();

function onForcedBankingSchemeChange() {
  if (bankAccountBaseFM) {
    if (props.forceBankingScheme) {
      bankAccountBaseFM.form.bankingScheme = props.forceBankingScheme;
    }
    setState(getChildModel(bankAccountBaseFM.form, 'bankingScheme')!, 'readonly', !!props.forceBankingScheme);
  }
}

watch(
  () => props.forceBankingScheme,
  () => {
    onForcedBankingSchemeChange();
  },
  { immediate: true }
);

const compoundValidation = computed(() => {
  if (bankAccountBaseFM.validation && state.detailsValidation) {
    return makeCompoundValidation([bankAccountBaseFM.validation, state.detailsValidation]);
  } else if (bankAccountBaseFM.validation) {
    return makeCompoundValidation([bankAccountBaseFM.validation]);
  }
  return {
    validations: [],
    $invalid: true,
    $dirty: false,
  };
});

watch(
  compoundValidation,
  () => {
    emit('update:compoundValidation', compoundValidation.value);
  },
  { immediate: true }
);

const anyLoading = computed(() => {
  return state.loadStates.bankDetails || state.loadStates.baseData || state.loadStates.address;
});

const beneficiaryBankData = computed(() => {
  if (props.beneficiary) {
    const data: Partial<BankAccount> = {
      reference: props.beneficiary.reference,
      accountHolderName: props.beneficiary.accountHolderName,
      countryCode: props.beneficiary.countryCode,
      currency: props.beneficiary.currency,
      iban: props.beneficiary.iban,
      bicSwift: props.beneficiary.bicSwift,
      bankName: props.beneficiary.bankName,
      bankAddress: props.beneficiary.bankAddress,
      bankCountry: props.beneficiary.bankCountry,
      routingNumber1: props.beneficiary.routingNumber1,
      routingNumber2: props.beneficiary.routingNumber2,
      accountNumber: props.beneficiary.accountNumber,
      accountType: props.beneficiary.accountType,
      bankingScheme: props.beneficiary.bankingScheme || props.forceBankingScheme,
      purposeCode: props.beneficiary.purposeCode,
      paymentRail: props.beneficiary.paymentRail,
    };

    Object.keys(data).forEach((k) => {
      if ((data as any)[k] === undefined) {
        delete (data as any)[k];
      }
    });
    return data;
  }
  return null;
});

watch(
  anyLoading,
  () => {
    emit('update:loading', anyLoading.value);
  },
  { immediate: true }
);

watch(
  () => bankAccountBaseFM.form.currency,
  () => {
    if (bankAccountBaseFM.form.currency) {
      set(state.bankAccountEdit, 'currency', bankAccountBaseFM.form.currency);
      updateBankingSchemeOptions();
    }
  },
  { immediate: true }
);

watch(
  () => bankAccountBaseFM.form.bankingScheme,
  () => {
    if (bankAccountBaseFM.form.bankingScheme) {
      set(state.bankAccountEdit, 'bankingScheme', bankAccountBaseFM.form.bankingScheme);
    }
  },
  { immediate: true }
);

watch(
  () => props.countryCode,
  () => {
    set(
      state.bankAccountEdit,
      'bankCountry',
      ((state.detailsValidation as any)?.bankCountry.$dirty && state.bankAccountEdit.bankCountry) ||
        props.beneficiary?.bankCountry ||
        props.countryCode
    );
  },
  { immediate: true }
);

/**
 * Clear beneficiary data (triggered on beneficiary change)
 *
 * As currency and banking scheme will be set in an existing Beneficiary that may be loaded asynchronously,
 * these values "hard reset" ONLY when beneficiary changes ID (initial load or beneficiary change)
 * Otherwise, form values are kept if added
 */
watch(
  () => props.beneficiary,
  (newVal?: Partial<Beneficiary>, oldVal?: Partial<Beneficiary>) => {
    if (props.beneficiary && bankAccountBaseFM) {
      const hardReset = props.beneficiary.id !== oldVal?.id;
      state.bankAccountEdit = {
        ...beneficiaryBankData.value,
        ...state.bankAccountEdit,
      };
      bankAccountBaseFM.form.currency = (!hardReset && bankAccountBaseFM.form.currency) || props.beneficiary.currency;
      bankAccountBaseFM.form.bankingScheme =
        (!hardReset && bankAccountBaseFM.form.bankingScheme) || props.beneficiary.bankingScheme;
    }
  },
  { immediate: true }
);

watch(
  () => state.bankAccountEdit,
  () => {
    if (!isEqual(state.bankAccountEdit, props.beneficiary)) {
      emit('update:bankAccount', state.bankAccountEdit);
    }
  },
  { deep: true }
);

watch(
  () => props.currency,
  () => {
    if (bankAccountBaseFM) {
      if (props.currency) {
        bankAccountBaseFM.form.currency = props.currency;
      }
      batchSetState(bankAccountBaseFM.form, 'readonly', ['currency'], !!props.currency);
    }
  },
  { immediate: true }
);

watch(
  () => props.beneficiaryType,
  () => {
    /**
     * Every time we change the beneficiary type (business or individual) we need to clean these two fields
     * That way we guarantee that no unnecessary fields are kept from previous data (reference PT-1958)
     * The beneficiary type change happens when we are creating a new beneficiary
     */
    if (props.beneficiaryType) {
      bankAccountBaseFM.form.currency = undefined;
      bankAccountBaseFM.form.bankingScheme = undefined;
      state.bankAccountEdit.bankingScheme = BankingScheme.SWIFT;
    }
  }
);

watch(
  () => bankAccountBaseFM.form.currency,
  () => {
    /**
     * Disable Payment Method field until we have an Account Currency selected
     * That way the valid Payment Methods will be shown, instead of assuming Local and Swift for all
     * Since we need an Account Currency to determine the available Payment Methods (reference PT-1958)
     */
    const bankingScheme = getChildModel(bankAccountBaseFM.form, 'bankingScheme');
    if (bankingScheme) {
      setState(bankingScheme, 'readonly', !bankAccountBaseFM.form.currency);
    }
  },
  { immediate: true }
);

function updateBankingSchemeOptions() {
  beneficiarieState.store
    .useSettingsStore()
    .loadTradeableCurrencies()
    .then(() => loadPaymentRails())
    .then(() => {
      if (bankAccountBaseFM.form.currency) {
        const currency = beneficiarieState.store
          .useSettingsStore()
          .currencies.find((c) => c.currency === bankAccountBaseFM.form.currency);
        if (currency) {
          const options: FieldOptionObj[] = [];
          if (currency.localEnabled) {
            options.push({
              value: BankingScheme.LOCAL,
              label:
                beneficiarieState.store.useSettingsStore().getPaymentRail({
                  currency: currency.currency,
                  bankingScheme: BankingScheme.LOCAL,
                  type: FeePaymentType.PAYMENT,
                })[0]?.label || 'Local',
            });
          }
          if (currency.swiftEnabled) {
            options.push({
              value: BankingScheme.SWIFT,
              label:
                beneficiarieState.store.useSettingsStore().getPaymentRail({
                  currency: currency.currency,
                  bankingScheme: BankingScheme.SWIFT,
                  type: FeePaymentType.PAYMENT,
                })[0]?.label || 'SWIFT',
            });
          }
          const bankingScheme = getChildModel(bankAccountBaseFM.form, 'bankingScheme');
          if (bankingScheme) {
            setState(bankingScheme, 'options', options);
            if (options.length >= 1) {
              if (options.find((option) => option.value === props.beneficiary?.bankingScheme)) {
                bankAccountBaseFM.form.bankingScheme = props.beneficiary?.bankingScheme;
              } else {
                bankAccountBaseFM.form.bankingScheme = null;
                (bankAccountBaseFM.validation as any)?.bankingScheme?.$reset();
              }
            }
          }
        }
        onForcedBankingSchemeChange();
      }
    });
}

function clearErrorMessages() {
  if (bankAccountDetailsForm.value) {
    bankAccountDetailsForm.value.clearErrorMessages();
  }
}

function setErrorMessage(fieldName: string, message: string) {
  if (bankAccountDetailsForm.value) {
    bankAccountDetailsForm.value.setErrorMessage(fieldName, message);
  }
}

defineExpose({ clearErrorMessages, setErrorMessage });
</script>

<template>
  <div>
    <ValidatedForm :fm="bankAccountBaseFM.form" :validation.sync="bankAccountBaseFM.validation">
      <template #bankAccountBaseForm.bankingScheme:after>
        <span class="text-secondary text-small" v-if="!bankAccountBaseFM.form.currency">
          Please select an Account Currency first
        </span>
      </template>
    </ValidatedForm>
    <template v-if="bankAccountBaseFM.validation && !bankAccountBaseFM.validation.$invalid">
      <div class="card-block mt-4">
        <h3 class="mb-3">Account details</h3>
        <BankAccountDetailsForm
          ref="bankAccountDetailsForm"
          :model.sync="state.bankAccountEdit"
          :beneficiary="beneficiary"
          :validation.sync="state.detailsValidation"
          :loading.sync="state.loadStates.bankDetails"
          v-bind="$attrs"
        />
      </div>
    </template>
  </div>
</template>
