import { render, staticRenderFns } from "./BeneficiaryDetails.vue?vue&type=template&id=3f3855d2&scoped=true"
import script from "./BeneficiaryDetails.vue?vue&type=script&lang=ts&setup=true"
export * from "./BeneficiaryDetails.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./BeneficiaryDetails.vue?vue&type=style&index=0&id=3f3855d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3855d2",
  null
  
)

export default component.exports