import get from 'lodash/get';
import { HedgingInstrumentsSettingsProps } from '../quote';

export enum ProfitMarginUnit {
  BASIS_POINTS = 'BASIS_POINTS',
  PERCENTAGE = 'PERCENTAGE',
}

export type ProfitMargins = {
  [key in HedgingInstrumentsSettingsProps]: BasisPointConfiguration;
};

export interface BasisPointConfiguration {
  value: number;
}

export type BasisPointsType = 'value' | 'range';

export function parseProfitMargins(profitMargins: ProfitMargins) {
  return Object.keys(profitMargins).map((name) => {
    let config = get(profitMargins, name);
    if (!config) {
      config = {};
    }
    return { name, config };
  });
}

export interface FxMarginsConfiguration {
  profitMargin: number;
}

export interface ProfitMarginsLimits {
  minProfit: number;
  maxProfit: number;
}

export type FxMarginList = {
  [value in HedgingInstrumentsSettingsProps]: FxMarginsConfiguration;
};

export type FxMargins = FxMarginList & ProfitMarginsLimits;
