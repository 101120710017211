<script setup lang="ts">
import AsyncImage from '@/app/components/common/AsyncImage.vue';
import { IndividualFileCategories, IndividualReference } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, onBeforeMount, onBeforeUnmount, PropType, ref, watch } from 'vue';
import { getServices } from '@/app/services';

const imageSrc = ref('');

const props = defineProps({
  user: {
    type: Object as PropType<IndividualReference>,
    required: true,
  },
});

const authStore = useAuthStore();

const services = getServices();

const individualSettingsStore = useIndividualSettingsStore();

const initials = computed(() => ((props.user!.firstName || ' ')[0] + (props.user!.lastName || ' ')[0]).toUpperCase());

const photo = computed(() => individualSettingsStore.getIndividualDocument(IndividualFileCategories.PROFILE_PICTURE));

function onPhotoIdChange() {
  if (photo.value) {
    services.individual.getDocumentAsBlob(authStore.loggedInIdentity!.id, photo.value!).subscribe((blob) => {
      if (imageSrc.value) {
        URL.revokeObjectURL(imageSrc.value);
      }
      imageSrc.value = URL.createObjectURL(blob!);
    });
  }
}

watch(() => photo.value?.id, onPhotoIdChange, { immediate: true });

onBeforeMount(() => {
  individualSettingsStore.loadIndividualDocuments(true);
});

onBeforeUnmount(() => {
  if (imageSrc.value) {
    URL.revokeObjectURL(imageSrc.value);
  }
});
</script>

<template>
  <div class="user-badge">
    {{ initials }}
    <AsyncImage class="user-badge-photo" v-if="imageSrc" :src="imageSrc" alt="Profile Picture" />
  </div>
</template>
