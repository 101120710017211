import { computed } from 'vue';
import { BaseFormModel, FieldModel } from '../interfaces';
import { useFormElement } from './useFormElement';
import { getShownFieldErrors } from '../helpers';

export function useBaseFieldValues<T extends BaseFormModel>(options: ReturnType<typeof useFormElement<T>>) {
  const unexpectedErrors = options.modelStateRef('unexpectedError', false);
  const showFieldErrors = computed(() =>
    getShownFieldErrors(options.field.value, options.model.value as unknown as FieldModel)
  );
  const required = computed(() => {
    const requiredVal = options.getModelState('required', false, true);
    return typeof requiredVal === 'function' ? requiredVal(options.model.value) : requiredVal;
  });

  return {
    readonly: options.modelStateRef('readonly', false, (curr, parent) => !!(curr || parent)),
    unexpectedErrors,
    customErrors: options.modelStateRef('errors', []),
    showFieldErrors,
    htmlTitle: options.modelStateRef('htmlTitle'),
    fieldTitle: options.modelStateRef('title'),
    fieldTitleTooltip: options.modelStateRef('titleTooltip'),
    fieldClass: options.modelStateRef('class'),
    inputClass: options.modelStateRef('inputClass', 'form-control', true),
    inputName: options.modelStateRef('inputName', options.model.value.$name),
    fieldType: options.modelStateRef<string>('fieldType'),
    disabled: options.modelStateRef('disabled', false, true),
    dirtyOnInput: options.modelStateRef('dirtyOnInput'),
    tooltipComponent: options.modelStateRef('tooltipComponent'),
    number: options.modelStateRef('number'),
    placeholder: options.modelStateRef('placeholder'),
    inputMode: options.modelStateRef('inputMode', 'text'),
    valueToInputFn: options.modelStateRef('valueToInputFn', (v: any) => v),
    inputToValueFn: options.modelStateRef('inputToValueFn', (v: any) => v),
    trimValue: options.modelStateRef('trim', true),
    emptyValue: options.modelStateRef('emptyValue', ''),
    autocomplete: options.modelStateRef('autocomplete', 'on'),
    required,

    /**
     * Max length of form field
     *
     * Defaults to 255, typical for API text values
     */
    maxLength: options.modelStateRef('maxLength', 255),
    inputErrorClass: options.modelStateRef('inputErrorClass', 'is-invalid', true),
    unexpectedErrorsShown: computed(() => options.field.value && unexpectedErrors.value),
    // FIXME we use options.field.value.$error && options.field.value.$invalid because in rare cases vuelidade shows $error as true for a valid field
    fieldErrorsShown: computed(
      () =>
        options.field.value &&
        ((options.field.value.$error && options.field.value.$invalid) || options.getModelState('errors', []).length) &&
        showFieldErrors.value
    ),
    showRequired: computed(() => {
      const showRequiredMarkers = options.getModelState('showRequiredMarkers', false, true);
      return showRequiredMarkers && required.value;
    }),
  };
}
