import { CountryCode, NumberFormat, parsePhoneNumberFromString } from 'libphonenumber-js';

export function isValidPhoneNumber(intlPhoneNumber: string, defaultCountry?: CountryCode) {
  return parsePhoneNumberFromString(intlPhoneNumber, defaultCountry)?.isValid() || false;
}

export function getPhoneNumber(intlPhoneNumber: string, defaultCountry?: CountryCode) {
  return getNumber(intlPhoneNumber, 'INTERNATIONAL', defaultCountry);
}

export function getNumberCountryCode(intlPhoneNumber: string) {
  const parsed = parsePhoneNumberFromString(intlPhoneNumber);
  if (parsed && parsed.country) {
    return parsed.country;
  }
}

export function getNumber(number?: string, type: NumberFormat = 'E.164', defaultCountry?: CountryCode) {
  if (number) {
    const parsed = parsePhoneNumberFromString(number, defaultCountry);
    if (parsed && parsed.isValid()) {
      return parsed.format(type);
    }
  }
  return '';
}

export function displayNumber(number?: string, defaultCountry?: CountryCode) {
  return getNumber(number, 'INTERNATIONAL', defaultCountry);
}

export function cleanNumber(number?: string, defaultCountry?: CountryCode) {
  return getNumber(number, 'E.164', defaultCountry);
}
