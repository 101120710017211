<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: Infinity,
  },
  showArrows: {
    type: [Boolean, String],
    default: false,
  },
  step: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits({
  'swipe-left': () => true,
  'swipe-right': () => true,
  tap: () => true,
  'swipe-up': () => true,
  'swipe-down': () => true,
  'update:index': (_value: number) => true,
});

// NOTE these values are used directly by the touch event handlers, so they don't need to be reactive
let touchstartX: number = 0;
let touchstartY: number = 0;
let touchendX: number = 0;
let touchendY: number = 0;

const canSwipeLeft = computed(() => {
  return drawArrows.value && props.index > 0;
});

const canSwipeRight = computed(() => {
  return drawArrows.value && props.index < props.max;
});

const drawArrows = computed(() => {
  return props.showArrows !== false;
});

function swipeRight() {
  emit('update:index', props.index + props.step);
  emit('swipe-right');
}

function swipeLeft() {
  emit('update:index', props.index - props.step);
  emit('swipe-left');
}

function touchStart(event: TouchEvent) {
  touchstartX = event.changedTouches[0].screenX;
  touchstartY = event.changedTouches[0].screenY;
}

function touchEnd(event: TouchEvent) {
  touchendX = event.changedTouches[0].screenX;
  touchendY = event.changedTouches[0].screenY;
  handleGesture();
}

function handleGesture() {
  if (touchendX < touchstartX) {
    if (props.index + 1 <= props.max) {
      emit('update:index', props.index + props.step);
    }
    emit('swipe-left');
  }

  if (touchendX > touchstartX) {
    if (props.index - 1 >= 0) {
      emit('update:index', props.index - props.step);
    }
    emit('swipe-right');
  }

  if (touchendY < touchstartY) {
    emit('swipe-up');
  }

  if (touchendY > touchstartY) {
    emit('swipe-down');
  }

  if (touchendY === touchstartY) {
    emit('tap');
  }
}
</script>

<template>
  <div @touchstart="touchStart" @touchend="touchEnd" class="swipable-box">
    <VRow alignH="center">
      <VCol :cols="$mediaQuery.is('smDown') ? '3' : '1'" class="text-right">
        <span v-if="canSwipeLeft" @click="swipeLeft"><IconChevronLeft class="swipe-icon left" /></span>
      </VCol>
      <VCol :cols="$mediaQuery.is('smDown') ? '6' : '9'" class="text-center">
        <Transition name="slide-and-fade" mode="out-in" appear>
          <div :key="index">
            <slot name="content" />
          </div>
        </Transition>
      </VCol>
      <VCol :cols="$mediaQuery.is('smDown') ? '3' : '2'" :class="$mediaQuery.is('smDown') ? 'text-left' : 'text-right'">
        <span v-if="canSwipeRight" @click="swipeRight"><IconChevronRight class="swipe-icon right" /></span>
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.app-logo {
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.swipe-icon {
  cursor: pointer;
  font-size: 16px;
  @include themedTextColor($color-dark-primary, $color-primary);
  z-index: 1;
}
</style>
