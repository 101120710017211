import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import {
  HedgingInstruments,
  LiquidityProvider,
  PaginatedQuery,
  PaginatedResponse,
  SingleCurrency,
  VersionedObject,
  standardiseQuerySort,
} from '../models';

export class LiquidityProviderService extends HttpBasedService {
  constructor(
    http: HttpService,
    protected baseUrl: string,
    private adminBaseUrl?: string,
    group = 'clientCollateralService'
  ) {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public listCurrencies(
    instrumentType: HedgingInstruments,
    oboClientId?: string,
    options?: Omit<HttpOptions<SingleCurrency[]>, 'cache'>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<SingleCurrency[]>(`${this.baseUrl}liquidity-providers/currencies/${instrumentType}`, {
      axiosConfig: { headers },
      options: {
        ...options,
        cache: {
          type: 'use',
          cacheKey: `liquidityproviderscurrenciesfields${instrumentType}`,
          itemKey: `liquidityproviderscurrencies${instrumentType}`,
        },
      },
    });
  }

  public listLiquidityProvidersCapabilities(params: PaginatedQuery) {
    if (!this.adminBaseUrl) {
      throw 'Access liquidity provider capabilities is an admin action only';
    }

    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<Partial<LiquidityProvider>>>(
      `${this.adminBaseUrl}liquidity-providers/capabilities`,
      {
        axiosConfig: { params },
      }
    );
  }

  public listLiquidityProviderAssignments(
    params: PaginatedQuery,
    options?: HttpOptions<PaginatedResponse<LiquidityProvider>>
  ) {
    if (!this.adminBaseUrl) {
      throw 'Access liquidity provider assignments is an admin action only';
    }

    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<LiquidityProvider>>(`${this.adminBaseUrl}liquidity-providers/assignments`, {
      axiosConfig: {
        params,
      },
      options,
    });
  }

  public updateLiquidityProvidersAssignment(
    id: string,
    model: Partial<LiquidityProvider>,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    if (!this.adminBaseUrl) {
      throw 'Update liquidity provider assignments is an admin action only';
    }

    return this.put<VersionedObject>(`${this.adminBaseUrl}liquidity-providers/assignments/${id}`, model, {
      options,
    });
  }
}
