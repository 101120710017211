<script setup lang="ts">
import OnboardingListBlock from '@/app/components/onboarding/report/OnboardingListBlock.vue';
import OnboardingStatuses from '@/app/components/onboarding/report/OnboardingStatuses.vue';
import OnboardingStepsGuideline from '@/app/components/onboarding/report/OnboardingStepsGuideline.vue';
import { useAuthStore } from '@/app/store/authStore';
import { AuthorityType } from 'ah-api-gateways';
import { computed } from 'vue';

const authStore = useAuthStore();

const canExport = computed(() => authStore.hasAuthorities(AuthorityType.EXPORT_CLIENT_REPORTS));
</script>

<template>
  <div class="padded-space" id="activity-onboarding-view">
    <h2>Onboarding</h2>
    <div class="onboarding-content">
      <div class="main-content">
        <OnboardingStatuses class="mb-3" />
        <OnboardingListBlock :showExport="canExport" documentsTitle="Onboarding List" />
      </div>
      <OnboardingStepsGuideline class="steps-guideline" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .main-content,
  .steps-guideline {
    flex: 1 1 100%;
    min-width: 0;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (min-width: 1780px) {
    flex-wrap: nowrap;
    align-items: flex-start;

    .main-content {
      flex: 1 1 auto;
    }

    .steps-guideline {
      flex: 0 0 300px;
      align-self: stretch;
    }
  }
}
</style>
