<script lang="ts" setup>
import {
  AuthorityType,
  Authority,
  ClientType,
  UserEntityType,
  Permission,
  LegalFileType,
  ExportFileType,
} from 'ah-api-gateways';
import AuthorisationsSettingsTable from '@/app/components/settings/authorisations/AuthorisationsSettingsTable.vue';
import { makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { checkboxField, dateField } from 'ah-common-lib/src/form/models';
import { ifTest, minDate, date } from 'ah-common-lib/src/form/validators';
import { Validation } from '@vuelidate/core';
import { tap, mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { cloneDeep } from 'lodash';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import { useTheme } from 'ah-theme';
import { useToast } from 'ah-common-lib/src/toast';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';

function makeExpirationDateForm(name: string) {
  return makeFormModel({
    name,
    fieldType: 'form',
    fields: [
      dateField(
        'expirationDate',
        '',
        { fieldType: 'date' },
        { minDate: minDate(), date: ifTest(date, (val) => val instanceof Date) }
      ),
    ],
  });
}

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'authorisations') {
      loadAuthorities();
    }
    if (k === 'loadClientPermissions') {
      loadPermissionSettings();
    }
    if (k === 'saveClientPermissions') {
      saveChanges();
    }
  },
}).manager;

const authStore = useAuthStore();

const services = getServices();

const theme = useTheme();

const toast = useToast();

const mediaQuery = useMediaQuery();

const authorisations = ref<Authority[]>([]);

const permissions = ref<Permission[]>([]);

const permissionsSaved = ref<Permission[]>([]);

const authorityForms = ref([
  {
    authority: AuthorityType.TRADE_ON_BEHALF_OF,
    form: makeExpirationDateForm('tradeExpiration'),
    validation: null as null | Validation,
  },
  {
    authority: AuthorityType.PAY_ON_BEHALF_OF,
    form: makeExpirationDateForm('paymentExpiration'),
    validation: null as null | Validation,
  },
]);

const agreementConfirmForm = reactive(
  makeFormModel({
    name: 'agreementConfirm',
    fieldType: 'form',
    fields: [
      checkboxField('confirmed', '', false, {
        required: false,
        errorMessages: {
          mustAccept: 'Must confirm this to continue',
        },
      }),
    ],
  })
);

const fieldsData = computed(() => {
  /**
   * Table rows information present in common-lib
   *
   * As for now this will return the payment and trading authorisation
   * titles and subtitles
   */
  return authorityForms.value
    .map((data) => ({
      data,
      setting: permissions.value.find((setting) => setting.authority === data.authority) || {
        authority: data.authority,
        allow: false,
      },
      authorisation: authorisations.value.find((a) => a.id === data.authority)!,
    }))
    .filter((p) => !!p.authorisation);
});

const columnClass = computed(() => (type: string) => mediaQuery.is('mdUp') ? `${type}-col` : 'flex-fill');

const partnerName = computed(() => theme.val?.name || '');

const isFormValid = computed(
  () => agreementConfirmForm.confirmed && !authorityForms.value.find((p) => p.validation?.$invalid)
);

function loadAuthorities() {
  return requestManager.currentOrNew(
    'authorisations',
    services.authz
      .getRoleAuthorities(
        authStore.loggedInRole!,
        authStore.loggedInIdentity!.client!.type === ClientType.INDIVIDUAL
          ? UserEntityType.INDIVIDUAL_CLIENT
          : UserEntityType.COMPANY_CLIENT
      )
      .pipe(
        tap((_authorities) => {
          authorisations.value = _authorities;
        })
      )
  );
}

function loadPermissionSettings() {
  return requestManager.currentOrNew(
    'loadClientPermissions',
    services.authz.getClientsPermissions(authStore.loggedInIdentity!.client!.id).pipe(
      tap((_permissions) => {
        setPermissions(_permissions);
      })
    )
  );
}

function setPermissions(_permissions: Permission[]) {
  permissionsSaved.value = cloneDeep(_permissions);
  permissions.value = _permissions;
}

function cancelChanges() {
  setPermissions(permissionsSaved.value);
}

function saveChanges() {
  /**
   * Save all changes done by client if validation valid
   */
  if (agreementConfirmForm.confirmed && authStore.loggedInIdentity?.client) {
    requestManager
      .new(
        'saveClientPermissions',
        forkJoin(
          fieldsData.value.map((f) =>
            services.authz.setClientsPermissions(authStore.loggedInIdentity!.client!.id, {
              ...f.setting,
              expires: f.data.form.expirationDate,
            })
          )
        ).pipe(mergeMap(() => loadPermissionSettings()))
      )
      .subscribe((_permissions) => {
        setPermissions(_permissions);
        toast.success('Your authorizations have been successfully updated.');
      });
  }
}

function downloadAgreementLink() {
  // Bypassing request manager as this request doesn't influence the component operations
  services.customerReference.downloadLegalDocument(LegalFileType.LPOA, ExportFileType.PDF).subscribe();
}

function updateExpirationFormState() {
  fieldsData.value.forEach((f) => {
    f.data.form.expirationDate = f.setting.expires;
    const expirationDate = getChildModel(f.data.form, 'expirationDate')!;
    setState(expirationDate, 'readonly', !f.setting.allow);
  });
}

watch(permissions, updateExpirationFormState, { immediate: true, deep: true });

if (authStore.loggedInIdentity?.client) {
  forkJoin([loadPermissionSettings(), loadAuthorities()]).subscribe(() => {
    updateExpirationFormState();
    agreementConfirmForm.confirmed = permissions.value.findIndex((p) => p.allow) !== -1;
  });
}
</script>

<template>
  <div>
    <h2>Authorisations</h2>
    <p class="text-secondary mt-n4">These settings authorise {{ partnerName }} to act on your behalf</p>
    <BoxGrid>
      <BoxGridBlock>
        <AuthorisationsSettingsTable
          class="p-2"
          :loading="requestManager.anyPending"
          :hideHeaders="$mediaQuery.is('smDown')"
        >
          <div class="authorisations-block">
            <VRow
              align-v="start"
              class="mb-md-5 mb-sm-3"
              v-for="fieldData in fieldsData"
              :key="fieldData.data.authority"
            >
              <VCol cols="6" :class="columnClass('authority')">
                <div class="mb-md-0 mb-sm-2">
                  <h3 class="title d-inline">{{ fieldData.authorisation.name }}</h3>
                  <BFormCheckbox
                    v-if="$mediaQuery.is('smDown')"
                    :checked="fieldData.setting.allow"
                    class="float-right"
                    name="check-button"
                    @change="fieldData.setting.allow = $event"
                    switch
                  />
                </div>
                <p class="sub-title text-secondary">
                  {{ fieldData.authorisation.description }}
                </p>
              </VCol>
              <VCol cols="2" :class="columnClass('switch')" v-if="!$mediaQuery.is('smDown')">
                <BFormCheckbox
                  :checked="fieldData.setting.allow"
                  name="check-button"
                  @change="fieldData.setting.allow = $event"
                  switch
                />
              </VCol>
              <VCol :class="columnClass('date')">
                <ValidatedForm
                  :fm="fieldData.data.form"
                  @update:validation="fieldData.data.validation = $event"
                  :ref="fieldData.data.authority.toLowerCase()"
                />
                <p class="text-muted">Optional</p>
              </VCol>
            </VRow>
            <VRow>
              <VCol :class="columnClass('authority')">
                <ValidatedForm :fm="agreementConfirmForm">
                  <template #agreementConfirm.confirmed:checkbox-label>
                    I confirm that I have read and agree to the Legal Power of Attorney (LPOA) agreement
                    <a class="link" @click.stop.prevent="downloadAgreementLink" target="_blank">here</a>.
                  </template>
                </ValidatedForm>
              </VCol>
            </VRow>
          </div>
        </AuthorisationsSettingsTable>
        <div class="my-4 text-sm-center text-md-left">
          <VButton class="secondary mr-2" label="Cancel" @click="cancelChanges" />
          <VButton
            :disabled="!isFormValid"
            class="btn-success"
            label="Save"
            @click="saveChanges"
            :loading="requestManager.anyPending"
          />
        </div>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .field-group-wrapper {
  margin-bottom: 0;
}

.btn {
  min-width: 140px;
}

.link {
  text-decoration: underline;
  @include themedTextColor($color-text, $color-dark-text);
}
</style>
