import mergeWith from 'lodash/mergeWith';
import { Form } from '../interfaces/form';

export function formOverride(form: Form, overrides: Partial<Form>) {
  return mergeWith(form, overrides, (objValue: any, srcValue: any) => {
    if (Array.isArray(objValue)) {
      return srcValue;
    }
    return undefined;
  });
}
