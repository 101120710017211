import {
  AuthenticationService,
  AuthorizationService,
  BankingReferenceService,
  BeneficiaryService,
  ClientService,
  FeesAndChargesService,
  FinancialReportsService,
  IndividualService,
  LimitsService,
  NotificationService,
  PricingEngineService,
  RateService,
  FxReferenceService,
  RiskReportsService,
  SpreadsService,
  TradeReportsService,
  TradeService,
  WalletService,
  TermsheetService,
  DocumentsService,
  PaymentsService,
  CreditService,
  IntegrationsService,
  PremiumPaymentService,
} from 'ah-api-gateways';
import { Toast } from 'ah-common-lib';
import { MediaQueries } from 'ah-common-lib/src/mediaQuery';
import _Vue, { PluginObject } from 'vue';
import { SettingsStore, WalletsStore } from 'ah-common-lib/src/models';
import { AuthStore } from 'ah-common-stores';
import VueI18n from 'vue-i18n';
import { NotificationsStore } from 'ah-notifications/src/store';
import { FeatureFlagStore } from 'ah-common-stores';
import { AppConfig } from 'config/baseConfig';
import { ClientCollateralService } from 'ah-api-gateways/services/clientCollateralService';

declare module 'vue/types/vue' {
  interface Vue {
    $ahTradesState: AhTradesState;
  }
}

export interface AhTradesPluginOptions {
  state: AhTradesState;
}

export interface AhTradesState {
  theme: {
    tradingDeskEmail: string;
    tradingDeskPhoneNumber: string;
    displayCostTransparency: boolean;
  };
  config: AppConfig;
  toast: Toast.Toast;
  mediaQuery: MediaQueries;
  showDevTools: boolean;
  i18n: VueI18n;
  store: {
    useWalletsStore: () => WalletsStore;
    useNotificationsStore: () => NotificationsStore;
    useSettingsStore: () => SettingsStore;
    useAuthStore: () => AuthStore;
    useFeatureFlagStore: () => FeatureFlagStore;
  };
  services: {
    pricingEngine: PricingEngineService;
    individual: IndividualService;
    client: ClientService;
    credit: CreditService;
    trade: TradeService;
    tradeReports: TradeReportsService;
    wallet: WalletService;
    risk: RiskReportsService;
    limits: LimitsService;
    authz: AuthorizationService;
    auth: AuthenticationService;
    notification: NotificationService;
    beneficiary: BeneficiaryService;
    spreads: SpreadsService;
    financial: FinancialReportsService;
    rates: RateService;
    bankingReference: BankingReferenceService;
    payments: PaymentsService;
    integrations: IntegrationsService;
    collateralProfile: ClientCollateralService;
    /**
     * Note:
     * Fees Service is currently NOT used for any OBO actions,
     * and it IS used for retrieving admin-only data in admin panel OBO contexts
     *
     * As such, it currently MAY be used targeting admin endpoints
     *
     * If OBO actions are added, it will need to be split into OBO and non-OBO imports
     */
    fees: FeesAndChargesService;
    fxReference: FxReferenceService;
    termsheetService: TermsheetService;
    documents: DocumentsService;
    premiumService: PremiumPaymentService;
  };
}

export const AhTradesPlugin = {
  install: function install(Vue: typeof _Vue, options: AhTradesPluginOptions) {
    const culledState: AhTradesState = {
      mediaQuery: options.state.mediaQuery,
      theme: options.state.theme,
      config: options.state.config,
      toast: options.state.toast,
      showDevTools: options.state.showDevTools,
      store: options.state.store,
      services: options.state.services,
      i18n: options.state.i18n,
    };
    Vue.prototype.$ahTradesState = new _Vue({
      data: culledState,
    });
  },
} as PluginObject<AhTradesPluginOptions>;

export function useAhTradesState() {
  return _Vue.prototype.$ahTradesState as AhTradesState;
}
