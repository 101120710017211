<script lang="ts" setup>
import {
  makeFormModel,
  batchSetState,
  toDataModel,
  updateModel,
  submitForm,
  resetForm,
} from 'ah-common-lib/src/form/helpers';
import { personalDetailsForm } from 'ah-common-lib/src/form/formModels';
import { mergeMap, catchError } from 'rxjs/operators';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { waitForCQRSEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { useAuthStore } from '@/app/store/authStore';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { computed, reactive } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useBvModal } from 'ah-common-lib/src/modals/useBvModal';
import { useToast } from 'ah-common-lib/src/toast';

const makePersonalDetailsFormModel = () => {
  const formModel = makeFormModel(personalDetailsForm(false, true));

  formModel.$fields = formModel.$fields.filter((f) => f.$name !== 'email' && f.$name !== 'phoneNumber');
  delete formModel.phoneNumber;
  delete formModel.email;

  batchSetState(
    formModel,
    'fieldWrapperClass',
    [
      'firstName',
      'lastName',
      'birthDate',
      'address.addressLine1',
      'address.addressLine2',
      'address.postalCode',
      'address.city',
    ],
    'col col-md-6 col-sm-12'
  );

  batchSetState(formModel, 'fieldType', ['birthDate'], 'text');

  batchSetState(formModel, 'title', {
    'address.addressLine1': 'Street Address',
    'address.addressLine2': 'Apt/Suite',
  });

  return formModel;
};

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const services = getServices();

const bvModal = useBvModal();

const toast = useToast();

const personalDetailsFormDef = reactive<FormDefinition>({
  form: makePersonalDetailsFormModel(),
  validation: null,
});

const model = computed(() => authStore.loggedInIdentity!);

const anyDirty = computed(() => personalDetailsFormDef.validation?.$anyDirty);

function reset() {
  if (model.value) updateModel(personalDetailsFormDef.form, model.value);
  resetPersonalDetailsForm();
}

function resetPersonalDetailsForm() {
  if (personalDetailsFormDef.validation) resetForm(personalDetailsFormDef.validation);
}

function saveModelRequest() {
  if (personalDetailsFormDef.validation) {
    submitForm(personalDetailsFormDef.validation);

    if (personalDetailsFormDef.validation.$invalid) {
      return;
    }
  }

  const modelUpdated = toDataModel(personalDetailsFormDef.form);

  if (modelUpdated.workPhoneNumber === '') {
    delete modelUpdated.workPhoneNumber;
  }

  requestManager
    .sameOrCancelAndNew(
      'saveModel',
      services.individual.updateIndividual(model.value.id, modelUpdated).pipe(
        mergeMap((idEntity) =>
          waitForCQRSEntityChange(
            idEntity,
            () => services.individual.getIndividual(idEntity.id, { errors: { silent: true } }),
            {
              checkProcessRestart: () => {
                return bvModal
                  .msgBoxConfirm(
                    'Your personal details were updated successfully, but our system may take some time to display it. Would you like to keep waiting?',
                    {
                      title: 'Continue loading?',
                      okTitle: 'Continue',
                      centered: true,
                    }
                  )
                  .then((value) => !!value);
              },
            }
          ).pipe(catchError(() => of(null)))
        )
      )
    )
    .subscribe((individual) => {
      if (individual) {
        authStore.setIdentity(individual);
        toast.success('Personal Details updated.');
        resetPersonalDetailsForm();
      } else {
        toast.error('An unexpected problem has occurred. Please try again later.');
      }
    });
}

updateModel(personalDetailsFormDef.form, model.value);
</script>

<template>
  <div class="change-contacts-form">
    <ValidatedForm :fm="personalDetailsFormDef.form" :validation.sync="personalDetailsFormDef.validation" />
    <div class="form-actions text-right">
      <div class="form-actions text-left">
        <VButton
          :loading="requestManager.requestStates.saveModel === 'pending'"
          :disabled="!anyDirty"
          label="Update details"
          class="btn btn-primary mr-2"
          @click="saveModelRequest"
        />
        <VButton
          class="btn btn-secondary mr-2"
          v-if="anyDirty"
          :disabled="requestManager.requestStates.saveModel === 'pending'"
          label="Reset"
          @click="reset"
        />
      </div>
    </div>
    <RouteProtectorModal
      :allowChange="!anyDirty"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .readonly {
  pointer-events: none;
}
</style>
