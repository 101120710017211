import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import { Observable } from 'rxjs';

import {
  VersionedObject,
  IndividualGroup,
  IndividualGroupRequest,
  PaginatedQuery,
  PaginatedResponse,
  Partner,
  PartnerSettings,
  standardiseQuerySort,
} from '../models';

export interface PartnerListFilter {
  query?: string;
}

export class PartnerService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'partnerService' } },
      },
    });
  }

  public getPartner(options?: HttpOptions<Partner>) {
    return this.get<Partner>(`${this.baseUrl}partner`, {
      options: {
        ...options,
      },
    });
  }

  public updatePartner(partner: Partial<Partner>, options?: HttpOptions<VersionedObject>) {
    return this.put<VersionedObject>(`${this.baseUrl}partner`, partner, {
      options: {
        ...options,
      },
    });
  }

  public getPartnerSettings(options?: HttpOptions<PartnerSettings>) {
    return this.get<PartnerSettings>(`${this.baseUrl}partner/settings`, {
      options: {
        ...options,
      },
    });
  }

  public updatePartnerSettings(data: Partial<PartnerSettings>, options?: HttpOptions<PartnerSettings>) {
    return this.put<PartnerSettings>(`${this.baseUrl}partner/settings`, data, {
      options: {
        ...options,
      },
    });
  }

  public listPartnerGroups(
    query: PaginatedQuery & { individualId?: string[] },
    options?: HttpOptions<PaginatedResponse<IndividualGroup>>
  ): Observable<PaginatedResponse<IndividualGroup>> {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<IndividualGroup>>(`${this.baseUrl}partner/groups`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public getPartnerGroup(groupId: string, options?: HttpOptions<IndividualGroup>): Observable<IndividualGroup> {
    return this.get<IndividualGroup>(`${this.baseUrl}partner/groups/${groupId}`, {
      options,
    });
  }

  public updatePartnerGroup(
    group: IndividualGroupRequest,
    options?: HttpOptions<VersionedObject>
  ): Observable<VersionedObject> {
    return this.put<VersionedObject>(`${this.baseUrl}partners/groups/${group.id}`, group, { options });
  }

  public createPartnerGroup(
    group: Omit<IndividualGroupRequest, 'id'>,
    options?: HttpOptions<VersionedObject>
  ): Observable<VersionedObject> {
    return this.post<VersionedObject>(`${this.baseUrl}partners/groups`, group, {
      options,
    });
  }
}
