import _Vue, { PluginObject } from 'vue';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import VueRouter from 'vue-router';
import { HttpService, InternalCustomAxiosRequestConfig } from 'ah-requests';

declare module 'vue/types/vue' {
  interface VueConstructor {
    ahAppUpdaterState: AppUpdater;
  }
  interface Vue {
    $ahAppUpdaterState: AppUpdater;
  }
}

export interface AhAppUpdaterPluginOptions {
  state: {
    installedVersion: string;
  };
  storeKey?: string;
  beforeUpdateReload?: () => Promise<any>;
  router: VueRouter;
  http: HttpService;
}

export interface AhAppUpdaterState {
  lastVersionCheck: number;
  installedVersion: string;
  latestVersion: string;
  latestAttemptedVersion: string;
  latestUpdateAttempt: number;
}

export interface AhAppUpdaterComputed {
  isUpToDate: boolean;
}

export interface AhAppUpdaterMethods {
  checkAppVersion: (force?: boolean) => Promise<void>;
  reloadToUpdate: (force?: boolean) => void;
  saveData: () => void;
  loadData: () => void;
}

export type AppUpdater = AhAppUpdaterState & AhAppUpdaterMethods & AhAppUpdaterComputed;

/**
 * Check for version update
 *
 * Will check for a higher version with the following checks:
 * - If any of the versions in candidate are higher than the current, returns true (checked in order)
 * - If any of the versions in candidate are lower than the current, returns false (checked in order)
 * - If all versions are the same, checks if candidate is strictly different from current
 */
function isHigherVersion(candidate: string, current: string) {
  try {
    const candidateVersions = candidate.split('.').map((i) => parseInt(i, 10));
    const currentVersions = current.split('.').map((i) => parseInt(i, 10));

    for (let i = 0; i < candidateVersions.length; i++) {
      if (candidateVersions[i] > currentVersions[i]) {
        return true;
      }
      if (candidateVersions[i] < currentVersions[i]) {
        return false;
      }
    }
    return candidate !== current;
  } catch (e) {
    return false;
  }
}

const VERSION_CHECK_TIMEOUT = MINUTE * 15;
const UPDATE_RETRY_TIMEOUT = MINUTE * 5;

const LOCALSTORAGE_FIELDS = ['lastVersionCheck', 'latestVersion'];
const SESSIONSTORAGE_FIELDS = ['latestAttemptedVersion', 'latestUpdateAttempt'];

const DEFAULT_STORE_KEY = 'ahAppUpdater';

let appUpdaterState: AppUpdater | undefined = undefined;

export function useAppUpdaterState(): AppUpdater {
  if (!appUpdaterState) {
    throw 'App updater not initialized!';
  }
  return appUpdaterState;
}

/**
 * App updater plugin
 *
 * This manages any version discrepancies in the application, and triggers a refresh accordingly:
 * - On route change
 * - On user command, if the update has not happened for over 3 minutes
 */
export const AhAppUpdaterPlugin = {
  install: function install(Vue: typeof _Vue, options: AhAppUpdaterPluginOptions) {
    const storeKey = options.storeKey || DEFAULT_STORE_KEY;
    let initial = true;
    let appVersionPromise: Promise<void> | null = null;

    appUpdaterState = new _Vue<AhAppUpdaterState, AhAppUpdaterMethods, AhAppUpdaterComputed>({
      data: () => ({
        /**
         * Date of the last version check
         *
         * Stored in localStorage for cross tab sync
         */
        lastVersionCheck: 0,
        /**
         * Currently running version
         * Not stored
         */
        installedVersion: options.state.installedVersion,
        /**
         * Latest available version
         *
         * Stored in localStorage for cross tab sync
         */
        latestVersion: '',
        /**
         * Latest version attempted to update to
         *
         * Stored in sessionStorafe (not synced)
         */
        latestAttemptedVersion: '',
        /**
         * Latest update attempt
         *
         * Stored in sessionStorafe (not synced)
         * Initiallised as the date of page loading (to prevent multiple refresh attempts)
         */
        latestUpdateAttempt: Date.now(),
      }),
      created() {
        this.loadData();
      },
      methods: {
        saveData() {
          const localStorageData = LOCALSTORAGE_FIELDS.reduce((o, f) => ({ ...o, [f]: this.$data[f] }), {} as any);
          window.localStorage.setItem(storeKey, JSON.stringify(localStorageData));

          const sessionStorageData = SESSIONSTORAGE_FIELDS.reduce((o, f) => ({ ...o, [f]: this.$data[f] }), {} as any);
          window.sessionStorage.setItem(storeKey, JSON.stringify(sessionStorageData));
        },
        loadData() {
          const localStorageItem = window.localStorage.getItem(storeKey);
          if (localStorageItem) {
            try {
              const data = JSON.parse(localStorageItem);
              LOCALSTORAGE_FIELDS.forEach((k) => {
                if (this.$data.hasOwnProperty(k)) {
                  Vue.set(this, k, data[k]);
                }
              });
            } catch (e) {
              // do nothing
            }
          }

          const sessionStorageItem = window.sessionStorage.getItem(storeKey);
          if (sessionStorageItem) {
            try {
              const data = JSON.parse(sessionStorageItem);
              SESSIONSTORAGE_FIELDS.forEach((k) => {
                if (this.$data.hasOwnProperty(k)) {
                  Vue.set(this, k, data[k]);
                }
              });
            } catch (e) {
              // do nothing
            }
          }
        },
        checkAppVersion(force = false) {
          if (!force && this.lastVersionCheck + VERSION_CHECK_TIMEOUT > Date.now()) {
            return Promise.resolve();
          }
          if (appVersionPromise) {
            return appVersionPromise;
          }
          appVersionPromise = options.http
            .request({
              axiosConfig: {
                method: 'GET',
                url: '/version.json',
              },
              options: {
                skipAuth: true,
                skipMaintenanceCheck: true,
                errors: {
                  silent: true,
                },
              },
            })
            .toPromise()
            .then((response) => {
              const version = (response.data as any)?.version ?? '';
              this.lastVersionCheck = Date.now();
              if (version) {
                this.latestVersion = version;
              }
              this.saveData();
            })
            .finally(() => {
              appVersionPromise = null;
            });
          return appVersionPromise;
        },
        reloadToUpdate(force = false) {
          if (
            force ||
            this.latestAttemptedVersion !== this.latestVersion ||
            this.latestUpdateAttempt + UPDATE_RETRY_TIMEOUT < Date.now()
          ) {
            this.latestAttemptedVersion = this.latestVersion;
            this.latestUpdateAttempt = Date.now();
            this.saveData();
            Promise.resolve(options.beforeUpdateReload ? options.beforeUpdateReload() : undefined).then(() => {
              window.location.reload();
            });
          }
        },
      },
      computed: {
        isUpToDate() {
          return !this.latestVersion || !isHigherVersion(this.latestVersion, this.installedVersion);
        },
      },
    });

    const state = appUpdaterState!;

    options.http.interceptRequest((httpConfig: InternalCustomAxiosRequestConfig<any>) => {
      try {
        state.checkAppVersion();
      } catch (e) {
        // do nothing (app version check should never block requests)
      }
      return httpConfig;
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        state.loadData();
        state.checkAppVersion();
      }
    });

    options.router.afterEach((to, from) => {
      if (initial) {
        initial = false;
        return;
      }
      if (to.path !== from.path && !state.isUpToDate) {
        state.reloadToUpdate();
      }
    });

    window.addEventListener(
      'storage',
      (event) => {
        if (event.storageArea === localStorage && event.key === storeKey) {
          state.loadData();
        }
      },
      false
    );

    state.checkAppVersion(true);

    Vue.prototype.$ahAppUpdaterState = state;
    Vue.ahAppUpdaterState = state;
  },
} as PluginObject<AhAppUpdaterPluginOptions>;
