import { render, staticRenderFns } from "./ClientIndividualsList.vue?vue&type=template&id=045853b3&scoped=true"
import script from "./ClientIndividualsList.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClientIndividualsList.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ClientIndividualsList.vue?vue&type=style&index=0&id=045853b3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "045853b3",
  null
  
)

export default component.exports