<script setup lang="ts">
import UserMenu from './UserMenu.vue';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import { BModal } from 'bootstrap-vue';
import UserIcon from './UserIcon.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import Vue, { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

const authStore = useAuthStore();

const route = useRoute();

const individualSettingsStore = useIndividualSettingsStore();

const userMenu = ref<InstanceType<typeof UserMenu>>();

const deleteModal = ref<InstanceType<typeof BModal>>();

const show = ref(false);

const clickListener = ref<(event: MouseEvent) => void>();

onBeforeMount(() => {
  if (authStore.isClientUser) {
    individualSettingsStore.loadIndividualDocuments();
  }
});

onMounted(() => {
  clickListener.value = (event: MouseEvent) => {
    if (
      show.value &&
      userMenu.value &&
      event.target &&
      !isDescendant((userMenu.value as Vue).$el, event.target as Element)
    ) {
      show.value = false;
    }
  };
  window.addEventListener('click', clickListener.value);
});

const user = computed(() => authStore.loggedInIdentity);

function logout() {
  deleteModal.value?.show();
  deleteModal.value?.$once('ok', () => authStore.logout());
}

function onRouteChange() {
  show.value = false;
}

watch(() => route, onRouteChange, { deep: true });

onBeforeUnmount(() => {
  if (clickListener.value) {
    window.removeEventListener('click', clickListener.value);
  }
});
</script>

<template>
  <div id="user-menu-toggle">
    <div class="d-flex justify-content-center align-items-center cursor-pointer" v-if="user">
      <UserIcon :user="user" class="mr-3" />
      <p class="semi-bold m-0">{{ user.firstName }} {{ user.lastName }}</p>
      <IconChevronDown :class="['ml-3 menu-toggle', { open: show }]" />
    </div>
    <BPopover
      v-if="user"
      placement="bottomright"
      custom-class="arrowless"
      target="user-menu-toggle"
      triggers="click blur"
      :show.sync="show"
    >
      <UserMenu ref="userMenu" @logout="logout" />
    </BPopover>

    <!-- MODALS -->
    <BModal
      :centered="true"
      ok-title="Yes, log out"
      cancel-variant="secondary"
      body-class="py-5 px-5"
      footer-class="justify-content-start pl-5 pb-5"
      ref="deleteModal"
      >Are you sure you want to log out?
    </BModal>
  </div>
</template>

<style lang="scss">
.b-popover {
  z-index: 100;
}

#user-menu-toggle {
  padding: 10px 15px;
}

.b-popover.arrowless .arrow {
  opacity: 0;
}

.menu-toggle {
  cursor: pointer;
  transition: transform 0.6s;
  width: 24px;
  height: 24px;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
