<script lang="ts" setup>
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';
import { useI18n } from 'ah-common-lib/src/i18n';
import { computed } from 'vue';

defineProps({
  rateAlert: {
    type: [String, Boolean],
    default: false,
  },
  loading: {
    type: [Boolean, String],
    default: false,
  },
  hideTargets: {
    type: [String, Boolean],
    default: false,
  },
});

const mediaQuery = useMediaQuery();

const i18n = useI18n();

const isStackedView = computed(() => mediaQuery.is('mdDown'));
</script>

<template>
  <LoadingOverlay :loading="loading">
    <div class="notifications-table">
      <VRow class="notifications-header align-items-end" v-if="!isStackedView">
        <VCol class="description-col">
          <VRow v-if="rateAlert !== false">
            <VCol class="rate-alert-edit-col"> </VCol>
            <VCol class="rate-alert-pair-col"> Currency pair </VCol>
            <VCol class="rate-alert-rate-col"> Rate to alert </VCol>
          </VRow>
          <span v-else>Description</span>
        </VCol>
        <VCol class="notify-col" v-if="hideTargets === false"> Users to notify </VCol>
        <VCol class="switches-col">
          <VRow>
            <VCol class="switch-col">
              Email notification
              <InfoTooltip class="header-tooltip" :text="i18n.t('tooltips.emailNotification')" />
            </VCol>
            <VCol class="switch-col">
              In-app notification
              <InfoTooltip class="header-tooltip" :text="i18n.t('tooltips.inAppNotification')" />
            </VCol>
            <!-- <VCol class="switch-col">
            SMS notification
            <InfoTooltip class="header-tooltip" :text="$t('tooltips.SMSNotification')" />
          </VCol> -->
          </VRow>
        </VCol>
      </VRow>
      <div class="notifications-body">
        <slot v-bind="{ isStackedView }" />
      </div>
    </div>
  </LoadingOverlay>
</template>

<style lang="scss" scoped>
.header-tooltip {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
}

.notifications-table {
  .notifications-header {
    font-weight: 600;
    padding-bottom: math.div($padded-space, 2);
    margin-bottom: $padded-space;
    @include themedPropColor('border-bottom', $color-box-border, $color-dark-box-border, '1px solid ');
  }

  .notifications-block:not(:last-child) {
    margin-bottom: $padded-space;
    @include themedPropColor('border-bottom', $color-box-border, $color-dark-box-border, '1px solid ');

    h3.notifications-block-title {
      margin-bottom: math.div($padded-space, 3);
      text-align: center;
    }

    @include desktop {
      @include themedProp('border-bottom', none, none);
      h3.notifications-block-title {
        text-align: left;
      }
    }
  }

  .no-notifications {
    text-align: center;

    @include desktop {
      text-align: left;
    }
  }

  .add-notification {
    display: block;
    margin: auto;

    @include desktop {
      margin: 0;
    }
  }

  .single-notification-editor {
    padding: math.div($padded-space, 3) 0;
    .description-col .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
    }

    &.sub-item {
      padding-top: 0;

      ::v-deep {
        .description-col .title {
          padding-left: 1em;
        }
      }
    }
  }

  ::v-deep {
    .description-col,
    .notify-col,
    .switches-col {
      flex-basis: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      margin-bottom: math.div($padded-space, 2);
    }

    .switches-col .switch-col {
      flex-basis: 33%;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
    }

    .rate-alert-edit-col {
      flex-basis: 2em;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .description-col {
      text-align: left;
      flex-basis: 0;
      flex-grow: 1;
      @include tablet {
        margin-right: 0.5em;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .notify-col {
      text-align: left;
      @include tablet {
        flex-basis: 12em;
      }
      @include desktop {
        flex-basis: 14em;
      }
    }

    .switches-col {
      @include tablet {
        flex-basis: 12em;
      }
      @include desktop {
        flex-basis: 18em;
      }
      .switch-col {
        flex-basis: 6em;
        @include upToResolution($tabletResolution) {
          flex-basis: 50%;
        }
      }
    }
  }
}
</style>
