<script setup lang="ts">
import AccountLimitsAndRequirementsHelpPage from '../../alt21Links/AccountLimitsAndRequirementsHelpPage.vue';
import SliderInput from 'ah-common-lib/src/common/components/SliderInput.vue';
import { VCol } from 'ah-common-lib/src/common/components';
import { VRow } from 'ah-common-lib/src/common/components';
import { computed, ref, watch } from 'vue';

const STEP_MULTIPLIER = 0.5;

const DEFAULT_PERCENTAGES = {
  min: 1,
  max: 10,
};

const props = defineProps({
  variationMarginPercentage: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits({
  'update:variationMarginPercentage': (_payload: number) => true,
});

/**
 * Since the percentage value from BE comes (an also receives it) in decimal, i.e. a value between 0 and 1
 * We need to convert this to real percentage, i.e. a value between 0 and 100%
 */
const parsedVariationMarginPercentage = computed(() => props.variationMarginPercentage * 100);

const selectedVariationMarginValue = ref<number>(parsedVariationMarginPercentage.value);

watch(selectedVariationMarginValue, (newAmount) => {
  /**
   * Since the percentage value from BE comes (an also receives it) in decimal, i.e. a value between 0 and 1
   * And inside of this component we convert it to real percentage, i.e. a value between 0 and 100%
   * When we emit the value we need to convert it again to decimal
   */
  const percentageToDecimal = newAmount / 100;
  emit('update:variationMarginPercentage', percentageToDecimal);
});
</script>

<template>
  <VRow class="variation-margin">
    <VCol cols="12" class="header">
      <h4>Variation Margin</h4>
      <AccountLimitsAndRequirementsHelpPage />
    </VCol>
    <VCol cols="12">
      <SliderInput
        :value.sync="selectedVariationMarginValue"
        :min="{ value: DEFAULT_PERCENTAGES.min, label: `${DEFAULT_PERCENTAGES.min}%` }"
        :max="{ value: DEFAULT_PERCENTAGES.max, label: `${DEFAULT_PERCENTAGES.max}%` }"
        showMarkers
        :markers="9"
        showTooltip="always"
        :stepMultiplier="STEP_MULTIPLIER"
        :decimalPlaces="1"
      />
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.variation-margin,
.header {
  margin-bottom: 4rem;
}
</style>
