import { AppConfig } from 'config/baseConfig';
import { RequestManager } from 'ah-requests';
import { TabSync } from '../tabSync/tabSync';

export interface StoreSupportData<Data = {}, Services = {}> {
  reqManager: RequestManager;
  config: AppConfig;
  s: Services;
  tabSync: TabSync;
  data: Data;
}

export function makeStoreSupportData<Data = {}, Services = {}>(config: {
  data?: Data;
  config: AppConfig;
  s: Services;
  tabSync: TabSync;
}): StoreSupportData<Data, Services> {
  return {
    reqManager: new RequestManager(),
    data: {} as Data,
    ...config,
  };
}
