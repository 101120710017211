<script setup lang="ts">
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import { reactive, ref } from 'vue';
import { useTheme } from 'ah-theme';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { textField } from 'ah-common-lib/src/form/models';

const theme = useTheme();

const requestManager = useRequestManager().manager;

const services = getServices();

const forgottenPasswordFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'ForgottenPasswordForm',
    fieldType: 'form',
    fields: [
      textField('username', 'Username', {
        placeholder: 'Username',
        required: true,
        errorMessages: {
          required: 'Username is required',
        },
      }),
    ],
  }),
  validation: null,
});

const isUsernameSent = ref<boolean>(false);

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-submit') {
    submit();
  }
}

function supportEmail() {
  theme.val?.helpEmail;
}

function submit() {
  if (forgottenPasswordFormDef.validation?.$invalid) {
    submitForm(forgottenPasswordFormDef.validation);
    return;
  }

  const payload = { username: forgottenPasswordFormDef.form.username };

  requestManager.sameOrCancelAndNew('resetPassword', services.account.resetPassword(payload), payload).subscribe(() => {
    isUsernameSent.value = true;
  });
}
</script>

<template>
  <SimpleCenteredPage fullHeight narrow id="forgotten-password-view">
    <div v-if="!isUsernameSent" class="main-area narrow mt-5">
      <h1 class="mb-3">Reset password</h1>
      <p class="mb-4">
        The security of your account is important to us. <br />
        To reset your password, enter your username.
      </p>
      <p class="username-warning">If you have not set up a username, by default it will be your email address.</p>
      <ValidatedForm
        class="rounded-fields"
        :fm="forgottenPasswordFormDef.form"
        :validation.sync="forgottenPasswordFormDef.validation"
        @form-event="onFormEvent"
      />
      <div class="form-actions d-flex justify-content-center align-items-center mt-5">
        <VButton :loading="requestManager.anyPending" @click="submit" class="btn-primary"> Get reset link </VButton>
      </div>
    </div>
    <div v-else class="main-area narrow mt-5">
      <h1 class="mb-3">Email sent</h1>
      <p class="mb-5">Check your email inbox for instructions from us on how to reset your password.</p>
      <hr />
    </div>
    <template #footer>
      <p v-if="!isUsernameSent">
        Already have an account?
        <RouterLink class="link back-to-login" :to="'/login'">Login</RouterLink>.
      </p>
      <div v-else>
        <p class="mb-2">
          <RouterLink class="link back-to-login" :to="'/login'">Go back to Login</RouterLink>
        </p>
        <p>
          Unable to reset your password?
          <a class="support-link" :href="`mailto:${supportEmail}?subject=Account access support`">Contact us.</a>
        </p>
      </div>
    </template>
  </SimpleCenteredPage>
</template>

<style lang="scss" scoped>
hr {
  margin-bottom: 2em;
  border-color: $x-ui-light-code-fd-subtle;
}

.back-to-login,
.support-link {
  font-weight: $font-weight-semibold;
}

.username-warning {
  @include themedTextColor($x-ui-light-tag-orange-text, $x-ui-dark-tag-orange-text);
  @include themedBackgroundColor($x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
  font-size: 0.625em;
  padding: 4px 8px;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: $font-weight-regular;
  font-family: $font-family-inter;
}
</style>
