import AhSlider from './AhSlider.vue';
import AhSliderMark from './AhSliderMark.vue';
import AhSliderDot from './AhSliderDot.vue';
import { ERROR_TYPE } from './utils/control';

/**
 * AhSlider component family
 *
 * This component is lifted from the original vue-slider, now defunct, with the following changes:
 * - Components renamed to match the AhFoo naming convention
 * - Changes to ensure compatibility
 *   - `value` prop is now `modelValue`
 *
 * Original vue-slider documentation links:
 * - https://nightcatsama.github.io/vue-slider-component
 * - https://github.com/NightCatSama/vue-slider-component
 *
 * TODO move components to composition API, for consistency
 */

(AhSlider as any).AhSliderMark = AhSliderMark;
(AhSlider as any).AhSliderDot = AhSliderDot;

export default AhSlider;
export { ERROR_TYPE, AhSliderMark, AhSliderDot };
