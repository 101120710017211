import { Address } from '../common';
import { PaymentRail } from '../payments';
import { BeneficiaryIdentificationType } from './beneficiaryEnums';

export enum BankAccountStatus {
  NEW = 'NEW',
  VALIDATED = 'VALIDATED',
  VERIFIED = 'VERIFIED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  ERROR = 'ERROR',
}

export enum BankAccountType {
  SAVINGS = 'SAVINGS',
  CHECKINGS = 'CHECKINGS',
}

export enum BankingScheme {
  LOCAL = 'LOCAL',
  SWIFT = 'SWIFT',
}

export enum RoutingCodeType {
  SORT_CODE = 'SORT_CODE',
  ABA = 'ABA',
  BSB_CODE = 'BSB_CODE',
  INSTITUTION_NO = 'INSTITUTION_NO',
  BANK_CODE = 'BANK_CODE',
  BRANCH_CODE = 'BRANCH_CODE',
  CLABE = 'CLABE',
  CNAPS = 'CNAPS',
  IFSC = 'IFSC',
}
export const routingCodeTypeLabels: Record<RoutingCodeType, string> = {
  [RoutingCodeType.SORT_CODE]: 'Sort code',
  [RoutingCodeType.ABA]: 'ABA number',
  [RoutingCodeType.BSB_CODE]: 'BSB code',
  [RoutingCodeType.INSTITUTION_NO]: 'Institution number',
  [RoutingCodeType.BANK_CODE]: 'Bank code',
  [RoutingCodeType.BRANCH_CODE]: 'Branch code',
  [RoutingCodeType.CLABE]: 'CLABE number',
  [RoutingCodeType.CNAPS]: 'CNAPS code',
  [RoutingCodeType.IFSC]: 'IFSC code',
};

export interface BankAccountBaseData {
  identificationType?: BeneficiaryIdentificationType;
  identificationValue?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  address: Address;
}

export interface BankAccount extends BankAccountBaseData {
  id: string;
  reference: string;
  accountHolderName: string;
  countryCode: string;
  currency: string;
  iban: string;
  bicSwift: string;
  bankName: string;
  bankAddress: string;
  bankCountry: string;
  routingNumber1: string;
  routingNumber2: string;
  accountNumber: string;
  accountType: BankAccountType;
  bankingScheme: BankingScheme;
  purposeCode: string;
  paymentRail: PaymentRail;
}
