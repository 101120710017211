<script setup lang="ts">
import { useAuthStore } from '@/app/store/authStore';
import SearchableTradeList from 'ah-trades/src/components/lists/SearchableTradeList.vue';
import ActivityTradeListFilters from 'ah-trades/src/components/lists/ActivityTradeListFilters.vue';
import { clientTradeStates, clientTradeStatus, fxHedgingInstruments } from 'ah-api-gateways';
import { computed } from 'vue';
import { partnerTradeReportTableFieldsFull } from 'ah-trades/src/models/tradeTableFields';

const authStore = useAuthStore();

const partnerId = computed(() => {
  return authStore.loggedInIdentity?.partner.id;
});

const config = computed(() => {
  return { tableFields: partnerTradeReportTableFieldsFull };
});

const possibleStates = computed(() => clientTradeStates);

const possibleStatuses = computed(() => clientTradeStatus);

const possibleInstruments = computed(() => fxHedgingInstruments);
</script>

<template>
  <div class="padded-space" id="activity-payments-view">
    <h2>Client Trades</h2>
    <SearchableTradeList
      ref="tradeList"
      showSearch
      withRowDetails
      showExport
      :possibleStatuses="possibleStatuses"
      :possibleStates="possibleStates"
      :possibleInstruments="possibleInstruments"
      :commonFilters="{ partnerId: partnerId }"
      :config="config"
      paginationQueryParam="activityTradesSort"
      filterQueryParam="activityTradesFilter"
      documentTitle="Client Trades List"
    >
      <template #filters="{ onFilterChange, filter }">
        <ActivityTradeListFilters
          showDates
          showSearch
          showStatuses
          :filter="filter"
          :possibleStatuses="possibleStatuses"
          :possibleStates="possibleStates"
          :possibleInstruments="possibleInstruments"
          @update:filter="onFilterChange"
        />
      </template>
    </SearchableTradeList>
  </div>
</template>
