<script lang="ts" setup>
import { Authority, Permission } from 'ah-api-gateways';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { cloneDeep } from 'lodash';
import { computed, PropType, watch } from 'vue';

const props = defineProps({
  authorities: { type: Array as PropType<Authority[]>, required: true },
  value: { type: Array as PropType<Permission[]>, required: true },
  hideSwitch: { type: [Boolean, String], default: false },
  hideGroupTitle: { type: [Boolean, String], default: false },
  readonly: { type: [Boolean, String], default: false },
});

const emit = defineEmits({
  'permission-change': (_permission: Permission) => true,
  'update:value': (_value: Permission[]) => true,
});

const shouldDisplaySwitch = computed(() => props.hideSwitch === false);

const shouldBeReadonly = computed(() => props.readonly !== false);

const shouldDisplayGroupTitle = computed(() => props.hideGroupTitle === false);

const groupedAuthoritites = computed(() => {
  const out = groupBy(props.authorities, 'sectionName');

  return Object.keys(out)
    .map((group) => ({
      name: group,
      index: out[group][0].sectionIndex,
      authorities: sortBy(out[group], 'index'),
    }))
    .sort((a, b) => a.index - b.index);
});

function getCurrentValue() {
  let out = cloneDeep(props.value);
  if (out.length === 0) {
    out = props.authorities.map((authority) => ({ authority: authority.id, allow: false }));
  }
  return out;
}

function setPermission(id: string, value: boolean) {
  let tempVal = getCurrentValue();
  const permission = tempVal.find((permission) => permission.authority === id);
  if (permission) {
    permission.allow = value;
    emit('permission-change', permission);
  }
  emit('update:value', tempVal);
}

function hasPermission(id: string) {
  const permission = props.value.find((permission) => permission.authority === id);
  return permission?.allow ?? false;
}

watch(
  () => props.authorities,
  () => {
    if (props.authorities.length && !props.value.length) {
      emit('update:value', getCurrentValue());
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="permissions-form">
    <div v-for="group in groupedAuthoritites" :key="group.name" class="auth-section">
      <h3 class="text-secondary mb-1" v-if="shouldDisplayGroupTitle">{{ group.name }}</h3>
      <div
        :class="shouldDisplaySwitch ? 'justify-content-between' : 'justify-content-start'"
        class="d-flex"
        v-for="authority in group.authorities"
        :key="authority.id"
      >
        <div class="permission-switch" v-if="!shouldDisplaySwitch">
          <BFormCheckbox
            :checked="hasPermission(authority.id)"
            :name="`check-button-${authority.name}`"
            :disabled="shouldBeReadonly"
            @input="setPermission(authority.id, $event)"
          />
        </div>
        <div class="permission-description">
          <h4>{{ authority.name }}</h4>
          <p class="text-secondary">{{ authority.description }}</p>
        </div>
        <div class="permission-switch" v-if="shouldDisplaySwitch">
          <BFormCheckbox
            :checked="hasPermission(authority.id)"
            :name="`check-button-${authority.name}`"
            :disabled="shouldBeReadonly"
            switch
            @input="setPermission(authority.id, $event)"
          />
        </div>
      </div>
    </div>
    <p v-if="authorities.length === 0" class="text-secondary">No editable permissions available for this role</p>
  </div>
</template>
