<script lang="ts" setup>
import { Client } from 'ah-api-gateways';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import { computed, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const props = defineProps({
  clientId: {
    type: String,
    required: true,
  },
  clientName: {
    type: String,
  },
  baseUrl: {
    type: String,
  },
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'getIndividual') {
      loadIndividual();
    }
  },
}).manager;

const services = getServices();

const client = ref<Client>();

const name = computed(() => {
  return props.clientName || client.value?.name;
});

const redirectTo = computed(() => {
  return `${props.baseUrl}${props.clientId}`;
});

function loadIndividual() {
  if (!props.clientName) {
    requestManager
      .sameOrCancelAndNew(
        'getIndividual',
        services.client.getClient(props.clientId, true, { errors: { silent: true } }),
        props.clientId
      )
      .subscribe((response) => {
        client.value = response;
      });
  }
}

watch(() => props.clientId, loadIndividual, { immediate: true });
</script>

<template>
  <div>
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else-if="name">
      <RouterLink :to="redirectTo" v-if="baseUrl"> {{ name }} </RouterLink>
      <span v-else class="client-name">{{ name }}</span>
    </template>
    <span v-else>N/A</span>
  </div>
</template>

<style lang="scss" scoped>
span:not(.client-name) {
  opacity: 0.5;
}
</style>
