<script setup lang="ts">
import { FundingCharge } from 'ah-api-gateways';
import { useSettingsStore } from '@/app/store/settingsModule';
import { computed, PropType } from 'vue';

interface CountryCodePair {
  countryName: string;
  countryCode: string;
}

const settingsStore = useSettingsStore();

const props = defineProps({
  fees: {
    type: Array as PropType<FundingCharge[]>,
    required: true,
  },
});

settingsStore.loadCountries();

const countries = computed(() => settingsStore.allCountries);

const sortedList = computed(() => {
  if (props.fees.length === 0) {
    return null;
  }
  const sectionTierList = [] as Array<CountryCodePair>;
  props.fees.forEach((fee) =>
    sectionTierList.push({ countryName: getCountryName(fee.countryCode), countryCode: fee.countryCode })
  );
  return sectionTierList.sort((a, b) => (a.countryName > b.countryName ? 1 : -1));
});

function isFirstLetter(index: number) {
  if (!sortedList.value) return false;
  const diff = index !== 0 && sortedList.value[index].countryName[0] !== sortedList.value[index - 1].countryName[0];
  return index === 0 || diff;
}

function lastOfSection(index: number) {
  if (!sortedList.value) return false;
  if (index === sortedList.value.length - 1) return true;
  const diff = sortedList.value[index].countryName[0] !== sortedList.value[index + 1].countryName[0];
  return sortedList.value.length > index + 1 && diff;
}

function getCountryName(cc: String) {
  const countryName = countries.value.find((country) => country.cc === cc);
  return countryName ? countryName.name : '';
}
</script>

<template>
  <div v-if="sortedList && countries.length">
    <div class="country-list" v-for="(fee, index) in sortedList" :key="`${index + fee.countryCode}`">
      <div class="country-list-header px-2 mb-2" v-if="isFirstLetter(index)">
        {{ fee.countryName[0] }}
      </div>
      <VRow
        class="country-item mb-2 pb-1"
        :class="lastOfSection(index) ? 'last-index' : ''"
        align-h="between"
        cols="12"
      >
        <VCol md="9" sm="7"> {{ fee.countryName }} </VCol>
        <VCol md="3" sm="5"> {{ fee.countryCode }} </VCol>
      </VRow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.country-list-header {
  margin: 0 -8px 0 -8px;
  font-weight: $font-weight-bold;
  @include themedTextColor($color-dark-text, $color-dark-text);
  @include themedBackgroundColor($color-dark-primary, $color-primary);
}

.country-item {
  font-size: 0.9em;
  &:not(.last-index) {
    @include themedPropColor('border-bottom', $color-dark-main-bg, $color-main-bg, '', ' 0.5px solid');
  }
}
</style>
