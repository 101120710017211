import { Ref, ref, computed, watch } from 'vue';
import { PaymentsService } from 'ah-api-gateways';
import { RequestManager } from 'ah-requests';
import { PaymentAggregateLimit } from 'ah-api-gateways/models/payments/paymentAggregatedLimit';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { editRoute } from 'ah-common-lib/src/helpers/route';
import Router from 'vue-router';

export interface AggregatedPaymentLimitCheckerOptions {
  requestManager?: RequestManager;
  loadRequestKey?: string;
  loadOnChanges?: boolean;
  services: {
    payments: PaymentsService;
  };
  data: {
    amendPaymentAmount?: Ref<number | undefined>;
    clientId: Ref<string | undefined>;
    oboClientId?: Ref<string | undefined>;
    paymentCurrency: Ref<string | undefined>;
    /** Payment amount (in the currency defined in paymentCurrency) */
    paymentAmount: Ref<number | undefined>;
  };
}

export const aggregatedPaymentReviewRoute = {
  path: '/dashboard/payments',
  query: {
    forAggregatedReview: 'true',
  },
};

export function useAggregatedPaymentLinkCheck(router: Router) {
  return new Promise<void>((resolve, reject) => {
    if (router.currentRoute.query.forAggregatedReview !== undefined) {
      router.replace(
        editRoute(router.currentRoute, {
          query: {
            remove: ['forAggregatedReview'],
          },
        })
      );
      return resolve();
    }

    reject;
  });
}

export function useAggregatedPaymentLimitChecker(options: AggregatedPaymentLimitCheckerOptions) {
  const requestManager = options.requestManager ?? useRequestManager().manager;
  const { amendPaymentAmount, paymentAmount, paymentCurrency, clientId, oboClientId } = options.data;

  const aggregatedLimitForCurrency = ref<PaymentAggregateLimit>();

  const isAggregatedLimitReached = computed(() => {
    if (!aggregatedLimitForCurrency.value || !paymentAmount.value) return false;

    const currentAmountInGBP = paymentAmount.value * aggregatedLimitForCurrency.value.currentRate;
    let currentAggregatedAmountInGBP: number = 0;

    if (amendPaymentAmount?.value) {
      const amendPaymentAmountInGBP = aggregatedLimitForCurrency.value.currentRate * amendPaymentAmount.value;
      currentAggregatedAmountInGBP =
        aggregatedLimitForCurrency.value.aggregatedAmount - amendPaymentAmountInGBP + currentAmountInGBP;
    } else {
      currentAggregatedAmountInGBP = aggregatedLimitForCurrency.value.aggregatedAmount + currentAmountInGBP;
    }

    return currentAggregatedAmountInGBP > aggregatedLimitForCurrency.value.limitAmount;
  });

  function loadAggregatedLimit() {
    if ((!clientId?.value && !oboClientId?.value) || !paymentCurrency.value) {
      throw 'Cannot load limits with incomplete data!';
    }
    requestManager
      .sameOrCancelAndNew(
        options.loadRequestKey ?? 'getAggregateLimit',
        options.services.payments.getAggregateLimit({
          clientId: (oboClientId?.value ?? clientId?.value) as string,
          oboClientId: oboClientId?.value,
          paymentCurrency: paymentCurrency.value!,
        })
      )
      .subscribe((aggregatedLimitResponse) => {
        aggregatedLimitForCurrency.value = aggregatedLimitResponse;
      });
  }

  if (options.loadOnChanges) {
    watch(
      [clientId, paymentCurrency],
      () => {
        if ((clientId?.value || oboClientId?.value) && paymentCurrency.value) {
          loadAggregatedLimit();
        }
      },
      { immediate: true }
    );
  }

  return {
    aggregatedLimitForCurrency,
    aggregatedPaymentReviewRoute,
    isAggregatedLimitReached,
    loadAggregatedLimit,
    requestManager,
  };
}
