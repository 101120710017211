<script lang="ts" setup>
defineProps({
  text: {
    type: String,
    default: '',
  },
});

const tooltipObject = {
  html: true,
  customClass: 'info-tooltip',
  placement: 'top',
};
</script>

<template>
  <span class="info-component-wrapper">
    <IconInfoCircle class="info-icon" v-b-tooltip.hover="tooltipObject" :title="text" />
  </span>
</template>

<style lang="scss" scoped>
.info-component-wrapper {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  z-index: 1;
  position: relative;
  .info-icon {
    // little thick to align svg correctly
    margin-bottom: 4px;
    color: getColorWithOffsetLightness($color-primary, +20%);
  }
}
</style>
