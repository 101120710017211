<script setup lang="ts">
import { PaginatedQuery, IndividualGroup } from 'ah-api-gateways';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { getServices } from '@/app/services';
import { groupTableFieldsFull } from '@/app/components/groups/tableFields/groupTableFields';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { TableFieldDefinition } from 'ah-common-lib/src/models';

const props = defineProps({
  ...defineUseManagedListingProps<IndividualGroup>(),
  config: {
    type: Object as PropType<{ tableFields: TableFieldDefinition<any>[] }>,
    default: () => ({ tableFields: groupTableFieldsFull }),
  },
});

interface IndividualGroupEmits extends UseManagedListingEmits<IndividualGroup> {}

const emit = defineEmits<IndividualGroupEmits>();

const requestManager = useRequestManager();

const authStore = useAuthStore();

const services = getServices();

const groupService = computed(() => (authStore.isClientUser ? services.clientGroup : services.partnerGroup));

const groupParentId = computed(() => {
  if (authStore.isClientUser) {
    return authStore.loggedInIdentity!.client!.id;
  } else {
    // Partner group endpoints do not use id in path, when called from a partner user
    return undefined;
  }
});

const managedListing = useManagedListing<IndividualGroup>({
  loadDataRequest(query: PaginatedQuery) {
    return groupService.value.listGroups(query, groupParentId.value);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

defineExpose({ loadData: managedListing.loadData });
</script>

<template>
  <PaginatedTable
    fixed
    hover
    nowrap
    animateCols
    class="individuals-table"
    items-label="groups"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(members)="{ item }">
      <span v-if="!item.individuals || item.individuals.length === 0"> No members </span>
      <span v-else-if="item.individuals.length > 3"> {{ item.individuals.length }} members </span>
      <span v-else>
        <span v-for="(individual, index) in item.individuals" :key="individual.id">
          {{ individual.firstName }} {{ individual.lastName }}<span v-if="index < item.individuals.length - 1">,</span>
        </span>
      </span>
    </template>

    <!-- row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>
