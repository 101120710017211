/* eslint-disable no-mixed-operators, no-bitwise */
export const TEMP_UUID_PREFIX = '$temp-';

export function generateUUID() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function generateTempUUID() {
  return `${TEMP_UUID_PREFIX}${generateUUID()}`;
}

export function generateUID(len: number) {
  let d = new Date().getTime();
  const uid = 'x'.repeat(len).replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uid;
}

export function isTempUUID(uuid: string) {
  return uuid.startsWith(TEMP_UUID_PREFIX);
}
