<script setup lang="ts">
import { AuthorityType, FeatureFlag } from 'ah-api-gateways';
import SideNavTradeLink from './SideNavTradeLink.vue';
import SideNavSettingsLink from './SideNavSettingsLink.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { useFeatureFlag } from 'ah-common-stores';
import { computed, onMounted } from 'vue';

const authStore = useAuthStore();

const individualSettingsStore = useIndividualSettingsStore();

const canViewTrades = computed(() => authStore.hasAuthorities(AuthorityType.VIEW_TRADES));

const isXhedgeProClient = computed(() => individualSettingsStore.client?.enableXHedgePro);

const { isActive: isViewSubscriptionsActive } = useFeatureFlag({
  featureFlag: FeatureFlag.MVP_OPTIONS_PAYMENTS_SCHEDULE,
});

onMounted(() => {
  individualSettingsStore.loadClient();
});
</script>

<template>
  <ResponsiveNav>
    <ul class="main">
      <RouterLink tag="li" class="menu-link" to="/dashboard" exact>
        <IconDashboard /> <span class="menu-link-text"> Dashboard </span>
      </RouterLink>
      <SideNavTradeLink v-if="canViewTrades" />
      <RouterLink tag="li" class="menu-link" to="/dashboard/payments">
        <IconWallet /> <span class="menu-link-text"> Payments</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/wallets">
        <IconWallet /> <span class="menu-link-text"> Wallets</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/beneficiaries">
        <IconAgenda /> <span class="menu-link-text"> Beneficiaries </span>
      </RouterLink>
      <RouterLink
        tag="li"
        class="menu-link"
        to="/dashboard/subscriptions"
        v-if="isViewSubscriptionsActive && isXhedgeProClient"
      >
        <IconReport /> <span class="menu-link-text"> Subscriptions </span>
      </RouterLink>
    </ul>
    <template #bottom>
      <ul class="bottom">
        <SideNavSettingsLink />
      </ul>
    </template>
  </ResponsiveNav>
</template>
