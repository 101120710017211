import { BankingScheme } from './beneficiary/bankAccount';
import { BeneficiaryType } from './beneficiary/beneficiaryEnums';
import { FeePaymentType } from './feesAndCharges/fundingCharge';

export interface BeneficiaryFieldStatusData {
  id?: string;
  mandatory: boolean;
  visible: boolean;
  title: string;
}

export interface LocalizedFieldData extends BeneficiaryFieldStatusData {
  cc: string;
  fieldName: string;
  options?: { label: string; value: string }[];
}

export interface RoutingCodeData {
  currency: string;
  routingCodeType: string;
  title: string;
}

export interface PurposeCodeData {
  purposeCode: string;
  purposeDescription: string;
}

export interface PurposeCodeResponse {
  purposeCodes: PurposeCodeData[];
  mandatory: boolean;
}

export interface LocalizedBeneficiaryFieldData extends LocalizedFieldData {
  beneficiaryType: BeneficiaryType;
}

export interface PaymentRailData {
  currency: string;
  bankingScheme: BankingScheme;
  type: FeePaymentType;
  paymentRail: string;
  label: string;
}

export interface PaymentLimitData {
  hasLimit: boolean;
  amountLimit: number;
}

export enum AccountNumberType {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  IBAN = 'IBAN',
}
