<script lang="ts" setup>
import { BankingScheme, FeePaymentType, FundingCharge, FeeChargeType, Beneficiary } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = withDefaults(
  defineProps<{
    /*
     *  Payment Currency
     *
     *  The currency in which the current payment is being made
     */
    paymentCurrency: string;
    /*
     *  Banking scheme
     *
     *  The current beneficiary banking scheme
     */
    bankingScheme: BankingScheme;
    feePaymentType: FeePaymentType;
    /*
     *  Fee Charge Type
     *
     *  If banking scheme is SWIFT, charge type must be defined or SHA will be assumed
     */
    chargeType?: FeeChargeType;
    /*
     *  Selected Beneficiary
     *
     *  Target beneficiary for current payment.
     *  If charged type is ACO, beneficiary must be defined
     */
    beneficiary?: Beneficiary | null;
    indexOfDetail?: number;
    /*
     *  Whether to display the text with a small font size
     */
    small?: string | boolean;
  }>(),
  {
    chargeType: FeeChargeType.SHA,
    beneficiary: null,
    indexOfDetail: 0,
    small: false,
  }
);

const requestManager = useRequestManager({ exposeToParent: false });

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const charge = ref<FundingCharge[] | null>(null);

const isClientUser = computed(() => tradeState.store.useAuthStore().isClientUser || onBehalfOfClient.value);

const clientId = computed(
  () => onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id ?? ''
);

const label = computed(() => (props.feePaymentType === FeePaymentType.RECEIPT ? 'Funding Fees' : 'Payment Fees'));

const isAllCurrencies = computed(() => {
  // if banking scheme is local allCurrencies will always be false
  // otherwise, we must check the chargeType:
  // SHA - allCurrencies is enabled
  // ACO - allCurrencies should be false/undefined
  if (props.bankingScheme === BankingScheme.SWIFT) {
    if (props.chargeType !== FeeChargeType.ACO) {
      return true;
    }
  }
  return false;
});

const countryCode = computed(() => {
  if (props.bankingScheme === BankingScheme.SWIFT && props.chargeType === FeeChargeType.ACO) {
    if (!props.beneficiary) {
      throw 'The beneficiary must be defined in ACO charges!';
    }
    return props.beneficiary!.bankCountry;
  }

  return undefined;
});

const fundingFeeRequest = computed(() => {
  const params = {
    bankingScheme: props.bankingScheme,
    type: props.feePaymentType,
    allCurrencies: isAllCurrencies.value,
    paymentCurrency: props.bankingScheme === BankingScheme.SWIFT ? undefined : props.paymentCurrency,
    countryCode: countryCode.value,
  };

  if (isClientUser.value) {
    return tradeState.services.fees.listClientFees({ ...params, clientId: clientId.value });
  }
  return tradeState.services.fees.listAHFees({
    ...params,
  });
});

function loadFee() {
  requestManager.manager.cancelAndNew('loadFee', fundingFeeRequest.value).subscribe((response) => {
    charge.value = response ?? null;
  });
}

watch(
  () => [props.bankingScheme, props.chargeType],
  () => {
    loadFee();
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.label-text {
  white-space: nowrap;
  @include upToResolution($tabletResolution) {
    white-space: normal;
  }
}
.small {
  ::v-deep {
    .label-col {
      font-size: $font-size-sm;
      font-weight: $font-weight-semibold;
    }
    .value-col {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
    }
  }
}
</style>

<template>
  <div :class="{ small: small !== false }" v-if="charge && charge.length > 0 && bankingScheme">
    <DataRow
      cols="12"
      md="4"
      sm="3"
      :label="label"
      class="mb-2 label-text currency-charge"
      :class="{ 'text-small': charge[indexOfDetail].paymentDate }"
      v-bind="$attrs"
    >
      {{ charge[indexOfDetail].feeCurrency }}
      <LoadingIcon class="ml-3" v-if="requestManager.manager.anyPending" />
      <span v-else> {{ formatCurrencyValue(charge[indexOfDetail].fee) }}</span>
    </DataRow>
  </div>
  <LoadingIcon v-else />
</template>
