<script lang="ts">
import { ListResponse, ListSortParams } from 'ah-api-gateways';
import { RequestState } from 'ah-requests';
import { EditTableMode, TableFieldDefinition } from 'ah-common-lib/src/models';
import Vue, { PropType, watch, onBeforeUnmount, PropOptions } from 'vue';

export const commonTableProps = {
  dataLoadState: {
    type: String as PropType<RequestState>,
    default: 'idle',
  },
  itemsLabel: {
    type: String,
    default: 'items',
  },
  primaryKey: {
    type: String,
    default: 'id',
  },
  customSearch: {
    type: [Boolean, String],
    default: false,
  },
  selectedItems: {
    type: Array, // as PropType<any[]>,
    default: () => [],
  },
  editMode: {
    type: String as PropType<EditTableMode>,
    default: EditTableMode.OFF,
  },
} satisfies Record<string, PropOptions>;

export const dynamicTableProps = {
  data: {
    type: Object as PropType<ListResponse<{ [key: string]: any }> | null>,
    default: null,
  },
  withRowDetails: {
    type: [Boolean, String, Function], // as PropType<boolean | string | ((item: any) => boolean)>,
    default: false,
  },
  /**
   * Whether to animate column transitions
   */
  animateCols: {
    type: [Boolean, String],
    default: false,
  },
  bordered: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to use flex scroll
   *
   * Flex scroll requires setting 'flex-basis' values for every column
   * Please refer to PrimeVue documentation
   */
  withFlexScroll: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to use 'simple' scroll - overriden by `withFlexScroll`
   *
   * In this mode, fixed columns are not possible (will simply add a overflow: auto to the wrapping element)
   */
  withSimpleScroll: {
    type: [Boolean, String],
    default: false,
  },
  rowClass: {
    type: [String, Function],
    required: false,
  },
  ...commonTableProps,
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
import { BOverlay } from 'bootstrap-vue';
import VButton from '../VButton.vue';
import NotFoundIcon from '../../../icons/components/NotFoundIcon.vue';
import LoadingIcon from '../../../icons/components/LoadingIcon.vue';
import { cloneDeep, debounce, DebouncedFunc, isEqual } from 'lodash';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { ref, onMounted, computed, getCurrentInstance } from 'vue';
import { useInjectedTableAggregator } from './ListAggregator.vue';

/**
 * Dymanic table implementation
 *
 * Wraps PrimeVue DataTable component - refer to https://www.primefaces.org/primevue-v2/#/datatable
 *
 * Emits extra events:
 * - row-toggle-all (no payload) triggered to signal the selectbox in the header has been clicked
 * - row-clicked (payload: row) Maps from Primevue event row-click
 */

const emit = defineEmits({
  'row-toggle-all': () => true,
  sort: (_model: ListSortParams) => true,
  'row-clicked': (_model: any) => true,
  'update:expandedRows': (_model: any[]) => true,
  'row-details-toggled': (_model: { item: any; shown: boolean }) => true,
});

const props = defineProps({
  expandedRows: {
    type: Array,
    default: () => [],
  },
  nowrap: {
    type: [Boolean, String],
    default: false,
  },
  showEmptyHeaders: {
    type: [Boolean, String],
    default: false,
  },
  fields: {
    type: Array as PropType<TableFieldDefinition[]>,
    required: true,
  },
  singleRowDetails: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether clicking the whole row should toggle details
   * Only relevant if withRowDetails is truthy
   */
  showDetailsOnRowClick: {
    type: [Boolean, String],
    default: true,
  },
  /**
   * Whether to show a scrollbar at the top, sinchronized with the bottom bar
   *
   * Only available if `withFlexScroll` is truthy
   */
  withTopScrollbar: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Time taken in ms for the animation to trigger
   */
  animationTimeout: {
    type: Number,
    default: 650,
  },
  /**
   * What to show on empty cells. Defaults to empty string
   */
  emptyCellValue: {
    type: String,
    default: '',
  },
  /**
   * Whether to force table size re-rendering on window size change
   */
  forceTableSizeRerendering: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to display diferent color on odd rows
   */
  striped: {
    type: [Boolean, String],
    default: true,
  },
  ...dynamicTableProps,
});

enum ActionTypes {
  ADD = 'add',
  KEEP = 'keep',
  REMOVE = 'remove',
}

const horizontalScrollerInner = ref<InstanceType<typeof HTMLElement>>();

const horizontalScroller = ref<InstanceType<typeof HTMLElement>>();

const dataTable = ref<InstanceType<typeof Vue>>();

const sortDesc = ref<boolean>(false);

const sortBy = ref<string>('');

const scrollTimeout = ref<number | null>(null);

const tableResizeObserver = ref<ResizeObserver | null>(null);

const dataTableRenderingIndex = ref<number>(1);

const rendering = ref<boolean>(false);

const debounceTableSizeRender = ref<DebouncedFunc<() => void>>();

const tableAggregator = useInjectedTableAggregator();

const currentInstance = getCurrentInstance()?.proxy;

const isEditing = computed(() => props.editMode === EditTableMode.ON || props.editMode === EditTableMode.ONLY_EDITED);

const editingRows = computed(() => (isEditing.value ? props.data?.list : []));

onMounted(() => {
  tableAggregator?.registerList(currentInstance);
  setTableSizeObserver();
  currentFields.value = props.fields;
  debounceTableSizeRender.value = debounce(() => {
    if (props.forceTableSizeRerendering !== false) {
      dataTableRenderingIndex.value++;
    }
    onTableSizeChange();
    rendering.value = false;
  }, 50);
});

function isSelected(item: any) {
  return props.selectedItems.includes(item[props.primaryKey]);
}

function allSelected() {
  return !props.data?.list.find((i) => !isSelected(i));
}

function anySelected() {
  return props.data?.list.find((i) => isSelected(i));
}

function makeCellData(slotProps: any, field: TableFieldDefinition) {
  return {
    ...slotProps,
    fieldData: field,
    field: field.key,
    sortBy: sortBy.value,
    sortDesc: sortDesc.value,
  };
}

function makeThCellData(slotProps: any, field: TableFieldDefinition) {
  return {
    ...makeCellData(slotProps, field),
    sort: () => toggleSort(field),
  };
}

function makeTdCellData(slotProps: any, field: TableFieldDefinition) {
  return {
    ...makeCellData(slotProps, field),
    item: slotProps.data,
    isSelected: isSelected(slotProps.data),
    value: slotProps.data[field.key],
    hasDetails: props.expandedRows.indexOf(slotProps.data[props.primaryKey]) >= 0,
    toggleDetails: (value?: boolean) => toggleRowDetails(slotProps.data, value),
  };
}

function makeExpansionRowData(slotProps: any) {
  return {
    ...slotProps,
    item: slotProps.data,
    hasDetails: props.expandedRows.indexOf(slotProps.data[props.primaryKey]) >= 0,
    toggleDetails: (value?: boolean) => toggleRowDetails(slotProps.data, value),
  };
}

function setTableSizeObserver() {
  const tableElem = document.getElementById('ah-dynamic-table')!;
  tableResizeObserver.value = new ResizeObserver(() => {
    if (!rendering.value) {
      rendering.value = true;
      debounceTableSizeRender.value!();
    }
  });
  tableResizeObserver.value.observe(tableElem);
}

const hasData = computed(() => !!props.data?.list?.length);

const isFlexScroll = computed(() => props.withFlexScroll !== false);

const isSimpleScroll = computed(() => props.withFlexScroll === false && props.withSimpleScroll !== false);

const isWithTopScrollbar = computed(() => isFlexScroll.value && props.withTopScrollbar !== false);

function onTableSizeChange() {
  if (isWithTopScrollbar.value) {
    const table = getTableElement();
    const scroll: HTMLElement | undefined = horizontalScrollerInner.value as HTMLElement;
    if (table && scroll) {
      // setting scroll slightly lower that scrollWidth to not trigger overflow
      scroll.style.width = `${table.scrollWidth - 2}px`;
      calculateDetailsSize();
    } else {
      window.setTimeout(onTableSizeChange, 50);
    }
  }
}

function calculateDetailsSize() {
  const detailsElement = document.getElementsByClassName('p-datatable-row-expansion');
  const thElement = document.getElementsByTagName('tr')[0];
  for (const item of detailsElement) {
    const tdList = item.getElementsByTagName('td');
    if (tdList.length === 1) {
      const node = document.createElement('td');
      node.style.minWidth = `${thElement.scrollWidth - thElement.clientWidth}px`;
      item.prepend(node);
    } else {
      tdList[0].style.minWidth = `${thElement.scrollWidth - thElement.clientWidth}px`;
    }
  }
}

function toggleSort(field: TableFieldDefinition) {
  const sortByAux = field.sortKey || field.key;
  sortDesc.value = sortByAux === sortBy.value ? !sortDesc.value : false;
  sortBy.value = sortByAux;
  emitSort();
}

/**
 * Animation methods and data
 */

const currentFields = ref<any[]>([]);

const transitionFields = ref<any[] | null>(null);

const fieldChangeTimeout = ref<number | null>(null);

const renderedFields = computed(() => (transitionFields.value || currentFields.value).filter((f) => !f.hidden));

function onFieldsChange(nextFields: any[], prevFields: any[]) {
  if (isEqual(nextFields, prevFields)) {
    return;
  }
  if (fieldChangeTimeout.value) {
    clearTimeout(fieldChangeTimeout.value);
    fieldChangeTimeout.value = 0;
  }

  if (props.animateCols !== false) {
    currentFields.value = [...prevFields];
    const fields = calculateTransition();
    transitionFields.value = fields.transitionFields;
    Vue.nextTick(() => {
      animateTransition(fields.fieldActions);

      fieldChangeTimeout.value = window.setTimeout(() => {
        currentFields.value = props.fields;
        transitionFields.value = null;
      }, props.animationTimeout);
    });
    return true;
  } else {
    currentFields.value = nextFields;
    transitionFields.value = null;
  }
}

watch(() => props.fields, onFieldsChange);

function calculateTransition() {
  const transitionFields = cloneDeep(currentFields.value);
  const fieldActions: ActionTypes[] = [];

  let fieldIndex = -1;
  currentFields.value.forEach((field) => {
    const found = props.fields.findIndex((f) => f.key === field.key);
    if (found < fieldIndex) {
      fieldActions.push(ActionTypes.REMOVE);
    } else {
      fieldActions.push(ActionTypes.KEEP);
      fieldIndex = found;
    }
  });

  fieldIndex = 0;
  props.fields.forEach((field) => {
    const found = transitionFields.findIndex(
      (f, index) => f.key === field.key && fieldActions[index] === ActionTypes.KEEP
    );
    if (found >= fieldIndex) {
      transitionFields[found] = cloneDeep(field);
      fieldIndex = found + 1;
    } else {
      transitionFields.splice(fieldIndex, 0, cloneDeep(field));
      fieldActions.splice(fieldIndex, 0, ActionTypes.ADD);
      fieldIndex++;
    }
  });
  return {
    transitionFields,
    fieldActions,
  };
}

/**
 * Animates extension/collapse of columns, by overwriting CSS values
 *
 * NOTE: This only works with knowledge of the current CSS styles (padding-right is reduced to 0 as tables have it)
 */
function animateTransition(fieldActions: ActionTypes[]) {
  if (!transitionFields.value) {
    return;
  }

  const fields = transitionFields.value!;
  const defaultCellWidth = `${100 / props.fields.length}%`;

  fieldActions.forEach((action, index) => {
    const field = fields[index];
    const colWidth = field.colStyle?.width || defaultCellWidth;
    const currentField = props.fields.find((currField) => currField.key === field.key);
    if (action === ActionTypes.ADD) {
      Vue.set(field, 'colStyle', { ...field.colStyle, width: '0', transition: '', 'padding-right': '0' });
      setTimeout(() => {
        const styleObj = {
          ...field.colStyle,
          width: colWidth,
          transition: `width ${props.animationTimeout}ms, padding ${props.animationTimeout}ms`,
        };
        delete styleObj['padding-right'];
        Vue.set(field, 'colStyle', styleObj);
      });
    } else if (action === ActionTypes.REMOVE) {
      Vue.set(field, 'colStyle', { ...field.colStyle, width: colWidth, transition: '' });
      setTimeout(() => {
        Vue.set(field, 'colStyle', {
          ...field.colStyle,
          width: '0',
          'padding-right': '0',
          transition: `width ${props.animationTimeout}ms, padding ${props.animationTimeout}ms`,
        });
      });
    } else if (action === ActionTypes.KEEP && field.colStyle?.width && currentField?.colStyle?.width) {
      setTimeout(() => {
        Vue.set(field, 'colStyle', {
          ...currentField.colStyle,
          transition: `width ${props.animationTimeout}ms, padding ${props.animationTimeout}ms`,
        });
      });
    }
  });
}

/**
 * Gets inner table element, if it exists
 */
function getTableElement(): HTMLElement | undefined {
  const table: Vue | undefined = dataTable.value as Vue;
  return (table?.$el as HTMLElement | undefined)?.firstElementChild as HTMLElement;
}

function scrollTo(left: number) {
  if (scrollTimeout.value) {
    clearTimeout(scrollTimeout.value);
  }
  scrollTimeout.value = window.setTimeout(() => {
    const table: HTMLElement | undefined = getTableElement();
    const scroll: HTMLElement | undefined = horizontalScroller.value as HTMLElement;
    if (table) {
      table.scrollLeft = left;
      if (scroll) {
        scroll.scrollLeft = left;
      }

      if (table.scrollLeft !== left) {
        const clearTimeoutRef = () => {
          window.setTimeout(() => {
            scrollTimeout.value = null;
          });
          table.removeEventListener('scroll', clearTimeoutRef);
        };
        table.addEventListener('scroll', clearTimeoutRef);
      } else {
        window.setTimeout(() => {
          scrollTimeout.value = null;
        });
      }
    } else {
      scrollTimeout.value = null;
    }
  });
}

function onScroll(event: Event) {
  if ((event as any).srcElement?.scrollLeft) {
    scrollTo((event as any).srcElement?.scrollLeft);
  } else {
    event.stopImmediatePropagation();
  }
}

function onDataChange() {
  if (props.data) {
    const data = (props.data ?? {}) as any;
    sortBy.value = data.sort;
    sortDesc.value = data.sortDirection === 'DESC';
  }
}

watch(() => props.data, onDataChange);

function emitSort() {
  emit('sort', {
    sort: sortBy.value,
    sortDirection: sortDesc.value ? 'DESC' : 'ASC',
  });
}

function propagateClick(event: MouseEvent) {
  ((event.target as HTMLInputElement).parentElement as HTMLDivElement).click();
}

onBeforeUnmount(() => {
  tableAggregator?.deregisterList(currentInstance);
  tableResizeObserver.value?.disconnect();
});

function hasRowDetails(item: any) {
  return typeof props.withRowDetails === 'function' ? props.withRowDetails(item) : props.withRowDetails !== false;
}

function onRowClicked(item: any) {
  emit('row-clicked', item);
  if (hasRowDetails(item) && props.showDetailsOnRowClick !== false) {
    toggleRowDetails(item);
  }
}

const expandedRowItems = computed(() =>
  props.data?.list?.filter((i: any) => i[props.primaryKey] && props.expandedRows.includes(i[props.primaryKey]))
);

function toggleRowDetails(item: any, value?: boolean) {
  let rows = [...props.expandedRows];
  const key = item[props.primaryKey];
  const index = rows.indexOf(key);
  const itemExists = index >= 0;
  const detailsShown = value ?? !itemExists;
  if (props.singleRowDetails !== false) {
    rows = [];
  }
  if (detailsShown && !itemExists) {
    rows.push(key);
  }
  if (!detailsShown && itemExists) {
    rows.splice(index, 1);
  }
  emit('update:expandedRows', rows);
  emit('row-details-toggled', { item, shown: detailsShown });
}

defineExpose({ scrollTo: scrollTo, toggleRowDetails: toggleRowDetails });
</script>

<template>
  <div
    :class="[
      'ah-dynamic-table',
      {
        loading: dataLoadState === 'pending',
        empty: !hasData,
        'loading-no-items': dataLoadState === 'pending' && showEmptyHeaders !== false && hasData,
      },
    ]"
    id="ah-dynamic-table"
    v-on="$listeners"
  >
    <template v-if="showEmptyHeaders !== false || hasData">
      <div ref="horizontalScroller" class="horizontal-scroller" v-if="isWithTopScrollbar" @scroll="onScroll">
        <div ref="horizontalScrollerInner" class="horizontal-scroller-inner" />
      </div>
      <div class="table-wrapper dynamic-table-wrapper" :class="{ scrollable: isFlexScroll }" @scroll.capture="onScroll">
        <BOverlay
          v-if="hasData && dataLoadState === 'pending'"
          :show="true"
          rounded="sm"
          class="table-overlay"
          no-wrap
          variant="box"
        >
          <template v-slot:overlay>
            <slot name="tableLoadState">
              <div class="loading-card card">
                <LoadingIcon class="loading-icon" />
                <div class="loading-text">
                  <p class="f-semibold mb-xs">Loading {{ itemsLabel }}...</p>
                </div>
              </div>
            </slot>
          </template>
        </BOverlay>
        <DataTable
          ref="dataTable"
          :key="dataTableRenderingIndex"
          :scrollable="isFlexScroll"
          :value="(data || {}).list || []"
          v-bind="$attrs"
          lazy
          :sortField="sortBy"
          :sortOrder="sortDesc ? -1 : 1"
          @row-click="onRowClicked($event.data)"
          :expandedRows="expandedRowItems"
          :stripedRows="striped !== false"
          :tableClass="`b-table ${nowrap !== false ? 'nowrap' : ''} ${isFlexScroll ? 'scrollable' : ''} ${
            bordered !== false ? 'table-bordered' : ''
          }`"
          :class="`${isSimpleScroll ? 'scrollable-simple' : ''}`"
          :rowClass="rowClass"
          editMode="row"
          :editingRows.sync="editingRows"
        >
          <!-- FIXME - using `index` as the key for Columns to prevent reorder issue with dynamic fields. This should be revisited once a move to Vue 3 / PrimeVue 3 is done -->
          <Column
            v-for="(field, index) in renderedFields"
            :key="field.key + index"
            :field="field.key"
            :sortable="false"
            :styles="field.colStyle"
            :header="field.header"
            :bodyClass="field.tdClass"
            :className="field.class"
            :frozen="field.frozen"
            :alignFrozen="field.alignFrozen"
            :headerClass="
              (field.thClass || '') + ($scopedSlots[`head(${field.key})`] || $scopedSlots[`head()`] ? ' with-slot' : '')
            "
          >
            <template #header="slotProps">
              <slot :name="`head(${field.key})`" v-bind="makeThCellData(slotProps, field)">
                <div class="checkbox-holder custom-control custom-checkbox" v-if="field.key === 'selectCheckbox'">
                  <input
                    :id="`tags-filter-select-selectCheckbox`"
                    :checked="allSelected()"
                    :indeterminate.prop="anySelected() && !allSelected()"
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label
                    :for="`tags-filter-select-selectCheckbox`"
                    class="custom-control-label"
                    @click.prevent.stop="$emit('row-toggle-all')"
                  >
                  </label>
                </div>
                <slot v-else name="head()" v-bind="makeThCellData(slotProps, field)" />
              </slot>
            </template>
            <template #editor="slotProps">
              <slot :name="`editor(${field.key})`" v-bind="makeTdCellData(slotProps, field)">
                <slot :name="`cell(${field.key})`" v-bind="makeTdCellData(slotProps, field)">
                  <span>{{ slotProps.data[field.key] }}</span>
                </slot>
              </slot>
            </template>
            <template #body="slotProps">
              <slot :name="`cell(${field.key})`" v-bind="makeTdCellData(slotProps, field)">
                <div class="checkbox-holder custom-control custom-checkbox" v-if="field.key === 'selectCheckbox'">
                  <input
                    :id="`tags-filter-select-${slotProps.data.id}`"
                    :checked="isSelected(slotProps.data)"
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label
                    :for="`tags-filter-select-${slotProps.data.id}`"
                    class="custom-control-label"
                    @click.prevent.stop="propagateClick"
                  >
                  </label>
                </div>
                <div class="custom-control custom-radio" v-else-if="field.key === 'selectRadio'">
                  <input
                    :id="`${{ itemsLabel }}-${slotProps.data.id}`"
                    :checked="isSelected(slotProps.data)"
                    type="radio"
                    name="selected-item"
                    class="custom-control-input"
                    @click.stop="propagateClick"
                  />
                  <label :for="`selected-item-${slotProps.data.id}`" class="custom-control-label" />
                </div>
                <div class="empty-cell" v-else-if="!slotProps.data[field.key] && emptyCellValue">
                  {{ emptyCellValue }}
                </div>
                <span v-else>{{ slotProps.data[field.key] }}</span>
              </slot>
            </template>
          </Column>
          <template #expansion="slotProps">
            <slot name="row-details" v-bind="makeExpansionRowData(slotProps)" />
          </template>
        </DataTable>
      </div>
      <slot name="show-totals" />
      <slot name="after-table" />
    </template>
    <BOverlay
      v-if="!hasData && dataLoadState === 'pending'"
      :show="dataLoadState === 'pending'"
      rounded="sm"
      class="table-overlay"
      variant="box"
    >
      <div class="table-list-standin"></div>
      <template v-slot:overlay>
        <slot name="tableLoadState">
          <div class="loading-card card">
            <LoadingIcon class="loading-icon" />
            <div class="loading-text">
              <p class="f-semibold mb-xs">Loading {{ itemsLabel }}...</p>
            </div>
          </div>
        </slot>
      </template>
    </BOverlay>
    <template v-if="!hasData && dataLoadState !== 'pending'">
      <div class="table-list-standin" v-if="dataLoadState === 'error'">
        <slot name="tableErrorState">
          <h2>Error loading {{ itemsLabel }}</h2>
          <VButton @click="emitSort()">Reload</VButton>
        </slot>
      </div>
      <div class="table-list-standin" v-else-if="customSearch !== false">
        <slot name="tableNoSearchResultsState">
          <NotFoundIcon class="not-found" />
          <h2>No {{ itemsLabel }} found for the filters applied</h2>
        </slot>
      </div>
      <div class="table-list-standin" v-else>
        <slot name="tableNoResultsState">
          <NotFoundIcon class="not-found" />
          <h2>No {{ itemsLabel }} found.</h2>
        </slot>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.table-list-standin {
  min-height: 200px;
}

.ah-dynamic-table {
  position: relative;

  th.with-slot .p-column-title {
    display: none;
  }

  th.text-right .p-column-header-content {
    text-align: right;
  }

  th.text-left .p-column-header-content {
    text-align: left;
  }

  th.text-center .p-column-header-content {
    text-align: left;
  }

  .ah-dynamic-table-body {
    // css hack to allow loading state on body only
    transform: scale(1);
  }

  .empty-cell {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }

  .horizontal-scroller {
    overflow-x: auto;
    scrollbar-width: thin;
    .horizontal-scroller-inner {
      height: 0.1rem;
      width: 0;
    }
  }

  .checkbox-holder {
    margin-left: 0;
    margin-right: auto;
  }

  table.scrollable {
    & > tbody > tr > td,
    & > thead > tr > th {
      display: unset;
      flex: 1 0 0;
      align-items: center;
      height: auto;
    }

    td.p-frozen-column {
      box-shadow: 0px 10px 10px 0px $common-color-lightGreyShadow;
    }

    .p-datatable-row-expansion td:last-child {
      &::before,
      &::after {
        content: ' ';
        position: absolute;
        right: 0;
        left: 0;
        height: 10px;
      }
      &::before {
        top: -10px;
        box-shadow: 0px 3px 10px 0px $common-color-lightGreyShadow;
      }
      &::after {
        bottom: -10px;
        box-shadow: 0px -3px 10px 0px $common-color-lightGreyShadow;
      }
    }
  }

  .scrollable-simple .p-datatable-wrapper {
    &,
    table {
      overflow: auto;
      width: unset;
    }
  }

  .table-wrapper {
    &.scrollable > .table-responsive {
      overflow-x: auto;
    }

    .table.table-bordered .b-table-bottom-row {
      border-bottom: $table-border-width solid $table-border-color;
      height: $table-border-width;
    }
  }

  .table-overlay {
    position: absolute;
    top: 1px !important;

    .loading-card {
      text-align: center;
      padding: math.div($padded-space, 2);

      .loading-icon {
        padding: 0;
        width: 3em;
        height: 1.8em;
        display: block;
        margin: auto;

        circle {
          fill: black;
        }
      }
    }
  }

  .table-responsive {
    scrollbar-width: thin;
  }

  &.loading-no-items {
    min-height: 11em;

    .table {
      min-height: 8em;
    }

    .table-overlay {
      bottom: -30px !important;
    }
  }
}
</style>
