<script lang="ts" setup>
import {
  Payment,
  BankingScheme,
  Beneficiary,
  PaymentType,
  PaymentReport,
  FeeChargeType,
  paymentTypeLabels,
  FeePaymentType,
  Client,
  Trade,
  GenericErrorCodes,
  PaginatedParams,
  Wallet,
  PaymentErrorCodes,
  getBuyCcy,
} from 'ah-api-gateways';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { scrollToValidation } from 'ah-common-lib/src/form/helpers';
import { FormValidation, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { computed, reactive, watch, onBeforeMount, ref, inject } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { paymentCurrencyForm, paymentPurposeForm } from '../formSchemas/paymentFormSchemas';
import { getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useAhPaymentState } from '..';
import { FieldOption } from 'ah-common-lib/src/form/interfaces';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import TradeBeneficiaryInfo from 'ah-trades/src/components/info/TradeBeneficiaryInfo.vue';
import CurrencyFundingFee from 'ah-trades/src/components/fees/CurrencyFundingFee.vue';
import PaymentReferenceForm from './PaymentReferenceForm.vue';
import PaymentReasonForm from './PaymentReasonForm.vue';
import BeneficiaryAccountSelector from 'ah-beneficiaries/src/components/BeneficiaryAccountSelector.vue';
import SchedulePaymentForm from './SchedulePaymentForm.vue';
import ChargeTypeForm from './ChargeTypeForm.vue';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from 'ah-common-lib/src/onBehalfOf/constants';
import PaymentEditorCancelModal from './modals/PaymentEditorCancelModal.vue';
import AggregatedLimitErrorMessage from './AggregatedLimitErrorMessage.vue';
import { useRouter } from 'vue-router/composables';
import { useAggregatedPaymentLimitChecker } from '../../../ah-trades/src/composables/aggregatedPaymentLimitChecker';

const emit = defineEmits<{
  /**
   * - update:validations: emmited when `validations` changes. Can be used with .sync
   */
  (e: 'update:validations', value: FormValidation[]): void;
  /**
   * update-payment: emmited when a payment is saved/created. Can be used with .sync
   */
  (e: 'update-payment', value: Payment): void;

  (e: 'cancel'): void;

  (e: 'update-title', value: string): void;
}>();

const props = defineProps<{
  /**
   * Payment to update, if any
   *
   * If unset, a new payment will be created
   * Can be updated via .sync
   */
  payment?: PaymentReport;
}>();

const requestManager = useRequestManager({
  exposeToParent: true,
}).manager;

const errorMessages: { [key: string]: string } = {
  [PaymentErrorCodes.PAYMENT_LINKED_TO_OTHER_TRADE]: 'Trade is already linked to another Payment',
  [PaymentErrorCodes.MAX_PAYMENTS_ON_TRADES]: 'The maximum number of payments allowed on a trade is 10',
  [PaymentErrorCodes.PAYMENT_EXCEEDS_TRADE_AMOUNT]: 'The payment amount exceeds the remaining trade amount',
  [PaymentErrorCodes.AGGREGATED_LIMIT_REACHED]:
    'Your current total scheduled payment amount is over the limit. Please review your payment again',
  formError: 'Form contains errors, please review values entered.',
  generic: 'An unexpected problem has occurred. Please try again later.',
};

const paymentState = useAhPaymentState();

const onBehalfOfClient = useOnBehalfOf();

const router = useRouter();

const authStore = paymentState.store.useAuthStore();

const chargeTypeForm = ref<InstanceType<typeof ChargeTypeForm> | null>(null);

const paymentReferenceForm = ref<InstanceType<typeof PaymentReferenceForm> | null>(null);

const paymentReasonForm = ref<InstanceType<typeof PaymentReasonForm> | null>(null);

const selectedBeneficiary = ref<Beneficiary | null>(null);

const currentPurposeCodesOptions = ref<{ value: string; label: string }[]>([]);

const isPaymentPurposeRequired = ref(false);

const selectedPurposeCodeDescription = computed(
  () =>
    currentPurposeCodesOptions.value.find((option) => option.value === paymentPurposeDef.form.paymentPurpose)?.label ??
    paymentPurposeDef.form.paymentPurpose
);

const state = reactive<{
  search: string;
  pageAndSortParams: Partial<PaginatedParams>;
  onBehalfOfClient: Client | null;
  selfBeneficiaries: Beneficiary[] | null;
  paymentType: PaymentType;
  paymentReason: string | null;
  paymentReference: string;
  selectedCurrency: string;
  executionDate: Date | null;
  chargeType?: FeeChargeType | null;
  wallets: Wallet[] | null;
  confirmPayment: boolean;
  trade: Trade | null;
  tradePaymentTotalSum: number;
}>({
  search: '',
  pageAndSortParams: { sort: 'createdAt', sortDirection: 'DESC' },
  onBehalfOfClient: inject(ON_BEHALF_OF_CLIENT_INJECT_KEY, null),
  selfBeneficiaries: null,
  paymentType: PaymentType.IMMEDIATE,
  paymentReason: '',
  paymentReference: '',
  selectedCurrency: '',
  executionDate: null,
  chargeType: null,
  wallets: null,
  confirmPayment: false,
  trade: null,
  tradePaymentTotalSum: 0,
});

const paymentCurrencyDef = reactive<FormDefinition>({
  form: makeFormModel(paymentCurrencyForm()),
  validation: null,
});

const paymentPurposeDef = reactive<FormDefinition>({
  form: makeFormModel(paymentPurposeForm()),
  validation: null,
});

const useChargeType = computed(() => selectedBeneficiary.value?.bankingScheme === BankingScheme.SWIFT);

const bankingScheme = computed(() => selectedBeneficiary.value?.bankingScheme);

const formsInvalid = computed(() => {
  return validations.value.find((f) => !f || f.$invalid);
});

const formsLoaded = computed(() => {
  return validations.value.filter((f) => !f).length === 0;
});

const isSaving = computed(() => {
  return requestManager.requestStates.savePayment === 'pending';
});

const isClient = computed(() => paymentState.store.useAuthStore().isClientUser);

const ownerId = computed(() => {
  if (isClient.value) {
    return paymentState.store.useAuthStore().loggedInIdentity?.client?.id;
  }
  if (!isClient.value) {
    return paymentState.store.useAuthStore().loggedInIdentity?.partner?.id;
  }
  return null;
});

const isPartnerUser = computed(() => !onBehalfOfClient.value && !paymentState.store.useAuthStore().isClientUser);

const validations = computed(() => {
  return [...(props.payment ? [] : [paymentCurrencyDef.validation!])] as FormValidation[];
});

const selectedWallet = computed(() => {
  return state.wallets?.filter((wallet) => {
    return wallet.id === paymentCurrencyDef.form.currency;
  })[0];
});

const clientId = computed(
  () => onBehalfOfClient.value?.id ?? paymentState.store.useAuthStore().loggedInIdentity?.client?.id
);

const { aggregatedLimitForCurrency, aggregatedPaymentReviewRoute, isAggregatedLimitReached, loadAggregatedLimit } =
  useAggregatedPaymentLimitChecker({
    requestManager: requestManager,
    services: paymentState.services,
    data: {
      clientId,
      oboClientId: computed(() => onBehalfOfClient.value?.id),
      paymentCurrency: computed(() => props.payment?.currency ?? selectedWallet?.value?.currency),
      paymentAmount: computed(() => paymentCurrencyDef.form.amount),
    },
  });

const isScheduleOrLinkToTradePaymentType = computed(
  () => state.paymentType === PaymentType.SCHEDULED || state.paymentType === PaymentType.TRADE_SEND_TO_BENEFICIARY
);

const showAggregatedLimitErrorMessage = computed(
  () => isScheduleOrLinkToTradePaymentType.value && isAggregatedLimitReached.value
);

const continueDisabled = computed(() => {
  return (
    !formsLoaded.value ||
    formsInvalid.value ||
    isCurrencyAmountInvalid.value ||
    !selectedBeneficiary.value ||
    (state.paymentType === PaymentType.TRADE_SEND_TO_BENEFICIARY && !state.trade?.id) ||
    (isPaymentPurposeRequired.value && !paymentPurposeDef.form.paymentPurpose) ||
    !state.paymentReason ||
    requestManager.anyPending ||
    (isAggregatedLimitReached.value && isScheduleOrLinkToTradePaymentType.value)
  );
});

const tradeAvailableBalance = computed(() => {
  let amount = 0;
  if (state.trade) {
    amount = getBuyCcy(state.trade).remainingClientAmount - state.tradePaymentTotalSum;

    if (props.payment) {
      amount += props.payment.amount;
    }
  }
  return amount;
});

const tradeDisplayBalance = computed(() => {
  let amount = 0;
  if (state.trade) {
    amount = tradeAvailableBalance.value - paymentCurrencyDef.form.amount;
  }
  return formatCurrencyValue(amount);
});

const isCurrencyAmountInvalid = computed(() => paymentCurrencyDef.validation?.$invalid === true);

function confirmPayment() {
  emit('update-title', 'Review and Execute');
  state.confirmPayment = true;
}

function savePayment() {
  submitForms();

  if (formsInvalid.value) {
    scrollToValidation(validations.value.find((f) => !f || f.$invalid)!);
    return;
  }

  const model: Payment = {
    ...formToPaymentModel(),
  };

  Object.keys(model).forEach((key) => {
    if ((model as any)[key] === '') {
      (model as any)[key] = null;
    }
  });

  let request = paymentState.services.payments.createPayment(model, onBehalfOfClient.value?.id, {
    errors: { silent: true },
  });

  if (model.type === PaymentType.SCHEDULED || model.type === PaymentType.TRADE_SEND_TO_BENEFICIARY) {
    request = paymentState.services.payments.createScheduledPayment(model, onBehalfOfClient.value?.id, {
      errors: { silent: true },
    });
  }
  if (props.payment) {
    request = paymentState.services.payments.updateScheduledPayment(
      props.payment.id,
      {
        amount: model.amount,
        chargeType: model.chargeType,
        description: model.description,
        reference: model.reference,
        purposeCode: model.purposeCode,
        executionDate: model.executionDate,
      },
      undefined,
      {
        errors: { silent: true },
      }
    );
  }

  requestManager.currentOrNew('savePayment', request).subscribe(
    () => {
      paymentState.toast.success(`Payment ${props.payment?.id ? 'updated' : 'created'}`);
      emit('update-payment', model);
    },
    (e) => {
      const error = e.response?.data;
      if (error && error.code === GenericErrorCodes.VALIDATION_ERROR) {
        if (error.subErrors && error.subErrors.length) {
          for (let i = 0; i < error.subErrors.length; i++) {
            const subError = error.subErrors[i];
            if (subError.category === 'VALIDATION') {
              setErrorMessage(subError.field, subError.message);
            }
          }
        }
        paymentState.toast.error(errorMessages.formError);
        return;
      }

      paymentState.toast.error(
        errorMessages[error.code] || errorMessages.generic,
        {},
        { ...(error.code === PaymentErrorCodes.AGGREGATED_LIMIT_REACHED && { autoHideDelay: 15000 }) }
      );
    }
  );
}

function setErrorMessage(fieldName: string, message: string) {
  let field = null;
  if (fieldName === 'currency' || 'amount') {
    field = getChildModel(paymentCurrencyDef.form, fieldName);
  }

  if (field) {
    setState(field, 'errors', [
      {
        name: 'customError',
        error: message,
      },
    ]);
  }
}

function formToPaymentModel(): Payment {
  let payment: Payment = {
    amount: paymentCurrencyDef.form.amount,
    type: state.paymentType,
    beneficiaryId: selectedBeneficiary.value!.id,
    reference: state.paymentReference || undefined,
    description: state.paymentReason || undefined,
    oboClientId: state.onBehalfOfClient?.id,
  } as any;

  if (!props.payment && selectedWallet.value?.id) {
    payment.walletId = selectedWallet.value?.id;
  }

  if (selectedBeneficiary.value?.bankingScheme === BankingScheme.SWIFT) {
    payment.chargeType = state.chargeType!;
  }

  if (payment.type === PaymentType.SCHEDULED) {
    payment.executionDate = state.executionDate ? formatDate(state.executionDate, 'yyyy-MM-dd') : '';
  }

  if (isPaymentPurposeRequired.value) {
    payment.purposeCode = paymentPurposeDef.form.paymentPurpose;
  }

  if (payment.type === PaymentType.TRADE_SEND_TO_BENEFICIARY) {
    payment.tradeId = state.trade?.id ?? '';
  }

  return payment;
}

function submitForms() {
  validations.value!.forEach((f) => {
    if (f) {
      submitForm(f);
    }
  });
}

function loadWallets() {
  const query: any = {};
  query.pageSize = 100;
  if (isClient.value) {
    query.owner = {
      isPartnerUser: isPartnerUser.value,
      id: ownerId.value,
    };
  }
  if (!isClient.value) {
    query.partnerWallet = true;
    query.partnerId = authStore.loggedInIdentity?.partner.id;
  }
  requestManager
    .currentOrNew('getWallets', paymentState.services.wallet.listWallets(query))
    .subscribe((response) => (state.wallets = response.list));
}

function setFormData() {
  if (!props.payment) {
    return;
  }
  paymentCurrencyDef.form.currency = props.payment.walletId;
  paymentCurrencyDef.form.amount = props.payment.amount;
  state.paymentReference = props.payment.reference;

  state.paymentType = props.payment.type;
  state.chargeType = props.payment.chargeType;

  if (props.payment.type === PaymentType.SCHEDULED && props.payment.executionDate) {
    let dateArray = props.payment.executionDate.split('-');

    state.executionDate = new Date(Number(dateArray[0]), Number(dateArray[1]), Number(dateArray[2]));
  }

  let model = getChildModel(paymentCurrencyDef.form, 'currency');
  if (model) {
    setState(model, 'readonly', true);
  }

  model = getChildModel(paymentPurposeDef.form, 'paymentPurpose');
  if (model) {
    setState(model, 'readonly', true);
  }

  if (props.payment.tradeId) {
    getTrade(props.payment.tradeId);
  }

  getBeneficiary(props.payment.beneficiaryId);

  setTimeout(() => {
    loadPaymentPurposeFields();
  }, 100);
}

function getBeneficiary(id: string) {
  requestManager
    .sameOrCancelAndNew('loadBeneficiary', paymentState.services.beneficiary.getBeneficiary(id))
    .subscribe(
      (res) => (
        (selectedBeneficiary.value = res), (state.search = res.name ? res.name : `${res.firstName} ${res.lastName}`)
      )
    );
}

function getTrade(id: string) {
  requestManager
    .sameOrCancelAndNew('loadTrade', paymentState.services.trade.getTrade(id))
    .subscribe((res) => setTrade(res));
}

function loadPaymentPurposeFields() {
  if (selectedWallet.value && selectedBeneficiary.value) {
    const params = {
      currency: selectedWallet.value.currency,
      beneficiaryType: selectedBeneficiary.value.type,
      bankAccountCountry: selectedBeneficiary.value.bankCountry,
      bankingScheme: selectedBeneficiary.value.bankingScheme,
    };
    requestManager
      .sameOrNewPromise(
        'loadPaymentPurpose',
        () => paymentState.store.useSettingsStore().loadBankPurposeCodes({ params }),
        params
      )
      .then((purposeCodesResponse) => {
        isPaymentPurposeRequired.value = purposeCodesResponse.mandatory;
        const purposeCodesOptions = purposeCodesResponse?.purposeCodes.map((code) => {
          return {
            value: code.purposeCode,
            label: code.purposeDescription,
          };
        });
        const validPurposeCode = purposeCodesOptions.find(
          (option) =>
            option.value === props.payment?.purposeCode || option.value === selectedBeneficiary.value?.purposeCode
        )?.value;
        const model = getChildModel(paymentPurposeDef.form, 'paymentPurpose');
        if (model) {
          setState(model, 'options', purposeCodesOptions);
        }
        currentPurposeCodesOptions.value = purposeCodesOptions;
        paymentPurposeDef.form.paymentPurpose = validPurposeCode ?? '';
      });
  }
}

function setCurrency(currency: string) {
  let wallet = state.wallets?.filter((wallet) => {
    return wallet.currency === currency;
  })[0];
  if (wallet) {
    paymentCurrencyDef.form.currency = wallet.id;
  }
}

function setTrade(trade: Trade | null) {
  if (trade && trade.id) {
    state.trade = trade;

    let buy = getBuyCcy(trade);

    setCurrency(buy.currency);
    let model = getChildModel(paymentCurrencyDef.form, 'currency');
    if (model) {
      setState(model, 'readonly', true);
    }
    setAmountMaxValidation();
  } else {
    state.trade = null;
    let model = getChildModel(paymentCurrencyDef.form, 'currency');
    if (model) {
      setState(model, 'readonly', false);
    }
  }
}

function setExecutionDate(date: Date | null) {
  state.executionDate = date;
}

function setPaymentType(type: PaymentType) {
  state.paymentType = type;

  if (type !== PaymentType.TRADE_SEND_TO_BENEFICIARY) {
    setTrade(null);
  }
}

function setAmountMaxValidation() {
  if (selectedWallet.value && state.paymentType === PaymentType.IMMEDIATE) {
    setState(getChildModel(paymentCurrencyDef.form, 'amount')!, 'max', selectedWallet.value.availableBalance);
  } else if (state.paymentType === PaymentType.TRADE_SEND_TO_BENEFICIARY && state.trade) {
    setState(getChildModel(paymentCurrencyDef.form, 'amount')!, 'max', tradeAvailableBalance.value);
  } else {
    setState(getChildModel(paymentCurrencyDef.form, 'amount')!, 'max', Infinity);
  }
}

function getTradeLinkedPaymentsTotalSum(tradeId: string) {
  requestManager
    .sameOrCancelAndNew(
      'getTradeLinkedPaymentsTotalSum',
      paymentState.services.payments.getTradeLinkedPaymentsTotalSum(tradeId, onBehalfOfClient.value?.id)
    )
    .subscribe((res) => (state.tradePaymentTotalSum = res?.totalAmount));
}

watch(
  () => [state.paymentType, paymentCurrencyDef.form.currency],
  () => {
    if (isScheduleOrLinkToTradePaymentType.value && clientId.value && selectedWallet?.value?.currency) {
      loadAggregatedLimit();
    }
  }
);

watch(
  () => state.wallets,
  () => {
    if (state.wallets) {
      const model = getChildModel(paymentCurrencyDef.form, 'currency');
      loadPaymentPurposeFields();
      if (model) {
        let wallets = (state.wallets ?? []).map((i: Wallet) => ({
          label: `${i.currency}`,
          value: i.id,
          balance: i.balance,
        })) as FieldOption[];
        setState(model, 'options', wallets);
      }
    }
  },
  { immediate: true }
);

watch(
  selectedBeneficiary,
  () => {
    if (selectedBeneficiary.value) {
      loadPaymentPurposeFields();
    }
  },
  { immediate: true }
);

watch(
  selectedWallet,
  () => {
    setAmountMaxValidation();
  },
  { immediate: true }
);

watch(
  () => state.paymentType,
  () => {
    setAmountMaxValidation();
  },
  { immediate: true }
);

watch(
  () => state.trade,
  () => {
    if (state.trade && state.trade.id) {
      getTradeLinkedPaymentsTotalSum(state.trade.id);
    }
  },
  { immediate: true }
);

watch(
  validations,
  () => {
    emit('update:validations', validations.value);
  },
  { immediate: true }
);

function routeToPaymentsPage() {
  router.push(aggregatedPaymentReviewRoute);
  emit('cancel');
}

onBeforeMount(() => {
  loadWallets();
  setFormData();
  if (props.payment?.amount && clientId.value) {
    loadAggregatedLimit();
  }
});
</script>

<template>
  <div class="payment-form">
    <div v-show="!state.confirmPayment">
      <BoxGridBlock cols="12" class="scheduled-payment-form mb-3">
        <SchedulePaymentForm
          ref="schedulePaymentForm"
          class="mt-3 mb-n3"
          :paymentType="state.paymentType ?? undefined"
          :executionDate="state.executionDate ?? undefined"
          :trade="state.trade ?? null"
          @update-execution-date="setExecutionDate($event)"
          @update-payment-type="setPaymentType($event)"
          @update-trade="setTrade($event)"
          :readonly="props.payment ? true : false"
          :paymentRail="selectedBeneficiary?.paymentRail"
        />
      </BoxGridBlock>

      <BoxGridBlock cols="12" class="scheduled-payment-form mb-3">
        <h3 class="card-title">Currency and Amount</h3>
        <!-- payment type -->
        <ValidatedForm :fm="paymentCurrencyDef.form" :validation.sync="paymentCurrencyDef.validation">
          <template #paymentCurrencyForm.currency:after>
            <p
              class="mt-3"
              v-if="paymentCurrencyDef.form.currency && state.paymentType !== PaymentType.TRADE_SEND_TO_BENEFICIARY"
            >
              Available balance:
              <b>{{ selectedWallet?.currency }} {{ formatCurrencyValue(selectedWallet?.availableBalance) }}</b>
            </p>
            <p class="mt-3" v-if="state.paymentType === PaymentType.TRADE_SEND_TO_BENEFICIARY && state.trade">
              <b>{{ tradeDisplayBalance }}</b> funds without a linked payment from conversion
            </p>
          </template>
          <template #paymentCurrencyForm.amount:after>
            <AggregatedLimitErrorMessage
              v-if="showAggregatedLimitErrorMessage && aggregatedLimitForCurrency"
              :aggregatedLimitForCurrency="aggregatedLimitForCurrency"
              class="mt-3"
            >
              <template #route>
                <a class="text-underline" @click="routeToPaymentsPage">please click here</a>
              </template>
            </AggregatedLimitErrorMessage>
          </template>
        </ValidatedForm>
      </BoxGridBlock>

      <BoxGridBlock cols="12" v-if="!$props.payment">
        <div class="col col-12">
          <h3>
            Beneficiary
            <InfoTooltip
              class="pb-1"
              text="The beneficiary is the person or entity that will receive the funds from a transaction."
            />
          </h3>
        </div>
        <BeneficiaryAccountSelector
          class="my-3"
          :beneficiary.sync="selectedBeneficiary"
          :allowedCurrencies="[selectedWallet?.currency ?? '']"
          v-bind="$attrs"
        />
      </BoxGridBlock>
      <BoxGridBlock cols="12" v-else>
        <h3>
          Beneficiary
          <InfoTooltip
            class="pb-1"
            text="The beneficiary is the person or entity that will receive the funds from a transaction."
          />
        </h3>
        <div class="p-datatable-wrapper">
          <DataRow class="mb-2" cols="5" label="Name">
            {{ selectedBeneficiary?.name ?? `${selectedBeneficiary?.firstName} ${selectedBeneficiary?.lastName}` }}
          </DataRow>
          <DataRow class="mb-2" cols="5" label="Method">
            {{ selectedBeneficiary?.paymentRail }}
          </DataRow>
          <DataRow class="mb-2" cols="5" label="Currency">
            {{ selectedBeneficiary?.currency }}
          </DataRow>
        </div>
      </BoxGridBlock>

      <BoxGridBlock cols="12">
        <h3 class="card-title">Payment Reason</h3>
        <PaymentReasonForm
          ref="paymentReasonForm"
          :reason.sync="state.paymentReason"
          :beneficiary="selectedBeneficiary ?? undefined"
          narrow
        />
      </BoxGridBlock>
      <BoxGridBlock cols="12">
        <h3 class="card-title">Payment Reference</h3>

        <PaymentReferenceForm ref="paymentReferenceForm" narrow :reference.sync="state.paymentReference" />
      </BoxGridBlock>

      <BoxGridBlock cols="12" v-if="isPaymentPurposeRequired">
        <h3 class="card-title">Payment Purpose</h3>
        <ValidatedForm :fm="paymentPurposeDef.form" :validation.sync="paymentPurposeDef.validation" />
      </BoxGridBlock>
      <BoxGridBlock cols="12" v-if="selectedBeneficiary?.bankingScheme === BankingScheme.SWIFT">
        <ChargeTypeForm ref="chargeTypeForm" :chargeType.sync="state.chargeType" v-if="useChargeType" />
        <CurrencyFundingFee
          md="3"
          small
          v-if="bankingScheme && selectedWallet && selectedBeneficiary"
          :paymentCurrency="selectedBeneficiary?.currency"
          :bankingScheme="bankingScheme"
          :beneficiary="selectedBeneficiary"
          :chargeType="state.chargeType ?? undefined"
          :feePaymentType="FeePaymentType.PAYMENT"
        />
      </BoxGridBlock>
      <div class="my-4 text-sm-center text-md-left">
        <!-- save and cancel -->
        <VButton label="Cancel" class="secondary mr-2" @click="$emit('cancel')" />
        <VButton :disabled="continueDisabled" :label="payment ? 'Save' : 'Confirm'" @click="confirmPayment" />
      </div>
    </div>
    <div v-show="state.confirmPayment">
      <div class="card-block mb-3">
        <h3 class="card-title">Payment Details</h3>
        <!-- payment type -->
        <VRow class="details-row my-3 w-100" alignH="start">
          <VCol cols="12" class="payment-details-border-right">
            <DataRow class="mb-2" cols="12" md="4" sm="3" label="Type">
              {{ paymentTypeLabels[state.paymentType] }}
            </DataRow>
            <DataRow class="mb-2" cols="12" md="4" sm="3" label="Amount">
              <b>{{ selectedWallet?.currency }} {{ formatCurrencyValue(paymentCurrencyDef.form.amount) }}</b>
            </DataRow>
            <DataRow class="mb-2" cols="12" md="4" sm="3" label="Method">
              {{ selectedBeneficiary?.paymentRail }}
            </DataRow>
            <CurrencyFundingFee
              md="5"
              v-if="bankingScheme && selectedWallet && selectedBeneficiary"
              :paymentCurrency="selectedBeneficiary?.currency"
              :bankingScheme="bankingScheme"
              :beneficiary="selectedBeneficiary"
              :chargeType="state.chargeType ?? undefined"
              :feePaymentType="FeePaymentType.PAYMENT"
            />
            <DataRow class="mb-2" cols="12" md="4" sm="3" label="Payment Reason">
              {{ state.paymentReason }}
            </DataRow>
            <DataRow class="mb-2" cols="12" md="4" sm="3" label="Payment Reference">
              {{ state.paymentReference }}
            </DataRow>
            <DataRow v-if="selectedPurposeCodeDescription" class="mb-2" cols="12" md="4" sm="3" label="Payment Purpose">
              {{ selectedPurposeCodeDescription }}
            </DataRow>
            <DataRow
              class="mb-2"
              cols="12"
              md="4"
              sm="3"
              label="Execution Date"
              v-if="state.paymentType === PaymentType.SCHEDULED && state.executionDate"
            >
              {{ formatDate(state.executionDate, 'yyyy-MM-dd') }}
            </DataRow>
          </VCol>
        </VRow>
      </div>
      <div class="card-block mb-3">
        <h3 class="card-title">Send Money To</h3>
        <TradeBeneficiaryInfo
          sm="2"
          :tradeDestination="{
            reason: state.paymentReason ?? '',
            reference: state.paymentReference,
            beneficiary: selectedBeneficiary,
            chargeType: state.chargeType ?? undefined,
          }"
        />
      </div>
      <div class="my-4 text-sm-center text-md-left">
        <!-- save and cancel -->
        <PaymentEditorCancelModal
          v-if="selectedBeneficiary"
          v-slot="{ showModal }"
          :beneficiary="selectedBeneficiary"
          @cancel-payment="emit('cancel'), emit('update-title', '')"
        >
          <button @click="showModal" class="btn btn-secondary">Cancel</button>
        </PaymentEditorCancelModal>
        <VButton :loading="isSaving" :disabled="!formsLoaded || formsInvalid" label="Confirm" @click="savePayment" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-underline {
  @include themedTextColor($common-color-red);
  text-decoration: underline;
}

::v-deep svg.icon {
  margin-left: 2px;
  @include themedTextColor($color-primary);
}

::v-deep .dates-filter-holder .field-group-wrapper {
  margin-bottom: 0;
}
</style>
