import { VersionedObject, CreatableObject, UpdatableObject } from './rest';

export enum ClientCollateralState {
  ABOVE_RECAPITALIZATION_THRESHOLD = 'ABOVE_RECAPITALIZATION_THRESHOLD',
  ABOVE_UC_THRESHOLD = 'ABOVE_UC_THRESHOLD',
  ABOVE_RECOMMENDED_RECAP_THRESHOLD = 'ABOVE_RECOMMENDED_RECAP_THRESHOLD',
  BELOW_RECOMMENDED_RECAP_THRESHOLD = 'BELOW_RECOMMENDED_RECAP_THRESHOLD',
  RECAPITALIZATION_REQUIRED = 'RECAPITALIZATION_REQUIRED',
  ABOVE_MAX_LIMIT = 'ABOVE_MAX_LIMIT',
}

export interface CollateralData {
  clientId: string;
  partnerId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface ClientCollateralsConfigurations {
  marginCallTrigger: number;
  autoCutOut: number;
  recapitalizationThreshold: number;
  unutilizedCollateralThreshold: number;
  recommendedRecapThreshold: number;
}

export interface ClientCollateralSettings extends CreatableObject, UpdatableObject, VersionedObject {
  id: string;
  minCreditUsageForFirstMarginCallWarning: number;
  minCreditUsageForFinalMarginCallWarning: number;
  minCreditUsageForDisablingCollateralWithdraw: number;
}

export enum ClientRateConfigurationKeys {
  IMCC_RATE_BPS = 'IMCC_RATE_BPS',
}

export interface ClientRatesRequest {
  key: ClientRateConfigurationKeys;
  value: string;
}

export interface ClientCollaterals extends CollateralData {
  state: ClientCollateralState;
  initialMarginCollateralAvailable: number;
  marginCollateralAvailable: number;
  recapitalizationThreshold: number;
  unutilizedCollateralThreshold: number;
  marginFundingRate: number;
  recommendedRecapThreshold: number;
  recommendedWithdraw: number;
  recommendedRecapitalizationAmount: number;
  marginCallTrigger: number;
  totalMarginRequirement: number;
  marginCreditFacility: number;

  currency: string;
  totalInitialMargin: number;
  totalVariationMarginMaintenance: number;
  postedCollateral: number;
  effectiveCollateral: number;
  initialMarginCreditAvailable: number;
  effectiveCollateralUtilization: number;
  totalInitialMarginCollateralFunding: number;
  totalInitialMarginCreditFunding: number;
  unutilizedCollateral: number;
  usdConversionRate: number;
  gbpConversionRate: number;
  eurConversionRate: number;
  autoCutOut: number;
  recapitalizationAmount: number;
  effectiveCollateralUtilizationAfterUcWithdraw: number;
  effectiveCollateralUtilizationAfterRecommendedWithdraw: number;
}

export interface CollateralVariationMarginConfig extends CollateralData, VersionedObject {
  variationMarginPercentage: number;
  variationMarginIncrementPercentage: number;
  variationMarginConfigurationUpdatedAt: string;
  variationMarginConfigurationUpdatedBy: string;
}

export interface CollateralVariationMarginConfigRequest {
  clientId: string;
  variationMarginPercentage: number;
  variationMarginIncrementPercentage: number;
}

export enum CollateralViewState {
  MARGIN_CALL_TRIGGERED = 'MARGIN_CALL_TRIGGERED',
  AT_ZERO_USAGE = 'AT_ZERO_USAGE',
  BELOW_RRT = 'BELOW_RRT',
  BELOW_UC_THRESHOLD = 'BELOW_UC_THRESHOLD',
  BELOW_RECAP_THRESHOLD = 'BELOW_RECAP_THRESHOLD',
  ABOVE_RECAP_THRESHOLD = 'ABOVE_RECAP_THRESHOLD',
  UNKNOWN = 'UNKNOWN',
}

export const callTriggeredStates = [
  ClientCollateralState.RECAPITALIZATION_REQUIRED,
  ClientCollateralState.ABOVE_MAX_LIMIT,
];

export enum FundingType {
  COLLATERAL = 'COLLATERAL',
  CREDIT = 'CREDIT',
  COLLATERAL_AND_CREDIT = 'COLLATERAL_AND_CREDIT',
  UNDEFINED = 'UNDEFINED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export const fundingTypesToHuman: Record<FundingType, string> = {
  COLLATERAL: 'Posted Collateral',
  CREDIT: 'Credit Facility',
  COLLATERAL_AND_CREDIT: 'Both',
  UNDEFINED: 'Unspecified',
  NOT_REQUIRED: 'Not required',
};
