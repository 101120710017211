<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 5H4.16667H17.5"
      stroke="currentColor"
      stroke-width="1.58333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6244 5.00163C16.6244 4.5644 16.2699 4.20996 15.8327 4.20996C15.3955 4.20996 15.041 4.5644 15.041 5.00163H16.6244ZM4.95768 5.00163C4.95768 4.5644 4.60324 4.20996 4.16602 4.20996C3.72879 4.20996 3.37435 4.5644 3.37435 5.00163H4.95768ZM5.87459 5.0013C5.87459 5.43853 6.22903 5.79297 6.66626 5.79297C7.10349 5.79297 7.45793 5.43853 7.45793 5.0013H5.87459ZM12.5413 5.0013C12.5413 5.43853 12.8957 5.79297 13.3329 5.79297C13.7702 5.79297 14.1246 5.43853 14.1246 5.0013H12.5413ZM15.041 5.00163V16.6683H16.6244V5.00163H15.041ZM15.041 16.6683C15.041 17.1515 14.6493 17.5433 14.166 17.5433V19.1266C15.5237 19.1266 16.6244 18.026 16.6244 16.6683H15.041ZM14.166 17.5433H5.83268V19.1266H14.166V17.5433ZM5.83268 17.5433C5.34943 17.5433 4.95768 17.1515 4.95768 16.6683H3.37435C3.37435 18.026 4.47498 19.1266 5.83268 19.1266V17.5433ZM4.95768 16.6683V5.00163H3.37435V16.6683H4.95768ZM7.45793 5.0013V3.33464H5.87459V5.0013H7.45793ZM7.45793 3.33464C7.45793 2.85139 7.84968 2.45964 8.33293 2.45964V0.876302C6.97523 0.876302 5.87459 1.97694 5.87459 3.33464H7.45793ZM8.33293 2.45964H11.6663V0.876302H8.33293V2.45964ZM11.6663 2.45964C12.1495 2.45964 12.5413 2.85139 12.5413 3.33464H14.1246C14.1246 1.97694 13.024 0.876302 11.6663 0.876302V2.45964ZM12.5413 3.33464V5.0013H14.1246V3.33464H12.5413Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
