import { getHSLA } from 'ah-theme';

// Colors retrieved from CSS theme variables
// These expect ah-theme package to be used, and as such depend on it

export const dataColorPrimary = getHSLA('primary', { alpha: 0.5 });
export const linesColorPrimary = getHSLA('text-secondary', { alpha: 0.5 });
export const dataColorGreen = getHSLA('widgets-green', { alpha: 0.5 });
export const dataColorGrey = getHSLA('widgets-grey', { alpha: 0.5 });
export const dataColorGreenHover = getHSLA('widgets-green', { alpha: 0.9 });
export const dataColorGreyHover = getHSLA('widgets-grey', { alpha: 0.9 });
export const linesColorGreen = getHSLA('widgets-green', { alpha: 1 });
export const linesColorGrey = getHSLA('widgets-grey', { alpha: 1 });
