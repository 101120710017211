import { compareParam } from './paramCheck';

function isNumber(num: any) {
  return typeof num === 'number' && !isNaN(num);
}

export const biggerThanField = (fieldName: string) => (val: any, obj: any) =>
  !isNumber(obj[fieldName]) || obj[fieldName] < val;

export const smallerThanField = (fieldName: string) => (val: any, obj: any) =>
  !isNumber(obj[fieldName]) || obj[fieldName] > val;

export const smallerThanParam = (fieldName: string, paramName: string) =>
  compareParam(fieldName, paramName, (val, compare) => !isNumber(compare) || val < compare);

export const biggerThanParam = (fieldName: string, paramName: string) =>
  compareParam(fieldName, paramName, (val, compare) => !isNumber(compare) || val > compare);

export const smallerThanOrEqualsParam = (fieldName: string, paramName: string) =>
  compareParam(fieldName, paramName, (val, compare) => !isNumber(compare) || val <= compare);

export const biggerThanOrEqualsParam = (fieldName: string, paramName: string) =>
  compareParam(fieldName, paramName, (val, compare) => !isNumber(compare) || val >= compare);
