import { render, staticRenderFns } from "./AppLogoIcon.vue?vue&type=template&id=11794a29&scoped=true"
import script from "./AppLogoIcon.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppLogoIcon.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AppLogoIcon.vue?vue&type=style&index=0&id=11794a29&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11794a29",
  null
  
)

export default component.exports