<script setup lang="ts">
import { Individual, IndividualType, PaginatedQuery, RegistrationStatus } from 'ah-api-gateways';
import { companyMemberTableFieldsFull } from '@/app/components/individual/tableFields/companyMemberTableFields';
import StatusPill from 'ah-common-lib/src/common/components/StatusPill.vue';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const services = getServices();

const props = defineProps({
  ...defineUseManagedListingProps<Individual>(),
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: companyMemberTableFieldsFull }),
  },
});

const requestManager = useRequestManager();

interface IndividualsListEmits extends UseManagedListingEmits<Individual> {}

const emit = defineEmits<IndividualsListEmits>();

const managedListing = useManagedListing<Individual>({
  loadDataRequest(query: PaginatedQuery) {
    return services.individual.listIndividuals(query);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

function isAdmin(item: Individual): boolean {
  return [IndividualType.PARTNER_ADMIN, IndividualType.CLIENT_ADMIN].includes(item.type);
}

function rowColorClass(individual: Individual) {
  if (individual && !isAdmin(individual) && individual.status !== RegistrationStatus.APPROVED) {
    return 'warning';
  }
}

function individualAttrs(individual: Individual) {
  const label = individual.status === RegistrationStatus.INVITED ? 'Invited' : 'Proposed';
  return {
    label,
    color: 'warning',
  };
}

function isApproved(individual: Individual): boolean {
  return individual.status === RegistrationStatus.APPROVED;
}
</script>

<template>
  <PaginatedTable
    fixed
    hover
    nowrap
    animateCols
    class="individuals-table"
    items-label="individuals"
    :tbody-tr-class="rowColorClass"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(name)="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
    <template #cell(status)="{ item }">
      <StatusPill v-if="isAdmin(item)" :label="item.owner ? 'Owner' : 'Admin'" color="success" class="text-small" />
      <StatusPill v-else-if="!isApproved(item)" v-bind="individualAttrs(item)" class="text-small" />
      <span v-else />
    </template>

    <!-- row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep .warning td::before {
  @include themedBorderColor($color-yellow-highlight, $color-yellow-highlight, '', ' !important');
}
</style>
