<script lang="ts" setup>
import { Trade, getPrimaryCcy, getSellCcy, getBuyCcy, formatHedgingInstrument } from 'ah-api-gateways';
import TradeDetailsModal from '../info/TradeDetailsModal.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { LockIcon } from 'ah-common-lib/src/icons/components';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    trade: Trade;
    originalOnly?: boolean | string;
    hedgingProductLabel?: string;
  }>(),
  {
    originalOnly: false,
    hedgingProductLabel: 'Type',
  }
);

const shouldShowOriginalOnly = computed(() => {
  return props.originalOnly !== false;
});

const clientRate = computed(() => {
  return getPrimaryCcy(props.trade).clientRate;
});

const sellCcy = computed(() => {
  return getSellCcy(props.trade);
});

const buyCcy = computed(() => {
  return getBuyCcy(props.trade);
});
</script>

<template>
  <div>
    <DataRow v-if="trade.parentTradeId" class="mb-2 trade-id" label="Original Trade ID" cols="6">
      <TradeDetailsModal
        :tradeId="trade.parentTradeId"
        :childrenId="trade.id"
        title="Original trade details"
        hide-state
        original-only
      >
        <template #default="{ show, parentTradeReference, anyPending }">
          <LoadingIcon v-if="anyPending" />
          <a v-else class="link" @click="show"> {{ parentTradeReference }}</a>
        </template>
      </TradeDetailsModal>
    </DataRow>
    <DataRow class="mb-2 trade-id" label="Trade ID" cols="6">
      {{ trade.referenceNumber }} <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.tradeID')" />
    </DataRow>
    <DataRow class="mb-2 trade-type" :label="hedgingProductLabel" cols="6">
      {{ formatHedgingInstrument(trade.hedgingProduct) }}
      <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.type')" />
    </DataRow>
    <slot name="notionals">
      <template v-if="!shouldShowOriginalOnly">
        <DataRow class="mb-2 trade-sell-currency" label="Sell amount" cols="6">
          {{ sellCcy.currency }}
          {{ formatCurrencyValue(trade.hasDrawdowns ? sellCcy.remainingClientAmount : sellCcy.clientAmount) }}
          <LockIcon v-if="sellCcy.isFixedSide" />
        </DataRow>
        <DataRow class="mb-2 trade-buy-currency" cols="6" label="Buy amount">
          {{ buyCcy.currency }}
          {{ formatCurrencyValue(trade.hasDrawdowns ? buyCcy.remainingClientAmount : buyCcy.clientAmount) }}
          <LockIcon v-if="buyCcy.isFixedSide" />
        </DataRow>
        <template v-if="trade.hasDrawdowns">
          <DataRow class="mb-2 trade-original-sell-currency" cols="6" label="Original Sell amount">
            {{ sellCcy.currency }}
            {{ formatCurrencyValue(sellCcy.clientAmount) }}
            <LockIcon v-if="sellCcy.isFixedSide" />
          </DataRow>
          <DataRow class="mb-2 trade-original-buy-currency" cols="6" label="Original Buy amount">
            {{ buyCcy.currency }}
            {{ formatCurrencyValue(buyCcy.clientAmount) }}
            <LockIcon v-if="buyCcy.isFixedSide" />
          </DataRow>
        </template>
      </template>
      <template v-else>
        <DataRow class="mb-2 trade-original-sell-currency" cols="6" label="Sell amount">
          {{ sellCcy.currency }}
          {{ formatCurrencyValue(sellCcy.clientAmount) }}
          <LockIcon v-if="sellCcy.isFixedSide" />
        </DataRow>
        <DataRow class="mb-2 trade-original-buy-currency" cols="6" label="Buy amount">
          {{ buyCcy.currency }}
          {{ formatCurrencyValue(buyCcy.clientAmount) }}
          <LockIcon v-if="buyCcy.isFixedSide" />
        </DataRow>
      </template>
    </slot>
    <DataRow class="mb-2 trade-rate" label="Booked rate" cols="6">
      {{ clientRate }}
      <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.contractRate')" />
    </DataRow>
    <DataRow cols="6" class="mb-2 trade-creation-date" label="Trade date">
      {{ formatDate(trade.createdAt, SIMPLE_DATE_FORMAT) }}
    </DataRow>
    <DataRow class="mb-2 trade-window-start-date" cols="6" label="Window start date" v-if="trade.windowStartDate">
      {{ formatDate(trade.windowStartDate, SIMPLE_DATE_FORMAT) }}
    </DataRow>
    <DataRow class="mb-2 trade-settlement-date" cols="6" label="Settlement date">
      {{ formatDate(trade.settlementDate, SIMPLE_DATE_FORMAT) }}
    </DataRow>
  </div>
</template>
