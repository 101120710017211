import { WalletType } from '../wallet';

export enum CobBalanceOwner {
  CLIENTS = 'CLIENTS',
  PARTNERS = 'PARTNERS',
  HOUSE = 'HOUSE',
}

export enum COBValueType {
  COLLATERAL = 'COLLATERAL',
  MONEY = 'MONEY',
  COB = 'COB',
}

export enum CurrencyMode {
  GBP = 'GBP',
  CCY = 'CCY',
}

export interface CobBalancesWalletReport {
  id: string;
  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;
  walletType: WalletType;
  totalCobBalanceGbpEquivalent: number;
  totalCollateralBalanceGbpEquivalent: number;
  totalMoneyBalanceGbpEquivalent: number;
  cobBalanceCurrencies: CobReportCurrency[];
  closeOfBusinessAt: string;
}

export interface CobReportCurrency {
  currency: string;
  cobBalance: number;
  collateralBalance: number;
  moneyBalance: number;
  gbpRate: number;
  cobBalanceGbpEquivalent: number;
  collateralBalanceGbpEquivalent: number;
  moneyBalanceGbpEquivalent: number;
}

export interface CobTotalBalancesWalletReport {
  id: string;
  walletType: WalletType;
  totalCobBalanceCurrencies: CobTotalReportCurrency[];
  totalCobBalanceGbpEquivalent: number;
  totalCollateralBalanceGbpEquivalent: number;
  totalMoneyBalanceGbpEquivalent: number;
  closeOfBusinessAt: string;
}

export interface CobTotalReportCurrency {
  currency: string;
  totalMoneyBalance: number;
  totalCobBalance: number;
  totalCollateralBalance: number;
  totalCobBalanceGbpEquivalent: number;
  totalCollateralBalanceGbpEquivalent: number;
  totalMoneyBalanceGbpEquivalent: number;
}
