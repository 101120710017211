import {
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  PaymentReport,
  ListQuery,
  ExportListType,
  DocumentExport,
  VersionedObject,
  Payment,
  PaymentRail,
  PaymentAggregateLimit,
  PaymentAggregateLimitParams,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions, RequestManager, HttpRequestOptions } from 'ah-requests';

export class PaymentsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'paymentService' } },
      },
    });
  }

  private requestManager = new RequestManager();

  public get requestStates() {
    return this.requestManager.requestStates;
  }

  public listPayments(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<PaymentReport>>(`${this.baseUrl}payments`, {
      axiosConfig: { params },
    });
  }

  public downloadListPayments(params: ListQuery, fileFormat: ExportListType, documentTitle = 'Payments List') {
    params = standardiseQuerySort(params || {});

    return this.get<DocumentExport>(`${this.baseUrl}payments/export`, {
      axiosConfig: {
        params: {
          ...params,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public downloadListPaymentsWithDetails(
    params: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Payments List'
  ) {
    params = standardiseQuerySort(params || {});

    return this.get<DocumentExport>(`${this.baseUrl}payments/details/export`, {
      axiosConfig: {
        params: {
          ...params,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public downloadPayment(id: string, documentTitle = 'Payments Export') {
    return this.get<DocumentExport>(`${this.baseUrl}payments/${id}/export`, {
      axiosConfig: {
        params: {
          documentTitle,
        },
      },
    });
  }

  public downloadPaymentMt103(paymentId: string, fileFormat: ExportListType, documentTitle = 'Transaction Statement') {
    return this.get<DocumentExport>(`${this.baseUrl}payments/${paymentId}/mt103/export`, {
      axiosConfig: {
        params: {
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public loadPaymentsDistinctRails(
    params: {
      clientId?: string | string[];
      partnerId?: string | string[];
      excludePartners?: boolean;
      excludeClients?: boolean;
    },
    options?: Partial<HttpOptions<{ paymentRails: PaymentRail[] }>>
  ) {
    return this.get<{ paymentRails: PaymentRail[] }>(`${this.baseUrl}payments/distinct-payment-rails`, {
      axiosConfig: { params },
      options,
    });
  }

  public createPayment(data: Partial<Payment>, oboClientId?: string, options?: Partial<HttpOptions<VersionedObject>>) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VersionedObject>(`${this.baseUrl}payments`, data, {
      axiosConfig: { headers },
      options,
    });
  }

  public createScheduledPayment(
    data: Partial<Payment>,
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VersionedObject>(`${this.baseUrl}payments/schedule`, data, {
      axiosConfig: { headers },
      options,
    });
  }

  public cancelPayment(id: string, oboClientId?: string, options?: Partial<HttpOptions<void>>) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.delete<void>(`${this.baseUrl}payments/schedule/${id}`, {
      options,
      axiosConfig: { headers },
    });
  }

  public updateScheduledPayment(
    id: string,
    data: Partial<Payment>,
    oboClientId?: string,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    const headers: Record<string, string> = {};

    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;
    return this.put<VersionedObject>(`${this.baseUrl}payments/schedule/${id}`, data, {
      axiosConfig: { headers },
      options,
    });
  }

  public getPayment(id: string, oboClientId?: string, options?: Partial<HttpOptions<PaymentReport>>) {
    const headers: Record<string, string> = {};

    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;
    return this.get<PaymentReport>(`${this.baseUrl}payments/${id}`, {
      axiosConfig: { headers },
      options,
    });
  }

  public getTradeLinkedPaymentsTotalSum(tradeId: string, oboClientId?: string) {
    const headers: Record<string, string> = {};

    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;
    return this.get<{ totalAmount: number }>(`${this.baseUrl}payments/trade/${tradeId}/sum`, {
      axiosConfig: { headers },
    });
  }

  public getAggregateLimit(
    params: PaymentAggregateLimitParams,
    options?: Partial<HttpRequestOptions<PaymentAggregateLimit>>
  ) {
    const headers: Record<string, string> = {};

    if (params.oboClientId) headers['x-ah-on-behalf-of'] = params.oboClientId;
    return this.get<PaymentAggregateLimit>(
      `${this.baseUrl}payments/schedule/aggregate-limit/${params.clientId}/${params.paymentCurrency}`,
      {
        ...options,
        axiosConfig: { ...options?.axiosConfig, headers },
      }
    );
  }
}
