<script lang="ts" setup>
import NotificationsModal from 'ah-notifications/src/components/notifications/NotificationsModal.vue';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { VButton } from 'ah-common-lib/src/common/components';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import { Notification, profileLinkNotificationTypes } from 'ah-api-gateways';

const notificationsStore = useNotificationsStore();

const router = useRouter();

const hasUnreadNotifications = computed(() => notificationsStore.unreadCount > 0);

function navigateToLimitsProfile(item: Notification) {
  if (profileLinkNotificationTypes.includes(item.type)) {
    router.push('/dashboard/trades/limits-collateral');
  }
}
</script>

<template>
  <NotificationsModal>
    <template #default="{ show }">
      <slot v-bind="{ show }">
        <span @click="show" v-b-tooltip="'Notifications'">
          <IconNotificationsActive v-if="hasUnreadNotifications" />
          <IconNotifications v-else />
        </span>
      </slot>
    </template>
    <template #notification-actions="{ item, hide }">
      <div class="text-center mt-3" v-if="profileLinkNotificationTypes.includes(item.type)">
        <VButton
          @click="
            navigateToLimitsProfile(item);
            hide();
          "
        >
          View new limit
        </VButton>
      </div>
    </template>
  </NotificationsModal>
</template>
