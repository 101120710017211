<script setup lang="ts">
import { computed, PropType } from 'vue';

const props = defineProps({
  alignV: {
    type: String as PropType<'start' | 'center' | 'end'>,
  },
  alignH: {
    type: String as PropType<'start' | 'center' | 'end' | 'around' | 'between'>,
  },
});

const bRowAttrs = computed(() => [
  props.alignV ? 'align-items-' + props.alignV : '',
  props.alignH ? 'justify-content-' + props.alignH : '',
]);
</script>

<template>
  <div class="row" :class="bRowAttrs">
    <slot />
  </div>
</template>

<style lang="scss" scoped></style>
