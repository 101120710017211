import { TimeFrames } from '..';

export enum RateGranularity {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
}

export type RatesQuery = {
  currencyPair: string;
  startTime: string;
  endTime: string;
  granularity: RateGranularity;
};

export interface HistoricalRate {
  currencyPair: string;
  ts: TimeFrames;
  rate: number;
}

export interface HistoricalRateList {
  rates: HistoricalRate[];
}
