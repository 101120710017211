<script setup lang="ts">
import Vue, { computed, PropType, reactive, ref, set, watch } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { CompoundValidation, FormDefinition, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import {
  setApiErrorMessages,
  setErrorMessage,
  setState,
  submitForm,
  toDataModel,
  updateModel,
} from 'ah-common-lib/src/form/helpers';
import {
  BaseIndividual,
  checkAddressHistoryValidity,
  Client,
  ClientFileCategories,
  EntityAddressHistory,
  FileCategory,
  UnsubmittedUbo,
  UnsubmittedUboFile,
  UploadedFile,
  FeatureFlag,
  ComplianceErrorCodes,
} from 'ah-api-gateways';
import { makeMemberForm } from './reviewForms';
import { cloneDeep } from 'lodash';
import UboDocumentsEditor from '../../ubos/UboDocumentsEditor.vue';
import AccordionBox from '@/app/components/common/AccordionBox.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAuthStore } from '@/app/store/authStore';
import { getServices } from '@/app/services';
import { forkJoin, from, of } from 'rxjs';
import { catchError, defaultIfEmpty, mergeMap, mergeMapTo } from 'rxjs/operators';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import RegistrationAddressForm from '../../registration/forms/common/RegistrationAddressForm.vue';
import ReviewScreenPreviousAddressesForms from './ReviewScreenPreviousAddressesForms.vue';
import { useFeatureFlag } from 'ah-common-stores';
import { useToast } from 'ah-common-lib/src/toast';
import { HttpError } from 'ah-requests';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';
import { forkJoinWithCompletion } from 'ah-common-lib/src/helpers/rxjs';
import ClientDocumentsUploader from '../../settings/client/ClientDocumentsUploader.vue';
import { helpers } from '@vuelidate/validators';

type UboForm = FormDefinition & {
  id: string;
  ubo: UnsubmittedUbo;
  currentAddressValidation: FormValidation | null;
  previousAddressesValidation: CompoundValidation | null;
};

const requestManager = useRequestManager().manager;

const services = getServices();

const toast = useToast();

const authStore = useAuthStore();

const individualStore = useIndividualSettingsStore();

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
  documents: {
    type: Array as PropType<UploadedFile[]>,
    default: () => [],
  },
  ubos: {
    type: Array as PropType<UnsubmittedUbo[]>,
    default: () => [],
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits({
  'update:ubos': (_ubos: UnsubmittedUbo[]) => true,
  'update:documents': (_ubos: UploadedFile[]) => true,
});

const state = reactive<{
  editing: boolean;
  uboForms: UboForm[];
  showErrorMessage: boolean;
}>({
  editing: false,
  uboForms: [] as UboForm[],
  showErrorMessage: false,
});

const { isActive: isOnboardingDocumentsActive } = useFeatureFlag({
  featureFlag: FeatureFlag.ONBOARDING_DOCUMENTS_ENABLED,
});

function isUboAUKResident(ubo?: UnsubmittedUbo): boolean {
  return ubo?.currentAddress?.countryCode === 'GB';
}

function includesFile(category: FileCategory) {
  return props.documents.find((doc) => doc.category === category) ? 'Uploaded' : 'Upload Later';
}

const signatoryDocsBlock = computed<InformationBlock[]>(() => [
  { label: 'Proof of ID', key: 'poi', value: includesFile(ClientFileCategories.PHOTO_ID), cols: 4 },
  { label: 'Proof of Address', key: 'poa', value: includesFile(ClientFileCategories.PROOF_OF_ADDRESS), cols: 4 },
]);

const companyDocsBlock = computed<InformationBlock[]>(() => [
  {
    label: 'Certificate of incorporation',
    key: 'incCertificate',
    value: includesFile(ClientFileCategories.INCORPORATED_DOCUMENT),
    cols: 4,
  },
  {
    label: 'Audited financial statements/latest accounts',
    key: 'financialStatements',
    value: includesFile(ClientFileCategories.FINANCIAL_STATEMENT),
    cols: 4,
  },
  {
    label: 'Sample Invoice/Proof of Business Activity',
    key: 'pba',
    value: includesFile(ClientFileCategories.SAMPLE_INVOICE),
    cols: 4,
  },
]);

const uboInfoBlock = ref<InformationBlock[]>([
  { label: 'Are there any UBOs?', key: 'any', value: props.ubos.length > 0 ? 'Yes' : 'No', cols: 4 },
]);

const uboBlock = [
  { label: 'Title', key: 'title', cols: 12 },
  { label: 'First Name', key: 'firstName', cols: 4 },
  { label: 'Last Name', key: 'lastName', cols: 4 },
  { label: 'Email Address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 4 },
  { label: 'Date of Birth', key: 'birthDate', cols: 4 },
  { label: 'Percentage of Ownership', key: 'ownershipPercentage', cols: 4 },
  { label: 'Proof of ID', key: 'poi', value: '', cols: 4 },
  { label: 'Proof of Address', key: 'poa', value: '', cols: 4 },
];

const filteredUboBlock = computed(() =>
  uboBlock.filter((item) => isOnboardingDocumentsActive.value || (item.key !== 'poi' && item.key !== 'poa'))
);

const uboAddressHistoryBlock = computed(() => {
  return (ubo: UnsubmittedUbo) => [
    { label: 'Address Line 1', key: 'addressLine', cols: 4 },
    { label: 'City', key: 'city', cols: 4 },
    { label: 'State/Province/County', key: 'stateOrProvince', cols: 4 },
    { label: 'Postcode/Zip Code', key: 'postalCode', cols: 4 },
    { label: 'Country', key: 'countryCode', cols: 4 },
    ...(isUboAUKResident(ubo)
      ? [
          {
            label: 'When did the UBO move to this address?',
            key: 'residingFrom',
            cols: 8,
          },
        ]
      : []),
  ];
});

const isEditable = computed(() => props.editable !== false);

const areUboFormsInvalid = computed(() =>
  state.uboForms.some((uboForm) => {
    return !!(
      uboForm.validation?.$invalid ||
      uboForm.currentAddressValidation?.$invalid ||
      uboForm.previousAddressesValidation?.$invalid
    );
  })
);

const uboAddressApiErrors = computed(() =>
  state.uboForms.map((uboForm) =>
    uboForm.ubo.addressHistoryApiError
      ? constructPayloadErrors<EntityAddressHistory>(uboForm.ubo.addressHistoryApiError)
      : undefined
  )
);

function checkUboAddressHistoryValidity(ubo: UnsubmittedUbo) {
  const addressHistoryValidity = checkAddressHistoryValidity({
    currentAddress: { ...ubo.currentAddress },
    previousAddresses: ubo.previousAddresses || [],
  });
  return !isUboAUKResident(ubo) || (addressHistoryValidity.age && addressHistoryValidity.gaps);
}

function uboSaveRequests() {
  const clientId = authStore.loggedInIdentity!.client!.id;

  return state.uboForms.map((uboForm, index) => {
    setState(uboForm.form, 'errors', [], true);
    const validateHistoryRequest = services.compliance
      .validateAddressHistory({
        currentAddress: uboForm.ubo.currentAddress,
        previousAddresses: uboForm.ubo.previousAddresses,
      })
      .pipe(
        catchError((e: HttpError) => {
          set(uboForm.ubo, 'addressHistoryApiError', e.response?.data);
          throw e;
        })
      );

    const uboUpdateRequest = services.compliance
      .updateUboUser(clientId, uboForm.ubo.id!, toDataModel(state.uboForms[index].form) as BaseIndividual, {
        errors: { silent: true },
      })
      .pipe(
        catchError((e: HttpError) => {
          set(uboForm.ubo, 'userCreationApiError', e.response?.data);
          if (e.response?.data.subErrors) {
            setApiErrorMessages(constructPayloadErrors<BaseIndividual>(e.response.data), uboForm.form);
          } else if (e.response?.data.code === ComplianceErrorCodes.CLIENT_UBO_EMAIL_ALREADY_IN_USE) {
            setErrorMessage(uboForm.form, 'email', e.response.data.message);
            toast.error(e.response.data.message);
          }
          throw e;
        })
      );

    return validateHistoryRequest.pipe(
      mergeMapTo(uboUpdateRequest),
      mergeMap(() =>
        forkJoin(
          (uboForm.ubo.readyToUpload || []).map((file) => {
            if (file.file) {
              return services.compliance.submitUboDocument(clientId, uboForm.ubo.id!, file.category, file.file!);
            } else {
              const toBeRemoved = uboForm.ubo.documents.find((doc) => doc.category === file.category);
              if (toBeRemoved) {
                return services.compliance.removeUboDocument(clientId, uboForm.ubo.id!, toBeRemoved.id);
              }
              return of(null);
            }
          })
        ).pipe(defaultIfEmpty([] as any))
      )
    );
  });
}

function save() {
  state.uboForms.forEach((uboForm) => {
    if (uboForm.validation) {
      submitForm(uboForm.validation);
    }
    if (uboForm.currentAddressValidation) {
      submitForm(uboForm.currentAddressValidation);
    }
    if (uboForm.previousAddressesValidation) {
      uboForm.previousAddressesValidation.validations.forEach(submitForm);
    }
  });

  if (areUboFormsInvalid.value) {
    toast?.error('Form contains errors! Please review and resubmit.');
    return;
  }

  if (state.uboForms.some((uboForm) => !checkUboAddressHistoryValidity(uboForm.ubo))) {
    state.showErrorMessage = true;
    toast?.error(
      'Please review the address history. Ensure it spans at least 3 years with no gaps for UK resident UBOs.'
    );
    return;
  }

  requestManager
    .currentOrNew(
      'saveUbos',
      forkJoinWithCompletion([...uboSaveRequests(), from(individualStore.loadClientDocuments(true))])
    )
    .subscribe(() => {
      toast?.success('Changes applied successfully');
      emit(
        'update:ubos',
        state.uboForms.map((uboForm) => ({
          ...uboForm.ubo,
          ...toDataModel(uboForm.form),
        }))
      );
      setTimeout(() => {
        state.editing = false;
        state.showErrorMessage = false;
      });
    });
}

function uboContainsFile(ubo: UnsubmittedUbo, category: FileCategory) {
  return !!ubo.documents?.find((f) => f.category === category);
}

const isDuplicateUboEmail = helpers.withParams({ type: 'isDuplicateUboEmail' }, (val: string) => {
  if (!val) return true;
  return state.uboForms.filter((form) => form.form.email === val).length <= 1;
});

function uboEmailValidations() {
  return { isDuplicateUboEmail };
}

function resetUbos(loadFiles: boolean = false) {
  const ubos = cloneDeep(props.ubos) as UnsubmittedUbo[];
  const uboForms: UboForm[] = [];
  ubos.forEach((ubo) => {
    const uboForm = state.uboForms.find((f) => f.id === ubo.id);
    uboForms.push({
      ...uboForm,
      id: ubo.id!,
      form: makeMemberForm(true, uboEmailValidations(), false, true),
      validation: null,
      currentAddressValidation: null,
      previousAddressesValidation: null,
      ubo,
    });
    updateModel(uboForms[uboForms.length - 1].form, ubo);
    if (loadFiles) {
      requestManager
        .sameOrCancelAndNew(
          'getUboDocuments',
          services.compliance.getUboDocuments(authStore.loggedInIdentity!.client!.id, ubo.id!, {
            errors: { silent: true },
          })
        )
        .subscribe((response) => {
          Vue.set(ubo, 'documents', response);
        });
    }
  });
  state.uboForms = uboForms as any;
}

function onFileSelected(ubo: UnsubmittedUbo, fileData: UnsubmittedUboFile) {
  let readyToUpload = ubo.readyToUpload;
  if (!readyToUpload) {
    Vue.set(ubo, 'readyToUpload', []);
    readyToUpload = ubo.readyToUpload!;
  }
  let uploadFile = readyToUpload.find((i) => i.category === fileData.category);

  if (!uploadFile) {
    uploadFile = { ...fileData };
    readyToUpload.push(uploadFile);
  } else {
    uploadFile.file = fileData.file;
  }
}

function onUboFormEvent(event: FormEvent<any>) {
  if (event.event === 'form-field-set-value') {
    setState(event.model, 'errors', []);
  }
}

watch(
  () => props.ubos,
  () => resetUbos(true),
  { immediate: true }
);

watch(
  () => state.editing,
  () => {
    if (state.editing) {
      resetUbos();
    }
  }
);
</script>

<template>
  <div class="card-block" x-test-name="documents-review">
    <div class="card-review-header">
      <h2>Documents</h2>
      <div class="button-holder" v-if="isEditable">
        <VButton blurOnClick @click="state.editing = !state.editing" class="btn-stroked">
          {{ state.editing ? 'Cancel' : 'Edit' }}
        </VButton>
        <VButton blurOnClick @click="save" v-if="state.editing" :loading="requestManager.anyPending" class="ml-3">
          Save
        </VButton>
      </div>
    </div>

    <div v-show="!state.editing">
      <template v-if="isOnboardingDocumentsActive">
        <h3 class="mb-3">Verification of Authorised Signatory</h3>
        <InfoBlock :info="signatoryDocsBlock" />
        <h3 class="my-3">Company Documents</h3>
        <InfoBlock :info="companyDocsBlock" />
      </template>
      <h3 class="my-3">Ultimate Beneficial Owner Information</h3>
      <InfoBlock :info="uboInfoBlock" />
      <div v-for="(uboForm, index) in state.uboForms" :key="uboForm.id">
        <h3 class="my-3">UBO {{ index + 1 }}</h3>
        <InfoBlock :model="uboForm.ubo" :info="filteredUboBlock">
          <template #poa-value>
            {{ uboContainsFile(uboForm.ubo, ClientFileCategories.PROOF_OF_ADDRESS) ? 'Uploaded' : 'Not Uploaded' }}
          </template>
          <template #poi-value>
            {{ uboContainsFile(uboForm.ubo, ClientFileCategories.PHOTO_ID) ? 'Uploaded' : 'Not Uploaded' }}
          </template>
          <template #birthDate-value>
            {{ formatDate(uboForm.ubo.birthDate, 'dd MMM yyyy') }}
          </template>
          <template #ownershipPercentage-value>
            {{ uboForm.ubo.ownershipPercentage ? `${uboForm.ubo.ownershipPercentage}%` : '-' }}
          </template>
        </InfoBlock>
        <h3>Current Address</h3>
        <InfoBlock :model="uboForm.ubo.currentAddress" :info="uboAddressHistoryBlock(uboForm.ubo)">
          <template #countryCode-value>
            {{ countryNameFromCC(uboForm.ubo.currentAddress.countryCode) }}
          </template>
          <template #residingFrom-value>
            {{ formatDate(uboForm.ubo.currentAddress.residingFrom, 'MMM, yyyy') }}
          </template>
        </InfoBlock>
        <template v-if="uboForm.ubo.previousAddresses && uboForm.ubo.previousAddresses.length > 0">
          <h3>Previous {{ uboForm.ubo.previousAddresses.length > 1 ? 'Addresses' : 'Address' }}</h3>
          <div v-for="(previousAddress, index) in uboForm.ubo.previousAddresses" :key="index">
            <h4 class="mb-3">Previous Address N&deg;{{ index + 1 }}</h4>
            <InfoBlock :model="previousAddress" :info="uboAddressHistoryBlock(uboForm.ubo)">
              <template #countryCode-value>
                {{ countryNameFromCC(previousAddress.countryCode) }}
              </template>
              <template #residingFrom-value>
                {{
                  `${formatDate(previousAddress.residingFrom, 'MMM, yyyy')} - ${formatDate(
                    previousAddress.residingTo,
                    'MMM, yyyy'
                  )}`
                }}
              </template>
            </InfoBlock>
          </div>
        </template>
        <hr v-if="index < state.uboForms.length - 1" />
      </div>
    </div>

    <div v-show="state.editing">
      <template v-if="isOnboardingDocumentsActive">
        <h3 class="file-uploader-section-title mt-0 col col-12">Verification of Authorised Signatory</h3>
        <ClientDocumentsUploader
          fileClass="col col-6"
          :files="documents"
          :categories="[ClientFileCategories.PHOTO_ID, ClientFileCategories.PROOF_OF_ADDRESS]"
          @update:files="emit('update:documents', $event)"
        />
        <h3 class="file-uploader-section-title mt-0 col col-12">Company Documents</h3>
        <ClientDocumentsUploader
          fileClass="col col-6"
          :files="documents"
          :categories="[
            ClientFileCategories.INCORPORATED_DOCUMENT,
            ClientFileCategories.FINANCIAL_STATEMENT,
            ClientFileCategories.SAMPLE_INVOICE,
          ]"
          @update:files="emit('update:documents', $event)"
        />
      </template>
      <AccordionBox
        class="ubo-wrapper"
        v-for="(uboForm, index) in state.uboForms"
        :key="`${uboForm.id}`"
        :title="`UBO ${index + 1}`"
        :open="index === 0"
      >
        <ValidatedForm :fm="uboForm.form" :validation.sync="uboForm.validation" @form-event="onUboFormEvent">
          <template #memberForm.ownershipPercentage:append>
            <BInputGroupText class="plus-minus">
              <div class="percent">%</div>
            </BInputGroupText>
          </template>
        </ValidatedForm>
        <h3 class="my-3">Current Address</h3>
        <RegistrationAddressForm
          use-three-columns
          :address.sync="uboForm.ubo.currentAddress"
          :validation.sync="uboForm.currentAddressValidation"
          :isCountryEditable="true"
          :apiErrors="uboAddressApiErrors[index]?.fields?.currentAddress"
        />
        <ReviewScreenPreviousAddressesForms
          use-three-columns
          class="mb-4"
          previous-addresses-parent-class=""
          v-if="isUboAUKResident(uboForm.ubo)"
          :previousAddresses.sync="uboForm.ubo.previousAddresses"
          :validation.sync="uboForm.previousAddressesValidation"
          :currentAddressResidenceDate.sync="uboForm.ubo.currentAddress.residingFrom"
          :showErrorMessage="state.showErrorMessage"
          :isUboLabel="true"
          :apiErrors="uboAddressApiErrors[index]"
        />
        <UboDocumentsEditor
          v-if="isOnboardingDocumentsActive"
          :ubo="uboForm.ubo"
          :files="uboForm.ubo.documents"
          @file-selected="onFileSelected(uboForm.ubo, $event)"
          :auto-upload="false"
        />
      </AccordionBox>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-uploader-section-title {
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
}

.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: $font-size-base;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }

  .button-holder {
    margin-left: auto;
    display: inline-flex;
  }
  .btn {
    min-width: 7rem;
  }
}
::v-deep {
  label {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }
  .documents-wrapper {
    .company-documents {
      margin: 2rem 0 !important;
    }
    h3 {
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      margin-top: 0 !important;
    }
    .files-list-element {
      margin-top: 0 !important;
    }
  }

  .ubo-wrapper {
    margin-bottom: calc($padded-space / 1.5);
    &:not(:last-child) {
      border-bottom: 1px solid;
      @include themedBorderColor($color-primary);
    }

    .header,
    .body {
      margin-bottom: calc($padded-space / 1.5);
      font-weight: $font-weight-semibold;
    }
  }
}
</style>
