<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { Beneficiary, HistoricalTransaction, PaymentReport, Trade, TransactionReason } from 'ah-api-gateways';
import TradeTransactionBankInfo from './info/TradeTransactionBankInfo.vue';
import TradeTransactionInfo from './info/TradeTransactionInfo.vue';
import { reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhWalletsState } from '..';

const props = withDefaults(
  defineProps<{
    trade: Trade;
    buttonTitle?: string;
    title?: string;
    payment: PaymentReport;
  }>(),
  {
    buttonTitle: 'View Related Payment',
    title: 'Related Payment',
  }
);

const state = reactive<{
  transaction: HistoricalTransaction | null;
  beneficiary: Beneficiary | null;
}>({
  transaction: null,
  beneficiary: null,
});

const modal = ref<InstanceType<typeof BModal> | null>(null);

const requestManager = useRequestManager();

const walletState = useAhWalletsState();

watch(
  () => props.trade,
  () => {
    requestManager.manager
      .sameOrCancelAndNew(
        'loadTransaction',
        walletState.services.wallet.listWalletTransactions({
          sort: 'createdAt',
          sortDirection: 'DESC',
          pageSize: 1,
          tradeId: props.trade.id,
          reason: TransactionReason.TRADE_BUY_CURRENCY_CLIENT_TO_BENEFICIARY,
        })
      )
      .subscribe((res) => (state.transaction = res.list[0]));
  },
  { immediate: true }
);

watch(
  () => props.payment,
  () => {
    requestManager.manager
      .sameOrCancelAndNew(
        'loadBeneficiary',
        walletState.services.beneficiary.getBeneficiary(props.payment.beneficiaryId!)
      )
      .subscribe((res) => (state.beneficiary = res));
  },
  { immediate: true }
);

function show() {
  modal.value?.show();
}

function hide() {
  requestManager.manager.cancel('loadTransaction');
  requestManager.manager.cancel('loadBeneficiary');
  modal.value?.hide();
}
</script>

<template>
  <span>
    <BModal :title="title" modal-class="side-modal transaction-details-modal" ref="modal" hide-footer>
      <BoxGrid alignV="start">
        <BoxGridBlock :loadingOverlayProps="{ state: requestManager.manager.currentStates }" cols="12">
          <h3>Beneficiary Details</h3>
          <TradeTransactionInfo :trade="trade" :payment="payment" />
        </BoxGridBlock>
        <BoxGridBlock :loadingOverlayProps="{ state: requestManager.manager.currentStates }" cols="12">
          <TradeTransactionBankInfo v-if="state.beneficiary" :beneficiary="state.beneficiary" />
        </BoxGridBlock>
        <BoxGridItem class="my-3">
          <VButton @click="hide"> Close </VButton>
        </BoxGridItem>
      </BoxGrid>
    </BModal>
    <slot v-bind="{ show }">
      <VButton
        blurOnClick
        :disabled="requestManager.manager.anyPending"
        :loading="requestManager.manager.anyPending"
        @click="show"
      >
        {{ buttonTitle }}
      </VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep {
  .transaction-details-modal .modal-dialog {
    width: 40em;
  }
}
</style>
