<script setup lang="ts">
import { isArray } from 'lodash';
import { PropType } from 'vue';

export type InformationBlock = {
  label: string;
  key: string;
  value?: any;
  cols?: number;
  labelClass?: string;
  valueClass?: string;
  colClass?: string;
};

defineProps({
  info: {
    type: Array as PropType<InformationBlock[]>,
    required: true,
  },
  model: {
    type: Object as PropType<any>,
  },
  emptyValue: {
    type: String,
    default: '',
  },
});

function isAnArray(value?: any) {
  if (value) return isArray(value);
}
</script>

<template>
  <BRow>
    <BCol :class="block.colClass" :cols="block.cols || 12" v-for="block in info" :key="block.key">
      <slot :name="block.key" v-bind="{ model }">
        <div>
          <label :class="block.labelClass" :for="block.key">
            <slot :name="`${block.key}-label`" v-bind="{ model }"> {{ block.label }} </slot>
          </label>
        </div>
        <div :class="block.valueClass" class="info-value" :id="block.key">
          <slot :name="`${block.key}-value`" v-bind="{ model, value: block.value || model[block.key] }">
            <template v-if="isAnArray(block.value || model[block.key])">
              <div v-if="(block.value || model[block.key]).length === 0">None</div>
              <template v-else>
                <div v-for="(value, index) in block.value || model[block.key]" :key="`${model[block.key]}-${index}`">
                  {{ value }}
                </div>
              </template>
            </template>
            <template v-else>
              {{ block.value || model[block.key] || emptyValue }}
            </template>
          </slot>
        </div>
      </slot>
    </BCol>
  </BRow>
</template>

<style lang="scss" scoped>
.info-value {
  margin-bottom: 1rem;
  opacity: 0.8;
  word-wrap: break-word;
}
</style>
