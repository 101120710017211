import { render, staticRenderFns } from "./ActivitySingleClientDashboardView.vue?vue&type=template&id=18a791ed&scoped=true"
import script from "./ActivitySingleClientDashboardView.vue?vue&type=script&setup=true&lang=ts"
export * from "./ActivitySingleClientDashboardView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ActivitySingleClientDashboardView.vue?vue&type=style&index=0&id=18a791ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18a791ed",
  null
  
)

export default component.exports