/* eslint-disable no-param-reassign */
import _Vue, { PluginObject } from 'vue';
import { generateUUID } from '../helpers/uuid';
import ToastHolder from './ToastHolder.vue';
import ToastComponent from './ToastComponent.vue';

export interface ToastPluginOptions {
  toastComponent: any; // FIXME add typings
}
export interface ToastAction {
  title: string;
  method: () => void | Promise<any>;
  class: string | string[] | { [key: string]: boolean };
}

export type ToastType = 'success' | 'warning' | 'danger' | 'info';

export interface ToastComponentProps {
  title?: string;
  message?: string;
  isHtml?: boolean;
  toastType?: ToastType;
  dismiss?: boolean;
  group?: string;
  id?: string;
  name?: string;
  position?: string;
  actions?: ToastAction[];
}

export interface ToastOptions {
  noAutoHide?: boolean;
  autoHideDelay?: number;
}

export type ToastProps = ToastComponentProps & {
  hideTimeout?: number;
  closePromise: Promise<void>;
  onClose: () => void;
};

export interface Toast {
  /**
   * Show a toast with success style
   * If an id is sent in ToastComponentsProps and there is a toast with same id, the toast will be updated otherwise a new one will be created
   *
   * @param message
   * @param options
   */
  show(message: string, toastComponentProps?: ToastComponentProps, toastOptions?: ToastOptions): Promise<void>;

  /**
   * Show a toast with success style
   * If an id is sent in ToastComponentsProps and there is a toast with same id, the toast will be updated otherwise a new one will be created
   *
   * @param message
   * @param options
   */
  success(message: string, toastComponentProps?: ToastComponentProps, toastOptions?: ToastOptions): Promise<void>;

  /**
   * Show a toast with info style
   * If an id is sent in ToastComponentsProps and there is a toast with same id, the toast will be updated otherwise a new one will be created
   *
   * @param message
   * @param options
   */
  info(message: string, toastComponentProps?: ToastComponentProps, toastOptions?: ToastOptions): Promise<void>;

  /**
   * Show a toast with error style
   * If an id is sent in ToastComponentsProps and there is a toast with same id, the toast will be updated otherwise a new one will be created
   *
   * @param message
   * @param options
   */
  error(message: string, toastComponentProps?: ToastComponentProps, toastOptions?: ToastOptions): Promise<void>;

  /**
   * Clear all toasts
   */
  clear(group?: string): void;
  /**
   * Remove a single toast
   */
  remove(id: string, ignoreProps?: boolean): void;
}

export default {
  install: function install(Vue: typeof _Vue, options?: ToastPluginOptions) {
    function getToast(id: string) {
      return (toastsHolder.$props.toastData as ToastProps[]).find((toast: ToastComponentProps) => toast.id === id);
    }

    function editToast(toast: ToastProps, componentProps: ToastComponentProps, toastOptions?: ToastOptions) {
      toast.title = componentProps.title;
      toast.message = componentProps.message;
      if (componentProps.toastType) {
        toast.toastType = componentProps.toastType;
      }
      toast.actions = componentProps.actions;
      setToastTimeout(toastOptions, toast);

      // Force a rerender of the toasts, as updating the toast directly won't trigger it
      toastsHolder.$props.toastData = [...toastsHolder.$props.toastData];
      return toast.closePromise;
    }

    function createToast(componentProps: ToastComponentProps, toastOptions?: ToastOptions) {
      const id = componentProps.id || generateUUID();

      let onClose!: () => void;

      const closePromise = new Promise<void>((resolve) => {
        onClose = resolve;
      });

      const toast: ToastProps = { id, onClose: () => onClose(), closePromise, ...componentProps };

      toastsHolder.$props.toastData = [...toastsHolder.$props.toastData, toast];

      const newToast = toastsHolder.$props.toastData.find((toast: ToastComponentProps) => toast.id === id);
      if (newToast) setToastTimeout(toastOptions, newToast);

      return toast.closePromise;
    }

    function toast(componentProps: ToastComponentProps, toastOptions?: ToastOptions) {
      if (componentProps.id) {
        const toast = getToast(componentProps.id);
        if (toast) {
          return editToast(toast, componentProps, toastOptions);
        }
      }
      return createToast(componentProps, toastOptions);
    }

    function hide(id?: string) {
      const toastData: ToastProps[] = toastsHolder.$props.toastData || [];
      if (!id) {
        toastData.forEach(hideToast);
        toastsHolder.$props.toastData = [];
      } else {
        const toastIndex = toastData.findIndex((toast: ToastComponentProps) => toast.id === id);

        if (toastIndex >= 0) {
          hideToast(toastData[toastIndex]);
          toastData.splice(toastIndex, 1);
        }
      }
      toastsHolder.$props.toastData = [...toastsHolder.$props.toastData];
    }

    function hideToast(toast: ToastProps) {
      const onClose = toast?.onClose;
      window.setTimeout(onClose);
      if (toast.hideTimeout) {
        clearTimeout(toast.hideTimeout);
      }
    }

    function setToastTimeout(toastOptions: ToastOptions = {}, toast: ToastProps) {
      if (toast.hideTimeout) {
        clearTimeout(toast.hideTimeout);
      }
      if (!toastOptions || !toastOptions.noAutoHide) {
        toast.hideTimeout = window.setTimeout(() => {
          hide(toast.id);
        }, toastOptions?.autoHideDelay || 5000);
      }
    }

    toastObj = {
      show(message: string, componentProps: ToastComponentProps = {}, toastOptions: ToastOptions) {
        return toast(
          {
            message,
            ...componentProps,
          },
          toastOptions
        );
      },
      error(message: string, componentProps: ToastComponentProps = {}, toastOptions: ToastOptions) {
        return toast(
          {
            message,
            toastType: 'danger',
            title: 'Error',
            ...componentProps,
          },
          toastOptions
        );
      },
      info(message: string, componentProps: ToastComponentProps = {}, toastOptions: ToastOptions) {
        return toast(
          {
            message,
            toastType: 'info',
            title: 'Info',
            ...componentProps,
          },
          toastOptions
        );
      },
      success(message: string, componentProps: ToastComponentProps = {}, toastOptions: ToastOptions) {
        return toast(
          {
            message,
            toastType: 'success',
            title: 'Success',
            ...componentProps,
          },
          toastOptions
        );
      },
      clear(group?: string) {
        if (!group) {
          hide();
        } else {
          toastsHolder.$props.toastData
            .filter((t: ToastComponentProps) => t.group === group)
            .forEach((t: ToastComponentProps) => {
              hide(t.id);
            });
        }
      },
      remove(id: string) {
        hide(id);
      },
    };

    Vue.toast = toastObj;
    Vue.prototype.$toast = toastObj;

    const ToastHolderComponent = Vue.extend(ToastHolder);
    const toastsHolder = new ToastHolderComponent({
      propsData: {
        toastData: [],
        toastComponent: options?.toastComponent ?? ToastComponent,
      },
    });
    toastsHolder.$mount();
    document.body.appendChild(toastsHolder.$el);
  },
} as PluginObject<{ toastComponentProps?: ToastComponentProps; toastOptions?: ToastOptions }>;

let toastObj: Toast | null = null;

export function useToast(): Toast {
  if (!toastObj) {
    throw 'Toast used before plugin setup!';
  }
  return toastObj;
}
