<script setup lang="ts">
import BankingProviderCard from '@/app/components/onboarding/BankingProviderCard.vue';
import CurrencyCloudIcon from '@/app/icons/svgs/CurrencyCloudIcon.vue';
import NatWestIcon from '@/app/icons/svgs/NatWestIcon.vue';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { BankType } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref } from 'vue';

const bankingProviderData = [
  {
    name: 'NatWest',
    value: BankType.NATWEST,
    icon: { component: NatWestIcon, size: '19px' },
    advantages: [
      'Your account will be powered by one of the UK’s leading banks, regulated by the PRA in adherence with the highest regulatory standards to ensure a secure and compliant environment for pooled accounts.',
      'NatWest provides ALT21 with local payment rails in GBP, CAD, and USD, leading to more efficient and cost beneficial payments.',
      'Unlike traditional FX brokers, ALT21 automates the entire process from start to finish, even with our pooled accounts.',
      'NatWest handles one in four daily payments in the UK and ranks among the top 25% of SWIFT processors globally, ensuring extensive worldwide coverage.',
    ],
    disadvantages: ['Virtual IBANs are currently unavailable with NatWest.'],
  },
  {
    name: 'CurrencyCloud',
    value: BankType.CURRENCY_CLOUD,
    icon: { component: CurrencyCloudIcon, size: '30px' },
    advantages: [
      'CurrencyCloud is regulated by the FCA and adheres to strict security protocols specifically designed for cross-border payments.',
      'Benefit from individual Virtual IBANs for each of your multi-currency wallets, in your own company’s name.',
    ],
    disadvantages: [
      'Some banks disfavour VIBANs, which can delay payment efficacy.',
      'Multi-currency IBANs use the "GB" country code, which can lead to difficulties with local correspondent banks.',
      'As CurrencyCloud is a BaaS provider rather than the correspondent bank, the third layer of transaction screening layer may sometimes cause payment delays.',
    ],
  },
];

const selectedProvider = ref<string>();

const authStore = useAuthStore();

const services = getServices();

const sessionIndividualName = computed(() => authStore.loggedInIdentity?.firstName);

const requestManager = useRequestManager().manager;

const emit = defineEmits({
  proceed: () => true,
});

const selected = (selectedValue: string) => {
  selectedProvider.value = selectedValue;
};

const finishOnboardingRequest = () => {
  requestManager
    .currentOrNew(
      'submitRegistration',
      services.compliance.submitBankingProvider(authStore.loggedInIdentity!.client!.id, {
        preferredBankingProvider: selectedProvider.value,
      })
    )
    .subscribe(() => emit('proceed'));
};
</script>

<template>
  <div class="bank-providers">
    <div class="providers-title mb-5">
      <h1>You're nearly finished, {{ sessionIndividualName }}</h1>
      <p class="subtitle mb-0">
        To complete your account setup, please choose a banking provider from the options below. <br />
        This cannot be changed later, so please select your provider carefully.
      </p>
    </div>
    <div class="provider-cards">
      <div v-for="(provider, index) in bankingProviderData" :key="index" class="provider-card-holder">
        <BankingProviderCard
          class="provider-card"
          @selected="selected"
          :title="provider.name"
          :value="provider.value"
          :advantages="provider.advantages"
          :disadvantages="provider.disadvantages"
          :selected="provider.value === selectedProvider"
          :icon="provider.icon"
        >
          <template #banking-icon>
            <component
              class="mr-1 banking-icon"
              :is="provider.icon.component"
              :style="`font-size:${provider.icon.size}; height: auto;`"
            />
          </template>
        </BankingProviderCard>
      </div>
    </div>
    <div class="pb-5 text-center">
      <VButton class="btn btn-primary" @click="() => finishOnboardingRequest()" :disabled="!selectedProvider">
        Confirm
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.providers-title {
  h1 {
    @include themedTextColor($common-color-widget-cyan, $common-color-light-cyan);
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
  }

  .subtitle {
    @include themedTextColor($xo-color-dark-gray, $common-color-white);
    font-size: 14px;
  }
}

.provider-cards {
  display: flex;
  flex-wrap: wrap;

  .provider-card-holder {
    flex-basis: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: stretch;
    margin-bottom: 3em;

    .provider-card {
      flex-grow: 1;
    }

    @include desktop {
      flex-basis: 50%;
      padding: 0 2em;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}
</style>
