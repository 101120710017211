<script setup lang="ts">
import { dateField, radioField } from 'ah-common-lib/src/form/models';
import { minDate as minDateFn, ifTest, date } from 'ah-common-lib/src/form/validators';
import { getChildModel, setState, makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { required } from '@vuelidate/validators';
import { format } from 'date-fns';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { computed, reactive, watch, onMounted } from 'vue';
import { isValid } from 'date-fns';
import AggregatedLimitErrorMessage from 'ah-payments/src/components/AggregatedLimitErrorMessage.vue';
import { TradeDestination } from 'ah-trades/src/components/forms/tradeDestination';
import { TradeFundsDestination } from 'ah-trades/src/models/trade';
import { AmountType, Beneficiary, QuotePriceResponse } from 'ah-api-gateways';
import { useAggregatedPaymentLimitChecker } from 'ah-trades/src/composables/aggregatedPaymentLimitChecker';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useAhWalletsState } from '..';

enum PaymentType {
  IMMEDIATE = 'IMMEDIATE',
  SCHEDULED = 'SCHEDULED',
}

const props = withDefaults(
  defineProps<{
    beneficiary?: Beneficiary;
    /**
     * Possible amend an existing scheduled payment schedule
     */
    // scheduledDate?: Date;
    /**
     * set if not possible to change form state, default set to false
     */
    readonly?: string | boolean;
    /**
     * Validation param
     *
     * if set the minimum date possible will be minDate as minDateFn, otherwise
     * tomorrow will be assumed
     */
    minDate?: Date;
    tradeDestination?: TradeDestination | null;
    tradePrice?: QuotePriceResponse | null;
  }>(),
  {
    readonly: false,
    minDate: () => new Date(),
  }
);

const emit = defineEmits<{
  /*
   * Emitted on form event, updating `scheduledDate`. If none is selected, `null` will be emmitted. Can be used with v-model
   */
  (e: 'update:tradeDestination', value: TradeDestination): void;
}>();

const onBehalfOfClient = useOnBehalfOf();
const oboClientId = computed(() => onBehalfOfClient.value?.id);
const walletState = useAhWalletsState();

const buyCcy = computed(() => {
  if (!props.tradePrice) return;
  const price = props.tradePrice;

  return price.ccy1.amountType === AmountType.BUY ? price.ccy1 : price.ccy2;
});

const {
  aggregatedLimitForCurrency,
  aggregatedPaymentReviewRoute,
  isAggregatedLimitReached,
  loadAggregatedLimit,
  requestManager,
} = useAggregatedPaymentLimitChecker({
  services: walletState.services,
  data: {
    clientId: computed(
      () => onBehalfOfClient.value?.id ?? walletState.store.useAuthStore().loggedInIdentity?.client?.id
    ),
    oboClientId,
    paymentAmount: computed(() => buyCcy.value?.clientAmount),
    paymentCurrency: computed(() => buyCcy.value?.currency),
  },
});

const schedulePaymentDateDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'schedulePaymentDate',
    fieldType: 'form',
    fields: [
      dateField(
        'scheduledDate',
        '',
        {
          errorMessages: {
            required: 'Date is required',
            minDate: () => 'Must be after ' + format(props.minDate, 'dd-MM-yyyy'),
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(required, (a) => !(a instanceof Date)),
          minDate: minDateFn(() => props.minDate),
        }
      ),
    ],
  }),
  validation: null,
});

const schedulePaymentDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'schedulePayment',
    fieldType: 'form',
    fields: [
      radioField(
        'schedule',
        'Schedule payment',
        [
          { label: 'Immediate', value: PaymentType.IMMEDIATE },
          { label: 'Select Date', value: PaymentType.SCHEDULED },
        ],
        { required: true, clearable: false },
        {}
      ),
    ],
  }),
  validation: null,
});

const scheduleOptionNote = computed(() => {
  return (value: PaymentType) => {
    if (value === PaymentType.IMMEDIATE) return 'Payment will be processed on next available business day.';
    else return 'Payment will be scheduled on the selected date.';
  };
});

const isReadonly = computed(() => props.readonly !== false);

const valid = computed(() => {
  if (schedulePaymentDateDef.validation && schedulePaymentDef.form.schedule !== PaymentType.IMMEDIATE) {
    return !schedulePaymentDateDef.validation.$invalid;
  }
  return true;
});

watch(
  () => props.tradeDestination?.executionDate,
  () => {
    if (props.tradeDestination?.executionDate) {
      schedulePaymentDef.form.schedule = PaymentType.SCHEDULED;
      setTimeout(() => {
        schedulePaymentDateDef.form.scheduledDate = props.tradeDestination?.executionDate;
      }, 0);
    } else {
      schedulePaymentDef.form.schedule = PaymentType.IMMEDIATE;
    }
  },
  { immediate: true }
);

watch(
  () => props.readonly,
  () => {
    const scheduleForm = getChildModel(schedulePaymentDef.form, 'schedule')!;
    const scheduleDateForm = getChildModel(schedulePaymentDateDef.form, 'scheduledDate')!;
    setState(scheduleForm, 'readonly', isReadonly.value);
    setState(scheduleDateForm, 'readonly', isReadonly.value);
    if (isReadonly.value) {
      schedulePaymentDef.form.schedule = PaymentType.IMMEDIATE;
    }
  },
  { immediate: true }
);

watch(() => requestManager.anyPending, checkAndEmitDestination);

watch(() => props.tradePrice, loadAggregatedLimit);

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    checkAndEmitDestination();
  }
}

function checkAndEmitDestination() {
  if (!isReadonly.value) {
    let scheduledDate: Date | null;
    const isValidDate = isValid(new Date(schedulePaymentDateDef.form.scheduledDate));
    const isFormValid =
      (schedulePaymentDef.form.schedule === PaymentType.IMMEDIATE || isValidDate) &&
      !(isAggregatedLimitReached.value && schedulePaymentDef.form.schedule !== PaymentType.IMMEDIATE);

    if (schedulePaymentDef.form.schedule === PaymentType.IMMEDIATE || !isValidDate) {
      scheduledDate = null;
    } else {
      scheduledDate = new Date(schedulePaymentDateDef.form.scheduledDate);
    }

    emit('update:tradeDestination', {
      ...props.tradeDestination,
      dirty: true,
      destination: TradeFundsDestination.SEND,
      beneficiary: props.beneficiary ?? null,
      executionDate: scheduledDate?.toISOString() ?? undefined,
      valid: isFormValid && !requestManager.anyPending,
    });
  }
}

function touchForms() {
  if (schedulePaymentDateDef.validation) {
    submitForm(schedulePaymentDateDef.validation);
  }
}

onMounted(checkAndEmitDestination);

defineExpose({ valid: valid.value, touchForms });
</script>

<template>
  <ValidatedForm class="schedule-payment-form" :fm="schedulePaymentDef.form" @form-event="onFormEvent">
    <template #schedulePayment.schedule:option="{ option }">
      <label class="d-inline-flex">
        <div>{{ option.label }}</div>
        <ValidatedForm
          class="ml-3 mt-n2 mb-n4"
          v-if="option.value === 'SCHEDULED' && schedulePaymentDef.form.schedule === option.value"
          :fm="schedulePaymentDateDef.form"
          :validation.sync="schedulePaymentDateDef.validation"
          @form-event="onFormEvent"
        />
      </label>
      <div
        class="text-secondary text-small mt-2 schedule-payment-description"
        v-if="schedulePaymentDef.form.schedule === option.value"
      >
        {{ scheduleOptionNote(option.value) }}
      </div>
    </template>
    <AggregatedLimitErrorMessage
      v-if="
        isAggregatedLimitReached &&
        aggregatedLimitForCurrency &&
        schedulePaymentDef.form.schedule !== PaymentType.IMMEDIATE
      "
      :aggregatedLimitForCurrency="aggregatedLimitForCurrency"
    >
      <template #route>
        <span v-if="oboClientId">please go to the payments page</span>
        <RouterLink v-else :to="aggregatedPaymentReviewRoute">please click here</RouterLink>
      </template>
    </AggregatedLimitErrorMessage>
  </ValidatedForm>
</template>

<style lang="scss" scoped>
::v-deep .schedule-payment-description {
  margin-left: -1.7rem !important;
}
</style>
