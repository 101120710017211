import { FilledChartColorOptions } from 'ah-reports';
import { getHSLA } from 'ah-theme';

/**
 * FIXME this colors will live here for now, since they are only used in here
 * and we don't want those as theme colors. In the future we will move this into a proper file.
 */

export const darkGreen: string = getHSLA('', { hue: '181.41', luminance: '23.33%', saturation: '71.43%' });

export const lightGreen: string = getHSLA('', { hue: '163.8', luminance: '61.75%', saturation: '83.5%' });

const highlightGreen: string = getHSLA('', { hue: '168', luminance: '86.7%', saturation: '73.5%' });

const darkYellow: string = getHSLA('', { hue: '36', luminance: '50%', saturation: '100%' });

const lightYellow: string = getHSLA('', { hue: '36', luminance: '85%', saturation: '100%' });

const darkRed: string = getHSLA('', { hue: '344', luminance: '49%', saturation: '79%' });

const lightRed: string = getHSLA('', { hue: '344', luminance: '85%', saturation: '77%' });

const PROFILE_USAGE_CHART_COLOR_PAIRS: Record<string, string[]> = {
  zeroPercent: [highlightGreen],
  belowEightyPercent: [darkGreen, lightGreen],
  aboveEightyPercent: [darkYellow, lightYellow],
  aboveNinetyPercent: [darkRed, lightRed],
};

function getChartColorNamesForProfileUsageLevel(profileUsage: number): string[] {
  switch (true) {
    case profileUsage === 0:
      return PROFILE_USAGE_CHART_COLOR_PAIRS.zeroPercent;
    case profileUsage >= 0.9:
      return PROFILE_USAGE_CHART_COLOR_PAIRS.aboveNinetyPercent;
    case profileUsage >= 0.8:
      return PROFILE_USAGE_CHART_COLOR_PAIRS.aboveEightyPercent;
    case profileUsage > 0:
      return PROFILE_USAGE_CHART_COLOR_PAIRS.belowEightyPercent;
    default:
      return PROFILE_USAGE_CHART_COLOR_PAIRS.zeroPercent;
  }
}

export function getChartColorsForProfileUsageLevel(profileUsage: number): FilledChartColorOptions[] {
  return getChartColorNamesForProfileUsageLevel(profileUsage).map((color) => ({
    backgroundColor: color,
    borderColor: color,
    hoverBackgroundColor: color,
    hoverBorderColor: color,
  }));
}
