/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import get from 'lodash/get';
import { Observable } from 'rxjs';

export type RequestState = 'idle' | 'pending' | 'success' | 'error' | 'none';

function isActive(vm: any) {
  return !vm._isDestroyed && !vm._isBeingDestroyed;
}

function vueSetterFunction(vm: Vue, key: string) {
  return (val: RequestState) => {
    let obj: any = vm;
    if (key.lastIndexOf('.') > -1) {
      obj = get(obj, key.substr(0, key.lastIndexOf('.')));
    }
    if (obj && isActive(vm)) {
      vm.$set(obj, key.substr(key.lastIndexOf('.') + 1), val);
    }
  };
}

export function manageRxState<T>(valueSetterFn: (val: RequestState) => void, req: Observable<T>) {
  return new Observable<T>((observer) => {
    valueSetterFn('pending');
    const subscription = req.subscribe({
      next(value) {
        valueSetterFn('pending');
        observer.next(value);
      },
      error(err) {
        valueSetterFn('error');
        observer.error(err);
      },
      complete() {
        valueSetterFn('success');
        observer.complete();
      },
    });
    return () => {
      valueSetterFn('idle');
      subscription.unsubscribe();
    };
  });
}

export function manageVueRxState<T>(vm: Vue, key: string, req: Observable<T>) {
  return manageRxState(vueSetterFunction(vm, key), req);
}

export function managePromiseState<T>(valueSetterFn: (val: RequestState) => void, req: Promise<T>) {
  valueSetterFn('pending');

  req.then(
    () => valueSetterFn('success'),
    () => valueSetterFn('error')
  );
  return req;
}

export function manageVuePromiseState<T>(vm: Vue, key: string, req: Promise<T>) {
  return managePromiseState(vueSetterFunction(vm, key), req);
}
