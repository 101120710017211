<script lang="ts">
import { format } from 'date-fns';
import { onBeforeUnmount } from 'vue';
import { useToast } from 'ah-common-lib/src/toast';

export default {
  setup() {
    const toast = useToast();

    const keyStrokes = new Map<string, boolean>();

    const onkeydown = (e: KeyboardEvent) => {
      if (e.target === document.body) {
        if (keyStrokes.get(e.key)) {
          return;
        }
        keyStrokes.set(e.key, true);
        if (['p', 'o', 'i'].reduce((v, c) => v && !!keyStrokes.get(c), true)) {
          toast.clear('appInfo');
          toast.info(
            `<br/><b>App version:</b>
              <br/>
              ${window.VUE_APP_VERSION}
              <br/>
              <b>Commit hash:</b>
              <br/>
              <p style="line-break: anywhere;">${window.VUE_APP_COMMIT_HASH}</p>
              <br/>
              <b>Build date & time:</b>
              <br/>
              ${format(new Date(window.VUE_APP_BUILD_TIME), 'dd/MM/yyyy HH:mm:ss')}`,
            {
              group: 'appInfo',
              isHtml: true,
            },
            {
              noAutoHide: true,
            }
          );
        }
      }
    };

    window.addEventListener('keydown', onkeydown);

    const onkeyup = (e: KeyboardEvent) => {
      keyStrokes.set(e.key, false);
    };

    window.addEventListener('keyup', onkeyup);

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onkeydown);
      window.removeEventListener('keyup', onkeyup);
    });

    return () => null;
  },
};
</script>
