<script lang="ts" setup>
import {
  PaginatedQuery,
  Beneficiary,
  beneficiaryStatusLabels,
  beneficiaryName,
  BeneficiaryCategory,
  BeneficiaryStatus,
} from 'ah-api-gateways';
import { beneficiaryTableFields } from '../models/beneficiaryTableFields';
import { tap } from 'rxjs/operators';
import { PaginatedTable } from 'ah-common-lib/src/common/components';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { PropType, computed, ref, watch } from 'vue';
import { useAhBeneficiariesState } from '..';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { TableFieldDefinition } from 'ah-common-lib/src/models';

const paginatedTable = ref<InstanceType<typeof PaginatedTable> | null>(null);

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: TableFieldDefinition[] }>,
    required: false,
    default: () => ({ tableFields: beneficiaryTableFields }),
  },
  withRowDetails: {
    type: Boolean,
    required: false,
  },
  allowedCurrencies: {
    type: Array as PropType<string[]>,
    required: false,
  },
  allowedBankingSchemes: {
    type: Array as PropType<string[]>,
    required: false,
  },
  ...defineUseManagedListingProps<Beneficiary>(),
});

const onBehalfOfClient = useOnBehalfOf();

const beneficiarieState = useAhBeneficiariesState();

interface BeneficiaryEmits extends UseManagedListingEmits<Beneficiary> {}

const emit = defineEmits<BeneficiaryEmits>();

const requestManager = useRequestManager();

const managedListing = useManagedListing<Beneficiary>({
  loadDataRequest(query: PaginatedQuery) {
    if (onBehalfOfClient.value) {
      query.category = BeneficiaryCategory.CLIENT_3RD_PARTY;
      query.clientId = onBehalfOfClient.value!.id;
    }
    return beneficiarieState.services.beneficiary.listBeneficiaries(query).pipe(
      tap(() => {
        setTimeout(openSelectedItem);
      })
    );
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

function openItem(item?: Beneficiary) {
  if (item && props.withRowDetails !== false) {
    const table = paginatedTable.value!.$refs.table as any;
    if (table) {
      table.toggleRowDetails(item, true);
    }
  }
}

const selectedItems = computed(() => {
  return managedListing.bindings.data?.list?.filter((i) => props.selectedItems?.includes(i.id));
});

function openSelectedItem() {
  if (selectedItems.value.length === 1) openItem(selectedItems.value[0]);
}

watch(() => props.selectedItems, openSelectedItem, { immediate: true });

function isBeneficiaryPending(beneficiary: Beneficiary) {
  return beneficiary.status === BeneficiaryStatus.PENDING;
}

// Using a similar approach to the "Row Selected" state to show a left aligned red bar on canceled/rejected trades
// Must use a td as the relative parent for this, and not the tr, due to a bug in Safari CSS
function tbodyTrClass(beneficiary: Beneficiary) {
  let out = '';
  if (beneficiary && isBeneficiaryPending(beneficiary)) {
    out = 'pending-row ';
  }
  if (
    (props.allowedCurrencies && !props.allowedCurrencies.includes(beneficiary?.currency)) ||
    (props.allowedBankingSchemes && !props.allowedBankingSchemes.includes(beneficiary.bankingScheme))
  ) {
    out += 'disabled';
  }

  return out;
}

function getBeneficiaryStatusLabels(item: any) {
  return beneficiaryStatusLabels[(item as Beneficiary).status];
}

function getCountryNameFromCC(item: any) {
  return countryNameFromCC(item.address.countryCode);
}

defineExpose({ loadData: managedListing.loadData, openItem });
</script>

<template>
  <PaginatedTable
    ref="paginatedTable"
    fixed
    hover
    nowrap
    animateCols
    class="beneficiaries-table"
    :rowClass="tbodyTrClass"
    items-label="beneficiaries"
    :withRowDetails="withRowDetails"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    :stacked="$ahBeneficiariesState.mediaQuery.is('smDown')"
  >
    <template #cell(name)="{ item }">
      {{ beneficiaryName(item) }}
    </template>
    <template #cell(status)="{ item }">
      {{ getBeneficiaryStatusLabels(item) || 'N/A' }}
    </template>
    <template #cell(countryCode)="{ item }">
      {{ getCountryNameFromCC(item) }}
    </template>

    <!-- row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
::v-deep .custom-control {
  padding-top: 0.5rem;

  .custom-control-input:checked ~ .custom-control-label {
    font-weight: 500;
  }
}

::v-deep .disabled {
  opacity: 0.2;
  pointer-events: none;
}
</style>
