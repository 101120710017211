import { TargetSelection } from '../targetSelection';
import { NotificationChannel, NotificationChannelSetting } from './notificationChannel';

export enum NotificationTimeOffsetUnit {
  NANOSECONDS = 'NANOSECONDS',
  MICROSECONDS = 'MICROSECONDS',
  MILLISECONDS = 'MILLISECONDS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export interface BaseNotificationSetting {
  id: string;
  channels: NotificationChannel[];
  to: TargetSelection[];
}

export interface NotificationSetting extends BaseNotificationSetting {
  type: string;
}

export interface TimedNotificationSetting extends NotificationSetting {
  timeUnit: NotificationTimeOffsetUnit;
  timeValue: number;
}

export interface NotificationSettingConfigGroup {
  name: string;
  index: number;
  notifications: NotificationSettingConfig[];
}

export interface NotificationSettingConfig {
  id?: string;
  type: string;
  entityType: string;
  editable: boolean;
  multiple: boolean;
  timed: boolean;
  defaultTimeUnit: NotificationTimeOffsetUnit | null;
  defaultTimeValue: number | null;
  sectionIndex: number;
  sectionName: string;
  index: number;
  name: string;
  description: string;
  multipleDescription?: string | null;
  channels: NotificationChannelSetting[];
}
