<script setup lang="ts">
import LoadingIcon from '../../icons/components/LoadingIcon.vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  loadingLabel: {
    type: String,
    default: '',
  },
  showLoadingSpinner: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  blurOnClick: {
    type: [Boolean, String],
    default: false,
  },
  roundedBorder: {
    type: Boolean,
    default: false,
  },
});

function onClick($event: MouseEvent) {
  if (props.blurOnClick !== false && $event && $event.currentTarget && ($event.currentTarget as HTMLElement).blur) {
    ($event.currentTarget as HTMLElement).blur();
  }
}
</script>

<template>
  <button :class="['btn', 'button', { loading, roundedBorder }]" @click="onClick" v-on="$listeners" v-bind="$attrs">
    <div class="label">
      <slot>
        {{ label }}
      </slot>
    </div>
    <div class="loading-wrapper">
      <slot name="loading">
        <span v-if="loadingLabel" class="loading-label">{{ loadingLabel }}</span>
        <LoadingIcon class="loading-icon" v-else-if="showLoadingSpinner" />
        <span v-else>{{ label }}</span>
      </slot>
    </div>
  </button>
</template>

<style lang="scss" scoped>
.button {
  position: relative;

  &.roundedBorder {
    border-radius: 6px;
  }

  &.loading {
    pointer-events: none;
    .label {
      opacity: 0;
    }

    .loading-wrapper {
      opacity: 1;
    }
  }
}

.loading-wrapper {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .loading-icon {
    height: 100%;
  }
}
</style>
