import { Form } from '../../interfaces';
import { emailField, textField } from '../../models';
import { formOverride } from '../../helpers';
import { url } from '../../validators';
import { addressForm } from '../general/addressForm';

export const partnerInformationForm = (addressRequired = true, overrides: Partial<Form> = {}): Form =>
  formOverride(
    {
      name: 'companyInfo',
      fieldType: 'form',
      state: {
        title: 'Partner information',
        showRequiredMarkers: true,
      },
      fields: [
        textField('name', 'Name'),
        textField('registrationNumber', 'Registration Number'),
        emailField('email', 'Contact Email', { required: false }),
        emailField('notificationsBccEmail', 'Repository Email', { required: false }),
        textField('website', 'Website', { required: false }, { url }),
        addressForm('address', 'Address', {}, addressRequired),
      ],
    },
    overrides
  );
