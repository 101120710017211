<script lang="ts" setup>
import { Trade, Individual } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useAhTradesState } from '../..';

const tradeState = useAhTradesState();

const props = defineProps<{
  trade: Trade;
}>();

const requestManager = useRequestManager({
  exposeToParent: ['getAgentInformation'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'getAgentInformation') {
      onTradeChange();
    }
  },
});

const state = reactive({
  oboCreator: null as Individual | null,
});

const needsIndividualInformation = computed(() => {
  return !props.trade.oboCreatedByName && !props.trade.oboCreatedByEmail;
});

const partnerName = computed(() => {
  return state.oboCreator?.partner?.name || state.oboCreator?.email || null;
});

function onTradeChange() {
  state.oboCreator = null;
  if (props.trade.oboCreatedByIndividualId && needsIndividualInformation.value) {
    requestManager.manager
      .currentOrNew(
        'getAgentInformation',
        tradeState.services.individual.getIndividual(props.trade.oboCreatedByIndividualId!, {
          errors: { silent: true },
        })
      )
      .subscribe((oboCreator) => {
        state.oboCreator = oboCreator;
      });
  }
}

watch(
  () => props.trade,
  () => {
    onTradeChange();
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="!needsIndividualInformation" class="h-100">
    <DataRow class="mb-2 trade-executed-by" label="Trade executed by" cols="6">
      {{ trade.oboCreatedByEmail }} <br />
      {{ trade.oboCreatedByName }}
    </DataRow>
  </div>
  <div v-else-if="state.oboCreator" class="h-100">
    <DataRow class="mb-2 trade-executed-by" label="Trade executed by" cols="6">
      {{ partnerName }} <br />
      {{ state.oboCreator.firstName }} {{ state.oboCreator.lastName }}
    </DataRow>
  </div>
</template>
