<script setup lang="ts">
import take from 'lodash/take';
import { makeBarChartOptions, makeBarChartDataset } from 'ah-reports';
import { TopAccountsCountReportChart } from 'ah-reports';
import { linesColorPrimary } from 'ah-reports';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';
import { getServices } from '@/app/services';

const TOP_DISPLAY_AMOUNT = 5;

const props = defineProps({
  fromDate: {
    type: Date,
    required: true,
  },
  toDate: {
    type: Date,
    required: true,
  },
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadData') {
      loadDates();
    }
  },
}).manager;

const services = getServices();

const options = ref(
  makeBarChartOptions(
    { horizontal: true, gridColor: linesColorPrimary },
    {
      scales: {
        y: {
          ticks: {
            autoSkip: false,
          },
        },
      },
    }
  )
);

const data = ref(makeBarChartDataset());

const accounts = ref<Array<{ clientId: string; clientName: string; tradedAmount: number }>>([]);

const dates = computed(() => ({
  from: props.fromDate,
  to: props.toDate,
}));

function loadDates() {
  requestManager
    .cancelAndNew('loadData', services.trade.getClientTopAccountsReport(props.fromDate, props.toDate))
    .subscribe((response) => {
      accounts.value = take(response.list, TOP_DISPLAY_AMOUNT);
      data.value.datasets![0].data! = accounts.value.map((i) => i.tradedAmount);
      data.value.labels = accounts.value.map((i) => i.clientName);

      // Force update by replacing prop reference entirely
      options.value = { ...options.value };
      data.value = { ...data.value };
    });
}

watch(dates, loadDates, { immediate: true });
</script>

<template>
  <div>
    <h3 class="mb-0">Top Accounts</h3>
    <TopAccountsCountReportChart :fromDate="fromDate" :toDate="toDate" />
    <div class="label text-center">Amounts in GBP</div>
    <div class="buttons text-right">
      <RouterLink class="button view-all transparent" :to="'/admin/activity/report'">
        <span> All Accounts </span>
        <IconArrowRight />
      </RouterLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 250px;
  padding-bottom: 33%;
}
.top-accounts-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  ol {
    width: 60%;
    list-style: none;
    counter-reset: li;
    li {
      counter-increment: li;
    }
    li::before {
      @include themedTextColor($color-primary);
      content: '.' counter(li);
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl;
    }
  }
  .top-accounts-item {
    display: grid;
    grid-template-columns: 10px 50% 50px auto;
    justify-items: start;
    align-items: center;
    .currency {
      justify-self: end;
    }
  }
}
.label {
  @include phablet {
    font-size: 10px;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    margin-left: 4rem;
  }
  @include tablet {
    margin-left: -2rem;
  }
  @include desktop {
    text-align: left !important;
    margin-left: 10rem;
  }
}
</style>
