import { Observable, Subject, Subscription } from 'rxjs';
import { multicast, refCount } from 'rxjs/operators';

import {
  PaginatedResponse,
  CallStatus,
  Call,
  PaginatedQuery,
  CallListFilters,
  CallTargetData,
  CallUpdateEvent,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions, SocketService, StompSocketTransport } from 'ah-requests';
import { CommNumber, CountryAvailableNumbers } from '../models/comms/commsNumber';

export class CommService extends HttpBasedService {
  private socket!: StompSocketTransport;

  constructor(http: HttpService, socketService: SocketService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'commService' } },
      },
    });
    this.socket = socketService.makeStompTransport();
  }

  private callUpdateSubs: { [callId: string]: Subscription } = {};

  private multicasts: { [callId: string]: Observable<CallUpdateEvent> } = {};

  public listCalls(query: PaginatedQuery<CallListFilters>, options?: HttpOptions<PaginatedResponse<Call>>) {
    return this.get<PaginatedResponse<Call>>(`${this.baseUrl}calls`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  /**
   * Watch for call connection updates
   *
   * Will connect automatically to the socket, if needed
   */
  public connectToCallUpdatesSocket(forceReconnection?: boolean) {
    this.socket.connect(`${this.baseUrl}calls/websocket`, forceReconnection);
    return this.socket;
  }

  /**
   * Watch for call connection updates
   *
   * Will connect automatically to the socket, if needed
   */
  public get callUpdatesSocket() {
    return this.socket;
  }

  /**
   * Disconnect from the notifications websocket
   */
  public disconnectFromCallUpdatesSocket() {
    this.socket.disconnect();
  }

  /**
   * Watch for call connection updates
   *
   * Will connect automatically to the socket, if needed
   */
  public watchUserConnectionUpdates(forceReconnection?: boolean) {
    this.connectToCallUpdatesSocket(forceReconnection);
    return this.socket.watchChannel<CallUpdateEvent>('/user/queue/connection-updates');
  }

  public startCall(target: CallTargetData) {
    return this.post<Call>(`${this.baseUrl}calls`, {
      callees: [target],
    });
  }

  public getCall(id: string) {
    return this.get<Call>(`${this.baseUrl}calls/${id}`);
  }

  public hangUpCall(id: string) {
    return this.put<void>(`${this.baseUrl}calls/${id}/hangup`);
  }

  public callStatus(id: string) {
    return this.get<{ callStatus: CallStatus }>(`${this.baseUrl}calls/status/${id}`);
  }

  public getOngoingCalls(accountId: string, options?: HttpOptions<PaginatedResponse<Call>>) {
    return this.get<PaginatedResponse<Call>>(`${this.baseUrl}calls/ongoing/${accountId}`, { options });
  }

  public redirectCall(id: string, callee: CallTargetData) {
    return this.post<Call>(`${this.baseUrl}calls/${id}/redirect`, { callee });
  }

  public callToConference(id: string, callees: CallTargetData[]) {
    return this.post<Call>(`${this.baseUrl}calls/${id}/conference`, {
      callees,
    });
  }

  public addConferenceParticipants(id: string, callees: CallTargetData[]) {
    return this.post<Call>(`${this.baseUrl}calls/${id}/participants`, {
      callees,
    });
  }

  public removeConferenceParticipants(id: string, connections: string[]) {
    return this.put<Call>(`${this.baseUrl}calls/${id}/participants/hangup`, {
      connections,
    });
  }

  public subCallStatusStream(id: string): Observable<CallUpdateEvent> {
    if (this.callUpdateSubs[id]) {
      return this.multicasts[id];
    }

    const multicasted = new Observable<CallUpdateEvent>((sub) => {
      let timestamp = '';
      this.callUpdateSubs[id] = this.socket.watchChannel<CallUpdateEvent>(`/call/updates/${id}`).subscribe({
        next(value) {
          if (value.timestamp && value.timestamp !== timestamp) {
            timestamp = value.timestamp;
            sub.next(value);
          }
        },
        error(err) {
          sub.error(err);
        },
        complete() {
          sub.complete();
        },
      });

      this.multicasts[id] = multicasted;

      return () => {
        this.callUpdateSubs[id]?.unsubscribe();
        delete this.callUpdateSubs[id];
        delete this.multicasts[id];
      };
    }).pipe(multicast(new Subject()), refCount());

    return multicasted;
  }

  public unsubAllCallStatusStreams() {
    Object.values(this.callUpdateSubs).forEach((sub) => sub.unsubscribe());
  }

  public unsubCallStatusStream(id: string) {
    if (this.callUpdateSubs[id]) {
      this.callUpdateSubs[id].unsubscribe();
    }
  }

  public listCommNumbers(query: PaginatedQuery, options?: HttpOptions<PaginatedResponse<CommNumber>>) {
    return this.get<PaginatedResponse<CommNumber>>(`${this.baseUrl}incoming-calls/numbers`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public getCommNumber(id: string, options?: HttpOptions<CommNumber>) {
    return this.get<CommNumber>(`${this.baseUrl}incoming-calls/numbers/${id}`, {
      options,
    });
  }

  public deleteCommNumber(id: string, options?: HttpOptions<void>) {
    return this.delete<void>(`${this.baseUrl}incoming-calls/numbers/${id}`, {
      options,
    });
  }

  public getAvailableNumbers(countryCode: string, options?: HttpOptions<CountryAvailableNumbers>) {
    return this.get<CountryAvailableNumbers>(`${this.baseUrl}incoming-calls/numbers/available/${countryCode}`, {
      options,
    });
  }

  public provisionUserCommNumber(accountId: string, phoneNumber?: string, options?: HttpOptions<CommNumber>) {
    const payload: any = { accountId };
    if (phoneNumber) {
      payload.phoneNumber = phoneNumber;
    }

    return this.post<CommNumber>(`${this.baseUrl}incoming-calls/numbers/available`, payload, {
      options,
    });
  }
}
