import { HttpRequestOptions, HttpService, HttpBasedService, downloadFile } from 'ah-requests';
import { DocumentExportType } from '../models';
import { map } from 'rxjs/operators';
import { defaultsDeep } from 'lodash';

export const cmsOriginTypes: DocumentExportType[] = [
  DocumentExportType.CLIENTS,
  DocumentExportType.INDIVIDUALS,
  DocumentExportType.PARTNERS,
  DocumentExportType.PARTNER_WITH_COMMISSIONS,
  DocumentExportType.COMPLIANCE_CASES,
  DocumentExportType.CLIENTS_ONBOARDING,
  DocumentExportType.CLIENT_INVITES,
];

export const analyticsOriginTypes: DocumentExportType[] = [
  DocumentExportType.AML_MONITORING,
  DocumentExportType.CAPITAL_COST,
  DocumentExportType.COLLATERALS,
  DocumentExportType.MARGIN_CREDIT_USAGE,
  DocumentExportType.POSITION_LIMITS,
  DocumentExportType.USAGE,
  DocumentExportType.EOD_SPOT_RATES,
  DocumentExportType.DELTA_RISK,
  DocumentExportType.HOUSE_MONEY_BALANCE,
  DocumentExportType.HOUSE_COLLATERAL_BALANCE,
  DocumentExportType.HOUSE_COB_BALANCE,
  DocumentExportType.CLIENT_MONEY_BALANCE,
  DocumentExportType.CLIENT_COLLATERAL_BALANCE,
  DocumentExportType.CLIENT_COB_BALANCE,
  DocumentExportType.PARTNER_MONEY_BALANCE,
  DocumentExportType.PARTNER_COLLATERAL_BALANCE,
  DocumentExportType.PARTNER_COB_BALANCE,
  DocumentExportType.SETTLED_COMMISSIONS,
  DocumentExportType.PARTNER_COMMISSIONS,
  DocumentExportType.UPCOMING_SETTLEMENTS,
  DocumentExportType.PNL_TYPE,
  DocumentExportType.PLN_TRADE,
  DocumentExportType.TRADING,
  DocumentExportType.EOM_STATEMENT,
  DocumentExportType.CLIENT_OUTSTANDING_FEES,
  DocumentExportType.PARTNER_OUTSTANDING_FEES,
  DocumentExportType.PARTNER_COMMISSION_REPORTS,
  DocumentExportType.HOUSE_WALLET_MONEY_BALANCE,
  DocumentExportType.HOUSE_WALLET_COLLATERAL_BALANCE,
  DocumentExportType.HOUSE_WALLET_TOTAL_BALANCE,
  DocumentExportType.CLIENT_WALLET_MONEY_BALANCE,
  DocumentExportType.CLIENT_WALLET_COLLATERAL_BALANCE,
  DocumentExportType.CLIENT_WALLET_TOTAL_BALANCE,
  DocumentExportType.PARTNER_WALLET_MONEY_BALANCE,
  DocumentExportType.PARTNER_WALLET_COLLATERAL_BALANCE,
  DocumentExportType.PARTNER_WALLET_TOTAL_BALANCE,
  DocumentExportType.VOICE_TRADE_CLIENT_EOM_REPORTS,
  DocumentExportType.WRAPPED_VOICE_TRADE_CLIENT_EOM_REPORTS,
  DocumentExportType.VOICE_TRADE_MARGIN_UTILITY_REPORTS,
  DocumentExportType.VOICE_TRADE_INITIAL_MARGIN_REQUIREMENT_REPORTS,
  DocumentExportType.VOICE_TRADE_CLIENT_MARGIN_COLLATERAL_REPORTS,
  DocumentExportType.VOICE_TRADE_CLIENT_WALLET_REPORTS,
  DocumentExportType.VOICE_TRADE_OPEN_POSITIONS_PARTNERS_REPORTS,
  DocumentExportType.VOICE_TRADE_OPEN_POSITIONS_CLIENTS_REPORTS,
  DocumentExportType.VOICE_TRADE_OPEN_POSITIONS_REPORTS,
  DocumentExportType.VOICE_TRADE_AUDITS,
  DocumentExportType.CASH_TRANSACTION_AUDITS,
  DocumentExportType.BANK_ACCOUNTS_AUDITS,
  DocumentExportType.LIQUIDITY_PROVIDERS_AUDITS,
  DocumentExportType.VOICE_TRADE_GENERAL_DAILY_STATEMENT_REPORTS,
  DocumentExportType.VOICE_TRADE_CLIENT_DAILY_STATEMENT_REPORTS,
  DocumentExportType.VOICE_TRADE_SETTLEMENT_WATCH_REPORTS,
  DocumentExportType.VOICE_TRADE_CLIENT_MONEY_BY_BANK_ACCOUNT_REPORT,
  DocumentExportType.VOICE_TRADE_CLIENT_MONEY_REPORT,
  DocumentExportType.VOICE_TRADE_CLIENT_LEDGER_BY_CLIENT_REPORTS,
  DocumentExportType.VOICE_TRADE_CLIENT_LEDGER_BY_TRANSACTION_REPORTS,
];

export const fxOriginTypes: DocumentExportType[] = [
  DocumentExportType.TRADES,
  DocumentExportType.TRADES_WITH_COMMISSIONS,
  DocumentExportType.FUTURE_COMMISSIONS,
  DocumentExportType.QUOTES,
  DocumentExportType.VOICE_TRADES,
  DocumentExportType.LIQUIDITY_PROVIDERS,
  DocumentExportType.CASH_TRANSACTIONS,
  DocumentExportType.BANK_ACCOUNTS,
  DocumentExportType.FX_VANILLA_OPTION_TERMSHEET,
  DocumentExportType.DELTA_TRADES,
];

export const bankingOriginTypes: DocumentExportType[] = [
  DocumentExportType.BENEFICIARIES,
  DocumentExportType.TRANSACTIONS,
  DocumentExportType.WALLETS,
  DocumentExportType.WALLET_TRANSACTIONS,
  DocumentExportType.MT_103_TRANSACTION,
  DocumentExportType.BALANCES_CERTIFICATE,
];

export class DocumentsService extends HttpBasedService {
  constructor(
    http: HttpService,
    private cmsBaseUrl: string,
    private analyticsBaseUrl: string,
    private fxBaseUrl: string,
    private bankingBaseUrl: string
  ) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'documentsService' } },
      },
    });
  }

  private getBaseUrl(resourceOrigin: DocumentExportType) {
    // TODO need to know specific mappings to use this
    if (cmsOriginTypes.includes(resourceOrigin)) {
      return this.cmsBaseUrl;
    }
    if (analyticsOriginTypes.includes(resourceOrigin)) {
      return this.analyticsBaseUrl;
    }
    if (fxOriginTypes.includes(resourceOrigin)) {
      return this.fxBaseUrl;
    }
    return this.bankingBaseUrl;
  }

  public downloadSyncDocument(
    resourceOrigin: DocumentExportType,
    fileId: string,
    options?: HttpRequestOptions<unknown>
  ) {
    return this.getSyncDocument(resourceOrigin, fileId, options).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  public getSyncDocument(resourceOrigin: DocumentExportType, fileId: string, options?: HttpRequestOptions<unknown>) {
    return this.rawRequest(
      defaultsDeep(
        {
          ...options,
          axiosConfig: {
            ...options?.axiosConfig,
            responseType: 'arraybuffer',
            url: `${this.getBaseUrl(resourceOrigin)}exported-documents/${resourceOrigin}/${fileId}/download`,
            method: 'get',
          },
        },
        this.defaultOptions
      )
    );
  }
}
