import { render, staticRenderFns } from "./ChargeTypeForm.vue?vue&type=template&id=be62cace&scoped=true"
import script from "./ChargeTypeForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./ChargeTypeForm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ChargeTypeForm.vue?vue&type=style&index=0&id=be62cace&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be62cace",
  null
  
)

export default component.exports