import _Vue, { PluginObject } from 'vue';

import AppLogo from './AppLogo.vue';
import DataRow from './DataRow.vue';
import BackButton from './BackButton.vue';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import ManagedLoadingOverlay from 'ah-common-lib/src/common/components/overlays/ManagedLoadingOverlay.vue';
import ConfirmModal from 'ah-common-lib/src/common/components/ConfirmModal.vue';
import InfoTooltip from './InfoTooltip.vue';
import DashboardViewAllButton from '@/app/components/dashboard/DashboardViewAllButton.vue';
import * as boxGridComponents from './boxGrid';
import * as structureComponents from './structure';
import * as tableComponents from './table';
import * as animationComponents from './animation';
import * as formComponents from './form';

export default {
  install: function install(Vue: typeof _Vue) {
    Vue.component('AppLogo', AppLogo);
    Vue.component('LoadingOverlay', LoadingOverlay);
    Vue.component('ManagedLoadingOverlay', ManagedLoadingOverlay);
    Vue.component('DataRow', DataRow);
    Vue.component('BackButton', BackButton);
    Vue.component('ConfirmModal', ConfirmModal);
    Vue.component('InfoTooltip', InfoTooltip);
    Vue.component('DashboardViewAllButton', DashboardViewAllButton);
    [boxGridComponents, structureComponents, tableComponents, animationComponents, formComponents].forEach((c) => {
      Object.keys(c).forEach((key) => Vue.component(key, (c as any)[key]));
    });
  },
} as PluginObject<void>;
