import { Store as PiniaStore } from 'pinia';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { useSettingsStore } from './settingsModule';
import { useAuthStore } from './authStore';
import { useIndividualSettingsStore } from './individualSettingsModule';
import { useWalletsStore } from './walletsModule';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { useNotificationSettingsStore } from './notificationSettingsModule';
import { useFeatureFlagStore } from 'ah-common-stores';

export const runActions = async (action: commonStoreActions, payload?: any) => {
  const actions = [] as Promise<any>[];

  const piniaStores: PiniaStore[] = [
    useSettingsStore(),
    useAuthStore(),
    useFeatureFlagStore(),
    useIndividualSettingsStore(),
    useWalletsStore(),
    useNotificationsStore(),
    useNotificationSettingsStore(),
  ];
  for (let i = 0; i < piniaStores.length; i += 1) {
    if (typeof (piniaStores[i] as any)[action] === 'function') {
      actions.push(Promise.resolve((piniaStores[i] as any)[action](payload)));
    }
  }
  await Promise.all(actions);
};
