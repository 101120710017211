<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import TradeCreator from '../TradeCreator.vue';
import { ref } from 'vue';

const emit = defineEmits<{
  (e: 'flow-completed'): void;
}>();

const tradeFlowModal = ref<BModal | null>(null);

function show() {
  tradeFlowModal.value?.show();
}

function hide() {
  tradeFlowModal.value?.hide();
}

function onFlowCompleted() {
  emit('flow-completed');
  tradeFlowModal.value!.hide();
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal trade-modal" ref="tradeFlowModal" hide-footer>
      <TradeCreator
        :inModal="true"
        @flow-completed="onFlowCompleted"
        @trade-created="$emit('trade-created', $event)"
        @update:beneficiary="$emit('update:beneficiary', $event)"
        @trade-cancelled="hide()"
        v-bind="$attrs"
      />
    </BModal>
    <slot v-bind="{ show }">
      <VButton @click="show()">Exchange</VButton>
    </slot>
  </span>
</template>

<style lang="scss">
.trade-modal .modal-dialog {
  width: 35em;
}
</style>
