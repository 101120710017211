<script lang="ts" setup>
import { DrawdownQuotePriceResponse, QuotePriceResponse } from 'ah-api-gateways';
import { computed } from 'vue';

const props = defineProps<{
  /**
   * Trade price response object
   */
  tradePriceResponse: QuotePriceResponse | DrawdownQuotePriceResponse;
}>();

const primaryRate = computed(() => {
  return props.tradePriceResponse.ccy1.isPrimaryRate ? props.tradePriceResponse.ccy1 : props.tradePriceResponse.ccy2;
});

const secondaryRate = computed(() => {
  return props.tradePriceResponse.ccy1.isPrimaryRate ? props.tradePriceResponse.ccy2 : props.tradePriceResponse.ccy1;
});
</script>

<template>
  <div>
    {{ primaryRate.currency }} 1
    <span class="trade-direction"><IconTrade class="exchange-icon" /></span>
    {{ secondaryRate.currency }}
    {{ primaryRate.clientRate.toFixed($ahTradesState.store.useSettingsStore().ratePrecision) }}
    <span class="text-secondary">
      (inverse {{ secondaryRate.clientRate.toFixed($ahTradesState.store.useSettingsStore().ratePrecision) }})</span
    >
  </div>
</template>

<style lang="scss" scoped>
.exchange-icon {
  font-size: 10px;
  width: 2em;
  height: 1.5em;
  margin-top: -3px;
}
</style>
