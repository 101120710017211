import { ValidationRule, ValidationRuleWithParams } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { getValidatorFn } from '../helpers';
import { FormModel } from '../interfaces';
import { stateValueGetter } from './helpers';

export const optional = (validator: ValidationRule) => ifTest(validator, (val) => helpers.req(val));

/**
 * Given a validator and a boolean "check" method,
 * returns a new validator which only runs if the "check" method returns a truthy value
 * if the "check" method returns a falsy value, the validator does not run, and validation is considered valid
 */
export function ifTest<T = any>(
  validator: ValidationRule<T>,
  check: (val: any, ctx: any, vm?: any) => boolean
): ValidationRule<T> {
  return {
    $validator: (val: any, ctx: any, vm: any) => !check(val, ctx, vm) || getValidatorFn(validator)(val, ctx, vm),
    $message: (validator as ValidationRuleWithParams).$message,
    $params: (validator as ValidationRuleWithParams).$params,
  };
}

export const requiredIfStateValue = (
  fieldName: string,
  stateKey = 'required',
  defaultValue: any = undefined,
  useHierarchy = true
) => requiredIfWithContext(stateValueGetter(fieldName, stateKey, defaultValue, useHierarchy));

export const requiredIfWithContext = (test: (vm: FormModel) => boolean) => {
  return (val: any, ctx: any) => {
    if (test(ctx)) {
      return helpers.req(val);
    }
    return true;
  };
};
