import { render, staticRenderFns } from "./TradeFundForm.vue?vue&type=template&id=e3bd14dc&scoped=true"
import script from "./TradeFundForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./TradeFundForm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TradeFundForm.vue?vue&type=style&index=0&id=e3bd14dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3bd14dc",
  null
  
)

export default component.exports