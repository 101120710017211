<script setup lang="ts">
import ClientRowInfo from '@/app/components/clients/clientInfo/ClientRowInfo.vue';
import { Client } from 'ah-api-gateways';
import OpenPositionsDashboardWidget from '@/app/components/trades/widgets/OpenPositionsDashboardWidget.vue';
import WalletsDashboardWidget from 'ah-wallets/src/components/WalletsDashboardWidget.vue';
import LimitsAndCollateralDashboardWidget from 'ah-trades/src/components/limitsAndCollateral/LimitsAndCollateralDashboardWidget.vue';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import DownloadEOMStatementModal from 'ah-trades/src/components/modals/DownloadEOMStatementModal.vue';
import { shortOpenPositionsTradeTableFields } from 'ah-trades/src/models/tradeTableFields';
import { computed, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useRouter } from 'vue-router/composables';

const requestManager = useRequestManager().manager;

const services = getServices();

const router = useRouter();

const props = defineProps({ clientId: { type: String, required: true } });

const client = ref<Client>();

const tradesConfig = computed(() => {
  return {
    tableFields: shortOpenPositionsTradeTableFields,
  };
});

function onClientIdChange() {
  requestManager
    .sameOrCancelAndNew('getClient', services.client.getClient(props.clientId))
    .subscribe((response) => (client.value = response));
}

function go(to: string) {
  const baseUrl = '/admin/activity/clients';
  router.push({ path: `${baseUrl}/${props.clientId}/${to}` });
}

function goToCollateral() {
  go('limits-collateral');
}

watch(() => props.clientId, onClientIdChange, { immediate: true });
</script>

<template>
  <div class="centered-space padded-space" v-if="client" id="activity-single-client-dashboard-view">
    <BoxGrid align-h="start">
      <BoxGridBlock lg="6" md="12" sm="12" title="Open FX Positions">
        <OpenPositionsDashboardWidget :config="tradesConfig" :client="client" />
      </BoxGridBlock>
      <OnBehalfOf :client="client">
        <BoxGridBlock lg="3" md="6" sm="12" title="Wallets">
          <WalletsDashboardWidget :redirect-to="`${clientId}/wallets`" />
        </BoxGridBlock>
      </OnBehalfOf>
      <OnBehalfOf :client="client">
        <BoxGridBlock lg="3" md="6" sm="12" title="Trading Limits and Collateral">
          <LimitsAndCollateralDashboardWidget :redirect-to="`${clientId}/limits-collateral`" />
        </BoxGridBlock>
      </OnBehalfOf>
    </BoxGrid>

    <div class="actions-row text-left my-3">
      <VButton class="btn-stroked" @click="go('trades')">Open FX Positions</VButton>
      <template>
        <VButton class="btn-stroked" @click="goToCollateral"> Collateral Management </VButton>
      </template>

      <VButton class="btn-stroked" @click="go('wallets')">Wallets</VButton>
      <VButton class="btn-stroked" @click="go('beneficiaries')">Beneficiaries</VButton>
      <VButton class="btn-stroked" @click="go('client-information')">Client Information</VButton>
      <DownloadEOMStatementModal v-slot="{ showModal }" :client="client">
        <VButton class="btn-stroked" blurOnClick @click="showModal">MTM Statement</VButton>
      </DownloadEOMStatementModal>
    </div>

    <BoxGrid align-h="start">
      <BoxGridItem>
        <BoxGridBlock>
          <ClientRowInfo v-if="client" :client="client" />
        </BoxGridBlock>
      </BoxGridItem>
    </BoxGrid>
  </div>
  <LoadingOverlay v-else id="activity-single-client-dashboard-loading" />
</template>

<style lang="scss" scoped>
.actions-row {
  .btn {
    min-width: 8.7rem;
    margin: 10px 10px;
  }

  ::v-deep .back-button {
    margin: 0;
    span {
      font-size: $font-size-sm;
    }
  }
}
</style>
