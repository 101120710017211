<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect x="4" y="8" width="12" height="2" fill="currentColor" />
      <rect x="6" y="11" width="8" height="2" fill="currentColor" />
      <rect x="8" y="14" width="4" height="2" fill="currentColor" />
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
