<script lang="ts" setup>
import { AuthorityType, Beneficiary, beneficiaryName } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import WithdrawFromWallet from 'ah-wallets/src/components/WithdrawFromWallet.vue';
import { computed, reactive, ref, defineAsyncComponent } from 'vue';
import { useAhBeneficiariesState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const TradeCreator = defineAsyncComponent(() => import('ah-trades/src/components/TradeCreator.vue'));

const emit = defineEmits<{
  /**
   * - flow-completed: emmited onSuccess
   */
  (e: 'flow-completed'): void;
}>();

const tradeFlowModal = ref<InstanceType<typeof BModal> | null>(null);

defineProps<{
  beneficiary: Beneficiary;
}>();

const beneficiariesState = useAhBeneficiariesState();

const canRequestPrices = computed(() => {
  return beneficiariesState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_PRICES) || onBehalfOfClient.value;
});

const state = reactive({
  tradeStage: '' as string,
  sendTypeForm: makeFormModel({
    name: 'tradeTypeForm',
    fieldType: 'form',
    fields: [
      radioField(
        'type',
        '',
        [
          {
            label: 'Same Currency',
            value: 'sameCurrency',
          },
          {
            label: 'Currency Exchange',
            value: 'currencyExchange',
            disabled: !canRequestPrices.value,
          },
        ],
        {
          defaultValue: 'sameCurrency',
          inline: true,
          fieldWrapperClass: 'col col-12 mb-n1',
        }
      ),
    ],
  }),
});

const onBehalfOfClient = useOnBehalfOf();

const canExchange = computed(() => {
  return beneficiariesState.store.useAuthStore().isClientUser || onBehalfOfClient.value;
});

function onStageChange(stage: string) {
  state.tradeStage = stage;
}

function show() {
  tradeFlowModal.value!.show();
}

function onCancel() {
  tradeFlowModal.value!.hide();
}

function onSuccess() {
  tradeFlowModal.value!.hide();
  emit('flow-completed');
}

function findBeneficiaryName(beneficiary: Beneficiary) {
  return beneficiaryName(beneficiary);
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal trade-modal" ref="tradeFlowModal" hide-footer>
      <h2 class="mb-4">Send money to {{ findBeneficiaryName(beneficiary) }}</h2>
      <ValidatedForm
        class="mb-2"
        :fm="state.sendTypeForm"
        v-if="canExchange && state.tradeStage !== 'tradeConfirmation'"
      />
      <TradeCreator
        v-if="canExchange && state.sendTypeForm.type === 'currencyExchange'"
        :beneficiary="beneficiary"
        :title="null"
        inModal
        isPayment
        @flow-completed="onSuccess"
        @trade-created="$emit('trade-created', $event)"
        @update:beneficiary="$emit('update:beneficiary', $event)"
        @update:stage="onStageChange"
        @trade-cancelled="onCancel"
      />
      <WithdrawFromWallet
        v-else
        :targetBeneficiary="beneficiary"
        @update:targetBeneficiary="$emit('update:beneficiary', $event)"
        @withdraw-succeded="onSuccess"
        @cancel="onCancel"
      />
    </BModal>
    <slot v-bind="{ show }">
      <VButton @click="show()">Send Money</VButton>
    </slot>
  </span>
</template>

<style lang="scss">
.trade-modal .modal-dialog {
  width: 37em !important;
}
</style>
