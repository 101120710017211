import { render, staticRenderFns } from "./SingleOwnerOutstandingFeesHeader.vue?vue&type=template&id=90172cda&scoped=true"
import script from "./SingleOwnerOutstandingFeesHeader.vue?vue&type=script&lang=ts&setup=true"
export * from "./SingleOwnerOutstandingFeesHeader.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SingleOwnerOutstandingFeesHeader.vue?vue&type=style&index=0&id=90172cda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90172cda",
  null
  
)

export default component.exports