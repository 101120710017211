<script setup lang="ts">
import { Partner } from 'ah-api-gateways';
import PartnerInformationEditor from '@/app/components/settings/PartnerInformationEditor.vue';
import { ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const requestManager = useRequestManager().manager;
const services = getServices();
const currentPartner = ref<Partner | null>(null);

function loadPartner() {
  requestManager.currentOrNew('loadPartner', services.partner.getPartner()).subscribe((partner) => {
    currentPartner.value = partner;
  });
}

loadPartner();
</script>

<template>
  <div class="padded-space" id="partner-information-settings-view">
    <div class="centered-space">
      <h2>Company Details</h2>
      <BoxGrid alignH="start">
        <BoxGridBlock
          cols="12"
          lg="6"
          xl="5"
          :loadingOverlayProps="{ loading: requestManager.anyPending, hideOnLoading: true }"
          useLoadingOverlay
          overlayType="simple"
        >
          <PartnerInformationEditor :partner.sync="currentPartner" />
        </BoxGridBlock>
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
</style>
