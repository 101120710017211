export enum UpdateType {
  NEW_FEATURES = 'NEW_FEATURES',
  IMPROVEMENTS = 'IMPROVEMENTS',
  BUG_FIXES = 'BUG_FIXES',
  HINTS = 'HINTS',
}

export enum UpdatePlatform {
  CLIENT = 'CLIENT',
  PARTNER = 'PARTNER',
  CLIENT_PARTNER = 'CLIENT_PARTNER',
}

export interface UpdateEntry {
  id: string;
  type: UpdateType;
  platform: UpdatePlatform;
  date: string;
  description: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  deleted?: boolean;
  deletedBy?: string;
  deletedAt?: string;
}

export const UpdatePlatformDescriptions: {
  [key in UpdatePlatform]: string;
} = {
  [UpdatePlatform.CLIENT]: 'Client',
  [UpdatePlatform.PARTNER]: 'Partner',
  [UpdatePlatform.CLIENT_PARTNER]: 'Client & partner',
};

export const UpdateTypeDescriptions: {
  [key in UpdateType]: string;
} = {
  [UpdateType.NEW_FEATURES]: 'New Feature',
  [UpdateType.IMPROVEMENTS]: 'Improvement',
  [UpdateType.BUG_FIXES]: 'Bug fix',
  [UpdateType.HINTS]: 'Hints',
};
