import { render, staticRenderFns } from "./SignatoryTermsReview.vue?vue&type=template&id=d1064492&scoped=true"
import script from "./SignatoryTermsReview.vue?vue&type=script&setup=true&lang=ts"
export * from "./SignatoryTermsReview.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SignatoryTermsReview.vue?vue&type=style&index=0&id=d1064492&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1064492",
  null
  
)

export default component.exports