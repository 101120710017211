<script setup lang="ts">
import UpdatedDate from '@/app/components/common/UpdatedDate.vue';
import { FutureCommissionSchedule } from '@/app/components/commissionSchedule';
import { CommissionsChart, SettlementScheduleCommissionChart } from 'ah-reports';
import { AuthorityType, ExportListType } from 'ah-api-gateways';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';
import { useAuthStore } from '@/app/store/authStore';
import { computed, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const services = getServices();

const futureCommission = ref<InstanceType<typeof FutureCommissionSchedule>>();

const timeStamp = ref<Date>();

function refreshData() {
  timeStamp.value = new Date();
  futureCommission.value?.loadData();
}

const canSeeWalletWithdrawCommissions = computed(() =>
  authStore.hasAuthorities(AuthorityType.VIEW_COMMISSION_WITHDRAWALS)
);

const dataDownloadState = computed(() => requestManager.requestStates.downloadData);

function downloadList(format: ExportListType) {
  requestManager.cancelAndNew('downloadData', services.trade.downloadFutureCommissionReport({}, format)).subscribe();
}
</script>

<template>
  <div class="padded-space" id="commission-schedule-view">
    <div class="centered-space">
      <h2>Commission schedule</h2>
      <BoxGrid alignV="start" alignH="start">
        <BoxGridBlock align-self="stretch" cols="12" lg="12" md="12">
          <h3>Commission settlement schedule <span class="text-small"> (GBP equivalent)</span></h3>
          <SettlementScheduleCommissionChart />
        </BoxGridBlock>
        <BoxGridBlock align-self="stretch" cols="12" lg="6" md="12">
          <h3>Settled (realised) commission</h3>
          <CommissionsChart graphType="trade-settled" />
        </BoxGridBlock>
        <BoxGridBlock align-self="stretch" cols="12" lg="6" md="12">
          <h3>Scheduled (unrealised) commission</h3>
          <CommissionsChart graphType="trade-scheduled" />
        </BoxGridBlock>
        <BoxGridBlock align-self="stretch" cols="12" lg="6" md="12">
          <h3>Total commission</h3>
          <CommissionsChart graphType="trade-all" />
        </BoxGridBlock>
        <BoxGridBlock align-self="stretch" cols="12" lg="6" md="12" v-if="canSeeWalletWithdrawCommissions">
          <h3>Commission withdrawn from wallets</h3>
          <CommissionsChart graphType="wallet-withdrawals" />
        </BoxGridBlock>
        <BoxGridBlock align-self="stretch" cols="12" lg="12" md="12">
          <span class="future-header mb-lg-0 mb-md-3">
            <h3>Future commission settlement schedule by currency</h3>
            <UpdatedDate :date="timeStamp" dateStyle="short" @refresh="refreshData" class="refresh-time mr-3" />
          </span>
          <FutureCommissionSchedule ref="futureCommission" />
        </BoxGridBlock>
        <ListDownloadButton :loading="dataDownloadState === 'pending'" @download="downloadList" />
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.future-header {
  display: flex;
}
.refresh-time {
  position: absolute;
  right: 0;
  opacity: 0.5;

  @include upToResolution($tabletResolution) {
    font-size: 10px;
    ::v-deep span {
      font-size: 10px;
    }
    top: 3rem;
  }
}
</style>
