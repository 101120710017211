<script setup lang="ts">
import { useAuthStore } from '@/app/store/authStore';
import { computed } from 'vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';

const authStore = useAuthStore();

const lastLoginTimestamp = computed(() => {
  if (authStore.lastLoginTimestamp) {
    return new Date(authStore.lastLoginTimestamp);
  }
  return null;
});
</script>

<template>
  <div v-if="lastLoginTimestamp">
    <p>Last login: {{ formatDate(lastLoginTimestamp, 'yyyy-MM-dd HH:mm:ss') }}</p>
  </div>
</template>

<style lang="scss" scoped>
p {
  text-align: end;
  font-size: 0.75em;
}
</style>
