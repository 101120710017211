<script setup lang="ts">
import { ClientOnboardingStatus } from 'ah-api-gateways';
import { ClientType } from 'ah-api-gateways';
import { computed, ref, watch } from 'vue';

const emit = defineEmits({
  'update:filter': (_filter: any) => true,
});

const clientStatusOptions = [
  {
    label: 'Info Required',
    value: ClientOnboardingStatus.CREATED,
  },
  {
    label: 'Pending',
    value: ClientOnboardingStatus.PENDING,
  },
  {
    label: 'Rejected',
    value: ClientOnboardingStatus.REJECTED,
  },
  {
    label: 'Approved',
    value: ClientOnboardingStatus.APPROVED,
  },
];

const clientTypeOptions = [
  {
    label: 'Company',
    value: ClientType.COMPANY,
  },
  {
    label: 'Individual',
    value: ClientType.INDIVIDUAL,
  },
];

const clientStateOptions = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

const search = ref('');

const clientTypes = ref([]);

const clientStates = ref([]);

const clientStatuses = ref([]);

const allFilters = computed(() => {
  const out: any = {};

  if (search.value.length) {
    out.query = search.value;
    out.queryBy = ['individuals', 'companyDetails'];
  }
  if (clientTypes.value.length === 1) {
    out.type = clientTypes.value[0];
  }
  if (clientStates.value.length === 1) {
    out.state = clientStates.value[0];
  }
  if (clientStatuses.value.length) {
    out.status = clientStatuses.value;
  }

  return out;
});

watch(allFilters, () => {
  emit('update:filter', allFilters.value);
});
</script>

<template>
  <BoxGrid alignH="start" alignV="stretch">
    <BoxGridItem sm="12" md="5" lg="4" cols="12" align-self="center" class="field-group-wrapper">
      <SearchInput
        class="search-input mb-0 mt-sm-0 mt-md-4 mock-label-padding"
        placeholder="Search by client or team members"
        :search.sync="search"
      />
    </BoxGridItem>
    <BoxGridItem sm="8" md="7" lg="8" cols="12">
      <BoxGrid alignH="end" alignV="stretch">
        <BoxGridItem cols="6" sm="12" md="6" offset="0" class="field-group-wrapper">
          <label
            >Onboarding status
            <a v-if="clientStatuses.length > 0" class="field-group-clear-link" @click="clientStatuses = []"> clear </a>
          </label>
          <TagMultiSelect
            :maxFulltextLabels="2"
            :options="clientStatusOptions"
            :value.sync="clientStatuses"
            itemsCountLabel="types"
          />
        </BoxGridItem>
        <BoxGridItem cols="3" sm="12" md="3" offset="0" class="field-group-wrapper">
          <label>
            Type
            <a v-if="clientTypes.length > 0" class="field-group-clear-link" @click="clientTypes = []"> clear </a>
          </label>
          <TagMultiSelect
            :maxFulltextLabels="2"
            :options="clientTypeOptions"
            :value.sync="clientTypes"
            itemsCountLabel="types"
          />
        </BoxGridItem>
        <BoxGridItem cols="3" sm="12" md="3" offset="0" class="field-group-wrapper">
          <label>
            State
            <a v-if="clientStates.length > 0" class="field-group-clear-link" @click="clientStates = []"> clear </a>
          </label>
          <TagMultiSelect
            :maxFulltextLabels="2"
            :options="clientStateOptions"
            :value.sync="clientStates"
            itemsCountLabel="types"
          />
        </BoxGridItem>
      </BoxGrid>
    </BoxGridItem>
  </BoxGrid>
</template>

<style lang="scss" scoped>
.dates-filter-holder {
  flex-basis: 26em;
  flex-shrink: 0;
  flex-grow: 0;
}

::v-deep {
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }

    .field-group-field-input.month,
    .field-group-field-input.day {
      width: 3rem !important;
    }

    .field-group-field-input.year {
      width: 4rem !important;
    }
  }
}
</style>
