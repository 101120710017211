import { DocumentExport, ExportListType, PaginatedParams, PaginatedQuery, PaginatedResponse } from 'ah-api-gateways';
import { Ref, ref, watch, onBeforeUnmount, computed, readonly, reactive } from 'vue';
import { cloneDeep, debounce, isEqual, keysIn, pick } from 'lodash';
import { onAsyncFileDownload } from './listingConfig';
import {
  EditedRowsSaveProcess,
  UseManagedListingEmits,
  UseManagedListingOptions,
  defineUseManagedListingProps,
} from './useManagedListingInterfaces';
import { setupComposableQueryParam } from '../helpers/useQueryParam';
import { managedComposableRefs } from '../helpers/managedComposable';
import { EditTableMode } from '../models';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpError } from 'ah-requests';

export function useManagedListing<
  T,
  F extends {
    [key: string]: any;
  } = any
>(options: UseManagedListingOptions<T, F>) {
  let innerFilter = {} as F;

  let currentQuery: any = null;

  let innerSortAndPageParams: Partial<PaginatedParams> = {};

  // Managed refs relating to all props (`update:key` events are triggered automatically when using this)
  const refs = managedComposableRefs(defineUseManagedListingProps<T, F>(), options);

  const emit: UseManagedListingEmits<T, F> = (e, value?) => options.emit && options.emit(e as any, value);

  const innerTableData: Ref<PaginatedResponse<T>> = ref({
    total: 0,
    list: [],
    ...(refs.tableData.value as any),
  });

  watch(
    () => options.reqManager.requestStates.downloadData,
    (state) => emit('update:dataDownloadState', state)
  );

  watch(
    () => options.reqManager.requestStates.loadData,
    (state) => emit('update:dataLoadState', state)
  );

  function updateInternalFilterFromSource(source: F, checkChanges = false) {
    const { sort, sortDirection, pageNumber, pageSize, total, data, ...filterIn } = source;
    const transformed: F = refs.dataTransforms.value?.filterTransform
      ? refs.dataTransforms.value.filterTransform(filterIn as F)
      : (filterIn as F);
    if (!isEqual(transformed, innerFilter)) {
      innerFilter = transformed;
      if (checkChanges) {
        loadDataIfChanged();
      }
    }
  }

  function updateInternalFilter(checkChanges = false) {
    updateInternalFilterFromSource(refs.filter.value || ({} as any), checkChanges);
  }

  function updateInternalSortAndPageParamsFromSource(source: Partial<PaginatedParams>, checkChanges = false) {
    const sortIn: Partial<PaginatedParams> = {};
    keysIn(refs.sortAndPageParams.value).forEach((k) => {
      if (['sort', 'sortDirection', 'pageNumber', 'pageSize'].includes(k)) {
        const key: keyof PaginatedParams = k as keyof PaginatedParams;
        sortIn[key] = source[key] as any;
      }
    });

    const newSort = {
      ...innerSortAndPageParams,
      ...sortIn,
    };
    if (!isEqual(newSort, innerSortAndPageParams)) {
      updateSortingFromData(newSort);
      if (checkChanges) {
        loadDataIfChanged();
      }
    }
  }

  function updateInternalSortAndPageParams(checkChanges = false) {
    updateInternalSortAndPageParamsFromSource(refs.sortAndPageParams!.value || ({} as any), checkChanges);
  }

  function updateSortingFromData(data: Partial<PaginatedParams>) {
    innerSortAndPageParams = {
      ...pick(data, ['pageNumber', 'sortDirection', 'sort', 'pageSize']),
    };
  }

  function makeQuery(forDownload = false, newQuery?: PaginatedQuery) {
    const { list, total, ...query } = {
      pageNumber: innerTableData.value?.pageNumber ?? 0,
      pageSize: innerTableData.value?.pageSize ?? 10,
      ...innerTableData.value,
    };

    /**
     * Delete from current query any undefined filter properties in the new query
     */
    Object.keys(query).forEach((key) => {
      if (['sort', 'sortDirection', 'pageNumber', 'pageSize'].includes(key)) return;
      if (innerFilter[key] === undefined) {
        delete (query as any)[key];
      }
    });

    /**
     * Assign, in order of priority:
     * - currently set page and sort params
     * - currently set filters
     * - newQuery (either from programatic usage or table sorting/pagination)
     */
    Object.assign(query, innerSortAndPageParams, innerFilter, newQuery || {});

    if (forDownload) {
      delete (query as any).pageNumber;
      delete (query as any).pageSize;
    }

    return refs.dataTransforms.value?.queryTransform
      ? refs.dataTransforms.value.queryTransform(query, forDownload)
      : query;
  }

  /**
   * Debounced method to check for changes to the filters/query and trigger a load
   *
   * This method is debounced to allow multiple update sources to act before triggering the load requests
   * (url query string, external defaults, etc.)
   */
  const loadDataIfChanged = debounce(function () {
    if (!isEqual(currentQuery, makeQuery())) {
      loadData();
    } else {
      updateSorting();
      updateFilter();
    }
  }, 50);

  function downloadData(type: ExportListType, downloadOptions?: { [key: string]: any }) {
    const query = makeQuery(true);

    if (!options.downloadDataRequest) {
      throw 'No downloadDataRequest set!';
    }

    options.reqManager
      .sameOrCancelAndNew('downloadData', options.downloadDataRequest(type, query, downloadOptions), query)
      .subscribe(
        (response) => {
          if ((response as DocumentExport)?.type) {
            emit('download-requested', response!);
            onAsyncFileDownload(response as DocumentExport);
          }
        },
        (error) => emit('download-request-error', error)
      );
  }

  function updateSorting() {
    if (!refs.sortAndPageParams.value || !isEqual(refs.sortAndPageParams.value, innerSortAndPageParams)) {
      refs.sortAndPageParams.value = cloneDeep(innerSortAndPageParams);
    }
  }

  function updateFilter() {
    if (!refs.filter.value || !isEqual(refs.filter.value, innerFilter)) {
      refs.filter.value = cloneDeep(innerFilter);
    }
  }

  function updateTableData() {
    if (!refs.tableData.value || !isEqual(refs.tableData.value, innerTableData)) {
      refs.tableData.value = cloneDeep(innerTableData.value);
    }
  }

  function setData(data: PaginatedResponse<T>) {
    innerTableData.value = data;
    updateTableData();
  }

  function cancelLoadRequest(key: 'loadData' | 'downloadData') {
    options.reqManager.cancel(key);
  }

  function saveEditedRows() {
    if (!options.saveEditedRowRequest) {
      throw 'Cannot save edited rows without a set saveEditedRowRequest.';
    }
    const process: EditedRowsSaveProcess<T> = {
      pendingItems: refs.editedRows.value ?? [],
      savedItems: [],
      erroredItems: [],
      state: 'idle',
    };

    const subject = new BehaviorSubject(cloneDeep(process));

    const executeNextAction: () => Observable<void> = () => {
      const item = process.pendingItems.pop();
      if (!item) {
        return of();
      }

      return options.saveEditedRowRequest!(item).pipe(
        tap(
          () => {
            process.savedItems.push(item);
            subject.next(cloneDeep(process));
          },
          (error: HttpError) => {
            process.erroredItems.push({ item, error });
            subject.next(cloneDeep(process));
          }
        ),
        catchError(() => of(null)),
        mergeMap(() => executeNextAction())
      );
    };

    options.reqManager.cancelAndNew('saveEditedRows', executeNextAction()).subscribe({
      complete() {
        process.state = process.pendingItems.length === 0 ? 'done' : 'cancelled';
        subject.next(cloneDeep(process));
        subject.complete();
      },
    });

    return subject.asObservable();
  }

  function loadData(
    newQuery?: PaginatedQuery,
    backgroundLoading = false,
    isReQuery = false
  ): Promise<PaginatedResponse<T>> {
    const query = makeQuery(false, newQuery);

    if (newQuery) {
      // If a loadData request includes filters, we update the internal filter to allow events post load to update any listeners
      updateInternalFilterFromSource(query as F, false);
    }

    return new Promise((resolve, reject) => {
      currentQuery = query;
      options.reqManager.cancel('loadBackgroundData');
      options.reqManager
        .sameOrCancelAndNew(
          backgroundLoading ? 'loadBackgroundData' : 'loadData',
          options.loadDataRequest(query),
          query
        )
        .subscribe(
          (data) => {
            const newData: PaginatedResponse<T> = {
              sort: query.sort || undefined,
              sortDirection: query.sort ? query.sortDirection || 'DESC' : undefined,
              pageNumber: (data as any).page ?? data.pageNumber ?? query.pageNumber,
              pageSize: (data as any).size ?? data.pageSize ?? query.pageSize,
              ...data,
            };

            innerTableData.value = newData;

            const maxPage = innerTableData.value.pageSize
              ? Math.max(0, Math.ceil((innerTableData.value.total ?? 0) / innerTableData.value.pageSize) - 1)
              : 0;

            // Protecting against pagination issues:
            // if loading page X+1 or higher and there are only X pages,
            // reset to highest available page number a re-query
            if (!isReQuery && (innerTableData.value.pageNumber ?? 0) > maxPage) {
              resolve(loadData({ pageNumber: maxPage }, backgroundLoading, true));
            } else {
              updateSortingFromData(innerTableData.value);
              currentQuery = makeQuery();
              updateTableData();
              updateSorting();
              updateFilter();
              emit('data-loaded');
              resolve(innerTableData.value);
            }
          },
          (error) => {
            innerTableData.value = {
              ...innerTableData.value,
              list: [],
            };
            emit('data-load-error', error);
            reject();
          },
          () => {
            reject();
          }
        );
    });
  }

  watch(
    refs.tableData,
    () => {
      innerTableData.value = {
        ...innerTableData.value,
        ...refs.tableData!.value,
      };
    },
    { deep: true }
  );

  watch(
    refs.sortAndPageParams,
    () => {
      updateInternalSortAndPageParams(true);
    },
    { deep: true }
  );

  watch(
    refs.filter,
    () => {
      updateInternalFilter(true);
    },
    { deep: true }
  );

  updateInternalSortAndPageParams();
  updateInternalFilter();
  loadDataIfChanged();

  /**
   * We setup watchers for query string parameters after all initial data has been set
   */
  setupComposableQueryParam(refs.filterQueryParam, refs.filter);
  setupComposableQueryParam(refs.paginationQueryParam, refs.sortAndPageParams);

  onBeforeUnmount(() => {
    options.reqManager.clear((key: string) => !['loadData', 'loadBackgroundData'].includes(key));
  });

  emit('update:triggers', {
    loadData,
    downloadData,
    loadDataRequest: options.loadDataRequest,
    cancelLoadRequest,
    saveEditedRows,
  });

  const displayData = computed(() => {
    if (refs.editedRows.value && refs.editMode.value === EditTableMode.ONLY_EDITED) {
      return {
        list: refs.editedRows.value,
        total: refs.editedRows.value.length,
        pageSize: refs.editedRows.value.length,
        pageNumber: 0,
      } as PaginatedResponse<T>;
    }
    return readonly(innerTableData).value;
  });

  return {
    listeners: {
      download: downloadData,
      sort: loadData,
    },
    refs,
    bindings: reactive({
      selectedItems: refs.selectedItems,
      primaryKey: refs.primaryKey,
      dataLoadState: computed(() => options.reqManager.requestStates.loadData),
      dataDownloadState: computed(() => options.reqManager.requestStates.downloadData),
      data: displayData,
      rowClass: (row: T) => {
        const key = (refs.primaryKey.value ?? 'id') as keyof T;
        return refs.editedRows.value?.find((item) => item[key] === row[key]) ? 'edited-row' : '';
      },
      editMode: refs.editMode,
      fields: options.fields,
      showExport: !refs.hideDownloadButton.value && !!options.downloadDataRequest,
      filter: refs.filter,
    }),
    loadData,
    downloadData,
    cancelLoadRequest,
    setData,
  };
}
