<template>
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1331 6.49084H10.8973V5.29937C10.8973 2.59912 8.70191 0.400391 5.99834 0.400391C3.29809 0.400391 1.09936 2.5958 1.09936 5.29937C1.09936 6.6454 1.00214 7.13277 1.54774 7.13277C2.09335 7.13277 1.99613 6.6454 1.99613 5.29937C1.99613 3.094 3.78965 1.29715 5.99834 1.29715C8.20371 1.29715 10.0006 3.09068 10.0006 5.29937V6.49084H0.86687C0.388597 6.49084 0 6.87944 0 7.35771V12.9409C0 14.3724 1.16579 15.5382 2.59729 15.5382H9.40271C10.8342 15.5382 12 14.3724 12 12.9409V7.35771C12 6.87944 11.6114 6.49084 11.1331 6.49084ZM11.1032 12.9409C11.1032 13.8775 10.3427 14.6414 9.40271 14.6414H2.60061C1.66399 14.6414 0.900083 13.8808 0.900083 12.9409V7.3876H11.1032V12.9409Z"
      fill="currentColor"
    />
    <path
      d="M6.0015 12.7579C6.92815 12.7579 7.67877 12.0039 7.67877 11.0806C7.67877 10.1539 6.92483 9.40332 6.0015 9.40332C5.07484 9.40332 4.32422 10.1573 4.32422 11.0806C4.32422 12.0039 5.07484 12.7579 6.0015 12.7579ZM6.0015 10.2968C6.43327 10.2968 6.78201 10.6488 6.78201 11.0773C6.78201 11.5057 6.43327 11.8611 6.0015 11.8611C5.56972 11.8611 5.22098 11.509 5.22098 11.0806C5.22098 10.6521 5.56972 10.2968 6.0015 10.2968Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
