import { render, staticRenderFns } from "./TradeReportListFilters.vue?vue&type=template&id=c147e404&scoped=true"
import script from "./TradeReportListFilters.vue?vue&type=script&lang=ts&setup=true"
export * from "./TradeReportListFilters.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./TradeReportListFilters.vue?vue&type=style&index=0&id=c147e404&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c147e404",
  null
  
)

export default component.exports