// FIXME this should be merged into ExportFileType
export enum ExportListType {
  XLSX = 'XLSX',
  CSV = 'CSV',
  PDF = 'PDF',
}

export enum ExportFileType {
  PDF = 'PDF',
  HTML = 'HTML',
}
