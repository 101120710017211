import { useCurrencyFormatter } from 'ah-common-lib/src/number/useCurrencyFormatter';
import { Ref, computed } from 'vue';
import { useAhWalletsState } from '..';

export function useWalletsCurrencyFormatter(options: { currency: Ref<string> }) {
  const settingsStore = useAhWalletsState().store.useSettingsStore();

  return useCurrencyFormatter({
    currency: options.currency,
    currencyDataReference: computed(() => settingsStore.currencies),
  });
}
