export const ibanCountryGroupings = [
  { cc: 'GF', ibanCC: 'FR' },
  { cc: 'GP', ibanCC: 'FR' },
  { cc: 'MQ', ibanCC: 'FR' },
  { cc: 'RE', ibanCC: 'FR' },
  { cc: 'PF', ibanCC: 'FR' },
  { cc: 'TF', ibanCC: 'FR' },
  { cc: 'TF', ibanCC: 'FR' },
  { cc: 'YT', ibanCC: 'FR' },
  { cc: 'NC', ibanCC: 'FR' },
  { cc: 'PM', ibanCC: 'FR' },
  { cc: 'WF', ibanCC: 'FR' },
  { cc: 'IM', ibanCC: 'GB' },
  { cc: 'GG', ibanCC: 'GB' },
  { cc: 'JE', ibanCC: 'GB' },
  { cc: 'AX', ibanCC: 'FI' },
  { cc: 'AX', ibanCC: 'FI' },
];
