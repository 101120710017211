import { Observable } from 'rxjs';
import { PaginatedQuery, PaginatedResponse, EmailTemplate } from '../models';
import { HttpBasedService, HttpService, HttpOptions } from 'ah-requests';

export class EmailTemplateService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'emailTemplateService' } },
      },
    });
  }

  public listTemplates(
    query: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<EmailTemplate>>>
  ): Observable<PaginatedResponse<EmailTemplate>> {
    return this.get<PaginatedResponse<EmailTemplate>>(`${this.baseUrl}templates/email`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public getTemplate(id: string, options?: Partial<HttpOptions<EmailTemplate>>) {
    return this.get<EmailTemplate>(`${this.baseUrl}templates/email/${id}`, { options });
  }

  public setTemplate(template: EmailTemplate, options?: Partial<HttpOptions<void>>) {
    return this.put<void>(
      `${this.baseUrl}templates/email/${template.id}`,
      {
        body: template.body,
        subject: template.subject,
      },
      { options }
    );
  }
}
