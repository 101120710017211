<script lang="ts" setup>
import { makeFormModel, setApiErrorMessages, setState, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { AuthorityType, Client, CompanyRegistrationModel } from 'ah-api-gateways';
import { companyDetailsReadForm, addressReadForm } from 'ah-common-lib/src/form/formModels';
import { mergeMap, tap, catchError } from 'rxjs/operators';
import { waitForCQRSEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { useSettingsStore } from '@/app/store/settingsModule';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { useAuthStore } from '@/app/store/authStore';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';
import { computed, PropType, reactive, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

/**
 * Client company details
 *
 * Emits:
 * - update:client (payload: Client): emitted when client trading details are updated
 */

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
});

const emit = defineEmits({
  'update:client': (_client: Client) => true,
});

const requestManager = useRequestManager().manager;

const settingsStore = useSettingsStore();

const authStore = useAuthStore();

const services = getServices();

const tradingDetailsFormModel = (editable = false) => makeFormModel(addressReadForm(editable));

const companyDetailsFormDef = reactive<FormDefinition>({
  form: makeFormModel(companyDetailsReadForm()),
  validation: null,
});

const addressDetailsFormDef = reactive<FormDefinition>({
  form: makeFormModel(addressReadForm()),
  validation: null,
});

const tradingDetailsFormDef = reactive<FormDefinition>({
  form: makeFormModel(addressReadForm(true)),
  validation: null,
});

const isInEditMode = ref(false);

const canEditClient = computed(() => authStore.hasAuthorities(AuthorityType.UPDATE_CLIENT));

const canEditTradingAddress = computed(() => {
  return !isInEditMode.value && canEditClient.value;
});

const calculatedModel = computed(() => {
  const tradingAddress = {
    ...props.client?.companyDetails.tradingAddress,
    ...toDataModel(tradingDetailsFormDef.form),
  };

  return {
    tradingAddress,
  };
});

function cancel() {
  updateFormModel();
  isInEditMode.value = false;
}

function updateFormModel() {
  const entity = settingsStore.entityTypeList?.find(
    (item) => item.id === props.client.companyDetails.entityType
  )?.legalForm;

  updateModel(companyDetailsFormDef.form, {
    ...props.client.companyDetails,
    name: props.client.name,
    entityType: entity,
    reportingCurrency: props.client.reportingCurrency,
    countryCode: countryNameFromCC(props.client.companyDetails.address.countryCode),
  });
  updateModel(addressDetailsFormDef.form, props.client?.companyDetails.address || {});
  updateModel(tradingDetailsFormDef.form, props.client?.companyDetails.tradingAddress || {});
}

function saveModel() {
  requestManager
    .sameOrCancelAndNew(
      'saveClient',
      services.client.updateClient(props.client!.id, calculatedModel.value).pipe(
        mergeMap((idEntity) =>
          waitForCQRSEntityChange(idEntity, () =>
            services.client.getClient(idEntity.id, false, { errors: { silent: true } })
          ).pipe(catchError(() => of(props.client)))
        ),
        tap(
          (client) => {
            emit('update:client', client);
            isInEditMode.value = false;
          },
          (error) => {
            if (error.response.status === 400) {
              const apiError = constructPayloadErrors<CompanyRegistrationModel>(error.response!.data);
              if (apiError.fields?.tradingAddress) {
                setApiErrorMessages(apiError.fields.tradingAddress, tradingDetailsFormDef.form);
              }
            }
          }
        )
      )
    )
    .subscribe();
}

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    setState(formEvent.model, 'errors', []);
  }
}

watch(
  isInEditMode,
  () => {
    tradingDetailsFormDef.form = tradingDetailsFormModel(isInEditMode.value);
    updateFormModel();
  },
  { immediate: true }
);

settingsStore.loadEntityTypes().then(() => updateFormModel());
</script>

<template>
  <BoxGrid alignH="start" class="change-contacts-form">
    <BoxGridBlock cols="12">
      <ValidatedForm :fm="companyDetailsFormDef.form" />
    </BoxGridBlock>
    <BoxGridBlock cols="12">
      <h3>Registered Address</h3>
      <ValidatedForm :fm="addressDetailsFormDef.form" />
    </BoxGridBlock>
    <BoxGridBlock cols="12">
      <h3>Trading Address</h3>
      <span class="edit-icon" v-if="canEditTradingAddress" @click="isInEditMode = true"> <IconPencil /> </span>
      <ValidatedForm
        :fm="tradingDetailsFormDef.form"
        :validation.sync="tradingDetailsFormDef.validation"
        @form-event="onFormEvent"
      />
      <template v-if="isInEditMode">
        <div>
          <VButton :disable="requestManager.anyPending" blurOnClick @click="cancel" class="btn btn-stroked mb-2">
            Cancel
          </VButton>
          <VButton :loading="requestManager.anyPending" blurOnClick @click="saveModel" class="btn"> Save </VButton>
        </div>
      </template>
    </BoxGridBlock>
  </BoxGrid>
</template>

<style lang="scss" scoped>
::v-deep .readonly-field {
  input {
    background: transparent !important;
    border: none;
    margin-left: -0.4rem;
    text-overflow: ellipsis;
  }
}

.btn {
  min-width: 6rem;
  margin-right: 1rem;
}

.edit-icon {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 2rem;
}
</style>
