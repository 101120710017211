<script lang="ts" setup>
import { NotificationChannel, NotificationChannelType, NotificationSettingConfigGroup } from 'ah-api-gateways';
import { computed, PropType } from 'vue';

const props = defineProps({
  group: {
    type: Object as PropType<NotificationSettingConfigGroup>,
    required: true,
  },
  showTitles: {
    type: [Boolean, String],
    default: false,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
  channels: {
    type: Array as PropType<NotificationChannel[]>,
    default: () => [],
  },
});

const emit = defineEmits({
  'target-change': (_payload: { type: NotificationChannelType; enabled: boolean }) => true,
});

function checkIsTargetting(type: NotificationChannelType) {
  const channel = props.channels.find((n) => n.type === type);
  return channel?.enabled;
}

const isTargettingEmail = computed(() => checkIsTargetting(NotificationChannelType.EMAIL));

const isTargettingInApp = computed(() => checkIsTargetting(NotificationChannelType.IN_APP));

const areTitlesShown = computed(() => props.showTitles !== false);

function areAllChildrenMandatory(type: NotificationChannelType) {
  let out = true;
  props.group.notifications.forEach((setting) => {
    if (setting.channels.find((channel) => channel.channelType === type)!.configurable) out = false;
  });
  return out;
}

const calcStyles = computed(() => {
  return (type: NotificationChannelType) => {
    let out: string[] = [];
    if (areAllChildrenMandatory(type)) {
      out.push('disabled');
      const channel = props.channels.find((n) => n.type === type);
      if (channel?.enabled) {
        out.push('disabled-on');
      } else if (channel) {
        out.push('disabled-off');
      }
    }
    return out;
  };
});

function onTargetChange(type: NotificationChannelType, enabled: boolean) {
  emit('target-change', { type: type, enabled: enabled });
}
</script>

<template>
  <VRow class="align-items-lg-start align-items-md-center">
    <VCol class="description-col">
      <h3 class="notifications-block-title mb-0">{{ group.name }}</h3>
    </VCol>
    <VCol class="notify-col text-md-right text-sm-center set-all-text"> Set all </VCol>
    <VCol class="switches-col mr-lg-0 mr-md-4 mr-sm-0">
      <VRow>
        <VCol class="switch-col email-col">
          <h4 v-if="areTitlesShown" class="col-inner-title">Email notification</h4>
          <IconLock v-if="areAllChildrenMandatory(NotificationChannelType.EMAIL)" class="lock" />
          <BFormCheckbox
            :checked="isTargettingEmail"
            :disabled="areAllChildrenMandatory(NotificationChannelType.EMAIL)"
            :class="calcStyles(NotificationChannelType.EMAIL)"
            name="check-button"
            switch
            @change="onTargetChange(NotificationChannelType.EMAIL, $event)"
          />
        </VCol>
        <VCol class="switch-col">
          <h4 v-if="areTitlesShown" class="col-inner-title">In-app notification</h4>
          <IconLock v-if="areAllChildrenMandatory(NotificationChannelType.IN_APP)" class="lock" />
          <BFormCheckbox
            :checked="isTargettingInApp"
            :disabled="areAllChildrenMandatory(NotificationChannelType.IN_APP)"
            :class="calcStyles(NotificationChannelType.IN_APP)"
            name="check-button"
            switch
            @change="onTargetChange(NotificationChannelType.IN_APP, $event)"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>
