var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.marksClasses},[_vm._t("step",function(){return [_c('div',{class:_vm.stepClasses,style:([
        _vm.stepStyle,
        _vm.mark.style || {},
        _vm.mark.active && _vm.stepActiveStyle ? _vm.stepActiveStyle : {},
        _vm.mark.active && _vm.mark.activeStyle ? _vm.mark.activeStyle : {},
      ])})]}),(!_vm.hideLabel)?_vm._t("label",function(){return [_c('div',{class:_vm.labelClasses,style:([
        _vm.labelStyle,
        _vm.mark.labelStyle || {},
        _vm.mark.active && _vm.labelActiveStyle ? _vm.labelActiveStyle : {},
        _vm.mark.active && _vm.mark.labelActiveStyle ? _vm.mark.labelActiveStyle : {},
      ]),on:{"click":_vm.labelClickHandle}},[_vm._v(" "+_vm._s(_vm.mark.label)+" ")])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }