/**
 * Authority List
 */
export enum AuthorityType {
  /**
   * Branding authorities
   */
  MANAGE_BRANDS = 'MANAGE_BRANDS', // [ '/brands' ]

  /**
   * Authentication authorities
   */
  IMPERSONATE = 'IMPERSONATE', // [ 'admin/switchuser/{userId}' ]
  VIEW_USER_ACCOUNTS = 'VIEW_USER_ACCOUNTS', // [ 'users', 'users/{id}', 'users/tfa-types' ]
  MANAGE_USER_ACCOUNTS = 'MANAGE_USER_ACCOUNTS', // [ 'users' ]
  VIEW_AUTHENTICATED_USER_PRIVATE_INFO = 'VIEW_AUTHENTICATED_USER_PRIVATE_INFO', // [ 'session/private' ]
  ACT_ON_BEHALF_OF = 'ACT_ON_BEHALF_OF',
  TRADE_ON_BEHALF_OF = 'TRADE_ON_BEHALF_OF',
  PAY_ON_BEHALF_OF = 'PAY_ON_BEHALF_OF',

  /**
   * Authorization authorities
   */
  VIEW_PUBLIC_ROLE_AUTHORITIES = 'VIEW_PUBLIC_ROLE_AUTHORITIES', // [ 'roles/{role}' ]
  VIEW_ROLE_AUTHORITIES = 'VIEW_ROLE_AUTHORITIES', // [ 'manage/{role}' ]
  UPDATE_ROLE_AUTHORITIES = 'UPDATE_ROLE_AUTHORITIES', // [ 'manage/{role}' ]
  VIEW_ALL_PERMISSIONS = 'VIEW_ALL_PERMISSIONS', // [ '/permissions' ]
  VIEW_CLIENT_PERMISSIONS = 'VIEW_CLIENT_PERMISSIONS', // [ '/clients/{id}/permissions' ]
  UPDATE_CLIENT_PERMISSIONS = 'UPDATE_CLIENT_PERMISSIONS', // [ 'clients/{id}/permissions' ]
  VIEW_GROUP_PERMISSIONS = 'VIEW_GROUP_PERMISSIONS', // [ 'groups/{id}/permissions' ]
  UPDATE_GROUP_PERMISSIONS = 'UPDATE_GROUP_PERMISSIONS', // [ 'groups/{id}/permissions' ]
  VIEW_USER_PERMISSIONS = 'VIEW_USER_PERMISSIONS', // [ '/users/{id}/permissions' ]
  UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS', // [ '/users/{id}/permission' ]

  /**
   * Customer manager authorities
   */
  VIEW_MEMBERS = 'VIEW_MEMBERS', // [ 'individuals' ]
  VIEW_MEMBER_GROUPS = 'VIEW_MEMBER_GROUPS', // [ 'individuals/groups' ]
  REGISTER_INVITED_MEMBER = 'REGISTER_INVITED_MEMBER', // [ 'individuals/${id}/register' ]
  NOTIFY_NOT_SERVED = 'NOTIFY_NOT_SERVED', // [ 'individuals/registernotserved' ]
  CREATE_PARTNER = 'CREATE_PARTNER', // [ 'partners' ]
  UPDATE_PARTNER = 'UPDATE_PARTNER', // [ 'partners/${partnerId}' ]
  DELETE_PARTNER = 'DELETE_PARTNER', // [ 'partners/${partnerId}' ]
  VIEW_PARTNER_GROUPS = 'VIEW_PARTNER_GROUPS', // [ 'partners/${partnerId}/groups' ]
  MANAGE_PARTNER_GROUPS = 'MANAGE_PARTNER_GROUPS', // [ 'partners/{id}/groups' ]
  VIEW_TARGET_PARTNER = 'VIEW_TARGET_PARTNER', // [ 'partners/{partnerId}' ]
  VIEW_ALL_PARTNERS = 'VIEW_ALL_PARTNERS', // [ 'partners/{partnerId}/documents', 'partners']
  VIEW_PARTNER_SETTINGS = 'VIEW_PARTNER_SETTINGS', // [ 'partners/${partnerId}/settings' ]
  UPDATE_PARTNER_SETTINGS = 'UPDATE_PARTNER_SETTINGS', // [ 'partners/${partnerId}/settings' ]
  CREATE_CLIENT = 'CREATE_CLIENT', // [ 'clients' ]
  DELETE_CLIENT = 'DELETE_CLIENT', // [ 'clients/{id}' ]
  UPDATE_CLIENT = 'UPDATE_CLIENT', // [ 'clients/{clientId}' ]
  VIEW_CLIENT_GROUPS = 'VIEW_CLIENT_GROUPS', // [ 'clients/{id}/groups' ]
  MANAGE_CLIENT_GROUPS = 'MANAGE_CLIENT_GROUPS', // [ 'clients/{id}/groups' ]
  VIEW_CLIENT_ASSIGNEES = 'VIEW_CLIENT_ASSIGNEES', // [ 'clients/{id}/assignees' ]
  MANAGE_CLIENT_ASSIGNEES = 'MANAGE_CLIENT_ASSIGNEES', // [ 'clients/{id}/assignees' ]
  UPDATE_CLIENT_SETTINGS = 'UPDATE_CLIENT_SETTINGS', // [ 'clients/${id}/settings' ]
  VIEW_CLIENT_SETTINGS = 'VIEW_CLIENT_SETTINGS', // [ 'clients/${id}/settings' ]
  MANAGE_MEMBERS = 'MANAGE_MEMBERS', // [ 'individuals' ]
  MANAGE_MEMBER_ASSIGNMENTS = 'MANAGE_MEMBER_ASSIGNMENTS', // [ 'individuals/{individualID}/assignments' ]
  VIEW_MEMBER_ASSIGNMENTS = 'VIEW_MEMBER_ASSIGNMENTS', // [ 'individuals/{individualID}/assignments' ]
  INVITE_MEMBER = 'INVITE_MEMBER', // [ 'individuals/invite' ]
  VIEW_MEMBER_INVITE_DETAILS = 'VIEW_MEMBER_INVITE_DETAILS', // [ 'individuals/invite' ]
  VERIFY_SELF_REGISTRATION_EMAIL = 'VERIFY_SELF_REGISTRATION_EMAIL', // [ 'individuals/verify' ]
  VIEW_CLIENTS = 'VIEW_CLIENTS', // [ 'clients', 'clients/reports/activity' ]
  EXPORT_CLIENT_REPORTS = 'EXPORT_CLIENT_REPORTS', // [ 'clients/export' ]
  EXPORT_PARTNERS_REPORT = 'EXPORT_PARTNERS_REPORT', // [ 'partners/export' ]
  VIEW_ALL_COMPLIANCE_CASES_REPORTS = 'VIEW_ALL_COMPLIANCE_CASES_REPORTS', // [ 'compliance/cases' ]
  EXPORT_COMPLIANCE_CASES_REPORT = 'EXPORT_COMPLIANCE_CASES_REPORT', // [ 'compliance/cases/export ]
  EXPORT_MEMBERS_REPORT = 'EXPORT_MEMBERS_REPORT', // [ 'individuals/export' ]
  VIEW_TARGET_CLIENT = 'VIEW_TARGET_CLIENT', // [ 'clients/{clientId}' ]
  VIEW_MEMBER_REPORTS = 'VIEW_MEMBER_REPORTS', // [ '/individuals' ]
  MANAGE_CLIENT_OWNER = 'MANAGE_CLIENT_OWNER', // [ 'clients/${clientId}/owner/${individualId}', 'clients/${clientId}/owner',  'clients/${clientId}/terms-and-conditions/reset' ]

  /**
   * Spreads authorities
   */
  VIEW_SPREADS = 'VIEW_SPREADS', // [ '/clients/{clientId}/spreads' ]
  UPDATE_SPREADS = 'UPDATE_SPREADS', // [ 'spreads/clients', 'spreads/partners' ]

  /**
   * Compliance authorities
   */
  CREATE_COMPLIANCE_CASE = 'CREATE_COMPLIANCE_CASE', // [ 'compliance/cases' ]
  VIEW_TARGET_COMPLIANCE_CASE_REPORT = 'VIEW_TARGET_COMPLIANCE_CASE_REPORT', // [ 'compliance/cases/{clientId}' ]
  VIEW_COMPLIANCE_CASE_DOCUMENTS = 'VIEW_COMPLIANCE_CASE_DOCUMENTS', // [ 'clients/cases/{clientId}/documents' ]
  DOWNLOAD_COMPLIANCE_CASE_DOCUMENTS = 'DOWNLOAD_COMPLIANCE_CASE_DOCUMENTS',
  UPLOAD_COMPLIANCE_CASE_DOCUMENTS = 'UPLOAD_COMPLIANCE_CASE_DOCUMENTS', // [ 'clients/cases/{clientId}/documents' ]
  DELETE_COMPLIANCE_CASE_DOCUMENTS = 'DELETE_COMPLIANCE_CASE_DOCUMENTS', // [ 'clients/cases/{clientId}/documents' ]

  VIEW_UBO_DOCUMENTS = 'VIEW_UBO_DOCUMENTS', // [ 'clients/cases/{clientId}/ubo/documents' ]
  UPLOAD_UBO_DOCUMENTS = 'UPLOAD_UBO_DOCUMENTS', // [ 'clients/cases/{clientId}/ubo/documents' ]
  DELETE_UBO_DOCUMENTS = 'DELETE_UBO_DOCUMENTS', // [ 'clients/cases/{clientId}/ubo/documents' ]
  DOWNLOAD_UBO_DOCUMENTS = 'DOWNLOAD_UBO_DOCUMENTS', // [ 'clients/cases/{clientId}/ubo/documents' ]

  DELETE_COMPLIANCE_CASE = 'DELETE_COMPLIANCE_CASE', // [ 'compliance/cases/{clientId}' ]
  VIEW_COMPLIANCE_CASES = 'VIEW_COMPLIANCE_CASES', // [ 'compliance/cases' ]
  VIEW_UBOS = 'VIEW_UBOS', // [ 'clients/cases/{clientId}/ubo' ]
  MANAGE_UBOS = 'MANAGE_UBOS', // [ 'clients/cases/{clientId}/ubo' ]
  UPDATE_COMPLIANCE_CASE = 'UPDATE_COMPLIANCE_CASE', // [ 'clients/cases/{clientId}/submit', 'clients/cases/{id}/documents/skip', 'individuals/{id}']

  /**
   * Customer reference authorities
   */
  EDIT_SANCTIONED_COUNTRIES = 'EDIT_SANCTIONED_COUNTRIES', // [ 'countries/{cc}' ]
  UPDATE_MANDATORY_CURRENCY_FIELDS = 'UPDATE_MANDATORY_CURRENCY_FIELDS', // [ 'address-fields/{currency}' ]

  /**
   * Risk management and credit/limits authorities
   */
  VIEW_ALL_RISK_REPORTS = 'VIEW_ALL_RISK_REPORTS', // [ 'aml-monitoring', 'risk/{x}' ]
  EXPORT_ALL_RISK_REPORTS = 'EXPORT_ALL_RISK_REPORTS', // [ 'aml-monitoring/export', 'risk/{x}/export' ]
  EXPORT_DELTA_RISK_REPORTS = 'EXPORT_DELTA_RISK_REPORTS', // [ 'risk/delta-risk/clients/export' ]
  VIEW_DELTA_RISK_REPORTS = 'VIEW_DELTA_RISK_REPORTS', // [ 'risk/delta-risk/clients' ]
  VIEW_EOD_SPOT_RATES_REPORTS = 'VIEW_EOD_SPOT_RATES_REPORTS', // [ 'risk/eod-spot-rates' ]
  VIEW_CLIENT_USAGE_REPORTS = 'VIEW_CLIENT_USAGE_REPORTS', // [ 'risk/usage' ]
  VIEW_CLIENT_MARGIN_CREDIT_USAGE_REPORTS = 'VIEW_CLIENT_MARGIN_CREDIT_USAGE_REPORTS', // [ 'risk/margin-credits-usage', 'credit/usage-reports/{id}' ]
  REQUEST_RISK_DECISION = 'REQUEST_RISK_DECISION', // [ 'risk-decision/{clientId}' ]
  VIEW_MARGIN_CREDIT_USAGE_REPORTS = 'VIEW_MARGIN_CREDIT_USAGE_REPORTS', // [ 'credit/usage-reports' ]
  MANAGE_CREDIT_PROFILES = 'MANAGE_CREDIT_PROFILES', // [ 'credit/profiles' ]
  VIEW_HISTORICAL_CREDIT_PROFILES = 'VIEW_HISTORICAL_CREDIT_PROFILES', // [ 'credit/profiles' ]
  VIEW_CREDIT_PROFILES = 'VIEW_CREDIT_PROFILES', // [ 'credit/profiles' ]
  NOTIFY_CREDIT_TRADING_DESK = 'NOTIFY_CREDIT_TRADING_DESK', // [ 'credit/request' ]
  NOTIFY_LIMITS_TRADING_DESK = 'NOTIFY_LIMITS_TRADING_DESK', // [ 'limits/request' ]
  VIEW_CREDIT_AUDIT_RECORDS = 'VIEW_CREDIT_AUDIT_RECORDS', // [ 'credit/audit-records' ]
  VIEW_LIMITS_AUDIT_RECORDS = 'VIEW_LIMITS_AUDIT_RECORDS', // [ 'limits/audit-records' ]
  MANAGE_CREDIT_REQUESTS = 'MANAGE_CREDIT_REQUESTS', // [ 'credit/requests' ]
  VIEW_CREDIT_REQUESTS = 'VIEW_CREDIT_REQUESTS', // [ 'credit/requests' ]
  VIEW_HISTORICAL_LIMITS_PROFILES = 'VIEW_HISTORICAL_LIMITS_PROFILES', // [ 'limits/historical-profiles' ]
  VIEW_ALL_LIMITS_PROFILES = 'VIEW_ALL_LIMITS_PROFILES', // [ 'limits/historical-profiles' ]
  VIEW_LIMITS_REQUESTS = 'VIEW_LIMITS_REQUESTS', // [ 'limits/requests' ]
  CREATE_LIMITS_REQUEST = 'CREATE_LIMITS_REQUEST', // [ 'limits/requests' ]
  DELETE_LIMITS_REQUEST = 'DELETE_LIMITS_REQUEST', // [ 'limits/requests' ]
  UPDATE_LIMITS_REQUEST = 'UPDATE_LIMITS_REQUEST', // [ 'limits/requests' ]
  VIEW_TARGET_USAGE_REPORTS = 'VIEW_TARGET_USAGE_REPORTS', // [ 'limits/usage-reports/{id}' ]
  VIEW_ALL_USAGE_REPORTS = 'VIEW_ALL_USAGE_REPORTS', // [ 'limits/usage-reports' ]
  VIEW_TARGET_LIMITS_PROFILE = 'VIEW_TARGET_LIMITS_PROFILE', // [ 'limits/profiles/{id}' ]
  MANAGE_LIMITS_PROFILE = 'MANAGE_LIMITS_PROFILE', // [ 'limits/profiles' ]
  VERIFY_OPEN_TRADE_LIMITS = 'VERIFY_OPEN_TRADE_LIMITS', // [ 'limits/{clientId}/open-trade/verify' ]
  REQUEST_CREDIT_FACILITY = 'REQUEST_CREDIT_FACILITY',

  /**
   * Notification authorities
   */
  VIEW_ALL_NOTIFICATION_SETTINGS = 'VIEW_ALL_NOTIFICATION_SETTINGS', // ['settings/notifications' ]
  VIEW_PARTNER_NOTIFICATION_SETTING = 'VIEW_PARTNER_NOTIFICATION_SETTING', // [ 'settings/notifications' ]
  MANAGE_PARTNER_NOTIFICATION_SETTINGS = 'MANAGE_PARTNER_NOTIFICATION_SETTINGS', // [ 'settings/notifications' ]
  VIEW_CLIENT_NOTIFICATION_SETTING = 'VIEW_CLIENT_NOTIFICATION_SETTING', // [ 'settings/notifications' ]
  MANAGE_CLIENT_NOTIFICATION_SETTINGS = 'MANAGE_CLIENT_NOTIFICATION_SETTINGS', // [ 'settings/notifications' ]
  VIEW_ALL_RATE_ALERTS = 'VIEW_ALL_RATE_ALERTS', // [ 'clients/rate-alerts' ]
  VIEW_RATE_ALERTS = 'VIEW_RATE_ALERTS', // [ 'settings/rate-alerts' ]
  VIEW_SMS_TEMPLATES = 'VIEW_SMS_TEMPLATES', // [ 'templates/sms' ]
  VIEW_EMAIL_TEMPLATES = 'VIEW_EMAIL_TEMPLATES', // [ 'templates/email' ]
  UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE', // [ 'templates/email/{id}' ]
  UPDATE_SMS_TEMPLATE = 'UPDATE_SMS_TEMPLATE', // [ 'templates/sms' ]
  MANAGE_NOTIFICATIONS = 'MANAGE_NOTIFICATIONS', // [ 'notifications' ]
  VIEW_NOTIFICATIONS = 'VIEW_NOTIFICATIONS', // [ 'notifications' ]
  VIEW_PARTNER_NOTIFICATION_SETTINGS_SPEC = 'VIEW_PARTNER_NOTIFICATION_SETTINGS_SPEC', // [ 'partners/settings/notifications/spec' ]
  VIEW_CLIENT_NOTIFICATION_SETTINGS_SPEC = 'VIEW_CLIENT_NOTIFICATION_SETTINGS_SPEC', // [ 'clients/settings/notifications/spec' ]
  SEND_MCF_SCHEDULED_EMAIL = 'SEND_MCF_SCHEDULED_EMAIL', // [ 'notifications/scheduled/mcf' ]
  SEND_POSITION_LIMITS_SCHEDULED_EMAIL = 'SEND_POSITION_LIMITS_SCHEDULED_EMAIL', // [ 'notifications/scheduled/position-limits' ]
  RESEND_NOTIFICATION = 'RESEND_NOTIFICATION', // [ 'notifications/resend' ]
  MANAGE_RATE_ALERTS = 'MANAGE_RATE_ALERTS', // [ 'settings/rate-alerts' ]

  /**
   * Call engine authorities
   */
  LIST_CALL_GROUPS = 'LIST_CALL_GROUPS', // [ 'groups' ]
  MAKE_CALL = 'MAKE_CALL', // [ 'calls' ]
  VIEW_CALLS_INFORMATION = 'VIEW_CALLS_INFORMATION', // [ 'calls', 'calls/{id}' ]
  MANAGE_CALL_GROUPS = 'MANAGE_CALL_GROUPS', // [ 'group/{id}' ]
  VIEW_ALL_ONGOING_CALLS = 'VIEW_ALL_ONGOING_CALLS', // [ 'ongoing/{id}' ]
  MANAGE_PROVISIONED_NUMBERS = 'MANAGE_PROVISIONED_NUMBERS', // [ 'incoming-calls' ]
  LIST_AVAILABLE_NUMBERS = 'LIST_AVAILABLE_NUMBERS', // [ 'incoming-calls/numbers/available', 'incoming-calls/numbers' ]
  GET_CALL_RECORDINGS = 'GET_CALL_RECORDINGS', // [ 'calls/${id}/recording' ]

  /**
   * Pricing engine and termsheet authorities
   */
  VIEW_OPTION_STATUS = 'VIEW_OPTION_STATUS', // [ 'option/status' ]
  VIEW_TRADING_THRESHOLDS = 'VIEW_TRADING_THRESHOLDS', // [ 'trading-thresholds/trading-hours', 'trading-thresholds/invalid-days', 'trading-thresholds/valid-days', 'trading-thresholds/trading-cutoff']
  VIEW_INSTRUMENT = 'VIEW_INSTRUMENT', // [ '/instruments' ]
  AMEND_PRICE_RATE = 'AMEND_PRICE_RATE', // [ 'prices/amend' ]
  VIEW_STRIKE_RATES = 'VIEW_STRIKE_RATES', // [ 'default/strikes' ]
  REQUEST_PRICES = 'REQUEST_PRICES', // ['prices', 'prices/bulk', 'prices/bulk/{id}', 'prices/cashout', 'prices/spot', 'prices/spot/{id}', 'prices/forward', 'prices/forward/{id}', 'prices/windowForward', 'prices/windowForward/{id}',  'prices/windowForward/{id}/drawdown', 'prices/flexibleForward',  'prices/flexibleForward/{id}', 'prices/flexibleForward/{id}/drawdown', 'prices/option/{id}', '/participatingForward/{id}']

  /**
   * Trading manager authorities
   */
  OPEN_TRADE = 'OPEN_TRADE', // [ 'trades' ]
  CANCEL_TRADE = 'CANCEL_TRADE', // [ 'trade/{id}/cancel' ]
  ROLLFORWARD_TRADE = 'ROLLFORWARD_TRADE', // [ 'trade/{id}/rollforward' ]
  VIEW_TRADING_STATUS = 'VIEW_TRADING_STATUS', // [ 'trades/status' ]
  UPDATE_TRADES = 'UPDATE_TRADES', // [ 'trades/{beneficiaryId} ]
  DRAWDOWN_TRADE = 'DRAWDOWN_TRADE', // [ 'trades/{tradeId}/drawdown' ]
  VIEW_COLLATERALS = 'VIEW_COLLATERALS', // [ 'collaterals/clients' ]
  VIEW_COLLATERAL_CONFIGURATIONS = 'VIEW_COLLATERAL_CONFIGURATIONS', // [ 'collaterals/configurations' ]
  UPDATE_COLLATERAL_CONFIGURATIONS = 'UPDATE_COLLATERAL_CONFIGURATIONS', // [ 'collaterals/configurations' ]
  AMEND_TRADES = 'AMEND_TRADES', // [ 'trades/{tradeId}/amend' ]
  SYNCHRONIZE_SETTLEMENT_DATE = 'SYNCHRONIZE_SETTLEMENT_DATE', // [ 'trades/{id}/synchronize-settlement-date' ]
  VERIFY_COLLATERALS = 'VERIFY_COLLATERALS', // [ '/clients/{clientId}/{action}/verify' ]
  VIEW_TRADES = 'VIEW_TRADES', // [ 'trades', 'trades/{id}', 'trades/export' ]
  MANAGE_SEMI_AUTOMATED_VANILLA_OPTIONS_TRADES = 'MANAGE_SEMI_AUTOMATED_VANILLA_OPTIONS_TRADES',
  /**
   * Wallet manager authorities
   */
  EMULATE_FUNDING = 'EMULATE_FUNDING', // [ 'funding-details']
  VALIDATE_OPEN_TRADE = 'VALIDATE_OPEN_TRADE', // [ '/validateopentrade/{ownerId}' ]
  VIEW_FUNDING_DETAILS = 'VIEW_FUNDING_DETAILS', // [ 'funding-details' ]
  VIEW_CLIENT_TOTAL_OUTSTANDING_FEES = 'VIEW_CLIENT_TOTAL_OUTSTANDING_FEES', // [ 'wallets/fees-debt/client' ]
  VIEW_PARTNER_TOTAL_OUTSTANDING_FEES = 'VIEW_PARTNER_TOTAL_OUTSTANDING_FEES', // [ 'wallets/fees-debt/partner' ]
  MAKE_PAYMENTS = 'MAKE_PAYMENTS', // [ 'wallets/balances/certificate', 'wallets/{id}/withdraw/{action}', 'wallets/{id}/schedule/withdraw/{action}' ]
  MANAGE_WALLETS = 'MANAGE_WALLETS', // [ 'wallets', 'wallets/fees/client/{clientId}/settle' ]
  VIEW_COMMISSION_WITHDRAWALS = 'VIEW_COMMISSION_WITHDRAWALS', // [ '/wallets/reports/withdrawals/commissions' ]
  VIEW_HISTORIC_WALLET_RECORDS = 'VIEW_HISTORIC_WALLET_RECORDS', // [ 'wallets/historic' ]
  CREATE_WALLET_FEE = 'CREATE_WALLET_FEE', // [ 'wallets/fees' ]
  UPDATE_WALLET_FEE = 'UPDATE_WALLET_FEE', // [ 'wallets/fees' ]
  VIEW_WALLET_FEES = 'VIEW_WALLET_FEES', // [ 'wallets/fees' ]
  DELETE_WALLET_FEE = 'DELETE_WALLET_FEE', // [ 'wallet/fees/{id}' ]
  VIEW_TRANSACTIONS = 'VIEW_TRANSACTIONS', // [ 'reports/transactions', 'reports/transactions/{id}', '/wallets/transactions', '/wallets/transactions/{id}' ]
  VIEW_WALLETS = 'VIEW_WALLETS', // [ 'wallets' ]
  EXPORT_TRANSACTIONS = 'EXPORT_TRANSACTIONS', // [ 'wallets/transactions/export' ]
  EXPORT_WALLETS_REPORT = 'EXPORT_WALLETS_REPORT', // [ 'wallets/export' ]
  VIEW_OUTSTANDING_FEE_REPORTS = 'VIEW_OUTSTANDING_FEE_REPORTS', // [ 'reports/fees/outstanding-fees' ]
  EXPORT_OUTSTANDING_FEE_REPORT = 'EXPORT_OUTSTANDING_FEE_REPORT', // [ 'reports/fees/outstanding-fees/export' ]
  EXPORT_BENEFICIARIES_REPORTS = 'EXPORT_BENEFICIARIES_REPORTS', // [ 'beneficiaries/export' ]
  VIEW_FEES = 'VIEW_FEES', // [ 'fees', 'fees/complete', 'fees/list' ]
  UPDATE_FEES = 'UPDATE_FEES', // [ 'fees/{feeId}' ]

  /**
   * Beneficiaries authorities
   */
  UPDATE_MANDATORY_BENEFICIARY_FIELDS = 'UPDATE_MANDATORY_BENEFICIARY_FIELDS', // [ 'beneficiary-fields' ]
  MANAGE_BENEFICIARIES = 'MANAGE_BENEFICIARIES', // [ 'beneficiaries' ]
  VIEW_BENEFICIARIES = 'VIEW_BENEFICIARIES', // [ 'beneficiaries', 'beneficiaries/export' ]

  /**
   * Reconciliation authorities
   */
  VIEW_RECONCILIATIONS_REPORTS = 'VIEW_RECONCILIATIONS_REPORTS', // [ 'reconciliations/wallets', 'reconciliations/payments', 'reconciliations/transfers' ]
  EXPORT_RECONCILIATIONS_REPORT = 'EXPORT_RECONCILIATIONS_REPORT', // [ 'reconciliations/{action}/export' ]

  /**
   * Financial reports authorities
   */
  VIEW_COB_REPORTS = 'VIEW_COB_REPORTS', // [ 'cob-balances' ]
  EXPORT_COB_REPORT = 'EXPORT_COB_REPORT', // [ 'cob-balances/export' ]
  VIEW_SETTLED_COMMISSIONS_REPORTS = 'VIEW_SETTLED_COMMISSIONS_REPORTS', // [ 'settled-commissions' ]
  EXPORT_SETTLED_COMMISSIONS_REPORT = 'EXPORT_SETTLED_COMMISSIONS_REPORT', // [ 'settled-commissions/export' ]
  VIEW_PARTNER_COMMISSION_REPORTS = 'VIEW_PARTNER_COMMISSION_REPORTS', // [ 'partner-commissions' ]
  EXPORT_PARTNER_COMMISSION_REPORTS = 'EXPORT_PARTNER_COMMISSION_REPORTS', // [ 'partner-commissions/export', 'partner-commissions/statements/export' ]
  VIEW_PARTNER_COMMISSIONS = 'VIEW_PARTNER_COMMISSIONS', // [ 'partners/commissions', 'partners/{id}/commissions' ]
  UPDATE_PARTNER_COMMISSIONS = 'UPDATE_PARTNER_COMMISSIONS', // [ 'partners/{id}/commissions' ]
  VIEW_TOP_TRADING_PARTNERS = 'VIEW_TOP_TRADING_PARTNERS', // [ 'reports/top-accounts/partners' ]
  VIEW_QUOTE_REPORTS = 'VIEW_QUOTE_REPORTS', // [ 'quotes' ]
  EXPORT_QUOTE_REPORTS = 'EXPORT_QUOTE_REPORTS', // [ 'quotes/export' ]
  VIEW_CASH_TRANSACTION_REPORTS = 'VIEW_CASH_TRANSACTION_REPORTS', // [ 'cashtransactions' ]
  EXPORT_LIQUIDITY_PROVIDER_REPORTS = 'EXPORT_LIQUIDITY_PROVIDER_REPORTS', // [ 'liquidityproviders/export' ]
  VIEW_LIQUIDITY_PROVIDER_REPORTS = 'VIEW_LIQUIDITY_PROVIDER_REPORTS', // [ '/liquidityproviders' ]
  VIEW_BANK_ACCOUNT_REPORTS = 'VIEW_BANK_ACCOUNT_REPORTS', // [ 'bankaccounts' ]
  EXPORT_BANK_ACCOUNT_REPORTS = 'EXPORT_BANK_ACCOUNT_REPORTS', // [ 'bankaccounts/export' ]
  EXPORT_TRADE_REPORT = 'EXPORT_TRADE_REPORT', // [ 'reports/commissions/future/export', 'trading-reports/trades-list/export' ]
  VIEW_TRADE_REPORTS = 'VIEW_TRADE_REPORTS', // [ 'trades/reports/{count | transaction-volume | top-accounts | trades-with-commission | commissions'}, 'trading-reports/trades-list'  ]
  VIEW_END_OF_MONTH_STATEMENTS = 'VIEW_END_OF_MONTH_STATEMENTS', // [ 'eom/statement' ]
  EXPORT_END_OF_MONTH_STATEMENTS = 'EXPORT_END_OF_MONTH_STATEMENTS', // [ 'eom/statement/export' ]
  VIEW_PNL_REPORTS = 'VIEW_PNL_REPORTS', // [ 'profit-and-losses' ]
  EXPORT_PNL_REPORT = 'EXPORT_PNL_REPORT', // [ 'profit-and-losses/export' ]
  EXPORT_SETTLEMENT_REPORTS = 'EXPORT_SETTLEMENT_REPORTS', // [ 'trading-reports/upcoming-settlement/export' ]
  VIEW_SETTLEMENT_REPORTS = 'VIEW_SETTLEMENT_REPORTS', // [ 'trading-reports/upcoming-settlement' ]

  /**
   * Market Data authorities
   */
  GENERATE_EOD_RATES = 'GENERATE_EOD_RATES', // [ 'generate/end-of-day' ]
  VIEW_RATES = 'VIEW_RATES', // [ 'rates' ]

  /**
   * Voice trades authorities
   */
  VIEW_CASH_TRANSACTIONS = 'VIEW_CASH_TRANSACTIONS', // [ 'cashtransactions' ]
  EXPORT_CASH_TRANSACTION_REPORTS = 'EXPORT_CASH_TRANSACTION_REPORTS', // [ 'cashtransactions/export' ]
  MANAGE_CASH_TRANSACTIONS = 'MANAGE_CASH_TRANSACTIONS', // [ 'cashtransactions' ]
  VIEW_LIQUIDITY_PROVIDERS = 'VIEW_LIQUIDITY_PROVIDERS', // [ '/liquidityproviders' ]
  MANAGE_LIQUIDITY_PROVIDERS = 'MANAGE_LIQUIDITY_PROVIDERS', // [ '/liquidityproviders/{id}' ]
  VIEW_BANK_ACCOUNTS = 'VIEW_BANK_ACCOUNTS', // [ 'bankaccounts' ]
  MANAGE_BANK_ACCOUNTS = 'MANAGE_BANK_ACCOUNTS', // [ 'bankaccounts' ]
  MANAGE_VOICE_TRADES = 'MANAGE_VOICE_TRADES', // [ 'voicetrades/{action}', 'voicetrades/{id}' ]
  EXPORT_VOICE_TRADE_REPORT = 'EXPORT_VOICE_TRADE_REPORT', // [ *multiple* ]
  VIEW_VOICE_TRADES = 'VIEW_VOICE_TRADES', // [ *multiple* ]
  EDIT_SETTLEMENT_REMINDER_ADVANCED_DAYS = 'EDIT_SETTLEMENT_REMINDER_ADVANCED_DAYS', // [ 'settings/${key}' ]
  VIEW_SETTLEMENT_REMINDER_ADVANCED_DAYS = 'VIEW_SETTLEMENT_REMINDER_ADVANCED_DAYS', // [ 'settings/${key}' ]

  /**
   * Software updates authorities
   */
  MANAGE_SOFTWARE_UPDATES = 'MANAGE_SOFTWARE_UPDATES', // [ 'software-updates' ]
  VIEW_SOFTWARE_UPDATES = 'VIEW_SOFTWARE_UPDATES', // [ 'software-updates' ]
  VIEW_SOFTWARE_REVISIONS = 'VIEW_SOFTWARE_REVISIONS', // [ 'software-updates/{id}/revisions' ]

  /**
   * Logs authorities
   */
  VIEW_VLOGS = 'VIEW_VLOGS', // [ 'operations' ]

  /**
   * Product catalogue authorities
   */
  MANAGE_PRODUCT_CATALOGUE = 'MANAGE_PRODUCT_CATALOGUE', // [ 'catalogues' ]
  UPDATE_INSTRUMENT = 'UPDATE_INSTRUMENT', // [ 'instruments/{id}' ]

  /**
   * System configurations authorities
   */
  VIEW_ADMIN_CONFIGURATIONS = 'VIEW_ADMIN_CONFIGURATIONS',
  UPDATE_ADMIN_CONFIGURATION = 'UPDATE_ADMIN_CONFIGURATION',
  VIEW_API_KEYS = 'VIEW_API_KEYS', // [ '/api-keys ]
  MANAGE_API_KEYS = 'MANAGE_API_KEYS', // [ '/api-keys ]
  VIEW_WEBHOOKS = 'VIEW_WEBHOOKS', // [ 'webhooks' ]
  MANAGE_WEBHOOKS = 'MANAGE_WEBHOOKS', // [ 'webhooks' ]
  RESUME_SUSPEND_TRADING = 'RESUME_SUSPEND_TRADING', // [ 'trades/suspend', 'trades/resume' ]
  START_PARTNER_COMMISSION_CURRENCY_CONVERSIONS = 'START_PARTNER_COMMISSION_CURRENCY_CONVERSIONS',

  /**
   * Coutts authorities
   */
  MANAGE_COUTTS_BALANCES = 'MANAGE_COUTTS_BALANCES', // [ 'coutts/balance' ]

  EXPORT_PAYMENTS = 'EXPORT_PAYMENTS', // [ 'payments/export' ]
  VIEW_PAYMENTS = 'VIEW_PAYMENTS', // [ 'payments', 'payments/{id}',  ]

  /**
   * Reconcilable transactions authorities
   */
  VIEW_RECONCILABLE_TRANSACTIONS = 'VIEW_RECONCILABLE_TRANSACTIONS',
  RECONCILE_TRANSACTIONS = 'RECONCILE_TRANSACTIONS',
}
