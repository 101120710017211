<script setup lang="ts">
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import { computed, useAttrs, ref } from 'vue';
import { BoxGridItemProps } from './interfaces';
import ManagedLoadingOverlay from 'ah-common-lib/src/common/components/overlays/ManagedLoadingOverlay.vue';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';

/**
 * Box Grid Item
 * Manages positioning of and item inside a BoxGrid, and includes embedded loading overlays
 *
 * Has no inherent styling
 * (see BoxGridBlock for usage of this component with a specific style and markup variation)
 *
 * Exposes LoadingOverlay/ManagedLoading overlay events via v-on="$listeners"
 * FIXME: expose events declaratively, so Typescript can pick up on them in parents
 *
 * Slots:
 * - default: Content inside BoxGridItem, will not display if in error/loading state (managed by LoadingOverlay)
 * - before-content: Markup to display before content in DOM
 * - after-content: Markup to display after content in DOM
 */

const props = defineProps(BoxGridItemProps);

const attrs = useAttrs();

const overlayProps = computed(() => {
  return { variant: 'box', opacity: '0.85', ...props.loadingOverlayProps };
});

const showLoadingOverlay = computed(() => props.useLoadingOverlay !== false);

const bColAttrs = computed(() => {
  return {
    'align-self': 'start',
    ...mapKeys(attrs, (v, k) => kebabCase(k)),
  };
});

/**
 * Whether the underlying BoxGridItem's Loading overlay is in an error state
 *
 * Updated via @update:isError (untyped, via an internal v-on="$listeners")
 */
const isError = ref<boolean>(false);

/**
 * Whether the underlying BoxGridItem's Loading overlay is in a loading state
 *
 * Updated via @update:isLoading (untyped, via an internal v-on="$listeners")
 */
const isLoading = ref<boolean>(false);
</script>

<template>
  <VCol v-bind="bColAttrs" v-transition-class="animateTransitions !== false && 'animated-col'" v-on="$listeners">
    <div
      :class="[
        'box-grid-item',
        itemClass,
        { 'stretch-v': bColAttrs['align-self'] === 'stretch', loading: isLoading, 'with-error': isError },
      ]"
    >
      <slot name="before-content" v-bind="{ isLoading, isError }" />
      <template v-if="showLoadingOverlay">
        <LoadingOverlay
          v-if="overlayType === 'simple'"
          v-bind="overlayProps"
          v-on="$listeners"
          :isLoading.sync="isLoading"
          :isError.sync="isError"
        >
          <slot v-bind="{ isError, isLoading }" />
        </LoadingOverlay>
        <ManagedLoadingOverlay
          v-else-if="overlayType === 'managed'"
          v-bind="overlayProps"
          v-on="$listeners"
          :isLoading.sync="isLoading"
          :isError.sync="isError"
        >
          <slot v-bind="{ isError, isLoading }" />
        </ManagedLoadingOverlay>
      </template>
      <slot v-bind="{ isLoading, isError }" v-else />
      <slot name="after-content" v-bind="{ isLoading, isError }" />
    </div>
  </VCol>
</template>

<style lang="scss" scoped>
.box-grid-item {
  display: flex;
  flex-direction: column;
  ::v-deep .loading-overlay {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
  }
  &.stretch-v {
    min-height: 100%;
  }
}
</style>
