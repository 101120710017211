<script setup lang="ts">
import LimitsAndCollateralDashboardWidget from 'ah-trades/src/components/limitsAndCollateral/LimitsAndCollateralDashboardWidget.vue';
import NotificationsDashboardWidget from '@/app/components/notifications/NotificationsDashboardWidget.vue';
import OpenPositionsDashboardWidget from '@/app/components/trades/widgets/OpenPositionsDashboardWidget.vue';
import PaymentsDashboardWidget from '@/app/components/payments/PaymentsDashboardWidget.vue';
import UsernameSetupModal from '@/app/components/session/UsernameSetupModal.vue';
import WalletsDashboardWidget from 'ah-wallets/src/components/WalletsDashboardWidget.vue';
import { AuthorityType } from 'ah-api-gateways';
import { computed, ref, watch } from 'vue';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const authStore = useAuthStore();
const services = getServices();
const requestManager = useRequestManager().manager;

const usernameSetupModal = ref<InstanceType<typeof UsernameSetupModal>>();

const canViewTrades = computed(() => authStore.hasAuthorities(AuthorityType.VIEW_TRADES));
const isImpersonated = computed(() => authStore.isImpersonated);

function loadSession() {
  requestManager
    .sameOrCancelAndNew('getSession', services.auth.getSession())
    .subscribe((session) => authStore.setTokenUser(session));
}

watch(
  () => authStore.loggedInUser,
  (user) => {
    if (user && !user.usernameSetupModalDismissed && user.usernameIsDefault && !isImpersonated.value) {
      usernameSetupModal.value?.show();
    }
  },
  { immediate: true }
);

loadSession();
</script>

<template>
  <div class="dashboard-view padded-space mt-3" id="dashboard-view">
    <BoxGrid align-h="start">
      <BoxGridItem cols="12">
        <BoxGrid align-h="start">
          <BoxGridItem align-self="stretch" cols="12" lg="8">
            <NotificationsDashboardWidget class="mb-3" />
          </BoxGridItem>
          <BoxGridBlock align-self="stretch" cols="12" lg="4" title="Upcoming payments">
            <PaymentsDashboardWidget />
          </BoxGridBlock>
          <BoxGridBlock align-self="stretch" v-if="canViewTrades" cols="12" title="Open FX Positions">
            <OpenPositionsDashboardWidget class="open-positions-widget" />
          </BoxGridBlock>
          <BoxGridBlock
            align-self="stretch"
            class="pr-3"
            cols="12"
            md="4"
            title="Wallets"
            :loadingOverlayProps="{ showRetry: true }"
            useLoadingOverlay
          >
            <WalletsDashboardWidget redirect-to="dashboard/wallets" />
          </BoxGridBlock>
          <BoxGridBlock
            cols="12"
            md="4"
            class="px-3"
            title="Trading Limits and Collateral"
            align-self="stretch"
            :loadingOverlayProps="{ showRetry: true }"
            useLoadingOverlay
          >
            <LimitsAndCollateralDashboardWidget redirect-to="dashboard/trades/limits-collateral" />
          </BoxGridBlock>
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
    <UsernameSetupModal ref="usernameSetupModal" />
  </div>
</template>
