<script setup lang="ts">
import { findComponentBasePath } from 'ah-common-lib/src/helpers/route';
import { getCurrentInstance } from 'vue';

const props = defineProps({ path: { type: String, required: false, default: '' } });

// basePath is set on component setup and not expected to react to prop changes
// eslint-disable-next-line vue/no-setup-props-destructure
let basePath = props.path;

const instance = getCurrentInstance();
if (basePath === '' && instance != null) {
  basePath = findComponentBasePath(instance.proxy);
}
</script>

<template>
  <div class="main" id="activity-sidebar-view">
    <h1 class="px-4">Activity</h1>
    <h5 class="menu-header">Clients</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/clients`"> Clients </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/trades`"> Client Trades </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/payments`"> Client Payments </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/onboarding`"> Onboarding </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/outstanding-fees`"> Outstanding Fees </RouterLink>
    </ul>
    <h5 class="menu-header">Reports</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/reports`"> Commission Report </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/commissions-schedule`"> Commission Schedule </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/commissions`"> Commission Analytics </RouterLink>
    </ul>
    <h5 class="menu-header">Trades</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/open-fx`"> Open FX Positions </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/open-options`"> Open Options Positions </RouterLink>
    </ul>
  </div>
</template>
