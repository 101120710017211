import { AmountType } from 'ah-api-gateways';
import { DrawdownDate, TimeFrameDate } from './date';

export enum TradeFundsDestination {
  KEEP = 'KEEP',
  SEND = 'SEND',
}

/**
 * Trade Details object
 * Includes all data necessary to request a price (including time Frame and Drawdown date data)
 */
export interface TradeDetails {
  walletId?: string;
  timeFrame?: TimeFrameDate;
  drawdownDate?: DrawdownDate;
  sellCurrency: string;
  buyCurrency: string;
  amount?: number;
  amountType?: AmountType;
  clientRateMarkup?: number;
  clientRate?: number;
  valid: boolean;
  dirty: boolean;
}
