<script setup lang="ts">
import { ClientType } from 'ah-api-gateways';
import { findComponentBasePath } from 'ah-common-lib/src/helpers/route';
import config from '@/config';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, getCurrentInstance } from 'vue';

const props = defineProps({ path: { type: String, required: false, default: '' } });

const authStore = useAuthStore();

const individualSettingsStore = useIndividualSettingsStore();

const isCompanyClient = computed(() => individualSettingsStore.client?.type === ClientType.COMPANY);

const isAdmin = authStore.isAdminUser;

const showDevTools = computed(() => config.showDevTools);

// eslint-disable-next-line vue/no-setup-props-destructure
let basePath = props.path;

const instance = getCurrentInstance();
if (basePath === '' && instance != null) {
  basePath = findComponentBasePath(instance.proxy);
}

individualSettingsStore.loadClient();
</script>

<template>
  <div class="main" id="settings-sidebar-view">
    <h1 class="px-4">Settings</h1>
    <template v-if="isAdmin">
      <h5 class="menu-header">Info</h5>
      <ul>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/client-information`" v-if="isCompanyClient">
          Information
        </RouterLink>
        <RouterLink tag="li" v-if="isCompanyClient" class="menu-link" :to="`${basePath}/ubos`"> UBOs </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/bank-accounts`"> Bank Accounts </RouterLink>
      </ul>
      <h5 class="menu-header">Manage</h5>
      <ul>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/members`" v-if="isCompanyClient"> Members </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/groups`" v-if="isCompanyClient"> Groups </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/rate-alerts`"> Rate alerts </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/notifications`" append> Notifications </RouterLink>
        <RouterLink tag="li" class="menu-link" :to="`${basePath}/authorisations`" append> Authorisations </RouterLink>
      </ul>
    </template>
    <h5 class="menu-header">General</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/personal-settings`"> Personal Info </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/account`"> Account Settings </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/dev-settings`" v-if="showDevTools">
        [Dev Settings]
      </RouterLink>
    </ul>
  </div>
</template>
