<script setup lang="ts">
import { walletTransactionsTableFields } from 'ah-trades/src/models/transactionsTableFields';
import {
  PaginatedQuery,
  Transaction,
  TransactionBase,
  HistoricalTransactionBase,
  TransactionState,
  BeneficiaryManagerErrorCodes,
} from 'ah-api-gateways';
import TransactionsTable from './TransactionsTable.vue';
import { SECOND } from 'ah-common-lib/src/constants/time';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { computed, onBeforeUnmount, PropType, ref, watch } from 'vue';
import { useAhWalletsState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: walletTransactionsTableFields }),
  },
  ...defineUseManagedListingProps<TransactionBase>(),
});
const RELOAD_TIMEOUT = 10 * SECOND;

const reloadTimeout = ref<number | null>(null);

interface TransactionEmits extends UseManagedListingEmits<TransactionBase> {}

const emit = defineEmits<TransactionEmits>();

const walletState = useAhWalletsState();

const requestManager = useRequestManager();

const onBehalfOfClient = useOnBehalfOf();

const managedListing = useManagedListing<TransactionBase>({
  loadDataRequest(query: PaginatedQuery) {
    /**
     * loadDataRequest will override sort if it is set. Otherwise it defaults to docCreatedAt DESC
     */
    return walletState.services.wallet.listWalletTransactions({
      ...query,
      clientId: onBehalfOfClient.value?.id,
    });
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

function getCancelTransactionRequest(transaction: Transaction) {
  return walletState.services.payments.cancelPayment(transaction.paymentId!, onBehalfOfClient.value?.id, {
    errors: { silent: true },
  });
}

function cancelTransaction(transaction: Transaction) {
  requestManager.manager.sameOrCancelAndNew('cancelTransaction', getCancelTransactionRequest(transaction)).subscribe(
    () => {
      walletState.toast.success('Payment cancelled successfully.');
      updateList();
    },
    (e) => {
      const error = e.response?.data;
      if (error && error.code === BeneficiaryManagerErrorCodes.BUSINESS_RULE_NOT_MET && error.message) {
        walletState.toast.error(error.message);
      } else {
        walletState.toast.error('An unexpected problem has occurred. Please try again later.');
      }
    }
  );
}

function updateList(query?: PaginatedQuery<TransactionBase>, backgroundLoading?: boolean) {
  managedListing.loadData(query, backgroundLoading);
}

/**
 * Setup a timeout for refreshing data
 *
 * FIXME: remove once notifications regarding transactions are available
 */
watch(
  () => props.tableData,
  () => {
    if (reloadTimeout.value) {
      clearTimeout(reloadTimeout.value);
    }
    if (props.tableData?.list?.find((i) => (i as HistoricalTransactionBase).state === TransactionState.PENDING)) {
      window.setTimeout(() => {
        updateList(undefined, true);
      }, RELOAD_TIMEOUT);
    }
  }
);

onBeforeUnmount(() => {
  if (reloadTimeout.value) {
    clearTimeout(reloadTimeout.value);
  }
});

if (managedListing.refs.tableData) {
  managedListing.refs.tableData.value = {
    list: [],
    ...(managedListing.bindings.data as any),
    sort: 'docCreatedAt',
    sortDirection: 'DESC',
  };
}

function loadData() {
  managedListing.loadData();
}

defineExpose({ loadData });
</script>

<template>
  <TransactionsTable
    @transaction-cancel="cancelTransaction"
    @withdraw-succeded="updateList"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  />
</template>
