<script setup lang="ts">
import { useAuthStore } from '@/app/store/authStore';
import AppBanner from '@/app/components/common/AppBanner.vue';
import { computed } from 'vue';
import { useWalletsStore } from '@/app/store/walletsModule';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRoute } from 'vue-router/composables';
import { inheritedMeta } from 'ah-common-lib/src/helpers/router';
import { ACTIVE_MARGIN_CALL_BANNER_META_FLAG } from '@/app/constants/banners';
import { ClientRiskProfileReport } from 'ah-api-gateways';

const authStore = useAuthStore();

const route = useRoute();

const walletStore = useWalletsStore();

const onBehalfOfClient = useOnBehalfOf();

const clientId = computed(() => onBehalfOfClient.value?.id ?? authStore.loggedInIdentity?.client?.id);

const isNewTradeScreen = computed(() => inheritedMeta(route)[ACTIVE_MARGIN_CALL_BANNER_META_FLAG]);

const clientRiskProfileReportData = computed<ClientRiskProfileReport | null>(() => {
  if (clientId.value) {
    return walletStore.getClientRiskProfile(clientId.value);
  }
  return null;
});

const limitsAndCollateralLink = computed(() => {
  if (authStore.isClientUser) {
    return '/dashboard/trades/limits-collateral';
  } else {
    return `admin/activity/clients/obo/${onBehalfOfClient.value?.id}/trade`;
  }
});

const showActiveMarginCallBanner = computed(
  () => isNewTradeScreen.value && clientRiskProfileReportData.value?.collateralProfile.activeMarginCall
);
</script>

<template>
  <AppBanner v-if="clientRiskProfileReportData && showActiveMarginCallBanner" class="text-center danger">
    <template>
      <p class="mb-0">
        Your current Mark-to-Market (MTM) position of all open contracts is currently at
        <strong>{{
          `${clientRiskProfileReportData.collateralProfile.currency} ${clientRiskProfileReportData.collateralProfile.totalMarkToMarket}`
        }}</strong>
        in excess of your VM Limit of
        <strong>{{
          `${clientRiskProfileReportData.collateralProfile.currency} ${
            clientRiskProfileReportData.collateralProfile.variationMarginAmount
          } (${clientRiskProfileReportData.collateralProfile.variationMarginPercentage * 100}%)`
        }}</strong>
        . A Margin Call has been issued on your account.
        <RouterLink :to="limitsAndCollateralLink" class="plain-color-link">View margin increment required</RouterLink>.
      </p>
    </template>
  </AppBanner>
</template>
