<script setup lang="ts">
import {
  PaymentState,
  paymentStateLabels,
  PaymentType,
  paymentTypeLabels,
  PaymentRail,
  paymentRailLabels,
  Beneficiary,
  BeneficiaryCategory,
} from 'ah-api-gateways';
// FIXME importing a composition API component with multiple scripts from a Class Component fails, need to ignore TS checks
// @ts-ignore
import InputDateSelector, { DateRange } from 'ah-common-lib/src/common/components/InputDateSelector.vue';
import { addDays, startOfDay, endOfDay, format } from 'date-fns';
import { useSettingsStore } from '@/app/store/settingsModule';
import SearchableSelector from 'ah-common-lib/src/common/components/selects/SearchableSelector.vue';
import { useAuthStore } from '@/app/store/authStore';
import {
  defineUseManagedListFilterProps,
  useManagedListFilter,
  UseManagedListingEmits,
} from 'ah-common-lib/src/listing';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';

interface PaymentsListFilters {
  query?: string;
  currency: string[];
  states: PaymentState[];
  beneficiaries: string[];
  clientIds: string[];
  type: PaymentType[];
  method: PaymentRail[];
  creationDate: DateRange | null;
  executionDate: DateRange | null;
}

const props = defineProps({
  ...defineUseManagedListFilterProps<PaymentsListFilters>(),
});

const settingsStore = useSettingsStore();

const authStore = useAuthStore();

const services = getServices();

const requestManager = useRequestManager().manager;

const user = computed(() => authStore.loggedInIdentity);

const partner = computed(() => user.value?.partner);

const client = computed(() => user.value?.client);

const paymentRails = ref(Object.keys(paymentRailLabels));

const currencies = computed(() => settingsStore.currencies.map((i) => ({ label: i.currency, value: i.currency })));

settingsStore.loadTradeableCurrencies();

const stateOptions = computed(() =>
  Object.keys(paymentStateLabels).map((k) => {
    return {
      value: k,
      label: paymentStateLabels[k as PaymentState],
    };
  })
);

const typeOptions = computed(() =>
  Object.keys(paymentTypeLabels).map((k) => {
    return {
      value: k,
      label: paymentTypeLabels[k as PaymentType],
    };
  })
);

const methodOptions = computed(() =>
  paymentRails.value.map((k) => {
    return {
      value: k,
      label: paymentRailLabels[k as PaymentRail],
    };
  })
);

function fetchRequestBeneficiary(query: any) {
  return services.beneficiary.listBeneficiaries({
    ...query,
    partnerId: partner.value?.id,
    clientId: client.value?.id,
    category: client.value ? BeneficiaryCategory.CLIENT_3RD_PARTY : BeneficiaryCategory.PARTNER_3RD_PARTY,
    withIndividuals: true,
  });
}

function singleFetchRequestBeneficiary(beneficiaryId: string) {
  return services.beneficiary.getBeneficiary(beneficiaryId);
}

function beneficiaryOptionLabel(b: Beneficiary) {
  return b.name || `${b.firstName} ${b.lastName}`;
}

interface PaymentsListFiltersEmits extends UseManagedListingEmits<PaymentsListFilters> {
  (e: 'update:tableConfig', value: boolean): void;
}

const emit = defineEmits<PaymentsListFiltersEmits>();

const { filterData } = useManagedListFilter<PaymentsListFilters>({
  emit,
  props,
  runSettersOnUndefined: true,
  filterKeys: [
    {
      key: 'excludeClients',
      getter: () => (partner.value?.id && client.value?.id ? false : true),
      setter: () => {},
    },
    {
      key: 'excludePartners',
      getter: () => (client.value?.id ? true : false),
      setter: () => {},
    },
    {
      key: 'partnerId',
      getter: () => partner.value?.id,
      setter: () => {},
    },
    {
      key: 'query',
      getter: (filters) => filters.value.query || undefined,
      setter: (value: any, filters) => (filters.value.query = value || undefined),
    },
    {
      key: 'queryBy',
      getter: (filters) => {
        if (filters.value.query) {
          return ['composedReferenceNumber', 'tradeComposedReferenceNumber'];
        }
      },
      setter: () => {},
    },
    {
      key: 'state',
      getter: (filters) => filters.value.states || [],
      setter: (value: any, filters) => (filters.value.states = value || []),
    },
    {
      key: 'currency',
      getter: (filters) => filters.value.currency || [],
      setter: (value: any, filters) => (filters.value.currency = value || []),
    },
    {
      key: 'beneficiaryId',
      getter: (filters) => filters.value.beneficiaries || [],
      setter: (value: any, filters) => (filters.value.beneficiaries = value || []),
    },
    {
      key: 'type',
      getter: (filters) => filters.value.type || [],
      setter: (value: any, filters) => (filters.value.type = value || []),
    },
    {
      key: 'beneficiaryPaymentRail',
      getter: (filters) => filters.value.method || [],
      setter: (value: any, filters) => (filters.value.method = value || []),
    },
    {
      key: 'createdAtFrom',
      getter: (filters) => {
        if (filters.value.creationDate?.start && !isNaN(new Date(filters.value.creationDate.start).valueOf())) {
          return startOfDay(new Date(filters.value.creationDate.start)).toISOString();
        }
      },
      setter: (value: any, filters) => {
        if (value) {
          if (filters.value.creationDate) {
            filters.value.creationDate.start = new Date(value);
          } else {
            filters.value.creationDate = {
              start: startOfDay(new Date(value)),
              end: endOfDay(addDays(new Date(value), 1)),
            };
          }
        }
      },
    },
    {
      key: 'createdAtTo',
      getter: (filters) => {
        if (filters.value.creationDate?.end && !isNaN(new Date(filters.value.creationDate.end).valueOf())) {
          return endOfDay(new Date(filters.value.creationDate.end)).toISOString();
        }
      },
      setter: (value: any, filters) => {
        if (value) {
          if (filters.value.creationDate) {
            filters.value.creationDate.end = new Date(value);
          } else {
            filters.value.creationDate = {
              start: startOfDay(new Date(value)),
              end: endOfDay(addDays(new Date(value), 1)),
            };
          }
        }
      },
    },
    {
      key: 'executionDateFrom',
      getter: (filters) => {
        if (filters.value.executionDate?.start && !isNaN(new Date(filters.value.executionDate.start).valueOf())) {
          return format(new Date(filters.value.executionDate.start), 'yyyy-MM-dd');
        }
      },
      setter: (value: any, filters) => {
        if (value) {
          if (filters.value.executionDate) {
            filters.value.executionDate.start = new Date(value);
          } else {
            filters.value.executionDate = {
              start: startOfDay(new Date(value)),
              end: endOfDay(addDays(new Date(value), 1)),
            };
          }
        }
      },
    },
    {
      key: 'executionDateTo',
      getter: (filters) => {
        if (filters.value.executionDate?.end && !isNaN(new Date(filters.value.executionDate.end).valueOf())) {
          return format(new Date(filters.value.executionDate.end), 'yyyy-MM-dd');
        }
      },
      setter: (value: any, filters) => {
        if (value) {
          if (filters.value.executionDate) {
            filters.value.executionDate.end = new Date(value);
          } else {
            filters.value.executionDate = {
              start: startOfDay(new Date(value)),
              end: endOfDay(addDays(new Date(value), 1)),
            };
          }
        }
      },
    },
  ],
  sortAndPageKeys: [],
});

function onClientChange() {
  const filter = {
    clientId: client.value?.id,
    partnerId: partner.value?.id,
    excludeClients: !client.value,
    excludePartners: !!client.value,
  };
  requestManager
    .sameOrCancelAndNew(
      'loadRails',
      services.payments.loadPaymentsDistinctRails(filter, {
        errors: {
          silent: true,
        },
      }),
      filter
    )
    .subscribe((response) => {
      const rails = response.paymentRails;
      filterData.value.method.forEach((m) => {
        if (!rails.includes(m)) {
          rails.push(m);
        }
      });
      paymentRails.value = rails;
    });
}

watch(
  [client, partner],
  () => {
    onClientChange();
  },
  { immediate: true }
);
</script>

<template>
  <BoxGrid alignH="start">
    <BoxGridItem sm="12" lg="12" class="field-group-wrapper"><h2>Filter Payments</h2> </BoxGridItem>
    <BoxGridItem sm="12" lg="5" class="field-group-wrapper">
      <SearchInput
        class="search-input mb-4 mb-xl-0 mock-label-padding"
        placeholder="Search by Payment ID or Trade ID"
        :search.sync="filterData.query"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="2" xl="2" class="field-group-wrapper state">
      <label>
        Type
        <a v-if="filterData.type.length > 0" class="field-group-clear-link" @click="filterData.type = []"> clear </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="typeOptions"
        :value.sync="filterData.type"
        itemsCountLabel="types"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="2" xl="2" class="field-group-wrapper state">
      <label>
        State
        <a v-if="filterData.states.length > 0" class="field-group-clear-link" @click="filterData.states = []">
          clear
        </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="stateOptions"
        :value.sync="filterData.states"
        itemsCountLabel="states"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="3" class="date-selector">
      <InputDateSelector
        title="Creation Date Range"
        :dateSelected.sync="filterData.creationDate"
        hide-choices
        clearable
        is-ranged
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="5" class="field-group-wrapper state">
      <div>
        <label>
          Beneficiary
          <a
            v-if="filterData.beneficiaries.length > 0"
            class="field-group-clear-link"
            @click="filterData.beneficiaries = []"
          >
            clear
          </a>
        </label>
        <SearchableSelector
          :fetchRequest="fetchRequestBeneficiary"
          :singleFetchRequest="singleFetchRequestBeneficiary"
          :value.sync="filterData.beneficiaries"
          itemsCountLabel="beneficiaries"
          appendLabelToPlaceholder="Beneficiaries"
          :maxFulltextLabels="3"
          :valueLabel="beneficiaryOptionLabel"
          :sortKey="['name', 'firstName', 'lastName']"
        />
      </div>
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="2" class="field-group-wrapper state">
      <label>
        Method
        <a v-if="filterData.method.length > 0" class="field-group-clear-link" @click="filterData.method = []">
          clear
        </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="methodOptions"
        :value.sync="filterData.method"
        itemsCountLabel="method"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="2" class="field-group-wrapper state">
      <label>
        Currency
        <a v-if="filterData.currency.length > 0" class="field-group-clear-link" @click="filterData.currency = []">
          clear
        </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="currencies"
        :value.sync="filterData.currency"
        itemsCountLabel="currency"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="3" class="date-selector">
      <InputDateSelector
        title="Payment Date Range"
        :dateSelected.sync="filterData.executionDate"
        hide-choices
        clearable
        is-ranged
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<style lang="scss" scoped>
::v-deep {
  @media only screen and (max-width: 1600px) {
    .state {
      margin-left: 0 !important;
    }
    .date-selector {
      flex-grow: 1;
      max-width: 100%;
    }
  }
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }
  }
}
</style>
