<script setup lang="ts">
import {
  TransactionDirection,
  Transaction,
  isDeposit,
  isWithdraw,
  isTrade,
  HistoricalTransaction,
  transactionStateLabels,
  TransactionState,
  isPartnerPayment,
} from 'ah-api-gateways';
import TransactionDetails from 'ah-wallets/src/components/transactions/TransactionDetails.vue';
import TransactionReason from 'ah-wallets/src/components/transactions/TransactionReason.vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { computed } from 'vue';
import { useAhWalletsState } from '../..';
import WalletsFormattedCurrency from '../../currency/WalletsFormattedCurrency.vue';

const props = withDefaults(
  defineProps<{
    /**
     * Whether to show scheduled transactions. If unset or falsy will return historical transactions
     *
     * Only works in the context of a wallet
     */
    scheduled?: boolean | string;
    /**
     * Whether to display row details
     *
     * Defaults to true
     */
    withRowDetails?: boolean | string;
    /**
     * Whether to display the transaction status border and text-decorations
     *
     * Defaults to true
     */
    withStatusIndication?: boolean | string;
    /**
     * Format of all dates displayed in table
     */
    dateFormat?: string;
  }>(),
  {
    scheduled: false,
    withRowDetails: true,
    withStatusIndication: true,
  }
);

const walletState = useAhWalletsState();

const isScheduled = computed(() => props.scheduled !== false);

function isDebit(item: Transaction) {
  return item.direction === TransactionDirection.DEBIT;
}

function isHistorical(item: Transaction): item is HistoricalTransaction {
  return !!(item as HistoricalTransaction).state;
}

function rowClass(item: HistoricalTransaction) {
  if (props.withStatusIndication !== false) {
    if (isHistorical(item)) {
      if ([TransactionState.FAILED, TransactionState.REJECTED].includes(item.state)) return 'failed';
      else if ([TransactionState.PENDING, TransactionState.IN_REVIEW].includes(item.state)) return 'pending';
    }
  }
}

function itemHasDetails(item: Transaction) {
  return (item && (isDeposit(item) || isWithdraw(item) || (isTrade(item) && item.tradeId))) || isPartnerPayment(item);
}

function getStateLabel(item: Transaction) {
  if (item && isHistorical(item)) {
    return transactionStateLabels[item.state];
  }
}
</script>

<template>
  <PaginatedTable
    fixed
    hover
    nowrap
    animateCols
    class="transactions-table"
    :stacked="walletState.mediaQuery.is('smDown')"
    :rowClass="rowClass"
    items-label="transactions"
    v-on="$listeners"
    v-bind="$attrs"
    :withRowDetails="withRowDetails !== false && itemHasDetails"
  >
    <template #cell(docCreatedAt)="{ item }">
      <span v-if="!isScheduled">
        {{ formatDate(item.docCreatedAt, dateFormat) }}
      </span>
      <span v-else-if="item.createdAt">
        {{ formatDate(item.createdAt, dateFormat) }}
      </span>
      <span v-else class="text-secondary"> &lt;Pending&gt;</span>
    </template>
    <template #cell(dateCreated)="{ item }">
      <span>
        {{ formatDate(item.dateCreated, dateFormat) }}
      </span>
    </template>
    <template #cell(executionDate)="{ item }">
      <span>
        {{ formatDate(item.executionDate, dateFormat) }}
      </span>
    </template>
    <template #cell(state)="{ item }">
      <span>{{ getStateLabel(item) }} </span>
    </template>
    <template #cell(value)="{ item }">
      <VRow class="flex-nowrap">
        <VCol cols="0">{{ item.currency }}</VCol>
        <VCol class="text-nowrap text-right">
          <WalletsFormattedCurrency :value="item.amount" :with-prefix="false" :currency="item.currency" />
        </VCol>
      </VRow>
    </template>
    <template #cell(debit)="{ item }">
      <span v-if="isDebit(item)">
        <WalletsFormattedCurrency :value="item.amount" :currency="item.currency" />
      </span>
    </template>
    <template #cell(credit)="{ item }">
      <span v-if="!isDebit(item)">
        <WalletsFormattedCurrency :value="item.amount" :currency="item.currency" />
      </span>
    </template>
    <template #cell(walletBalance)="{ item }">
      <span v-if="typeof item.walletBalance === 'number'">
        <WalletsFormattedCurrency :value="item.walletBalance" :currency="item.currency" />
      </span>
      <span v-else> - </span>
    </template>
    <template #cell(type)="{ item }">
      <TransactionReason :transaction="item" />
    </template>

    <template #row-details="{ item }">
      <TransactionDetails :class="rowClass(item)" :transaction="item" v-bind="$attrs" v-on="$listeners" />
    </template>

    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.transactions-table ::v-deep {
  tr {
    position: relative;
    border-color: $color-danger;

    td:first-child {
      padding-left: 1rem;
    }

    td:first-child:before,
    .transaction-details:before {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 8px solid transparent;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.b-table-has-details > td:first-child:before {
      border-bottom-left-radius: 0px;
    }
    .transaction-details:before {
      border-top-left-radius: 0px;
    }
  }

  .failed {
    td {
      text-decoration: line-through;
    }
    td:first-child::before,
    &::before {
      @include themedBorderColor($color-danger, $color-danger, '', ' !important');
    }
  }

  .pending {
    td {
      @include themedTextColor($color-text-secondary);
    }
    td:first-child::before,
    &::before {
      @include themedBorderColor($color-yellow-highlight, $color-yellow-highlight, '', ' !important');
    }
  }
}
</style>
