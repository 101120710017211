import { PropType } from 'vue';

export enum ToastPosition {
  inline = 'inline',
  fixed = 'fixed',
}

export enum ToastType {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
}

export interface ActionOption {
  title: string;
  method: () => void | Promise<any>;
  class: string | string[] | { [key: string]: boolean };
}

export function defineToastComponentProps() {
  return {
    id: { type: String, required: true },
    title: { type: String, required: false, default: 'Success' },
    message: { type: String, required: false, default: '' },
    isHtml: { type: Boolean, required: false, default: false },
    toastType: { type: String as PropType<ToastType>, required: false, default: 'success' },
    dismiss: { type: Boolean, required: false, default: true },
    position: { type: String as PropType<ToastPosition>, required: false },
    actions: { type: Array as PropType<ActionOption[]>, required: false, default: () => [] },
  };
}

export type ToastComponentProps = ReturnType<typeof defineToastComponentProps>;
