import { HttpBasedService, HttpRequestOptions, HttpService } from 'ah-requests';
import { ProductBulkResponse, ProductBulkRequest } from '../models/products';
('../models');

export class ProductCatalogueService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private adminBaseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'productService' } },
      },
    });
  }

  public updateBulkProducts(products: ProductBulkRequest, options?: HttpRequestOptions<ProductBulkResponse>) {
    return this.put<ProductBulkResponse>(`${this.baseUrl}products/bulk`, products, options);
  }
}
