<script setup lang="ts">
import DataRow from 'ah-common-lib/src/common/components/DataRow.vue';
import TradeFundForm from '../forms/tradeFundForm/TradeFundForm.vue';
import { ClientRiskProfileReport, FundingType, QuotePriceResponse, TradeFundingDetails } from 'ah-api-gateways';
import { VCol, VRow } from 'ah-common-lib/src/common/components';
import { computed, watch, PropType, ref } from 'vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useAhTradesState } from '../..';

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  (e: 'update:areIMLimitOrCollateralProfileInvalid', value: boolean): void;
  (e: 'update:sufficientCollateral', value: boolean): void;
  (e: 'update:tradeFundingDetails', value: Partial<TradeFundingDetails>): void;
}>();

const props = defineProps({
  tradePriceResponse: {
    type: Object as PropType<QuotePriceResponse>,
    required: true,
  },
  tradeFundingDetails: {
    type: Object as PropType<Partial<TradeFundingDetails>>,
  },
  hideFundingForm: {
    type: Boolean,
    default: false,
  },
});

const clientId = computed(
  () => onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
);

const clientRiskProfileReportData = computed<ClientRiskProfileReport | null>(() =>
  clientId.value ? tradeState.store.useWalletsStore().getClientRiskProfile(clientId.value) : null
);

const areClientRiskProfileLoading = computed(() => {
  return tradeState.store.useWalletsStore().areClientRiskProfileLoading(clientId.value);
});

const shouldHideIMFundingForm = computed(() => props.hideFundingForm !== false || areClientRiskProfileLoading.value);

const sufficientCollateral = computed(
  () =>
    (!!clientRiskProfileReportData.value?.collateralProfile &&
      clientRiskProfileReportData.value.collateralProfile.availableCollateral >=
        props.tradePriceResponse.homeCurrencyInitialMarginAmount) ||
    props.tradePriceResponse.homeCurrencyInitialMarginAmount <= 0 ||
    isFundWithCreditPossible.value
);

function loadClientRiskProfileReport() {
  if (!clientId.value) {
    throw 'Cannot load client risk profile report with incomplete data';
  }

  tradeState.store.useWalletsStore().loadClientRiskProfile({ force: true, clientId: clientId.value });
}

const isFundWithCollateralPossible = computed(() => {
  if (!clientRiskProfileReportData.value?.collateralProfile || areClientRiskProfileLoading.value) {
    return false;
  }

  return (
    sufficientCollateral.value &&
    (!clientRiskProfileReportData.value?.creditProfile ||
      clientRiskProfileReportData.value.creditProfile?.marginCreditLimitTotal === 0)
  );
});

const isFundWithCreditPossible = computed(() => {
  if (!clientRiskProfileReportData.value?.creditProfile || areClientRiskProfileLoading.value) {
    return false;
  }

  return (
    props.tradePriceResponse.homeCurrencyInitialMarginAmount > 0 &&
    clientRiskProfileReportData.value.creditProfile.marginCreditLimitTotalRemaining >=
      props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

const isFormValid = computed(
  () =>
    isFundWithCollateralPossible.value ||
    isFundWithCreditPossible.value ||
    props.tradePriceResponse.homeCurrencyInitialMarginAmount <= 0 ||
    false
);

const isIMTotalLimitGreaterThanZero = computed(
  () =>
    clientRiskProfileReportData.value?.creditProfile &&
    clientRiskProfileReportData.value.creditProfile.marginCreditLimitTotal > 0
);

const areIMLimitOrCollateralProfileInvalid = computed(
  () =>
    (!clientRiskProfileReportData.value?.collateralProfile && !areClientRiskProfileLoading.value) ||
    (!isFundWithCreditPossible.value && isIMTotalLimitGreaterThanZero.value)
);

const fundType = ref();

function emitValues(creditContribution?: number, collateralContribution?: number) {
  emit('update:tradeFundingDetails', {
    initialMarginFundingType: fundType.value,
    initialMarginFundingCurrency: props.tradePriceResponse.homeCurrency,
    initialMarginCollateralFundingAmount: collateralContribution,
    initialMarginCreditFundingAmount: creditContribution,
    valid: isFormValid.value,
  });
}

/**
 * Initial fund type set
 */
function setFundTypeAndEmit() {
  if (props.tradePriceResponse.homeCurrencyInitialMarginAmount <= 0) {
    emit('update:tradeFundingDetails', {});
  } else if (!isFundWithCreditPossible.value) {
    fundType.value = FundingType.COLLATERAL;
    emitValues(0, props.tradePriceResponse.homeCurrencyInitialMarginAmount);
  } else {
    fundType.value = FundingType.CREDIT;
    emitValues(props.tradePriceResponse.homeCurrencyInitialMarginAmount, 0);
  }
}

function reloadCollaterals() {
  loadClientRiskProfileReport();
  setFundTypeAndEmit();
}

watch(isFundWithCreditPossible, setFundTypeAndEmit, { immediate: true });

watch(isFormValid, () => {
  if (props.tradeFundingDetails) {
    emit('update:tradeFundingDetails', { ...props.tradeFundingDetails, valid: isFormValid.value });
  }
});

watch(() => props.tradePriceResponse, reloadCollaterals, { immediate: true });

watch(
  sufficientCollateral,
  () => {
    emit('update:sufficientCollateral', sufficientCollateral.value);
  },
  { immediate: true }
);

watch(
  areIMLimitOrCollateralProfileInvalid,
  () => {
    emit('update:areIMLimitOrCollateralProfileInvalid', !!areIMLimitOrCollateralProfileInvalid.value);
  },
  { immediate: true }
);

watch(
  () => props.tradeFundingDetails,
  () => {
    if (props.tradeFundingDetails?.initialMarginFundingType) {
      fundType.value = props.tradeFundingDetails.initialMarginFundingType;
    }
  },
  { immediate: true }
);
</script>

<template>
  <BoxGridBlock
    v-if="tradePriceResponse.homeCurrencyInitialMarginAmount === 0"
    class="mb-3"
    cols="12"
    align-self="stretch"
  >
    <h3>Margin Requirements</h3>
    <p class="text-secondary">There is no initial margin requirement on this trade.</p>
  </BoxGridBlock>

  <!-- Safeguard if the user doesn't have collateralProfile or if IM Limit is bigger than 0 but less than Initial Margin,
    we should not allow to proceed with the trade -->
  <BoxGridBlock v-else-if="areIMLimitOrCollateralProfileInvalid" class="mb-3" cols="12" align-self="stretch">
    <VRow>
      <VCol cols="1" class="text-center">
        <ErrorExclamationIcon class="icon" />
      </VCol>
      <VCol cols="11" class="pl-0 text-danger">
        You have insufficient IM limit to proceed with this trade. Please contact
        <a class="plain-color-link text-danger" href="mailto:credit@alt21.com">credit@alt21.com</a> for assistance
      </VCol>
    </VRow>
  </BoxGridBlock>
  <!-- If it is possible to fund the trade with CREDIT, than we will no show any block
    The block will only be showed if the trade is to be funded with COLLATERAL, but we will give
    priority to be funded with CREDIT -->
  <BoxGridBlock v-else-if="!isFundWithCreditPossible" class="mb-3" cols="12" align-self="stretch">
    <div v-if="tradePriceResponse.homeCurrencyInitialMarginAmount > 0">
      <h3>Margin Requirements</h3>

      <p class="text-secondary">There is an initial margin requirement on this trade:</p>

      <LoadingOverlay :loading="areClientRiskProfileLoading" variant="box" opacity="0.4">
        <template v-if="clientRiskProfileReportData">
          <DataRow cols="6" label="Initial Margin" class="mb-2" labelClass="font-weight-bold">
            {{ clientRiskProfileReportData.collateralProfile.currency }}
            {{ formatCurrencyValue(tradePriceResponse.homeCurrencyInitialMarginAmount) }}
            ({{ tradePriceResponse.initialMarginPercentage * 100 }} %)<InfoTooltip
              :text="$ahTradesState.i18n.t('tooltips.initialMargin')"
            />
          </DataRow>
          <TradeFundForm
            v-if="!shouldHideIMFundingForm"
            :isFundWithCollateralPossible="isFundWithCollateralPossible"
            :collateralProfile="clientRiskProfileReportData.collateralProfile"
            :currency="tradePriceResponse.homeCurrency"
            @update:tradeFundingDetails="$emit('update:tradeFundingDetails', $event)"
            @reload-collaterals="reloadCollaterals"
          />
        </template>
      </LoadingOverlay>
    </div>
  </BoxGridBlock>
</template>

<style lang="scss" scoped>
.icon {
  width: 1.25rem;
  height: auto;
}
</style>
