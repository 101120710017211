import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const bankAccountsTableFields: TableFieldDefinition[] = [
  {
    header: 'Currency',
    key: 'currency',
  },
  { header: 'Payment Method', key: 'paymentRail' },
  { header: 'Bank', key: 'bankName' },
];

export const narrowBankAccountsTableFields: TableFieldDefinition[] = [
  {
    header: '',
    key: 'currency',
  },
];
