export enum SettlementState {
  NO_BALANCE = 'NO_BALANCE',
  FUNDED = 'FUNDED',
  BALANCE_SHORT = 'BALANCE_SHORT',
  SELL_LEG_SETTLED = 'SELL_LEG_SETTLED',
  BUY_FUNDS_SETTLED = 'BUY_FUNDS_SETTLED',
  DATE_ROLLED = 'DATE_ROLLED',
  BALANCE_SHORT_LATE = 'BALANCE_SHORT_LATE',
}

export const settlementStateBadges: Record<SettlementState, { label: string; class: string }> = {
  [SettlementState.BALANCE_SHORT]: {
    label: 'Balance short',
    class: 'red',
  },
  [SettlementState.BUY_FUNDS_SETTLED]: {
    label: 'Buy funds settled',
    class: 'green',
  },
  [SettlementState.DATE_ROLLED]: {
    label: 'Date rolled',
    class: 'green',
  },
  [SettlementState.FUNDED]: {
    label: 'Funded',
    class: 'green',
  },
  [SettlementState.BALANCE_SHORT_LATE]: {
    label: 'Balance short & Late',
    class: 'red',
  },
  [SettlementState.NO_BALANCE]: {
    label: 'No balance',
    class: 'orange',
  },
  [SettlementState.SELL_LEG_SETTLED]: {
    label: 'Sell leg Settled',
    class: 'green',
  },
};
