<script setup lang="ts">
import { computed, PropType } from 'vue';

const props = defineProps({
  cols: {
    type: [String, Number],
  },
  xl: {
    type: [String, Number],
  },
  lg: {
    type: [String, Number],
  },
  md: {
    type: [String, Number],
  },
  sm: {
    type: [String, Number],
  },
  offset: {
    type: [String, Number],
  },
  offsetXl: {
    type: [String, Number],
  },
  offsetLg: {
    type: [String, Number],
  },
  offsetMd: {
    type: [String, Number],
  },
  offsetSm: {
    type: [String, Number],
  },
  alignSelf: {
    type: String as PropType<'start' | 'center' | 'end'>,
  },
});

const colAttrs = computed(() => [
  props.cols ? `col-${props.cols}` : '',
  props.xl ? `col-xl-${props.xl}` : '',
  props.lg ? `col-lg-${props.lg}` : '',
  props.md ? `col-md-${props.md}` : '',
  props.sm ? `col-sm-${props.sm}` : '',
]);

const alignAttrs = computed(() => (props.alignSelf ? `align-self-${props.alignSelf}` : ''));

const offsetAttrs = computed(() => [
  props.offset ? `offset-${props.offset}` : '',
  props.offsetXl ? `offset-xl-${props.offsetXl}` : '',
  props.offsetLg ? `offset-lg-${props.offsetLg}` : '',
  props.offsetMd ? `offset-md-${props.offsetMd}` : '',
  props.offsetSm ? `offset-sm-${props.offsetSm}` : '',
]);
</script>

<template>
  <div :class="[...colAttrs, alignAttrs, ...offsetAttrs, { col: !cols }]" v-on="$listeners" v-bind="$attrs">
    <slot />
  </div>
</template>
