<template>
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.62109 11.6738L11.3648 16.2931H16.8193L14.0848 11.6738H8.62109Z" fill="#E40420" />
    <path d="M16.8204 6.99805L19.5332 11.6726L16.8204 16.2919L14.0859 11.6726L16.8204 6.99805Z" fill="#D10917" />
    <path d="M4.56641 4.63714H10.0071L12.7267 0H7.27123L4.56641 4.63714Z" fill="#E40420" />
    <path d="M12.7274 0L15.4471 4.63714L12.7274 9.294L10.0078 4.63714L12.7274 0Z" fill="#D10917" />
    <path d="M3.17255 6.99805L0.476562 11.6726H5.91652L8.62059 6.99805H3.17255Z" fill="#E40420" />
    <path d="M0.476562 11.6738L3.17255 16.2931H8.62059L5.91652 11.6738H0.476562Z" fill="#D10917" />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
