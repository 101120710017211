<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { makeFormModel, getChildModel } from 'ah-common-lib/src/form/helpers';
import { selectField, radioField } from 'ah-common-lib/src/form/models';
import { subYears, startOfYear, isSameMonth } from 'date-fns';
import { FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { setState } from 'ah-common-lib/src/form/helpers';
import { helpers, required } from '@vuelidate/validators';
import { Client } from 'ah-api-gateways/models';
import { cloneDeep } from 'lodash';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { reactive, ref, computed, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../..';

const tradeState = useAhTradesState();
const requestManager = useRequestManager().manager;

const monthsList = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

const downloadEomStatementFormModal = ref<BModal | null>(null);

const props = defineProps<{
  client: Client;
}>();

const state = reactive({
  startDate: startOfYear(subYears(new Date(), 5)) as Date,
  years: [] as FieldOptionObj[],
  months: [] as FieldOptionObj[],
});

const optionForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'optionForm',
    fieldType: 'form',
    fields: [
      radioField(
        'isMonthly',
        '',
        [
          { value: false, label: 'Most recent' },
          { value: true, label: 'Select month' },
        ],
        {
          required: true,
          defaultValue: false,
          inline: true,
        }
      ),
    ],
  }),
  validation: null,
});

const dateForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'dateForm',
    fieldType: 'form',
    fields: [
      selectField('year', '', [], {
        required: () => optionForm.form.isMonthly,
        placeholder: 'Year',
        fieldWrapperClass: 'col col-5',
      }),
      selectField(
        'month',
        '',
        monthsList,
        {
          required: () => optionForm.form.isMonthly,
          placeholder: 'Month',
          fieldWrapperClass: 'col col-5',
          errorMessages: {
            minMonth: 'Please select a month up to the last month',
            required: 'Required field',
          },
        },
        {
          minMonth: helpers.withParams({ type: 'minMonth' }, (value: number, vm: any) => {
            const lastAvailable = getLastAvailableMonth();
            if (vm.year === lastAvailable.year) {
              return vm.month <= lastAvailable.month;
            }
            return vm.year <= lastAvailable.year;
          }),
          required: required,
        }
      ),
    ],
  }),
  validation: null,
});

const clientHasData = computed(() => !isSameMonth(state.startDate, new Date()));

const clientId = computed(() => props.client.id);

function clearErrorMessages() {
  dateForm.validation?.$reset();
}

function showModal() {
  downloadEomStatementFormModal.value?.show();
}

function cancel() {
  downloadEomStatementFormModal.value?.hide();
}

function send() {
  let date = getLastAvailableMonth();
  if (optionForm.form.isMonthly === true) {
    if (dateForm.validation?.$invalid) {
      dateForm.validation?.$touch();
      return;
    }
    date.year = dateForm.form!.year;
    date.month = dateForm.form!.month;
  }

  return requestManager
    .cancelAndNew(
      `exportingStatement`,
      tradeState.services.tradeReports.downloadEOMStatement({
        eomStatementMonth: date.month + 1,
        eomStatementYear: date.year,
        clientId: clientId.value,
      })
    )
    .subscribe((doc) => tradeState.store.useNotificationsStore().triggerFileExportRequestNotification(doc));
}

function setFieldsState() {
  setState(getChildModel(dateForm.form!, 'year')!, 'readonly', !optionForm.form.isMonthly);
  setState(getChildModel(dateForm.form!, 'month')!, 'readonly', !dateForm.form!.year || !optionForm.form.isMonthly);
}

function calculateMonthsOptions() {
  const list = cloneDeep(monthsList);
  const isCurrentYear = new Date().getFullYear() === dateForm.form!.year;

  if (state.startDate.getFullYear() === dateForm.form!.year) {
    const end = isCurrentYear ? new Date().getMonth() : list.length;
    state.months = list.slice(state.startDate.getMonth(), end);
  } else if (isCurrentYear) {
    state.months = list.slice(0, new Date().getMonth());
  } else {
    state.months = list;
  }
  setState(getChildModel(dateForm.form!, 'month')!, 'options', state.months);
}

function selectedDate(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    optionForm.form.isMonthly = true;
    if (event.model.$name === 'year') {
      dateForm.form!.month = '';
      setFieldsState();
      clearErrorMessages();
    }
    calculateMonthsOptions();
  }
}

function onFormChange() {
  if (!dateForm.form) {
    return;
  }
  if (optionForm.form.isMonthly) {
    dateForm.form.year = state.years.length === 1 ? state.years[0].value : '';
    calculateMonthsOptions();
    dateForm.form.month = state.months.length === 1 ? state.months[0].value : '';
  } else {
    const lastDates = getLastAvailableMonth();
    dateForm.form.year = lastDates.year;
    dateForm.form.month = lastDates.month;
  }
  setFieldsState();
  clearErrorMessages();
}

watch(
  () => optionForm.form.isMonthly,
  () => {
    onFormChange();
  },
  { immediate: true }
);

function getLastAvailableMonth() {
  let year = new Date().getFullYear();
  let month = new Date().getUTCMonth() - 1;
  if (month < 0) {
    year = year - 1;
    month = 11;
  }

  return { year, month };
}

function onClientChange() {
  if (props.client.createdAt) {
    state.startDate = new Date(props.client.createdAt);
  }

  let currentYear = getLastAvailableMonth().year;

  while (currentYear >= state.startDate.getFullYear()) {
    state.years.push({ value: currentYear, label: currentYear.toString() });
    currentYear -= 1;
  }

  if (dateForm.form) setState(getChildModel(dateForm.form, 'year')!, 'options', state.years);
}

watch(
  () => props.client,
  () => {
    onClientChange();
  },
  { immediate: true }
);
</script>

<template>
  <span>
    <span>
      <BModal
        title="End of Month Statement"
        modal-class="side-modal eom-modal"
        ref="downloadEomStatementFormModal"
        hide-footer
      >
        <template v-if="clientHasData">
          <div class="card-block">
            <VRow>
              <VCol cols="6">
                <ValidatedForm :fm="optionForm.form" />
              </VCol>
            </VRow>
            <ValidatedForm :fm="dateForm.form" :validation.sync="dateForm.validation" @form-event="selectedDate" />
          </div>
        </template>

        <template v-else>
          <div class="my-5 text-muted">First statement will only be available next month.</div>
        </template>

        <VRow class="mt-3">
          <VCol cols="12">
            <VButton class="btn-cancel btn-stroked btn-eom mr-3" @click="cancel">Cancel</VButton>
            <VButton
              class="btn-eom btn-primary"
              @click="send"
              v-if="clientHasData"
              :disabled="requestManager.anyPending"
              :loading="requestManager.requestStates.exportingStatement === 'pending'"
            >
              Send
            </VButton>
          </VCol>
        </VRow>
      </BModal>
    </span>

    <slot v-bind="{ showModal }">
      <VButton @click="showModal()" />
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep {
  .eom-modal .modal-dialog {
    width: 38em;
  }

  .btn-eom {
    width: 110px;
  }

  .btn-primary {
    @include themedBackgroundColor($color-dark-primary, $color-primary);
    @include themedBorderColor($color-dark-primary, $color-primary);
  }

  .btn-cancel {
    @include themedBorderColor($color-dark-primary, $color-primary);
    @include themedTextColor($color-dark-primary, $color-primary);
  }

  .card-block {
    @include themedProp(
      'background-color',
      getColorWithOffsetLightness($color-box-bg, -10%),
      getColorWithOffsetLightness($color-dark-box-bg, 10%)
    );
    @include themedProp(
      'border-color',
      getColorWithOffsetLightness($color-box-bg, -10%),
      getColorWithOffsetLightness($color-dark-box-bg, 10%)
    );
  }
}
</style>
