<script setup lang="ts">
import AgentCommissionListing from '@/app/components/commission/AgentCommissionListing.vue';
import CommissionPeriodSelector from './CommissionPeriodSelector.vue';
import { computed, ref } from 'vue';

const periodFilter = ref<{} | null>(null);

/**
 * filter gives the start date and the type of commission wiht which the start date is linked with,
 * client in this case
 */
const filter = computed(() => ({ ...periodFilter.value, type: 'agent' }));
</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col-md-8 col-sm-12">
        <label> Total commission (Agent)</label>
      </div>
      <div class="col col-md-4 col-sm-6">
        <CommissionPeriodSelector :filter.sync="periodFilter" />
      </div>
    </div>
    <AgentCommissionListing :filter="filter" />
  </div>
</template>
