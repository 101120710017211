<script lang="ts" setup>
import CurrencyFilterSelect from '../currency/CurrencyFilterSelect.vue';
import { dateField, selectField } from 'ah-common-lib/src/form/models';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import {
  HedgingInstruments,
  TradeState,
  tradeStateBadges,
  TradeStatus,
  tradeStatusBadges,
  formatHedgingInstrument,
} from 'ah-api-gateways';
import { addDays, subDays } from 'date-fns';
import { computed, PropType, ref, watch } from 'vue';
import { useAhTradesState } from '../..';
import {
  defineUseManagedListFilterProps,
  useManagedListFilter,
  UseManagedListFilterEmits,
} from 'ah-common-lib/src/listing';
import { noop } from 'lodash';

interface TradeListFilters {
  hedgingProduct?: HedgingInstruments[];
  state?: TradeState[];
  status?: TradeStatus[];
  sellCurrency?: string[];
  buyCurrency?: string[];
  clientId?: string[];
  query?: string;
  queryBy?: string;
}

const allInstrumentsSelected = ref(false);

const allStatesSelected = ref(false);

const allStatusesSelected = ref(false);

const ahTradesState = useAhTradesState();

const dateTypeOptions = [
  { label: 'Window start date', value: 'windowStartDate' },
  { label: 'Settlement date', value: 'settlementDate' },
  { label: 'Trade date', value: 'created' },
];

const dateTypeForm = ref(
  makeFormModel({
    name: 'dateForm',
    fieldType: 'form',
    fields: [
      selectField('dateType', 'Type of Date', dateTypeOptions, {
        required: false,
        defaultValue: 'windowStartDate',
        clearValue: '',
      }),
    ],
  })
);

const startDateForm = ref(
  makeFormModel({
    name: 'dateForm',
    fieldType: 'form',
    fields: [
      dateField('startDate', 'Start date', {
        required: false,
        clearable: true,
        clearValue: '',
      }),
    ],
  })
);

const endDateForm = ref(
  makeFormModel({
    name: 'dateForm',
    fieldType: 'form',
    fields: [
      dateField('endDate', 'End date', {
        required: false,
        clearable: true,
        clearValue: '',
      }),
    ],
  })
);

interface TradeListFiltersEmits extends UseManagedListFilterEmits<TradeListFilters> {}

const emit = defineEmits<TradeListFiltersEmits>();

const props = defineProps({
  ...defineUseManagedListFilterProps<TradeListFilters>(),
  /**
   * Whether to show state filters
   *
   * Any value other than false is considered truthy
   */
  showStates: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Which states to allow
   *
   * Only applicable if showStates is true
   */
  possibleStates: {
    type: Array as PropType<TradeState[]>,
  },
  /**
   * Whether to show status filters
   *
   * Any value other than false is considered truthy
   */
  showStatuses: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Which states to allow
   *
   * Only applicable if showStates is true
   */
  possibleStatuses: {
    type: Array as PropType<TradeStatus[]>,
  },
  /**
   * Which hedging instruments to allow
   */
  possibleInstruments: {
    type: Array as PropType<HedgingInstruments[]>,
  },
  /**
   * Whether to show date filters
   *
   * Any value other than false is considered truthy
   */
  showDates: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to show the search box for searching by trade id
   * (or client name, if allowClientNameSearch is truthy)
   *
   * Any value other than false is considered truthy
   */
  showSearch: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to allow searching by client name
   * Has no effect if showSearch is false
   *
   * Any value other than false is considered truthy
   */
  allowClientNameSearch: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether a trade is pre searched
   *
   * This value should be the same as the trade reference number
   */
  openTrade: {
    type: String,
  },
});

const { filterData } = useManagedListFilter<TradeListFilters>({
  props,
  emit,
  runSettersOnUndefined: true,
  filterKeys: [
    {
      key: 'hedgingProduct',
      getter: (filters) => {
        if (filters.value.hedgingProduct?.length) {
          const out =
            filters.value.hedgingProduct?.filter(
              (s) => !props.possibleInstruments?.length || props.possibleInstruments.includes(s)
            ) || [];
          allInstrumentsSelected.value = out.length === props.possibleStatuses?.length;
          return out;
        } else if (props.possibleInstruments?.length) {
          return props.possibleInstruments;
        }
      },
      setter: (value: any, filters) => {
        if (!Array.isArray(value)) {
          filters.value.hedgingProduct = [];
          return;
        }
        const filteredInstruments = value.filter(
          (s: any) => !props.possibleInstruments?.length || props.possibleInstruments.includes(s)
        );
        if (props.possibleInstruments && filteredInstruments.length >= props.possibleInstruments.length) {
          filters.value.hedgingProduct = !allInstrumentsSelected.value ? [] : props.possibleInstruments;
        } else {
          filters.value.hedgingProduct = filteredInstruments;
        }
      },
    },
    {
      key: 'state',
      getter: (filters) => {
        if (props.showStates !== false) {
          if (filters.value.state?.length) {
            const out = filters.value.state?.filter(
              (s) => !props.possibleStates?.length || props.possibleStates.includes(s)
            );
            allStatesSelected.value = out.length === props.possibleStates?.length;
            return out;
          } else if (props.possibleStates?.length) {
            return props.possibleStates;
          }
        }
      },
      setter: (value: any, filters) => {
        if (!Array.isArray(value)) {
          filters.value.state = [];
          return;
        }
        const filteredStates = value.filter(
          (s: any) => !props.possibleStates?.length || props.possibleStates.includes(s)
        );
        if (props.possibleStates && filteredStates.length >= props.possibleStates.length) {
          filters.value.state = !allStatesSelected.value ? [] : props.possibleStates;
        } else {
          filters.value.state = filteredStates;
        }
      },
    },
    {
      key: 'status',
      getter: (filters) => {
        if (props.showStatuses !== false) {
          if (filters.value.status?.length) {
            const out = filters.value.status?.filter(
              (s) => !props.possibleStatuses?.length || props.possibleStatuses.includes(s)
            );
            allStatusesSelected.value = out.length === props.possibleStatuses?.length;
            return out;
          } else if (props.possibleStatuses?.length) {
            return props.possibleStatuses;
          }
        }
      },
      setter: (value: any, filters) => {
        if (!Array.isArray(value)) {
          filters.value.status = [];
          return;
        }
        const filteredStates = value.filter(
          (s: any) => !props.possibleStatuses?.length || props.possibleStatuses.includes(s)
        );
        if (props.possibleStatuses && filteredStates.length >= props.possibleStatuses.length) {
          filters.value.status = !allStatusesSelected.value ? [] : props.possibleStatuses;
        } else {
          filters.value.status = filteredStates;
        }
      },
    },
    {
      key: 'sellCurrency',
      getter: (filters) => filters.value.sellCurrency || undefined,
      setter: (value: any, filters) => (filters.value.sellCurrency = value),
    },
    {
      key: 'buyCurrency',
      getter: (filters) => filters.value.buyCurrency || undefined,
      setter: (value: any, filters) => (filters.value.buyCurrency = value),
    },
    {
      key: 'clientId',
      getter: (filters) => filters.value.clientId || undefined,
      setter: (value: any, filters) => (filters.value.clientId = value),
    },
    {
      key: 'query',
      getter: (filters) => filters.value.query || undefined,
      setter: (value: any, filters) => (filters.value.query = value),
    },
    {
      key: 'queryBy',
      getter: () => (props.allowClientNameSearch === false ? ['REFERENCE_NUMBER'] : undefined),
      setter: noop,
    },
    ...dateTypeOptions.map((o) => ({
      key: `${o.value}From`,
      getter: () => {
        if (
          dateTypeForm.value.dateType === o.value &&
          startDateForm.value?.startDate &&
          !isNaN(new Date(startDateForm.value?.startDate).valueOf())
        ) {
          return startDateForm.value?.startDate;
        }
      },
      setter: (value: any) => {
        if (value) {
          startDateForm.value.startDate = new Date(value);
          dateTypeForm.value.dateType = o.value;
        }
      },
    })),
    ...dateTypeOptions.map((o) => ({
      key: `${o.value}To`,
      getter: () => {
        if (dateTypeForm.value.dateType === o.value && endDateForm.value.endDate) {
          let date = new Date(endDateForm.value.endDate);
          if (!isNaN(date.valueOf())) {
            return addDays(date, 1).toISOString();
          }
        }
      },
      setter: (value: any) => {
        if (value) {
          const date = subDays(new Date(value), 1);
          endDateForm.value.endDate = date.toISOString();
          dateTypeForm.value.dateType = o.value;
        }
      },
    })),
  ],
  sortAndPageKeys: [],
});

const hedgingInstrumentOptions = computed(() => {
  const instruments: HedgingInstruments[] = [
    HedgingInstruments.FX_SPOT,
    HedgingInstruments.FX_FORWARD,
    HedgingInstruments.WINDOW_FORWARD,
    HedgingInstruments.FLEXIBLE_FORWARD,
  ];

  return instruments
    .filter((k) => !props.possibleInstruments?.length || props.possibleInstruments.includes(k))
    .map((k) => {
      return {
        value: k,
        label: formatHedgingInstrument(k),
      };
    });
});

const stateOptions = computed(() => {
  return (Object.keys(tradeStateBadges) as TradeState[])
    .filter((k) => !props.possibleStates?.length || props.possibleStates.includes(k))
    .map((k) => {
      return {
        value: k,
        label: tradeStateBadges[k].label,
      };
    });
});

const statusOptions = computed(() => {
  return (Object.keys(tradeStatusBadges) as TradeStatus[])
    .filter((k) => !props.possibleStatuses?.length || props.possibleStatuses.includes(k))
    .map((k) => {
      return {
        value: k,
        label: tradeStatusBadges[k].label,
      };
    });
});

watch(
  () => props.openTrade,
  () => {
    if (props.openTrade) {
      filterData.value.query = props.openTrade;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="currency-filter-select">
    <BoxGrid alignH="start" alignV="stretch">
      <BoxGridItem cols="12">
        <BoxGrid alignH="start" alignV="stretch">
          <BoxGridItem lg="4" cols="12" align-self="center" v-if="showSearch !== false" class="field-group-wrapper">
            <VRow align-v="center">
              <VCol cols="12" sm="11" md="11" lg="11">
                <SearchInput
                  class="search-input mb-0 mock-label-padding"
                  :placeholder="`Search for ${allowClientNameSearch === false ? '' : 'client name or'} trade ID...`"
                  :search.sync="filterData.query"
                />
              </VCol>
              <VCol cols="12" sm="1">
                <InfoTooltip
                  class="mock-label-padding search-info-icon"
                  :text="ahTradesState.i18n.t('tooltips.tradeID')"
                />
              </VCol>
            </VRow>
          </BoxGridItem>
          <BoxGridItem
            sm="12"
            md="12"
            lg="3"
            cols="4"
            offset="0"
            :offset-lg="showSearch !== false ? 1 : 0"
            class="field-group-wrapper"
          >
            <label>
              Instrument
              <a
                v-if="filterData.hedgingProduct && filterData.hedgingProduct.length > 0"
                class="field-group-clear-link"
                @click="filterData.hedgingProduct = []"
              >
                clear
              </a>
            </label>
            <TagMultiSelect
              :maxFulltextLabels="2"
              :options="hedgingInstrumentOptions"
              :value.sync="filterData.hedgingProduct"
              itemsCountLabel="types"
            />
          </BoxGridItem>
          <BoxGridItem sm="6" md="6" lg="2" cols="4" class="field-group-wrapper">
            <CurrencyFilterSelect title="Sell Currency" :value.sync="filterData.sellCurrency" />
          </BoxGridItem>
          <BoxGridItem sm="6" md="6" lg="2" cols="4" class="field-group-wrapper">
            <CurrencyFilterSelect title="Buy Currency" :value.sync="filterData.buyCurrency" />
          </BoxGridItem>
        </BoxGrid>
      </BoxGridItem>

      <BoxGridItem cols="12" v-if="showDates !== false || showStates !== false">
        <BoxGrid class="mt-3" :alignH="ahTradesState.mediaQuery.is('smDown') ? 'start' : 'end'" alignV="stretch">
          <BoxGridItem
            sm="6"
            md="6"
            lg="2"
            cols="4"
            class="state-filter-holder field-group-wrapper"
            v-if="showStates !== false"
          >
            <label>
              State
              <a
                v-if="filterData.state && filterData.state.length > 0"
                class="field-group-clear-link"
                @click="filterData.state = []"
              >
                clear
              </a>
            </label>
            <TagMultiSelect
              :maxFulltextLabels="2"
              :options="stateOptions"
              :value.sync="filterData.state"
              itemsCountLabel="states"
            />
          </BoxGridItem>
          <BoxGridItem
            sm="6"
            md="6"
            lg="2"
            cols="4"
            class="state-filter-holder field-group-wrapper"
            v-if="showStatuses !== false"
          >
            <label>
              State
              <a
                v-if="filterData.status && filterData.status.length > 0"
                class="field-group-clear-link"
                @click="filterData.status = []"
              >
                clear
              </a>
            </label>
            <TagMultiSelect
              :maxFulltextLabels="2"
              :options="statusOptions"
              :value.sync="filterData.status"
              itemsCountLabel="statuses"
            />
          </BoxGridItem>
          <template v-if="showDates !== false">
            <BoxGridItem sm="6" md="6" lg="2" cols="4" class="dates-type-holder">
              <ValidatedForm :fm="dateTypeForm" />
            </BoxGridItem>
            <BoxGridItem class="dates-filter-holder">
              <ValidatedForm :fm="startDateForm" />
            </BoxGridItem>
            <BoxGridItem class="dates-filter-holder">
              <ValidatedForm :fm="endDateForm" />
            </BoxGridItem>
          </template>
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.dates-type-holder,
.state-filter-holder {
  min-width: 17%;
}

.year-wrapper {
  margin-top: 0 !important;
}

::v-deep .dates-filter-holder {
  min-width: 213px;
  flex-grow: 0;
  flex-basis: 210px;
}
</style>
