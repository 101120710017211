<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import {
  TargetSelection,
  GroupTargetSelection,
  IndividualTargetSelection,
  EmailTargetSelection,
  matchesRecipient,
  PhoneTargetSelection,
  NotificationSetting,
  RateAlertSetting,
  RegistrationStatus,
  NotificationSettingConfig,
  IndividualType,
} from 'ah-api-gateways';
import TargetSearchSelect from '@/app/components/user/TargetSearchSelect.vue';
import NotificationTargetBlock from '@/app/components/settings/notifications/NotificationTargetBlock.vue';
import { rateAlertInequalityTypeStrings } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { computed, PropType, reactive, ref, watch } from 'vue';

const props = defineProps({
  config: {
    type: Object as PropType<NotificationSettingConfig>,
    required: true,
  },
  notification: {
    type: Object as PropType<NotificationSetting | RateAlertSetting>,
    required: true,
  },
});

const emit = defineEmits({
  'update:notification': (_notification: NotificationSetting | RateAlertSetting) => true,
});

const authStore = useAuthStore();

const allUsersFM = reactive(
  makeFormModel({
    name: 'allUsersForm',
    fieldType: 'form',
    fields: [
      radioField(
        'allUsers',
        '',
        [
          { label: 'All users', value: true },
          { label: 'Choose users', value: false },
        ],
        {
          defaultValue: true,
          inline: true,
        }
      ),
    ],
  })
);

const modal = ref<InstanceType<typeof BModal>>();

const notificationRecipients = ref<TargetSelection[]>([]);

const isRateAlert = computed(() => !!(props.notification as RateAlertSetting).inequality);

const baseCountryCode = computed(() => authStore.loggedInIdentity!.address?.countryCode);

const notificationRate = computed(() => {
  if ((props.notification as RateAlertSetting).rate) {
    return (props.notification as RateAlertSetting).rate.toFixed(3);
  }

  return '';
});

const clientId = computed(() => authStore.loggedInIdentity?.client?.id);

const partnerId = computed(() => authStore.loggedInIdentity?.partner?.id);

const hasRecipients = computed(() => {
  return !!(
    individualRecipients.value.length ||
    groupRecipients.value.length ||
    phoneRecipients.value.length ||
    emailRecipients.value.length
  );
});

const individualRecipients = computed(() => {
  return notificationRecipients.value.filter(
    (i) => (i as IndividualTargetSelection).individual
  ) as IndividualTargetSelection[];
});

const groupRecipients = computed(
  () => notificationRecipients.value.filter((i) => (i as GroupTargetSelection).group) as GroupTargetSelection[]
);

const phoneRecipients = computed(
  () => notificationRecipients.value.filter((i) => (i as PhoneTargetSelection).phoneNumber) as PhoneTargetSelection[]
);

const emailRecipients = computed(
  () => notificationRecipients.value.filter((i) => (i as EmailTargetSelection).email) as EmailTargetSelection[]
);

const targetTypes = computed(() => {
  const targetTypes: any = {
    phone: {
      disallowed: phoneRecipients.value.map((r) => r.phoneNumber),
    },
    email: {
      disallowed: emailRecipients.value.map((r) => r.email),
    },
  };

  const statusFilters = [RegistrationStatus.PENDING, RegistrationStatus.APPROVED, RegistrationStatus.REJECTED];

  const peopleFilters = clientId.value
    ? {
        clientId: clientId.value,
        type: [IndividualType.CLIENT_ADMIN, IndividualType.CLIENT_INDIVIDUAL],
        status: statusFilters,
      }
    : {
        partnerId: partnerId.value,
        type: [IndividualType.PARTNER_ADMIN, IndividualType.PARTNER_AGENT],
        status: statusFilters,
      };

  targetTypes.individual = {
    disallowed: individualRecipients.value.map((r) => r.individual.id),
    filters: peopleFilters,
  };

  targetTypes.group = {
    disallowed: groupRecipients.value.map((r) => r.group.id),
    filters: peopleFilters,
  };

  return targetTypes;
});

function isInRecipientsList(target: TargetSelection) {
  return !!notificationRecipients.value.find((i) => matchesRecipient(i, target));
}

function onTargetSelected(target: TargetSelection) {
  if (!isInRecipientsList(target)) {
    notificationRecipients.value.push(target);
  }
}

function deleteTarget(target: TargetSelection) {
  const index = notificationRecipients.value.findIndex((i) => matchesRecipient(i, target));
  if (index > -1) {
    notificationRecipients.value.splice(index, 1);
  }
}

function show() {
  modal.value?.show();
  resetData();
}

function save() {
  emit('update:notification', {
    ...props.notification,
    to: allUsersFM.allUsers
      ? []
      : notificationRecipients.value.filter((i) => !(i as any).client && !(i as any).partner),
  });
  modal.value?.hide();
}

function cancel() {
  modal.value?.hide();
}

function resetData() {
  allUsersFM.allUsers = props.notification.to.length === 0;
  notificationRecipients.value = [...props.notification.to];
}

watch(() => props.notification, resetData, { immediate: true });
</script>

<template>
  <span>
    <BModal title="Users to notify" modal-class="side-modal modal-lg" ref="modal" hide-footer>
      <p class="text-secondary mt-n3">Select members to be notified of the following:</p>
      <div class="card-block">
        <template v-if="!isRateAlert && config.name">
          <h3 class="mb-0">{{ config.name }}</h3>
          <p class="text-secondary mb-0">{{ config.description }}</p>
        </template>
        <template v-else>
          {{ notification.currencyPair }} rate is {{ rateAlertInequalityTypeStrings[notification.inequality] }}
          {{ notificationRate }}
        </template>
      </div>
      <ValidatedForm :fm="allUsersFM" class="mt-3" />
      <template v-if="!allUsersFM.allUsers">
        <TargetSearchSelect
          class="mb-3"
          :types="targetTypes"
          :baseCountryCode="baseCountryCode"
          @target-selected="onTargetSelected"
        />
        <div class="card-block recipients-list mb-3" v-if="hasRecipients">
          <template v-if="individualRecipients.length">
            <h3 class="recipients-list-title">Users</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.individual.id"
                v-for="target in individualRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
          <template v-if="groupRecipients.length">
            <h3 class="recipients-list-title">Groups</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.group.id"
                v-for="target in groupRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
          <template v-if="emailRecipients.length">
            <h3 class="recipients-list-title">External Emails</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.email"
                v-for="target in emailRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
          <template v-if="phoneRecipients.length">
            <h3 class="recipients-list-title">External phone numbers</h3>
            <BoxGrid align-content="start" align-h="start" class="notification-targets-holder">
              <BoxGridItem
                class="notification-target-item-holder"
                :key="target.phoneNumber"
                v-for="target in phoneRecipients"
              >
                <NotificationTargetBlock
                  class="notification-target-item"
                  :target="target"
                  @delete="deleteTarget(target)"
                />
              </BoxGridItem>
            </BoxGrid>
          </template>
        </div>
      </template>
      <div class="buttons">
        <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
        <VButton class="done-btn" @click="save">Done</VButton>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <VButton blurOnClick @click="show">Select Target</VButton>
    </slot>
  </span>
</template>

<style lang="scss">
.recipients-list-title {
  margin-bottom: math.div($padded-space, 3);
}

.notification-targets-holder {
  display: flex;
  margin-bottom: math.div($padded-space, 2);

  &:last-child {
    margin-bottom: 0;
  }

  .notification-target-item-holder {
    flex-basis: 33%;
    flex-grow: 0;
    min-width: 200px;

    .notification-target-item {
      width: 100%;
    }
  }
}
</style>
