<template>
  <svg width="31" height="18" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.93753 13.9773C6.209 13.9773 3.99642 11.7459 3.99642 8.99414H1C1 13.4169 4.55204 16.9891 8.92761 16.9891L8.93753 13.9773Z"
      fill="#FF5000"
    />
    <path
      d="M29.7557 8.995C29.7557 4.57223 26.2037 1 21.8281 1V4.02189C24.5566 4.02189 26.7692 6.25328 26.7692 9.005L29.7557 8.995Z"
      fill="#FF5000"
    />
    <path
      d="M16.8573 8.995C16.8573 4.57223 13.3052 1 8.92969 1V4.02189C11.6582 4.02189 13.8708 6.25328 13.8708 9.005C13.8708 13.4278 17.4228 17 21.7984 17V13.9781C19.0699 13.9781 16.8573 11.7467 16.8573 8.995Z"
      fill="#FF5000"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
