<script lang="ts" setup>
import FormField from './fields/FormField.vue';
import FormSection from './FormSection.vue';
import { BaseFormModel, isFormType } from '../interfaces/form';
import { computed, ref } from 'vue';
import { useFormElement } from '../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../composables/useFormElementInterfaces';

const props = defineProps(makeUseFormElementProps<BaseFormModel>());

const emit = defineEmits(makeUseFormElementEmits());

const item = ref<InstanceType<typeof FormField>>();

const { modelStateRef, getModelState, model, field, getSlotName } = useFormElement({
  emit,
  props,
});

const formWrapperClass = modelStateRef('formWrapperClass', '', true);

const fieldWrapperClass = modelStateRef('fieldWrapperClass', '', true);

const isForm = computed(() => {
  return isFormType(getModelState('fieldType'));
});

const hidden = computed(() => {
  const hidden = getModelState('hidden', false);

  return typeof hidden === 'function' ? hidden(model) : hidden;
});

function triggerFieldAction(path: string, action: any) {
  if (item.value?.triggerFieldAction) {
    item.value.triggerFieldAction(path, action);
  }
}

defineExpose({
  triggerFieldAction,
});
</script>

<template>
  <div v-show="!hidden" :class="['field-group-wrapper', isForm ? formWrapperClass : fieldWrapperClass]">
    <slot :name="getSlotName('before')" v-bind="{ field, model }" />
    <slot :name="getSlotName()" v-bind="{ field, model }">
      <FormSection v-if="isForm" :field="field" :model="model" @form-event="($event) => $emit('form-event', $event)">
        <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </FormSection>
      <FormField v-else ref="item" :field="field" :model="model" @form-event="($event) => $emit('form-event', $event)">
        <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </FormField>
    </slot>
    <slot :name="getSlotName('after')" v-bind="{ field, model }" />
  </div>
</template>
