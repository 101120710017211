<script lang="ts" setup>
import { Authority, Permission, UserEntityType, UserRole } from 'ah-api-gateways';
import PermissionsForm from './PermissionsForm.vue';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const props = defineProps({
  /**
   * Type of target - will affect the service that gets called to load/save data
   */
  targetType: { type: String as PropType<'user' | 'group'>, required: true },
  targetId: { type: String, required: true },
  role: { type: String as PropType<UserRole>, required: true },
});

const requestManager = useRequestManager({
  exposeToParent: ['loadTargetPermissions'],
  onRetryFromParentManager(k: string) {
    if (k === 'loadTargetPermissions') {
      loadTargetPermissions();
    }
  },
}).manager;

const services = getServices();

/**
 * Copy of the targetId property
 *
 * Used to maintain target reference until the debounced update is flushed
 */
const currTargetId = ref('');

const authorities = ref<Authority[]>([]);

const permissions = ref<Permission[]>([]);

const entityType = computed(() => {
  if (props.targetType === 'group') {
    return UserEntityType.GROUP;
  }
  return UserEntityType.USER;
});

function savePermission(permission: Permission) {
  requestManager
    .cancelAndNew(
      'savePermission',
      props.targetType === 'user'
        ? services.authz.setUserPermissions(props.targetId, permission, { errors: { silent: true } })
        : services.authz.setGroupPermissions(props.targetId, permission, { errors: { silent: true } })
    )
    .subscribe();
}

function loadTargetPermissions() {
  requestManager
    .cancelAndNew(
      'loadTargetPermissions',
      props.targetType === 'user'
        ? services.authz.getUserPermissions(props.targetId)
        : services.authz.getGroupPermissions(props.targetId, { errors: { silent: true } })
    )
    .subscribe((perms) => {
      permissions.value = perms;
    });
}

watch(
  [() => props.targetType, () => props.role],
  () => {
    requestManager
      .cancelAndNew('loadRole', services.authz.getRoleAuthorities(props.role, entityType.value))
      .subscribe((auths) => {
        authorities.value = auths ?? [];
      });
  },
  { immediate: true }
);

watch(
  () => props.targetId,
  () => {
    currTargetId.value = props.targetId;
    loadTargetPermissions();
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <h3>Permissions</h3>
    <slot />
    <LoadingIcon v-if="requestManager.requestStates.loadRole === 'pending'" />
    <PermissionsForm
      v-else
      :authorities="authorities"
      :value="permissions"
      @permission-change="savePermission($event)"
    />
  </div>
</template>
