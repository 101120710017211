import { HttpService, HttpBasedService, HttpOptions } from 'ah-requests';
import {
  Partner,
  PartnerSettings,
  PaginatedQuery,
  PaginatedResponse,
  ListQuery,
  standardiseQuerySort,
  ExportListType,
  IndividualGroup,
  IndividualGroupRequest,
  VersionedObject,
  DocumentExport,
} from '../models';
import { Observable } from 'rxjs';

export interface PartnerAdminListFilter {
  query?: string;
}

export class PartnerAdminService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'partnerService' } },
      },
    });
  }

  public getPartner(partnerId: string, options?: HttpOptions<Partner>) {
    return this.get<Partner>(`${this.baseUrl}partners/${partnerId}`, {
      options: {
        ...options,
        cache: {
          cacheKey: 'Partner',
          itemKey: partnerId,
          type: 'use',
        },
      },
    });
  }

  public deletePartner(partnerId: string, options?: HttpOptions<void>) {
    return this.delete(`${this.baseUrl}partners/${partnerId}`, { options });
  }

  public listPartners(
    query: PaginatedQuery<PartnerAdminListFilter>,
    options?: HttpOptions<PaginatedResponse<Partner>>
  ) {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<Partner>>(`${this.baseUrl}partners`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public downloadPartnerList(
    query: ListQuery<PartnerAdminListFilter>,
    fileFormat: ExportListType,
    options?: HttpOptions<DocumentExport>,
    documentTitle = 'Partners List'
  ) {
    query = standardiseQuerySort(query);

    return this.get<DocumentExport>(`${this.baseUrl}partners/export`, {
      options,
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public downloadPartnerWithCommissionsList(
    query: ListQuery<PartnerAdminListFilter>,
    fileFormat: ExportListType,
    options?: HttpOptions<DocumentExport>,
    documentTitle = 'Partners List'
  ) {
    // Partners with commissions use "partnerName" as the sorting
    query = standardiseQuerySort(query);
    if (query.sort) {
      const sortArr = Array.isArray(query.sort) ? query.sort : [query.sort];
      const partnerSortIndex = sortArr.findIndex((i) => i && i.startsWith('name,'));
      if (partnerSortIndex > -1) {
        sortArr.splice(partnerSortIndex, 1, `partnerName,${sortArr[partnerSortIndex].split(',')[1]}`);
        query.sort = sortArr;
      }
    }

    return this.get<DocumentExport>(`${this.baseUrl}partners-commissions/export`, {
      options,
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public createPartner(partner: Partial<Partner>, options?: HttpOptions<VersionedObject>) {
    return this.post<VersionedObject>(`${this.baseUrl}partners`, partner, { options });
  }

  public updatePartner(partnerId: string, partner: Partial<Partner>, options?: HttpOptions<VersionedObject>) {
    return this.put<VersionedObject>(`${this.baseUrl}partners/${partnerId}`, partner, {
      options: {
        ...options,
        cache: {
          cacheKey: 'Partner',
          itemKey: partnerId,
          type: 'delete',
        },
      },
    });
  }

  public getPartnerSettings(partnerId: string, options?: HttpOptions<PartnerSettings>) {
    return this.get<PartnerSettings>(`${this.baseUrl}partners/${partnerId}/settings`, {
      options: {
        ...options,
        cache: {
          cacheKey: 'PartnerSettings',
          itemKey: partnerId,
          type: 'use',
        },
      },
    });
  }

  public updatePartnerSettings(id: string, data: Partial<PartnerSettings>, options?: HttpOptions<PartnerSettings>) {
    return this.put<PartnerSettings>(`${this.baseUrl}partners/${id}/settings`, data, {
      options: {
        ...options,
        cache: {
          cacheKey: 'PartnerSettings',
          itemKey: id,
          type: 'override',
        },
      },
    });
  }

  public listPartnerGroups(
    partnerId: string,
    query: PaginatedQuery & { individualId?: string[] },
    options?: HttpOptions<PaginatedResponse<IndividualGroup>>
  ): Observable<PaginatedResponse<IndividualGroup>> {
    query = standardiseQuerySort(query);

    return this.get<PaginatedResponse<IndividualGroup>>(`${this.baseUrl}partners/${partnerId}/groups`, {
      axiosConfig: {
        params: query,
      },
      options,
    });
  }

  public getPartnerGroup(
    partnerId: string,
    groupId: string,
    options?: HttpOptions<IndividualGroup>
  ): Observable<IndividualGroup> {
    return this.get<IndividualGroup>(`${this.baseUrl}partners/${partnerId}/groups/${groupId}`, {
      options,
    });
  }

  public updatePartnerGroup(
    partnerId: string,
    group: IndividualGroupRequest,
    options?: HttpOptions<VersionedObject>
  ): Observable<VersionedObject> {
    return this.put<VersionedObject>(`${this.baseUrl}partners/${partnerId}/groups/${group.id}`, group, { options });
  }

  public createPartnerGroup(
    partnerId: string,
    group: Omit<IndividualGroupRequest, 'id'>,
    options?: HttpOptions<VersionedObject>
  ): Observable<VersionedObject> {
    return this.post<VersionedObject>(`${this.baseUrl}partners/${partnerId}/groups`, group, {
      options,
    });
  }
}
