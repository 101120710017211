<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 17V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V17"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M8 12L12 16L16 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 2V16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M18.7323 9.30844C16.5481 9.30844 14.7773 7.53773 14.7773 5.35344C14.7773 3.16915 16.5481 1.39844 18.7323 1.39844C20.9166 1.39844 22.6873 3.16915 22.6873 5.35344C22.6873 7.53773 20.9166 9.30844 18.7323 9.30844Z"
      fill="#EF6172"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
