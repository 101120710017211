/**
 * Directly adapted from https://github.com/flatanimals/vue-media-queries
 */

import _Vue, { PluginObject } from 'vue';
import throttle from 'lodash/throttle';

declare module 'vue/types/vue' {
  interface Vue {
    $mediaQuery: MediaQueries;
  }
}

export interface MediaQueriesOptions {
  bands?: { [key: string]: string };
  delay?: number;
}

export interface MediaQueries {
  expr: (expressionString: string) => boolean;
  is: (bandName: string) => boolean;
  below: (size: number, measurement?: string) => boolean;
  above: (size: number, measurement?: string) => boolean;
  between: (sizes: number[], measurement?: string) => boolean;
  beyond: (sizes: number[], measurement?: string) => boolean;
  resize: number;
  bands: { [key: string]: string },
  delay: number,
}

let mediaQuery: MediaQueries | null = null

export default {
  install: function install(
    Vue: typeof _Vue,
    options: MediaQueriesOptions = {
      bands: {
        xsDown: '(max-width: 575.98px)',
        xsOnly: '(max-width: 575.98px)',
        smUp: '(min-width: 576px)',
        smDown: '(max-width: 767.98px)',
        smOnly: '(min-width: 576px) and (max-width: 767.98px)',
        mdUp: '(min-width: 768px)',
        mdDown: '(max-width: 991.98px)',
        mdOnly: '(min-width: 768px) and (max-width: 991.98px)',
        lgUp: '(min-width: 992px)',
        lgDown: '(max-width: 1199.98px)',
        lgOnly: '(min-width: 992px) and (max-width: 1199.98px)',
        xlUp: '(min-width: 1200px)',
        xlOnly: '(min-width: 1200px)',
      },
    }
  ) {
    const bands = Object.assign({}, options.bands);
    const delay = options.delay || 200;
    const resize = 1;

    const mq = new Vue({
      data: {
        bands,
        delay,
        resize,
      },
      methods: {
        getArgs(args: any[]) {
          return args.length > 0 ? args.reverse() : args;
        },
        prepare(val: string) {
          return ('' + parseInt(val)).length === ('' + val).length ? `${val}px` : val;
        },
        expr(expressionString: string) {
          return this.resize && matchMedia(expressionString).matches;
        },
        is(band: string) {
          if (!this.bands[band]) {
            return false;
          }
          return this.expr(this.bands[band]);
        },
        below(...args: any[]) {
          const [value, measurement = 'width'] = this.getArgs(args);
          return this.expr(`(max-${measurement}: ${this.prepare(value)})`);
        },
        above(...args: any[]) {
          const [value, measurement = 'width'] = this.getArgs(args);
          return this.expr(`(min-${measurement}: ${this.prepare(value)})`);
        },
        between(...args: any[]) {
          const [value, measurement = 'width'] = this.getArgs(args);
          const [minVal, maxVal] = value;

          return this.expr(`
            (min-${measurement}: ${this.prepare(minVal)}) and
            (max-${measurement}: ${this.prepare(maxVal)})
          `);
        },
        beyond(...args: any[]) {
          const [value, measurement = 'width'] = this.getArgs(args);
          const [minVal, maxVal] = value;

          return this.expr(`
            (min-${measurement}: ${this.prepare(maxVal)}),
            (max-${measurement}: ${this.prepare(minVal)})
          `);
        },
      },
    });

    mediaQuery = mq as MediaQueries;

    const throttleResize = throttle(() => {
      mq.resize = (mq.resize % 3) + 1;
      mq.$forceUpdate();
    }, this.delay);

    window.addEventListener('resize', throttleResize);

    Vue.prototype.$mediaQuery = mq;
  },
} as PluginObject<void>;

export function useMediaQuery(): MediaQueries {
  if (!mediaQuery) {
    throw 'MediaQuery plugin not initialised!';
  }

  return mediaQuery;
}
