<script lang="ts" setup>
import { AmountType, Trade, getCcyByAmountType } from 'ah-api-gateways';
import { computed } from 'vue';
import TradesFormattedCurrency from '../../currency/TradesFormattedCurrency.vue';

const props = defineProps<{
  item: Trade;
}>();

const buyCCY = computed(() => getCcyByAmountType(props.item, AmountType.BUY));

const sellCCY = computed(() => getCcyByAmountType(props.item, AmountType.SELL));
</script>

<template>
  <div class="some-class">
    <div>
      Selling:
      <TradesFormattedCurrency
        class="currency-value"
        :value="item.hasDrawdowns ? sellCCY.remainingClientAmount : sellCCY.clientAmount"
        :currency="sellCCY.currency"
      />
      <template v-if="item.hasDrawdowns">
        <br />
        <span class="text-secondary">
          Original:
          <TradesFormattedCurrency class="currency-value" :value="sellCCY.clientAmount" :currency="sellCCY.currency" />
        </span>
      </template>
    </div>
    <div>
      Buying:
      <TradesFormattedCurrency
        class="currency-value"
        :value="item.hasDrawdowns ? buyCCY.remainingClientAmount : buyCCY.clientAmount"
        :currency="buyCCY.currency"
      />
      <template v-if="item.hasDrawdowns">
        <br />
        <span class="text-secondary">
          Original:
          <TradesFormattedCurrency class="currency-value" :value="buyCCY.clientAmount" :currency="buyCCY.currency" />
        </span>
      </template>
    </div>
  </div>
</template>
