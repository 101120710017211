export function isDescendant(parents: Element | Element[], child: Element, includeParent = false) {
  let node = includeParent ? child : child.parentNode;
  const parentsArray = Array.isArray(parents) ? parents : [parents];
  while (node != null) {
    if (parentsArray.includes(node as Element)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
