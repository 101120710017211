<script setup lang="ts">
import { Stoppable, useIntervalFn, useTimeoutFn } from '@vueuse/core';
import { SECOND, DAY, HOUR, MINUTE } from 'ah-common-lib/src/constants/time';
import { ref, watch, computed } from 'vue';

const props = defineProps({
  targetDate: {
    type: Date,
    required: true,
  },
  pause: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: [Boolean, String],
    default: false,
  },
});

const difference = ref<string[]>([]);

const emit = defineEmits({
  'timer-end': () => true,
});

function updateUI() {
  let differenceDate = new Date(props.targetDate).getTime() - new Date().getTime();

  if (differenceDate >= 0) {
    let daysDifference = Math.floor(differenceDate / 1000 / 60 / 60 / 24);
    differenceDate -= daysDifference * DAY;
    let hoursDifference = Math.floor(differenceDate / 1000 / 60 / 60);
    differenceDate -= hoursDifference * HOUR;
    let minutesDifference = Math.floor(differenceDate / 1000 / 60);
    differenceDate -= minutesDifference * MINUTE;
    let secondsDifference = Math.floor(differenceDate / SECOND);

    const value = [
      daysDifference.toString(),
      hoursDifference.toString(),
      minutesDifference.toString(),
      secondsDifference.toString(),
    ].map((elem) => elem);

    while (value[0] === '0') {
      value.shift();
    }

    difference.value = value;
  } else {
    difference.value = ['0'];
  }
}

useIntervalFn(updateUI, SECOND);

let timerTimeout: Stoppable | null = null;

const timelabel = computed(() => {
  if (difference.value.length === 1) {
    return ' s';
  }
  return '';
});

watch(
  () => [props.targetDate, props.pause],
  () => {
    if (timerTimeout) {
      timerTimeout.stop();
    }
    if (new Date(props.targetDate) >= new Date()) {
      timerTimeout = useTimeoutFn(() => {
        if (!props.pause) {
          emit('timer-end');
        }
      }, props.targetDate.valueOf() - Date.now());
    } else {
      if (!props.pause) {
        emit('timer-end');
      }
    }
    updateUI();
  },
  { immediate: true }
);

function refresh() {
  emit('timer-end');
}
</script>

<template>
  <span class="updated-date">
    <template v-if="loading === false">
      <span>Refreshing in </span>
      <template v-for="(differenceElement, index) in difference">
        <span :key="`counter${index}`">{{ differenceElement }}</span>
        <span v-if="index < difference.length - 1" :key="`separator-${index}`">:</span>
      </template>
      <span> {{ timelabel }}</span>
    </template>
    <template v-else> Updating... </template>
    <span @click="refresh"> <IconRefresh :class="['refresh-icon', { loading }]" /> </span>
  </span>
</template>

<style lang="scss" scoped>
.refresh-icon {
  margin-left: 0.5em;
  cursor: pointer;

  &.loading {
    animation: spin 4s linear infinite;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
