import { addressForm } from '../general/addressForm';
import { subYears } from 'date-fns';
import { Form } from '../../interfaces';
import { textField, dateField, emailField, phoneField } from '../../models';
import { requiredIfStateValue, maxDate, ifTest, date } from '../../validators';
import { validName } from 'ah-common-lib/src/form/validators';

export function personalDetailsForm(requireAllFields = false, readonly = false): Form {
  let requiredMessages = {};
  if (!readonly) {
    requiredMessages = requireAllFields
      ? {
          requiredText: '* All fields are mandatory.',
        }
      : {
          showRequiredMarkers: true,
          requiredText: '* Mandatory fields.',
        };
  }

  return {
    name: 'personalDetails',
    state: {
      title: 'Personal details',
      class: 'form-narrow margin-auto',
      ...requiredMessages,
    },
    fieldType: 'form',
    fields: [
      textField(
        'firstName',
        'First name',
        { class: 'half-width left', required: !readonly, readonly },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last name',
        { class: 'half-width right', required: !readonly, readonly },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      dateField(
        'birthDate',
        'Date of birth',
        {
          useDateTime: false,
          required: !readonly,
          readonly,
          errorMessages: {
            maxDate: 'Must be 18 years or older.',
          },
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(requiredIfStateValue('birthDate'), (a) => !(a instanceof Date)),
          maxDate: maxDate(subYears(new Date(), 18)),
        }
      ),
      emailField('email', 'Email'),
      phoneField('phoneNumber', 'Main phone number'),
      phoneField('workPhoneNumber', 'Work phone number', { required: false }),
      addressForm(
        'address',
        'Contacts',
        {
          state: {
            class: 'form-narrow',
            readonly,
          },
        },
        requireAllFields
      ),
    ],
  };
}
