import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import { FxMargins } from '../models';

export class SpreadsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'spreadsService' } },
      },
    });
  }

  public getClientSpreads(clientId: string, options?: Partial<HttpOptions<FxMargins>>) {
    return this.get<FxMargins>(`${this.baseUrl}spreads/clients/${clientId}`, {
      options: { ...options },
    });
  }

  public updateClientSpreads(clientId: string, settings: FxMargins, options?: Partial<HttpOptions<FxMargins>>) {
    return this.put<FxMargins>(`${this.baseUrl}spreads/clients/${clientId}`, settings, {
      options: { ...options },
    });
  }

  // only allowed when admin

  public getPartnerSpreads(partnerId: string, options?: Partial<HttpOptions<FxMargins>>) {
    return this.get<FxMargins>(`${this.baseUrl}spreads/partners/${partnerId}`, {
      options: { ...options },
    });
  }

  public updatePartnerSpreads(partnerId: string, settings: FxMargins, options?: Partial<HttpOptions<FxMargins>>) {
    return this.put<FxMargins>(`${this.baseUrl}spreads/partners/${partnerId}`, settings, {
      options: { ...options },
    });
  }
}
