<script setup lang="ts">
import { computed, ref } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import NotificationsList from 'ah-notifications/src/components/notifications/NotificationsList.vue';
import LastLoginInfo from '@/app/components/session/LastLoginInfo.vue';
import NotificationsIcon from '@/app/icons/svgs/UpdatesIcon.vue';
import XHedgeNotificationsModal from '../nav/XHedgeNotificationsModal.vue';

const user = computed(() => useAuthStore().loggedInIdentity);

const notificationList = ref<InstanceType<typeof NotificationsList> | null>(null);

const greetingLabel = computed(() => {
  const now = new Date();

  if (now.getHours() > 5 && now.getHours() <= 12) {
    return 'Morning';
  } else if (now.getHours() > 12 && now.getHours() <= 18) {
    return 'Afternoon';
  } else {
    return 'Night';
  }
});
</script>

<template>
  <div class="notification-widget">
    <div class="greeting-label">
      <h1 v-if="user">
        Good {{ greetingLabel }}, <b>{{ user.firstName }} </b>
      </h1>
      <LastLoginInfo class="last-login" />
    </div>
    <NotificationsList
      ref="notificationList"
      class="notifications-widget-list"
      withUpdatesNotification
      simple
      :maxItems="5"
    >
      <template #noResults>
        <div class="no-results-info">
          <div class="chart-icon">
            <NotificationsIcon />
          </div>
          There are no notifications
        </div>
      </template>
    </NotificationsList>
    <XHedgeNotificationsModal v-slot="{ show }" v-if="!notificationList?.noNotifications">
      <VButton blurOnClick class="transparent text-small" @click="show">
        View all notifications <IconArrowRight class="arrow-icon" />
      </VButton>
    </XHedgeNotificationsModal>
  </div>
</template>

<style lang="scss" scoped>
.notification-widget {
  padding: 0 1rem;

  .btn {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
  }

  .greeting-label {
    padding: 0 1rem;
    display: inline-flex;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid;
    @include themedBorderColor($color-primary);
    h1 {
      font-size: 2.5em;
      margin-bottom: 0;
      &:not(b) {
        font-weight: $font-weight-regular;
      }
    }
    .last-login {
      margin-left: auto;
      ::v-deep p {
        margin-bottom: 0;
      }
    }
  }
  .notifications-widget-list {
    padding-top: 2em;
    padding-right: 2.5em;
    padding-left: 1rem;
    @include themedTextColor($color-primary, $color-dark-primary);
  }
}

::v-deep {
  .no-results-info {
    margin: 2.5rem 0;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);

    .chart-icon {
      margin: 1rem 0;
      svg {
        width: 3rem;
        height: auto;
        path {
          @include themedPropColor('stroke ', $color-text-secondary, $color-dark-text-secondary);
        }
      }
    }
  }
}
</style>
