import Vue, { PluginObject, onBeforeUnmount } from 'vue';

const eventBus = new Vue();

export default {
  install: function install(vue: typeof Vue) {
    vue.eventBus = eventBus;
    vue.prototype.$eventBus = eventBus;
  },
} as PluginObject<void>;

export function useEventBus() {
  return eventBus;
}

export function useEventBusListener(event: string, callback: () => void, cleanup = true) {
  eventBus.$on(event, callback);

  if (cleanup) {
    onBeforeUnmount(() => eventBus.$off(event, callback));
  }
}
