export enum TradeState {
  SUBMITTED = 'SUBMITTED',
  VERIFIED = 'VERIFIED',
  OPEN = 'OPEN',
  CANCELING = 'CANCELING',
  CLOSED_REJECTED = 'CLOSED_REJECTED',
  CLOSED_SETTLED = 'CLOSED_SETTLED',
  CLOSED_CANCELED = 'CLOSED_CANCELED',
  CLOSED_AMENDED = 'CLOSED_AMENDED',
}

export const adminOnlyTradeStates: TradeState[] = [TradeState.CLOSED_AMENDED];

export const openTradeStates = [TradeState.OPEN, TradeState.SUBMITTED, TradeState.VERIFIED];

/**
 * Active margin states for a trate
 *
 * Trades in this state will apply their margin values against the Client's Collaterals
 *
 * Exceptions (will never apply margins):
 *  - Trades with `syncFunds == true`
 *  - Spot Trades with `spotsIm == false`
 */
export const activeMarginTradeStates = [TradeState.VERIFIED, TradeState.OPEN, TradeState.CANCELING];

/**
 * Close trade states
 */
export const closedTradeStates = [
  TradeState.CANCELING,
  TradeState.CLOSED_CANCELED,
  TradeState.CLOSED_REJECTED,
  TradeState.CLOSED_SETTLED,
  TradeState.CLOSED_AMENDED,
];

export const tradeStateBadges: Record<TradeState, { label: string; class: string }> = {
  [TradeState.SUBMITTED]: {
    label: 'Submitted',
    class: 'light-grey',
  },
  [TradeState.CANCELING]: {
    label: 'Cancelling',
    class: 'red',
  },
  [TradeState.VERIFIED]: {
    label: 'Verified',
    class: 'light-grey',
  },
  [TradeState.OPEN]: {
    label: 'Open',
    class: 'light-purple',
  },
  [TradeState.CLOSED_REJECTED]: {
    label: 'Rejected',
    class: 'red',
  },
  [TradeState.CLOSED_CANCELED]: {
    label: 'Cancelled',
    class: 'light-red',
  },
  [TradeState.CLOSED_SETTLED]: {
    label: 'Settled',
    class: 'green',
  },
  [TradeState.CLOSED_AMENDED]: {
    label: 'Amended',
    class: 'black',
  },
};

export const clientTradeStates = (Object.keys(tradeStateBadges) as TradeState[]).filter(
  (k) => !adminOnlyTradeStates.includes(k)
);
