export const allowedCurrencies = ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'JPY', 'NZD', 'USD', 'ZAR'] as const;

export type Currency = (typeof allowedCurrencies)[number];

export interface CurrencyExchange {
  sellCurrency: Currency;
  buyCurrency: Currency;
  rate: number;
  invertedRate: number;
  rateUpdatedTimestamp: string;
}
