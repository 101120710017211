<script setup lang="ts">
import { computed } from 'vue';
import type { Notification } from 'ah-api-gateways';
/**
 * File Export Card component
 *
 * Displays a file export notification.
 *
 * TODO: designs for notifications will likely become like this one. This may replace the existing NotificationCard then
 *
 */

const props = withDefaults(
  defineProps<{
    notification: Notification;
    /**
     * Open Resource button text content
     */
    openResourceText?: string;
    /**
     * Whether to show the Open Resource button
     */
    showOpenResource?: boolean | string;
    /**
     * Loading state for the Open Resource button
     */
    loading?: boolean | string;
  }>(),
  {
    openResourceText: 'Open resource',
    showOpenResource: false,
    loading: false,
  }
);

const emit = defineEmits<{
  (e: 'mark-as-read', notification: Notification): void;
  (e: 'open-resource', notification: Notification): void;
}>();

function markAsRead() {
  emit('mark-as-read', props.notification);
}

function openResource() {
  emit('open-resource', props.notification);
}

const isOpenResourceShown = computed(() => props.showOpenResource !== false);
</script>

<template>
  <div class="notification-card my-2" :class="{ read: notification.read }">
    <div class="d-flex justify-content-between mb-1">
      <span class="d-flex">
        <h4>{{ notification.title }}</h4>
      </span>
      <span v-if="!notification.read" class="notification-dot" @click="markAsRead()">&#9679;</span>
    </div>
    <span>{{ notification.message }}</span>
    <div class="open-resource-wrapper" v-if="isOpenResourceShown">
      <VButton blurOnClick @click="openResource" :loading="props.loading" class="open-resouce btn-stroked">
        <slot name="open-resource-text">
          {{ openResourceText }}
        </slot>
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-card {
  padding: 0.8rem;
  @include themedBorderColor($color-primary);
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;

  .notification-dot {
    @include themedTextColor($color-primary-light);
    font-size: 1.6em;
    line-height: 0.8em;
    cursor: pointer;
  }

  .icon {
    width: 1em;
    align-self: center;
  }

  .open-resource-wrapper {
    text-align: center;
    margin-top: 1em;
  }
}
</style>
