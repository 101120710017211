import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const clientTableFields: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    header: 'Users',
    key: 'individuals',
  },
  {
    header: 'Registration Date',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'Assignees',
    key: 'assignees',
  },
];

export const clientSelectionTableFields: TableFieldDefinition[] = [
  {
    header: '',
    key: 'selectRadio',
  },
  {
    header: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    header: 'Status',
    key: 'status',
    sortable: true,
  },
  {
    header: 'Assignees',
    key: 'assignees',
  },
];
