<script setup lang="ts">
import AcoTiersModal from './AcoTiersModal.vue';
import BoxGrid from '../common/boxGrid/BoxGrid.vue';
import BoxGridBlock from '../common/boxGrid/BoxGridBlock.vue';
import DataRow from 'ah-common-lib/src/common/components/DataRow.vue';
import InfoTooltip from '../common/InfoTooltip.vue';
import LoadingIcon from 'ah-common-lib/src/icons/components/LoadingIcon.vue';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import VButton from 'ah-common-lib/src/common/components/VButton.vue';
import VCol from 'ah-common-lib/src/common/components/VCol.vue';
import VRow from 'ah-common-lib/src/common/components/VRow.vue';
import ValidatedForm from 'ah-common-lib/src/form/components/formComponents/ValidatedForm.vue';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { BankingScheme, FundingCharge, FeePaymentType, AcoTier } from 'ah-api-gateways';
import { FormValidation, FormEvent, FormModel } from 'ah-common-lib/src/form/interfaces';
import { Observable, combineLatest } from 'rxjs';
import { computed, PropType, ref, watch } from 'vue';
import { getChildModel } from 'ah-common-lib/src/form/helpers';
import { getServices } from '@/app/services';
import { makeFormModel, resetForm, setState } from 'ah-common-lib/src/form/helpers';
import { numberField } from 'ah-common-lib/src/form/models';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';

export interface CombinedDisplayFee {
  id: string;
  rail: string;
  currency: string;
  chargeType: string;
  currencyRail: string;
  defaultFee: number;
  partnerFee: number;
}

/**
 * Fees Editor component
 * Displays fees and allows editing (and saving)
 *
 * Emits:
 * - 'cancel' when cancel button is clicked and fields are reset to their initial values
 * - 'update:formBusy' whenever the requestManager state changes, for .sync prop consumption
 */

const props = defineProps({
  clientId: {
    type: String,
    default: null,
  },
  paymentType: {
    type: String as PropType<FeePaymentType>,
    required: true,
  },
  bankingScheme: {
    type: String as PropType<BankingScheme>,
  },
  clientName: {
    type: String,
  },
});

const emit = defineEmits({
  cancel: () => true,
  'update:formBusy': (_payload: Boolean) => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'submit') {
      submit();
    }
    if (k === 'loadFees') {
      loadFees();
    }
    if (k === 'loadDefaultFees') {
      loadDefaultFees();
    }
  },
}).manager;

const services = getServices();

const mediaQuery = useMediaQuery();

const toast = useToast();

const checkBelowValues = ref<InstanceType<typeof BModal>>();

const partnerFees = ref<FundingCharge[]>([]);

const defaultFees = ref<FundingCharge[]>([]);

const combinedFeesArr = ref<CombinedDisplayFee[]>([]);

const updateFeeFormModelValidations = ref<{ [key: string]: FormValidation | null }>({});

const updateFeeFormModels = ref<{ [key: string]: FormModel }>({});

const savedLastValue = ref<CombinedDisplayFee | null>(null);

const toolTipText =
  '<b>What is the difference between ‘Shared’ and ‘Ours’ charge type?</b> <br><br> &#x2022 <b> Shared (SHA):</b> The intermediary bank charges are deducted from the payment amount. As a result, the payment amount received in the beneficiary bank account may be less than the full amount expected.<br> &#x2022 <b>Ours (ACO):</b> The intermediary bank charges are covered by the payer and not deducted from the payment amount. The beneficiary receives the full payment amount but the payer will be charged a higher payment fee to cover the intermediary bank charges';

const typeLabel = computed(() => (props.paymentType === 'RECEIPT' ? 'Funding' : 'Payment'));

const areAllFormsValid = computed(() => {
  const invalidIndex = Object.keys(updateFeeFormModelValidations.value).findIndex((key) => {
    const validation = updateFeeFormModelValidations.value[key];
    return !validation || validation.$invalid;
  });

  return invalidIndex === -1;
});

const areAllFormsPristine = computed(() => {
  const invalidIndex = Object.keys(updateFeeFormModelValidations.value).findIndex((key) => {
    const validation = updateFeeFormModelValidations.value[key];
    return validation?.$dirty;
  });

  return invalidIndex === -1;
});

const isStackedView = computed(() => mediaQuery.is('smDown'));

/**
 * Create key-value object containing a form for each fee
 *
 * Validation object is created with keys pre-set to null, to ensure reactivity
 */
function makeFormModels() {
  const formModels: { [key: string]: FormModel } = {};
  const formValidations: { [key: string]: FormValidation | null } = {};

  combinedFeesArr.value.forEach((fee) => {
    const model = makeFormModel({
      name: `feeForm-${fee.id}`,
      fieldType: 'form',
      fields: [
        numberField('value', '', {
          required: true,
          inputClass: `form-control ${fee.defaultFee > fee.partnerFee ? 'below-value' : 'above-value'}`,
        }),
      ],
    });

    model.value = fee.partnerFee;

    formModels[fee.id] = model;
    formValidations[fee.id] = null;
  });

  updateFeeFormModels.value = formModels;
  updateFeeFormModelValidations.value = formValidations;
}

function paintAllFees() {
  combinedFeesArr.value.forEach((fee) => {
    if (updateFeeFormModels.value[fee.id]) {
      const formField = getChildModel(updateFeeFormModels.value[fee.id], 'value');
      if (formField) {
        setState(
          formField,
          'inputClass',
          `form-control ${fee.defaultFee > updateFeeFormModels.value[fee.id].value ? 'below-value' : 'above-value'}`
        );
      }
    }
  });
}

function onFeeFormEvent(event: FormEvent<number>, fee: CombinedDisplayFee) {
  if (event.event === 'form-field-set-value') {
    setState(
      event.model,
      'inputClass',
      `form-control ${fee.defaultFee > event.field.$model ? 'below-value' : 'above-value'}`
    );
    if (!savedLastValue.value && fee.defaultFee > event.field.$model) {
      savedLastValue.value = fee;
      checkBelowValues.value?.show();
    }
  }
}

function cancel() {
  resetFeesToInitial();
  Object.values(updateFeeFormModelValidations.value).forEach((validation) => {
    if (validation) {
      resetForm(validation);
    }
  });
  emit('cancel');
}

function handleBellowValuesHide(event: BvModalEvent) {
  if (event.trigger !== 'ok') {
    cancelFeeChange();
  }
}

function cancelFeeChange() {
  const model = updateFeeFormModels.value[savedLastValue.value!.id];
  model.value = savedLastValue.value!.partnerFee;
  setState(
    model,
    'inputClass',
    `form-control ${savedLastValue.value!.defaultFee > model.value ? 'below-value' : 'above-value'}`
  );
  paintAllFees();
}

function fundingFeesRequest() {
  const fundingFeeDetails = {
    type: props.paymentType,
    bankingScheme: props.bankingScheme,
    sort: 'bankingScheme,desc',
  };
  if (props.clientId) {
    return services.fees.listClientFees({ ...fundingFeeDetails, clientId: props.clientId });
  }
  return services.fees.listPartnerFees({ ...fundingFeeDetails });
}

function paymentRailToDisplay(tier: AcoTier | null) {
  switch (tier) {
    case AcoTier.ACO_TIER_1:
      return 'Tier 1';
    case AcoTier.ACO_TIER_2:
      return 'Tier 2';
    case AcoTier.ACO_TIER_3:
      return 'Tier 3';
    case AcoTier.ACO_TIER_4:
      return 'Tier 4';
    default:
      return '';
  }
}

function submit() {
  if (!areAllFormsValid.value) {
    return;
  }

  const changedFees: CombinedDisplayFee[] = combinedFeesArr.value.filter(
    (fee) => fee.partnerFee != updateFeeFormModels.value[fee.id].value
  );

  const actions: Observable<FundingCharge[]>[] = changedFees.map((fee) =>
    services.fees.updateFees(fee.id, updateFeeFormModels.value[fee.id].value)
  );

  requestManager.currentOrNew('submit', combineLatest(actions)).subscribe(() => {
    Object.values(updateFeeFormModelValidations.value).forEach((validation) => {
      if (validation) {
        resetForm(validation);
      }
    });
    loadFees();
    toast.success('Fees Updated Successfully.');
  });
}

function resetFeesToInitial() {
  combinedFeesArr.value.forEach((fee) => {
    updateFeeFormModels.value[fee.id].value = fee.partnerFee;
  });
  paintAllFees();
}

// Method called by parent component
function setFeesToZero() {
  combinedFeesArr.value.forEach((fee) => {
    updateFeeFormModels.value[fee.id].value = 0;
  });
  paintAllFees();
  submit();
}

// Method called by parent component
function setFeesToDefault() {
  combinedFeesArr.value.forEach((fee) => {
    updateFeeFormModels.value[fee.id].value = fee.defaultFee;
  });
  paintAllFees();
  submit();
}

function loadDefaultFees() {
  requestManager
    .cancelAndNew(
      'loadDefaultFees',
      services.fees.listAHFees({
        type: props.paymentType,
        bankingScheme: props.bankingScheme,
        sort: 'bankingScheme,desc',
      })
    )
    .subscribe((response) => {
      defaultFees.value =
        response.filter((fee, index, self) =>
          fee.tier ? index === self.findIndex((t) => t.tier === fee.tier) : true
        ) ?? null;
      mapPartnerFeesToDefaultFees();
      makeFormModels();
      paintAllFees();
    });
}

function mapPartnerFeesToDefaultFees() {
  combinedFeesArr.value = [];
  defaultFees.value.forEach((defaultFee) => {
    const partnerFee = partnerFees.value.find(
      (f) =>
        f.paymentRail === defaultFee.paymentRail &&
        f.tier === defaultFee.tier &&
        f.paymentCurrency === defaultFee.paymentCurrency
    );

    /**
     * Partner fee is assumed to exist as the API only allows editing, not creation
     *
     * Any fee for which there isn't a partner fee is not added to the list
     *
     * any currency which is repeated, paymentRail is added for it to differentiate repeatedness
     */

    if (partnerFee) {
      combinedFeesArr.value.push({
        id: partnerFee.id,
        rail: partnerFee.bankingScheme,
        currency: partnerFee.paymentCurrency || paymentRailToDisplay(defaultFee.tier) || 'All',
        currencyRail:
          defaultFees.value.filter((fee) => fee.paymentCurrency === defaultFee.paymentCurrency).length === 1
            ? ''
            : defaultFee.paymentRail,
        chargeType: defaultFee.chargeType,
        defaultFee: defaultFee.fee,
        partnerFee: partnerFee.fee,
      });
    }
  });
}

function loadFees() {
  requestManager.cancelAndNew('loadFees', fundingFeesRequest()).subscribe((response) => {
    partnerFees.value =
      response.filter((fee, index, self) => (fee.tier ? index === self.findIndex((t) => t.tier === fee.tier) : true)) ??
      null;
    loadDefaultFees();
  });
}

watch(
  () => requestManager.anyPending,
  () => {
    emit('update:formBusy', requestManager.anyPending);
    Object.values(updateFeeFormModels.value).forEach((model) => {
      setState(model, 'readonly', requestManager.anyPending);
    });
  },
  { immediate: true }
);

watch(() => props.paymentType, loadFees, { immediate: true });

defineExpose({
  setFeesToZero,
  setFeesToDefault,
});
</script>

<template>
  <div>
    <span v-if="!isStackedView">
      <VRow class="row mt-4">
        <VCol>
          <label> Rail </label>
        </VCol>
        <VCol> <label> Currency </label> </VCol>
        <VCol v-if="paymentType === FeePaymentType.PAYMENT">
          <label> ACO/SHA </label> <InfoTooltip :text="toolTipText" />
        </VCol>
        <VCol>
          <label> Fees Charged to you </label>
        </VCol>
        <VCol>
          <label> Fees Charged to client by you </label>
        </VCol>
      </VRow>
      <hr />
    </span>
    <template v-if="combinedFeesArr.length && requestManager.requestStates.loadDefaultFees !== 'pending'">
      <template v-if="isStackedView">
        <BoxGrid>
          <BoxGridBlock>
            <h3 v-if="clientName !== null">{{ clientName }} {{ typeLabel }} Fee Settings</h3>
            <h3 v-else>Global Client {{ typeLabel }} Fee Settings</h3>
            <AcoTiersModal v-if="bankingScheme === 'SWIFT'" v-slot="{ showModal }">
              <div class="text-sm-center">
                <VButton @click="showModal" class="mr-2 btn-stroked">Check ACO tiers</VButton>
              </div>
            </AcoTiersModal>

            <div class="mobile-info-row pt-3" v-for="fee in combinedFeesArr" :key="fee.id">
              <DataRow cols="7" label="Rail" class="fee-rail">
                <label class="pl-4"> {{ fee.rail }} </label>
              </DataRow>
              <DataRow cols="7" label="Currency" class="fee-currency">
                <label class="pl-4"> {{ fee.currency }}</label>
                <label class="pl-4" v-if="fee.currencyRail !== '' && fee.currencyRail !== null">
                  -{{ fee.currencyRail }}
                </label>
              </DataRow>
              <DataRow cols="7" label="Charge" class="fee-charge-type">
                <span class="pl-4" v-if="paymentType === FeePaymentType.PAYMENT">
                  <label> {{ fee.chargeType }} </label>
                </span>
              </DataRow>
              <DataRow cols="7" label="Fees Charged to you" class="fee-partner-charge">
                <label class="pl-4"> {{ fee.defaultFee }} </label>
              </DataRow>
              <DataRow cols="7" label="Fees Charged to client by you" class="fee-client-charge">
                <label v-if="updateFeeFormModels[fee.id]">
                  <ValidatedForm
                    class="fee-form px-3 text-center"
                    :fm="updateFeeFormModels[fee.id]"
                    :validation.sync="updateFeeFormModelValidations[fee.id]"
                    @form-event="onFeeFormEvent($event, fee)"
                  />
                </label>
              </DataRow>
            </div>
            <div class="text-center mt-4">
              <VButton @click="cancel" class="btn-secondary mr-2" :disabled="requestManager.anyPending"
                >Discard Changes</VButton
              >
              <VButton
                @click="submit"
                :disabled="requestManager.anyPending"
                :loading="requestManager.requestStates.submit === 'pending'"
                >Save</VButton
              >
            </div>
          </BoxGridBlock>
        </BoxGrid>
      </template>
      <template v-else>
        <VRow class="row" v-for="fee in combinedFeesArr" :key="fee.id">
          <VCol>
            <label> {{ fee.rail }} </label>
          </VCol>
          <VCol>
            <label> {{ fee.currency }}</label>
            <label v-if="fee.currencyRail !== '' && fee.currencyRail !== null"> -{{ fee.currencyRail }} </label>
          </VCol>
          <VCol v-if="paymentType === FeePaymentType.PAYMENT">
            <label> {{ fee.chargeType }} </label>
          </VCol>
          <VCol>
            <label> {{ fee.defaultFee }} </label>
          </VCol>
          <VCol>
            <label v-if="updateFeeFormModels[fee.id]">
              <ValidatedForm
                class="fee-form"
                :fm="updateFeeFormModels[fee.id]"
                :validation.sync="updateFeeFormModelValidations[fee.id]"
                @form-event="onFeeFormEvent($event, fee)"
              />
            </label>
          </VCol>
        </VRow>
        <div class="text-left mt-4">
          <VButton @click="cancel" class="btn-secondary mr-2" :disabled="requestManager.anyPending"
            >Discard Changes</VButton
          >
          <VButton
            @click="submit"
            :disabled="requestManager.anyPending"
            :loading="requestManager.requestStates.submit === 'pending'"
            >Save</VButton
          >
        </div>
      </template>
    </template>
    <LoadingIcon v-else-if="requestManager.requestStates.loadDefaultFees === 'pending'" class="svg" />
    <RouteProtectorModal
      :allowChange="areAllFormsPristine"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
    <BModal ref="checkBelowValues" :centered="true" ok-title="Yes" ok-variant="success" @hide="handleBellowValuesHide">
      <div class="text-center">
        <p>By setting client fees to below yours you will still incur fees</p>
        <p>but are choosing not to pass these fully onto the clients.</p>
        <p>Are you sure you want to proceed?</p>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
hr {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

::v-deep .svg {
  width: 2em;
  height: 4em;
}

.fee-form {
  position: relative;
  top: -0.45em;
}

::v-deep .form-control.above-value {
  @include themedBorderColor($color-profit, $color-dark-profit);
}
::v-deep .form-control.below-value {
  @include themedBorderColor($color-loss, $color-dark-loss);
}

.mobile-info-row {
  border-bottom: 1px solid;
  @include themedBorderColor($color-primary, $color-dark-primary);
}
</style>
