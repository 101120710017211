<script lang="ts" setup>
import VersionAlert from 'ah-common-lib/src/common/components/VersionAlert.vue';
import config from '@/config';
import MaintenanceWarningScreen from './app/components/common/MaintenanceWarningScreen.vue';
import { useSettingsStore } from './app/store/settingsModule';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { FeatureFlag, NotificationEventType, NotificationType } from 'ah-api-gateways';
import { useTabSync } from 'ah-common-lib/src/tabSync';
import { useFeatureFlag } from 'ah-common-stores';
import { computed, onMounted, ref, watch } from 'vue';
import { useTheme } from 'ah-theme';
/**
 * Main App component
 *
 * This component serves 2 functions:
 * - Displays the maintenance screen if either the app is in maintenance OR the app theme has failed to load
 * - Re-checks for theme until it loads properly
 */

const CHECK_THEME_LOAD_TIMEOUT = 3000;

const pageLoaded = ref(false);

let checkLoadThemeTimeout: number | undefined;

const authStore = useAuthStore();

const settingsStore = useSettingsStore();

const tabSync = useTabSync();

const theme = useTheme();

const sound = new Audio('https://files.alt21.com/cashregister.mp3');

const showDevTools = computed(() => !!config.showDevTools);

const themeLoaded = computed(() => theme.loaded || theme.error);

const isInMaintenance = computed(() => authStore.isInMaintenance);

const { isActive: areNotificationSoundsActive, loadFlag: loadNotificationSoungsFeatureFlag } = useFeatureFlag({
  featureFlag: FeatureFlag.FRONTEND_NOTIFICATION_SOUNDS_ENABLED,
  skipPreload: true,
});

onMounted(() => {
  window.setTimeout(() => {
    pageLoaded.value = true;
  });

  useNotificationsStore().notificationEvents.subscribe((event) => {
    if (
      event.eventType === NotificationEventType.NOTIFICATION_CREATED &&
      event.payload.type === NotificationType.TRADE_CONFIRMED &&
      authStore.isAgent &&
      areNotificationSoundsActive.value
    ) {
      tabSync.isLeaderPromise.finally(() => {
        sound.volume = 0.1;
        sound.play();
      });
    }
  });
});

function checkLoadTheme() {
  if (checkLoadThemeTimeout) {
    clearTimeout(checkLoadThemeTimeout);
  }
  if (!theme.loaded && !isInMaintenance.value) {
    theme.load().subscribe({
      error: () => {
        checkLoadThemeTimeout = window.setTimeout(() => checkLoadTheme(), CHECK_THEME_LOAD_TIMEOUT);
      },
    });
  }
}

watch(
  () => authStore.isLoggedIn && authStore.isAgent,
  (loggedInAgent) => loggedInAgent && loadNotificationSoungsFeatureFlag(true),
  { immediate: true }
);

watch(isInMaintenance, checkLoadTheme, { immediate: true });

watch(
  () => settingsStore.darkMode,
  () => {
    if (settingsStore.darkMode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  },
  { immediate: true }
);
</script>

<template>
  <div id="app-holder" v-if="themeLoaded">
    <Transition name="fade" :appear="pageLoaded">
      <MaintenanceWarningScreen v-if="isInMaintenance || $theme.error" class="maintenance-screen-holder" />
      <RouterView v-else />
    </Transition>
    <VersionAlert v-if="showDevTools" />
  </div>
</template>

<style lang="scss">
#app-holder {
  height: 100%;
  @include themedBackgroundColor($color-main-bg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.maintenance-screen-holder {
  z-index: 1101; // Above toaster z-index
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>
