var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._b({ref:"container",class:_vm.containerClasses,style:(_vm.containerStyleValue),on:{"click":_vm.clickHandle,"&touchstart":function($event){return _vm.dragStartOnProcess.apply(null, arguments)},"&mousedown":function($event){return _vm.dragStartOnProcess.apply(null, arguments)}}},'div',_vm.$attrs,false),[_c('div',{staticClass:"vue-slider-rail",style:(_vm.railStyle)},[_vm._l((_vm.processArray),function(item,index){return _vm._t("process",function(){return [_c('div',{key:`process-${index}`,staticClass:"vue-slider-process",style:(item.style)})]},null,item)}),(_vm.sliderMarks && _vm.control)?_c('div',{staticClass:"vue-slider-marks"},[_vm._l((_vm.control.markList),function(mark,index){return _vm._t("mark",function(){return [_c('AhSliderMark',{key:`mark-${index}`,style:({
            [_vm.isHorizontal ? 'height' : 'width']: '100%',
            [_vm.isHorizontal ? 'width' : 'height']: _vm.tailSize,
            [_vm.mainDirection]: `${mark.pos}%`,
          }),attrs:{"mark":mark,"hideLabel":_vm.hideLabel,"stepStyle":_vm.stepStyle,"stepActiveStyle":_vm.stepActiveStyle,"labelStyle":_vm.labelStyle,"labelActiveStyle":_vm.labelActiveStyle},on:{"press-label":(pos) => _vm.clickable && _vm.setValueByPos(pos)},scopedSlots:_vm._u([{key:"step",fn:function(){return [_vm._t("step",null,null,mark)]},proxy:true},{key:"label",fn:function(){return [_vm._t("label",null,null,mark)]},proxy:true}],null,true)})]},null,mark)})],2):_vm._e(),_vm._l((_vm.dots),function(dot,index){return _c('AhSliderDot',_vm._b({key:`dot-${index}`,ref:`dot-${index}`,refInFor:true,style:([
        _vm.dotBaseStyle,
        {
          [_vm.mainDirection]: `${dot.pos}%`,
          transition: `${_vm.mainDirection} ${_vm.animateTime}s`,
        },
      ]),attrs:{"value":dot.value,"disabled":dot.disabled,"focus":dot.focus,"dot-style":[dot.style, dot.disabled ? dot.disabledStyle : undefined, dot.focus ? dot.focusStyle : undefined],"tooltip":dot.tooltip || _vm.tooltip,"tooltip-style":[
        _vm.tooltipStyle,
        dot.tooltipStyle,
        dot.disabled ? dot.tooltipDisabledStyle : undefined,
        dot.focus ? dot.tooltipFocusStyle : undefined,
      ],"tooltip-formatter":Array.isArray(_vm.sliderTooltipFormatter) ? _vm.sliderTooltipFormatter[index] : _vm.sliderTooltipFormatter,"tooltip-placement":_vm.tooltipDirections[index],"role":"slider","aria-valuenow":dot.value,"aria-valuemin":_vm.min,"aria-valuemax":_vm.max,"aria-orientation":_vm.isHorizontal ? 'horizontal' : 'vertical',"tabindex":"0"},on:{"drag-start":() => _vm.dragStart(index),"focus":() => _vm.focus(dot, index),"blur":() => _vm.blur()},scopedSlots:_vm._u([{key:"dot",fn:function(){return [_vm._t("dot",null,null,dot)]},proxy:true},{key:"tooltip",fn:function(){return [_vm._t("tooltip",null,null,dot)]},proxy:true}],null,true)},'AhSliderDot',_vm.dotAttrs,false))})],2),_vm._t("default",null,{"value":_vm.getValue()})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }