<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import {
  partnerTradeOptionsReportTableFields,
  tradeOptionsReportTableFields,
} from 'ah-trades/src/models/tradeTableFields';
import TradeListing from 'ah-trades/src/components/lists/TradeListing.vue';
import TradeOptionsRowDetails from 'ah-trades/src/components/lists/TradeOptionsRowDetails.vue';
import { ExportListType, PaginatedParams, openTradeStatuses } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import ListDownloadButton from 'ah-common-lib/src/common/components/listing/ListDownloadButton.vue';

const state = reactive<{
  sortAndPageParams: Partial<PaginatedParams>;
  dataDownloadState: string;
}>({
  sortAndPageParams: { sort: 'createdAt', sortDirection: 'DESC' },
  dataDownloadState: 'idle',
});

const tradeListing = ref<InstanceType<typeof TradeListing> | null>(null);

const authState = useAuthStore();

const isPartnerUser = computed(() => !authState.isClientUser);

const partnerId = computed(() => {
  if (isPartnerUser.value) {
    return authState.loggedInIdentity?.partner.id;
  }

  return undefined;
});

const config = computed(() => {
  return {
    tableFields: isPartnerUser.value ? partnerTradeOptionsReportTableFields : tradeOptionsReportTableFields,
  };
});

function downloadList(format: ExportListType) {
  tradeListing.value!.downloadData(format);
}

const possibleStatuses = computed(() => openTradeStatuses);
</script>

<template>
  <div class="padded-space" id="open-positions-view">
    <div class="inner">
      <h2>Open Option Positions</h2>
      <BoxGrid alignH="start" alignV="stretch">
        <BoxGridBlock cols="12">
          <TradeListing
            ref="tradeListing"
            fixed
            withRowDetails
            :config="config"
            :filter="{ hedgingProduct: 'VANILLA_OPTIONS', partnerId: partnerId, status: possibleStatuses }"
            :sortAndPageParams.sync="state.sortAndPageParams"
            :dataDownloadState.sync="state.dataDownloadState"
            :showExport="false"
            paginationQueryParam="openOptionsPositionsSort"
            with-simple-scroll
            nowrap
          >
            <template #row-details="slotScope">
              <slot name="trade-details" v-bind="slotScope">
                <div class="trade-details">
                  <TradeOptionsRowDetails :trade="slotScope.item" />
                </div>
              </slot>
            </template>
          </TradeListing>
        </BoxGridBlock>
        <BoxGridItem class="bg-transparent" cols="12">
          <ListDownloadButton
            :loading="state.dataDownloadState === 'pending'"
            class="download-button"
            @download="downloadList"
          />
        </BoxGridItem>
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inner {
  position: relative;
}
</style>
