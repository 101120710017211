<script lang="ts" setup>
import { makeTimeLineChartDataset, makeTimeLineChartOptions } from '../helpers/chartConfig';
import VChart from './VChart.vue';
import coinify from 'coinify';
import { addDays, differenceInDays, format, startOfDay, subDays, subMonths } from 'date-fns';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { dateField } from 'ah-common-lib/src/form/models';
import { Point, ScaleOptions } from 'chart.js';
import { RateGranularity } from 'ah-api-gateways';
import { fillDates } from '../helpers/fillDates';
import { dataColorPrimary, linesColorPrimary } from '../helpers/graphColors';
import { minDate, maxDate } from 'ah-common-lib/src/form/validators';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useAhReportsState } from '..';
import { FormModel } from 'ah-common-lib/src/form/interfaces';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'getMidMarketRates') {
    onChanges();
  }
}

const props = defineProps<{
  currencyPair: string;
  alertRate: number;
}>();

const state = reactive({
  options: makeTimeLineChartOptions(
    { color: linesColorPrimary },
    {
      plugins: {
        annotation: {
          annotations: {
            alertRateLine: {
              type: 'line',
              yMin: props.alertRate,
              yMax: props.alertRate,
              borderWidth: 2,
              borderColor: '#59BFF9',
              label: {
                enabled: true,
                yAdjust: -10,
                content: 'Alert rate',
                position: 'start',
                backgroundColor: 'transparent',
                color: '#59BFF9',
              },
            },
          },
        },
        tooltip: {
          callbacks: {
            title(items) {
              if (items.length > 0 && (items[0].raw as Point).x) {
                return format(new Date((items[0].raw as Point).x), 'dd MMM yy');
              }
              return '';
            },
          },
          bodyAlign: 'center',
        },
      },
      scales: {
        y: {
          beginAtZero: false,
          grid: {
            drawBorder: false,
            display: true,
            borderDash: [8, 8],
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
        },
      },
    }
  ),
  data: makeTimeLineChartDataset({ label: '', color: dataColorPrimary }),
});

const fromDatePicker: { form: FormModel } = reactive({
  form: makeFormModel({
    name: 'date',
    fieldType: 'form',
    fields: [
      dateField(
        'fromDate',
        '',
        { defaultValue: subMonths(new Date(), 1).toString() },
        {
          maxDate: maxDate(() => to.value),
        }
      ),
    ],
  }),
});

const toDatePicker: { form: FormModel } = reactive({
  form: makeFormModel({
    name: 'date',
    fieldType: 'form',
    fields: [
      dateField(
        'toDate',
        '',
        { defaultValue: new Date().toString() },
        {
          minDate: minDate(() => from.value),
        }
      ),
    ],
  }),
});

watch(
  () => [props.alertRate, rateRequest],
  () => {
    onChanges();
  },
  {
    immediate: true,
  }
);

const reportsState = useAhReportsState();

function onChanges() {
  requestManager.manager
    .sameOrCancelAndNew('getMidMarketRates', reportsState.services.rates.listRates(rateRequest.value))
    .subscribe((response) => {
      state.data!.datasets![0].data! = fillDates(
        response.rates.map((i) => ({
          x: startOfDay(new Date(i.ts)),
          y: i.rate,
        })),
        startOfDay(from.value),
        startOfDay(to.value)
      );

      const timeRange = differenceInDays(from.value, to.value);
      let unit: 'day' | 'month' | 'week' = 'day';
      if (timeRange > 10) {
        unit = timeRange > 60 ? 'month' : 'week';
      }
      const xScale: ScaleOptions<'time'> = state.options!.scales?.x as any;
      if (xScale && xScale.time) {
        xScale.time.unit = unit;
      }

      const annotations = state.options!.plugins!.annotation!.annotations as any;
      annotations.alertRateLine.yMin = props.alertRate;
      annotations.alertRateLine.yMax = props.alertRate;

      // Force update by replacing prop reference entirely
      state.options = { ...state.options };
      if (state.data) {
        state.data = { ...state.data };
      }
    });
}

const rateRequest = computed(() => ({
  currencyPair: props.currencyPair,
  startTime: subDays(from.value, 1).toISOString(),
  endTime: addDays(to.value, 1).toISOString(),
  granularity: RateGranularity.DAILY,
}));

const from = computed(() => new Date(fromDatePicker.form.fromDate));

const to = computed(() => new Date(toDatePicker.form.toDate));

const curr1 = computed(() => {
  let symbol = coinify.get(props.currencyPair.substring(0, 3)).symbol;
  symbol.length >= 2 ? (symbol = '') : '';
  return symbol + ' ' + props.currencyPair.substring(0, 3);
});

const curr2 = computed(() => {
  let symbol = coinify.get(props.currencyPair.substring(3)).symbol;
  symbol.length >= 2 ? (symbol = '') : '';
  return symbol + ' ' + props.currencyPair.substring(3);
});
</script>

<template>
  <div class="card-block chart-box mt-4 mb-4 p-4">
    <VRow class="mb-3" align-h="between" align-v="center">
      <VCol>
        <h3 class="mb-0">{{ curr1 }} <IconArrowRight class="mx-2 mb-1" /> {{ curr2 }}</h3>
      </VCol>
      <VCol>
        <div class="date-picker-form text-right mr-5">
          {{ formatDate(from, 'MMM yy') }} <ValidatedForm :fm="fromDatePicker.form" />
          <span class="mx-3"> – </span>
          {{ formatDate(to, 'MMM yy') }} <ValidatedForm :fm="toDatePicker.form" />
        </div>
      </VCol>
    </VRow>
    <span v-if="requestManager.manager.requestStates.getMidMarketRates === 'pending'">
      <LoadingIcon class="svg" />
    </span>
    <span v-else>
      <VChart class="chart" type="line" :options="state.options" :data="state.data" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .field-group-wrapper {
    max-width: 50%;
    margin: 0;
  }
  input {
    display: none !important;
  }
  .date-picker-form > * {
    display: inline-block;
  }
}
</style>
