<script setup lang="ts">
import ClientOnBehalfOfActions from '@/app/components/clients/clientInfo/ClientOnBehalfOfActions.vue';
import { Client, Permission } from 'ah-api-gateways';
import { ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

const requestManager = useRequestManager().manager;

const services = getServices();

const props = defineProps({ clientId: { type: String, required: true } });

const client = ref<Client>();

const clientPermissions = ref<Permission[] | null>(null);

function updateClient() {
  requestManager
    .currentOrNew('getClient', services.client.getClient(props.clientId))
    .subscribe((response) => (client.value = response));

  requestManager
    .currentOrNew('getClientPermissions', services.authz.getClientsPermissions(props.clientId))
    .subscribe((response) => (clientPermissions.value = response));
}

watch(() => props.clientId, updateClient, { immediate: true });
</script>

<template>
  <div class="menu-wrapper p-3" id="obo-menu-view">
    <BoxGrid align-h="start" align-v="stretch">
      <BoxGridBlock
        lg="6"
        md="6"
        sm="12"
        useLoadingOverlay
        overlayType="simple"
        :loadingOverlayProps="{ state: requestManager.currentStates, showRetry: true }"
      >
        <template v-if="client && clientPermissions">
          <div class="mb-2">{{ client.name }}</div>
          <ClientOnBehalfOfActions :client="client" :clientPermissions="clientPermissions" v-on="$listeners" />
        </template>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.menu-wrapper {
  position: relative;
  min-height: 75vh;

  ::v-deep .box-grid-item {
    @include tablet {
      min-height: 38rem;
    }
  }
}
</style>
