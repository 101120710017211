<script setup lang="ts">
import SideNavSettingsLink from './SideNavSettingsLink.vue';
import SideNavActivityLink from './SideNavActivityLink.vue';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

const settingsOpen = ref<boolean>(false);

const route = useRoute();

watch(() => route.fullPath, onRouteChange, { immediate: true });

function onRouteChange() {
  settingsOpen.value = route.fullPath.includes('/admin/settings');
}
</script>

<template>
  <ResponsiveNav>
    <ul class="main">
      <RouterLink tag="li" class="menu-link" to="/admin" exact>
        <IconDashboard />
        <span class="menu-link-text"> Dashboard </span>
      </RouterLink>
      <SideNavActivityLink />
      <RouterLink tag="li" class="menu-link" to="/admin/payments">
        <IconWallet /> <span class="menu-link-text"> Payments</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/admin/wallets">
        <IconWallet /> <span class="menu-link-text"> Wallets </span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/admin/beneficiaries">
        <IconAgenda /> <span class="menu-link-text"> Beneficiaries </span>
      </RouterLink>
      <!-- <RouterLink tag="li" class="menu-link" to="/admin/training">
        <IconTraining />
        <span class="menu-link-text"> Training </span>
      </RouterLink> -->
    </ul>
    <template #bottom>
      <ul class="bottom">
        <SideNavSettingsLink basePath="/admin/settings" />
      </ul>
    </template>
  </ResponsiveNav>
</template>
