import { SpotLiveRate } from '../models/rates/spotLiveRate';
import { HttpBasedService, HttpService, RequestManager } from 'ah-requests';
import { HistoricalRateList, RatesQuery } from '../models/rates/historicalRates';

export class RateService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'rateService' } },
      },
    });
  }

  private requestManager = new RequestManager();

  public get requestStates() {
    return this.requestManager.requestStates;
  }

  public listRates(query: RatesQuery) {
    return this.get<HistoricalRateList>(`${this.baseUrl}rates`, {
      axiosConfig: {
        params: query,
      },
    });
  }

  public spotLiveRate(currencyPair: string) {
    return this.get<SpotLiveRate>(`${this.baseUrl}rates/spot/live?currencyPair=${currencyPair}`);
  }
}
