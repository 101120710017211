<script lang="ts" setup>
import { IndividualFileCategories } from 'ah-api-gateways';
import AsyncImage from '@/app/components/common/AsyncImage.vue';
import UploadButton from 'ah-common-lib/src/common/components/upload/UploadButton.vue';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useToast } from 'ah-common-lib/src/toast';
import { getServices } from '@/app/services';

const requestManager = useRequestManager({
  exposeToParent: false,
}).manager;

const individualSettingsStore = useIndividualSettingsStore();

const authStore = useAuthStore();

const toast = useToast();

const services = getServices();

const imageSrc = ref('');

const photo = computed(() => individualSettingsStore.getIndividualDocument(IndividualFileCategories.PROFILE_PICTURE));

function uploadPhoto(files: FileList) {
  const file = files[0];

  if (file) {
    if (file.size > 10485760) {
      toast.error(`File size exceeds maximum allowed of 10 MB`);
    } else if (!file.type.startsWith('image')) {
      toast.error(`File must be an image (.BMP, .JPEG, .GIF, .TIF, .PNG)`);
    } else {
      requestManager
        .currentOrNew(
          'uploadPhoto',
          individualSettingsStore.uploadIndividualDocument(file, IndividualFileCategories.PROFILE_PICTURE)
        )
        .subscribe();
    }
  }
}

watch(
  () => photo.value?.id,
  () => {
    if (photo.value) {
      services.individual.getDocumentAsBlob(authStore.loggedInIdentity!.id, photo.value).subscribe((blob) => {
        if (imageSrc.value) {
          URL.revokeObjectURL(imageSrc.value);
        }
        imageSrc.value = URL.createObjectURL(blob!);
      });
    }
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  if (imageSrc.value) {
    URL.revokeObjectURL(imageSrc.value);
  }
});

individualSettingsStore.loadIndividualDocuments(true);
</script>

<template>
  <div class="profile-photo-editor">
    <div class="profile-photo-holder">
      <div class="profile-photo">
        <AsyncImage class="profile-photo-inner" v-if="photo" :src="imageSrc" alt="Profile Picture" />
      </div>
    </div>
    <UploadButton
      @files-selected="uploadPhoto"
      :loading="requestManager.anyPending"
      buttonClass="btn-stroked"
      accept="image/*"
    >
      {{ photo ? 'Re-upload' : 'Upload' }} <IconUpload />
    </UploadButton>
    <p class="text-secondary small-text mt-3">
      Max size: 10 MB<br />
      Supported formats:<br />
      .BMP, .JPEG, .GIF, .TIF, .PNG
    </p>
  </div>
</template>

<style lang="scss" scoped>
.profile-photo-editor {
  text-align: center;

  .profile-photo-holder {
    width: 70%;
    max-width: 200px;
    position: relative;
    margin: auto;
    margin-bottom: 1em;
    border-radius: 100%;
    overflow: hidden;
    @include themedBackgroundColor($color-dark-main-bg, $color-main-bg);

    .profile-photo {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      .profile-photo-inner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
}

.small-text {
  font-size: 0.8em;
}
</style>
