import { HttpBasedService, HttpOptions, HttpRequestOptions, HttpService } from 'ah-requests';
import {
  ClientType,
  UserCreationRequest,
  ClientRegistrationRequest,
  VersionedObject,
  UserRegistrationData,
  SessionData,
  OnboardingIndividualInfo,
  ClientReferralData,
  ReferralLinkRequest,
} from '../models';
import { CMSErrorCodes } from '../httpErrorCodes';

export class RegistrationService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'registrationService' } },
      },
    });
  }

  public getRegistrationUser(userId: string, httpOptions?: Partial<HttpRequestOptions<UserRegistrationData>>) {
    return this.get<UserRegistrationData>(`${this.baseUrl}registration/form/${userId}`, httpOptions);
  }

  public registrationLanding(payload: ClientReferralData, httpOptions?: Partial<HttpRequestOptions<VersionedObject>>) {
    return this.post<VersionedObject>(`${this.baseUrl}referral`, payload, {
      ...httpOptions,
      options: {
        ...httpOptions?.options,
        skipAuth: true,
      },
    });
  }

  public getClientReferralLink(clientId: string, httpOptions?: Partial<HttpRequestOptions<ReferralLinkRequest>>) {
    return this.get<ReferralLinkRequest>(`${this.baseUrl}referral/${clientId}`, {
      options: {
        ...httpOptions?.options,
      },
    });
  }

  public registerUser(
    clientType: ClientType,
    payload: UserCreationRequest,
    httpOptions?: Partial<HttpRequestOptions<SessionData>>
  ) {
    const registrationType = clientType === ClientType.COMPANY ? 'b2b' : 'b2c';
    return this.post<SessionData>(`${this.baseUrl}registration/${registrationType}`, payload, {
      ...httpOptions,
      options: {
        ...httpOptions?.options,
        skipAuth: true,
      },
    });
  }

  public usernameValidation(payload: { username: string }, options?: Partial<HttpOptions<void>>) {
    return this.post<void>(`${this.baseUrl}registration/username-validation`, payload, {
      options,
    });
  }

  public registerClient(
    payload: ClientRegistrationRequest,
    httpOptions?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.post<VersionedObject>(`${this.baseUrl}register/client`, payload, httpOptions);
  }

  public updateClient(
    clientId: string,
    payload: ClientRegistrationRequest,
    httpOptions?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.put<VersionedObject>(`${this.baseUrl}register/client/${clientId}`, payload, httpOptions);
  }

  public proposeUser(
    clientId: string,
    payload: Partial<OnboardingIndividualInfo>,
    httpOptions?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.post<VersionedObject>(`${this.baseUrl}register/client/${clientId}/individual`, payload, httpOptions);
  }

  public editProposedUser(
    individualId: string,
    clientId: string,
    payload: Partial<OnboardingIndividualInfo>,
    httpOptions?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.put<VersionedObject>(
      `${this.baseUrl}register/client/${clientId}/individual/${individualId}`,
      payload,
      httpOptions
    );
  }

  public deleteProposedUser(
    individualId: string,
    clientId: string,
    httpOptions?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.delete<VersionedObject>(
      `${this.baseUrl}register/client/${clientId}/individual/${individualId}`,
      httpOptions
    );
  }

  public resendRegistrationConfirmation(email: string, options?: HttpOptions<void>) {
    return this.put<void>(
      `${this.baseUrl}registration/client/verify`,
      { email },
      {
        options: {
          ...options,
          errors: {
            silent: false,
            errorMessages: [
              {
                message: `This email has already been verified`,
                name: 'client-verification-conflict',
                code: (response) => response?.data?.code === CMSErrorCodes.EMAIL_ALREADY_VERIFIED,
                toastType: 'info',
              },
            ],
            ...options?.errors,
          },
        },
      }
    );
  }
}
