<script lang="ts" setup>
import SingleNotificationSettingEditForm from '@/app/components/settings/notifications/SingleNotificationSettingEditForm.vue';
import {
  RateAlertSetting,
  RateAlertInequalityType,
  ClientType,
  NotificationSettingConfig,
  NotificationChannelType,
} from 'ah-api-gateways';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { generateTempUUID, isTempUUID } from 'ah-common-lib/src/helpers/uuid';
import { SECOND } from 'ah-common-lib/src/constants/time';
import NotificationSettingsTable from '@/app/components/settings/notifications/NotificationSettingsTable.vue';
import RateAlertSettingsEditModal from '@/app/components/settings/notifications/RateAlertSettingsEditModal.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationSettingsStore } from '@/app/store/notificationSettingsModule';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref } from 'vue';
import { getServices } from '@/app/services';

const NOTIFICATION_SAVE_DEBOUNCE_TIME = SECOND * 1.5;

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'loadRateAlertSettings' || k === 'loadNotificationsConfig' || k === 'getMidMarketLiveRate') {
      loadConfigs();
    }
  },
}).manager;

const notificationSettingsStore = useNotificationSettingsStore();

const authStore = useAuthStore();

const services = getServices();

const tempRateAlertSettings = ref<RateAlertSetting[]>([]);

const spotLiveRateValue = ref(0);

const tempRateAlert = computed<RateAlertSetting>(() => ({
  channels: [],
  to: [],
  id: generateTempUUID(),
  rate: spotLiveRateValue.value,
  currencyPair: 'GBPEUR',
  inequality: RateAlertInequalityType.HIGHER_THAN,
}));

const rateAlertConfig = computed<NotificationSettingConfig>(() => {
  const entityType = authStore.isClientUser ? 'CLIENT' : 'PARTNER';
  return {
    type: 'RATE_ALERT',
    entityType: entityType,
    editable: true,
    multiple: true,
    timed: false,
    defaultTimeUnit: null,
    defaultTimeValue: null,
    sectionIndex: -1,
    sectionName: '',
    index: 0,
    name: '',
    description: '',
    multipleDescription: null,
    channels: [
      {
        channelType: NotificationChannelType.EMAIL,
        configurable: false,
        editable: true,
        defaultEnabledValue: true,
      },
      {
        channelType: NotificationChannelType.IN_APP,
        configurable: false,
        editable: true,
        defaultEnabledValue: true,
      },
      {
        channelType: NotificationChannelType.SMS,
        configurable: true,
        editable: false,
        defaultEnabledValue: true,
      },
    ],
  };
});

const client = computed(() => authStore.loggedInIdentity?.client);

const isIndividual = computed(() => client.value?.type === ClientType.INDIVIDUAL);

const rateAlerts = computed(() => notificationSettingsStore.rateAlertSettings || []);

const loadingRateAlerts = computed(
  () => rateAlerts.value.length === 0 && requestManager.requestStates.loadRateSettings === 'pending'
);

function loadConfigs() {
  requestManager.currentOrNewPromise('loadNotificationsConfig', () =>
    notificationSettingsStore.loadNotificationConfig(true)
  );
  requestManager.currentOrNewPromise('loadRateSettings', () => notificationSettingsStore.loadRateAlertSettings(true));
  loadCurrentMidMarketRate('GBPEUR');
}

function onRateAlertUpdate(rateAlert: RateAlertSetting) {
  // Using timer->take as a debounce method, as RequestManager will cancel any previous request,
  // and the promise will never be called
  if (isTempUUID(rateAlert.id)) {
    tempRateAlertSettings.value.push(rateAlert);
  }
  requestManager
    .cancelAndNew(`saveRateAlert-${rateAlert.id}`, timer(NOTIFICATION_SAVE_DEBOUNCE_TIME).pipe(take(1)))
    .subscribe(() => {
      notificationSettingsStore.saveRateAlertSetting(rateAlert).then(() => {
        if (isTempUUID(rateAlert.id)) {
          const index = tempRateAlertSettings.value.findIndex((i) => i.id === rateAlert.id);
          if (index > -1) {
            tempRateAlertSettings.value.splice(index, 1);
          }
        }
      });
    });
}

function onRateAlertDelete(rateAlert: RateAlertSetting) {
  requestManager.cancel(`saveRateAlert-${rateAlert.id}`);
  notificationSettingsStore.deleteRateAlertSetting(rateAlert);
}

function loadCurrentMidMarketRate(currencyPair: string) {
  requestManager
    .sameOrCancelAndNew('getMidMarketLiveRate', services.rates.spotLiveRate(currencyPair))
    .subscribe((response) => {
      spotLiveRateValue.value = response.spotRate;
    });
}

loadConfigs();
</script>

<template>
  <div class="rate-alerts-holder">
    <div class="rate-alerts-title">
      <h2>Rate alerts</h2>
      <p class="text-secondary mt-n4">Setup alerts for when currency pairs of your interest reach a certain rate.</p>
    </div>
    <RateAlertSettingsEditModal :rateAlert="tempRateAlert" v-slot="{ show }" @update:rateAlert="onRateAlertUpdate">
      <VButton @click="show" class="rate-alerts-add">Add rate alert</VButton>
    </RateAlertSettingsEditModal>
    <BoxGrid class="rate-alerts-table">
      <BoxGridBlock>
        <NotificationSettingsTable
          rateAlert
          :loading="loadingRateAlerts"
          v-slot="{ isStackedView }"
          :hideTargets="isIndividual"
        >
          <div class="notifications-block" v-if="rateAlerts.length > 0">
            <SingleNotificationSettingEditForm
              v-for="rateAlert in rateAlerts"
              :config="rateAlertConfig"
              :notification="rateAlert"
              :hideTargets="isIndividual"
              :showTitles="isStackedView"
              :key="rateAlert.id"
              deletable
              class="single-notification-editor sub-item"
              @update:notification="onRateAlertUpdate"
              @delete-notification="onRateAlertDelete"
            />
            <SingleNotificationSettingEditForm
              v-for="rateAlert in tempRateAlertSettings"
              :key="rateAlert.id"
              :config="rateAlertConfig"
              :notification="rateAlert"
              :hideTargets="isIndividual"
              :showTitles="isStackedView"
              readonly
              deletable
              class="single-notification-editor sub-item"
              @update:notification="onRateAlertUpdate"
              @delete-notification="onRateAlertDelete"
            />
          </div>
          <span v-else class="text-secondary">No rate alerts added yet</span>
        </NotificationSettingsTable>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.rate-alerts-title {
  float: left;
}

.rate-alerts-add {
  float: right;
}

.rate-alerts-table {
  clear: both;
}
</style>
