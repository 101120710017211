/**
 * Convert an object/array to a JSON representation in a consistent way, so it can be used as a key on requests
 */
export function deterministicJSON(obj: any) {
  return JSON.stringify(obj, (_: any, v: any) =>
    typeof v !== 'object' || v === null || Array.isArray(v)
      ? v
      : // eslint-disable-next-line no-nested-ternary
        Object.fromEntries(Object.entries(v).sort(([ka], [kb]) => (ka < kb ? -1 : ka > kb ? 1 : 0)))
  );
}

export function tryParse(value?: string | null) {
  try {
    return JSON.parse(value || '');
  } catch (e) {
    return null;
  }
}
