var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TransitionGroup',{staticClass:"solutions",attrs:{"name":"solutions-list","tag":"div"}},_vm._l((_setup.state.solutions),function(solution,index){return _c('BoxGridItem',{key:solution.id,staticClass:"mb-5",attrs:{"md":"6","sm":"12"}},[_c('BoxGridBlock',{staticClass:"solutions-list-item",attrs:{"loadingOverlayProps":{
        state: _setup.requestManager.manager.requestStates[`createVanillaOptions${solution.id}`],
        loadingText: _setup.loadingText(solution),
        class: 'loading-solution',
        opacity: '0',
        hideOnLoading: true,
        showRetry: true,
      },"useLoadingOverlay":"","overlayType":"simple","cols":"12","align-self":"stretch"},on:{"retry":function($event){return _setup.retryReloadPrices(solution)}}},[(_setup.isValidSolution(solution))?_c('div',[_c(_setup.OptionSolutionDetailsForm,{key:solution.id,attrs:{"solution":solution,"isFirst":_setup.isFirst(index),"isLast":_setup.isLast(index),"index":index,"apiError":_setup.apiErrors(solution.id)},on:{"sort-solutions":function($event){return _setup.sortSolution($event, solution)},"remove-solution":function($event){return _setup.deleteSolution(solution)},"update:price":function($event){return _setup.reloadPrices($event, solution.id)},"update:solution":function($event){return _setup.updateSolution($event, solution.id)},"update:validation":function($event){return _setup.updateValidation($event, solution.id)}}})],1):_vm._e()]),(_setup.isValidSolution(solution))?_c('VButton',{attrs:{"loading":_setup.requestManager.manager.requestStates[`createVanillaOptions${solution.id}`] === 'pending',"disabled":_setup.requestManager.manager.requestStates[`createVanillaOptions${solution.id}`] === 'error' ||
        _setup.apiErrors(solution.id) ||
        _setup.isInvalid(solution.id)},on:{"click":function($event){return _setup.selectedSolution(solution)}}},[_vm._v(" Select Trade ")]):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }