export interface TableFieldDefinition<T = any> {
  /**
   * Table field definitions used in DynamicTable
   *
   * Will match certain PrimeVue DataTable properties - this is an incomplete list
   */
  key: string;
  header: string;
  class?: string | string[];
  info?: boolean;
  labelInfo?: string;
  colStyle?: { [key: string]: string };
  sortable?: boolean;
  sortKey?: string;
  sortDirection?: string;
  hidden?: boolean;
  tdClass?:
    | string
    | (string | { [key: string]: string })[]
    | ((value: any, key: string, item: T) => (string | { [key: string]: string })[]);
  /**
   * Class to apply to the header cells
   *
   * CAVEAT: PrimeVue applies classes to the header CELL, but wraps all content in a div with class `p-column-header-content`
   * Because of this, functional classes like `text-right` will not work as intended, unless handled directly
   */
  thClass?: string | string[];
  frozen?: boolean;
  alignFrozen?: 'left' | 'right';
  metadata?: { [key: string]: any };
}

export interface TableFieldConfiguration extends TableFieldDefinition {
  break?: boolean;
}

export interface CustomTableConfig {
  tableBlocks: TableConfigBlock[];
  cols?: number;
  editable: boolean;
}

export interface TableEditConfiguration {
  columnConfig: CustomTableConfig[];
  defaultColumns: string[];
  storeKey: string;
}

export interface TableConfiguration {
  editConfig?: TableEditConfiguration;
  tableFields: TableFieldDefinition[];
}

interface TableConfigBlock {
  key: string;
  header: string;
  tableColumns: string[];
  cols?: number;
  selectAll?: boolean;
}

export enum EditTableMode {
  ON = 'ON',
  OFF = 'OFF',
  ONLY_EDITED = 'ONLY_EDITED',
}
