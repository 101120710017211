<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import WithdrawFromWallet from './WithdrawFromWallet.vue';
import { Wallet, Beneficiary, Transaction } from 'ah-api-gateways';
import { ref, computed } from 'vue';

const withdrawFromWalletModal = ref<InstanceType<typeof BModal> | null>(null);

const props = withDefaults(
  defineProps<{
    /**
     * Possible target wallet
     *
     * If set, currency selection is disabled, and is set to the wallet's currency
     */
    wallet: Wallet;
    /**
     * Possible amend an existing scheduled payment
     *
     * Ignored if selfBeneficiary is truthy
     */
    transaction?: Transaction | null;
    /**
     * wheather to display the button on the selection dropwown or not
     *
     * Defaults to false, any value other than false is considered truthy
     */
    hideButton?: boolean | string;
    /**
     * Whether to use self beneficiary
     *
     * If set, targetBeneficiary will be ignored and self beneficiary will be considered the target
     *
     * Defaults to false, any value other than false is considered truthy
     */
    selfBeneficiary?: boolean | string;
    /**
     * Possible target beneficiary
     *
     * Ignored if selfBeneficiary is truthy
     *
     * If set, and wallet is not set, currency selection is constrained to the currencies for which the beneficiary has accounts
     */
    targetBeneficiary?: Beneficiary;
  }>(),
  {
    transaction: null,
    hideButton: false,
    selfBeneficiary: false,
  }
);

const emit = defineEmits<{
  (e: 'withdraw-succeded', value: void): void;
}>();

function show() {
  withdrawFromWalletModal.value?.show();
}

function hide() {
  withdrawFromWalletModal.value?.hide();
}

function onWithdrawSucceeded() {
  emit('withdraw-succeded');
  hide();
}

const title = computed(() => {
  if (props.selfBeneficiary !== false) {
    return `Withdraw money from ${props.wallet.currency} wallet`;
  }
  return `${props.transaction ? 'Amend' : 'Make'} Payment`;
});
</script>

<template>
  <span>
    <BModal :title="title" modal-class="side-modal withdraw-modal" ref="withdrawFromWalletModal" hide-footer>
      <WithdrawFromWallet
        :targetWallet="wallet"
        :targetBeneficiary="targetBeneficiary"
        :selfBeneficiary="selfBeneficiary"
        :transaction="transaction"
        @withdraw-succeded="onWithdrawSucceeded"
        @cancel="hide()"
        v-bind="$attrs"
      />
    </BModal>
    <slot v-bind="{ show }" v-if="hideButton === false">
      <VButton @click="show">Withdraw From Wallet</VButton>
    </slot>
  </span>
</template>

<style lang="scss">
.withdraw-modal .modal-dialog {
  width: 38em;
}
</style>
