import { helpers } from '@vuelidate/validators';

export const passwordLength = helpers.withParams({ type: 'password' }, (value: string) => {
  return value.length >= 8 && value.length <= 64;
});

export const passwordLower = helpers.withParams({ type: 'password' }, (value: string) => {
  return /[a-z]/.test(value);
});

export const passwordUpper = helpers.withParams({ type: 'password' }, (value: string) => {
  return /[A-Z]/.test(value);
});

export const passwordSpecialChar = helpers.withParams({ type: 'password' }, (value: string) => {
  return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
});

export const passwordNumber = helpers.withParams({ type: 'password' }, (value: string) => {
  return /[0-9]/.test(value);
});
