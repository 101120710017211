/**
 * Convertes empty strings to nulls.
 * Some endpoints don't remove the DB property if we send an empty string, only with null. Because of this, the function converts empty strings to nulls.
 *
 * @param model
 * @returns
 */
export function nullifyEmptyStringProperties(model: any): any {
  Object.keys(model).forEach((key) => {
    if (typeof model[key] === 'string' && (model[key] as string).trim() === '') {
      (model[key] as any) = null;
    }
  });

  return model;
}
