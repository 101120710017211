<script setup lang="ts">
import BankAccountsTable from 'ah-beneficiaries/src/components/bankAccounts/BankAccountsTable.vue';
import BankAccountEditModal from 'ah-beneficiaries/src/components/bankAccounts/BankAccountEditModal.vue';
import BankAccountInfo from 'ah-beneficiaries/src/components/bankAccounts/BankAccountInfo.vue';
import { AuthorityType, Beneficiary } from 'ah-api-gateways';
import {
  bankAccountsTableFields,
  narrowBankAccountsTableFields,
} from 'ah-beneficiaries/src/models/bankAccountsTableFields';
import { AnimatedListingWithSubViewState } from '@/app/components/common/animation/AnimatedListingWithSubView.vue';
import { mergeMap } from 'rxjs/operators';
import { waitForEntityDeletion } from 'ah-requests';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, ref } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useMediaQuery } from 'ah-common-lib/src/mediaQuery';

const mediaQuery = useMediaQuery();

const requestManager = useRequestManager().manager;

const services = getServices();

const selectedBeneficiary = ref<Beneficiary>();

// Auto-set by AnimatedListingWithSubView[animatedState.sync] in template
const animatedState = ref<AnimatedListingWithSubViewState>();

const canManageBeneficiaries = computed(() => useAuthStore().hasAuthorities(AuthorityType.MANAGE_BENEFICIARIES));

const individualSettingsStore = useIndividualSettingsStore();

const beneficiaries = computed(() => individualSettingsStore.selfBeneficiaries);

const tableFields = computed(() => {
  return mediaQuery.is('mdDown') && !animatedState.value?.wideList
    ? narrowBankAccountsTableFields
    : bankAccountsTableFields;
});

const reqManagerState = computed(() => {
  if (requestManager.isAnyInState('error')) return 'error';
  if (requestManager.isAnyInState('pending')) return 'pending';
  return 'idle';
});

function openBeneficiary(beneficiary: Beneficiary) {
  if (selectedBeneficiary.value === beneficiary) {
    selectedBeneficiary.value = undefined;
  } else {
    selectedBeneficiary.value = beneficiary;
  }
}

function onBeneficiaryUpdated(beneficiary?: Beneficiary) {
  if (selectedBeneficiary.value && selectedBeneficiary.value.id === beneficiary?.id) {
    selectedBeneficiary.value = beneficiary;
  }
  loadBeneficiaries();
}

function loadBeneficiaries() {
  requestManager.newPromise('loadBeneficiaries', individualSettingsStore.loadSelfBeneficiaries(true));
}

function removeBankAccount(beneficiary: Beneficiary) {
  if (beneficiaries.value) {
    requestManager
      .currentOrNew(
        `deleteBeneficiary-${beneficiary.id}`,
        services.beneficiary
          .deleteBeneficiary(beneficiary.id)
          .pipe(
            mergeMap(() =>
              waitForEntityDeletion(
                () => services.beneficiary.getBeneficiary(beneficiary.id, { errors: { silent: true } }),
                { retries: 30 }
              )
            )
          )
      )
      .subscribe(() => {
        if (selectedBeneficiary.value?.id === beneficiary.id) {
          selectedBeneficiary.value = undefined;
        }
        loadBeneficiaries();
      });
  }
}

loadBeneficiaries();
</script>

<template>
  <div class="padded-space" id="bank-accounts-settings-view">
    <div class="centered-space">
      <h2>
        <span class="content">Bank Accounts</span>
        <BankAccountEditModal
          v-if="canManageBeneficiaries"
          v-slot="slotProps"
          @update:beneficiary="onBeneficiaryUpdated()"
        >
          <VButton
            @click="slotProps.showModal"
            :disabled="requestManager.requestStates.loadBeneficiary === 'pending'"
            class="add-bank-account ml-2"
            v-b-tooltip.hover="{
              html: true,
              customClass: 'info-tooltip',
              placement: 'top',
            }"
            :title="$t('tooltips.linkBankAccount')"
          >
            Link a bank account
          </VButton>
        </BankAccountEditModal>
      </h2>
      <BackButton
        class="d-block mb-0 back-button-mobile"
        @click="openBeneficiary(selectedBeneficiary)"
        v-if="selectedBeneficiary && $mediaQuery.is('mdDown')"
      />
      <AnimatedListingWithSubView
        :wideListBlockProps="{ cols: 8, md: 8, sm: 12 }"
        :narrowListBlockProps="{ cols: 4, lg: 6, sm: 6 }"
        :infoBlockProps="{ cols: 8, lg: 6, sm: 12 }"
        :showInfo="!!selectedBeneficiary"
        :animatedState.sync="animatedState"
        :listingBlockClass="selectedBeneficiary ? 'd-sm-none d-lg-block' : 'd-block'"
      >
        <template #listing="{ animatedState }">
          <BankAccountsTable
            showEmptyHeaders
            :selectedItems="selectedBeneficiary ? [selectedBeneficiary.id] : []"
            withRowSubView
            allowDelete
            :fields="tableFields"
            :dataLoadState="reqManagerState"
            :data="{ list: beneficiaries }"
            @row-clicked="openBeneficiary"
            @bank-account-remove="removeBankAccount"
          />
          <BackButton
            v-if="!animatedState.wideList && $mediaQuery.is('lgUp') && selectedBeneficiary"
            class="back-to-list"
            @click="openBeneficiary(selectedBeneficiary)"
          />
        </template>
        <template #sub-view>
          <BoxGridBlock>
            <BankAccountInfo v-if="selectedBeneficiary" :beneficiary="selectedBeneficiary" />
            <div class="mt-4">
              <ConfirmModal
                v-if="selectedBeneficiary"
                v-slot="{ showModal }"
                modalText="Delete this bank account?"
                @ok="removeBankAccount(selectedBeneficiary)"
              >
                <VButton
                  label="Delete"
                  :disabled="requestManager.anyPending"
                  :loading="requestManager.requestStates[`deleteBeneficiary-${selectedBeneficiary.id}`] === 'pending'"
                  @click="showModal"
                />
              </ConfirmModal>
            </div>
          </BoxGridBlock>
        </template>
      </AnimatedListingWithSubView>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.add-bank-account {
  font-size: 1rem;
}

.back-to-list {
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 1;
}

@include upToResolution($tabletResolution) {
  //remove height from td here the delete button is
  //and set is position to top right corner
  ::v-deep td:nth-child(4) {
    max-height: 0 !important;

    .delete-icon {
      position: absolute;
      top: 1rem;
      right: 3rem;
    }
  }
}

.back-button-mobile {
  margin-top: -1rem;

  ::v-deep * {
    font-size: $font-size-xs;
  }
}
</style>
