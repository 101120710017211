import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const optionPremiumPaymentSettlementFullTableFields: TableFieldDefinition[] = [
  {
    header: 'Date Due',
    key: 'date',
  },
  {
    header: 'Amount',
    key: 'amount',
  },
  {
    header: 'State',
    key: 'isPaid',
  },
];

export const optionPremiumPaymentSettlementShortTableFields: TableFieldDefinition[] = [
  {
    header: 'Date',
    key: 'date',
  },
  {
    header: 'You pay',
    key: 'amount',
    tdClass: 'text-right',
    thClass: 'text-right',
  },
];

export const optionPremiumPaymentSettlementEditTableFields: TableFieldDefinition[] = [
  {
    header: 'Date Due',
    key: 'date',
  },
  {
    header: 'Amount',
    key: 'amount',
  },
  {
    header: 'Payment Made',
    key: 'isPaid',
  },
];
