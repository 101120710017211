export enum IndividualType {
  PARTNER_UBO = 'PARTNER_UBO',
  PARTNER_DIRECTOR = 'PARTNER_DIRECTOR',
  PARTNER_ADMIN = 'PARTNER_ADMIN',
  PARTNER_AGENT = 'PARTNER_AGENT',
  CLIENT_UBO = 'CLIENT_UBO',
  CLIENT_DIRECTOR = 'CLIENT_DIRECTOR',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_INDIVIDUAL = 'CLIENT_INDIVIDUAL',
}

export const partnerUserIndividualTypes = [IndividualType.PARTNER_ADMIN, IndividualType.PARTNER_AGENT];

export const partnerAllIndividualTypes = [
  IndividualType.PARTNER_UBO,
  IndividualType.PARTNER_DIRECTOR,
  IndividualType.PARTNER_ADMIN,
  IndividualType.PARTNER_AGENT,
];

export const clientUserIndividualTypes = [IndividualType.CLIENT_ADMIN, IndividualType.CLIENT_INDIVIDUAL];

export const clientAllIndividualTypes = [
  IndividualType.CLIENT_UBO,
  IndividualType.CLIENT_DIRECTOR,
  IndividualType.CLIENT_ADMIN,
  IndividualType.CLIENT_INDIVIDUAL,
];

export const individualTypeLabels: Record<IndividualType, string> = {
  [IndividualType.PARTNER_UBO]: 'Ultimate Beneficial Owner',
  [IndividualType.PARTNER_DIRECTOR]: 'Partner Director',
  [IndividualType.PARTNER_ADMIN]: 'Partner Agent (Admin)',
  [IndividualType.PARTNER_AGENT]: 'Partner Agent',
  [IndividualType.CLIENT_UBO]: 'CLIENT_UBO',
  [IndividualType.CLIENT_DIRECTOR]: 'Director',
  [IndividualType.CLIENT_ADMIN]: 'Client User (Admin)',
  [IndividualType.CLIENT_INDIVIDUAL]: 'Client User',
};

export function individualIsType(individual: { type: IndividualType }, type: IndividualType | IndividualType[]) {
  const types = Array.isArray(type) ? type : [type];
  return types.includes(individual.type);
}
