<script setup lang="ts">
import { ClientCommission, PaginatedQuery } from 'ah-api-gateways';
import { clientCommissionTableFields } from './tableFields/commissionTableFields';
import { computed, PropType } from 'vue';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({ tableFields: clientCommissionTableFields }),
  },
  ...defineUseManagedListingProps<ClientCommission>(),
});

interface ClientCommissionEmits extends UseManagedListingEmits<ClientCommission> {}

const emit = defineEmits<ClientCommissionEmits>();

const requestManager = useRequestManager();

const services = getServices();

/**
 * service call to get, the list of clients commissions, passing whole query:PaginatedQuery as argument
 */
const managedListing = useManagedListing<ClientCommission>({
  loadDataRequest(query: PaginatedQuery) {
    return services.trade.listClientCommissions(query);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

const currency = computed(
  () => (props.tableData?.list && props.tableData.list.length > 0 && props.tableData.list[0].currency) || 'GBP'
);
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    nowrap
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
  >
    <template #cell(totalPartnerProfit)="{ item }">
      {{ formatCurrencyValue(item.totalPartnerProfit) }}
    </template>
    <template #headInner(totalPartnerProfit)>
      Commission <span class="text-small">({{ currency }} Equivalent)</span>
    </template>
  </PaginatedTable>
</template>
