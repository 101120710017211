<script lang="ts" setup>
import { BankingScheme, FeePaymentType } from 'ah-api-gateways';
import { computed, onMounted } from 'vue';
import { useAhBeneficiariesState } from '../..';

const props = withDefaults(
  defineProps<{
    currency: string;
    bankingScheme: BankingScheme;
    type?: FeePaymentType;
  }>(),
  {
    bankingScheme: BankingScheme.LOCAL,
    FeePaymentType: FeePaymentType.PAYMENT,
  }
);

const beneficiarieState = useAhBeneficiariesState();

onMounted(() => {
  beneficiarieState.store.useSettingsStore().loadPaymentRails();
});

const paymentRail = computed(() => {
  if (!props.bankingScheme) {
    return '-';
  }
  const out = beneficiarieState.store.useSettingsStore().getPaymentRail({
    currency: props.currency,
    bankingScheme: props.bankingScheme,
    type: props.type,
  });
  if (out.length > 0) {
    return out[0].label;
  }
  return props.bankingScheme;
});
</script>

<template>
  <span>{{ paymentRail }}</span>
</template>
