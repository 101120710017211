import { PaginatedQuery, PaginatedResponse, standardiseQuerySort, VersionedObject } from '../models';
import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import {
  ReconcilableTransaction,
  ReconcilableTransactionsFilters,
  ReconcilableTransactionsRequest,
} from '../models/reconcilableTransactions';

export class ReconcilableTransactionsService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'reconcilableTransactionsService' } },
      },
    });
  }

  public listReconcilableTransactions(
    query?: PaginatedQuery<ReconcilableTransactionsFilters>,
    options?: Partial<HttpOptions<PaginatedResponse<ReconcilableTransaction>>>
  ) {
    const params = query ? standardiseQuerySort(query) : undefined;
    return this.get<PaginatedResponse<ReconcilableTransaction>>(`${this.baseUrl}reconcilable-transactions`, {
      axiosConfig: {
        params,
      },
      options,
    });
  }

  public getReconcilableTransaction(id: string, options?: Partial<HttpOptions<ReconcilableTransaction>>) {
    return this.get<ReconcilableTransaction>(`${this.baseUrl}reconcilable-transactions/${id}`, {
      options,
    });
  }

  public reconcileTransaction(data: ReconcilableTransactionsRequest, options?: HttpOptions<VersionedObject>) {
    const { reconcilableTransactionId, ...payload } = data;
    return this.put<VersionedObject>(`${this.baseUrl}reconcilable-transactions/${reconcilableTransactionId}`, payload, {
      options,
    });
  }
}
