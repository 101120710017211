import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const clientOnboardingTableFields: TableFieldDefinition[] = [
  {
    header: 'Client',
    key: 'name',
    sortable: true,
  },
  {
    header: 'Type',
    key: 'type',
    sortable: true,
  },
  {
    header: 'Registration',
    key: 'createdAt',
    sortable: true,
  },
  {
    header: 'T&Cs Accepted',
    key: 'termsAndConditionsDate',
    sortable: true,
  },
  {
    header: 'Approval/Rejection',
    key: 'caseClosedAt',
    sortable: true,
  },
  {
    header: 'State',
    key: 'statusLabel',
    sortable: true,
  },
  {
    header: 'Notes',
    key: 'notes',
  },
];
