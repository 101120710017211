<script lang="ts">
import { computed, PropOptions } from 'vue';
import { defaultCurrencyFormatter } from '../helpers/currency';

export const formattedNumberProps = {
  value: { type: [Number, String] },
  prefixLabel: { type: String, default: '' },
  formatter: {
    type: Intl.NumberFormat,
    default: () => defaultCurrencyFormatter,
  },
  scaledDecimals: {
    type: [Boolean, String],
    default: false,
  },
  placeholder: {
    type: String,
    default: '-',
  },
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
const props = defineProps(formattedNumberProps);

const valueParts = computed<Intl.NumberFormatPart[]>(() =>
  typeof props.value === 'number'
    ? props.formatter.formatToParts(props.value)
    : [
        {
          value: props.placeholder,
          type: 'nan',
        },
      ]
);
</script>

<template>
  <span :class="['formatted-number', { 'scaled-decimals': scaledDecimals !== false }]">
    <span v-if="props.prefixLabel" class="prefix"> {{ props.prefixLabel }} </span>
    <span v-for="(part, index) in valueParts" :key="index" :class="['number-segment', `number-segment-${part.type}`]">{{
      part.value
    }}</span>
  </span>
</template>

<style lang="scss">
// Default non-scoped styles, to be restyled when needed
.formatted-number {
  .prefix,
  .number-segment {
    font-size: 1em;
  }

  &.scaled-decimals {
    .number-segment-fraction,
    .number-segment-decimal {
      font-size: 0.75em;
    }
  }
}
</style>
