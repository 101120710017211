<script lang="ts" setup>
import { Client, AssigneeReference } from 'ah-api-gateways';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, ref, watch } from 'vue';
import { getServices } from '@/app/services';

const props = defineProps({
  client: {
    type: Object as () => Client,
    required: true,
  },
  /**
   * Max number of items shown. If 0, shows only count of assignees
   */
  maxItems: {
    type: Number,
    default: 3,
  },
});

const services = getServices();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager(k: string) {
    if (k === 'getAssignees') {
      loadAssignees();
    }
  },
}).manager;

const clientAssignees = ref<AssigneeReference[]>([]);

function loadAssignees() {
  requestManager
    .sameOrCancelAndNew(
      'getAssignees',
      services.client.getClientAssignees(props.client.id, { errors: { silent: true } }),
      props.client.id
    )
    .subscribe((response) => {
      clientAssignees.value = response;
    });
}

const sortedAssignees = computed(() => {
  if (clientAssignees.value) {
    const primary = clientAssignees.value.find((a) => a.primary === true)!;
    return [...(primary ? [primary] : []), ...clientAssignees.value.filter((a) => a.primary === false)].slice(
      0,
      props.maxItems
    );
  }
  return [];
});

const extraAssigneeCount = computed(() => {
  if (clientAssignees.value) {
    return clientAssignees.value.length - sortedAssignees.value.length;
  }
  return 0;
});

watch(() => props.client.id, loadAssignees, { immediate: true });

defineExpose({ loadAssignees });
</script>

<template>
  <div>
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else-if="clientAssignees && requestManager.requestStates.getAssignees !== 'error'">
      <span v-if="sortedAssignees.length === 0" class="text-secondary"> No Assignees </span>
      <span v-else-if="maxItems === 0">{{ extraAssigneeCount }} Assignees</span>
      <template v-else>
        <span v-for="(assignee, index) in sortedAssignees" :key="assignee.individual.id">
          {{ assignee.individual.firstName }} {{ assignee.individual.lastName }}
          <span v-if="index < sortedAssignees.length - 1">,</span>
        </span>
        <span v-if="extraAssigneeCount > 0">+{{ extraAssigneeCount }} others</span>
      </template>
    </template>
    <span v-else>N/A</span>
  </div>
</template>

<style lang="scss" scoped>
span:not(.client-name) {
  opacity: 0.5;
}
</style>
