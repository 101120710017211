/* eslint-disable no-console */
/**
 * Interface to be used accross the application for logging.
 */
export interface LogService {
  log: Function;
  debug: Function;
  info: Function;
  warn: Function;
  error: Function;
}

const noop = (): any => undefined;

/**
 * Default implementation for logging, will log into console when not in production
 * TODO create ServerLogService to upload critical errors and analyze afterwards
 */
export class ConsoleLogService implements LogService {
  constructor(private isProduction: boolean) {}

  get log() {
    if (!this.isProduction) {
      return console.log.bind(console);
    } else {
      return noop;
    }
  }

  get debug() {
    if (!this.isProduction) {
      return console.debug.bind(console);
    } else {
      return noop;
    }
  }

  get info() {
    if (!this.isProduction) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    return console.warn.bind(console);
  }

  get error() {
    return console.error.bind(console);
  }
}
