<script setup lang="ts">
import WalletTransactionsListing from './WalletTransactionsListing.vue';
import WithdrawFromWalletModal from './WithdrawFromWalletModal.vue';
import DownloadStatementModal from 'ah-wallets/src/components/transactions/DownloadStatementModal.vue';
import {
  walletScheduledTransactionsTableFields,
  walletTransactionsTableFields,
} from 'ah-trades/src/models/transactionsTableFields';
import FeesInfoBlock from 'ah-trades/src/components/fees/FeesInfoBlock.vue';
import { AuthorityType, TransactionType, ScheduleState, Client } from 'ah-api-gateways';
import WalletInfoHeaderMobile from './WalletInfoHeaderMobile.vue';
import WalletInfoTitle from './WalletInfoTitle.vue';
import QueryStringTabs from 'ah-common-lib/src/common/components/QueryStringTabs.vue';
import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from 'ah-common-lib/src/onBehalfOf/constants';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhWalletsState } from '..';
import { useRoute } from 'vue-router/composables';

const walletState = useAhWalletsState();

const requestManager = useRequestManager({
  exposeToParent: ['loadWallet', 'waitForChange'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadWallet') {
      loadWallet();
    }
  },
});

const props = withDefaults(
  defineProps<{
    walletId: string;
    /**
     * Should this wallet be displayed as read-only (i.e. no Transfer/Withdraw operations shown)
     *
     * This allows WalletInfo to be used in OBO contexts OUTSIDE a "act on behalf of" section
     * (where data about the Client is read but not written to)
     */
    readonly?: boolean | string;
  }>(),
  {
    readonly: false,
  }
);

const state = reactive<{
  onBehalfOfClient: Client | null;
  tabIndex: number;
}>({
  onBehalfOfClient: inject(ON_BEHALF_OF_CLIENT_INJECT_KEY, null),
  tabIndex: 0,
});

const emit = defineEmits<{
  /**
   * when the back button is pressed
   */
  (e: 'back-to-list', value: void): void;
  /**
   * when a withdraw or a payment is made
   */
  (e: 'wallet-changed', value: void): void;
}>();

const scheduledTransactions = ref<InstanceType<typeof WalletTransactionsListing> | null>(null);

const pastTransactions = ref<InstanceType<typeof WalletTransactionsListing> | null>(null);

const tabsContainer = ref<InstanceType<typeof QueryStringTabs> | undefined>(undefined);

const route = useRoute();

onMounted(() => {
  if (route.query.fees !== undefined) {
    setTimeout(() => (state.tabIndex = 2));
  }
});

const canSeeFees = computed(() => {
  return walletState.store
    .useAuthStore()
    .hasAuthorities([AuthorityType.VIEW_WALLET_FEES, AuthorityType.VIEW_FEES], true);
});

const canSeeTransactions = computed(() => {
  return walletState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_TRANSACTIONS);
});

const wallet = computed(() => walletState.store.useWalletsStore().getWalletById(props.walletId));

const isChargesWallet = computed(() => wallet.value?.currency === 'GBP');

const canMakeOwnPayments = computed(
  () => !state.onBehalfOfClient && walletState.store.useAuthStore().hasAuthorities(AuthorityType.MAKE_PAYMENTS)
);

const canMakeOBOPayments = computed(() =>
  state.onBehalfOfClient?.permissions?.includes(AuthorityType.PAY_ON_BEHALF_OF)
);

/**
 * Withdraw is allowed ONLY in non-OBO settings (readonly is ignored if onBehalfOfClient is set)
 */
const withdrawAllowed = computed(() => canMakeOwnPayments.value && !state.onBehalfOfClient && !isReadonly.value);

const sendMoneyAllowed = computed(() => (canMakeOBOPayments.value || canMakeOwnPayments.value) && !isReadonly.value);

const isReadonly = computed(() => props.readonly !== false);

/**
 * Historical Transactions filter - shows all types except PENDING_BALANCE
 */
const historicalTransactionFilters = {
  type: [
    TransactionType.DEPOSIT,
    TransactionType.WITHDRAW,
    TransactionType.WALLETS_MOVEMENT,
    TransactionType.WITHDRAW_COLLATERAL,
    TransactionType.POST_COLLATERAL,
    TransactionType.COLLECT_COLLATERAL,
    TransactionType.ROLLBACK,
    TransactionType.ADJUSTMENT,
  ],
};

const scheduledTransactionFilters = {
  scheduleState: [
    ScheduleState.PENDING_CONFIRMATION,
    ScheduleState.READY,
    ScheduleState.SUBMITTED,
    ScheduleState.FAILED_EXTERNALLY,
    ScheduleState.FAILED_EXECUTION,
  ],
};

function loadWallet() {
  requestManager.manager.newPromise(
    'loadWallet',
    walletState.store.useWalletsStore().loadWallet({ id: props.walletId, force: true })
  );
}

watch(() => props.walletId, loadWallet);

function onTradeCompleted() {
  refresh();
}

function goBack() {
  emit('back-to-list');
}

function refresh() {
  scheduledTransactions.value?.loadData();
  pastTransactions.value?.loadData();
  emit('wallet-changed');
}

defineExpose({ refresh });
</script>

<template>
  <span>
    <div v-if="walletState.mediaQuery.is('smDown')">
      <WalletInfoHeaderMobile
        :walletId="walletId"
        :readonly="isReadonly"
        @back="goBack"
        @wallet-changed="refresh"
        @trade-completed="onTradeCompleted"
      />
    </div>
    <BoxGridBlock :loading="requestManager.manager.requestStates.loadWallet === 'pending'">
      <div v-if="wallet">
        <span v-if="!$ahWalletState.mediaQuery.is('smDown')">
          <BackButton label="go back" class="back-button d-sm-block d-lg-none px-2 py-1" @click="goBack()" />
          <div class="back-button d-sm-none d-lg-block" @click="goBack()">
            <CloseIcon />
          </div>
          <div class="d-flex justify-content-between mt-4">
            <WalletInfoTitle :wallet="wallet" />
            <div class="menu-wrapper">
              <BDropdown variant="primary" no-caret inline toggle-class="menu-button" right>
                <template #button-content><IconDots /></template>
                <WithdrawFromWalletModal
                  :wallet="wallet"
                  selfBeneficiary
                  v-slot="{ show }"
                  @withdraw-succeded="refresh"
                  v-if="withdrawAllowed"
                >
                  <BDropdownItem @click="show" class="mb-3"><IconDocument /> Withdraw Money</BDropdownItem>
                </WithdrawFromWalletModal>

                <WithdrawFromWalletModal
                  :wallet="wallet"
                  v-slot="{ show }"
                  @withdraw-succeded="refresh"
                  v-if="sendMoneyAllowed"
                >
                  <BDropdownItem @click="show" class="mb-3"><IconDocument /> Make Payment</BDropdownItem>
                </WithdrawFromWalletModal>
                <DownloadStatementModal
                  :feesStatements="tabsContainer && tabsContainer.tabIndex === 2"
                  :wallet="wallet"
                  v-slot="{ show }"
                >
                  <BDropdownItem @click="show"><IconDocument /> Statement</BDropdownItem>
                </DownloadStatementModal>
              </BDropdown>
            </div>
          </div>
        </span>
        <div class="tabs-container" v-if="canSeeTransactions">
          <QueryStringTabs ref="tabsContainer" content-class="mt-4 px-2 mx-1" lazy v-model="state.tabIndex">
            <BTab title="Scheduled Transactions" name="scheduledTransactions" active>
              <WalletTransactionsListing
                scheduled
                :config="{ tableFields: walletScheduledTransactionsTableFields }"
                :filter="scheduledTransactionFilters"
                :walletId="walletId"
                ref="scheduledTransactions"
              />
            </BTab>
            <BTab title="Historical Transactions" name="historicalTransactions">
              <WalletTransactionsListing
                :config="{ tableFields: walletTransactionsTableFields }"
                :filter="historicalTransactionFilters"
                :walletId="walletId"
                ref="pastTransactions"
                :hide-state="false"
              />
            </BTab>
            <BTab title="Fees & Charges" name="fees" v-if="isChargesWallet && canSeeFees">
              <FeesInfoBlock @fees-changed="refresh" :referenceWalletId="walletId" />
            </BTab>
          </QueryStringTabs>
        </div>
      </div>
    </BoxGridBlock>
  </span>
</template>

<style lang="scss" scoped>
.tabs-container {
  min-height: 65vh;
  @include upToResolution($tabletResolution) {
    font-size: 13px;
    ::v-deep li {
      max-width: 33%;
      & .nav-link {
        text-align: center;
        padding-left: 0px;
        padding-right: 8px;
      }
    }
  }
}
.back-button {
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 10px;
}
.menu-wrapper {
  ::v-deep .menu-button {
    padding-right: 0.5em;
    padding-left: 0.5em;

    svg {
      width: 1.4em;
    }
  }
  ::v-deep .dropdown {
    button {
      @include themedBackgroundColor($color-nav-list-link, $color-dark-primary, '', ' !important');
    }
    .dropdown-menu {
      @include themedBackgroundColor($color-box-bg, $color-dark-box-bg, '', ' !important');
      a,
      path {
        @include themedPropColor('fill', $color-text, $color-dark-text);
        @include themedTextColor($color-text, $color-dark-text);
      }
      a:hover {
        path {
          @include themedPropColor('fill', $color-dark-text, $color-dark-text);
        }
        @include themedTextColor($color-dark-text, $color-dark-text);
        @include themedBackgroundColor($color-nav-list-link, $color-dark-primary, '', ' !important');
      }
    }
  }
}
</style>
