import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const productCommissionTableFields: TableFieldDefinition[] = [
  {
    header: 'Product',
    key: 'hedgingProduct',
  },
  {
    header: 'Commission',
    key: 'totalPartnerProfit',
    thClass: 'text-center',
    tdClass: 'text-right pr-5',
    sortable: true,
  },
];
export const clientCommissionTableFields: TableFieldDefinition[] = [
  {
    header: 'Client',
    key: 'clientName',
  },
  {
    header: 'Commission',
    key: 'totalPartnerProfit',
    thClass: 'text-center',
    tdClass: 'text-right pr-5',
    sortable: true,
  },
];
export const agentCommissionTableFields: TableFieldDefinition[] = [
  {
    header: 'Agent',
    key: 'assignee',
  },
  {
    header: 'Commission',
    key: 'totalPartnerProfit',
    thClass: 'text-center',
    tdClass: 'text-right pr-5',
    sortable: true,
  },
];
