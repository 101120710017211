<script setup lang="ts">
import { Observable } from 'rxjs';
import { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  entityKey: {
    type: String,
    default: 'name',
  },
  entityKeySecond: {
    type: String,
  },
  name: {
    type: String,
    default: '',
  },
  email: {
    type: String,
  },
  notFoundValue: {
    type: String,
    default: '-',
  },
  getRequest: {
    type: Function as PropType<(id: string) => Observable<any>>,
    default: () => () => null,
  },
});

const requestManager = useRequestManager().manager;

const entity = ref<any>();

const title = computed(() => {
  if (props.entityKeySecond) {
    return props.name || (entity.value && entity.value[props.entityKey])
      ? entity.value[props.entityKey] + ' ' + entity.value[props.entityKeySecond]
      : '';
  }
  return props.name || (entity.value && entity.value[props.entityKey]) || '';
});

function onIdChange() {
  if (props.id && !props.name) {
    entity.value = null;
    requestManager
      .sameOrCancelAndNew(`fetch-${props.entityKey}`, props.getRequest(props.id), props.id)
      .subscribe((response) => (entity.value = response));
  } else {
    entity.value = null;
    entity.value = { id: props.id, firstName: props.name, email: props.email };
  }
}

watch(() => props.id, onIdChange, { immediate: true });
</script>

<template>
  <div class="entity-cell">
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else>
      <slot v-bind="{ entity }">
        <template v-if="title">
          <span class="entity-name"> {{ title }} </span>
        </template>
        <span v-else class="not-found"> {{ notFoundValue }}</span>
      </slot>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.entity-cell * {
  word-break: break-all;
}

.entity-name {
  @include responsive_font($min: 0.875, $max: 1);
  display: block;
}
</style>
