<script setup lang="ts">
import { ref, computed, PropType, watch } from 'vue';

interface Marker {
  value: number;
  label?: string;
}

interface Limits {
  value: number;
  label?: string;
}

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  min: {
    type: Object as PropType<Limits>,
    default: () => ({ value: 0, label: '' }),
  },
  max: {
    type: Object as PropType<Limits>,
    required: true,
  },
  showColorRanges: {
    type: Boolean,
    default: false,
  },
  showMarkers: {
    type: Boolean,
    default: false,
  },
  showThumb: {
    type: Boolean,
    default: true,
  },
  markers: {
    type: [Array, Number] as PropType<Marker[] | number[] | number>,
    default: () => [],
  },
  showTooltip: {
    type: String as PropType<'always' | 'hover'>,
  },
  tooltipPosition: {
    type: String as PropType<'top' | 'bottom'>,
    default: 'top',
  },
  tooltipFormatter: {
    type: [Function, String] as PropType<(value: number) => string | string>,
    default: (value: number) => value.toString(),
  },
  decimalPlaces: {
    type: Number,
    default: 0,
  },
  stepMultiplier: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits({ 'update:value': (_payload: number) => true });

const sliderCurrentValue = ref();

const sliderCurrentValuePercentage = computed(() => {
  const range = props.max.value - props.min.value;
  const adjustedValue = sliderCurrentValue.value - props.min.value;

  if (sliderCurrentValue.value > props.max.value) {
    return 100;
  }

  return (adjustedValue * 100) / range;
});

const defaultMarkers = computed(() => {
  if (typeof props.markers === 'number') {
    const step = 100 / props.markers;
    return Array.from({ length: props.markers - 1 }, (_, index) => ({ value: step * (index + 1), label: '' }));
  }
  return props.markers.map((marker) => (typeof marker === 'number' ? { value: marker, label: '' } : marker));
});

const step = computed(() => {
  if (props.stepMultiplier > 0) {
    return props.stepMultiplier;
  } else if (props.decimalPlaces > 0) {
    return Math.pow(10, -props.decimalPlaces);
  }
  return 1;
});

function stepFactor(value: number) {
  let outValue = value;

  if (props.stepMultiplier > 0) {
    const multiplier = props.stepMultiplier;
    outValue = Math.round(outValue / multiplier) * multiplier;
  }

  if (props.decimalPlaces > 0) {
    const factor = Math.pow(10, props.decimalPlaces);
    outValue = Math.round(outValue * factor) / factor;
  }

  return outValue;
}

function handleSliderChange() {
  const value = stepFactor(sliderCurrentValue.value);

  sliderCurrentValue.value = value;
  emit('update:value', value);
}

function getTooltipContent() {
  const formattedValue = stepFactor(sliderCurrentValue.value);

  if (typeof props.tooltipFormatter === 'function') {
    return props.tooltipFormatter(formattedValue);
  } else if (typeof props.tooltipFormatter === 'string') {
    return (props.tooltipFormatter as string).replace('{value}', formattedValue.toString());
  }
  return formattedValue.toString();
}

function isActiveByPosition(stepValue: number) {
  return sliderCurrentValuePercentage.value > stepValue;
}

const isThumbHovered = ref(false);

function handleThumbMouseEnter() {
  if (props.showTooltip === 'hover') {
    isThumbHovered.value = true;
  }
}

function handleThumbMouseLeave() {
  if (props.showTooltip === 'hover') {
    isThumbHovered.value = false;
  }
}

watch(
  () => props.value,
  () => {
    sliderCurrentValue.value = props.value;
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div class="slider-container">
    <div class="slider-track">
      <div v-if="showColorRanges">
        <slot name="colorRanges" />
      </div>
      <div v-if="showMarkers" class="markers">
        <div v-for="marker in defaultMarkers" :key="marker.value">
          <slot name="marker" :marker="marker" :active="() => isActiveByPosition(marker.value)">
            <div class="marker" :style="{ left: marker.value + '%' }">
              <span class="marker-label">{{ marker.label }}</span>
            </div>
          </slot>
        </div>
      </div>
      <slot name="currentValueColorRange" :currentValuePercentage="sliderCurrentValuePercentage">
        <div class="default-slider-current-value" :style="{ width: sliderCurrentValuePercentage + '%' }"></div>
      </slot>

      <input
        v-if="showThumb"
        type="range"
        class="slider-thumb"
        :min="min.value"
        :max="max.value"
        v-model="sliderCurrentValue"
        :step="step"
        @input="handleSliderChange"
        @mouseenter="handleThumbMouseEnter"
        @mouseleave="handleThumbMouseLeave"
      />
      <div class="tooltip-track">
        <div
          v-if="showTooltip"
          class="tooltip-holder"
          :style="{ left: sliderCurrentValuePercentage + '%' }"
          :class="{
            'position-top': tooltipPosition === 'top',
            'position-bottom': tooltipPosition === 'bottom',
            'always-visible': showTooltip === 'always',
            'hover-visible': isThumbHovered,
          }"
        >
          <slot name="tooltip" :value="sliderCurrentValue">
            <div class="tooltip-base">
              <slot name="tooltip-content" :value="sliderCurrentValue">
                <div class="tooltip-default-style">{{ getTooltipContent() }}</div>
              </slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div v-if="min?.label || max?.label" class="limits-labels">
      <span>{{ min?.label ?? '' }}</span>
      <span>{{ max.label ?? '' }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .slider-track {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: $x-ui-dark-contrast-bg-base-pressed;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: visible;

    .markers {
      position: absolute;
      width: 100%;
      height: 1rem;
      top: 0;
      pointer-events: none;

      .marker {
        position: absolute;
        height: 100%;
        border-left: 1px solid $x-ui-light-contrast-fg-secondary;
        text-align: center;
        color: $x-ui-light-contrast-fg-secondary;

        .marker-label {
          position: absolute;
          top: 1.25rem;
          left: -1rem;
        }
      }
    }

    .default-slider-current-value {
      position: absolute;
      height: 100%;
      background-color: $x-ui-dark-tag-orange-text;
      border-radius: 10px;
      overflow: hidden;
    }

    input[type='range'] {
      position: absolute;
      width: 100%;
      height: 10px;
      appearance: none;
      background: transparent;
      pointer-events: none;
      z-index: 10;

      &::-webkit-slider-thumb {
        appearance: none;
        height: 20px;
        width: 20px;
        background-color: $x-ui-dark-tag-orange-text;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: auto;
        border: 1px solid white;
        box-shadow: 0.25px 0.25px 2px 1px rgba(0, 0, 0, 0.32);
      }

      &::-moz-range-thumb {
        appearance: none;
        height: 20px;
        width: 20px;
        background-color: $x-ui-dark-tag-orange-text;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid white;
      }
    }

    .tooltip-track {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      left: 10px;
    }

    .tooltip-holder {
      position: absolute;
      top: 50%;
      height: 0;
      width: 0;
      display: none;

      .tooltip-base {
        width: auto;
        height: auto;
        position: absolute;
        transform: translateX(-50%);
        font-size: 0.85rem;
        white-space: nowrap;
        z-index: 3;
        pointer-events: none;

        .tooltip-default-style {
          border-radius: 5px;
          background-color: $x-ui-dark-tag-orange-text;
          color: white;
          padding: 0.25rem 0.5rem;

          &::before {
            content: '';
            position: absolute;
            bottom: -0.25rem;
            left: 50%;
            translate: -50% 0;
            width: 0.5rem;
            height: 0.5rem;
            rotate: 45deg;
            z-index: -1;
            background-color: inherit;
          }
        }
      }

      &.position-bottom .tooltip-base {
        top: 2em;
        .tooltip-default-style::before {
          bottom: 1.827em;
        }
      }

      &.position-top .tooltip-base {
        bottom: 2em;
      }

      &.always-visible {
        display: block;
      }

      &.hover-visible {
        display: block;
      }
    }
  }

  .limits-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #3a3b3b;
    margin-top: 1rem;

    span {
      font-size: $font-size-sm;
    }
  }
}
</style>
