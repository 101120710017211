<script lang="ts" setup>
import { PartnerTrade, CommissionDisplayType, HedgingInstruments } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { computed } from 'vue';
import FundingTypeCell from '../tableCells/FundingTypeCell.vue';

const props = withDefaults(
  defineProps<{
    tradeReport: PartnerTrade;
    rejectedOrCanceled?: boolean;
    displayType?: CommissionDisplayType;
  }>(),
  {
    rejectedOrCanceled: false,
    displayType: CommissionDisplayType.ORIGINAL,
  }
);

const isApportioned = computed(() => {
  return props.displayType === CommissionDisplayType.APPORTIONED;
});

const isVanillaOptions = computed(() => {
  return props.tradeReport.hedgingProduct === HedgingInstruments.VANILLA_OPTIONS;
});
</script>

<template>
  <div>
    <h3 v-if="$mediaQuery.is('smDown')">Commissions Summary</h3>
    <VRow>
      <VCol xl="4" lg="6" md="5" sm="6" class="info-col info-labels">
        <h3 v-if="$mediaQuery.is('mdUp')">Commissions Summary</h3>
        <div>Mark Up Commission:</div>
        <div v-if="!isVanillaOptions">
          Swap PNL Commission: <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.swapPnlCommission')" />
        </div>
        <div>Date Roll Commission: <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.dateRollCommission')" /></div>
        <div>Apportioned Comm. Date:</div>
        <div>IMCC:</div>
        <div>IMCC Date:</div>
        <div>Commission Rate:</div>
      </VCol>

      <VCol xl="3" lg="3" md="4" sm="3" class="info-col">
        <div class="info-header" v-if="$mediaQuery.is('mdUp')">Actual Currency</div>
        <div v-if="!isApportioned">
          <div>{{ tradeReport.profitCurrency }} {{ formatCurrencyValue(tradeReport.partnerProfit) }}</div>
          <div v-if="!isVanillaOptions">
            {{ tradeReport.swapPnlCurrency }} {{ formatCurrencyValue(tradeReport.partnerSwapPnl) }}
          </div>
          <div>
            {{ tradeReport.drawdownPnlCurrency }} {{ formatCurrencyValue(tradeReport.partnerDrawdownPnl || 0) }}
          </div>
          <div>{{ formatDate(tradeReport.commissionsDate) }}</div>
          <div>
            {{ tradeReport.initialMarginCreditChargeAmountCurrency }}
            {{ formatCurrencyValue(tradeReport.partnerInitialMarginCreditChargeAmount) }}
          </div>
          <div>{{ formatDate(tradeReport.commissionsDate) }}</div>
          <div>{{ tradeReport.partnerCommissionPercentage * 100 }} %</div>
        </div>
        <div v-else>
          <div>{{ tradeReport.profitCurrency }} {{ formatCurrencyValue(tradeReport.remainingPartnerProfit) }}</div>
          <div v-if="!isVanillaOptions">
            {{ tradeReport.swapPnlCurrency }} {{ formatCurrencyValue(tradeReport.remainingPartnerSwapPnl) }}
          </div>
          <div>
            {{ tradeReport.drawdownPnlCurrency }}
            {{ formatCurrencyValue(tradeReport.remainingPartnerDrawdownPnl) }}
          </div>
          <div>{{ formatDate(tradeReport.commissionsDate) }}</div>
          <div>
            {{ tradeReport.initialMarginCreditChargeAmountCurrency }}
            {{ formatCurrencyValue(tradeReport.remainingPartnerInitialMarginCreditChargeAmount) }}
          </div>
          <div>{{ formatDate(tradeReport.commissionsDate) }}</div>
          <div>{{ tradeReport.partnerCommissionPercentage * 100 }} %</div>
        </div>
      </VCol>

      <VCol xl="3" lg="3" md="3" sm="3" class="info-col">
        <div class="info-header" v-if="$mediaQuery.is('mdUp')">{{ tradeReport.homeCurrency }} Currency EQUIV.</div>
        <div v-if="!isApportioned">
          <div>{{ tradeReport.homeCurrency }} {{ formatCurrencyValue(tradeReport.gbpPartnerProfit) }}</div>
          <div>{{ tradeReport.homeCurrency }} {{ formatCurrencyValue(tradeReport.gbpPartnerSwapPnl) }}</div>
          <div>{{ tradeReport.homeCurrency }} {{ formatCurrencyValue(tradeReport.gbpPartnerDrawdownPnl) }}</div>
          <div>&nbsp;</div>
          <div>
            {{ tradeReport.homeCurrency }}
            {{ formatCurrencyValue(tradeReport.gbpPartnerInitialMarginCreditChargeAmount) }}
          </div>
        </div>
        <div v-else>
          <div>{{ tradeReport.homeCurrency }} {{ formatCurrencyValue(tradeReport.remainingGbpPartnerProfit) }}</div>
          <div>{{ tradeReport.homeCurrency }} {{ formatCurrencyValue(tradeReport.remainingGbpPartnerSwapPnl) }}</div>
          <div>
            {{ tradeReport.homeCurrency }} {{ formatCurrencyValue(tradeReport.remainingGbpPartnerDrawdownPnl) }}
          </div>
          <div>&nbsp;</div>
          <div>
            {{ tradeReport.homeCurrency }}
            {{ formatCurrencyValue(tradeReport.remainingGbpPartnerInitialMarginCreditChargeAmount) }}
          </div>
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol xl="4" lg="6" md="5" sm="6" class="info-labels">
        <div>IM Funded with:</div>
      </VCol>
      <VCol xl="5" lg="6" md="7" sm="6" class="info-col">
        <FundingTypeCell :trade="tradeReport" />
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.info-col div {
  font-size: $font-size-sm;
  height: 2rem;
  white-space: nowrap;
  &.info-header {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    margin-bottom: 0.9rem;
  }

  @include phablet {
    text-overflow: initial;
    overflow: visible;
  }
}
.info-labels div {
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
