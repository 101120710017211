import Vue from 'vue';
import Router, { NavigationGuard } from 'vue-router';
import config from '@/config';
import { routes } from './routes';
import { checkAuth, requireRouteRolesAndAuthorities, requireClientChecks } from './guards';
import { CustomRoute } from './routeModels';

Vue.use(Router);

let router!: Router;

function createRouter() {
  router = new Router({
    mode: (config.routerMode as any) || 'hash',
    routes,
  });

  function tagPage(to: CustomRoute) {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);

    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title! + ' | ' + Vue.theme.val?.name;
    } else {
      document.title = Vue.theme.val?.name || '';
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
      (el) => el.parentNode && el.parentNode.removeChild(el)
    );
    if (nearestWithMeta) {
      nearestWithMeta.meta
        .metaTags!.map((tagDef: any) => {
          const tag = document.createElement('meta');

          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
          });

          tag.setAttribute('data-vue-router-controlled', '');

          return tag;
        })
        .forEach((tag: Element) => document.head.appendChild(tag));
    }
  }

  router.beforeEach(checkAuth as NavigationGuard);
  router.beforeEach(requireRouteRolesAndAuthorities as NavigationGuard);
  router.beforeEach(requireClientChecks as NavigationGuard);

  router.beforeEach((to, from, next) => {
    try {
      tagPage(to as CustomRoute);
    } catch (e) {
      // if tagPage errors we can safely ignore it
    }
    next();
  });
}

export function getRouter(create = false) {
  if (create) {
    createRouter();
  }
  return router;
}
