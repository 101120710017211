<script lang="ts">
export interface AnimatedListingWithSubViewState {
  wideList: boolean;
  showInfo: boolean;
}
</script>

<script lang="ts" setup>
import { reactive, watch } from 'vue';

const props = defineProps({
  showInfo: {
    type: Boolean,
    required: true,
  },
  listingBlockClass: {
    type: String,
    required: false,
  },
  wideListBlockProps: {
    type: Object,
    default: () => ({}),
  },
  narrowListBlockProps: {
    type: Object,
    default: () => ({}),
  },
  infoBlockProps: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits({
  'update:animatedState': (_value: AnimatedListingWithSubViewState) => true,
});

const animatedState = reactive<AnimatedListingWithSubViewState>({
  wideList: !props.showInfo,
  showInfo: props.showInfo,
});

function afterAnimation() {
  animatedState.wideList = !props.showInfo;
  animatedState.showInfo = props.showInfo;
}

watch(
  animatedState,
  () => {
    emit('update:animatedState', animatedState);
  },
  { immediate: true }
);

watch(
  () => props.showInfo,
  (newVal, oldVal) => {
    if (oldVal && !newVal) {
      animatedState.showInfo = false;
    } else if (!oldVal && newVal) {
      animatedState.wideList = false;
    }
  }
);
</script>

<template>
  <BoxGrid alignH="start">
    <BoxGridBlock
      :class="[animatedState.wideList ? 'with-wide-list' : 'with-narrow-list', listingBlockClass]"
      v-bind="animatedState.wideList ? wideListBlockProps : narrowListBlockProps"
      animateTransitions
      @transition-end="afterAnimation"
    >
      <slot name="listing" v-bind="{ animatedState }" />
    </BoxGridBlock>

    <Transition name="slide-and-fade" @after-leave="afterAnimation">
      <BoxGridItem v-if="animatedState.showInfo" v-bind="infoBlockProps">
        <slot name="sub-view" v-bind="{ animatedState }" />
      </BoxGridItem>
    </Transition>
  </BoxGrid>
</template>
