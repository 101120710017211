<script lang="ts" setup>
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { Beneficiary } from 'ah-api-gateways';
import { FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { computed, reactive, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    /**
     * Sync-able value for reference
     */
    reference?: string;
    /**
     * Beneficiary target of the payment, for reference value prefil.
     *
     * A change to beneficiary will trigger a reset of the reference value.
     */
    beneficiary?: Beneficiary;
    /**
     * Whether to display this component in a narrow style
     */
    narrow?: string | boolean;
  }>(),
  {
    reference: '',
    narrow: false,
  }
);

const txReferenceForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'referenceForm',
    fieldType: 'form',
    fields: [
      textField('reference', '', {
        required: false,
        defaultValue: props.reference,
        showLengthCounter: true,
        maxLength: 18,
      }),
    ],
  }),
  validation: null,
});

function onReferenceChange() {
  if (txReferenceForm.form.reference) {
    txReferenceForm.form.reference = props.reference?.slice(0, 18) || '';
  }
}

const emit = defineEmits<{
  /**
   * - update:reference (payload: string | null) : .sync-able emission of reference value
   */
  (e: 'update:reference', value: string | null): void;
}>();

const isNarrow = computed(() => props.narrow !== false);

const valid = computed(() => !(txReferenceForm.validation && txReferenceForm.validation.$invalid));

function emitValue() {
  emit('update:reference', txReferenceForm.form.reference);
}

watch(
  () => props.reference,
  () => {
    onReferenceChange();
  },
  { immediate: true }
);

watch(
  () => props.beneficiary,
  () => {
    if (props.beneficiary) {
      onReferenceChange();
      // Value needs to be emmitted in the next JS frame, to allow all watchers to run beforehand
      setTimeout(() => emitValue());
    }
  },
  { immediate: true }
);

function touchForms() {
  if (txReferenceForm.validation) {
    submitForm(txReferenceForm.validation);
  }
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emitValue();
  }
}

defineExpose({ touchForms, valid });
</script>

<template>
  <ValidatedForm
    :fm="txReferenceForm.form"
    :validation.sync="txReferenceForm.validation"
    :class="{ 'narrow-style': isNarrow }"
    @form-event="onFormEvent"
  >
    <template #referenceForm.reference:before>
      <span class="label-before"> Reference </span>
    </template>
  </ValidatedForm>
</template>

<style lang="scss" scoped>
::v-deep {
  .field-group-wrapper {
    display: flex;
    margin-bottom: 0;

    .label-before {
      white-space: nowrap;
      margin-top: 0.4em;
      margin-right: 3.5em;
    }

    .field-group.reference {
      width: 100%;
    }
  }
}

.narrow-style ::v-deep .field-group-wrapper {
  flex-wrap: nowrap;
}
</style>
