<script lang="ts" setup>
import { getHSLA } from 'ah-theme';

import { makeBarChartDataset, makeBarChartOptions } from '../helpers/chartConfig';
import VChart from './VChart.vue';
import { linesColorPrimary } from '../helpers/graphColors';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useAhReportsState } from '..';

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: onRetryFromParentManager,
});

function onRetryFromParentManager(k: string) {
  if (k === 'loadGraphData') {
    loadGraphData();
  }
}

const props = defineProps<{
  graphType: 'wallet-withdrawals' | 'trade-settled' | 'trade-all' | 'trade-scheduled';
}>();

const options = makeBarChartOptions({ horizontal: true, gridColor: linesColorPrimary });

const data = reactive(makeBarChartDataset());

const reportsState = useAhReportsState();

function loadGraphData() {
  requestManager.manager.sameOrCancelAndNew('loadGraphData', getDataRequest()).subscribe((response) => {
    const graphData = {
      monthToDate: response.monthToDatePartnerProfit,
      yearToDate: response.yearToDatePartnerProfit,
      allTime: response.allTimePartnerProfit,
    };
    data.datasets[0].data = Object.values(graphData);
    data.labels = ['Month to date', 'Year to date', 'All-time '];
    data.datasets[0].backgroundColor = getHSLA('widgets-green');
  });
}

watch(
  () => props.graphType,
  () => {
    loadGraphData();
  },
  { immediate: true }
);

function getDataRequest() {
  if (props.graphType === 'wallet-withdrawals') {
    return reportsState.services.wallet.getWithdrawalCommissions();
  }
  if (props.graphType === 'trade-settled') {
    return reportsState.services.trade.getSettledCommissions();
  }
  if (props.graphType === 'trade-all') {
    return reportsState.services.trade.getTotalCommissions();
  }
  return reportsState.services.trade.getScheduledCommissions();
}

const hasData = computed(() => {
  return data.datasets[0].data?.find((e) => e && typeof e === 'number' && e > 0);
});
</script>

<template>
  <div>
    <LoadingIcon v-if="requestManager.manager.requestStates.loadGraphData === 'pending'" class="svg" />
    <div v-else>
      <div v-if="hasData">
        <VChart class="chart" type="bar" :options="options" :data="data" />
        <span class="chart-label">Amounts in GBP equivalent</span>
      </div>
      <div v-else>No data found</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  padding-bottom: 33%;
}
.chart-label {
  font-size: 13px;
  margin-left: 82px;
  opacity: 0.5;
}
</style>
