<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import AcoTiersList from '@/app/components/FeesAndCharges/AcoTiersList.vue';
import { BankingScheme, AcoTier, FeePaymentType, FundingCharge } from 'ah-api-gateways';
import { computed, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';

/**
 * Show ACO Tiers for payment fees
 *
 * Uses Global values as reference
 */

const emit = defineEmits({
  'update:formBusy': (_value: boolean) => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadPartnerFees') {
      loadPartnerFees();
    }
  },
}).manager;

const services = getServices();

const acoTiersFormModal = ref<InstanceType<typeof BModal>>();

const partnerFees = ref<FundingCharge[]>([]);

watch(
  () => requestManager.anyPending,
  () => {
    emit('update:formBusy', requestManager.anyPending);
  },
  { immediate: true }
);

const tierList = computed(() => {
  return [
    { value: AcoTier.ACO_TIER_1, label: 'Tier 1' },
    { value: AcoTier.ACO_TIER_2, label: 'Tier 2' },
    { value: AcoTier.ACO_TIER_3, label: 'Tier 3' },
    { value: AcoTier.ACO_TIER_4, label: 'Tier 4' },
  ];
});

function loadPartnerFees() {
  requestManager
    .currentOrNew(
      'loadPartnerFees',
      services.fees.listAHFees({
        type: FeePaymentType.PAYMENT,
        sort: 'bankingScheme,desc',
        bankingScheme: BankingScheme.SWIFT,
        pageSize: 1000,
      })
    )
    .subscribe((response) => {
      partnerFees.value = response ?? null;
    });
}

loadPartnerFees();

function showModal() {
  acoTiersFormModal.value?.show();
}
</script>

<template>
  <span>
    <BModal modal-class="side-modal aco-tier-modal" title="ACO tier breakdown" ref="acoTiersFormModal" hide-footer>
      <BoxGridBlock>
        <LoadingIcon v-if="requestManager.requestStates.loadPartnerFees === 'pending'" class="svg" />
        <BTabs class="tabs" content-class="mt-3" lazy v-else>
          <VRow class="table-labels mb-2" align-h="between" cols="12">
            <VCol md="9" sm="7"> Country </VCol>
            <VCol md="3" sm="5"> ISO </VCol>
          </VRow>
          <span v-for="(tier, index) in tierList" :key="index">
            <BTab class="tab" :title="tier.label" :active="index === 0">
              <AcoTiersList :fees="partnerFees.filter((fee) => fee.tier === tier.value)" />
            </BTab>
          </span>
        </BTabs>
      </BoxGridBlock>
    </BModal>
    <slot v-bind="{ showModal }" />
  </span>
</template>

<style lang="scss" scoped>
::v-deep .modal-body {
  width: 45rem;
  @include tablet {
    width: 40rem;
  }
  @include upToResolution($tabletResolution) {
    width: 100vw;
  }
}

.tabs,
.table-labels {
  font-size: 0.9em;
  ::v-deep .nav-item {
    width: 25%;
  }
}

::v-deep .nav-link {
  @include upToResolution($tabletResolution) {
    text-align: center;
  }
}
</style>
