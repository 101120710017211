<script setup lang="ts">
import { getChildModel, makeFormModel, setState } from '../../form/helpers';
import { selectField } from '../../form/models';
import { PaginatedParams } from 'ah-api-gateways';
import { formTitle } from '../../form/models';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { PropType, reactive, watch } from 'vue';
import { TableFieldDefinition } from 'src/models';
const emit = defineEmits({
  'update:sortBy': (_value: string) => true,
  'update:sortAndPageParams': (_value: PaginatedParams) => true,
});

const props = defineProps({
  sortAndPageParams: {
    type: Object as PropType<Partial<PaginatedParams>>,
    default: () => ({}),
  },
  fields: {
    type: Array as PropType<TableFieldDefinition[]>,
    required: true,
  },
});

const sortFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'sorterForm',
    fieldType: 'form',
    fields: [
      formTitle('Sort'),
      selectField('sortBy', '', [], {
        required: false,
        fieldWrapperClass: 'col col-8',
      }),
      selectField(
        'sortDirection',
        '',
        [
          {
            label: 'Asc',
            value: 'ASC',
          },
          {
            label: 'Desc',
            value: 'DESC',
          },
        ],
        {
          required: false,
          fieldWrapperClass: 'col col-4',
        }
      ),
    ],
  }),
  validation: null,
});

function onFieldsChange() {
  // FIXME update sortForm.sortby.options
  const sortby = getChildModel(sortFormDef.form, 'sortBy')!;

  if (sortby) {
    setState(
      sortby,
      'options',
      props.fields.filter((i) => i.sortable).map((i) => ({ label: i.header, value: i.sortKey ? i.sortKey : i.key }))
    );
  }
}

watch(() => props.fields, onFieldsChange, { immediate: true });

function onSortByChange() {
  sortFormDef.form.sortBy = props.sortAndPageParams.sort;
  sortFormDef.form.sortDirection = props.sortAndPageParams.sortDirection;
}

watch(() => props.sortAndPageParams, onSortByChange, { immediate: true });

function onFormEvent() {
  emit('update:sortBy', sortFormDef.form.sortBy);
  emit('update:sortAndPageParams', {
    ...props.sortAndPageParams,
    sort: sortFormDef.form.sortBy,
    sortDirection: sortFormDef.form.sortDirection,
  });
}
</script>

<template>
  <ValidatedForm :fm="sortFormDef.form" @form-event="onFormEvent" />
</template>
