import { optional } from './optional';
import { compareState } from './paramCheck';
import { FieldOptions } from '../interfaces/form';

export function fileTypeCheck(fieldName: string) {
  return optional(
    compareState(fieldName, (val: File[], fieldState: FieldOptions) => {
      if (val && val.length && fieldState.acceptedFileTypes && fieldState.acceptedFileTypes.length) {
        for (let index = 0; index < val.length; index += 1) {
          if (fieldState.acceptedFileTypes.findIndex((i) => val[index].type.startsWith(i)) === -1) {
            return false;
          }
        }
      }

      return true;
    })
  );
}

export function fileSizeCheck(fieldName: string) {
  return optional(
    compareState(fieldName, (val: File[], fieldState: FieldOptions) => {
      if (val && val.length && fieldState.maxSize) {
        for (let index = 0; index < val.length; index += 1) {
          if (val[index].size > fieldState.maxSize) {
            return false;
          }
        }
      }

      return true;
    })
  );
}
