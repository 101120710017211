import { HttpService, HttpBasedService, HttpOptions } from 'ah-requests';
import { AiChatbotRequest, AiChatbotResponse } from '../models/aiChatbot';

export class AiChatbotService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'aiChatbotService' } },
      },
    });
  }

  /**
   * Query AI Chatbot
   *
   * FIXME this request is based on API contracts for future work, and is currently BROKEN
   * Added as this is only used in the AI chatbot overlay (hidden by FE feature flag)
   * and can be available for testing byt the API devs whenever needed
   */
  public queryChatbot(data: AiChatbotRequest, options?: Partial<HttpOptions<AiChatbotResponse>>) {
    return this.post<AiChatbotResponse>(`${this.baseUrl}support-bot/message`, data, {
      options,
    });
  }
}
