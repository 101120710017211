export enum SystemErrorCodes {
  IN_MAINTENANCE = 'SYS-10000',
}

export enum GenericErrorCodes {
  VALIDATION_ERROR = 'GEN-10401',
  UNAUTHENTICATED = 'GEN-10100',
  RESOURCE_NOT_FOUND = 'GEN-10200',
  BUSINESS_RULE_NOT_MET = 'GEN-10409',
}

export enum AuthenticationServiceErrorCodes {
  UNVERIFIED_USER = 'AUTH-30107',
  BAD_CREDENTIALS = 'AUTH-30108',
}

export enum PricingEngineErrorCodes {
  AMOUNT_OUT_OF_BOUNDS = 'PE-10120',
  UNAVAILABLE_CURRENCY_PAIR = 'PE-10101',
  OPTION_PREMIUM_LESS_THAN_THRESHOLD = 'PE-10132',
  OPTION_PREMIUM_GREATER_THAN_THRESHOLD = 'PE-10133',
  BASIS_POINTS_BELOW_PARTNER_PROFIT_LIMITS = 'PE-10141',
  BASIS_POINTS_ABOVE_PARTNER_PROFIT_LIMITS = 'PE-10142',
  PROFIT_MARGIN_LIMITS_NOT_FOUND = 'PE-10328',
  CLIENT_MARKUP_IS_NEGATIVE = 'PE-10220',
  EXPIRY_DATE_INVALID = 'PE-10128',
}

export enum LimitsErrorCodes {
  INVALID_AMOUNT = 'LS-12002',
}

export enum TradingManagerErrorCodes {
  LIMITS_PROFILE_MISSING = 'TM-10500',
  AMEND_INVALID_SETTLEMENT_DATE = 'TM-10437',
  PRICE_EXPIRED = 'TM-10401',
  PREMIUM_CONFIRMED = 'TM-10472',
  PREMIUM_NOT_CONFIRMED = 'TM-10473',
}

export enum BeneficiaryManagerErrorCodes {
  IBAN_VALIDATION_ERROR = 'BM-10410',
  BUSINESS_RULE_NOT_MET = 'GEN-10404',
}

export enum SecurityErrorCodes {
  INVALID_OTP = 'SEC-30103',
  EXPIRED_OTP = 'SEC-30105',
}

export enum CMSErrorCodes {
  CLIENT_REGISTRATION_NUMBER_CONFLICT = 'CMS-20305',
  INVALID_APPROVE_TOKEN = 'CMS-20611',
  OWNER_ALREADY_APPROVED = 'CMS-20902',
  EMAIL_ALREADY_VERIFIED = 'CMS-20612',
  APPLICANT_OWNER_EMAIL_CONFLICT = 'CMS-20901',
  CLIENT_INVITE_CONFLICT = 'CMS-20315',
}

export enum AuthErrorCodes {
  CLIENT_USERNAME_CONFLICT = 'AUTH-21300',
  OTP_ATTEMPTS_EXCEEDED = 'AUTH-30112',
  FAILED_MFA_VALUE = 'AUTH-30113',
  MFA_ATTEMPTS_EXCEEDED = 'AUTH-30114',
  INVALID_MFA_CHECK = 'AUTH-30115',
  MFA_ATTEMPT_TYPE_MISMATCH = 'AUTH-30116',
}

export enum PaymentErrorCodes {
  PAYMENT_EXCEEDS_TRADE_AMOUNT = 'PAYMENTS-20012',
  PAYMENT_LINKED_TO_OTHER_TRADE = 'PAYMENTS-20014',
  TRADE_IS_IN_INVALID_STATE = 'PAYMENTS-20015',
  MAX_PAYMENTS_ON_TRADES = 'PAYMENTS-20018',
  AGGREGATED_LIMIT_REACHED = 'PAYMENTS-20019',
}

export enum ComplianceErrorCodes {
  TERMS_AND_CONDITIONS_ALREADY_APPROVED = 'COMP-20110',
  ADDRESS_HISTORY_TEMPORAL_GAPS = 'COMP-20407',
  ADDRESS_HISTORY_YEARS = 'COMP-20408',
  INVALID_STATE_OR_PROVINCE = 'COMP-20409',
  ADDRESS_HISTORY_MISSING_RESIDING_FROM = 'COMP-20414',
  CLIENT_UBO_EMAIL_ALREADY_IN_USE = 'COMP-20430',
}

export enum CollateralErrorCodes {
  INVALID_VARIATION_MARGIN_PERCENTAGE_VALUE = 'COLLATERAL-20000',
  INVALID_VARIATION_MARGIN_INCREMENT_PERCENTAGE_VALUE = 'COLLATERAL-20001',
}
