import _Vue, { ObjectDirective } from 'vue';

const observer = new IntersectionObserver((entries) => {
  entries.forEach((e) => {
    e.target.dispatchEvent(new CustomEvent('intersectionEvent', { detail: e }));
  });
});

export default {
  install: function install(Vue: typeof _Vue) {
    Vue.directive('on-intersect', {
      bind(el: HTMLElement, binding) {
        observer.observe(el);
        el.addEventListener('intersectionEvent', (e) => {
          const intersection = (e as CustomEvent<IntersectionObserverEntry>).detail;
          if (typeof binding.value === 'function') {
            binding.value(intersection);
          }
        });
      },
      unbind(el) {
        observer.unobserve(el);
      },
    } as ObjectDirective);
  },
};
