<script setup lang="ts">
import FeesEditor from '@/app/components/FeesAndCharges/FeesEditor.vue';
import { BModal } from 'bootstrap-vue';
import AcoTiersModal from '@/app/components/FeesAndCharges/AcoTiersModal.vue';
import { BankingScheme, FeePaymentType } from 'ah-api-gateways';
import { PropType, computed, ref } from 'vue';
import { useTheme } from 'ah-theme';
import { capitalize } from 'lodash';

const props = defineProps({
  clientId: { type: String, required: false },
  clientName: { type: String, required: false },
  paymentType: { type: String as PropType<FeePaymentType>, required: false },
  inModal: { type: Boolean, required: false },
});

const theme = useTheme();

const feesEditor = ref<InstanceType<typeof FeesEditor> | null>(null);

const resetToZeromodal = ref<BModal>();

const resetToDefaultModal = ref<BModal>();

const mobileFeesEditorModal = ref<BModal>();

const isFormBusy = false;

const bankingScheme = ref<BankingScheme>(BankingScheme.LOCAL);

const typeLabel = computed(() =>
  props.paymentType === FeePaymentType.PAYMENT ? capitalize(FeePaymentType.PAYMENT) : capitalize(FeePaymentType.FUNDING)
);

const partnerName = computed(() => theme.val.name);

const mobileTitle = computed(() =>
  bankingScheme.value === BankingScheme.LOCAL ? 'Edit LOCAL rail fees' : 'Edit SWIFT rail fees'
);

const isInModal = computed(() => props.inModal !== false);

function resetFeesToZero() {
  const confirm = () => {
    feesEditor.value!.setFeesToZero();
  };

  resetToZeromodal.value?.show();
  resetToZeromodal.value?.$once('ok', confirm);
  resetToZeromodal.value?.$once('hidden', () => {
    resetToZeromodal.value?.$off('ok', confirm);
  });
}

function resetFeesToDefault() {
  const confirm = () => {
    feesEditor.value!.setFeesToDefault();
  };

  resetToDefaultModal.value?.show();
  resetToDefaultModal.value?.$once('ok', confirm);
  resetToDefaultModal.value?.$once('hidden', () => {
    resetToDefaultModal.value?.$off('ok', confirm);
  });
}

function openMobileModal(bankingSchemeArg: BankingScheme) {
  bankingScheme.value = bankingSchemeArg;
  mobileFeesEditorModal.value?.show();
}
</script>

<template>
  <div :class="{ 'padded-space': !isInModal }" id="fees-list-view" v-if="paymentType">
    <h2 v-if="!isInModal">{{ typeLabel }} Fees</h2>
    <BoxGrid alignH="start">
      <BoxGridBlock cols="12" lg="12" align-self="stretch">
        <h3 v-if="clientName === null">
          <p v-if="paymentType === 'RECEIPT'">
            Funding fees are fees incurred when funds are received into the wallet.
          </p>
          <p>Use the settings below to assign GBP payment fees charged to all your clients.</p>
          <p>ALT 21 incurred fees are passed to {{ partnerName }}. Fees will be debited from your GBP Wallet.</p>
          <p>
            {{ partnerName }} can choose to charge fees to clients. Fees will be credited to your GBP Wallet. Fees can
            be further adjusted at an individual level under Clients.
          </p>
          <p>Fees can be further adjusted at an individual level under Clients.</p>
        </h3>
        <h3 v-else>
          <p v-if="paymentType === 'RECEIPT'">
            Funding fees are fees incurred when funds are received into the wallet.
          </p>
          <p>
            Use the settings below to assign GBP payment fees that are applied specifically to {{ clientName }}. Note:
            This will override the values you have assigned as your global fees settings.
          </p>
          <p>ALT 21 incurred fees are passed to you. These fees will be debited from your GBP Wallet.</p>

          <p>
            {{ partnerName }} can chose to charge fees to {{ clientName }}. Fees will be credited to your GBP Wallet.
          </p>
          <p>
            The Fees adjusted at the client level will always override any changes at the global. Reset to Global if you
            want to go back to using global fee settings.
          </p>
        </h3>
        <BoxGrid alignH="start">
          <BoxGridItem cols="6" sm="12" md="6" class="text-center">
            <VButton
              class="mt-3"
              @click="resetFeesToDefault"
              :loading="isFormBusy"
              :label="clientName !== null ? 'Reset client fees to global' : 'Reset client fees to default'"
            />
            <BModal ref="resetToDefaultModal" :centered="true" ok-title="Yes" ok-variant="success">
              <div class="text-center">
                <p>All your fees will be made to default.</p>
                <p>Are you sure you want to proceed?</p>
              </div>
            </BModal>
          </BoxGridItem>
          <BoxGridItem cols="6" sm="12" md="6">
            <div class="text-center">
              <VButton class="mt-3 btn btn-primary px-sm-4" @click="resetFeesToZero" :loading="isFormBusy">
                Reset client fees to zero
              </VButton>
            </div>
            <BModal ref="resetToZeromodal" :centered="true" ok-title="Yes" ok-variant="success">
              <div class="text-center">
                <p>By resetting client fees to zero you will still incur fees</p>
                <p>but are choosing not to pass these onto the clients.</p>
                <p>Are you sure you want to proceed?</p>
              </div>
            </BModal>
          </BoxGridItem>
        </BoxGrid>
      </BoxGridBlock>
      <BoxGridItem cols="12">
        <BoxGrid alignH="start">
          <BoxGridBlock cols="12" lg="12">
            <div class="d-md-flex justify-content-between d-sm-block">
              <h3 v-if="clientName !== null">{{ clientName }} {{ typeLabel }} Fee Settings</h3>
              <h3 v-else>Global Client {{ typeLabel }} Fee Settings</h3>
              <AcoTiersModal v-slot="{ showModal }">
                <div class="text-sm-center">
                  <VButton @click="showModal" class="mr-2 btn-stroked">Check ACO tiers</VButton>
                </div>
              </AcoTiersModal>
            </div>

            <div v-if="$mediaQuery.is('smDown')">
              <BModal ref="mobileFeesEditorModal" :title="mobileTitle" modal-class="side-modal" hide-footer>
                <FeesEditor
                  ref="feesEditor"
                  :clientId="clientId"
                  :paymentType="paymentType"
                  :formBusy.sync="isFormBusy"
                  :bankingScheme="bankingScheme"
                  :clientName="clientName"
                />
              </BModal>
              <div class="text-center">
                <VButton class="mt-3 btn btn-primary px-4" @click="openMobileModal(BankingScheme.SWIFT)">
                  Edit SWIFT rail fees
                </VButton>
              </div>
              <div class="text-center">
                <VButton class="mt-3 btn btn-primary px-4" @click="openMobileModal(BankingScheme.LOCAL)">
                  Edit LOCAL rail fees
                </VButton>
              </div>
            </div>
            <FeesEditor
              ref="feesEditor"
              :clientId="clientId"
              :paymentType="paymentType"
              :formBusy.sync="isFormBusy"
              v-else
            />
          </BoxGridBlock>
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>
