import { required } from '@vuelidate/validators';
import { Form, Field } from '../../interfaces';
import { textField, fileField, tagsField, textAreaField } from '../../models';
import { formOverride } from '../../helpers';
import { email } from '../../validators/email';

const myEmail = ({ text }: { text: string }) => !(email as any)(text);

export const emailFieldValidation = {
  tagValidation: [
    {
      classes: 'bad-email',
      rule: myEmail,
      disableAdd: false,
    },
  ],
  includeInvalidTags: true,
  errorMessages: {
    tags: 'One or more of the emails added is incorrect',
  },
};

export const restrictedmailFormFields = (includeFiles = true): Field[] => [
  tagsField('bcc', 'BCC', emailFieldValidation),
  textField('subject', 'Subject', {}, { required }),
  textAreaField('message', 'Email message', {}, { required }),
  ...(includeFiles ? [fileField('documents', 'Documents', { download: true }, {})] : []),
];

export const emailFormFields = (): Field[] => [
  tagsField('to', 'To', emailFieldValidation),
  tagsField('cc', 'CC', emailFieldValidation),
  ...restrictedmailFormFields(),
];

export const emailForm = (name: string, title: string, overrides: any = {}): Form =>
  formOverride(
    {
      name,
      fieldType: 'form',
      state: {
        title,
      },
      fields: emailFormFields(),
    },
    overrides
  );
