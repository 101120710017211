<script lang="ts">
import { PropOptions, PropType, computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { maxLength, required } from '@vuelidate/validators';
import { monthSelectorField, nullField, selectField, textField } from 'ah-common-lib/src/form/models';
import { ifTest, date, afterDateField, optional, requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import { AddressHistoryItem } from 'ah-api-gateways';
import { CompoundValidation } from 'ah-common-lib/src/form/interfaces';
import { PayloadErrorData } from 'ah-requests';
import { EntityAddressHistory } from 'ah-api-gateways';

export const previousAddressesFormsProps = {
  currentAddressResidenceDate: {
    type: String,
  },
  previousAddresses: {
    type: Array as PropType<Partial<AddressHistoryItem>[]>,
  },
  previousAddressesParentClass: {
    type: String,
    default: '',
  },
  showErrorMessage: {
    type: Boolean,
    required: true,
  },
  isSecondarySignatoryLabel: {
    type: [Boolean, String],
    default: false,
  },
  isUboLabel: {
    type: [Boolean, String],
    default: false,
  },
  useThreeColumns: {
    type: [Boolean, String],
    default: false,
  },
  apiErrors: {
    type: Object as PropType<PayloadErrorData<EntityAddressHistory>>,
  },
} satisfies Record<string, PropOptions>;

export const previousAddressesFormsEmitsDef = {
  'update:validation': (_payload: CompoundValidation) => true,
  'update:previousAddresses': (_payload: Partial<AddressHistoryItem>[]) => true,
  'update:currentAddressResidenceDate': (_payload: string) => true,
  'toggle-error-message': (_show: boolean) => true,
} as const;
</script>

<script setup lang="ts">
import {
  getChildModel,
  getState,
  makeCompoundValidation,
  makeFormModel,
  setApiErrorMessages,
  setState,
  updateModel,
} from 'ah-common-lib/src/form/helpers';
import {
  BaseFormModel,
  FieldOptionObj,
  FormDefinition,
  FormEvent,
  FormValidation,
} from 'ah-common-lib/src/form/interfaces';
import { checkAddressHistoryValidity, getAddressHistoryAge, MIN_ADDRESS_HISTORY_AGE } from 'ah-api-gateways';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { toDataModel } from 'ah-common-lib/src/form/helpers';
import { useSettingsStore } from '@/app/store/settingsModule';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import RemoveButton from './RemoveButton.vue';
import { countryHasStates, countryNameFromCC } from 'ah-common-lib/src/helpers/countries';

const props = defineProps(previousAddressesFormsProps);

const emit = defineEmits(previousAddressesFormsEmitsDef);

const settingsStore = useSettingsStore();

// We add an id to the FormDef objects to allow proper re-rendering when removing or adding items
const addressesForms = ref<(FormDefinition & { id: string })[]>([]);

const residingFromFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'residingFromDate',
    fieldType: 'form',
    fields: [
      monthSelectorField(
        'residingFrom',
        props.isSecondarySignatoryLabel !== false
          ? 'When did the Signatory move to this address?'
          : props.isUboLabel !== false
          ? 'When did the UBO move to this address?'
          : 'When did you move to this address?',
        {
          fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-7',
          placeholder: 'Pick a Date',
          required: true,
          errorMessages: {
            required: 'Required field',
          },
        },
        {
          required,
          date: ifTest(date, (val) => val instanceof Date),
        }
      ),
    ],
  }),
  validation: null,
});

const hasSufficientAddressHistory = computed(() => {
  const addressHistoryAge = getAddressHistoryAge({
    currentAddress: { residingFrom: props.currentAddressResidenceDate },
    previousAddresses: props.previousAddresses,
  });

  return addressHistoryAge >= MIN_ADDRESS_HISTORY_AGE;
});

const validation = computed(() =>
  makeCompoundValidation<BaseFormModel>(
    [residingFromFormDef.validation, ...addressesForms.value.map((formDef) => formDef.validation)].filter(
      (i) => !!i
    ) as FormValidation[]
  )
);

const addressHistoryValidity = computed(() =>
  checkAddressHistoryValidity({
    currentAddress: {
      residingFrom: props.currentAddressResidenceDate,
    },
    previousAddresses: props.previousAddresses,
  })
);

function makePreviousAddressFormDef(): FormDefinition & { id: string } {
  const formDef: FormDefinition = {
    form: makeFormModel({
      name: 'addressHistory',
      fieldType: 'form',
      fields: [
        textField(
          'addressLine',
          'Address Line 1',
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-12',
            required: true,
            showRequiredMarkers: true,
            errorMessages: { maxLength: 'Address Line1 cannot have more than 150 characters.' },
          },
          { maxLength: maxLength(150), required: requiredIfStateValue('addressLine') }
        ),
        textField(
          'city',
          'City',
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-6',
            required: true,
            showRequiredMarkers: true,
            errorMessages: { maxLength: 'City cannot have more than 50 characters.' },
          },
          { maxLength: maxLength(50), required: requiredIfStateValue('city') }
        ),
        textField(
          'stateOrProvince',
          'State/Province/County',
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-6',
            required: true,
            showRequiredMarkers: true,
            errorMessages: { maxLength: 'State/Province cannot have more than 50 characters.' },
          },
          { maxLength: maxLength(50), required: requiredIfStateValue('stateOrProvince') }
        ),
        textField(
          'postalCode',
          'Postcode / ZIP code',
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-6',
            required: true,
            errorMessages: { maxLength: 'Postcode cannot have more than 10 digits.' },
            showRequiredMarkers: true,
          },
          { maxLength: maxLength(10), required: requiredIfStateValue('postalCode') }
        ),
        selectField(
          'countryCode',
          'Country',
          [],
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-6',
            placeholder: 'Select One',
            required: true,
            showRequiredMarkers: true,
          },
          { required: requiredIfStateValue('countryCode') }
        ),
        nullField('monthSeparator', {
          fieldWrapperClass: 'col col-12',
        }),
        monthSelectorField(
          'residingFrom',
          'From',
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-6',
            placeholder: 'Pick a Date',
            required: true,
            errorMessages: {
              required: 'Required field',
            },
          },
          {
            required,
            date: ifTest(date, (val) => val instanceof Date),
          }
        ),
        monthSelectorField(
          'residingTo',
          'To',
          {
            fieldWrapperClass: props.useThreeColumns !== false ? 'col col-4 px-4' : 'col col-6',
            placeholder: 'Pick a Date',
            required: true,
            errorMessages: {
              required: 'Required field',
              validDateRange: '"To" date must be after "From" date',
            },
          },
          {
            required,
            date: ifTest(date, (val) => val instanceof Date),
            validDateRange: optional(afterDateField('residingFrom')),
          }
        ),
      ],
    }),
    validation: null,
  };
  const countryCodeField = getChildModel(formDef.form, 'countryCode')!;

  setState(
    countryCodeField,
    'options',
    settingsStore.allCountries.map((country) => ({
      value: country.cc,
      label: country.name,
    }))
  );

  return { ...formDef, id: generateUUID() };
}

function addPreviousAddress() {
  // FIXME Typescript complains with Validation typing
  addressesForms.value.push(makePreviousAddressFormDef() as any);
  emit('toggle-error-message', false);
}

function removePreviousAddress(index: number) {
  addressesForms.value.splice(index, 1);
  emit(
    'update:previousAddresses',
    addressesForms.value.map((formDef) => toDataModel(formDef.form))
  );
}

function onUserFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    setState(event.model, 'errors', []);

    emit(
      'update:previousAddresses',
      addressesForms.value.map((formDef) => toDataModel(formDef.form))
    );
  }
}

function onResidingFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emit('update:currentAddressResidenceDate', residingFromFormDef.form.residingFrom);
  }
}

onBeforeMount(() => {
  settingsStore.loadCountries(true).then(() => {
    addressesForms.value.forEach((formDef) => {
      const countryCodeField = getChildModel(formDef.form, 'countryCode')!;

      setState(
        countryCodeField,
        'options',
        settingsStore.allCountries.map((country) => ({
          value: country.cc,
          label: country.name,
        }))
      );
    });
  });
});

watch(validation, () => emit('update:validation', validation.value), { immediate: true });

watch(
  () => props.currentAddressResidenceDate,
  () => {
    updateModel(residingFromFormDef.form, { residingFrom: props.currentAddressResidenceDate });
  },
  { immediate: true }
);

watch(
  () => props.previousAddresses,
  () => {
    const newFormDefs: FormDefinition[] = [];
    props.previousAddresses?.forEach((previousAddressesItem, index) => {
      const formDef = addressesForms.value[index] ?? makePreviousAddressFormDef();
      updateModel(formDef.form, previousAddressesItem, undefined, true);
      setupAddressForm(previousAddressesItem, formDef);
      newFormDefs.push(formDef);
    });

    addressesForms.value = newFormDefs as any;
  },
  { immediate: true }
);

watch(
  () => props.apiErrors,
  (newVal, oldVal) => {
    if (newVal?.occurrenceId !== oldVal?.occurrenceId) {
      addressesForms.value.forEach((formDef, index) => {
        setState(formDef.form, 'errors', [], true);
        const errors = props.apiErrors?.fields?.previousAddresses?.fields?.[index];
        errors && setApiErrorMessages(errors, formDef.form);
      });
    }
  },
  { immediate: true }
);

function setupAddressForm(address: Partial<AddressHistoryItem>, formDef: FormDefinition) {
  const stateOrProvinceField = getChildModel(formDef.form, 'stateOrProvince')!;
  const countryCode = address?.countryCode || '';

  const countryName = countryNameFromCC(countryCode);
  if (countryName && formDef.form.countryName !== countryName) {
    formDef.form.countryName = countryName;
  }

  if (countryHasStates(countryCode)) {
    setState(stateOrProvinceField, 'fieldType', 'select');
    settingsStore.loadISOCodes({ countryCode }).then((states) => {
      // Try to keep the value of State, searching by state code OR state name, among the available options
      if (formDef.form.stateOrProvince) {
        const state = states.find(
          (item) => item.subdivisionName === formDef.form.stateOrProvince || item.code === formDef.form.stateOrProvince
        );
        formDef.form.stateOrProvince = state ? state.code : null;
      }
      setState(
        stateOrProvinceField,
        'options',
        states.map((s) => ({
          value: s.code,
          label: s.subdivisionName,
        }))
      );
    });
  } else if (getState(stateOrProvinceField, 'fieldType') !== 'text') {
    // Try to keep the value of selected State, if any, converting to label for free text
    const options = getState(stateOrProvinceField, 'options', []) as FieldOptionObj[];
    const selected = options.find((option) => option.value === formDef.form.stateOrProvince);
    formDef.form.stateOrProvince = selected ? selected.label : null;
    setState(stateOrProvinceField, 'fieldType', 'text');
  }
}
</script>

<template>
  <div>
    <ValidatedForm
      :fm="residingFromFormDef.form"
      :validation.sync="residingFromFormDef.validation"
      @form-event="onResidingFormEvent"
    />
    <div v-if="previousAddresses?.length || !hasSufficientAddressHistory" :class="previousAddressesParentClass">
      <div class="previous-address">
        <h3 class="previous-address-title">Previous Address(es)</h3>
        <div class="mb-3">
          <p class="address-requirement-info-block">
            <IconInfoCircle class="info-icon mr-2" />
            <span>Please provide all addresses you have lived at in the past 3 years</span>
          </p>

          <div v-for="(formDef, index) in addressesForms" :key="formDef.id" class="address-form">
            <div class="d-flex justify-content-between my-4">
              <h4 class="previous-address-subtitle my-0 mt-1">Previous Address N&deg;{{ index + 1 }}</h4>
              <RemoveButton
                class="remove-btn-review-styling"
                :removeButtonText="'Remove Address'"
                @remove="removePreviousAddress(index)"
              />
            </div>

            <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" @form-event="onUserFormEvent">
              <template #addressHistory.monthSeparator:before>
                <span class="previous-address-date-label">When did you reside at this address?</span>
              </template>
            </ValidatedForm>
          </div>

          <div class="text-center mt-2">
            <VButton
              @click="addPreviousAddress"
              :class="[
                'add-address-btn',
                { 'error-styling': showErrorMessage && (!addressHistoryValidity.age || !addressHistoryValidity.gaps) },
              ]"
              >Add Previous Address</VButton
            >
            <template v-if="showErrorMessage">
              <div class="error-message mt-2" v-if="!addressHistoryValidity.age">
                Please provide addresses spanning back at least 3 years
              </div>
              <div class="error-message mt-2" v-if="!addressHistoryValidity.gaps">
                Please ensure address dates contain no gaps
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-message {
  @include themedTextColor($color-danger);
  font-size: $font-size-base;
}

.error-styling {
  border: 1px solid getColor($color-danger);
}

.add-address-btn {
  border-radius: 0.375em;
  margin: 1em 0;
  padding: 0.375em 10.125em;
  background: $common-color-white;
  @include themedTextColor($color-text-black);
  box-shadow: 0px 1px 2px 1px rgba(3, 7, 18, 0.12);

  &:hover,
  &:focus {
    background: getColor($color-main-bg) !important;
  }
}
</style>
