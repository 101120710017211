<script lang="ts" setup>
import { RateAlertSetting, RateAlertInequalityType } from 'ah-api-gateways';
import RateAlertSettingsEditModal from '@/app/components/settings/notifications/RateAlertSettingsEditModal.vue';
import { useSettingsStore } from '@/app/store/settingsModule';
import { computed, PropType } from 'vue';

const props = defineProps({
  rateAlert: {
    type: Object as PropType<RateAlertSetting>,
    required: true,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
});

const settingsStore = useSettingsStore();

const rateAlertFromCurrency = computed(() => props.rateAlert.currencyPair.substr(0, 3));

const rateAlertToCurrency = computed(() => props.rateAlert.currencyPair.substr(3));

const rateAlertRate = computed(() => props.rateAlert.rate.toFixed(settingsStore.ratePrecision));

const rateAlertInequalitySymbol = computed(() =>
  props.rateAlert.inequality === RateAlertInequalityType.HIGHER_THAN ? '>' : '<'
);
</script>

<template>
  <VRow class="align-items-center single-rate-alert-settings">
    <VCol class="rate-alert-edit-col">
      <span class="edit-icon-block">
        <RateAlertSettingsEditModal
          :rateAlert="rateAlert"
          @update:rateAlert="$emit('update:rateAlert', $event)"
          @delete-rate-alert="$emit('delete-rate-alert')"
          v-slot="{ show }"
        >
          <span @click="readonly === false && show()"
            ><IconPencil :class="['edit-icon', { disabled: readonly !== false }]"
          /></span>
        </RateAlertSettingsEditModal>
      </span>
    </VCol>
    <VCol class="rate-alert-pair-col"> {{ rateAlertFromCurrency }} -> {{ rateAlertToCurrency }} </VCol>
    <VCol class="rate-alert-rate-col">
      {{ rateAlertInequalitySymbol }} {{ rateAlertRate }} {{ rateAlert.currencyPair }}
    </VCol>
  </VRow>
</template>

<style lang="scss">
.edit-icon-block {
  cursor: pointer;

  .edit-icon {
    @include themedTextColor($color-text-secondary);

    &:not(.disabled):hover {
      @include themedTextColor($color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
</style>
