<script lang="ts" setup>
import AppBanner from 'ah-common-lib/src/common/components/AppBanner.vue';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import { computed, onBeforeMount } from 'vue';
import { useAhTradesState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const tradeState = useAhTradesState();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadProfile') {
      loadCurrencyProfile();
    }
  },
});

function loadCurrencyProfile() {
  requestManager.manager.newPromise('loadProfile', tradeState.store.useSettingsStore().loadClientLimitsProfile());
}

onBeforeMount(() => {
  loadCurrencyProfile();
});

const profile = computed(() => tradeState.store.useSettingsStore().clientLimitsProfile);

const tradingDeskPhoneNumber = computed(() => tradeState.theme.tradingDeskPhoneNumber);

const tradingDeskEmail = computed(() => tradeState.theme.tradingDeskEmail);

const tradingDeskEmailUrl = computed(() => {
  return 'mailto:' + tradingDeskEmail.value;
});
</script>

<template>
  <AppBanner v-if="!requestManager.manager.anyPending && !profile" class="text-center warning">
    <p class="mb-0">No Limits Profile has been provided for you yet, so trading is still not possible.</p>
    <p class="mb-0">
      Please contact our trading desk on {{ getPhoneNumber(tradingDeskPhoneNumber) }} or
      <a id="email" :href="tradingDeskEmailUrl" target="_blank">{{ tradingDeskEmail }}</a> .
    </p>
  </AppBanner>
</template>

<style lang="scss" scoped>
.app-banner {
  &.warning {
    @include themedProp(
      'background',
      getColorWithAlpha($color-yellow-highlight, 0.15),
      getColorWithAlpha($color-dark-yellow-highlight, 0.15)
    );
    @include themedPropColor('border-bottom-color', $color-yellow-highlight);
  }
}
</style>
