import { HOUR } from '../constants/time';
import { RequestManager, RequestState } from 'ah-requests';
import { generateUUID } from './uuid';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * CachedItem is kept as a data-only class to avoid issues when hydrating the Vuex data in persistent storage
 * (it is only a class to leverage the constructor)
 *
 * Only STATIC methods are available
 *
 * Attention:
 * CachedItem returns loaded item by reference for performance reasons.
 * It is the recipient's responsibility to clone/prevent unwanted edits
 */
export class CachedItem<T> {
  public loadState: RequestState = 'idle';
  public loadDate: string = '';
  public item: T | null = null;

  protected static reqManager = new RequestManager();

  constructor(public readonly cacheKey: string = generateUUID(), public readonly cacheDuration: number = HOUR * 2) {}

  static isCachedItemExpired(item: CachedItem<any>) {
    const loadDate = new Date(item.loadDate || '').valueOf();

    return !(item.item && !isNaN(loadDate) && Date.now() - loadDate < item.cacheDuration);
  }

  static expireCachedItem(item: CachedItem<any>) {
    item.loadDate = '';
  }

  static clearCachedItem(item: CachedItem<any>) {
    this.reqManager.cancel(item.cacheKey);
    item.loadDate = '';
    item.loadState = 'idle';
    item.item = null;
  }

  static setCachedItem<T>(item: CachedItem<T>, value: T): void {
    item.item = value;
    item.loadDate = new Date().toISOString();
    item.loadState = 'idle';
  }

  static loadCachedItem<T>(item: CachedItem<T>, request: Observable<T>, force = false): Promise<T> {
    if (!force && !CachedItem.isCachedItemExpired(item)) {
      return Promise.resolve(item.item!);
    }

    item.loadState = 'pending';

    return this.reqManager
      .currentOrNew(
        item.cacheKey,
        request.pipe(
          tap((result) => CachedItem.setCachedItem(item, result)),
          catchError((e) => {
            item.loadState = 'error';
            throw e;
          })
        )
      )
      .toPromise();
  }

  static loadStoreCachedItem<K extends string, T extends { [Property in K]: CachedItem<B> }, B>(
    store: T,
    itemKey: K,
    request: Observable<B>,
    force?: boolean
  ) {
    return CachedItem.loadCachedItem(store[itemKey], request, force).then((item) => {
      store[itemKey] = { ...store[itemKey] };
      return item;
    });
  }
}
