import { generateUID } from '../helpers/uuid';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { ActionRequestSyncMessage, isActionRequestMessage, SyncMessage, SyncMessageType } from './syncMessage';

/**
 * Implements a cross-tab sync service allowing to avoid race conditions
 *
 * Channel can send action requests and receive responses
 */
export class TabSync {
  private channel = new BroadcastChannel('syncService', { webWorkerSupport: false });

  private elector = createLeaderElection(this.channel);

  /**
   * Leader status is not exposed, as it might lead to issues with race conditions otherwise
   */
  private leader = false;

  private onIsLeaderListeners: (() => void)[] = [];

  private triggerListeners: { [key: string]: ((message: SyncMessage) => Promise<void>)[] } = {};

  readonly ready!: Promise<void>;

  constructor() {
    // We add a timeout so that leader status is set when ready is
    this.ready = this.elector.applyOnce().then(() => new Promise((resolve) => setTimeout(() => resolve())));
    (global as any)['VUE_IS_TAB_LEADER'] = false;
    this.elector.awaitLeadership().then(() => {
      this.leader = true;
      (global as any)['VUE_IS_TAB_LEADER'] = true;
      this.onIsLeaderListeners.forEach((i) => i());
      return this.leader;
    });

    this.channel.addEventListener('message', (message) => this.handleChannelMessage(message));
  }

  private async handleChannelMessage(message: SyncMessage) {
    if (
      isActionRequestMessage(message) &&
      (this.leader || !message.payload.leaderOnly) &&
      this.triggerListeners[message.payload.name]
    ) {
      Promise.all(this.triggerListeners[message.payload.name].map((f) => f(message)))
        .then(
          () => true,
          () => false
        )
        .then((success) => {
          if (this.leader) {
            this.postMessage({
              type: SyncMessageType.actionResponse,
              payload: {
                ...message.payload,
                success,
              },
            });
          }
        });
    }
  }

  public get isLeaderPromise() {
    return this.ready.then(() => this.leader);
  }

  public onIsLeader(listener: () => void) {
    this.onIsLeaderListeners.push(listener);
    return () => {
      this.onIsLeaderListeners.splice(this.onIsLeaderListeners.indexOf(listener), 1);
    };
  }

  /**
   * Registers an trigger response that will be called whenever this tab receives a given message
   *
   * If this tab is the leader, a response will be sent pending on success of triggers
   * Multiple triggers can be registered for the same event - all triggers will need to succeed for the response to be a success
   */
  public onActionTrigger(name: string, listener: (message: SyncMessage) => Promise<void> | void): () => void {
    this.triggerListeners[name] = this.triggerListeners[name] || [];
    const listenerPromise = (message: SyncMessage) => Promise.resolve(listener(message));
    this.triggerListeners[name].push(listenerPromise);
    return () => {
      this.triggerListeners[name]?.splice(this.triggerListeners[name].indexOf(listenerPromise), 1);
    };
  }

  /**
   * Trigger an action in the leader tab, and await confirmation of success
   */
  public async awaitLeaderAction(name: string, data?: any): Promise<void> {
    if (await this.isLeaderPromise) {
      return Promise.reject('Calling leader action from leader window');
    }
    const id = generateUID(8);
    this.postMessage(<ActionRequestSyncMessage>{
      type: SyncMessageType.actionRequest,
      payload: { name, id, leaderOnly: true, includeSelf: false, data },
    });
    return new Promise((resolve) => {
      const listener = (content: SyncMessage) => {
        if (content.type === SyncMessageType.actionResponse && content.payload.id === id) {
          resolve();
          this.channel.removeEventListener('message', listener);
        }
      };
      this.channel.addEventListener('message', listener);
    });
  }

  public triggerAction(name: string, data?: any, includeSelf = false) {
    this.postMessage(<ActionRequestSyncMessage>{
      type: SyncMessageType.actionRequest,
      payload: { name, id: generateUID(8), leaderOnly: false, includeSelf, data },
    });
  }

  public postMessage(message: SyncMessage) {
    this.channel.postMessage(message);
    if (message.payload.includeSelf) {
      this.handleChannelMessage(message);
    }
  }
}
