<script setup lang="ts">
import PaymentsListBlock from '@/app/components/payments/PaymentsListBlock.vue';
import PaymentsCreateEditModal from 'ah-payments/src/components/modals/PaymentsCreateEditModal.vue';
import { useAuthStore } from '@/app/store/authStore';
import { AuthorityType } from 'ah-api-gateways';
import { ref } from 'vue';

const authStore = useAuthStore();
const canExport = authStore.hasAuthorities(AuthorityType.EXPORT_PAYMENTS);

const paymentListBlock = ref<InstanceType<typeof PaymentsListBlock> | null>(null);

function loadData() {
  paymentListBlock.value?.loadData();
}
</script>

<template>
  <div class="padded-space" id="my-payments-view">
    <div class="d-flex align-items-center">
      <h2>My Payments</h2>
      <PaymentsCreateEditModal v-slot="{ showModal }" @update-payment="loadData">
        <VButton @click="showModal" class="btn-primary mr-2 ml-4 mb-3">New Payment</VButton>
      </PaymentsCreateEditModal>
    </div>
    <PaymentsListBlock ref="paymentListBlock" :showExport="canExport" documentsTitle="My Payments" />
  </div>
</template>
