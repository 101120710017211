import { useCurrencyFormatter } from 'ah-common-lib/src/number/useCurrencyFormatter';
import { Ref, computed } from 'vue';
import { useAhTradesState } from '..';

export function useTradesCurrencyFormatter(options: { currency: Ref<string> }) {
  const settingsStore = useAhTradesState().store.useSettingsStore();

  return useCurrencyFormatter({
    currency: options.currency,
    currencyDataReference: computed(() => settingsStore.currencies),
  });
}
