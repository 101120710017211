<template>
  <svg width="16.9" height="22.65" viewBox="0 0 16.9 22.65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M0,4.83a.76.76,0,0,0,.11.36h0a.7.7,0,0,0,.13.16s0,0,0,.05L5.35,9.49a.73.73,0,0,0,.44.15.71.71,0,0,0,.55-.26.7.7,0,0,0-.11-1L2.68,5.53H16.2a.7.7,0,0,0,0-1.4H2.66L6.23,1.24a.69.69,0,0,0,.11-1,.69.69,0,0,0-1-.1L.29,4.24l-.06.07-.08.1,0,.06A.73.73,0,0,0,0,4.83Z"
        />
        <path
          fill="currentColor"
          d="M16.9,17.83a.63.63,0,0,0-.11-.35l-.05-.07-.07-.09-.06-.07-5.06-4.09a.71.71,0,0,0-1,.11.7.7,0,0,0,.11,1l3.57,2.88H.7a.7.7,0,0,0,0,1.4H14.23L10.67,21.4a.7.7,0,0,0,.44,1.25.68.68,0,0,0,.44-.16l5.06-4.08,0-.05a1,1,0,0,0,.13-.16h0A.73.73,0,0,0,16.9,17.83Z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
