import { ToRefs, ExtractPropTypes, PropOptions, ref, customRef } from 'vue';
import { useVModel } from '@vueuse/core';

/**
 * Helper types to define a ManagedComposable
 *
 * This is a composable that:
 * - Exposes a known props method that can be used to augment the props of the component that uses the composable
 * - Exposes known props as options (via a refs object) which can override any props given
 * - Uses emit object to control any emit augmented, and emits any prop change via VModel bindings
 */

export type ManagedComposableOptions<Props extends Record<string, PropOptions>, EmitType = any> = {
  /**
   * Props object, to map to refs
   *
   * If adding props:
   *    - `emit` is mandatory
   *    - props will be mapped to VModels
   *    - any refs directly defined in `refs` will overwrite this
   *    - all required refs must be defined, between `props` and `refs` objects
   */
  props?: Partial<ExtractPropTypes<Props>>;
  /**
   * Refs object
   *
   * If adding refs:
   *    - any refs defined will overwrite the equivalent `props` property, if set
   *    - all required refs must be defined, between `props` and `refs` objects
   */
  refs?: Partial<ToRefs<ExtractPropTypes<Props>>>;
  emit?: EmitType;
  // Whether to use passive VModel - defaults to true, unless specifically set as `false`
  passiveVModel?: boolean;
};

/**
 * Build refs object from ManagedComponent Options
 *
 * Given a ManagedComposableOptions object, will create managed refs,
 * where any refs defined using options will override props
 *
 * Will either use useVModel, or a managed ref that emits an update event - regardless, any changes to these refs will trigger updates
 *
 * This is meant for internal use, within a composable
 */
export function managedComposableRefs<T extends Record<string, PropOptions>>(
  propsObject: T,
  options: ManagedComposableOptions<T>
): Required<ToRefs<ExtractPropTypes<T>>> {
  const out: Required<ToRefs<ExtractPropTypes<T>>> = {} as Required<ToRefs<ExtractPropTypes<T>>>;

  if (options.props && !options.emit) {
    throw 'When using props must also provide emit';
  }

  Object.keys(propsObject).forEach((key) => {
    const typedKey = key as keyof ExtractPropTypes<T>;
    const hasRef = options.refs && options.refs[typedKey];
    const hasProp = options.props && options.props.hasOwnProperty(typedKey);

    if (!hasRef && hasProp) {
      // Passing `passive` option as any because otherwise Typescript complains, as it should be true OR false
      // (NOT a boolean, as the typings use two separate overloaded function signatures for each primitive)
      out[typedKey] = useVModel(options.props!, typedKey, options.emit!, {
        passive: (options.passiveVModel !== false) as any,
      }) as any;
    } else if (hasRef || !propsObject[typedKey].required) {
      const keyRef = options.refs && options.refs[typedKey] ? options.refs[typedKey] : ref();
      (out as any)[typedKey] = customRef(() => ({
        set(value) {
          keyRef!.value = value;
          options.emit && options.emit(`update:${key}`, value);
        },
        get() {
          return keyRef!.value;
        },
      }));
    } else if (propsObject[typedKey].required) {
      throw `'${String(typedKey)}' is a required value, and has not been provided in either 'props' or 'refs'`;
    }
  });

  return out;
}

/**
 * Helper function to expose only Props that are not already overriden by the given refs for a ManagedComposable
 */
export function computeExposedProps<T extends Record<string, PropOptions>>(
  propsObject: T,
  refs: Partial<ToRefs<ExtractPropTypes<T>>>
): Partial<T> {
  const out: Partial<T> = { ...propsObject };

  Object.keys(propsObject).forEach((key) => {
    const typedKey = key as keyof ExtractPropTypes<T>;
    if (refs && refs[typedKey]) {
      delete out[typedKey];
    }
  });

  return out;
}
