import { NotificationSettingConfig, PaginatedQuery, PaginatedResponse, RateAlertSetting } from '../models';
import { HttpOptions, HttpService } from 'ah-requests';
import { NotificationSettingsService } from './notificationSettingsService';

export class ClientNotificationSettingsService extends NotificationSettingsService {
  constructor(http: HttpService, baseUrl: string) {
    super(http, `${baseUrl}clients/`, 'clientNotificationSettingsService');
  }

  public getRateAlert(parentId: string, rateAlertId: string) {
    return this.get<RateAlertSetting>(`${this.baseUrl}${parentId}/settings/rate-alerts/${rateAlertId}`);
  }

  public listRateAlerts(parentId: string, query: PaginatedQuery & { currencyPair?: string }) {
    return this.get<PaginatedResponse<RateAlertSetting>>(`${this.baseUrl}${parentId}/settings/rate-alerts`, {
      axiosConfig: {
        params: query,
      },
    });
  }

  public deleteRateAlert(parentId: string, rateAlertId: string) {
    return this.delete<void>(`${this.baseUrl}${parentId}/settings/rate-alerts/${rateAlertId}`);
  }

  public editRateAlert(parentId: string, rateAlert: Partial<RateAlertSetting> & Pick<RateAlertSetting, 'id'>) {
    return this.put<RateAlertSetting>(`${this.baseUrl}${parentId}/settings/rate-alerts/${rateAlert.id}`, rateAlert);
  }

  public createRateAlert(parentId: string, rateAlert: Partial<Omit<RateAlertSetting, 'id'>>) {
    return this.post<RateAlertSetting>(`${this.baseUrl}${parentId}/settings/rate-alerts`, rateAlert);
  }

  public listNotificationConfig(options: HttpOptions<NotificationSettingConfig[]> = {}) {
    return this.get<NotificationSettingConfig[]>(`${this.baseUrl}settings/notifications/spec`, { options });
  }
}
