import { render, staticRenderFns } from "./AccountVerificationForm.vue?vue&type=template&id=03830064&scoped=true"
import script from "./AccountVerificationForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./AccountVerificationForm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AccountVerificationForm.vue?vue&type=style&index=0&id=03830064&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03830064",
  null
  
)

export default component.exports