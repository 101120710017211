import { PaymentType, paymentTypeLabels } from './paymentType';
import { PaymentState, paymentStateLabels } from './paymentState';
import { PaymentRail, paymentRailLabels } from './paymentMethod';
import type { UpdatableObject, VersionedObject } from '../../models';
import { FeeChargeType } from '../feesAndCharges/fundingCharge';

export interface Payment extends UpdatableObject, VersionedObject {
  id: string;
  type: PaymentType;
  currency: string;
  amount: number;
  beneficiaryId: string;
  reason: string;
  reference: string;
  beneficiaryName: string;
  referenceNumber: string;
  chargeType: FeeChargeType;
  executionDate: string;
  walletId: string;
  purposeCode: string;
  tradeId: string;
  description?: string;
}

export interface CreatePaymentRequest {
  walletId: string;
  amount: number;
  beneficiaryId: string;
  reason: string;
  chargeType: FeeChargeType;
  reference?: string;
  executionDate?: string;
  tradeId?: string;
  purposeCode?: string;
}

export const paymentTypeToHuman = (paymentType: PaymentType) => {
  if (!paymentType) {
    return '';
  }
  return paymentTypeLabels[paymentType];
};

export const paymentStateToHuman = (paymentState: PaymentState) => {
  if (!paymentState) {
    return '';
  }
  return paymentStateLabels[paymentState];
};

export const paymentRailToHuman = (paymentRail: PaymentRail) => {
  if (!paymentRail) {
    return '';
  }
  return paymentRailLabels[paymentRail];
};
