import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const beneficiaryTableFields: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
    sortable: true,
  },
  { header: 'Status', key: 'status', sortable: true, colStyle: { width: '7em' } },
  { header: 'Country', key: 'countryCode', sortable: true, sortKey: 'addressCountryName' },
  { header: 'Currency', key: 'currency', sortable: true },
];

export const selectableBeneficiaryTableFields: TableFieldDefinition[] = [
  { header: '', key: 'selectRadio', colStyle: { width: '3em' } },
  {
    header: 'Name',
    key: 'name',
    sortable: true,
  },
  { header: 'Method', key: 'paymentRail', sortable: true },
  { header: 'Currency', key: 'currency', sortable: true },
];

export const shortBeneficiaryTableFields: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
];
