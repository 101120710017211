<script setup lang="ts">
import OptionCreator from 'ah-trades/src/components/OptionCreator.vue';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { useRouter } from 'vue-router/composables';

const router = useRouter();

const props = defineProps<{
  clientId?: string;
}>();

function onTradeCreated() {
  if (props.clientId) {
    router.replace('.');
  }
}
</script>

<template>
  <div class="padded-space" id="trade-view">
    <OnBehalfOf :clientId="clientId">
      <OptionCreator @trade-created="onTradeCreated" />
    </OnBehalfOf>
  </div>
</template>
