<script setup lang="ts">
import AddMoneyModal from 'ah-wallets/src/components/AddMoneyModal.vue';
import CreateTradeModal from '../../modals/CreateTradeModal.vue';
import { AuthorityType } from 'ah-api-gateways';
import { computed, reactive } from 'vue';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useAhTradesState } from '../../..';
import { VButton } from 'ah-common-lib/src/common/components';

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const state = reactive<{
  allowedSellCurrencies: string[];
  currencies: string[];
}>({
  allowedSellCurrencies: [],
  currencies: ['GBP', 'EUR', 'USD'],
});

const canSeeFundingDetails = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_FUNDING_DETAILS)
);

const openTradeAllowed = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.OPEN_TRADE)
);

const requestPricesAllowed = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.REQUEST_PRICES)
);

function loadTradeableCurrencies() {
  tradeState.store
    .useSettingsStore()
    .loadTradeableCurrencies()
    .then((currencies) => {
      state.allowedSellCurrencies = currencies
        .map((currencyObj) => currencyObj.currency)
        .filter((currencyObj) => {
          return !state.currencies.includes(currencyObj);
        });
    });
}

loadTradeableCurrencies();
</script>

<template>
  <div class="d-flex" style="width: 100%">
    <AddMoneyModal :allowedCurrencies="state.currencies" v-if="canSeeFundingDetails" v-bind="$attrs" v-slot="{ show }">
      <VButton
        @click="show"
        class="action new-xhedge-styles mr-4"
        label="Add funds to a GBP/EUR/USD wallet"
        roundedBorder
      />
    </AddMoneyModal>
    <CreateTradeModal
      title="Convert from another currency wallet"
      v-slot="{ show }"
      :allowedBuyCurrencies="['GBP']"
      :allowedSellCurrencies="state.allowedSellCurrencies"
      forceKeep
      forceImmediateSpot
      syncTradeFunds
      v-if="openTradeAllowed && requestPricesAllowed"
      v-bind="$attrs"
    >
      <VButton
        @click="show"
        class="action new-xhedge-styles"
        label="Convert from another currency wallet"
        roundedBorder
      />
    </CreateTradeModal>
  </div>
</template>
