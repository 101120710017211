<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { ref } from 'vue';

const confirmModal = ref<InstanceType<typeof BModal>>();

defineProps({
  buttonTitle: {
    type: String,
    default: 'Remove',
  },
  modalText: {
    type: String,
    default: 'Confirm?',
  },
  noButton: {
    type: [Boolean, String],
    default: false,
  },
});

function showModal() {
  confirmModal.value?.show();
}
</script>

<template>
  <span>
    <BModal
      :centered="true"
      ok-title="Confirm"
      ok-variant="danger"
      cancel-variant="secondary"
      body-class="py-5 px-5"
      footer-class="justify-content-start pl-5 pb-5"
      ref="confirmModal"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot name="modal-message">
        {{ modalText }}
      </slot>
    </BModal>
    <slot v-bind="{ showModal }" v-if="noButton === false">
      <VButton blurOnClick @click="showModal">{{ buttonTitle }}</VButton>
    </slot>
  </span>
</template>
