import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const paymentScheduleTableFields: TableFieldDefinition[] = [
  {
    header: 'Date',
    key: 'date',
  },
  {
    header: 'Customer Pays',
    key: 'sellAmount',
    tdClass: 'text-right',
    thClass: 'text-right',
  },
  {
    header: 'You Receive',
    key: 'buyAmount',
    tdClass: 'text-right',
    thClass: 'text-right',
  },
];
