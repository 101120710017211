import { ref, provide, defineComponent, PropType, watch, computed, h } from 'vue';
import { Client } from 'ah-api-gateways';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from './constants';
import LoadingOverlay from '../common/components/overlays/LoadingOverlay.vue';
import { useRequestManager } from '../requestManager/useRequestManager';
import { Observable } from 'rxjs';

interface OnBehalfOfOptions {
  loadClient: (clientId: string) => Observable<Client>;
}

const options: OnBehalfOfOptions = {
  loadClient: () => {
    throw 'On Behalf Of Options must be set!';
  },
};

export function setOnBehalfOfOptions(opts: OnBehalfOfOptions) {
  options.loadClient = opts.loadClient;
}

export default defineComponent({
  props: {
    client: {
      type: Object as PropType<Client>,
      required: false,
    },
    clientId: {
      type: String,
      required: false,
    },
  },
  emits: {
    'update:client'(payload: Client) {
      return !!payload;
    },
  },
  setup(props, { slots, emit }) {
    const clientRef = ref<Client | null>(null);

    const reqManager = useRequestManager({
      exposeToParent: true,
      onRetryFromParentManager(k: string) {
        if (k === 'loadOboClient') {
          loadClient();
        }
      },
    });

    const loadingClient = computed(() => reqManager.manager.requestStates.loadOboClient === 'pending');

    provide(ON_BEHALF_OF_CLIENT_INJECT_KEY, clientRef);

    function loadClient() {
      if (props.clientId) {
        reqManager.manager
          .sameOrCancelAndNew('loadOboClient', options.loadClient(props.clientId))
          .subscribe((client) => {
            clientRef.value = client;
            emit('update:client', client);
          });
      }
    }

    watch(() => props.clientId, loadClient, { immediate: true });

    watch(
      () => props.client,
      (val) => {
        if (val) {
          clientRef.value = val;
        }
      },
      { immediate: true }
    );

    return () => {
      if (loadingClient.value) {
        return slots.loading ? slots.loading() : h(LoadingOverlay, { props: { loading: true, 'no-wrap': '' } });
      } else {
        return slots.default ? slots.default() : (null as any);
      }
    };
  },
});
