<script setup lang="ts">
import { makeFormModel, toDataModel, updateModel, batchSetState } from 'ah-common-lib/src/form/helpers';
import { FxMargins, Client, HedgingInstrumentsSettingsProps, FxMarginsConfiguration } from 'ah-api-gateways';
import { basisPointsField } from 'ah-common-lib/src/form/models';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { computed, onMounted, PropType, reactive, watch } from 'vue';

const props = defineProps({
  model: {
    type: Object as PropType<FxMargins>,
    required: true,
  },
  client: {
    type: Object as PropType<Client>,
    required: true,
  },
  profitKey: {
    type: String as PropType<HedgingInstrumentsSettingsProps>,
    required: true,
  },
  title: {
    type: String,
  },
  unit: {
    type: String,
    required: true,
  },
  minValue: {
    type: Number,
    required: true,
  },
  maxValue: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits({
  'update:model': (_model: FxMarginsConfiguration) => true,
  'update:validation': (_validation: FormValidation) => true,
});

const formModel = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'profits',
    fieldType: 'form',
    fields: [basisPointsField('profitMargin', 'Profit Markup', { minValue: props.minValue, maxValue: props.maxValue })],
  }),
  validation: null,
});

onMounted(() => {
  if (formModel.form) {
    batchSetState(formModel.form, 'unit', ['profitMargin'], props.unit);
    batchSetState(formModel.form, 'title', ['profitMargin'], props.title ? props.title : '');
  }
});

function updateForm() {
  if (formModel.form && props.model) {
    updateModel(formModel.form, props.model[props.profitKey]);
    batchSetState(formModel.form, 'minValue', ['profitMargin'], props.model.minProfit);
    batchSetState(formModel.form, 'maxValue', ['profitMargin'], props.model.maxProfit);
  }
}

watch(() => props.model, updateForm, { immediate: true, deep: true });

function onProfitMarginChange() {
  if (formModel.form) {
    emit('update:model', toDataModel(formModel.form));
  }
}

watch(() => formModel.form, onProfitMarginChange, { immediate: true, deep: true });

const min = computed(() => (props.minValue ? props.minValue / 100 : 0));

const max = computed(() => (props.maxValue ? props.maxValue / 100 : 0));
</script>

<template>
  <div class="form-item-wrapper mb-3">
    <ValidatedForm
      :class="unit === 'basisPoints' ? 'no-title' : ''"
      :fm="formModel.form"
      @update:validation="$emit('update:validation', $event)"
    />
    <span v-if="unit === 'percentage'" class="text-small text-secondary"> min: {{ min }}%, max: {{ max }}%</span>
  </div>
</template>

<style lang="scss" scoped>
.form-item-wrapper {
  position: relative;
  .no-title {
    ::v-deep label {
      opacity: 0;
    }
  }
  .text-small {
    bottom: -0.5rem;
    left: 0;
    position: absolute;
  }
}
</style>
