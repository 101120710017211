import { TableFieldDefinition } from 'ah-common-lib/src/models';

export const groupTableFieldsFull: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
  { header: 'Members', key: 'members' },
];

export const groupTableFieldsMinimal: TableFieldDefinition[] = [
  {
    header: 'Name',
    key: 'name',
  },
];
