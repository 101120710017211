<script lang="ts" setup>
import {
  TargetSelection,
  IndividualTargetSelection,
  GroupTargetSelection,
  PhoneTargetSelection,
  EmailTargetSelection,
  targetSelectionName,
} from 'ah-api-gateways';
import { computed, PropType } from 'vue';

type RecipientType = 'group' | 'user' | 'phone' | 'email' | 'allUsers';

const RecipientTypeCountLabels: Record<RecipientType, string> = {
  group: 'group',
  user: 'user',
  phone: 'phone number',
  email: 'email',
  allUsers: 'All team members',
};

const props = defineProps({
  targets: {
    type: Array as PropType<TargetSelection[]>,
    default: () => [],
  },
  max: {
    type: Number,
    default: 4,
  },
  noTargetsText: {
    type: String,
    default: 'No targets found',
  },
});

const isAllUsers = computed(() => {
  return props.targets.length === 0;
});

const recipientLists = computed(() => {
  return {
    allUsers: props.targets as TargetSelection[],
    group: props.targets.filter((i) => (i as GroupTargetSelection).group) as IndividualTargetSelection[],
    user: props.targets.filter((i) => (i as IndividualTargetSelection).individual) as GroupTargetSelection[],
    phone: props.targets.filter((i) => (i as PhoneTargetSelection).phoneNumber) as PhoneTargetSelection[],
    email: props.targets.filter((i) => (i as EmailTargetSelection).email) as EmailTargetSelection[],
  } as Record<RecipientType, TargetSelection[]>;
});

const targetDescriptions = computed(() => {
  const out: string[] = [];

  let parts: RecipientType[] = ['phone', 'email'];
  if (isAllUsers.value) {
    parts = sortByAmount(parts);
    parts.unshift('allUsers');
  } else {
    parts.push('group', 'user');
    parts = sortByAmount(parts);
  }

  if (parts[0] === 'allUsers') {
    out.push(RecipientTypeCountLabels[parts[0]]);
    parts.splice(0, 1);
  }

  while (parts.length) {
    if (out.length + parts.reduce((count, part) => recipientLists.value[part].length + count, 0) > this.max) {
      let count = recipientLists.value[parts[0]].length;
      out.push(`${count} ${RecipientTypeCountLabels[parts[0]]}${count > 1 ? 's' : ''}`);
    } else {
      let array = recipientLists.value[parts[0]];
      array.forEach((el) => {
        out.push(targetSelectionName(el));
      });
    }

    parts.splice(0, 1);
  }

  return out;
});

function sortByAmount(arr: RecipientType[]) {
  return [...arr]
    .filter((i) => recipientLists.value[i].length > 0)
    .sort((a, b) => recipientLists.value[b].length - recipientLists.value[a].length);
}
</script>

<template>
  <span v-if="!targetDescriptions.length" class="target-list no-targets">{{ noTargetsText }}</span>
  <span v-else class="target-list">
    <span class="target-list-item" v-for="(target, index) in targetDescriptions" :key="index">
      {{ target }}<span v-if="index < targetDescriptions.length - 1">,</span>
    </span>
  </span>
</template>
