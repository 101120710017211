import { HttpOptions, HttpService } from 'ah-requests';
import { NotificationSettingsService } from './notificationSettingsService';
import { PaginatedQuery, NotificationSetting, NotificationSettingConfig } from '../models';

export class PartnerNotificationSettingsService extends NotificationSettingsService {
  constructor(http: HttpService, baseUrl: string) {
    super(http, `${baseUrl}partners/`, 'partnerNotificationSettingsService');
  }

  public editNotificationSetting(notification: Partial<NotificationSetting> & Pick<NotificationSetting, 'id'>) {
    return super.editNotificationSetting(notification);
  }

  public createNotificationSetting(notification: Partial<Omit<NotificationSetting, 'id'>>) {
    return super.createNotificationSetting(notification);
  }

  public deleteNotificationSetting(notificationId: string) {
    return super.deleteNotificationSetting(notificationId);
  }

  public listNotificationSettings(query: PaginatedQuery & { type?: string }) {
    return super.listNotificationSettings(query);
  }

  public listNotificationConfig(options: HttpOptions<NotificationSettingConfig[]> = {}) {
    return this.get<NotificationSettingConfig[]>(`${this.baseUrl}settings/notifications/spec`, { options });
  }
}
