<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { ComplianceStatus } from 'ah-api-gateways';
import { selectField } from 'ah-common-lib/src/form/models';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import InputDateSelector, { DateRange } from 'ah-common-lib/src/common/components/InputDateSelector.vue';
import { startOfDay, endOfDay } from 'date-fns';
import {
  UseManagedListingEmits,
  defineUseManagedListFilterProps,
  useManagedListFilter,
} from 'ah-common-lib/src/listing';
import { SearchInput } from '../../common/form';

interface OnboardingClientsListFilters {
  query?: string;
  status?: ComplianceStatus[];
  selectedRegistrationDate?: DateRange;
  selectedTermsAndConditionsDate?: DateRange;
  selectedCaseClosedDate?: DateRange;
}

const props = defineProps({
  ...defineUseManagedListFilterProps<OnboardingClientsListFilters>(),
});

interface OnboardingClientsListFiltersEmits extends UseManagedListingEmits<OnboardingClientsListFilters> {}

const emit = defineEmits<OnboardingClientsListFiltersEmits>();

const clientStatuses = ref<ComplianceStatus[]>([]);
const selectedDate = ref<DateRange>();

const dateTypeOptions: FieldOptionObj[] = [
  { label: 'Registration', value: 'createdAt' },
  { label: 'T&Cs Accepted', value: 'termsAndConditionsDate' },
  { label: 'Approved/Rejected', value: 'caseClosedAt' },
];

const clientStatusOptions = computed(() => [
  { label: 'Pending Questionnaire', value: ComplianceStatus.PENDING },
  { label: 'Incomplete', value: ComplianceStatus.DOCUMENTS_REQUIRED },
  { label: 'In Review', value: ComplianceStatus.SUBMITTED },
  { label: 'Approved', value: ComplianceStatus.APPROVED },
  { label: 'T&Cs Updated', value: ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS },
  { label: 'Rejected', value: ComplianceStatus.REJECTED },
  { label: 'Suspended', value: ComplianceStatus.SUSPENDED },
  { label: 'Deactivated', value: ComplianceStatus.DEACTIVATED },
  { label: "Suspended - T&C's Outstanding", value: ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED },
]);

const dateTypeForm = ref(
  makeFormModel({
    name: 'dateTypeForm',
    fieldType: 'form',
    fields: [
      selectField('type', 'Date Range Type', dateTypeOptions, {
        required: false,
        defaultValue: 'createdAt',
        titleClass: 'text-nowrap',
      }),
    ],
  })
);

const defaultDate = computed(() => ({
  start: startOfDay(new Date()),
  end: endOfDay(new Date()),
}));

const { filterData } = useManagedListFilter<OnboardingClientsListFilters>({
  emit,
  props,
  runSettersOnUndefined: true,
  sortAndPageKeys: [],
  filterKeys: [
    {
      key: 'query',
      getter: (filters) => filters.value.query || undefined,
      setter: (value: any, filters) => (filters.value.query = value),
    },
    {
      key: 'queryBy',
      getter: (filters) => (filters.value.query && filters.value.query.length > 0 && ['name']) || undefined,
      setter: () => null,
    },
    {
      key: 'status',
      getter: () => {
        if (clientStatuses.value.length > 0) {
          const status = [...clientStatuses.value];
          if (status.includes(ComplianceStatus.DOCUMENTS_REQUIRED)) {
            status.push(ComplianceStatus.TERMS_AND_CONDITIONS);
          }
          return status;
        }
        return undefined;
      },
      setter: (value: ComplianceStatus[], filters) => {
        if (Array.isArray(value)) {
          clientStatuses.value = value.filter((s) => s !== ComplianceStatus.TERMS_AND_CONDITIONS);
        }
        filters.value.status = value;
      },
    },
    {
      key: 'createdAtFrom',
      getter: (filters) => filters.value.selectedRegistrationDate?.start,
      setter: (value, filters) => {
        if (filters.value.selectedRegistrationDate) {
          filters.value.selectedRegistrationDate.start = value;
        }
      },
    },
    {
      key: 'createdAtTo',
      getter: (filters) => filters.value.selectedRegistrationDate?.end,
      setter: (value, filters) => {
        if (filters.value.selectedRegistrationDate) {
          filters.value.selectedRegistrationDate.end = value;
        }
      },
    },
    {
      key: 'termsAndConditionsDateFrom',
      getter: (filters) => filters.value.selectedTermsAndConditionsDate?.start,
      setter: (value, filters) => {
        if (filters.value.selectedTermsAndConditionsDate) {
          filters.value.selectedTermsAndConditionsDate.start = value;
        }
      },
    },
    {
      key: 'termsAndConditionsDateTo',
      getter: (filters) => filters.value.selectedTermsAndConditionsDate?.end,
      setter: (value, filters) => {
        if (filters.value.selectedTermsAndConditionsDate) {
          filters.value.selectedTermsAndConditionsDate.end = value;
        }
      },
    },
    {
      key: 'caseClosedAtFrom',
      getter: (filters) => filters.value.selectedCaseClosedDate?.start,
      setter: (value, filters) => {
        if (filters.value.selectedCaseClosedDate) {
          filters.value.selectedCaseClosedDate.start = value;
        }
      },
    },
    {
      key: 'caseClosedAtTo',
      getter: (filters) => filters.value.selectedCaseClosedDate?.end,
      setter: (value, filters) => {
        if (filters.value.selectedCaseClosedDate) {
          filters.value.selectedCaseClosedDate.end = value;
        }
      },
    },
  ],
});

watch(
  () => selectedDate.value,
  (newValue) => {
    if (newValue) {
      const dateType = dateTypeForm.value.type;
      switch (dateType) {
        case 'createdAt':
          filterData.value.selectedRegistrationDate = {
            start: startOfDay(new Date(newValue.start)),
            end: endOfDay(new Date(newValue.end)),
          };
          break;
        case 'termsAndConditionsDate':
          filterData.value.selectedTermsAndConditionsDate = {
            start: startOfDay(new Date(newValue.start)),
            end: endOfDay(new Date(newValue.end)),
          };
          break;
        case 'caseClosedAt':
          filterData.value.selectedCaseClosedDate = {
            start: startOfDay(new Date(newValue.start)),
            end: endOfDay(new Date(newValue.end)),
          };
          break;
      }
    } else {
      filterData.value.selectedRegistrationDate = undefined;
      filterData.value.selectedTermsAndConditionsDate = undefined;
      filterData.value.selectedCaseClosedDate = undefined;
    }

    emit('update:filter', { ...filterData.value });
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <BoxGrid alignH="start">
    <BoxGridItem sm="12" lg="6" xl="3" class="field-group-wrapper">
      <SearchInput
        class="search-input mb-4 mb-xl-0 mock-label-padding"
        placeholder="Search by client"
        :search.sync="filterData.query"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" offset-xl="1" lg="6" xl="2" class="field-group-wrapper state">
      <label>
        State
        <a v-if="clientStatuses.length > 0" class="field-group-clear-link" @click="clientStatuses = []"> clear </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="clientStatusOptions"
        :value.sync="clientStatuses"
        itemsCountLabel="types"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="4" xl="2">
      <ValidatedForm :fm="dateTypeForm" />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="8" xl="4" class="date-selector">
      <InputDateSelector
        title="Date Range"
        :dateSelected.sync="selectedDate"
        :defaultDate="defaultDate"
        hide-choices
        clearable
        is-ranged
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<style lang="scss" scoped>
::v-deep {
  @media only screen and (max-width: 1600px) {
    .state {
      margin-left: 0 !important;
    }
    .date-selector {
      flex-grow: 1;
      max-width: 100%;
    }
  }
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }
  }
}
</style>
