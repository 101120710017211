import { render, staticRenderFns } from "./TimeFrameInput.vue?vue&type=template&id=fd5b6806&scoped=true"
import script from "./TimeFrameInput.vue?vue&type=script&setup=true&lang=ts"
export * from "./TimeFrameInput.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd5b6806",
  null
  
)

export default component.exports