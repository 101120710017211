<script setup lang="ts">
import { Client, ClientType, PaginatedResponse, Individual } from 'ah-api-gateways';
import { useSettingsStore } from '@/app/store/settingsModule';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';

const requestManager = useRequestManager().manager;

const services = getServices();

const settingsStore = useSettingsStore();

const individualSettingsStore = useIndividualSettingsStore();
/**
 * Potentially set client id to display information
 */
const props = defineProps({ clientId: { type: String, required: false } });

const client = ref<Client>();

const clientIndividuals = ref<PaginatedResponse<Individual> | null>(null);

onBeforeMount(() => settingsStore.loadEntityTypes());

const isCompany = computed(() => client.value!.type === ClientType.COMPANY);

function onClientChange() {
  clientIndividuals.value = null;
  if (props.clientId) {
    requestManager.cancel('getClientIndividuals');

    requestManager
      .sameOrCancelAndNew('getClient', services.client.getClient(props.clientId), props.clientId)
      .subscribe((response) => {
        client.value = response;
        requestManager
          .sameOrCancelAndNew(
            'getClientIndividuals',
            services.individual.listIndividuals({ clientId: response.id, pageSize: 1 }),
            response.id
          )
          .subscribe((response) => {
            clientIndividuals.value = response;
          });
      });
  } else {
    client.value = individualSettingsStore.client!;
  }
}

watch(() => props.clientId, onClientChange, { immediate: true });

const firstBlock = computed(() => {
  let out: { label: string; value?: string }[] = [];
  out.push({ label: 'Name of entity', value: client.value!.name });

  if (isCompany.value) {
    const company = client.value?.companyDetails;
    out.push({ label: 'Registration number', value: company?.registrationNumber });
    out.push({
      label: 'Country of incorporation',
      value: countryNameFromCC(company?.address.countryCode),
    });
    out.push({
      label: 'Entity type',
      value: settingsStore.entityTypesList.find((type) => type.id === company?.entityType)?.legalForm,
    });
  } else if (clientIndividuals.value?.list.length) {
    out.push({
      label: 'Country of residency',
      value: countryNameFromCC(clientIndividuals.value?.list[0].address.countryCode),
    });
  }
  return out;
});

const secondBlock = computed(() => {
  let out: { label: string; value?: string }[] = [];
  if (isCompany.value) {
    const individual = clientIndividuals.value?.list[0];
    return [
      { label: 'Address', value: client.value?.companyDetails.address?.addressLine },
      { label: 'Post code', value: client.value?.companyDetails.address?.postalCode },
      { label: 'Telephone number', value: individual?.phoneNumber },
      { label: 'Email', value: individual?.email },
    ];
  } else if (clientIndividuals.value?.list.length) {
    const individual = clientIndividuals.value.list[0];
    return [
      { label: 'Address', value: individual?.address?.addressLine },
      { label: 'Post code', value: individual?.address?.postalCode },
      { label: 'Telephone number', value: individual?.phoneNumber },
      { label: 'Email', value: individual?.email },
    ];
  }
  return out;
});
</script>

<template>
  <div class="position-relative pt-0 mt-5 padded-space" id="client-information-view">
    <LoadingOverlay :state="requestManager.currentStates">
      <div class="centered-space" v-if="client">
        <div>
          <h2>{{ isCompany ? 'Company' : 'Client' }} Information</h2>
          <BoxGrid alignH="start">
            <BoxGridBlock itemClass="card-block h-100" class="h-100" cols="12" lg="6" xl="5">
              <div class="info-block" v-for="(item, index) in firstBlock" :key="index">
                <div class="label">{{ item.label }}</div>
                <div class="value">{{ item.value || '-' }}</div>
              </div>
            </BoxGridBlock>
            <BoxGridBlock itemClass="card-block h-100" class="h-100" cols="12" lg="6" xl="5">
              <div class="info-block" v-for="(item, index) in secondBlock" :key="index">
                <div class="label">{{ item.label }}</div>
                <div class="value">{{ item.value || '-' }}</div>
              </div>
            </BoxGridBlock>
          </BoxGrid>
        </div>
      </div>
    </LoadingOverlay>
  </div>
</template>

<style lang="scss" scoped>
.info-block {
  margin-bottom: 30px;
  .label {
    font-weight: $font-weight-semibold;
  }
  .value {
    font-size: $font-size-sm;
  }
}
</style>
