<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { AuthorityType, Permission } from 'ah-api-gateways';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { makeFormModel } from 'ah-common-lib/src/form/helpers/formMaker';
import { checkboxField } from 'ah-common-lib/src/form/models';
import { helpers } from '@vuelidate/validators';
import { useTheme } from 'ah-theme';

const props = defineProps<{ permissions?: Permission[]; validation?: FormValidation<Permission[]> }>();

const emit = defineEmits<{
  (e: 'update:permissions', value: Permission[]): void;
  (e: 'update:validation', value: FormValidation<Permission[]>): void;
}>();

const getAgreementLink = 'https://www.assurehedge.com/wp-content/uploads/Assure_Hedge_UK_LTD_LPOA_Draft_02092021.pdf';

const permissionsInner = ref({
  title: 'OBO Permissions',
  description: `OBO (On Behalf Of) permits ${useTheme().val.name} to
    trade and make payments on your behalf. These settings can be changed
    after your account is activated.`,
  options: [
    { key: AuthorityType.TRADE_ON_BEHALF_OF, value: false, label: 'Trading OBO' },
    { key: AuthorityType.PAY_ON_BEHALF_OF, value: false, label: 'Payment OBO' },
  ],
});

const calculatedModel = computed(() =>
  permissionsInner.value.options.map((opt) => ({ authority: opt.key, allow: opt.value } as Permission))
);

const validationInner = computed(() => {
  return {
    $model: calculatedModel.value,
    $invalid: !!agreementFM.validation?.$invalid,
    $dirty: !!agreementFM.validation?.$dirty,
  } as FormValidation<Permission[]>;
});

const anyPermissionSet = computed(() => permissionsInner.value.options.findIndex((p) => p.value) >= 0);

const agreementFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'agreementConfirm',
    fieldType: 'form',
    fields: [
      checkboxField(
        'lpoaAgreed',
        '',
        false,
        {
          hidden: () => !anyPermissionSet.value,
          errorMessages: { mustAccept: 'Required field' },
        },
        {
          mustAccept: helpers.withParams(
            { type: 'mustAccept' },
            (val: any) => !anyPermissionSet.value || (anyPermissionSet.value && !!val)
          ),
        }
      ),
    ],
  }),
  validation: null,
});

watch(
  () => props.permissions,
  () => {
    const tradeChecked =
      props.permissions?.find((p) => p.authority === AuthorityType.TRADE_ON_BEHALF_OF && p.allow)?.allow ?? false;
    const paymentChecked =
      props.permissions?.find((p) => p.authority === AuthorityType.PAY_ON_BEHALF_OF && p.allow)?.allow ?? false;

    if (tradeChecked || paymentChecked) {
      permissionsInner.value.options[0].value = tradeChecked;
      permissionsInner.value.options[1].value = paymentChecked;
    }
  },
  { immediate: true }
);

watch(calculatedModel, () => emit('update:permissions', calculatedModel.value));

watch(validationInner, () => emit('update:validation', validationInner.value));
</script>

<template>
  <div class="card-block" x-test-name="obo-settings-form">
    <h3>{{ permissionsInner.title }}</h3>
    <VRow>
      <VCol class="d-inline-flex" v-for="permission in permissionsInner.options" :key="permission.key">
        <label class="mr-3"> {{ permission.label }}</label>
        <BFormCheckbox v-model="permission.value" :name="`check-button-${permission.key}`" switch />
      </VCol>
    </VRow>
    <p class="text-muted">{{ permissionsInner.description }}</p>

    <ValidatedForm class="mt-4" :fm="agreementFM.form" :validation.sync="agreementFM.validation">
      <template #agreementConfirm.lpoaAgreed:checkbox-label>
        <span class="text-muted text-small">
          I confirm that I have read and agree to the Legal Power of Attorney (LPOA) agreement
          <a class="link" :href="getAgreementLink" target="_blank">here</a>.
        </span>
      </template>
    </ValidatedForm>
  </div>
</template>
